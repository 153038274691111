import React, { useEffect, useMemo } from "react";
import { Ballot } from "@material-ui/icons";
import {
  IconButton,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import { generateDefaultChartOptions2D, getPercentage } from "../../utils";
import { useModels } from "../../hooks/useModels";

const EstadisticoLecturaVotos = ({ history }) => {
  const [maxInterval, setMaxInterval] = React.useState(0);
  const [nombre, setNombre] = React.useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [nombreConsejo, setNombreConsejo] = React.useState("");

  let chartOptions = generateDefaultChartOptions2D();

  const objExtraParamLectura = useMemo(
    () => ({
      idConsejoElectoral: idConsejoElectoral,
      limite: 1,
    }),
    [idConsejoElectoral]
  );

  const [
    actas,
    actasLoading,
    actasErrorLectura,
    actasPageLectura,
    refreshActasLectura,
    deleteActaLectura,
  ] = useModels({
    name: "consejo-electoral/estadistico-lectura",
    extraParams: objExtraParamLectura,
  });

  const reqProperties = {
    name: "consejo-electoral",
    idEstado: 26,
    limite: -1,
    ordenar: "nombre.asc",
  };

  const [request, setRequest] = React.useState(reqProperties);
  const [
    consejos,
    modelsConsejosLoading,
    modelsConsejosError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels(request);

  useEffect(() => {
    const idConsejoElectoral = consejos[0]?.idConsejoElectoral;
    const nombreC = consejos[0]?.nombre;

    const idConsejoElectoral2 = consejos[maxInterval + 1]?.idConsejoElectoral;
    const nombreC2 = consejos[maxInterval + 1]?.nombre;

    if (idConsejoElectoral && idConsejoElectoral !== "") {
      let mounted = true;

      if (maxInterval == 0) {
        setIdConsejoElectoral(idConsejoElectoral);
        setNombreConsejo(nombreC);
      }

      let timeout = setInterval(() => {
        if (mounted) {
          setIdConsejoElectoral(idConsejoElectoral2);
          setNombreConsejo(nombreC2);
          setMaxInterval(maxInterval + 1);

          if (maxInterval == 92) {
            setMaxInterval(0);
          }
        }
      }, 30000);
      return () => {
        if (timeout !== null) {
          clearInterval(timeout);
        }
        mounted = false;
      };
    }
  }, [maxInterval, consejos]);

  const totales = actas.length > 0 ? actas[0] : {};

  const sinLecturaInt =
    actas && actas[0]
      ? parseInt(actas[0]?.actasTotal.toString().replace(",", "")) -
        parseInt(actas[0]?.actasLectura.toString().replace(",", ""))
      : 0;

  const leidasInt =
    actas && actas[0]
      ? parseInt(actas[0]?.actasLectura.toString().replace(",", ""))
      : 0;

  let extraOptions = {
    colors: ["#9E9E9E", "#ad2624"],
    title: {
      text: "LECTURA DE VOTOS",
    },
    subtitle: {
      text: "TOTALIZADOR DE ACTAS POR LECTURA DE VOTOS",
    },
    plotOptions: {
      pie: {
        size: 300,
      },
    },
    series: [
      {
        name: "Actas",
        data: [
          ["Sin Lectura", sinLecturaInt],
          ["Leídas", leidasInt],
          // ["Sin Recibo Paquete", totales.sinrpe],
        ],
      },
    ],
  };
  chartOptions = { ...chartOptions, ...extraOptions };

  return (
    <TablePageLayout
      title="ESTADÍSTICOS: AVANCE LECTURA ACTA"
      model="consejos-electorales"
      history={history}
      links={[
        {
          to: "/estadisticos/avance-lectura-acta",
          icon: <Ballot />,
          label: "Estadísticos: Avance lectura acta",
        },
      ]}
      tableLoading={actasLoading}
      // tableErrors={consejosElectoralesError}

      TableComponent={
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3"> {nombreConsejo} </Typography>
            <br></br>
            <br></br>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h4">
              <div style={{ color: "black" }}>
                TOTAL ACTAS:
                <b style={{ color: "red" }}> {totales.actasTotal}</b>
              </div>

              <div style={{ color: "black" }}>
                CON LECTURA:
                <b style={{ color: "red" }}>{totales.actasLectura}</b>
              </div>

              <div style={{ color: "black" }}>
                CON DATOS GENERALES:
                <b style={{ color: "red" }}>{totales.actasDatosGenerales}</b>
              </div>

              <div style={{ color: "black" }}>
                FALTANTES LECTURA:
                <b style={{ color: "red" }}>{totales.actasFaltantesLectura}</b>
              </div>
            </Typography>
          </Grid>

          <Grid item xs={8}>
            {idConsejoElectoral !== "" && (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default EstadisticoLecturaVotos;
