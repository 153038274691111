import React from "react";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import HomeIcon from "@material-ui/icons/Home";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import { Tooltip } from "@material-ui/core";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "../firebase";
import TipoEleccion from "../utils/tipoEleccion";

import {
  bodegasCollapse,
  paquetesCollapse,
  actasCollapse,
  reunionDeTrabajoCollapse,
  computoCollapse,
  estadisticosCollapse,
  panelDeControlCollapse,
  clausuraControlCollapse,
} from "../utils/sidebarCollapse";

import { useAuth } from "../hooks/useAuth";
import { useCacheBuster } from "../hooks/CacheBuster";
import { ExitToApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  doubleNested: {
    paddingLeft: theme.spacing(6),
  },
}));

const mapLinks = (link) => link.permission;
const snapshotOptions = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

const nombreColeccion =
  window.location.host === "2021.computosonora.com" ? "menu-lateral" : "asda";

const Sidebar = ({ isOpen, ...props }) => {
  const { session, sessionLoading, user, userLoading } = useAuth();
  const classes = useStyles();
  const refMemo = React.useMemo(
    () =>
      user && user.consejoElectoral && user.consejoElectoral.idConsejoElectoral
        ? firebase
            .firestore()
            .doc(
              `${nombreColeccion}/${user.consejoElectoral.idConsejoElectoral}`
            )
        : undefined,
    [user]
  );
  const [doc, docLoading, docError] = useDocument(refMemo, snapshotOptions);
  const { refreshCacheAndRedirect } = useCacheBuster();

  const CollapsableList = ({
    icon,
    title,
    links = [],
    allowAll = false,
    ...rest
  }) => {
    const [collapse, setCollapse] = React.useState(false);
    const toggleCollapse = () => setCollapse((c) => !c);
    const { permiso } = user;

    return (
      <>
        <Tooltip placement="right" title={title} arrow>
          <ListItem button onClick={toggleCollapse} {...rest}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText style={{ whiteSpace: "pre-wrap" }} primary={title} />
            {collapse ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </Tooltip>

        <Collapse in={collapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {links.map((link, i) => {
              if (link.links)
                return (
                  <CollapsableList
                    key={i}
                    className={classes.nested}
                    itemclassname={classes.doubleNested}
                    icon={link.icon}
                    title={link.title}
                    links={link.links}
                    allowAll={allowAll}
                  />
                );

              if ((permiso[link.permission] & 1) === 1 || allowAll)
                return (
                  <NavLink
                    to={link.to}
                    key={i}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Tooltip placement="right" title={link.title} arrow>
                      <ListItem
                        button
                        className={
                          rest.itemclassname
                            ? rest.itemclassname
                            : classes.nested
                        }
                      >
                        {link.icon ? (
                          <ListItemIcon>{link.icon}</ListItemIcon>
                        ) : null}

                        <ListItemText
                          primary={link.title}
                          style={{ whiteSpace: "pre-wrap" }}
                        />
                      </ListItem>
                    </Tooltip>
                  </NavLink>
                );
              return null;
            })}
          </List>
        </Collapse>
      </>
    );
  };

  if (!session || sessionLoading) return null;
  if (!user || userLoading) return null;
  const { permiso } = user;

  let configFirebase = {};
  if (doc && !docLoading) {
    configFirebase = doc.data() || {};
  }
  let esMunicipal = false;
  if (user && user.consejoElectoral) {
    esMunicipal =
      user.consejoElectoral.idTipoConsejoElectoral ===
        TipoEleccion.AYUNTAMIENTO &&
      user.puesto === "CONSEJERO(A) PRESIDENTE(A)";
  }

  const hasReadPermission = (p) => (permiso[p] & 1) === 1;

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <NavLink
          to="#"
          onClick={(_) => refreshCacheAndRedirect("/")}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText secondary="Proceso Electoral 2021" />
          </ListItem>
        </NavLink>
        {(configFirebase["bodegas"] === true ||
          configFirebase["bodegas"] === undefined) &&
          bodegasCollapse.links.map(mapLinks).some(hasReadPermission) && (
            <CollapsableList
              icon={bodegasCollapse.icon}
              title={bodegasCollapse.title}
              links={bodegasCollapse.links}
            />
          )}

        {(configFirebase["paquetes"] === true ||
          configFirebase["paquetes"] === undefined) &&
          paquetesCollapse.links.map(mapLinks).some(hasReadPermission) && (
            <CollapsableList
              icon={paquetesCollapse.icon}
              title={paquetesCollapse.title}
              links={paquetesCollapse.links}
            />
          )}
        {(configFirebase["actas"] === true ||
          configFirebase["actas"] === undefined) &&
          actasCollapse.links.map(mapLinks).some(hasReadPermission) && (
            <CollapsableList
              icon={actasCollapse.icon}
              title={actasCollapse.title}
              links={actasCollapse.links}
            />
          )}
        {(configFirebase["reunionTrabajo"] === true ||
          configFirebase["reunionTrabajo"] === undefined) &&
          reunionDeTrabajoCollapse.links
            .map(mapLinks)
            .some(hasReadPermission) && (
            <CollapsableList
              icon={reunionDeTrabajoCollapse.icon}
              title={reunionDeTrabajoCollapse.title}
              links={reunionDeTrabajoCollapse.links}
            />
          )}
        {(configFirebase["sesionExtraordinaria"] === true ||
          configFirebase["sesionExtraordinaria"] === undefined) &&
          (permiso["sesionExtraordinaria"] & 1) === 1 && (
            <NavLink
              to="/sesion-extraordinaria"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Tooltip placement="right" title="Sesión Extraordinaria" arrow>
                <ListItem button>
                  <ListItemIcon>
                    <MeetingRoom />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: "pre-wrap" }}
                    primary="Sesión Extraordinaria"
                  />
                </ListItem>
              </Tooltip>
            </NavLink>
          )}

        {(configFirebase["computo"] === true ||
          configFirebase["computo"] === undefined) &&
          computoCollapse.links.map(mapLinks).some(hasReadPermission) && (
            <CollapsableList
              icon={computoCollapse.icon}
              title={computoCollapse.title}
              links={computoCollapse.links}
            />
          )}

        {(configFirebase["controlCapacitacion"] === true ||
          configFirebase["controlCapacitacion"] === undefined) &&
          (permiso["controlCapacitacion"] & 1) === 1 && (
            <NavLink
              to="/control-capacitacion"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Tooltip placement="right" title="Capacitación" arrow>
                <ListItem button>
                  <ListItemIcon>
                    <MeetingRoom />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: "pre-wrap" }}
                    primary="Control de capacitación"
                  />
                </ListItem>
              </Tooltip>
            </NavLink>
          )}

        {(configFirebase["estadisticos"] === true ||
          configFirebase["estadisticos"] === undefined) &&
          estadisticosCollapse.links.map(mapLinks).some(hasReadPermission) && (
            <CollapsableList
              icon={estadisticosCollapse.icon}
              title={estadisticosCollapse.title}
              links={estadisticosCollapse.links}
            />
          )}

        {(configFirebase["panelControl"] === true ||
          configFirebase["panelControl"] === undefined) &&
          (permiso["panelControl"] & 1) === 1 && (
            <CollapsableList
              icon={panelDeControlCollapse.icon}
              title={panelDeControlCollapse.title}
              links={panelDeControlCollapse.links}
              allowAll
            />
          )}
        {esMunicipal && (
          <CollapsableList
            icon={clausuraControlCollapse.icon}
            title={clausuraControlCollapse.title}
            links={clausuraControlCollapse.links}
            allowAll
          />
        )}

        {(configFirebase["controlActas"] === true ||
          configFirebase["controlActas"] === undefined) &&
          (permiso["controlActas"] & 1) === 1 &&
          permiso["rol"] === "Admin" && (
            <NavLink
              to="/control-actas"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Tooltip placement="right" title="Control de actas" arrow>
                <ListItem button>
                  <ListItemIcon>
                    <MeetingRoom />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: "pre-wrap" }}
                    primary="Control de actas"
                  />
                </ListItem>
              </Tooltip>
            </NavLink>
          )}

        <NavLink
          to="/logout"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText secondary="CERRAR SESIÓN" />
          </ListItem>
        </NavLink>
      </List>
    </>
  );
};

export default Sidebar;
