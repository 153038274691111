import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import ReceiptIcon from "@material-ui/icons/Receipt";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import RoomIcon from "@material-ui/icons/Room";
import MapIcon from "@material-ui/icons/Map";
import DescriptionIcon from "@material-ui/icons/Description";
import GavelIcon from "@material-ui/icons/Gavel";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import ArchiveIcon from "@material-ui/icons/Archive";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PictureInPictureIcon from "@material-ui/icons/PictureInPicture";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import HouseIcon from "@material-ui/icons/House";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BallotIcon from "@material-ui/icons/Ballot";
import BarChartIcon from "@material-ui/icons/BarChart";
import BookIcon from "@material-ui/icons/Book";
import CachedIcon from "@material-ui/icons/Cached";
import CodeIcon from "@material-ui/icons/Code";
import CompareIcon from "@material-ui/icons/Compare";
import ComputerIcon from "@material-ui/icons/Computer";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import EventNoteIcon from "@material-ui/icons/EventNote";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import InboxIcon from "@material-ui/icons/Inbox";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import PieChartIcon from "@material-ui/icons/PieChart";
import RateReviewIcon from "@material-ui/icons/RateReview";
import ViewListIcon from "@material-ui/icons/ViewList";
import WorkIcon from "@material-ui/icons/Work";
import CropFreeIcon from "@material-ui/icons/CropFree";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { Redo, SyncProblem } from "@material-ui/icons";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const bodegasCollapse = {
  title: "Bodega",
  icon: <BusinessIcon />,
  links: [
    {
      to: "/bodegas/administracion",
      icon: <AssignmentIcon />,
      title: "Administración",
      permission: "bodegaAdministracion",
    },
    {
      to: "/bodegas/bitacora-de-paquetes",
      icon: <BookIcon />,
      title: "Paquetes",
      permission: "bodegaPaquetes",
    },
    {
      to: "/bodegas/movimientos-de-bodegas",
      icon: <CompareArrowsIcon />,
      title: "Movimientos de Bodegas",
      permission: "bodegaPaquetes",
    },
  ],
};

const paquetesCollapse = {
  title: "Paquetes",
  icon: <MoveToInboxIcon />,
  links: [
    {
      to: "/bodegas/bitacora-de-paquetes/recepcion-paquetes",
      icon: <InboxIcon />,
      title: "Recepción Estatal",
      permission: "recepcionEstatal",
    },
    {
      to: "/recepciones-municipales/nueva",
      icon: <InboxIcon />,
      title: "Recepción Municipal",
      permission: "paquetesRecepciones",
    },
    {
      to: "/recepciones-municipales/avance",
      icon: <InboxIcon />,
      title: "Avance de Recepción",
      permission: "paquetesRecepciones",
    },
  ],
};

const actasCollapse = {
  title: "Actas",
  icon: <ReceiptIcon />,
  links: [
    {
      to: "/lectura-de-votos/nueva",
      icon: <FindInPageIcon />,
      title: "Lectura",
      permission: "actaLectura",
    },
    {
      to: "/lectura-de-votos/datos-generales/nueva",
      icon: <FileCopyIcon />,
      title: "Datos Generales",
      permission: "actaDatosGenerales",
    },
    {
      to: "/lectura-estatal/nueva",
      icon: <InboxIcon />,
      title: "Lectura Estatal",
      permission: "lecturaEstatal",
    },
    {
      to: "/lectura-de-votos/resultados-preliminares",
      icon: <BallotIcon />,
      title: "Avance",
      permission: "actaResultados",
    },
  ],
};

const reunionDeTrabajoCollapse = {
  title: "Reunión de Trabajo",
  icon: <GroupWorkIcon />,
  links: [
    {
      to: "/reunion-de-trabajo/informe",
      icon: <AssignmentIcon />,
      title: "Informe",
      permission: "reunionTrabajoReportes",
    },
    {
      to: "/rectificacion",
      icon: <RateReviewIcon />,
      title: "Rectificación",
      permission: "reunionTrabajoRectificacion",
    },
    {
      to: "/grupos-trabajo",
      icon: <WorkIcon />,
      title: "Grupos de Trabajo",
      permission: "gruposDeTrabajoRepresentantes",
    },
  ],
};

const computoCollapse = {
  title: "Cómputo",
  icon: <ComputerIcon />,
  links: [
    {
      to: "/cotejo/nuevo",
      icon: <CompareIcon />,
      title: "Cotejo",
      permission: "computoCotejo",
    },
    {
      to: "/recuento/nuevo",
      icon: <AccountTreeIcon />,
      title: "Recuento en Pleno",
      permission: "computoRecuento",
    },
    {
      to: "/recuento-gt/nuevo",
      icon: <Redo />,
      title: "Recuento por Grupo de Trabajo",
      permission: "computoRecuentoGt",
    },
    {
      to: "/recuento-gt-reservado/nuevo",
      icon: <SyncProblem />,
      title: "Deliberación de votos reservados",
      permission: "computoRecuentoGtRes",
    },
    {
      to: "/acreditacion-representantes",
      icon: <SyncProblem />,
      title: "Acreditación de representantes",
      permission: "acreditacionRep",
    },
    {
      to: "/computo/resultados",
      icon: <AnnouncementIcon />,
      title: "Resultados de la Votación",
      permission: "computoResultadosVotacion",
    },
  ],
};

const estadisticosCollapse = {
  title: "Estadísticos",
  icon: <BarChartIcon />,
  links: [
    {
      to: "/estadisticos/avance-recepcion-paquetes",
      icon: <CachedIcon />,
      title: "Avance de Recepción de Paquetes Electorales",
      permission: "estadisticaAvanceRecepcion",
    },
    {
      to: "/estadisticos/reportes-eiedea",
      icon: <CachedIcon />,
      title: "Reporte de causales aritméticas",
      permission: "estadisticaCausalesAritmeticas",
    },
    {
      to: "/estadisticos/avance-bodega-electoral",
      icon: <FindReplaceIcon />,
      title: "Avance de Bodega Electoral",
      permission: "estadisticaAvanceBodega",
    },
    {
      to: "/estadisticos/reporte-clausura",
      icon: <DescriptionIcon />,
      title: "Reporte Clausura",
      permission: "estadisticaReportesClausura",
    },
    {
      to: "/estadisticos/cotejo-recuento",
      icon: <DescriptionIcon />,
      title: "Cotejo/Recuento",
      permission: "estadisticaCotejoRecuento",
    },
    {
      to: "/estadisticos/informe-recuento",
      icon: <DescriptionIcon />,
      title: "Informe Recuento",
      permission: "estadisticaInformeRecuento",
    },
    {
      to: "/estadisticos/lectura-votos",
      icon: <PieChartIcon />,
      title: "Estadísticos Lectura de Votos",
      permission: "estadisticaLecturaVotos",
    },
    // {
    //   to: "",
    //   icon: <ViewListIcon />,
    //   title: "Avance Lectura de Votos",
    //   permission: "estadisticaAvanceLecturaVotos",
    // },
    {
      to: "/estadisticos/informacion-complementaria",
      icon: <DonutLargeIcon />,
      title: "Estadísticos información complementaria",
      permission: "estadisticaInfoComplementaria",
    },
    {
      to: "/estadisticos/monitor-eleccion",
      icon: <EqualizerIcon />,
      title: "Monitor elección",
      permission: "estadisticaMonitorEleccion",
    },
    {
      to: "/estadisticos/grupos-trabajo",
      icon: <EqualizerIcon />,
      title: "Estadísticos de grupos de trabajo",
      permission: "estadisticaGT",
    },
    {
      to: "/estadisticos/reporte-gtp",
      icon: <PieChartIcon />,
      title: "Estadísticos de cómputo",
      permission: "estadisticaGT",
    },
    {
      to: "/conteo-preliminar",
      icon: <PieChartIcon />,
      title: "Estadísticos de conteo preliminar",
      permission: "estadisticaPreliminar",
    },
    {
      to: "/estadisticos/global",
      icon: <PieChartIcon />,
      title: "Estadísticos Globales",
      permission: "estadisticaGlobal",
    },
    {
      to: "/estadisticos/avance-lectura-acta",
      icon: <PieChartIcon />,
      title: "Avance lectura Acta",
      permission: "estadisticaLecturaCarrusel",
    },
    {
      to: "/estadisticos/avance-lectura-acta-gubernatura",
      icon: <PieChartIcon />,
      title: "Avance lectura Acta Gubernatura",
      permission: "estadisticaLecturaCarruselGub",
    },
    {
      to: "/estadisticos/avance-recepcion",
      icon: <PieChartIcon />,
      title: "Avance Recepción",
      permission: "estadisticaRecepcionCarrusel",
    },
    {
      to: "/estadisticos/avance-recepcion-gubernatura",
      icon: <PieChartIcon />,
      title: "Avance Recepción Gubernatura",
      permission: "estadisticaRecepcionCarruselGub",
    },
    {
      to: "/estadisticos/avance-bodega-gubernatura",
      icon: <PieChartIcon />,
      title: "Avance Bodegas Gubernatura",
      permission: "estadisticaBitacoraCarruselGub",
    },
    {
      to: "/estadisticos/bitacora-entrada-paquetes",
      icon: <PieChartIcon />,
      title: "Bitacora Entrada Paquetes",
      permission: "estadisticaBitacoraCarrusel",
    },
    {
      to: "/estadisticos/bitacora-entrada-paquetes-gubernatura",
      icon: <PieChartIcon />,
      title: "Bitacora Entrada Paquetes Gubernatura",
      permission: "estadisticaBitacoraCarruselGub",
    },
  ],
};

const panelDeControlCollapse = {
  title: "Panel de Control",
  icon: <InsertChartIcon />,
  links: [
    {
      icon: <InsertChartIcon />,
      title: "Catálogos",
      links: [
        {
          to: "/partidos-politicos",
          icon: <AssignmentIcon />,
          title: "Partidos Políticos",
        },
        {
          to: "/candidatos",
          icon: <AssignmentIcon />,
          title: "Candidatos",
        },
        {
          to: "/candidatos-independientes",
          icon: <AssignmentIcon />,
          title: "Candidatos Independientes",
        },
        {
          to: "/candidatura-comun",
          icon: <AssignmentIcon />,
          title: "Candidatura Común",
        },
        {
          to: "/combinacion-coalicion",
          icon: <AssignmentIcon />,
          title: "Combinación Coalición",
        },
        {
          to: "/coaliciones",
          icon: <AssignmentIcon />,
          title: "Coaliciones",
        },
        {
          to: "/secciones",
          icon: <AssignmentIcon />,
          title: "Secciones",
        },
        {
          to: "/distritos",
          icon: <AssignmentIcon />,
          title: "Distritos",
        },
        {
          to: "/tipo-asistentes",
          icon: <AssignmentIcon />,
          title: "Tipos de Asistente",
        },
      ],
    },
    {
      title: "Archivos",
      icon: <DynamicFeedIcon />,
      links: [
        {
          to: "/archivos",
          icon: <DescriptionIcon />,
          title: "Excels",
        },
        {
          to: "/pdfs",
          icon: <DescriptionIcon />,
          title: "PDFs",
        },
        {
          to: "/recursos",
          icon: <DescriptionIcon />,
          title: "Banco de recursos",
        },
      ],
    },
    {
      to: "/usuarios",
      icon: <PersonIcon />,
      title: "Usuarios",
    },
    {
      to: "/consejeros",
      icon: <PersonIcon />,
      title: "Consejeros",
    },
    {
      to: "/agendas",
      icon: <EventNoteIcon />,
      title: "Agendas",
    },
    {
      to: "/qr",
      icon: <CropFreeIcon />,
      title: "QR",
    },
    {
      to: "/bodegas",
      icon: <BusinessIcon />,
      title: "Bodegas",
    },
    {
      to: "/estados",
      icon: <MapIcon />,
      title: "Estados",
    },
    {
      to: "/municipios",
      icon: <RoomIcon />,
      title: "Municipios",
    },
    {
      to: "/modelos-acta",
      icon: <AssignmentIcon />,
      title: "Modelos Acta",
    },
    {
      to: "/consejos-electorales",
      icon: <GavelIcon />,
      title: "Consejos Electorales",
    },
    {
      to: "/tipos-casilla",
      icon: <BookmarksIcon />,
      title: "Tipos de Casilla",
    },
    {
      to: "/tipos-acta",
      icon: <LibraryBooksIcon />,
      title: "Tipos de Acta",
    },
    {
      to: "/tipos-eleccion",
      icon: <PictureInPictureIcon />,
      title: "Tipos de Elección",
    },
    {
      to: "/casillas",
      icon: <ArchiveIcon />,
      title: "Casillas electorales",
    },
    {
      to: "/motivo-apertura-cierre",
      icon: <InsertDriveFileIcon />,
      title: "Motivos de apertura ó cierre",
    },
    {
      to: "/tipos-domicilio",
      icon: <HouseIcon />,
      title: "Tipos de domicilio",
    },
    {
      to: "/scripts",
      icon: <CodeIcon />,
      title: "Soporte TI",
    },
    {
      to: "/capacitacion",
      icon: <LocalLibraryIcon />,
      title: "Capacitación",
    },
    {
      to: "/administracion-grupos-trabajo",
      icon: <GroupWorkIcon />,
      title: "Grupos de trabajo",
    },
    {
      to: "/config-menu",
      icon: <GroupWorkIcon />,
      title: "Menú Lateral",
      permission: "controlMenuLateral",
    },
    {
      to: "/avance-computo/descargar",
      icon: <CloudDownloadIcon />,
      title: "Avance Cómputo",
    },
    {
      to: "/planillas",
      icon: <BookmarksIcon />,
      title: "Planillas",
    },
  ],
};

const clausuraControlCollapse = {
  title: "Clausura Casilla",
  icon: <ArchiveIcon />,
  links: [
    {
      to: "/clausura-casillas",
      icon: <CheckBoxOutlineBlankIcon />,
      title: "Clausura Casillas",
    },
  ],
};

export {
  bodegasCollapse,
  paquetesCollapse,
  actasCollapse,
  reunionDeTrabajoCollapse,
  computoCollapse,
  estadisticosCollapse,
  panelDeControlCollapse,
  clausuraControlCollapse,
};
