import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import { Paper, Typography } from "@material-ui/core";
import { baseUrl, baseUrlPdf } from "../../utils/variables";
import { auth } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    marginTop: 20,
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const ResultadosDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const id = auth.currentUser.uid;

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ display: "inline-block" }}>
        <StyledBreadcrumb
          component="a"
          label="Inicio"
          icon={<HomeIcon fontSize="small" />}
          onClick={() => {
            history.push("/");
          }}
        />
        <StyledBreadcrumb component="a" label="Resultados" onClick={() => {}} />
      </Breadcrumbs>

      <Grid container spacing={3} component={Paper} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Typography>FORMATOS GLOBALES DE ARCHIVOS</Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <FormControl
            className={classes.formControl}
            size="small"
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="grouped-select" id="grouped-select">
              Imprimir formato
            </InputLabel>
            <Select
              defaultValue=""
              labelId="grouped-select"
              id="grouped-select"
              label="Imprimir formato"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <ListSubheader style={{ fontWeight: "bold", color: "black" }}>
                Pdf
              </ListSubheader>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-resultado-jornada?nombre=ResultadoJornada&descargar=0&marca=1&uid=${id}`}
                value={1}
              >
                RESULTADO JORNADA
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-informes?nombre=InformePresidente&descargar=0&marca=1&uid=${id}`}
                value={2}
              >
                Informe al presidente
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/casillas-recuento?nombre=CasillasARecuento&descargar=0&marca=1&uid=${id}`}
                value={3}
              >
                CASILLAS RECUENTO
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta-casilla-levantada-en-pleno&uid=${id}`}
                value={4}
              >
                6285 ACTA CASILLA 13
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}acta-final?idUsuario=${id}&descargar=0&marca=1`}
                value={5}
              >
                4086 ACTA FINAL
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/acta-computo?nombre=5284_Acta_Computo_13&descargar=0&marca=1&uid=${id}`}
                value={6}
              >
                5284 ACTA COMPUTO 13
              </MenuItem>
              {/* <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-constancia-individual`}
                value={7}
              >
                7787 CONSTANCIA INDIVIDUAL 13
              </MenuItem> */}
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-constancia-mayoria-validez?nombre=ACRGT_GUBERNATURA&descargar=0&marca=1&uid=${id}`}
                value={8}
              >
                Constancia de mayoría y validez de elección para GUBERNATURA
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta-circunstancia-grupo-trabajo?nombre=Acta_Circunstanciada_GRUPO_TRABAJO&descargar=0&marca=1&uid=${id}`}
                value={9}
              >
                Acta Circunstanciada del Recuento Parcial en Grupo de Trabajo
                para elección de GUBERNATURA
              </MenuItem>

              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta-computo-representacion-proporcion&uid=${id}`}
                value={10}
              >
                ACTA COMPUTO REPRESENTACION PROPORCIONAL
              </MenuItem>

              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta-circunstanciada-voto-reservado?nombre=ACVR_GUBERNATURA&descargar=0&marca=1&uid=${id}`}
                value={11}
              >
                Acta Circunstanciada de votos reservados de la elección de
                GUBERNATURA levantada en el consejo General
              </MenuItem>
              <ListSubheader style={{ fontWeight: "bold", color: "black" }}>
                ACTA DE ESCRUTINIO Y CÓMPUTO DE CASILLA POR ELECCIÓN
              </ListSubheader>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/acta-escrutinio-computo-casilla-eleccion-gubernatura?nombre=2_AECC_GUBERNATURA&descargar=0&marca=1&uid=${id}`}
                value={12}
              >
                Acta de Escrutinio y Cómputo de casilla GUBERNATURA
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta?tipo=3&ver=I&uid=${id}`}
                value={13}
              >
                Acta de Escrutinio y Cómputo de casilla DIPUTADOS LOCALES
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta?tipo=4&ver=I&uid=${id}`}
                value={14}
              >
                Acta de Escrutinio y Cómputo de casilla AYUNTAMIENTO
              </MenuItem>
              <ListSubheader style={{ fontWeight: "bold", color: "black" }}>
                ACTA DE ESCRUTINIO Y CÓMPUTO DE CASILLA DE ELECCIÓN ESPECIAL
              </ListSubheader>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta?tipo=2E&ver=I&uid=${id}`}
                value={15}
              >
                Acta de Escrutinio y Cómputo de casilla especial GUBERNATURA
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta?tipo=3EMR&ver=I&uid=${id}`}
                value={16}
              >
                Acta de Escrutinio y Cómputo de casilla especial DIPUTACIONES
                LOCALES
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta?tipo=4E&ver=I&uid=${id}`}
                value={17}
              >
                Acta de Escrutinio y Cómputo de casilla especial AYUNTAMIENTO
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/pdf-acta?tipo=3ERP&ver=I&uid=${id}`}
                value={18}
              >
                Acta de Escrutinio y Cómputo de casilla especial DIPUTACIONES
                LOCALES REPRESENTACIÓN PROPORCIONAL
              </MenuItem>
              <ListSubheader style={{ fontWeight: "bold", color: "black" }}>
                ACTA DE ESCRUTINIO Y CÓMPUTO DE CASILLA LEVANTADA POR TIPO DE
                ELECCIÓN
              </ListSubheader>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/acta-escrutinio-computo-levantada-tipo-eleccion-gubernatura?nombre=7_AECLC_GUBERNATURA&descargar=0&marca=1&uid=${id}`}
                value={19}
              >
                Acta de Escrutinio y Cómputo levantada en el consejo general de
                la elección GUBERNATURA
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/acta-escrutinio-computo-levantada-tipo-eleccion-diputaciones-locales?nombre=9MR_AECLC_DIPUTACIONLOCAL&descargar=0&marca=1&uid=${id}`}
                value={20}
              >
                Acta de Escrutinio y Cómputo levantada en el consejo general de
                la elección DIPUTADOS LOCALES
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}acta-escrutinio-computo/ayuntamiento?idActa=6220&idUsuario=${id}`}
                value={21}
              >
                Acta de Escrutinio y Cómputo levantada en el consejo general de
                la elección AYUNTAMIENTO
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrl}pdf/acta-escrutinio-computo-levantada-tipo-eleccion-diputacion-local-representacion-proporcional?nombre=9RP_Diputaciones_Locales_Representacion_Proporcional&descargar=0&marca=1`}
                value={22}
              >
                Acta de Escrutinio y Cómputo levantada en el consejo general de
                la elección DIPUTACIONES LOCALES REPRESENTACIÓN PROPORCIONAL
              </MenuItem>

              <ListSubheader style={{ fontWeight: "bold", color: "black" }}>
                CONSTANCIA INDIVIDUAL DE RESULTADOS ELECTORALES POR PUNTO DE
                RECUENTO DE LA ELECCIÓN
              </ListSubheader>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}acta-escrutinio-computo-eleccion-especial/gubernatura?descargar=0&marca=1&idUsuario=${id}`}
                value={27}
              >
                Constancia individual de resultados electorales por punto de
                recuento de la elección por la GUBERNATURA
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}acta-escrutinio-computo-eleccion-especial/relativa?descargar=0&marca=1&idUsuario=${id}`}
                value={28}
              >
                Constancia individual de resultados electorales por punto de
                recuento de la elección por la DIPUTACIONES LOCALES
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}acta-escrutinio-computo-eleccion-especial/proporcional?descargar=0&marca=1&idUsuario=${id}`}
                value={28}
              >
                Constancia individual de resultados electorales por punto de
                recuento de la elección por REPRESENTACIÓN PROPORCIONAL
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}acta-escrutinio-computo-eleccion-especial/ayuntamiento?descargar=0&marca=1&idUsuario=${id}`}
                value={29}
              >
                Constancia individual de resultados electorales por punto de
                recuento de la elección por la AYUNTAMIENTO
              </MenuItem>
              <ListSubheader style={{ fontWeight: "bold", color: "black" }}>
                Acuerdos
              </ListSubheader>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}vf4-acuerdo?idUsuario=${id}`}
                value={30}
              >
                VF 4- Acuerdo CME-CDE
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}vf5-acuerdo?idUsuario=${id}`}
                value={31}
              >
                VF 5 - Acuerdo CDE-CME
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}vf6-acuerdo?idUsuario=${id}`}
                value={32}
              >
                VF 6 - Acuerdo CDE-CME
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href={`${baseUrlPdf}vf7-acuerdo?idUsuario=${id}`}
                value={33}
              >
                VF 7 - Acuerdo CDE-CME
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResultadosDetail;
