import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { IconButton, Button, Grid, Menu, MenuItem } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import CropFreeIcon from "@material-ui/icons/CropFree";
import AssignmentIcon from '@material-ui/icons/Assignment';

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { set } from "date-fns";


const Qr = ({
  dialog,
  setEditingConsejo,
  setSelectedConsejo,
}) => {
  const history = useHistory();
  const header = [];
  const [nombre, setNombre] = React.useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [idDistrito, setIdDistrito] = React.useState("");
  const [clearDistrito, setClearDistrito] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [idConsejoElectoralActa, setIdConsejoElectoralActa] = React.useState(null);
  const [nombreConsejoElectoralActa, setNombreConsejoElectoralActa] = React.useState("");
  const [nombreActa, setNombreActa] = React.useState("");
  const [qr, setQr] = React.useState("");
  const [idMunicipioBusqueda, setIdMunicipioBusqueda] = React.useState(null);
  const [idDistritoBusqueda, setIdDistritoBusqueda] = React.useState(null);
  const [tipoEleccion, setTipoEleccion] = React.useState("");
  const [ignorarMunicipio, setIgnorarMunicipio] = React.useState(null);
  const [idTipoConsejoElectoral, setIdTipoConsejoElectoral] = React.useState(null);

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const actasParamsMemo = React.useMemo(
    () => ({ idConsejoElectoral: idConsejoElectoralActa,
             idMunicipio: idMunicipioBusqueda,
             idDistrito: idDistritoBusqueda,
             ignorarMunicipio: ignorarMunicipio,
             ordenar: "idActa.desc"
             }),
    [idConsejoElectoralActa,idDistritoBusqueda,ignorarMunicipio,idTipoConsejoElectoral]
  );
  

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "consejo-electoral",
    expand: "estado,municipio,distrito,tipoConsejoElectoral",
    ordenar: "nombre.asc",
  });

  const [
    modelsActa,
    modelsLoadingActa,
    modelsErrorActa,
    modelsPageActa,
    refreshModelsActa,
    deleteModelActa,
  ] = useModels({
    name: "acta",
    expand: "tipoEleccion",
    ordenar: "idActa.asc",
    extraParams: actasParamsMemo
  });

  const setIdConsejoElectoralCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdConsejoElectoral(v.idConsejoElectoral);
    } else {
      setIdConsejoElectoral("");
    }
  }, []);


  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    //if (idDistrito > 0) params = { ...params, idDistrito };
    if (idConsejoElectoral > 0) params = { ...params, idConsejoElectoral };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
  };

  const changePage = (page) => {
    let params = {
      name: "consejo-electoral",
      expand: "estado,municipio,distrito,tipoConsejoElectoral",
      ordenar: "nombre.asc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      idConsejoElectoral,
      idDistrito,
    };
    refreshModels(true, params);
  };

  const changePageActa = (page) => {
    let params = {
      name: "acta",
      expand: "casilla.actas",
      ordenar: "idActa.asc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      idConsejoElectoral: idConsejoElectoralActa,
    };
    refreshModelsActa(true, params);
  };

 

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <>
    <TablePageLayout
      title="QR POR CONSEJOS ELECTORALES"
      model="consejos-electorales"
      history={history}
      links={[
        {
          to: "/qr",
          icon: <CropFreeIcon />,
          label: "QR",
        },
      ]}
      SearchComponents={
        <>
         
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Consejo Electoral",
              cell: (row) => <>{row.nombre ? row.nombre : ""}</>,
            },
            {
              prop: "idTipoConsejoElectoral",
              name: "Tipo Consejo Electoral",
              cell: (row) => (
                <>
                  {row.tipoConsejoElectoral
                    ? row.tipoConsejoElectoral?.nombre
                    : ""}
                </>
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  
                  <Button variant="outlined" color="primary" onClick={()=>{ 
                      if(row.tipoConsejoElectoral.idTipoConsejoElectoral == 3 || row.tipoConsejoElectoral.idTipoConsejoElectoral == 4 || row.tipoConsejoElectoral.idTipoConsejoElectoral  == 2){
                        setIgnorarMunicipio(1);
                        setIdConsejoElectoralActa(row.idConsejoElectoral); 
                        setIdMunicipioBusqueda(row.idMunicipio); 
                        setIdDistritoBusqueda(row.idDistrito); 
                      }else{
                        setIdTipoConsejoElectoral(1);
                        setIgnorarMunicipio(0);
                        setIdConsejoElectoralActa(row.idConsejoElectoral); 
                        setIdMunicipioBusqueda(null); 
                        setIdDistritoBusqueda(null);
                      }
                      setOpen(true); 
                      setNombreConsejoElectoralActa(row.nombre); 
                     }}>
                    Ver Actas
                  </Button>
                </div>
              ),
            },
          ]}
        />
      }
    />

    <Dialog
        fullWidth={true}
        maxWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">ACTAS <br/> {nombreConsejoElectoralActa}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {idConsejoElectoralActa &&(
              <TablePagination
              header={header}
              data={ modelsActa && modelsActa }
              paginatedApi
              changePageApi={changePageActa}
              count={modelsPageActa ? modelsPageActa.total : 0}
              extraRows={[
                {
                  prop: "nombreActa",
                  name: "Acta",
                  cell: (row) => <>{row.nombreCompletoActa ? row.nombreCompletoActa : ""}</>,
                },
                {
                  prop: "qr",
                  name: "QR",
                  cell: (row) => (
                    <>
                      {row.token ? row.token : ""}
                    </>
                  ),
                },
                {
                  prop: "acciones",
                  name: "Acciones",
                  cell: (row, index) => (
                    <div>
                      <Button variant="outlined" color="primary" onClick={()=>{
                        setTipoEleccion(row.tipoEleccion.nombre);
                        setQr(row.token);
                        setNombreActa(row.nombreCompletoActa);
                        handleClickOpen2();}}>
                      <CropFreeIcon style={{ paddingRight: 5 }}/> Ver QR
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
           )}
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Grid justifyContent="center" style={{ textAlign: "center"}}>
              {qr &&(
                <img
                src={`http://apps.edesarrollos.info/ekuerre/qr.png?data=${qr}`}
              />
              )}
            <DialogTitle id="max-width-dialog-title">
              {nombreActa &&(
              <div>
                <h3 >{`${nombreActa}`}</h3>
                <h4>{tipoEleccion}</h4>   
              </div>
              )}
            </DialogTitle>
          </Grid>          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      
  </>
  );
};

export default Qr;
