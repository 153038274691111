import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import PageHeader from "../../components/PageHeader";
import Loading from "../../components/FullScreenLoader";

import { useModel } from "../../hooks/useModel";
import { useQuery } from "../../hooks/useQuery";
import { useModels } from "../../hooks/useModels";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function SeccionesDetail({ match, history }) {
  const classes = useStyles();
  const query = useQuery();
  const idSeccion = query.get("idSeccion");
  const editing = !!idSeccion;

  const [nombre, setNombre] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [idDistritoFederal, setIdDistritoFederal] = React.useState("");
  const [idDistritoLocal, setIdDistritoLocal] = React.useState("");

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "seccion",
    expand: "distrito.distritosFederales,municipio",
    id: idSeccion,
    redirectOnPost: true,
  });

  const distritosParamsMemo = React.useMemo(() => ({ tipo: 1 }), []);

  const [modelDistrito, modelDistritoLoading] = useModels({
    name: "distrito",
    ordenar: "idDistrito.asc",
    limite: -1,
    extraParams: distritosParamsMemo,
  });

  const [
    modelDistritoLocal,
    modelDistritoLocalLoading,
    modelDistritoLocalError,
    modelDistritoLocalPage,
    modelDistritoLocalRefresh,
  ] = useModels({
    name: "distrito",
    expand: "distritosLocales",
    ordenar: "idDistrito.asc",
    limite: -1,
    extraParams: distritosParamsMemo,
  });

  const distritosFederalesCambio = (event) => {
    const { value } = event.target;
    let params = {};
    setIdDistritoFederal(value);
    params = { ...params, idDistrito: value };
    modelDistritoLocalRefresh(true, params);
  };

  const distritosLocalesCambio = (event) => {
    const { value } = event.target;
    setIdDistritoLocal(value);
  };

  const municipioCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
    } else {
      setIdMunicipio("");
    }
  }, []);

  const onSaveClicked = async () => {
    let body = {
      nombre,
      descripcion,
      tipo,
      idMunicipio,
      idDistrito: idDistritoLocal,
    };
    if (editing) {
      body.idSeccion = idSeccion;
    }
    body = { ...body };
    updateModel(body);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE SECCIÓN"
        model="seccion"
        editing={editing}
        links={[
          {
            to: "/secciones",
            label: "Secciones",
          },
          {
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nombre"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Descripcion"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setDescripcion(e.target.value)}
            helperText={modelError.descripcion}
            error={Boolean(modelError.descripcion)}
            inputProps={{
              value: descripcion,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Tipo"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setTipo(e.target.value)}
            helperText={modelError.tipo}
            error={Boolean(modelError.tipo)}
            inputProps={{
              value: tipo,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Distritos Federales
            </InputLabel>
            <Select
              value={idDistritoFederal}
              style={{ textAlign: "left" }}
              onChange={distritosFederalesCambio}
              label="Distritos Federales"
            >
              {modelDistrito !== null
                ? modelDistrito.map((opt, index) => (
                    <MenuItem key={index} value={opt.idDistrito}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Distritos Locales
            </InputLabel>
            <Select
              disabled={!idDistritoFederal}
              value={idDistritoLocal}
              error={Boolean(modelError.idDistrito)}
              style={{ textAlign: "left" }}
              onChange={distritosLocalesCambio}
              label="Distritos Locales"
            >
              {modelDistritoLocal &&
              modelDistritoLocal[0] &&
              modelDistritoLocal[0].distritosLocales
                ? modelDistritoLocal[0].distritosLocales.map((opt, index) => (
                    <MenuItem key={index} value={opt.idDistrito}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idDistrito ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idDistrito}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AsyncAutocompleteSearch
            label="Municipio"
            name="municipio"
            defaultValue={model && model.municipio}
            labelProp="nombre"
            error={modelError.idMunicipio ? "idMunicipio" : ""}
            campoError="idMunicipio"
            onChange={municipioCambio}
          />
          {modelError.idMunicipio ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idMunicipio}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSaveClicked}
            disabled={updateModelLoading}
            style={{ float: "right" }}
            startIcon={<SaveIcon />}
          >
            {editing ? "GUARDAR  SECCION" : "CREAR SECCION"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default SeccionesDetail;
