import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormHelperText,
  GridList,
  GridListTile,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardMedia from "@material-ui/core/CardMedia";

import Loading from "../../components/FullScreenLoader";
import CustomDialog from "../../components/CustomDialog";
import PageHeader from "../../components/PageHeader";
import CheckboxAutocomplete from "../../components/CheckboxAutocomplete";

import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import { useQuery } from "../../hooks/useQuery";
import { photoBaseUrl } from "../../utils/variables";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function CombinacionCoalicionDetail({ match, history }) {
  const classes = useStyles();
  const query = useQuery();
  const idCombinacionCoalicion = query.get("idCombinacionCoalicion");
  const editing = !!idCombinacionCoalicion;
  const [idCoalicion, setIdCoalicion] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [ruta, setRuta] = React.useState("");
  const [orden, setOrden] = React.useState("");
  const [claveCoalicion, setClaveCoalicion] = React.useState("");
  const [selectedPartido, setSelectedPartido] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "combinacion-coalicion",
    id: idCombinacionCoalicion,
    expand: "coalicion,combinacionPartidos.partidoPolitico",
    redirectOnPost: true,
  });

  const [partidosPoliticos] = useModels({ name: "partido-politico" });
  const [coaliciones] = useModels({ name: "coalicion" });
  const [recursos] = useModels({ name: "recurso" });
  const [count, setCount] = React.useState(0);

  const coalicionCambio = (event) => {
    const { value } = event.target;
    setIdCoalicion(value);
  };

  React.useEffect(() => {
    let mounted = true;
    if (!modelLoading && model && modelError && count === 0) {
      let m = model && model.combinacionPartidos;
      setIdCoalicion(model.idCoalicion);
      setNombre(model.nombre);
      setRuta(model.logo);
      setOrden(model.orden);
      setClaveCoalicion(model.claveCoalicion);
      setSelectedPartido(m && m.map((i) => i.partidoPolitico));
      setCount(1);
    }
    return () => {
      mounted = false;
    };
  }, [model, modelLoading, modelError, count]);

  const handleClose = () => {
    setIsOpen((v) => !v);
  };

  const onSaveClicked = async () => {
    let body = {
      idCoalicion,
      nombre,
      logo: ruta,
      orden,
      claveCoalicion,
      partidosPoliticos: selectedPartido,
    };
    if (editing) {
      body.idCombinacionCoalicion = idCombinacionCoalicion;
    }
    body = { ...body };
    updateModel(body);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE COMBINACIÓN COALICIÓN"
        model="combinacion-coalicion"
        editing={editing}
        links={[
          {
            to: "/combinacion-coalicion",
            label: "Combinación Coalición",
          },
          {
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Combinación Coalición
            </InputLabel>
            <Select
              value={idCoalicion}
              style={{ textAlign: "left" }}
              onChange={coalicionCambio}
              error={Boolean(modelError.idCoalicion)}
              label="Combinación Coalición"
            >
              {coaliciones !== null
                ? coaliciones.map((opt, index) => (
                    <MenuItem key={index} value={opt.idCoalicion}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idCoalicion ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idCoalicion}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nombre"
            variant="outlined"
            size="small"
            required
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Orden"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setOrden(e.target.value)}
            helperText={modelError.orden}
            error={Boolean(modelError.orden)}
            inputProps={{
              value: orden,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Clave Coalicion"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setClaveCoalicion(e.target.value)}
            helperText={modelError.claveCoalicion}
            error={Boolean(modelError.claveCoalicion)}
            inputProps={{
              value: claveCoalicion,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxAutocomplete
            label="Partidos Politícos"
            size="small"
            onChange={(_, e) => setSelectedPartido([...e])}
            value={selectedPartido || []}
            options={partidosPoliticos}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClose()}
          >
            Seleccionar Logo
          </Button>
          <CustomDialog
            isOpen={isOpen}
            handleClose={handleClose}
            title="Selección de Logo"
          >
            <GridList cellHeight={160} cols={10}>
              {recursos.map((recurso) => (
                <GridListTile key={recurso.idRecurso} cols={1}>
                  <img
                    src={`${photoBaseUrl}${recurso.ruta}`}
                    alt={recurso.nombre}
                    onClick={() => {
                      setRuta(recurso.ruta);
                      handleClose();
                    }}
                    style={{ width: "100%", height: "auto" }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </CustomDialog>
        </Grid>
        {ruta !== "" && (
          <Grid item xs={12} md={12}>
            <CardMedia
              style={{ width: "auto" }}
              component="img"
              alt="Imagen de la combinación coalición"
              height="300"
              image={`${photoBaseUrl}${ruta}`}
              title="Imagen de la combinación coalición"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSaveClicked}
            disabled={updateModelLoading}
            style={{ float: "right" }}
            startIcon={<SaveIcon />}
          >
            {editing
              ? "GUARDAR COMBINACIÓN COALICIÓN"
              : "CREAR COMBINACIÓN COALICIÓN"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default CombinacionCoalicionDetail;
