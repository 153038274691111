import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  TableContainer,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { NON_DIGIT, tipoVotos } from "../../constants/mockData";
import Loader from "../../components/Loader";
import { useQuery } from "../../hooks/useQuery";
import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";

import PageLayout from "../../components/PageLayout";
import { FindInPage, Warning } from "@material-ui/icons";
import Services from "../../services";
import TouchApp from "@material-ui/icons/TouchApp";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
    paddingBottom: "1%",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2) * -1,
    color: "#000",
  },
  successButton: {
    float: "right",
    top: 10,
    backgroundColor: "green",
    color: "white",
  },
  formControl: {
    minWidth: "100%",
  },
  table: {
    minWidth: 700,
    tableLayout: "auto",
  },
  act: { fontSize: 30 },
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  fieldText: { fontSize: 42 },
  panelEspecialText: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#76777b",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const breadcrumbs = [
  {
    to: `/lectura-de-votos`,
    icon: <FindInPage fontSize="small" />,
    label: "Lectura de votos de actas con recepción",
  },
];

const LecturaDeVotosDetail = ({ match, history }) => {
  const classes = useStyles();
  const query = useQuery();
  const idActa = query.get("a") || null;
  const actaToken = query.get("token") || null;
  const [guardarCargando, setGuardarCargando] = React.useState(false);
  const [validando, setValidando] = React.useState(false);
  const [toRecuento, setToRecuento] = React.useState(false);
  const [estatusActa, setEstatusActa] = React.useState("");

  const [votosParticipantesPoliticos, setVotosParticipantesPoliticos] =
    React.useState([]);

  const { model: acta, modeLoading: actaLoading } = useModel({
    name: "acta",
    id: idActa,
    expand:
      "participantesPoliticos,fechaLectura,estatusActa,votosGenerales,votosParticipantes,esEspecial",
  });
  const [estatusesActa, estatusesActaLoading] = useModels({
    name: "estatus-acta",
    fields: "idEstatusActa,nombre,recuento",
  });

  React.useEffect(() => {
    if (
      !actaLoading &&
      acta &&
      acta.participantesPoliticos &&
      acta.participantesPoliticos.length > 0
    ) {
      // if (acta.fechaLectura) history.push("/lectura-de-votos");
      const merged = [...acta.participantesPoliticos, ...tipoVotos];
      const conResultado = merged.map((entry) => ({
        ...entry,
        resultado: "",
      }));
      setVotosParticipantesPoliticos(conResultado);
      if (acta.fechaLectura) {
        setEstatusActa(acta?.estatusActa?.idEstatusActa || "");
        const tmp = [
          acta.votosGenerales.votosCandidatosNoRegistrados,
          acta.votosGenerales.votosNulos,
          acta.votosGenerales.votosTotales,
        ];
        const preFill = merged.map((entry, i, a) => {
          let votos = "0";
          if (acta?.votosParticipantes[i]?.votos)
            votos = acta.votosParticipantes[i].votos;
          if (tmp[i - (a.length - 3)]) votos = tmp[i - (a.length - 3)];
          return {
            ...entry,
            resultado: votos,
          };
        });
        setVotosParticipantesPoliticos([...preFill]);
      }
    }
  }, [acta, actaLoading, history]);

  React.useEffect(() => {
    if (estatusActa !== "") {
      const found = estatusesActa.find(
        (e) => e.idEstatusActa === parseInt(estatusActa)
      );
      if (found && found.recuento) {
        const merged = [...acta.participantesPoliticos, ...tipoVotos];
        const conResultado = merged.map((entry) => ({
          ...entry,
          resultado: "",
          disabled: false,
        }));
        setVotosParticipantesPoliticos(conResultado);
        setToRecuento(true);
      } else setToRecuento(false);
    }
  }, [estatusActa, estatusesActa, acta]);

  const onSaveClicked = () => {
    if (!validando) setValidando(true);
    if (
      !toRecuento &&
      votosParticipantesPoliticos.some((p) => p.resultado === "")
    ) {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    } else {
      const votosCandidatosNoRegistrados =
        votosParticipantesPoliticos[votosParticipantesPoliticos.length - 3]
          .resultado;
      const votosNulos =
        votosParticipantesPoliticos[votosParticipantesPoliticos.length - 2]
          .resultado;
      const votosTotales =
        votosParticipantesPoliticos[votosParticipantesPoliticos.length - 1]
          .resultado;
      const votos = votosParticipantesPoliticos
        .slice(0, votosParticipantesPoliticos.length - 3)
        .map((e) => e.resultado);
      const obj = {
        idActa: acta.idActa,
        idTipoActa: acta.idTipoActa,
        idEstatusActa: parseInt(estatusActa),
        votosNulos,
        votosCandidatosNoRegistrados,
        votosTotales,
        votosParticipante: votos.map((v, i) => ({
          votos: v,
          idParticipantePolitico:
            acta.participantesPoliticos[i].idParticipantePolitico,
        })),
      };
      if (estatusActa !== "" && !(acta && acta.fechaLectura)) {
        setGuardarCargando(true);
        Services.post("acta-registro-voto-participante/guardar", obj)
          .then((result) => {
            if (result) {
              history.push("/lectura-de-votos");
            }
          })
          .finally(() => setGuardarCargando(false));
      }
    }
  };

  if (estatusesActaLoading || actaLoading) return <Loader />;

  return (
    <PageLayout
      title={`LECTURA DE VOTOS DE ACTA ${acta ? acta.nombreCompletoActa : ""} ${
        acta && acta.fechaLectura ? `- FECHA LECTURA: ${acta.fechaLectura}` : ""
      }`}
      links={[
        ...breadcrumbs,
        {
          to: `/lectura-de-votos/detalle?id=${acta ? acta.idActa : null}`,
          icon: <FindInPage fontSize="small" />,
          label: `${acta ? acta.nombreCompletoActa : ""}`,
        },
      ]}
      history={history}
      SearchComponents={<></>}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Typography color="primary" className={classes.sectionTitle}>
            Estado del Acta
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <FormControl
            className={classes.formControl}
            error={validando && estatusActa === ""}
            variant="outlined"
            size="small"
            disabled={estatusesActaLoading}
          >
            <InputLabel id="plantilla-label">
              Seleccione el estado del acta
            </InputLabel>
            <Select
              labelId="plantilla-label"
              label="Seleccione el estatus del acta"
              value={estatusActa}
              onChange={(e) => setEstatusActa(e.target.value)}
              disabled={acta && acta.fechaLectura}
            >
              {estatusesActa.map((status, index) => (
                <MenuItem key={index} value={status.idEstatusActa}>
                  {status.idEstatusActa === 1
                    ? "ACTA CAPTURABLE"
                    : status.idEstatusActa === 4
                    ? "SIN ACTA"
                    : status.nombre}
                </MenuItem>
              ))}
            </Select>
            {validando && estatusActa === "" && (
              <FormHelperText>
                Favor de seleccionar el estado del acta
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {/* {processed && ( */}
          {estatusActa === "" && (
            <Grid container direction="column" alignItems="center">
              <TouchApp
                style={{ fontSize: 48, marginTop: 20, marginBottom: 20 }}
              />
              <Typography>
                Es necesario seleccionar el estado del ACTA{" "}
                {`${acta ? acta.nombreCompletoActa : ""}`}.
              </Typography>
            </Grid>
          )}
          {estatusActa !== "" && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      colSpan={6}
                      style={{ textAlign: "center", fontSize: 24 }}
                    >
                      RESULTADOS DE LA VOTACIÓN DE CASILLA:
                      {` ${acta ? acta.nombreCompletoActa : ""}`}
                      {/* <b> {model ? model.nombreCompletoActa : ""}</b> */}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      colSpan={6}
                      style={{ textAlign: "center" }}
                    >
                      Digite los votos para cada partido politico, coalición,
                      cantidatura común, candidato independiente, candidatos no
                      registrados y total de votos. <br /> En caso de no haber
                      votos en alguno de los campos, digite el número cero.
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={1}>No.</StyledTableCell>
                    <StyledTableCell colSpan={2} align="center">
                      Partido Político, Candidatura Común, Coalición o Candidato
                      Independiente
                    </StyledTableCell>
                    <StyledTableCell colSpan={3} align="center">
                      RESULTADOS DE LA VOTACIÓN
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acta &&
                  acta.participantesPoliticos &&
                  acta.participantesPoliticos.length > 0 ? (
                    [
                      ...acta.participantesPoliticos.sort((a, b) =>
                        a.orden && b.orden ? a.orden - b.orden : 1
                      ),
                      ...tipoVotos,
                    ].map((p, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell colSpan={1}>
                          {index < acta.participantesPoliticos.length
                            ? index + 1
                            : ""}
                        </StyledTableCell>
                        <StyledTableCell colSpan={1} align="center">
                          {p.logo !== "" ? (
                            <img
                              alt={p.nombre}
                              src={p.logo}
                              style={{ width: 100 }}
                            />
                          ) : (
                            <Typography className={classes.panelEspecialText}>
                              {p.nombre}
                            </Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell colSpan={4} align="right">
                          <form
                            autoComplete="off"
                            onSubmit={(e) => e.preventDefault()}
                          >
                            <TextField
                              id={index.toString()}
                              label={
                                acta.esEspecial
                                  ? "Número del 0 al 9999"
                                  : "Número del 0 al 999"
                              }
                              fullWidth
                              variant="standard"
                              required
                              type="text"
                              disabled={
                                toRecuento || (acta && acta.fechaLectura)
                              }
                              value={
                                votosParticipantesPoliticos[index]
                                  ? votosParticipantesPoliticos[index].resultado
                                  : ""
                              }
                              inputProps={{
                                className: classes.fieldText,
                              }}
                              onChange={(e) => {
                                const { value } = e.target;
                                const intValue = parseInt(
                                  value.toString().replace(NON_DIGIT, "")
                                );
                                let pEntry = isNaN(intValue) ? "" : intValue;
                                setVotosParticipantesPoliticos((v) => {
                                  let arr = [...v];
                                  if (
                                    acta.esEspecial &&
                                    arr.length > 0 &&
                                    value.length <= 3
                                  ) {
                                    arr[index].resultado = pEntry;
                                  } else if (
                                    !acta.esEspecial &&
                                    arr.length > 0 &&
                                    value.length <= 3
                                  ) {
                                    arr[index].resultado = pEntry;
                                  }
                                  return arr;
                                });
                              }}
                            />
                          </form>

                          {validando &&
                            !toRecuento &&
                            (votosParticipantesPoliticos[index]
                              ? votosParticipantesPoliticos[index].resultado
                              : "") === "" && (
                              <FormHelperText
                                className={classes.tableHelperText}
                              >
                                Favor de ingresar un número entero
                              </FormHelperText>
                            )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <div />
                  )}
                </TableBody>
              </Table>
              {(!acta.participantesPoliticos ||
                !acta.participantesPoliticos.length) && (
                <Grid container direction="column" alignItems="center">
                  <Warning
                    style={{
                      fontSize: 48,
                      marginTop: 40,
                      color: "#808080",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 18,
                      marginBottom: 40,
                      color: "#808080",
                    }}
                  >
                    Ocurrió un error al cargar el detalle de ACTA.
                  </Typography>
                </Grid>
              )}
            </TableContainer>
          )}
          {/* )} */}
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={
              guardarCargando ||
              estatusActa === "" ||
              (acta && acta.fechaLectura) ||
              (!toRecuento &&
                votosParticipantesPoliticos.some((p) => p.resultado === ""))
            }
            color="primary"
            variant="contained"
            style={{ float: "right", marginLeft: "1%" }}
            onClick={onSaveClicked}
          >
            {guardarCargando ? "CARGANDO" : "GUARDAR"}
          </Button>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default LecturaDeVotosDetail;
