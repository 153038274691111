import React from "react";
import firebase from "../firebase";

const SignOut = () => {
  React.useEffect(() => {
    firebase.auth().signOut();
  }, []);
  return <div></div>;
};

export default SignOut;
