import { Grid, Switch, Typography } from "@material-ui/core";
import { FindInPage, History, Save } from "@material-ui/icons";
import React from "react";
import AsyncAutocompleteSearch from "../components/AsyncAutocompleteSearch";
import PageLayout from "../components/PageLayout";
import opciones from "../utils/configMenuOptions";
import firebase from "../firebase";
import { useDocument } from "react-firebase-hooks/firestore";

const breadcrumbs = [
  {
    to: `/config-menu`,
    icon: <FindInPage fontSize="small" />,
    label: `Configuración: Control de acceso`,
  },
];

const snapshotOptions = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

const nombreColeccion =
  window.location.host === "2021.computosonora.com"
    ? "menu-lateral"
    : "menu-lateral-dev";

const ConfiguracionMenu = ({ history }) => {
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const refMemo = React.useMemo(
    () =>
      idConsejoElectoral && idConsejoElectoral !== ""
        ? firebase.firestore().doc(`${nombreColeccion}/${idConsejoElectoral}`)
        : undefined,
    [idConsejoElectoral]
  );
  const [doc, docLoading] = useDocument(refMemo, snapshotOptions);
  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );
  const setIdConsejoElectoralCallback = React.useCallback((e, v) => {
    if (!v) return;
    const { idConsejoElectoral } = v;
    if (idConsejoElectoral && idConsejoElectoral !== "") {
      setIdConsejoElectoral(idConsejoElectoral);
    } else {
      setIdConsejoElectoral("");
    }
  }, []);

  const onSwitchChanged = React.useCallback(
    (option, value) => {
      doc.ref.set({ [option.key]: value }, { merge: true });
    },
    [doc]
  );
  let configFirebase = {};
  if (doc && !docLoading) {
    configFirebase = doc.data() || {};
  }

  return (
    <PageLayout
      title="CONFIGURACIÓN GLOBAL DE ACCESOS A MENÚ LATERAL"
      links={breadcrumbs}
      history={history}
      SearchComponents={
        <Grid container>
          <Grid item xs={6}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
        </Grid>
      }
      loading={docLoading}
    >
      <Grid container direction="column" style={{ marginTop: 50 }}>
        {idConsejoElectoral &&
          idConsejoElectoral !== "" &&
          opciones.map((option) => (
            <Grid item xs={4}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography>
                    <strong>{option.label}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    checked={configFirebase[option.key] === true}
                    onChange={(_, value) => onSwitchChanged(option, value)}
                  />
                </Grid>
              </Grid>
              <hr />
            </Grid>
          ))}
      </Grid>
    </PageLayout>
  );
};

export default ConfiguracionMenu;
