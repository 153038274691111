import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Button, Card, Grid, Paper, Typography, Box } from "@material-ui/core";
import PageLayout from "../../components/PageLayout";
import { useHttp } from "../../hooks/useHttp";
import { emptyRequest, getRequest } from "../../constants/request";
import Loading from "../../components/FullScreenLoader";
import { Lock, LockOpen } from "@material-ui/icons";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
    paddingBottom: "1%",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2) * -1,
  },
  card: {
    height: 250,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  cardText: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#fff",
    padding: 8
  },
  icon: {fontSize: 54, color: "#fff"},
}));

const statusStore = ["Cerrada - Registrar apertura de bodega", "Registrar Apertura", "Registrar Cierre", "Registrar Apertura"];

const BodegaAdministracionDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const [modelRequest, setModelsRequest] = React.useState(emptyRequest);
  const [models, modelsLoading] = useHttp(modelRequest);
  const [status, setStatus] = useState(0);
  const [url, setUrl] = useState("")
  const {user, userLoading} = useAuth();

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      const modelReq = getRequest("bodega/verificar-bodega");
      modelReq.alert = true;
      setModelsRequest(modelReq);
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (models && models.detalle) {
      if (models.detalle.identificador === 2) {
        setUrl(`/bodegas/cierre?idConsejoElectoral=${models.detalle.idConsejoElectoral}`)
      } else {
        setUrl(`/bodegas/apertura?idConsejoElectoral=${models.detalle.idConsejoElectoral}`)
      }

      setStatus(models.detalle.identificador);
    }
  }, [models]);

  let nombreConsejo = models && models.detalle && models.detalle.consejoNombre;

  if (modelsLoading || userLoading || !user) return <Loading/>;

  const getButtonsEnvio = () => {
    const buttons = {
      1: null,
      2: <Box p={1} width={250}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            style={{
              height: 250,
              fontSize: 24,
              fontWeight: "bold",
              color: "#fff",
              backgroundColor: status === 2 ? "#279695" : "#a13800",
              textTransform: 'none'
            }}
            onClick={() => history.push("/bodegas/bitacora-de-paquetes/envio-paquetes")}
          >
            Envío Distrital
          </Button>
        </Box>,
      3: <Box p={1} width={250}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            style={{
              height: 250,
              fontSize: 24,
              fontWeight: "bold",
              color: "#fff",
              backgroundColor: status === 2 ? "#279695" : "#a13800",
              textTransform: 'none'
            }}
            onClick={() => history.push("/bodegas/bitacora-de-paquetes/envio-paquetes")}
          >
            Envío Estatal
          </Button>
        </Box>,
      4: null,
    };

    return buttons[user.consejoElectoral.idTipoConsejoElectoral];
  };

  const getButtonsRecepcion = () => {
    const buttons = {
      1: <Box p={1} width={250}>
          <Card
            className={classes.card}
            style={{backgroundColor: status === 2 ? "#279695" : "#a13800"}}
            onClick={() =>
              history.push("/bodegas/bitacora-de-paquetes/recepcion-paquetes")
            }
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.cardText}>
                  Recepción Estatal
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Box>,
      2: null,
      3: <Box p={1} width={250}>
          <Card
            className={classes.card}
            style={{backgroundColor: status === 2 ? "#279695" : "#a13800"}}
            onClick={() => history.push("/bodegas/bitacora-de-paquetes/recepcion-paquetes")}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.cardText}>
                  Recepción Distrital
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Box>,
      4: null,
    };

    return buttons[user.consejoElectoral.idTipoConsejoElectoral];
  };

  return (
    <>
      <div className={classes.root}>
        <PageLayout
          history={history}
          title="ADMINISTRACIÓN EN BODEGA"
          links={[
            {
              to: "/bodegas/administracion",
              icon: <AssignmentIcon fontSize="small"/>,
              label: "Administración",
            },
          ]}
        >
         <Grid item xs={12}>
            <Typography color="primary" className={classes.title}>
            {
              status === 2
              ?
                "ADMINISTRACIÓN DEL CIERRE DE BODEGA DEL " + nombreConsejo
              :
                "ADMINISTRACIÓN DE APERTURA DE BODEGA DEL " + nombreConsejo
            }
            </Typography>
          </Grid>
          <div style={{ width: '100%' }}>
            <Box display="flex" flexWrap="nowrap">
              <Box p={1} width={250}>
                <Card
                  className={classes.card}
                  style={{backgroundColor: status === 2 ? "#279695" : "#a13800"}}
                  onClick={() => {
                    history.push(url)
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      {
                        status === 2
                          ?
                          <LockOpen className={classes.icon} color="primary" />
                          :
                          <Lock className={classes.icon} color="primary" />
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.cardText}>
                        {statusStore[status]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
              <Box p={1} width={250} alignContent="center">
                <Card
                  className={classes.card}
                  style={{backgroundColor: status === 2 ? "#279695" : "#a13800"}}
                  onClick={() => history.push("/bodegas/administracion/bodega")}
                >
                  <Typography className={classes.cardText}>
                    Bitácora de bodegas
                  </Typography>
                </Card>
              </Box>
              <Box p={1} width={250}>
                <Card
                  className={classes.card}
                  style={{backgroundColor: status === 2 ? "#279695" : "#a13800"}}
                  onClick={() => history.push("/bodegas/bitacora-de-paquetes")}
                >
                  <Typography style={{padding: 10}} className={classes.cardText}>
                    Bitácora de paquetes
                  </Typography>
                </Card>
              </Box>
              {getButtonsRecepcion()}
              {getButtonsEnvio()}
            </Box>
          </div>
        </PageLayout>
      </div>
    </>
  );
};

export default BodegaAdministracionDetail;