import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Grid,
  Button,
  Box,
  Paper,
  makeStyles,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import PageLayout from "../../components/PageLayout";
import AlertDialog from "../../components/AlertDialog";
import TablePagination from "../../components/TablePagination";
import FullScrennLoader from "../../components/FullScreenLoader";
import TimePicker from "../../components/TimePicker";
import DatePicker from "../../components/DatePicker";
import Services from "../../services";
import { useAuth } from "../../hooks/useAuth";
import { useModels } from "../../hooks/useModels";
import { useAlert } from "../../hooks/useAlert";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import { useModel } from "../../hooks/useModel";
import AsyncCreatableAutocomplete from "../../components/AsyncCreatableAutocomplete";
import CreateEntryDialog from "../../components/CreateEntryDialog";

const request = {
  name: "acta",
  extraParams: {
    estatusRecuentoSinRectificar: "con-recuento",
  },
  expand: "motivosRecuento,actaEstatusGlobal.estatusActa",
};

const requestGT = {
  name: "grupo-trabajo",
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    width: "100%",
  },

  roo2: {
    width: "100%",
    maxWidth: 1000,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontSize: 22,
  },
  body: {
    fontSize: 18,
    color: "#000",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const header = [
  {
    prop: "nombreCompletoActa",
    name: "Paquete",
  },
];

const reqDiferencia = {
  name: "acta/reporte-diferencia-porcentual",
  id: -1,
  override: true,
};

const CalculoDeRecuento = ({ history }) => {
  const classes = useStyles();
  const [models, modelsLoading] = useModels(request);
  const [modelsGT, modelsGTLoading] = useModels(requestGT);
  const { user, userLoading } = useAuth();
  const [open, setOpen] = useState(false);
  const [sTipoRecuento, setSTipoRecuneot] = useState("");
  const [segmento, setSegmento] = useState("");
  const [total, setTotal] = useState("");
  const [recuentoTotal, setRecuentoTotal] = useState("");
  const [grupoTrabajo, setGrupoTrabajo] = useState({
    paquetesRecontar: 0,
    gruposTrabajo: 0,
    puntosRecuento: 0,
    distribucionPR: 0,
    segmento: 0,
    fechaInicio: new Date(),
    fechaFin: "",
    horaInicio: new Date().toLocaleTimeString("es-MX"),
    horaFin: "",
  });
  const [gt, setGt] = useState([]);
  const [saving, setSaving] = useState(false);
  const { showAlert } = useAlert();
  const [paquetesRecontar, setPaquetesRecontar] = useState(3);
  const [modelo, setModelo] = useState(1);
  const [borrandoGrupo, setBorrandoGrupo] = useState(false);
  const [obligatorio, setObligatorio] = useState(false);
  const [integrantesBodega, setIntegrantesBodega] = useState([]);
  const [integrantesSeguimiento, setIntegrantesSeguimiento] = useState([]);
  const [integrantesAcreditacion, setIntegrantesAcreditacion] = useState([]);
  const [todos, setTodos] = useState([]);
  const [definidos, setDefinidos] = useState([]);
  const [idGrupoTrabajo, setIdGrupoTrabajo] = useState(null);
  const [totalGT, setTotalGT] = useState([]);
  const [terminoRectificacion, setTerminoRectificacion] = useState(0);
  const [nuevaAgenda, setNuevaAgenda] = useState([]);
  const [agendaSelecionada, setAgendaSelecionada] = useState(null);

  const { model: porcentual, modelLoading: porcentualLoading } =
    useModel(reqDiferencia);

  const grupoTrabajoIntegrante = React.useMemo(
    () => ({
      name: "grupo-trabajo-integrante",
      extraParams: {
        ordenar: "idGrupoTrabajoIntegrante.asc",
        soloGenerales: true,
        idConsejoElectoral: user?.consejoElectoral?.idConsejoElectoral,
        idGrupoTrabajo: idGrupoTrabajo,
      },
      expand: "agenda",
    }),
    [idGrupoTrabajo]
  );

  const [modelsIntegrante, modelsIntegranteLoading, refreshModelsIntegrante] =
    useModels(grupoTrabajoIntegrante);

  const agendaMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda2: [2, 3, 4, 6, 9, 10].join(","),
    }),
    []
  );

  const agendaAcreditacionMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda: 13,
      sinUsuario: 2,
      idConsejoElectoral: user?.consejoElectoral?.idConsejoElectoral,
    }),
    []
  );

  const rectificar = React.useMemo(
    () => ({
      name: "grupo-trabajo/fin-rectificacion",
    }),
    [user]
  );

  const [modelsRect, modelsLoadingRect, refreshModelsRect] =
    useModels(rectificar);

  const amosay = async () => {
    const response2 = await Services.post(
      "grupo-trabajo/segmento-por-consejo",
      {
        idConsejoElectoral: user?.consejoElectoral.idConsejoElectoral,
      }
    );
    setSegmento(response2);
  };

  const integrantesDefinidos = async () => {
    const def = await Services.post("grupo-trabajo/definidos-por-grupo", {
      idConsejoElectoral: user?.consejoElectoral.idConsejoElectoral,
    });
    setDefinidos(def.resultado);
  };

  const paquetes = async () => {
    const response3 = await Services.post("grupo-trabajo/paquetes-por-consejo");
    setTotal(response3);
  };

  const paquetesTotal = async () => {
    const response34 = await Services.post(
      "grupo-trabajo/paquetes-total-por-consejo"
    );
    setRecuentoTotal(response34);
  };

  useEffect(() => {
    amosay();
    paquetes();
    paquetesTotal();
    integrantesDefinidos();
    if (user && models) {
      setSTipoRecuneot(user.consejoElectoral.sTipoRecuento);
      setGrupoTrabajo({
        ...grupoTrabajo,
        paquetesRecontar: models.length,
        gruposTrabajo: user.consejoElectoral.gruposDeTrabajo,
        puntosRecuento: user.consejoElectoral.puntosRecuento,
        distribucionPR: user.consejoElectoral.puntosRecuento,
        segmento: user.consejoElectoral.segmento,
      });
    }
  }, [user, models]);

  useEffect(() => {
    if (modelsGT && modelsGT.length) {
      setIdGrupoTrabajo(modelsGT[0].idGrupoTrabajo);
      setGrupoTrabajo({
        ...grupoTrabajo,
        fechaInicio: Date.parse(modelsGT[0].fechaInicio),
        fechaFin: Date.parse(modelsGT[0].fechaFin),
        horaInicio: new Date(modelsGT[0].fechaInicio).toLocaleTimeString(
          "es-MX"
        ),
        horaFin: new Date(modelsGT[0].fechaFin).toLocaleTimeString("es-MX"),
      });
    }
  }, [modelsGT]);

  useEffect(() => {
    let mounted = true;
    let _b = [];
    let _s = [];
    let _a = [];
    let _todos = [];

    if (mounted && modelsIntegrante) {
      let cont = 0;

      modelsIntegrante.forEach((g) => {
        _todos = [
          ..._todos,
          {
            idAgenda: g?.idAgenda,
            index: cont,
          },
        ];

        if (g.idGrupoTrabajoPuesto === 4) {
          _b = [
            ..._b,
            {
              idAgenda: g?.idAgenda,
              idGrupoTrabajoPuesto: 4,
              nombre: g?.nombre,
              apellidoPaterno: g?.apellidoPaterno,
              apellidoMaterno: g?.apellidoMaterno,
              index: cont,
            },
          ];
          cont++;
        } else if (g.idGrupoTrabajoPuesto === 10) {
          _s = [
            ..._s,
            {
              idAgenda: g?.idAgenda,
              idGrupoTrabajoPuesto: 10,
              nombre: g?.nombre,
              apellidoPaterno: g?.apellidoPaterno,
              apellidoMaterno: g?.apellidoMaterno,
              index: cont,
            },
          ];
          cont++;
        } else if (g.idGrupoTrabajoPuesto === 6) {
          _a = [
            ..._a,
            {
              idAgenda: g?.idAgenda,
              idGrupoTrabajoPuesto: 6,
              nombre: g?.nombre,
              apellidoPaterno: g?.apellidoPaterno,
              apellidoMaterno: g?.apellidoMaterno,
              index: cont,
            },
          ];
          cont++;
        }
      });

      setTodos(_todos);
      setIntegrantesBodega(_b);
      setIntegrantesSeguimiento(_s);
      setIntegrantesAcreditacion(_a);
    }
  }, [modelsIntegrante]);

  const crearGrupos = async () => {
    try {
      if (saving) return;

      setSaving(true);

      const fechaI = new Date();
      const fechaF = new Date(
        new Date(fechaI).setHours(
          new Date(fechaI).getHours() + segmento?.resultado[0]?.segmentos / 2
        )
      );

      const data = {
        idConsejoElectoral: user.consejoElectoral.idConsejoElectoral,
        numeroPaquetes: total && total.resultado[0],
        fechaInicio: fechaI,
        fechaFin: fechaF,
      };

      const response = await Services.post(
        "grupo-trabajo/iniciar-recuento",
        data
      );

      showAlert({
        severity: "success",
        message: response.mensaje,
      });

      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  const iniciarRecuentoTotal = async () => {
    try {
      if (saving) return;

      setSaving(true);
      const fechaI = new Date();
      const fechaF = new Date(
        new Date(fechaI).setHours(
          new Date(fechaI).getHours() + segmento?.resultado[0]?.segmentos / 2
        )
      );

      const data = {
        idConsejoElectoral: user?.consejoElectoral?.idConsejoElectoral,
        total: recuentoTotal && recuentoTotal?.resultado[0],
        fechaInicio: fechaI,
        fechaFin: fechaF,
      };

      const response = await Services.post(
        "grupo-trabajo/iniciar-recuento-total",
        data
      );

      showAlert({
        severity: "success",
        message: response.mensaje,
      });

      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  const iniciarRecuentoPleno = async () => {
    try {
      if (saving) return;

      setSaving(true);

      const data = {
        idConsejoElectoral: user?.consejoElectoral?.idConsejoElectoral,
      };

      const response = await Services.post(
        "grupo-trabajo/iniciar-recuento-pleno",
        data
      );

      showAlert({
        severity: "success",
        message: response.mensaje,
      });

      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const reiniciarRecuento = async () => {
    try {
      if (borrandoGrupo) return;

      setBorrandoGrupo(true);

      const response = await Services.delete(
        "grupo-trabajo/borrar-grupo-por-consejo",
        {
          idConsejoElectoral: user.consejoElectoral.idConsejoElectoral,
        }
      );

      showAlert({
        severity: "success",
        message: response.mensaje,
      });

      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setBorrandoGrupo(false);
    }
  };

  const guardar = async () => {
    try {
      await Services.post("grupo-trabajo-integrante/guardar2", {
        integrantesBodega: integrantesBodega,
        integrantesSeguimiento: integrantesSeguimiento,
        integrantesAcreditacion: integrantesAcreditacion,
        idConsejoElectoral: user.consejoElectoral.idConsejoElectoral,
      });

      showAlert({
        severity: "success",
        message: "Integrantes Generales guardados con éxito.",
      });

      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const total_GT = React.useCallback(async () => {
    const responseGT = await Services.post(
      "grupo-trabajo/total-actas-por-grupo",
      {
        idConsejoElectoral: user?.consejoElectoral.idConsejoElectoral,
      }
    );
    setTotalGT(responseGT);
  }, [user]);

  if (userLoading || modelsLoading || modelsGTLoading || modelsLoadingRect)
    return <FullScrennLoader />;

  return (
    <PageLayout
      title="Cálculo de Recuento"
      history={history}
      links={[
        {
          to: "/grupos-trabajo",
          icon: <GroupWorkIcon />,
          label: "Grupos-Trabajo",
        },
      ]}
    >
      {modelsRect && modelsRect.length && modelsRect[0] == 1 && (
        <>
          <Grid item xs={12} style={{ marginTop: -20 }}>
            {total &&
              total?.resultado[0] === 0 &&
              porcentual &&
              porcentual.diferenciaPorcentual > 1 && (
                <Typography
                  variant="h5"
                  style={{ color: "black", paddingTop: "0px" }}
                >
                  No se han detectado Paquetes para Recuento.
                </Typography>
              )}

            {total &&
              total?.resultado[0] === 0 &&
              porcentual &&
              porcentual.diferenciaPorcentual <= 1 && (
                <Typography
                  variant="h5"
                  style={{ color: "black", paddingTop: "0px" }}
                >
                  Al presentarse una diferencia porcentual menor o igual al 1%
                  del total de los votos, será necesario que este Consejo
                  Electoral realice un recuento total de
                  <Typography variant="b" style={{ color: "red" }}>
                    &nbsp; {recuentoTotal && recuentoTotal.resultado[0]} &nbsp;
                  </Typography>
                  paquetes para la elección que le corresponde.
                </Typography>
              )}

            {total &&
              total?.resultado[0] >= 1 &&
              total?.resultado[0] <= 20 &&
              porcentual &&
              porcentual.diferenciaPorcentual > 1 && (
                <Typography variant="h5" style={{ color: "black" }}>
                  Se ha detectado
                  <Typography variant="b" style={{ color: "red" }}>
                    &nbsp; {total && total.resultado[0]} &nbsp;
                  </Typography>
                  Paquetes para Recuento, el Cómputo se llevará a cabo en el
                  Pleno.
                </Typography>
              )}

            {total &&
              porcentual &&
              porcentual.diferenciaPorcentual <= 1 &&
              total?.resultado[0] == recuentoTotal?.resultado[0] &&
              recuentoTotal?.resultado[0] <= 20 && (
                <Typography variant="h5" style={{ color: "black" }}>
                  Se ha detectado
                  <Typography variant="b" style={{ color: "red" }}>
                    &nbsp; {total && total.resultado[0]} &nbsp;
                  </Typography>
                  Paquetes para Recuento, el Cómputo se llevará a cabo en el
                  Pleno.
                </Typography>
              )}

            {total &&
              total?.resultado[0] >= 1 &&
              total?.resultado[0] <= 20 &&
              porcentual &&
              porcentual.diferenciaPorcentual <= 1 &&
              total?.resultado[0] != recuentoTotal?.resultado[0] && (
                <Typography variant="h5" style={{ color: "black" }}>
                  Al presentarse una diferencia porcentual menor o igual al 1%
                  del total de los votos, será necesario que este Consejo
                  Electoral realice un recuento total de
                  <Typography variant="b" style={{ color: "red" }}>
                    &nbsp; {recuentoTotal && recuentoTotal.resultado[0]} &nbsp;
                  </Typography>
                  paquetes para la elección que le corresponde.
                </Typography>
              )}

            {total &&
              total?.resultado[0] > 20 &&
              porcentual &&
              porcentual.diferenciaPorcentual > 1 && (
                <div>
                  <hr />
                  {modelsGT.length ? (
                    <>
                      <Typography variant="h5" style={{ color: "black" }}>
                        Se ha detectado
                        <Typography variant="b" style={{ color: "red" }}>
                          &nbsp; {total && total.resultado[0]} &nbsp;
                        </Typography>
                        Paquetes para Recuento, se definen a continuación sus
                        <Typography variant="b" style={{ color: "red" }}>
                          &nbsp;
                          {modelsGT && modelsGT.length > 0 && modelsGT.length}
                          &nbsp;
                        </Typography>
                        Grupos de Trabajo y
                        <Typography variant="b" style={{ color: "red" }}>
                          &nbsp;
                          {modelsGT &&
                            modelsGT[0] &&
                            modelsGT[0].numeroPuntosRecuento}
                          &nbsp;
                        </Typography>
                        Puntos De Recuento
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="h5" style={{ color: "black" }}>
                      Se ha detectado
                      <Typography variant="b" style={{ color: "red" }}>
                        &nbsp; {total && total.resultado[0]} &nbsp;
                      </Typography>
                      Paquetes para Recuento, se definen a continuación sus
                      <Typography variant="b" style={{ color: "red" }}>
                        &nbsp;
                        {modelsGT && modelsGT.length > 0 && modelsGT.length}
                        &nbsp;
                      </Typography>
                      Grupos de Trabajo y
                      <Typography variant="b" style={{ color: "red" }}>
                        &nbsp;
                        {modelsGT &&
                          modelsGT[0] &&
                          modelsGT[0].numeroPuntosRecuento}
                        &nbsp;
                      </Typography>
                      Puntos De Recuento
                    </Typography>
                  )}
                </div>
              )}

            {total &&
              total?.resultado[0] > 20 &&
              porcentual &&
              porcentual.diferenciaPorcentual <= 1 && (
                <div>
                  <hr />
                  {modelsGT.length ? (
                    <>
                      <Typography variant="h5" style={{ color: "black" }}>
                        Se ha detectado
                        <Typography variant="b" style={{ color: "red" }}>
                          &nbsp; {total && total.resultado[0]} &nbsp;
                        </Typography>
                        Paquetes para Recuento, se definen a continuación sus
                        <Typography variant="b" style={{ color: "red" }}>
                          &nbsp;
                          {modelsGT && modelsGT.length > 0 && modelsGT.length}
                          &nbsp;
                        </Typography>
                        Grupos de Trabajo y
                        <Typography variant="b" style={{ color: "red" }}>
                          &nbsp;
                          {modelsGT &&
                            modelsGT[0] &&
                            modelsGT[0].numeroPuntosRecuento}
                          &nbsp;
                        </Typography>
                        Puntos De Recuento
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="h5" style={{ color: "black" }}>
                        Al presentarse una diferencia porcentual menor o igual
                        al 1% del total de los votos, será necesario que este
                        Consejo Electoral realice un recuento total de
                        <Typography variant="b" style={{ color: "red" }}>
                          &nbsp; {recuentoTotal && recuentoTotal.resultado[0]}{" "}
                          &nbsp;
                        </Typography>
                        paquetes para la elección que le corresponde.
                      </Typography>
                    </>
                  )}
                </div>
              )}
          </Grid>

          <br />
          {modelsGT && modelsGT.length ? (
            <Grid
              component={Paper}
              container
              spacing={3}
              className={classes.paper}
              style={{ marginTop: 10 }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  color="secondary"
                  className={classes.sectionTitle}
                >
                  <div style={{ fontSize: 20 }}>INTEGRANTES GENERALES</div>
                </Typography>
              </Grid>
              {modelsIntegrante &&
                modelsGT[0] &&
                modelsIntegrante.length &&
                modelsIntegrante.map((int, i) => {
                  return (
                    <Grid item xs={12}>
                      {int.idGrupoTrabajoPuesto === 6 && (
                        <AsyncAutocompleteSearch
                          label={int.nombrePuesto}
                          name="agenda"
                          labelProp="nombreCompleto"
                          onChange={(index, e, v) => {
                            if (v && int.idGrupoTrabajoPuesto === 6) {
                              setIntegrantesAcreditacion((ac) =>
                                ac.map((acreditacion) =>
                                  acreditacion.index === i
                                    ? {
                                        ...acreditacion,
                                        nombre: v.nombre,
                                        apellidoPaterno: v.apellidoPaterno,
                                        apellidoMaterno: v.apellidoMaterno,
                                        idAgenda: v.idAgenda,
                                        idGrupoTrabajoPuesto: 6,
                                      }
                                    : acreditacion
                                )
                              );
                              setTodos((t) =>
                                t.map((todos) =>
                                  todos.index === i
                                    ? {
                                        ...todos,
                                        idAgenda: v.idAgenda,
                                      }
                                    : todos
                                )
                              );
                            }
                          }}
                          extraParams={agendaAcreditacionMemo}
                          index={i}
                          ignoreIds={todos
                            .filter((a) => a.idAgenda)
                            .map((a) => a.idAgenda)}
                          propId="idAgenda"
                          defaultValue={int?.agenda}
                          idConsejoElectoral
                        />
                        // <AsyncCreatableAutocomplete
                        //   label={int.nombrePuesto}
                        //   name="agenda"
                        //   labelProp="nombreCompleto"
                        //   onChange={(index,v) => {
                        //     if (v && int.idGrupoTrabajoPuesto === 6) {
                        //       setIntegrantesAcreditacion((ac) =>
                        //         ac.map((acreditacion) =>
                        //             acreditacion.index === i
                        //             ?
                        //             {
                        //               ...acreditacion,
                        //               nombre: v.nombre,
                        //               apellidoPaterno: v.apellidoPaterno,
                        //               apellidoMaterno: v.apellidoMaterno,
                        //               idAgenda: v.idAgenda,
                        //               idGrupoTrabajoPuesto: 6,
                        //             }
                        //             : acreditacion
                        //         )
                        //       );
                        //       setTodos((t) =>
                        //         t.map((todos) =>
                        //           todos.index === i
                        //             ?
                        //             {
                        //               ...todos,
                        //               idAgenda: v.idAgenda,
                        //             }
                        //             : todos
                        //         )
                        //       );
                        //     }
                        //   }}
                        //   extraParams={agendaAcreditacionMemo}
                        //   //clearInput={asistentes[i].idTipoAgenda}
                        //   defaultValue={
                        //     integrantesAcreditacion &&
                        //     integrantesAcreditacion.some((p) => p.index === i) &&
                        //     integrantesAcreditacion.find((p) => p.index === i)?.agenda
                        //     ? integrantesAcreditacion.find((p) => p.index === i).agenda
                        //     : int?.agenda
                        //   }
                        //   propId="idAgenda"
                        //   ignoreIds={todos.filter((a) => a.idAgenda).map((a) => a.idAgenda)}
                        // />
                      )}
                      {int.idGrupoTrabajoPuesto === 4 && (
                        <AsyncCreatableAutocomplete
                          label={int.nombrePuesto}
                          name="agenda"
                          labelProp="nombreCompleto"
                          onChange={(index, v) => {
                            if (v && int.idGrupoTrabajoPuesto === 4) {
                              setIntegrantesBodega((bod) =>
                                bod.map((bodega) =>
                                  bodega.index === i
                                    ? {
                                        ...bodega,
                                        nombre: v.nombre,
                                        apellidoPaterno: v.apellidoPaterno,
                                        apellidoMaterno: v.apellidoMaterno,
                                        idAgenda: v.idAgenda,
                                        idGrupoTrabajoPuesto: 4,
                                      }
                                    : bodega
                                )
                              );
                              setTodos((t) =>
                                t.map((todos) =>
                                  todos.index === i
                                    ? {
                                        ...todos,
                                        idAgenda: v.idAgenda,
                                      }
                                    : todos
                                )
                              );
                            }
                          }}
                          extraParams={agendaMemo}
                          //clearInput={asistentes[i].idTipoAgenda}
                          dialogForm={CreateEntryDialog}
                          //defaultValue={int?.agenda}
                          defaultValue={
                            integrantesBodega &&
                            integrantesBodega.some((p) => p.index === i) &&
                            integrantesBodega.find((p) => p.index === i)?.agenda
                              ? integrantesBodega.find((p) => p.index === i)
                                  .agenda
                              : int?.agenda
                          }
                          dialogProps={{
                            setArr: setIntegrantesBodega,
                            setEntry: (entry) => {
                              setIntegrantesBodega((v) =>
                                v.map((a) =>
                                  a.index === i
                                    ? {
                                        ...a,
                                        nombre: entry.nombre,
                                        apellidoPaterno: entry.apellidoPaterno,
                                        apellidoMaterno: entry.apellidoMaterno,
                                        idAgenda: entry.idAgenda,
                                        idGrupoTrabajoPuesto: 4,
                                        agenda: {
                                          nombreCompleto: `${entry.puesto} - ${entry.nombre} ${entry.apellidoPaterno} ${entry.apellidoMaterno}`,
                                        },
                                      }
                                    : a
                                )
                              );
                            },
                            idConsejoElectoral:
                              user?.consejoElectoral?.idConsejoElectoral,
                            ignoreIds: [0, 1, 5, 7, 8, 11, 12, 13, 14],
                            tipoAgenda: {
                              idTipoAgenda: [2, 3, 4, 6, 9, 10],
                              tipoAgenda: "",
                            },
                          }}
                          propId="idAgenda"
                          ignoreIds={todos
                            .filter((a) => a.idAgenda)
                            .map((a) => a.idAgenda)}
                        />
                      )}
                      {int.idGrupoTrabajoPuesto === 10 && (
                        <AsyncCreatableAutocomplete
                          label={int.nombrePuesto}
                          name="agenda"
                          labelProp="nombreCompleto"
                          onChange={(index, v) => {
                            if (v && int.idGrupoTrabajoPuesto === 10) {
                              setIntegrantesSeguimiento((seg) =>
                                seg.map((seguimiento) =>
                                  seguimiento.index === i
                                    ? {
                                        ...seguimiento,
                                        nombre: v.nombre,
                                        apellidoPaterno: v.apellidoPaterno,
                                        apellidoMaterno: v.apellidoMaterno,
                                        idAgenda: v.idAgenda,
                                        idGrupoTrabajoPuesto: 10,
                                      }
                                    : seguimiento
                                )
                              );
                              setTodos((t) =>
                                t.map((todos) =>
                                  todos.index === i
                                    ? {
                                        ...todos,
                                        idAgenda: v.idAgenda,
                                      }
                                    : todos
                                )
                              );
                            }
                          }}
                          extraParams={agendaMemo}
                          //clearInput={asistentes[i].idTipoAgenda}
                          dialogForm={CreateEntryDialog}
                          //defaultValue={int?.agenda}
                          defaultValue={
                            integrantesSeguimiento &&
                            integrantesSeguimiento.some((p) => p.index === i) &&
                            integrantesSeguimiento.find((p) => p.index === i)
                              ?.agenda
                              ? integrantesSeguimiento.find(
                                  (p) => p.index === i
                                ).agenda
                              : int?.agenda
                          }
                          dialogProps={{
                            setArr: setIntegrantesSeguimiento,
                            setEntry: (entry) => {
                              setIntegrantesSeguimiento((v) =>
                                v.map((a) =>
                                  a.index === i
                                    ? {
                                        ...a,
                                        nombre: entry.nombre,
                                        apellidoPaterno: entry.apellidoPaterno,
                                        apellidoMaterno: entry.apellidoMaterno,
                                        idAgenda: entry.idAgenda,
                                        idGrupoTrabajoPuesto: 10,
                                        agenda: {
                                          nombreCompleto: `${entry.puesto} - ${entry.nombre} ${entry.apellidoPaterno} ${entry.apellidoMaterno}`,
                                        },
                                      }
                                    : a
                                )
                              );
                            },
                            idConsejoElectoral:
                              user?.consejoElectoral?.idConsejoElectoral,
                            ignoreIds: [0, 1, 5, 7, 8, 11, 12, 13, 14],
                            tipoAgenda: {
                              idTipoAgenda: [2, 3, 4, 6, 9, 10],
                              tipoAgenda: "",
                            },
                          }}
                          propId="idAgenda"
                          ignoreIds={todos
                            .filter((a) => a.idAgenda)
                            .map((a) => a.idAgenda)}
                        />
                        // <AsyncAutocompleteSearch
                        //   label={int.nombrePuesto}
                        //   name="agenda"
                        //   labelProp="nombreCompleto"
                        //   onChange={(index, e, v) => {
                        //     if (v && int.idGrupoTrabajoPuesto === 10) {
                        //       setIntegrantesSeguimiento((seg) =>
                        //         seg.map((seguimiento) =>
                        //           seguimiento.index === i
                        //             ?
                        //             {
                        //               ...seguimiento,
                        //               nombre: v.nombre,
                        //               apellidoPaterno: v.apellidoPaterno,
                        //               apellidoMaterno: v.apellidoMaterno,
                        //               idAgenda: v.idAgenda,
                        //               idGrupoTrabajoPuesto: 4,
                        //             }
                        //             : seguimiento
                        //         )
                        //       );
                        //       setTodos((t) =>
                        //         t.map((todos) =>
                        //           todos.index === i
                        //             ?
                        //             {
                        //               ...todos,
                        //               idAgenda: v.idAgenda,
                        //             }
                        //             : todos
                        //         )
                        //       );
                        //     }
                        //   }}
                        //   extraParams={agendaMemo}
                        //   index={i}
                        //   ignoreIds={todos
                        //     .filter((a) => a.idAgenda)
                        //     .map((a) => a.idAgenda)}
                        //   propId="idAgenda"
                        //   defaultValue={int?.agenda}
                        // />
                      )}
                    </Grid>
                  );
                })}
              <Grid item xs={12}>
                <Box display="flex" p={1}>
                  <Box flexGrow={1}></Box>
                  <Box mr={1}></Box>
                  <Box>
                    <Button
                      variant="contained"
                      size="middle"
                      color="secondary"
                      onClick={async () => {
                        await guardar();
                      }}
                    >
                      GUARDAR INTEGRANTES
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {modelsGT.length ? <br /> : ""}

          <div>
            {modelsGT && modelsGT.length ? (
              <div>
                <Grid
                  component={Paper}
                  container
                  spacing={3}
                  className={classes.paper}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      color="secondary"
                      className={classes.sectionTitle}
                    >
                      <div style={{ fontSize: 20 }}>GRUPOS DE TRABAJO</div>
                    </Typography>
                  </Grid>
                  <Grid container>
                    {modelsGT.map((g, i) => (
                      <Grid item xs={12} lg={4} style={{ padding: "10px" }}>
                        <Grid
                          container
                          component="a"
                          href={`/grupos-trabajo/grupos/integrantes?id=${g.idGrupoTrabajo}&n=${g.numeroGrupoTrabajo}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Card className={classes.root}>
                            <CardActionArea>
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  Grupo de Trabajo {g.numeroGrupoTrabajo}
                                  <br />
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Grid container justify="flex-end">
                                  <Button
                                    size="small"
                                    color="primary"
                                    style={{ color: "white" }}
                                  >
                                    {definidos?.map((d, ind) =>
                                      d.idGrupo === g.idGrupoTrabajo ? (
                                        <div>
                                          Por Definir: {d.falta} - Definidos:{" "}
                                          {d.total}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </Button>
                                </Grid>
                              </CardActions>
                            </CardActionArea>
                          </Card>
                          <div>
                            <br></br>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      color="secondary"
                      className={classes.sectionTitle}
                    >
                      <div style={{ fontSize: 20 }}></div>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ) : (
              ""
            )}
          </div>

          {recuentoTotal &&
          recuentoTotal.resultado[0] > 0 &&
          recuentoTotal.resultado[0] <= 20 &&
          total &&
          total?.resultado[0] <= 20 &&
          porcentual &&
          porcentual?.diferenciaPorcentual <= 1 &&
          total?.resultado[0] != recuentoTotal?.resultado[0] ? (
            <div>
              <hr />
              {modelsGT.length ? (
                ""
              ) : (
                <Grid
                  component={Paper}
                  container
                  spacing={3}
                  className={classes.paper}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table" size="small">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={2}>
                              <Typography>
                                <strong>
                                  Reporte de Diferencia Porcentual
                                </strong>
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell>Primer Lugar</StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.primerLugar} votos
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>Segundo Lugar</StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.segundoLugar} votos
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>
                              Total de Votos de Todos los Candidatos
                            </StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.totalVotos} votos
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>
                              Diferencia entre Primer y Segundo Lugar
                            </StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.diferencia} votos
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>
                              Diferencia porcentual
                            </StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.diferenciaPorcentual}%
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <>
                      <Box display="flex" justifyContent="left"></Box>

                      <Box
                        display="flex"
                        justifyContent="left"
                        style={{ marginTop: 20 }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          Iniciar Recuento Total En Pleno
                        </Button>
                      </Box>
                    </>
                  </Grid>
                </Grid>
              )}
            </div>
          ) : null}

          {recuentoTotal &&
          recuentoTotal.resultado[0] > 20 &&
          porcentual &&
          porcentual?.diferenciaPorcentual <= 1 ? (
            <div>
              <hr />
              {modelsGT.length ? (
                ""
              ) : (
                <Grid
                  component={Paper}
                  container
                  spacing={3}
                  className={classes.paper}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table" size="small">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={2}>
                              <Typography>
                                <strong>
                                  Reporte de Diferencia Porcentual
                                </strong>
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell>Primer Lugar</StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.primerLugar} votos
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>Segundo Lugar</StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.segundoLugar} votos
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>
                              Total de Votos de Todos los Candidatos
                            </StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.totalVotos} votos
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>
                              Diferencia entre Primer y Segundo Lugar
                            </StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.diferencia} votos
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>
                              Diferencia porcentual
                            </StyledTableCell>
                            <StyledTableCell>
                              {porcentual?.diferenciaPorcentual}%
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <>
                      <Box display="flex" justifyContent="left"></Box>

                      <Box
                        display="flex"
                        justifyContent="left"
                        style={{ marginTop: 20 }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          Iniciar Recuento Total
                        </Button>
                      </Box>
                    </>
                  </Grid>
                </Grid>
              )}
            </div>
          ) : null}

          {total &&
          total.resultado[0] > 20 &&
          porcentual &&
          porcentual?.diferenciaPorcentual > 1 ? (
            <div>
              <hr />
              {modelsGT.length ? (
                ""
              ) : (
                <>
                  <Box display="flex" justifyContent="left">
                    <Typography algin="left" color="secondary">
                      Al iniciar el cálculo de Grupos de Trabajo, el Consejo
                      inciará su modalidad de Recuento con Grupos de Trabajo.
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="left"
                    style={{ marginTop: 20 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={user && user.idTipoAgenda !== 6}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Iniciar Recuento
                    </Button>
                  </Box>
                </>
              )}
            </div>
          ) : null}

          {recuentoTotal &&
            recuentoTotal.resultado[0] > 20 &&
            porcentual &&
            porcentual?.diferenciaPorcentual <= 1 && (
              <AlertDialog
                open={open}
                onClose={() => setOpen(false)}
                title="Iniciar Recuento Total"
                content="Se hará el registro de Grupos de trabajo y Puntos de Recuento​
              Para los Paquetes Electorales. Esta acción no podrá cambiarse​ 
              Una vez iniciada. ¿Desea iniciar el Recuento Total?"
                onAccept={iniciarRecuentoTotal}
                loading={saving}
              />
            )}

          {recuentoTotal &&
            recuentoTotal.resultado[0] > 0 &&
            recuentoTotal.resultado[0] <= 20 &&
            porcentual &&
            porcentual?.diferenciaPorcentual <= 1 && (
              <AlertDialog
                open={open}
                onClose={() => setOpen(false)}
                title="Iniciar Recuento Total"
                content="Se iniciará el Recuento​ Total 
              Para los Paquetes Electorales. Esta acción no podrá cambiarse​ 
              Una vez iniciada. ¿Desea iniciar el Recuento Total?"
                onAccept={iniciarRecuentoPleno}
                loading={saving}
              />
            )}

          {total &&
            total.resultado[0] > 20 &&
            porcentual &&
            porcentual?.diferenciaPorcentual > 1 && (
              <AlertDialog
                open={open}
                onClose={() => setOpen(false)}
                title="Iniciar Recuento"
                content="Se hará el registro de Grupos de trabajo y Puntos de Recuento​ 
              Para los Paquetes Electorales. Esta acción no podrá cambiarse​ 
              Una vez iniciada. ¿Desea iniciar el Recuento?"
                onAccept={crearGrupos}
                loading={saving}
              />
            )}
        </>
      )}
      {modelsRect && modelsRect.length && modelsRect[0] == 2 && (
        <>
          <Grid item xs={12} style={{ marginTop: -20 }}>
            {total && total?.resultado[0] === 0 && (
              <Typography
                variant="h5"
                style={{ color: "black", paddingTop: "0px" }}
              >
                No se han detectado Paquetes para Recuento. Debe terminar la
                Rectificación para continuar.
              </Typography>
            )}
            {total && total?.resultado[0] > 0 && total?.resultado[0] <= 20 && (
              <Typography
                variant="h5"
                style={{ color: "black", paddingTop: "0px" }}
              >
                Para continuar su trabajo en Pleno, primero debe terminar la
                Rectificación.
              </Typography>
            )}
            {total && total?.resultado[0] > 20 && (
              <Typography
                variant="h5"
                style={{ color: "black", paddingTop: "0px" }}
              >
                No se pueden crear Grupos de Trabajo hasta que finalice el
                proceso de Rectificación.
              </Typography>
            )}
            <Box display="flex" justifyContent="left"></Box>

            <Box display="flex" justifyContent="left" style={{ marginTop: 20 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  window.location = "/rectificacion";
                }}
              >
                Ir a Rectificación
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </PageLayout>
  );
};

export default CalculoDeRecuento;
