import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Typography, Checkbox } from "@material-ui/core";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Save } from "@material-ui/icons";

import PageHeader from "../../components/PageHeader";
import FullScreenLoader from "../../components/FullScreenLoader";

import { useModel } from "../../hooks/useModel";
import { useQuery } from "../../hooks/useQuery";
import services from "../../services";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
  centerItems: {
    display: "flex",
    alignItems: "center",
  },
}));

const permisos = [
  { name: "Actas - Lectura", prop: "actaLectura" },
  { name: "Actas - Datos Generales", prop: "actaDatosGenerales" },
  { name: "Actas - Resultados", prop: "actaResultados" },
  {
    name: "Reunión de Trabajo - Rectificación",
    prop: "reunionTrabajoRectificacion",
  },
  { name: "Reunión de Trabajo - Avance", prop: "reunionTrabajoAvance" },
  { name: "Reunión de Trabajo - Reportes", prop: "reunionTrabajoReportes" },
  { name: "Cómputo - Cotejo", prop: "computoCotejo" },
  { name: "Cómputo - Recuento", prop: "computoRecuento" },
  { name: "Cómputo - Resultados Votación", prop: "computoResultadosVotacion" },
  { name: "Paquetes Recibidos", prop: "paquetesRecibidos" },
  {
    name: "Grupo de Trabajo - Representantes",
    prop: "gruposDeTrabajoRepresentantes",
  },
  { name: "Sesión Extraordinaria", prop: "sesionExtraordinaria" },
  { name: "Cómputo - Recuento GT", prop: "computoRecuentoGt" },
  { name: "Cómputo - Recuento GT Res", prop: "computoRecuentoGtRes" },
  { name: "Control Capacitación", prop: "controlCapacitacion" },
  { name: "Lectura Estatal", prop: "lecturaEstatal" },
  { name: "Recepción Estatal", prop: "recepcionEstatal" },
  { name: "Bodega - Administración", prop: "bodegaAdministracion" },
  { name: "Bodega - Paquetes", prop: "bodegaPaquetes" },
  { name: "Bodega - Bitácora", prop: "bodegaBitacora" },
  { name: "Paquetes - Recepciones", prop: "paquetesRecepciones" },
  {
    name: "Estadísticos - Avance Recepción de Paquetes",
    prop: "estadisticaAvanceRecepcion",
  },
  {
    name: "Estadísticos - Reporte de Causales Aritméticas",
    prop: "estadisticaCausalesAritmeticas",
  },
  {
    name: "Estadísticos - Avance de Bodega Electoral",
    prop: "estadisticaAvanceBodega",
  },
  {
    name: "Estadísticos - Reporte Clausura",
    prop: "estadisticaReportesClausura",
  },
  { name: "Estadísticos - Cotejo/Recuento", prop: "estadisticaCotejoRecuento" },
  {
    name: "Estadísticos - Informe Recuento",
    prop: "estadisticaInformeRecuento",
  },
  {
    name: "Estadísticos - Estadísticos Lectura de Votos",
    prop: "estadisticaLecturaVotos",
  },
  {
    name: "Estadísticos - Estadísticos Información Complementaria",
    prop: "estadisticaInfoComplementaria",
  },
  {
    name: "Estadísticos - Monitor Elección",
    prop: "estadisticaMonitorEleccion",
  },
  {
    name: "Estadísticos - Estadísticos de Grupos de Trabajo",
    prop: "estadisticaGT",
  },
  {
    name: "Estadísticos - Estadísticos de Conteo Preliminar",
    prop: "estadisticaPreliminar",
  },
  {
    name: "Estadísticos - Estadísticos Globales",
    prop: "estadisticaGlobal",
  },
  {
    name: "Estadísticos - Avance Lectura Acta",
    prop: "estadisticaLecturaCarrusel",
  },
  {
    name: "Estadísticos - Avance Lectura Acta Gubernatura",
    prop: "estadisticaLecturaCarruselGub",
  },
  {
    name: "Estadísticos - Recepcion Carrusel",
    prop: "estadisticaRecepcionCarrusel",
  },
  {
    name: "Estadísticos - Recepcion Carrusel Gubernatura",
    prop: "estadisticaRecepcionCarruselGub",
  },
  // {
  //   name: "Estadísticos - Reportes Clausura",
  //   prop: "estadisticaReportesClausura",
  // },
  // {
  //   name: "Estadísticos - Informe Recuento",
  //   prop: "estadisticaInformeRecuento",
  // },
  // {
  //   name: "Estadísticos - Cotejo Recuento",
  //   prop: "estadisticaCotejoRecuento",
  // },
  // {
  //   name: "Estadísticos - Monitor Eleccion",
  //   prop: "estadisticaMonitorEleccion",
  // },
  {
    name: "Estadísticos - Bitacora",
    prop: "estadisticaBitacoraCarrusel",
  },
  {
    name: "Estadísticos - Bitacora Gubernatura",
    prop: "estadisticaBitacoraCarruselGub",
  },
  {
    name: "Catalogos - Partidos Politicos",
    prop: "catalogoPartidoPolitico",
  },
  {
    name: "Catalogos - Candidatos",
    prop: "catalogoCandidato",
  },
  {
    name: "Catalogos - Coaliciones",
    prop: "catalogoCoalicion",
  },
  {
    name: "Catalogos - Secciones",
    prop: "catalogoSeccion",
  },
  {
    name: "Catalogos - Distritos",
    prop: "catalogoDistrito",
  },
  {
    name: "Usuarios",
    prop: "usuario",
  },
  {
    name: "Agendas",
    prop: "agenda",
  },
  {
    name: "Bodegas",
    prop: "bodega",
  },
  {
    name: "Estados",
    prop: "estado",
  },
  {
    name: "Municipio",
    prop: "municipio",
  },
  {
    name: "Consejos Electorales",
    prop: "consejoElectoral",
  },
  {
    name: "Tipo Casilla",
    prop: "tipoCasilla",
  },
  {
    name: "Tipo Acta",
    prop: "tipoActa",
  },
  {
    name: "Tipo Elección",
    prop: "tipoEleccion",
  },
  {
    name: "Casillas",
    prop: "casilla",
  },
  {
    name: "Motivos Apertura Y Cierre",
    prop: "motivoAperturaCierre",
  },
  {
    name: "Tipo Domicilio",
    prop: "tipoDomicilio",
  },
  {
    name: "Soporte TI",
    prop: "soporteTI",
  },
  {
    name: "Capacitaciones",
    prop: "capacitacion",
  },
  {
    name: "Archivo de Excel",
    prop: "archivoExcel",
  },
  {
    name: "Archivo Recurso",
    prop: "archivoRecurso",
  },
  {
    name: "Paquetes Recibidos",
    prop: "paquetesRecibidos",
  },
  {
    name: "Panel de Control",
    prop: "panelControl",
  },
  {
    name: "Control de Menu Lateral",
    prop: "controlMenuLateral",
  },
];

function ConsejoElectoralPermiso({
  match,
  history,
  dialog,
  editingConsejoElectoral,
  setConsejoElectoralSearch,
}) {
  const classes = useStyles();
  const query = useQuery();
  const idConsejoElectoral = query.get("id") || undefined;
  const editing = !!idConsejoElectoral;
  const [usuarios, setUsuarios] = React.useState([]);

  const consejoElectoralRequestMemo = React.useMemo(
    () =>
      editing
        ? {
            name: "consejo-electoral",
            expand:
              "estado,municipio,distrito,tipoConsejoElectoral,usuarios,usuarios.tipoAgenda,usuarios.usuario,usuarios.usuario.permiso",
            id: idConsejoElectoral,
            redirectOnPost: true,
          }
        : {},
    [editing, idConsejoElectoral]
  );

  const {
    model,
    modelLoading,
    modelError,
    refreshModel,
    updateModel,
    updateModelLoading,
  } = useModel(consejoElectoralRequestMemo);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      const members = model.usuarios.filter((m) => m.usuario);

      setUsuarios(members);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSaveClicked = () => {
    const body = usuarios.map((usuario, index) => ({
      idUsuario: usuario.usuario.idUsuario,
      permiso: usuario.usuario.permiso,
    }));
    if (editing) {
      body.idConsejoElectoral = idConsejoElectoral;
    }

    services.post("usuario/guardar-multi", body).then((res) => {
      if (res) {
        history.push("/consejos-electorales");
      }
    });
  };

  const setAllPermissions = (val, permissions) => {
    let tmpPermissions = {};

    let availablePermissions = permisos.map((p) => [[p.prop], val ? 1 : 0]);

    availablePermissions.forEach((perm) => (tmpPermissions[perm[0]] = perm[1]));

    return {
      ...permissions,
      ...tmpPermissions,
    };
  };

  if (editing && !model) return <FullScreenLoader />;

  return (
    <>
      <PageHeader
        history={history}
        title={`CONSEJO ELECTORAL - ${model.nombre}`}
        editing={editing}
        dialog={dialog}
        links={[
          {
            to: "/consejos-electorales",
            icon: <AssignmentReturnedIcon />,
            label: "Consejos-Electorales",
          },
          {
            to: "/consejos-electorales/nuevo",
            icon: <ListAltIcon />,
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />
      <Grid component={Paper} container spacing={3} className={classes.paper}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Typography>
                <strong>
                  {usuarios.length >= 1
                    ? "USUARIOS DEL CONSEJO"
                    : "NO HAY USUARIOS EN EL CONSEJO"}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    Todos
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={2} className={classes.centerItems}>
                      <Checkbox
                        onChange={(e, v) =>
                          setUsuarios((mmbrs) =>
                            mmbrs.map((membr) => ({
                              ...membr,
                              usuario: {
                                ...membr.usuario,
                                permiso: setAllPermissions(
                                  v,
                                  membr.usuario.permiso
                                ),
                              },
                            }))
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.centerItems}>
                      <Typography>Todos</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {permisos.map((p) => (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={2} className={classes.centerItems}>
                            <Checkbox
                              onChange={(e, v) =>
                                setUsuarios((mmbrs) =>
                                  mmbrs.map((membr) => ({
                                    ...membr,
                                    usuario: {
                                      ...membr.usuario,
                                      permiso: {
                                        ...membr.usuario.permiso,
                                        [p.prop]: v ? 1 : 0,
                                      },
                                    },
                                  }))
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={10} className={classes.centerItems}>
                            <Typography> {p.name} </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {usuarios.map((m, i) => (
                <Grid
                  container
                  spacing={2}
                  key={i}
                  style={{ padding: 10, marginBottom: 20 }}
                >
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                      Usuario
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      {m.nombreCompleto}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      {permisos.map((p) => (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={2} className={classes.centerItems}>
                              <Checkbox
                                value={m?.usuario?.permiso[p.prop]}
                                checked={m?.usuario?.permiso[p.prop]}
                                onChange={() => {
                                  setUsuarios((mmbrs) =>
                                    mmbrs.map((miemb, ind) =>
                                      i === ind
                                        ? {
                                            ...miemb,
                                            usuario: {
                                              ...miemb.usuario,
                                              permiso: {
                                                ...miemb.usuario.permiso,
                                                [p.prop]: miemb.usuario.permiso[
                                                  p.prop
                                                ]
                                                  ? 0
                                                  : 1,
                                              },
                                            },
                                          }
                                        : { ...miemb }
                                    )
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={10} className={classes.centerItems}>
                              <Typography>{p.name}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSaveClicked}
              >
                {editing ? "EDITAR" : "CREAR"} CONSEJO ELECTORAL
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ConsejoElectoralPermiso;
