import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import LandscapeIcon from "@material-ui/icons/Landscape";

const Estados = ({
  dialog,
  setEditingEstado,
  setSelectedEstado,
  handleClose,
}) => {
  const history = useHistory();
  const header = [];
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "estado",
    ordenar: "idEstado.desc",
  });

  const [nombre, setNombre] = useState("");
  const [abreviacion, setAbreviacion] = useState("");
  const [clave, setClave] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();

  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    if (clave.length > 0) params = { ...params, clave };
    if (abreviacion.length > 0) params = { ...params, abreviacion };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar este estado?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        await deleteModel(row.idEstado);
        await refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };
  const changePage = (page) => {
    let params = {
      name: "estado",
      ordenar: "idEstado.desc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      nombre,
      clave,
      abreviacion,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="ESTADOS"
      actionButton={{
        to: "estados/nuevo",
        icon: <AddIcon />,
        label: "Nuevo Estado",
      }}
      links={[
        {
          to: "/estados",
          icon: <LandscapeIcon />,
          label: "Estados",
        },
      ]}
      history={history}
      SearchComponents={
        <>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Nombre"
              onChange={(event) => setNombre(event.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Abreviación"
              onChange={(event) => setAbreviacion(event.target.value)}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Clave"
              onChange={(event) => setClave(event.target.value)}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre",
              cell: (row, index) => <div>{`${row.nombre}`}</div>,
            },
            {
              prop: "abreviacion",
              name: "Abreviación",
              cell: (row, index) => <div>{`${row.abreviacion}`}</div>,
            },
            {
              prop: "clave",
              name: "Clave",
              cell: (row, index) => <div>{`${row.clave}`}</div>,
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(`/estados/detalle?id=${row.idEstado}`)
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Estados;
