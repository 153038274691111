import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";

import { tipoPuesto } from "../constants/mockData";
import AsyncSelect from "./AsyncSelect";
import SingleSelect from "./SingleSelect";

import services from "../services";

const CreateEntryDialog = ({
  onCloseClicked,
  onSubmitted,
  idConsejoElectoral,
  tipoAgenda,
  setArr,
  setEntry,
  index,
  ignoreIds,
  ...rest
}) => {
  const [responseError, setResponseError] = React.useState(null);
  const [nombre, setNombre] = React.useState("");
  const [apellidoPaterno, setApellidoPaterno] = React.useState("");
  const [apellidoMaterno, setApellidoMaterno] = React.useState("");
  const [idTipoAgenda, setIdTipoAgenda] = React.useState("");
  const [puesto, setPuesto] = React.useState("");
  const [opcionesPuesto, setOpcionesPuesto] = React.useState([]);

  const defaultV = React.useMemo(
    () =>
      tipoAgenda ? { ...tipoAgenda, nombre: tipoAgenda.tipoAgenda } : null,
    [tipoAgenda]
  );

  React.useEffect(() => {
    const tipo = !defaultV || (defaultV && ignoreIds.includes(defaultV.idTipoAgenda))
      ? ""
      : defaultV.idTipoAgenda;

    if (defaultV) {
      setIdTipoAgenda(tipo);
      setOpcionesPuesto(tipoPuesto[tipo]);
    }
  }, [defaultV, setIdTipoAgenda, ignoreIds]);

  const setIdTipoAgendaCallback = React.useCallback(
    (e) => {
      setIdTipoAgenda(e.target.value.id);
      setPuesto(
        tipoPuesto[e.target.value.id].length === 1
          ? tipoPuesto[e.target.value.id][0].nombre
          : ""
      );
      setOpcionesPuesto((v) => tipoPuesto[e.target.value.id]);
    },
    [setIdTipoAgenda]
  );

  const onSubmitPressed = () => {
    let hasErrors = false;
    let errors = {};
    if(nombre.replace(/ /g, '') === '') {
      errors['nombre'] = "Nombre no puede estar vacío";
      hasErrors = true;
    }
    if(apellidoPaterno.replace(/ /g, '') === '') {
      errors['apellidoPaterno'] = "Apellido Paterno no puede estar vacío.";
      hasErrors = true;
    }
    if(apellidoMaterno.replace(/ /g, '') === '') {
      errors['apellidoMaterno'] = "Apellido Paterno no puede estar vacío.";
      hasErrors = true;
    }
    if(puesto.replace(/ /g, '') === '') {
      errors['puesto'] = "Puesto no puede estar vacío.";
      hasErrors = true;
    }
    if(hasErrors) {
      setResponseError(errors);
      return;
    }
    const agenda = {
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      idTipoAgenda,
      puesto,
      idConsejoElectoral,
    };
    services.post("agenda/guardar", agenda).then((res) => {
      if (res) {
        if (
          Boolean(agenda.nombre) &&
          Boolean(agenda.apellidoPaterno) &&
          Boolean(agenda.apellidoMaterno) &&
          Boolean(idConsejoElectoral) &&
          Boolean(idTipoAgenda) &&
          Boolean(puesto)
        ) {
          if (!isNaN(index)) {
            setArr((v) =>
              v.map((a, i) =>
                index === i
                  ? {
                      ...a,
                      idAgenda: res.detalle.idAgenda,
                      idTipoAgenda: res.detalle.idTipoAgenda,
                      tipoAgenda: res.detalle.tipoAgenda,
                      entry: res.detalle,
                    }
                  : a
              )
            );
          } else if (setEntry) {
            setEntry(res.detalle);
          }
          onCloseClicked();
        } else {
          setResponseError(res.errores);
        }
      }
    });
  };

  return (
    <form onSubmit={(_) => onSubmitted({})}>
      <DialogTitle>Agregar nueva persona</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AsyncSelect
              label="Tipo"
              name="agenda/tipo-agenda"
              labelProp="nombre"
              defaultValue={
                !defaultV || (defaultV && ignoreIds.includes(defaultV.idTipoAgenda)) ? null : defaultV
              }
              onChange={setIdTipoAgendaCallback}
              ignoreIds={ignoreIds ? ignoreIds : []}
              propId="id"
              helperText={responseError && responseError.idTipoAgenda}
              error={Boolean(responseError && responseError.idTipoAgenda)}
            />
          </Grid>
          <Grid item xs={6}>
            <SingleSelect
              label="Puesto"
              value={puesto}
              options={opcionesPuesto}
              onChange={(e) => setPuesto(e.target.value.nombre)}
              helperText={responseError && responseError.puesto}
              error={Boolean(responseError && responseError.puesto)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              margin="dense"
              name="nombre"
              placeholder="Nombre(s)"
              label="Nombre(s)"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              type="text"
              helperText={responseError && responseError.nombre}
              error={Boolean(responseError && responseError.nombre)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              required
              name="apellidoPaterno"
              label="Apellido paterno"
              placeholder="Apellido paterno"
              value={apellidoPaterno}
              onChange={(e) => setApellidoPaterno(e.target.value)}
              type="text"
              helperText={responseError && responseError.apellidoPaterno}
              error={Boolean(responseError && responseError.apellidoPaterno)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              variant="outlined"
              margin="dense"
              name="apellidoMaterno"
              placeholder="Apellido materno"
              label="Apellido materno"
              value={apellidoMaterno}
              onChange={(e) => setApellidoMaterno(e.target.value)}
              type="text"
              helperText={responseError && responseError.apellidoMaterno}
              error={Boolean(responseError && responseError.apellidoMaterno)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClicked} color="primary">
          Cancelar
        </Button>
        <Button onClick={onSubmitPressed} color="primary">
          Agregar
        </Button>
      </DialogActions>
    </form>
  );
};

export default CreateEntryDialog;
