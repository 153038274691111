import React, { useEffect, useState } from "react";

import { Grid, Paper } from "@material-ui/core";

import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import { generateDefaultChartOptions } from "../../utils";
import Services from "../../services";

import PageLayout from "../../components/PageLayout";
import LoadingF from "../../components/FullScreenLoader";
import TablePagination from "../../components/TablePagination";

import { useQuery } from "../../hooks/useQuery";

highcharts3d(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

const header = [
  {
    prop: "nombre",
    name: "Consejo",
  },
  {
    prop: "esperados",
    name: "Esperados",
  },
  {
    prop: "almacenados",
    name: "Almacenados",
  },
  {
    prop: "recibidos",
    name: "Recibo",
  },
  {
    prop: "envioDistrital",
    name: "Enviados a distrito",
  },
];

const PaquetesAvanceDetail = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  const idConsejoSeleccionado = query.get("id");
  const [counts, setCounts] = useState(null);

  useEffect(() => {
    let mounted = true;

    Services.get(
      `acta/obtener-actas-estadistica?idConsejoElectoral=${idConsejoSeleccionado}`
    )
      .then((response) => {
        if (mounted) setCounts(response);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        if (mounted) setLoading(false);
      });

    return () => (mounted = false);
  }, [idConsejoSeleccionado]);

  let detalle = counts && counts.respuesta.length > 0 ? counts.respuesta : [];
  let recibidos =
    counts && counts.respuesta.length > 0
      ? counts.respuesta.map((d) => [d.nombre, d.recibidos])
      : [];
  let esperados =
    counts && counts.respuesta.length > 0
      ? counts.respuesta.map((d) => [d.nombre, d.esperados])
      : [];

  let chartOptions = generateDefaultChartOptions("column", {
    title: `RECEPCIÓN POR CONSEJO: ${
      detalle.length > 0 ? detalle[0].nombre : ""
    }`,
    subtitle: " ",
    inverted: true,
  });

  const extraOptions = {
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0,
        pointWidth: 40,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      headerFormat:
        '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} Actas</b><br/>',
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -10,
        allowOverlap: true,
        style: {
          wordBreak: "break-all",
          textOverflow: "allow",
        },
      },
      categories: function () {
        var value = this.value,
          output;

        detalle.forEach(function (consejo) {
          if (consejo.name === value) {
            output = consejo.name;
          }
        });

        return output;
      },
    },
    yAxis: [
      {
        title: {
          text: "Actas Totales y a Recuento",
        },
        showFirstLabel: false,
      },
    ],
    series: [
      {
        color: "rgb(158, 159, 163)",
        pointPlacement: -0.2,
        linkedTo: "main",
        data: getData(esperados),
        name: "Total",
      },
      {
        name: "Recuento",
        id: "main",
        dataSorting: {
          enabled: true,
          matchByName: true,
        },
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: "16px",
            },
          },
        ],
        color: "#a13800",
        data: getData(recibidos),
      },
    ],
    exporting: {
      allowHTML: true,
    },
  };

  chartOptions = {
    ...chartOptions,
    ...extraOptions,
  };

  function getData(data) {
    return data.map(function (consejo, i) {
      return {
        name: consejo[0],
        y: consejo[1],
      };
    });
  }

  if (loading) return <LoadingF />;

  return (
    <PageLayout
      title="AVANCE EN RECEPCIÓN POR CONSEJO"
      history={history}
      links={[
        {
          to: "/estadisticos/avance-recepcion-paquetes",
          label: "Avance de Recepción de Paquetes",
        },
        {
          to: `/estadisticos/avance-recepcion-paquetes/detalle?id=${idConsejoSeleccionado}`,
          label: "Detalle",
        },
      ]}
    >
      <Grid container component={Paper} spacing={3}>
        <Grid item xs={12}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Grid>
        <Grid item xs={12}>
          <TablePagination header={header} data={detalle} />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default PaquetesAvanceDetail;
