import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button, Grid, Paper, TextField, Typography, GridList, GridListTile, Card 
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CardMedia from "@material-ui/core/CardMedia";
import Loading from "../../components/FullScreenLoader";
import CheckboxAutocomplete from "../../components/CheckboxAutocomplete";
import CustomDialog from "../../components/CustomDialog";
import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import { useQuery } from "../../hooks/useQuery";
import PageHeader from "../../components/PageHeader";
import { photoBaseUrl } from "../../utils/variables";
import { useAlert } from "../../hooks/useAlert";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  input: {
    display: "none",
  },
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

function CandidatruaComunDetail({ match, history }) {
  const classes = useStyles();
  const query = useQuery();
  const {showAlert} = useAlert();
  const idCandidaturaComun = query.get("idCandidaturaComun");
  const editing = !!idCandidaturaComun;
  const [isOpen, setIsOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState("");
  const [nombreAcuerdo, setNombreAcuerdo] = React.useState("");
  const [fechaAcuerdo, setFechaAcuerdo] = React.useState(new Date());
  const [ruta, setRuta] = React.useState("");
  const [claveCandidaturaComun, setClaveCandidaturaComun] = React.useState("");
  const [orden, setOrden] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [selectedPartido, setSelectedPartido] = React.useState([]);
  const [selectedPartidoIds, setSelectedPartidoIds] = React.useState([]);
  const [candidaturaComunSedePorcentajePartido, setCandidaturaComunSedePorcentajePartido] = useState([]);

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "candidatura-comun",
    id: idCandidaturaComun,
    expand: "candidaturaComunPartidos.partidoPolitico,candidaturaComunSedePorcentajePartido",
    redirectOnPost: true,
  });

  const [partidosPoliticos] = useModels({ name: "partido-politico" });
  const [recursos] = useModels({ name: "recurso" });

  React.useEffect(() => {
    let mounted = true;

    if (!modelLoading && model && modelError && count === 0 && mounted) {
      let m = model && model.candidaturaComunPartidos;
      setNombre(model.nombre);
      setNombreAcuerdo(model.nombreAcuerdo);
      setFechaAcuerdo(model.fechaAcuerdo);
      setRuta(model.logo);
      setClaveCandidaturaComun(model.claveCandidaturaComun);
      setOrden(model.orden);
      setSelectedPartido(m && m.map((i) => ({...i.partidoPolitico, partidoViejo: true})));
      setSelectedPartidoIds(m.map((i) => i.partidoPolitico.idPartidoPolitico));
      setCandidaturaComunSedePorcentajePartido(model.candidaturaComunSedePorcentajePartido.map(cp => ({...cp, porcentaje: parseInt(cp.porcentaje)})))
      setCount(1);
    }
    return () => {
      mounted = false;
    };
  }, [model, modelLoading, modelError, count]);

  const handleClose = () => {
    setIsOpen((v) => !v);
  };

  const onSaveClicked = async () => {
    const percentages = candidaturaComunSedePorcentajePartido.map(cp => cp.porcentaje);
    const total = percentages.reduce((total, num) => total + Math.round(num), 0);

    if(percentages.length && total != 100) {
      showAlert({
        message: "La suma de los porcentajes debe ser 100%.",
        severity: "warning",
      });

      return;
    }

    let body = {
      nombre,
      nombreAcuerdo,
      fechAcuerdo: fechaAcuerdo,
      logo: ruta,
      claveCandidaturaComun,
      orden,
      partidosPoliticos: selectedPartido,
      candidaturaComunSedePorcentajePartido: candidaturaComunSedePorcentajePartido
    };

    if (editing) {
      body.idCandidaturaComun = idCandidaturaComun;
    }

    updateModel(body);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE CANDIDATURA COMÚN"
        model="candidatura-comun"
        editing={editing}
        links={[
          {
            to: "/candidatura-comun",
            label: "Candidatura Común",
          },
          {
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nombre"
            variant="outlined"
            size="small"
            required
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Acuerdo"
            size="small"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setNombreAcuerdo(e.target.value)}
            helperText={modelError.nombreAcuerdo}
            error={Boolean(modelError.nombreAcuerdo)}
            inputProps={{
              value: nombreAcuerdo,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
         {/*  <DatePicker
            label="Fecha Acuerdo"
            value={fechaAcuerdo}
            onChange={setFechaAcuerdoCallback}
          /> */}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Clave Candidatura Común"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setClaveCandidaturaComun(e.target.value)}
            helperText={modelError.claveCandidaturaComun}
            error={Boolean(modelError.claveCandidaturaComun)}
            inputProps={{
              value: claveCandidaturaComun,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Orden"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setOrden(e.target.value)}
            helperText={modelError.orden}
            error={Boolean(modelError.orden)}
            inputProps={{
              value: orden,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxAutocomplete
            label="Partidos Politicos"
            size="small"
            onChange={(_, e) => {
              const idsPartidosPoliticos = e.map(e => e.idPartidoPolitico);

              setCandidaturaComunSedePorcentajePartido(candidaturaComunSedePorcentajePartido.filter(cp => 
                selectedPartidoIds.includes(cp.idPartidoPolitico) && 
                idsPartidosPoliticos.includes(cp.idPartidoPolitico)
              ));

              setSelectedPartido([...e]);
            }}
            value={selectedPartido || []}
            options={partidosPoliticos}
          />
        </Grid>
        
        <Typography style={{color: "black"}}><b>Porcentajes</b></Typography>
        <div style={{width: "100%", padding: 10}}>
          <Grid container spacing={2}>
          {
            model && candidaturaComunSedePorcentajePartido.length
            ?
              candidaturaComunSedePorcentajePartido.map((cp, index) => { 
                const partidoPolitico = (model.candidaturaComunPartidos.find(cc => cc.idPartidoPolitico === cp.idPartidoPolitico)).partidoPolitico;
                
                return (
                  <Grid key={index} item md={2} xl={2} xs={6} sm={6}>
                    <div style={{height: 60}}>
                      <Typography>{partidoPolitico.nombre}</Typography>
                    </div>
                    <TextField
                      type="number"
                      size="small"
                      variant="outlined"
                      onChange={(event) => 
                        setCandidaturaComunSedePorcentajePartido(c => 
                          c.map(_cp => _cp.idPartidoPolitico === cp.idPartidoPolitico ? {..._cp, porcentaje: parseInt(event.target.value)} : _cp)
                        )
                      }
                      error={Boolean(modelError.orden)}
                      value={cp.porcentaje}
                    />
                  </Grid>
                )})
            :
              null
          }
          </Grid>
        </div>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => handleClose()}
          >
            Seleccionar Logo
          </Button>
          <CustomDialog
            isOpen={isOpen}
            handleClose={handleClose}
            title="Selección de Logo"
          >
            <GridList cellHeight={160} className={classes.gridList} cols={10}>
              {recursos.map((recurso) => (
                <GridListTile key={recurso.idRecurso} cols={1}>
                  <img
                    src={`${photoBaseUrl}${recurso.ruta}`}
                    alt={recurso.nombre}
                    onClick={() => {
                      setRuta(recurso.ruta);
                      handleClose();
                    }}
                    style={{ width: "100%", height: "auto" }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </CustomDialog>
        </Grid>
        {ruta !== "" && (
          <Grid item xs={12} md={12}>
            <CardMedia
              style={{ width: "auto" }}
              component="img"
              alt="Imagen de la candidatura común"
              height="300"
              image={`${photoBaseUrl}${ruta}`}
              title="Imagen de la candidatura común"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveClicked}
            disabled={updateModelLoading}
            className={classes.button}
            style={{ float: "right" }}
            startIcon={<SaveIcon />}
          >
            {editing ? "GUARDAR CANDIDATURA COMÚN" : "CREAR CANDIDATURA COMÚN"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default CandidatruaComunDetail;
