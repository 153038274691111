import React from "react";
import {
  Paper,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PageLayout from "../../components/PageLayout";
import TablePagination from "../../components/TablePagination";
import { format } from 'date-fns';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
    paddingBottom: "1%",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  saveButtonContainer: { marginTop: theme.spacing(4) },
  topic: { fontSize: 28 },
  card: {
    height: 250,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  icon: { fontSize: 54, color: "#fff" },
  formControl: {
    minWidth: "100%",
  },
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  tableText: {
    fontSize: 18,
    color: "#000",
  },
  subtitle: { fontWeight: "bold" },
  tableButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
  },
  buttonIcon: { color: "#fff", fontWeight: "bold" },
  tableIcon: { fontSize: 42 },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  body: {
    color: theme.palette.primary.main,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TablePackagesPagination() {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      component="div"
      count={100}
      page={page}
      onChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}

const header = [
  {
    prop: "consejo",
    name: "Consejo",
  },
  {
    prop: "motivoApertura",
    name: "Motivo Apertura",
  },
];

const AdministracionPaqueteDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const [packages] = React.useState([
    {
      consejo: "CONSEJO MUNICIPAL DE AGUA PRIETA",
      fechaApertura: "2020-09-24 17:06:00",
      motivoApertura: "Recepción de Documentación Electoral",
      fechaCierre: "",
    },
    {
      consejo: "CONSEJO MUNICIPAL DE AGUA PRIETA",
      fechaApertura: "2020-09-24 17:06:00",
      motivoApertura: "Recepción de Documentación Electoral",
      fechaCierre: "2020-09-24 17:06:00",
    },
  ]);

  return (
    <>
      <div className={classes.root}>
      <PageLayout 
        title="LISTADO DE APERTURAS Y CIERRES DE BODEGA ELECTORAL" 
        history={history}
        links={[
          {
            to: "/bodegas/administracion",
            icon: <AssignmentIcon />,
            label: "Administracion",
          },
          {
            to: "/bodegas/administracion/paquete",
            icon: <ListIcon />,
            label: "Bodega",
          }
        ]}
      >
        <Grid
          component={Paper}
          container
          spacing={3}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12}>
            <TablePagination 
              header={header}
              data={packages}
              extraRows={[
                {
                  prop: "fechaApertura",
                  name: "Fecha Apertura",
                  cell: (row) => <> { format(new Date(row.fechaApertura), "dd-MM-yyyy hh:mm") } </>
                },
                {
                  prop: "fechaCierre",
                  name: "Fecha Cierre",
                  cell: (row) => <> { row.fechaCierre ? format(new Date(row.fechaCierre), "dd-MM-yyyy hh:mm") : "Sin fecha de cierre." }   </>
                }
              ]}
            />
          </Grid>
        </Grid>
        </PageLayout>
      </div>
    </>
  );
};

export default AdministracionPaqueteDetail;
