import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { IconButton, Button, Grid, Menu, MenuItem } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { CheckBox } from "@material-ui/icons";

const Consejo = ({
  dialog,
  setEditingConsejo,
  setSelectedConsejo,
  handleClose,
}) => {
  const history = useHistory();
  const header = [];
  const [nombre, setNombre] = React.useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [idDistrito, setIdDistrito] = React.useState("");
  const [clearDistrito, setClearDistrito] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const distritosParamsMemo = React.useMemo(
    () => ({ tipo: 2, ordenar: "nombre.asc" }),
    []
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "consejo-electoral",
    expand: "estado,municipio,distrito,tipoConsejoElectoral",
    ordenar: "nombre.asc",
  });

  const setIdConsejoElectoralCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdConsejoElectoral(v.idConsejoElectoral);
    } else {
      setIdConsejoElectoral("");
    }
  }, []);

  const setIdDistritoCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdDistrito(v.idDistrito);
    } else {
      setIdDistrito("");
    }
  }, []);

  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    //if (idDistrito > 0) params = { ...params, idDistrito };
    if (idConsejoElectoral > 0) params = { ...params, idConsejoElectoral };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar este consejo electoral?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        await deleteModel(row.idConsejoElectoral);
        await refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "consejo-electoral",
      expand: "estado,municipio,distrito,tipoConsejoElectoral",
      ordenar: "nombre.asc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      idConsejoElectoral,
      idDistrito,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="CONSEJOS ELECTORALES"
      model="consejos-electorales"
      history={history}
      actionButton={{
        to: "consejos-electorales/nuevo",
        icon: <AddIcon />,
        label: "Nuevo Consejo Electoral",
      }}
      links={[
        {
          to: "/consejos-electorales",
          icon: <AssignmentReturnedIcon />,
          label: "Consejos-Electorales",
        },
      ]}
      SearchComponents={
        <>
          {/* <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Distrito"
              name="distrito"
              labelProp="nombre"
              onChange={setIdDistritoCallback}
              clearInput={clearDistrito}
              extraParams={distritosParamsMemo}
            />
          </Grid> */}
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="small"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Consejo Electoral",
              cell: (row) => <>{row.nombre ? row.nombre : ""}</>,
            },
            {
              prop: "idTipoConsejoElectoral",
              name: "Tipo Consejo Electoral",
              cell: (row) => (
                <>
                  {row.tipoConsejoElectoral
                    ? row.tipoConsejoElectoral?.nombre
                    : ""}
                </>
              ),
            },
            {
              prop: "nombreDistrito",
              name: "Distrito INE",
              cell: (row) => <>{row.distrito && row.distrito.nombre}</>,
            },
            {
              prop: "nombreMunicipio",
              name: "Municipio",
              cell: (row) => <>{row.municipio ? row.municipio.nombre : ""}</>,
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/consejos-electorales/detalle?id=${row.idConsejoElectoral}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/consejos-electorales/permisos?id=${row.idConsejoElectoral}`
                        )
                      }
                    >
                      <CheckBox style={{ paddingRight: 5 }} />
                      Permisos
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Consejo;
