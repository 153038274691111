import React from "react";
import {
  Card,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import * as histogram from "highcharts/modules/histogram-bellcurve";
import { generateDefaultChartOptions } from "../utils";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PageLayout from "../components/PageLayout";
import Loading from "../components/FullScreenLoader";
import firebase from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";

highcharts3d(Highcharts);
histogram(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}
const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 34,
  },
}));

const defaultChartOptions = generateDefaultChartOptions("column");

const LecturaVirtual = ({ history }) => {
  const classes = useStyles();
  const collectionRef = firebase.firestore().collection("lectura-virtual");
  const query = collectionRef.orderBy("fecha", "desc").limit(1);
  const [collection, collectionLoading] = useCollectionData(query, {
    snapshotListenOptions: { includeMetadataChanges: false },
  });

  const formatterTotalCallback = React.useCallback(
    (obj) => {
      if (!collection) return "";
      return (
        '<span><img src="' +
        collection[0].total[obj.pos].logo +
        '" style="width: 40px; height: 40px;"/><br></span>' +
        `${collection[0].total[obj.pos].porcentaje.toFixed(2)} %`
      );
    },
    [collection]
  );

  const totalMemo = React.useMemo(
    () =>
      collection
        ? collection[0]?.total?.map((c) => ({
            name: c.nombre,
            y: c.votos,
            logo: c.logo,
          }))
        : [],
    [collection]
  );

  const extraOptionsTotal = React.useMemo(() => {
    return {
      ...defaultChartOptions,
      title: {
        text: "",
        style: {
          fontSize: 18,
        },
        align: "left",
      },
      legend: {
        enabled: false,
      },
      subtitle: {
        text: `TOTAL DE VOTOS <strong>${collection && collection[0]?.totalVotos}</strong> <br> COMPUTADAS <strong>${collection && collection[0]?.computadas}</strong> <br> POR COMPUTAR <strong>${collection && collection[0]?.porComputar}</strong>`,
        style: {
          color: "#000",
          fontSize: '18pt',
        },
        align: "left",
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: " ",
        },
      },
      xAxis: {
        type: "category",
        labels: {
          useHTML: true,
          animate: true,
          formatter: formatterTotalCallback,
        },
      },
      series: [
        {
          dataLabels: [
            {
              enabled: true,
              inside: true,
              style: {
                fontSize: "22px",
              },
            },
          ],
          colors: ["#cd5d23"],
          colorByPoint: true,
          name: "Resultados Preliminares",
          data: totalMemo,
        },
      ],
      exporting: {
        allowHTML: true,
      },
    };
  }, [formatterTotalCallback, totalMemo]);

  const totalChartMemo = React.useMemo(
    () => (
      <HighchartsReact highcharts={Highcharts} options={extraOptionsTotal} />
    ),
    [extraOptionsTotal]
  );

  if (collectionLoading || (collection && collection.length === 0))
    return <Loading />;

  return (
    <PageLayout
      title="COTEJO DE ACTAS PARA GUBERNATURA"
      history={history}
      links={[
        {
          to: "/lectura-virtual",
          icon: <EqualizerIcon fontSize="small" />,
          label: "Cotejo de actas para gubernatura",
        },
      ]}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            CÓMPUTO TOTAL
          </Typography>
        </Grid>
      </Grid>
      <Grid container component={Card} spacing={3}>
        <Grid item xs={12}>
          {totalChartMemo}
        </Grid>
      </Grid>
      <Grid container style={{ height: 50 }} />
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            RESULTADOS DE LA CASILLA <u>{collection[0].nombreActa}</u>
          </Typography>
        </Grid>
      </Grid>
      <Grid container component={Card} spacing={3}>
        <Table>
          <TableHead>
            <TableCell align="center">
              <img height={75} alt="partido" src={collection[0].acta[0].logo} />
            </TableCell>
            <TableCell align="center">
              <img height={75} alt="partido" src={collection[0].acta[1].logo} />
            </TableCell>
            <TableCell align="center">
              <img height={75} alt="partido" src={collection[0].acta[2].logo} />
            </TableCell>
            <TableCell align="center">
              <img height={75} alt="partido" src={collection[0].acta[3].logo} />
            </TableCell>
            <TableCell align="center">
              <img height={75} alt="partido" src={collection[0].acta[4].logo} />
            </TableCell>
            <TableCell align="center">
              <img height={75} alt="partido" src={collection[0].acta[5].logo} />
            </TableCell>
            <TableCell align="center">
              <img height={75} alt="partido" src={collection[0].acta[6].logo} />
            </TableCell>
            <TableCell align="center">
              <img height={75} alt="partido" src={collection[0].acta[7].logo} />
            </TableCell>
          </TableHead>
          <TableBody>
            <TableCell align="center">
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                {collection[0].acta[0].votos}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                {collection[0].acta[1].votos}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                {collection[0].acta[2].votos}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                {collection[0].acta[3].votos}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                {collection[0].acta[4].votos}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                {collection[0].acta[5].votos}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                {collection[0].acta[6].votos}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                {collection[0].acta[7].votos}
              </Typography>
            </TableCell>
          </TableBody>
        </Table>
        {/* <Grid item xs={12}>
          
          {actaChartMemo}
        </Grid> */}
      </Grid>
    </PageLayout>
  );
};

export default LecturaVirtual;
