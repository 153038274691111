import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";

const MuiTheme = createMuiTheme(
  {
    palette: {
      secondary: {
        main: "#a13800",
      },
      primary: {
        main: "#cd5d23",
      },
      error: {
        main: "#ff1744",
      },
    },
  },
  esES
);

export default MuiTheme;
