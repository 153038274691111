import React from "react";
import { Ballot } from "@material-ui/icons";
import { Button, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { generateDefaultChartOptions } from "../../utils";
import { useModels } from "../../hooks/useModels";

const actasRequestDefault = {
  name: "consejo-electoral/estadistico-detalle",
  extraParams: {},
};

const EstadisticoInformacionComplementaria = ({ history }) => {
  const header = [];
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [actasRequest, setActasRequest] = React.useState(actasRequestDefault);
  const [actas, actasLoading] = useModels(actasRequest);
  let chartOptions = generateDefaultChartOptions();

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    (e, v) => {
      if (!v) return;
      const { idConsejoElectoral } = v;
      if (idConsejoElectoral && idConsejoElectoral !== "") {
        const req = { ...actasRequestDefault };
        req.extraParams = { ...req.extraParams, idConsejoElectoral };
        setActasRequest(req);
        setIdConsejoElectoral(idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setActasRequest]
  );

  const onSearchClicked = async () => {
    if (idConsejoElectoral && idConsejoElectoral !== "") {
      const req = { ...actasRequestDefault };
      req.extraParams = { ...req.extraParams, idConsejoElectoral };
      setActasRequest(req);
    }
  };

  const boletasSobrantes =
    actas && actas[0]
      ? parseInt(actas[0]?.boletasSobrantes.toString().replace(",", ""))
      : 0;

  const personasQueVotaron =
    actas && actas[0]
      ? parseInt(actas[0]?.personasVotaron.toString().replace(",", ""))
      : 0;
  const representantesVotaron =
    actas && actas[0]
      ? parseInt(actas[0]?.representantes.toString().replace(",", ""))
      : 0;
  const votosSacados =
    actas && actas[0]
      ? parseInt(actas[0]?.votosSacados.toString().replace(",", ""))
      : 0;
  let extraOptions = {
    colors: ["#9E9E9E", "#ad2624"],
    title: {
      text: "CAPTURA DE INFORMACIÓN COMPLEMENTARIA",
    },
    subtitle: {
      text: "TOTALIZADOR DE INFORMACIÓN COMPLEMENTARIA",
    },
    series: [
      {
        name: "Resultados",
        data: [
          ["Boletas sobrantes", boletasSobrantes],
          ["Personas que votaron", personasQueVotaron],
          ["Representantes que votaron", representantesVotaron],
          ["Votos sacados de la urna", votosSacados],
        ],
      },
    ],
  };
  chartOptions = { ...chartOptions, ...extraOptions };

  return (
    <TablePageLayout
      title="ESTADÍSTICOS: AVANCE DE CAPTURA DE INFORMACIÓN COMPLEMENTARIA"
      model="consejos-electorales"
      history={history}
      links={[
        {
          to: "/lectura-votos",
          icon: <Ballot />,
          label: "Estadísticos: Información complementaria",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={actasLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={actasLoading}
      TableComponent={
        <Grid container justify="center">
          <Grid item xs={8} style={{ marginTop: 50 }}>
            <TablePagination
              header={header}
              data={actas}
              paginatedApi
              extraRows={[
                {
                  prop: "boletasSobrantes",
                  name: "BOLETAS SOBRANTES",
                  cell: (row) => <>{row.boletasSobrantes}</>,
                },
                {
                  prop: "personasVotaron",
                  name: "PERSONAS QUE VOTARON",
                  cell: (row) => <>{row.personasVotaron}</>,
                },
                {
                  prop: "representantes",
                  name: "REPRESENTANTES QUE VOTARON",
                  cell: (row) => <>{row?.representantes || 0}</>,
                },
                {
                  prop: "votosSacados",
                  name: "VOTOS SACADOS DE URNA",
                  cell: (row) => <>{row.votosSacados}</>,
                },
              ]}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 50 }}>
            {actas && actas[0] && (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default EstadisticoInformacionComplementaria;
