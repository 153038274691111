import React from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import { useAuth } from "../../hooks/useAuth";

const Logs = ({ dialog, setEditingAgenda, setSelectedAgenda, handleClose }) => {
  const history = useHistory();
  const header = [];

  const { user } = useAuth();
  const [sinUsuario, setSinUsuario] = React.useState("");
  const [busqueda, setBusqueda] = React.useState("");
  const [modulo, setModulo] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [clearConsejo, setClearConsejo] = React.useState(false);
  const [buscando, setBuscando] = React.useState(false);
  let aux = {};
  aux = {
    limite: 0,
  };
  const logTipoMemo = React.useMemo(() => aux);

  const logMemo = React.useMemo(() => {
    if (buscando && modulo) {
      return {
        name: "log",
        extraParams: {
          models: tipo,
        },
      };
    } else {
      return {};
    }
  }, [buscando, modulo]);

  const extraParams = React.useMemo(
    () => ({
      modulo: modulo,
    }),
    [modulo]
  );

  const [models, modelsLoading, modelsError, modelsPage, refreshModels] =
    useModels({
      name: "log",
      pagina: 0,
      extraParams,
    });

  const setTipoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setModulo(v.modulo);
      } else {
        setModulo("x");
      }
    },
    [setModulo]
  );

  React.useEffect(() => {
    if (!modulo) {
      return;
    }
  }, [modulo]);

  const onSearchClicked = async () => {
    let params = {};
    setBuscando(true);
    if (busqueda.length > 0) params = { ...params, busqueda };
    if (sinUsuario.valor > 0)
      params = { ...params, sinUsuario: sinUsuario.valor };
    // console.log("parametros a buscar", params);
  };

  return (
    <TablePageLayout
      title="Log del sistema"
      model="log"
      history={history}
      SearchComponents={
        <>
          <Grid item xs={12} sm={12} md={8}>
            <AsyncAutocompleteSearch
              label="Tipo de log"
              name="log"
              labelProp="modulo"
              onChange={setTipoCallback}
              extraParams={logMemo}
              clearInput={clearConsejo}
            />
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "fecha",
              name: "Fecha de Acceso",
              cell: (row, index) => <div>{row.fecha}</div>,
            },
            {
              prop: "idUsuario",
              name: "usuario",
              cell: (row, index) => <div>{row.usuario}</div>,
            },
            {
              prop: "tipo",
              name: "Accedio a:",
              cell: (row, index) => <div>{row.mensaje}</div>,
            },
          ]}
        />
      }
    />
  );
};

export default Logs;
