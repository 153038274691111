import React from "react";

const NotificationsContext = React.createContext();

// const defaultNotifications = () => [1, 2];

export function NotificationsProvider(props) {
  const [notifications, setNotifications] = React.useState([]);

  // COMPONENTE SE MONTÓ
  React.useEffect(() => {}, []);

  const memData = React.useMemo(() => {
    // AQUI SE PONE TODO LO Q EL HOOK EXPORTA
    return { notifications, setNotifications };
  }, [notifications]);

  return <NotificationsContext.Provider value={memData} {...props} />;
}

export function useNotifications() {
  const context = React.useContext(NotificationsContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: notifications context not defined.";
  }
  return context;
}
