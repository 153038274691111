import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { IconButton, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Add } from "@material-ui/icons";

import { photoBaseUrl } from "../../utils/variables";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";

import { useModels } from "../../hooks/useModels";

const CombinacionCoalicion = () => {
  const history = useHistory();

  const [nombre, setNombre] = useState("");
  const [idCoalicion, setIdCoalicion] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "combinacion-coalicion",
    ordenar: "idCombinacionCoalicion.desc",
    expand: "coalicion,combinacionPartidos.partidoPolitico",
    pagina: 0,
  });

  const [coaliciones] = useModels({ name: "coalicion" });

  const onSearchClicked = async (page) => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    if (idCoalicion.toString().length > 0) params = { ...params, idCoalicion };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar esta combinación coalición?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        deleteModel(row.idCombinacionCoalicion);
        refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "combinacion-coalicion",
      ordenar: "idCombinacionCoalicion.desc",
      nombre,
      idCoalicion,
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  const coalicionCambio = (event) => {
    const { value } = event.target;
    setIdCoalicion(value);
  };

  return (
    <TablePageLayout
      title="COMBINACIÓN COALICIÓN"
      model="combinacion-coalicion"
      history={history}
      actionButton={{
        to: "combinacion-coalicion/nuevo",
        icon: <Add />,
        label: "Agregar combinación coalición",
      }}
      links={[
        {
          label: "Combinación Coalición",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={5}>
            <TextField
              fullWidth
              size="small"
              label="Nombre Combinación Coalición"
              onChange={(event) => setNombre(event.target.value)}
              variant="outlined"
              onBlur={(_) => {
                if (nombre.length > 0) {
                  const nombres = nombre.split(" ");
                  if (nombres && nombres.length > 0)
                    refreshModels(null, { nombre });
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Coaliciones
              </InputLabel>
              <Select
                value={idCoalicion}
                style={{ textAlign: "left" }}
                variant="outlined"
                onChange={coalicionCambio}
                label="Coaliciones"
              >
                {coaliciones
                  ? coaliciones.map((opt, index) => (
                      <MenuItem key={index} value={opt.idCoalicion}>
                        {opt.nombre}
                      </MenuItem>
                    ))
                  : []}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              fullWidth
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={[]}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage !== null ? modelsPage.total : 0}
          labelRowsPerPage={"Renglones por página"}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre Combinación Coalición",
              cell: (row) => <>{row.nombre}</>,
            },
            {
              prop: "coalicion",
              name: "Coalición",
              cell: (row) => <>{row.coalicion.nombre}</>,
            },
            {
              prop: "logo",
              name: "Imagen",
              cell: (row) => (
                <a
                  href={`${photoBaseUrl}${row.logo}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${photoBaseUrl}${row.logo}`}
                    alt="logo"
                    height={80}
                    style={{ borderRadius: 5 }}
                    align="center"
                  />
                </a>
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/combinacion-coalicion/detalle?idCombinacionCoalicion=${row.idCombinacionCoalicion}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default CombinacionCoalicion;
