import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  Select,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";

import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import TouchApp from "@material-ui/icons/TouchApp";

import YesNoTable from "./YesNoTable";
import ActasTable from "./ActasTable";
import { emptyRequest } from "../../constants/request";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import PageLayout from "../../components/PageLayout";
import DatePicker from "../../components/DatePicker";
import TimePicker from "../../components/TimePicker";
import AsyncCreatableAutocomplete from "../../components/AsyncCreatableAutocomplete";
import CreateEntryDialog from "../../components/CreateEntryDialog";

import Services from "../../services";
import { useModels } from "../../hooks/useModels";
import { useAuth } from "../../hooks/useAuth";
import SingleSelect from "../../components/SingleSelect";
import { Inbox } from "@material-ui/icons";
import { useQuery } from "../../hooks/useQuery";
import { useModel } from "../../hooks/useModel";
import Loader from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    color: "#000",
    marginBottom: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  submitButton: {
    color: "#fff",
  },
}));

const breadcrumbs = [
  {
    to: `/recepciones-municipales`,
    icon: <MoveToInboxIcon fontSize="small" />,
    label: `Recepciones municipales`,
  },
  {
    to: "#",
    icon: <MoveToInboxIcon fontSize="small" />,
    label: `Detalle de recepción`,
  },
];

const DetalleRecepcion = ({ history }) => {
  const classes = useStyles();
  const query = useQuery();
  const { user } = useAuth();
  const idActa = query.get("a") || null;

  const memo = React.useMemo(
    () => ({
      idRecepcion: idActa,
      expand: "acta,acta.tipoEleccion,acta.estatusPaquete,acta.estatusActa",
    }),
    [idActa]
  );

  const [recepcion, recepcionLoading] = useModels({
    name: "recepcion",
    extraParams: memo,
  });

  if (recepcionLoading) return <Loader />;

  return (
    <PageLayout
      title={`DETALLE DE RECEPCIÓN DE PAQUETE`}
      history={history}
      loading={false}
      links={breadcrumbs}
      actionButton={{
        to: `/recepciones-municipales`,
        icon: <Inbox />,
        label: `VER HISTORIAL`,
      }}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
        alignItems="flex-end"
      >
        {recepcion && recepcion[0] && (
          <Grid item xs={12}>
            <Typography style={{ color: "black" }}>
              Persona que entregó:
            </Typography>
            <Typography style={{ fontSize: 24, color: "black" }}>
              {recepcion[0].nombreFuncionarioEntrega}
            </Typography>
            <Typography style={{ color: "black" }}>
              Persona que recibió:
            </Typography>
            <Typography style={{ fontSize: 24, color: "black" }}>
              {recepcion[0].nombreFuncionarioRecibe}
            </Typography>
            <Typography style={{ color: "black" }}>
              Fecha de recepción:
            </Typography>
            <Typography style={{ fontSize: 24, color: "black" }}>
              {recepcion[0].fecha}
            </Typography>
          </Grid>
        )}

        {/* {JSON.stringify(recepcion)} */}
        {recepcion.map((recepcion, index) => (
          <Grid item xs={4}>
            <Typography style={{ color: "black", fontSize: 22 }}>
              <strong>
                ACTA: {recepcion?.acta?.nombreCompletoActa} -{" "}
                {recepcion?.acta?.tipoEleccion?.nombre}
              </strong>
            </Typography>
            <Typography style={{ color: "black" }}>
              ESTATUS DEL PAQUETE:{" "}
              <strong>{recepcion?.acta?.estatusPaquete?.nombre}</strong>
            </Typography>
            <Typography style={{ color: "black" }}>
              ESTATUS DEL ACTA:{" "}
              <strong>{recepcion?.acta?.estatusActa?.nombre}</strong>
            </Typography>
            <Typography style={{ color: "black" }}>
              ACTA CON FIRMA:{" "}
              <strong>{recepcion?.firmaFuncionario ? "SÍ" : "NO"}</strong>
            </Typography>
            <Typography style={{ color: "black" }}>
              ACTA CON MUESTRAS DE ALTERACIÓN:{" "}
              <strong>{recepcion?.conAlteracion ? "SÍ" : "NO"}</strong>
            </Typography>
            <Typography style={{ color: "black" }}>
              CINTA O ETIQUETA DE SEGURIDAD:{" "}
              <strong>{recepcion?.cintaSeguridad ? "SÍ" : "NO"}</strong>
            </Typography>
            <Typography style={{ color: "black" }}>
              SOBRE PARA EL PREP:{" "}
              <strong>{recepcion?.actaPrep ? "SÍ" : "NO"}</strong>
            </Typography>
            <Typography style={{ color: "black" }}>
              BOLSA PARA EL CONSEJO MUNICIPAL:{" "}
              <strong>{recepcion?.actaEscrutinioComputo ? "SÍ" : "NO"}</strong>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </PageLayout>
  );
};

export default DetalleRecepcion;
