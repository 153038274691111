import { Grid, Typography } from "@material-ui/core";
import { TouchApp } from "@material-ui/icons";
import React from "react";

const SeleccionarActa = () => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      style={{ color: "#919191" }}
    >
      <TouchApp
        style={{
          fontSize: 48,
          marginTop: 10,
          marginBottom: 20,
          marginRight: 20,
        }}
      />
      <Typography>Es necesario seleccionar un ACTA para continuar.</Typography>
    </Grid>
  );
};

export default SeleccionarActa;
