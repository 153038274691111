import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { IconButton, Button, Grid, Menu, MenuItem } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import { photoBaseUrl } from "../../utils/variables";

const Candidato = () => {
  const history = useHistory();
  const header = [];

  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);

  const municipioParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "candidato",
    expand: "distrito,municipio,partidoPolitico",
    ordenar: "idCandidato.desc",
  });

  const {
    modelMunicipio,
    modelMunicipioLoading,
    modelMunicipioError,
    updateMunicipioModel,
    updateMunicipioModelLoading,
  } = useModel({
    name: "municipio",
    expand: "estado",
    ordenar: "idMunicipio.desc",
    extraParams: municipioParamsMemo,
  });

  const setIdMunicipioCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
    } else {
      setIdMunicipio("");
    }
  }, []);

  const onSearchClicked = async () => {
    let params = {};
    if (idMunicipio > 0) params = { ...params, idMunicipio };
    params = { ...params };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar este candidato?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        await deleteModel(row.idCandidato);
        await refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };
  const changePage = (page) => {
    let params = {
      name: "candidato",
      ordenar: "idCandidato.desc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      idMunicipio,
    };
    refreshModels(true, params);
  };

  console.log(models);

  return (
    <TablePageLayout
      title="CONTROL DE CANDIDATOS"
      actionButton={{
        to: "candidatos/nuevo",
        icon: <AddIcon />,
        label: "Nuevo Candidato",
      }}
      links={[
        {
          label: "Candidatos",
        },
      ]}
      history={history}
      SearchComponents={
        <>
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Municipio"
              name="municipio"
              labelProp="nombre"
              defaultValue={modelMunicipio && modelMunicipio}
              onChange={setIdMunicipioCallback}
              extraParams={municipioParamsMemo}
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre del Candidato",
              cell: (row, index) => <>{row.nombre}</>,
            },
            {
              prop: "tipoEleccion",
              name: "Tipo Elección",
              cell: (row, index) => <>{row.tipoEleccion}</>,
            },
            {
              prop: "municipio",
              name: "Municipio",
              cell: (row, index) => <>{row.municipio.nombre}</>,
            },
            {
              prop: "partidoPolitico",
              name: "Partido Politico",
              cell: (row, index) => (
                <a
                  href={`${photoBaseUrl}${row.partidoPolitico.logo}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${photoBaseUrl}${row.partidoPolitico.logo}`}
                    alt="logo"
                    height={80}
                    style={{ borderRadius: 5 }}
                    align="center"
                  />
                </a>
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/candidatos/detalle?id=${row.idCandidato}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Candidato;
