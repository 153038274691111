/* CacheBuster component */
import React from "react";
import packageJson from "../../package.json";

import app from "../firebase";
import "@firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

global.appVersion = packageJson.version;

const CacheBusterContext = React.createContext();

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export function CacheBusterProvider(props) {
  const reference = app
    .firestore()
    .collection("generales")
    .doc("configuracion");

  const [cacheBusterLoading, setCacheBusterLoading] = React.useState();
  const [isLatestVersion, setIsLatestVersion] = React.useState(false);
  const [firebaseVersion, firebaseVersionLoading, firebaseVersionError] =
    useDocumentData(reference);

  const refreshCacheAndRedirect = React.useCallback((uri) => {
    // console.log("Clearing cache and hard reloading...");
    if (caches) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.replace(uri);
  }, []);

  const compareVersions = React.useCallback(async () => {
    if (firebaseVersion && !firebaseVersionLoading && !firebaseVersionError) {
      // fetch("/meta.json", { cache: "no-cache" })
      //   .then((response) => response.json())
      //   .then((meta) => {
      //     const latestVersion = meta.version;
      const latestVersion = firebaseVersion.version;
      const currentVersion = global.appVersion;

      const shouldForceRefresh = semverGreaterThan(
        latestVersion,
        currentVersion
      );
      if (shouldForceRefresh) {
        // console.log(
        //   `Nueva versión - ${latestVersion}. Refrescar página con CTRL + R`
        // );
        setIsLatestVersion(false);
        setCacheBusterLoading(false);
      } else {
        // console.log(
        //   `Tienes la versión mas reciente - ${latestVersion}. No es necesario refrescar.`
        // );
        setIsLatestVersion(true);
        setCacheBusterLoading(false);
      }
    }
    // });
  }, [firebaseVersion, firebaseVersionLoading, firebaseVersionError]);

  React.useEffect(() => {
    compareVersions();
  }, [compareVersions]);

  const memData = React.useMemo(() => {
    return { cacheBusterLoading, isLatestVersion, refreshCacheAndRedirect };
  }, [cacheBusterLoading, isLatestVersion, refreshCacheAndRedirect]);

  return <CacheBusterContext.Provider value={memData} {...props} />;
}

export function useCacheBuster() {
  const context = React.useContext(CacheBusterContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: cache buster context not defined.";
  }
  return context;
}
