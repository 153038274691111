import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import PageHeader from "../../components/PageHeader";
import Loading from "../../components/FullScreenLoader";

import { useModel } from "../../hooks/useModel";
import { useQuery } from "../../hooks/useQuery";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function TipoAsistenteDetail({ match, history }) {
  const classes = useStyles();
  const query = useQuery();
  const idTipoAsistente = query.get("idTipoAsistente");
  const editing = !!idTipoAsistente;

  const [tipo, setTipo] = React.useState("");
  const [clave, setClave] = React.useState("");

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "tipo-asistente",
    id: idTipoAsistente,
    redirectOnPost: true,
  });

  React.useEffect(() => {
    if (!modelLoading && model && modelError) {
      setTipo(model.tipo);
      setClave(model.clave);
    }
  }, [model, modelLoading, modelError]);

  const onSaveClicked = async () => {
    let body = {
      tipo,
      clave,
    };
    if (editing) {
      body.idTipoAsistente = idTipoAsistente;
    }
    body = { ...body };
    updateModel(body);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE TIPO DE ASISTENTE"
        model="tipo-asistentes"
        editing={editing}
        links={[
          {
            to: "/tipo-asistentes",
            label: "Tipo Asistentes",
          },
          {
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            label="Tipo Asistente"
            variant="outlined"
            size="small"
            required
            fullWidth
            onChange={(e) => setTipo(e.target.value)}
            helperText={modelError.tipo}
            error={Boolean(modelError.tipo)}
            inputProps={{
              value: tipo,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Clave"
            size="small"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setClave(e.target.value)}
            helperText={modelError.clave}
            error={Boolean(modelError.clave)}
            inputProps={{
              value: clave,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSaveClicked}
            disabled={updateModelLoading}
            style={{ float: "right" }}
            startIcon={<SaveIcon />}
          >
            {editing ? "GUARDAR TIPO ASISTENTE" : "CREAR TIPO ASISTENTE"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default TipoAsistenteDetail;
