import React from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Button,
  Divider,
} from "@material-ui/core";

import { useModel } from "../../hooks/useModel";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loader from "../../components/Loader";
import AsyncSelect from "../../components/AsyncSelect";
import SingleSelect from "../../components/SingleSelect";
import services from "../../services";

const expands = [
  "tipoEleccion",
  "recepcion",
  "fechaLectura",
  "datosGenerales",
  "fechaRectificacion",
  "estatusPaquete",
  "estatusActa",
  "fechaComputo",
  "motivosRecuento",
  "recuentoCotejo",
  "recuentoGT",
];

const expandsActa = expands.join(",");
const gExtra = { expand: "nombreCompleto" };

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
  },
}));

const ModoIndividual = () => {
  const classes = useStyles();
  const [idActa, setIdActa] = React.useState(null);
  const [grupoTrabajo, setGrupoTrabajo] = React.useState({});
  const [actionLoading, setActionLoading] = React.useState(false);

  const actaRequestMemo = React.useMemo(
    () => ({
      name: "acta",
      id: idActa,
      expand: expandsActa,
    }),
    [idActa]
  );

  const {
    model: acta,
    modelLoading: actaLoading,
    refreshModel: actaRefresh,
  } = useModel(actaRequestMemo);

  React.useEffect(() => {
    if (acta && !actaLoading && acta?.recuentoGT) {
      const { nombreGrupo, idGrupoTrabajo } = acta.recuentoGT;
      setGrupoTrabajo({
        nombreCompleto: nombreGrupo,
        idGrupoTrabajo: idGrupoTrabajo,
      });
    }
  }, [acta, actaLoading]);

  const actionButtonClicked = React.useCallback(
    async (action) => {
      let response = null;
      const baseUrl = "acta-control/";
      try {
        setActionLoading(true);
        switch (action) {
          case "recepcion":
            response = await services.post(baseUrl + "limpiar-recepcion", {
              idActa,
            });
            break;
          case "lectura":
            response = await services.post(baseUrl + "limpiar-lectura", {
              idActa,
            });
            break;
          case "datosGenerales":
            response = await services.post(
              baseUrl + "limpiar-datos-generales",
              { idActa }
            );
            break;
          case "rectificacion":
            response = await services.post(baseUrl + "limpiar-rectificacion", {
              idActa,
            });
            break;
          case "computo":
            response = await services.post(baseUrl + "limpiar-computo", {
              idActa,
            });
            break;
          case "recuentoCotejo":
            response = await services.post(baseUrl + "cotejo-recuento", {
              idActa,
            });
            break;
          case "grupoTrabajo":
            response = await services.post(baseUrl + "actualizar-gt", {
              idActa,
              idGrupoTrabajo: grupoTrabajo.idGrupoTrabajo,
            });
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (response) actaRefresh();
        setActionLoading(false);
      }
    },
    [actaRefresh, idActa, setActionLoading, grupoTrabajo]
  );

  const onEntryChanged = (_, actaSeleccionada) => {
    if (actaSeleccionada && actaSeleccionada !== "") {
      setIdActa(actaSeleccionada.idActa);
    } else {
      setIdActa(null);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          SELECCIONE EL ACTA POR EDITAR
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <AsyncAutocompleteSearch
          label="Seleccione el acta deseada"
          name="acta"
          labelProp="nombreCompletoActa"
          searchParam="busqueda"
          onChange={onEntryChanged}
          noOptionsText="No se encontraron actas por editar."
        />
      </Grid>
      {actaLoading && <Loader />}
      {!actaLoading && acta && idActa && (
        <Grid item xs={12}>
          <Grid
            container
            style={{ marginTop: 10 }}
            spacing={1}
            alignItems="baseline"
          >
            <Grid item xs={12}>
              <Typography variant="text" color="secondary">
                <strong>NOMBRE COMPLETO DEL ACTA</strong>
              </Typography>
              <Typography variant="h5" className={classes.title}>
                {acta?.nombreCompletoActa}
                {" - "}
                {acta?.tipoEleccion?.nombre}
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="text" color="secondary">
                <strong>FECHA DE RECEPCIÓN DE PAQUETE</strong>
              </Typography>
              <Typography variant="h5" className={classes.title}>
                {acta?.recepcion?.fecha || "SIN RECEPCIÓN"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={!Boolean(acta?.recepcion?.fecha) || actionLoading}
                variant="contained"
                color="secondary"
                onClick={() => actionButtonClicked("recepcion")}
              >
                LIMPIAR RECEPCIÓN
              </Button>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="text" color="secondary">
                <strong>FECHA DE LECTURA DE VOTOS</strong>
              </Typography>
              <Typography variant="h5" className={classes.title}>
                {acta?.fechaLectura || "SIN LECTURA DE VOTOS"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={!Boolean(acta?.fechaLectura) || actionLoading}
                variant="contained"
                color="secondary"
                onClick={() => actionButtonClicked("lectura")}
              >
                LIMPIAR LECTURA
              </Button>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="text" color="secondary">
                <strong>FECHA DE CAPTURA DE DATOS GENERALES</strong>
              </Typography>
              <Typography variant="h5" className={classes.title}>
                {acta?.datosGenerales?.creado ||
                  "SIN CAPTURA DE DATOS GENERALES"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={
                  !Boolean(acta?.datosGenerales?.creado) || actionLoading
                }
                variant="contained"
                color="secondary"
                onClick={() => actionButtonClicked("datosGenerales")}
              >
                LIMPIAR DATOS GENERALES
              </Button>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="text" color="secondary">
                <strong>FECHA DE RECTIFICACIÓN</strong>
              </Typography>
              <Typography variant="h5" className={classes.title}>
                {acta?.fechaRectificacion || "SIN RECTIFICACIÓN"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={!Boolean(acta?.fechaRectificacion) || actionLoading}
                variant="contained"
                color="secondary"
                onClick={() => actionButtonClicked("rectificacion")}
              >
                LIMPIAR RECTIFICACIÓN
              </Button>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="text" color="secondary">
                <strong>ESTATUS Y FECHA DE CÓMPUTO</strong>
              </Typography>
              <Typography variant="h5" className={classes.title}>
                {acta?.recuentoCotejo
                  ? `${acta?.recuentoCotejo === "C" ? "COTEJO" : "RECUENTO"}`
                  : "SIN ESTATUS"}
                {": "}
                {acta?.fechaComputo ? `${acta?.fechaComputo}` : "SIN CÓMPUTO"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={!Boolean(acta?.fechaComputo) || actionLoading}
                variant="contained"
                color="secondary"
                onClick={() => actionButtonClicked("computo")}
              >
                LIMPIAR CÓMPUTO
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={Boolean(acta?.fechaComputo) || actionLoading}
                variant="contained"
                color="secondary"
                onClick={() => actionButtonClicked("recuentoCotejo")}
              >
                ENVIAR A{" "}
                {`${acta?.recuentoCotejo === "C" ? "RECUENTO" : "COTEJO"}`}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="text" color="secondary">
                    <strong>ESTATUS GENERAL DEL ACTA</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="text" color="black">
                    <strong>
                      {acta?.estatusPaquete?.nombre} -{" "}
                      {acta?.estatusActa?.nombre}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="text" color="secondary">
                    <strong>GRUPOS DE TRABAJO</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={8}>
                      <AsyncSelect
                        label="Grupo de trabajo"
                        name="grupo-trabajo"
                        labelProp="nombreCompleto"
                        onChange={(e) => setGrupoTrabajo(e.target.value)}
                        propId="idGrupoTrabajo"
                        disabled={acta?.fechaComputo}
                        value={grupoTrabajo}
                        extraParams={gExtra}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        disabled={acta?.fechaComputo || actionLoading}
                        variant="contained"
                        color="secondary"
                        onClick={() => actionButtonClicked("grupoTrabajo")}
                      >
                        ACTUALIZAR GT
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="text" color="secondary">
                    <strong>MOTIVOS DE RECUENTO</strong>
                  </Typography>
                </Grid>
                {acta?.motivosRecuento?.map((motiv, i) => (
                  <Grid key={i} item>
                    <Typography variant="text" color="black">
                      <strong>
                        {i + 1}. {motiv.nombre}
                      </strong>
                    </Typography>
                  </Grid>
                ))}
                {acta?.motivosRecuento?.length === 0 && (
                  <Grid item>
                    <Typography variant="text" color="black">
                      <strong>Acta sin motivos de recuento.</strong>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ModoIndividual;
