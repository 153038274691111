import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  AppBar,
  Tabs,
  Tab,
  Avatar,
  TextField,
  Button,
  Grid,
  Typography,
  Tooltip,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import BookIcon from "@material-ui/icons/Book";
import { withStyles } from "@material-ui/core/styles";
import DialogPaqueteDetail from "./dialogPaqueteDetail";
import PageLayout from "../../components/PageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingF from "../../components/FullScreenLoader";
import Loading from "../../components/Loading";
import Services from "../../services";
import { useAlert } from "../../hooks/useAlert";
import { useAuth } from "../../hooks/useAuth";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import AlertDialog from "../../components/AlertDialog";
import Link from "@material-ui/core/Link/Link";

const miStorage = window.localStorage;
const StyledTab = withStyles(() => ({
  root: {
    textTransform: "none",
    backgroundColor: "#a13800",
  },
}))(Tab);
const typeBinnacle = ["esperado", "almacenado", "entregado"];
const url =
  "acta?expand=ultimaRecepcion,nombreConTipoEleccion,ultimaBitacora,envioRecepcionDistrital";
const header = [
  {
    prop: "nombreConTipoEleccion",
    name: "Sección-Casilla-Elección",
  },
];
const urls = [
  `${url}&estatusBodega=esperado&idTipoEleccion=`,
  `${url}&estatusBodega=almacenado&idTipoEleccion=`,
  `${url}&estatusBodega=entregado&idTipoEleccion=`,
  `${url}&estatusBodega=esperado&idTipoEleccion=2`,
  `${url}&estatusBodega=esperado&idTipoEleccion=3`,
  `${url}&estatusBodega=esperado&idTipoEleccion=1`,
];
// Tipos de Consejos
const tcs = {
  1: "1",
  2: "1,2,3",
  3: "1,3",
  4: "1,3",
};
const buttonSacarAlmacenar = {
  1: [1],
  2: [1, 2, 3],
  3: [1, 3],
};

const BitacoraPaquetes = ({ history }) => {
  const { showAlert } = useAlert();
  const { user, userLoading } = useAuth();
  const [value, setValue] = React.useState(0);
  const [binnacles, setBinnacles] = useState([]);
  const [paginacion, setPagination] = useState(null);
  const [starting, setStarting] = useState(true);
  const [search, setSearch] = useState("");
  const [idActa, setIdActa] = useState("");
  const [loading, setLoading] = useState(false);
  const [totals, setTotals] = useState([0, 0, 0]);
  const [tipoBitacora, setTipoBitacora] = useState("");
  const [tiposConsejos, setTiposConsejos] = useState("");
  const [openAlertDialog, setOpenAlerDialog] = useState(false);
  const [idAgendaEntrega, setIdAgendaEntrega] = useState("");
  const [idAgendaRecibe, setIdAgendaRecibe] = useState("");
  const [agendaEntrega, setAgendaEntrega] = useState("");
  const [agendaRecibe, setAgendaRecibe] = useState("");

  useEffect(() => {
    let mounted = true;

    const getBinnacleByStatus = async () => {
      try {
        if (user && user.consejoElectoral) {
          let promices = [];

          const _tiposConsejos =
            tcs[user.consejoElectoral.idTipoConsejoElectoral];

          promices.push(Services.get(`${urls[0]}${_tiposConsejos}`));
          promices.push(Services.get(`${urls[1]}${_tiposConsejos}&limite=1`));
          promices.push(Services.get(`${urls[2]}${_tiposConsejos}&limite=1`));
          // promices.push(Services.get(urls[3] + "&limite=1"));
          // promices.push(Services.get(urls[4] + "&limite=1"));
          // promices.push(Services.get(urls[5] + "&limite=1"));

          const responses = await Promise.all(promices);

          if (mounted) {
            setPagination(responses[0].paginacion);
            setBinnacles(responses[0].resultado);
            setTotals([
              responses[0].paginacion.total,
              responses[1].paginacion.total,
              responses[2].paginacion.total,
            ]);
            setTiposConsejos(_tiposConsejos);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setStarting(false);
      }
    };

    getBinnacleByStatus();

    return () => (mounted = false);
  }, []);

  if (starting || userLoading || !user) return <LoadingF />;

  const handleChange = async (event, newValue) => {
    try {
      setLoading(true);
      setValue(newValue);
      setSearch("");

      const response = await Services.get(`${urls[newValue]}${tiposConsejos}`);

      setPagination(response.paginacion);
      setBinnacles(response.resultado);
      setTotals(
        totals.map((t, i) => (i === newValue ? response.paginacion.total : t))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const searchBinnacle = async () => {
    try {
      setLoading(true);
      setValue(-1);
      const searchUrl = search
        ? `${url}&busqueda=${search}&idTipoEleccion=${tiposConsejos}`
        : `${url}&estatusBodega=${typeBinnacle[value]}&busqueda=${search}&idTipoEleccion=${tiposConsejos}`;

      const response = await Services.get(searchUrl);

      setPagination(response.paginacion);
      setBinnacles(response.resultado);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onOpenDialog = async (_idActa, _tipo) => {
    const response = await Services.get("bodega/verificar-bodega");

    if (response.detalle.identificador !== 2) {
      setOpenAlerDialog(true);

      return;
    }

    let _agendaEntrega = "";
    try {
      _agendaEntrega = JSON.parse(miStorage.getItem("AgendaEntregaComputo21"));
      setIdAgendaEntrega(_agendaEntrega.idAgenda);
    } catch (e) {}

    let _agendaRecibe = "";
    try {
      _agendaRecibe = JSON.parse(miStorage.getItem("AgendaRecibeComputo21"));
      setIdAgendaRecibe(_agendaRecibe.idAgenda);
    } catch (e) {}
    setAgendaEntrega(_agendaEntrega);
    setAgendaRecibe(_agendaRecibe);
    setTipoBitacora(_tipo);
    setIdActa(_idActa);
  };

  const changePage = async (page) => {
    try {
      const response = await Services.get(
        url + "&estatusBodega=" + typeBinnacle[value] + "&pagina=" + page
      );

      setBinnacles(response.resultado);
    } catch (error) {
      console.log(error);
    }
  };

  const getButtonsRecepcion = () => {
    const idTipoConsejoElectoral = user.consejoElectoral.idTipoConsejoElectoral;
    const buttons = {
      1: (
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={() =>
            history.push({
              pathname: "/bodegas/bitacora-de-paquetes/recepcion-paquetes",
              state: {
                idTipoConsejoElectoral: idTipoConsejoElectoral,
              },
            })
          }
        >
          Recepción Estatal
        </Button>
      ),
      2: (
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={() =>
            history.push({
              pathname: "/bodegas/bitacora-de-paquetes/envio-paquetes",
              state: {
                idTipoConsejoElectoral: idTipoConsejoElectoral,
              },
            })
          }
        >
          Envío Distrital
        </Button>
      ),
      3: (
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={() =>
            history.push({
              pathname: "/bodegas/bitacora-de-paquetes/recepcion-paquetes",
              state: {
                idTipoConsejoElectoral: idTipoConsejoElectoral,
              },
            })
          }
        >
          Recepción Distrital
        </Button>
      ),
      4: null,
    };

    return buttons[idTipoConsejoElectoral];
  };

  const idConsejoElectoral = user.consejoElectoral?.idConsejoElectoral;

  const actualizarTotales = async () => {
    let promices = [];

    const _tiposConsejos = tcs[user.consejoElectoral.idTipoConsejoElectoral];

    promices.push(Services.get(`${urls[0]}${_tiposConsejos}&limite=1`));
    promices.push(Services.get(`${urls[1]}${_tiposConsejos}&limite=1`));
    promices.push(Services.get(`${urls[2]}${_tiposConsejos}&limite=1`));

    const responses = await Promise.all(promices);
    setTotals([
      responses[0].paginacion.total,
      responses[1].paginacion.total,
      responses[2].paginacion.total,
    ]);
  };

  return (
    <>
      <PageLayout
        title="BITÁCORA DE ENTRADA Y SALIDA DE PAQUETES ELECTORALES"
        history={history}
        links={[
          {
            to: "/bodegas/bitacora-de-paquetes",
            icon: <BookIcon fontSize="small" />,
            label: "Bitácora de paquetes",
          },
        ]}
        SearchComponents={<></>}
      >
        <Grid container spacing={2}>
          <Grid item sm={8} xs={8} md={8} xl={8}>
            <TextField
              size="small"
              style={{ backgroundColor: "white" }}
              fullWidth
              label="Buscar"
              variant="outlined"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </Grid>
          <Grid item sm={2} xs={2} md={2} xl={2}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={async () => await searchBinnacle()}
            >
              BUSCAR
            </Button>
          </Grid>
          <Grid item sm={2} xs={2} md={2} xl={2}>
            {getButtonsRecepcion()}
          </Grid>
          <Grid item xs={12}>
            <AppBar position="static">
              <Tabs value={value} onChange={handleChange} variant="fullWidth">
                <StyledTab
                  icon={<Typography variant="h5">{totals[0]}</Typography>}
                  label="Esperados"
                />
                <StyledTab
                  icon={<Typography variant="h5">{totals[1]}</Typography>}
                  label="Almacenados"
                />
                <StyledTab
                  icon={<Typography variant="h5">{totals[2]}</Typography>}
                  label="Entregados"
                />
              </Tabs>
            </AppBar>
            {loading ? (
              <Loading />
            ) : binnacles && binnacles.length ? (
              <TablePagination
                data={binnacles}
                header={header}
                paginatedApi
                count={paginacion.total}
                changePageApi={changePage}
                extraRows={[
                  {
                    prop: "recibido",
                    name: "Recibo",
                    cell: (row) => (
                      <Tooltip
                        title={
                          row.ultimaRecepcion && row.ultimaRecepcion.fecha
                            ? "Recibo Capturado: " +
                              format(
                                new Date(row.ultimaRecepcion.fecha),
                                "dd-MM-yyyy hh:mm"
                              )
                            : "Sin Recibo"
                        }
                      >
                        <Avatar
                          style={{
                            backgroundColor:
                              row.ultimaRecepcion && row.ultimaRecepcion.fecha
                                ? "#4caf50"
                                : "#a13800",
                          }}
                        >
                          {row.ultimaRecepcion && row.ultimaRecepcion.fecha ? (
                            <CheckIcon />
                          ) : (
                            <QueryBuilderIcon />
                          )}
                        </Avatar>
                      </Tooltip>
                    ),
                  },
                  {
                    prop: "envioDistrital",
                    name: value === 2 ? "Envio Distrital" : "",
                    cell: (row) =>
                      value === 2 &&
                      row.envioRecepcionDistrital &&
                      row.envioRecepcionDistrital.fechaEnvio
                        ? format(
                            new Date(row.envioRecepcionDistrital.fechaEnvio),
                            "dd-MM-yyyy hh:mm"
                          )
                        : "",
                  },
                  {
                    prop: "acciones",
                    name: "Acciones",
                    cell: (row) =>
                      row.ultimaBitacora &&
                      row.ultimaBitacora.entradaSalida === "E" ? (
                        row.idTipoEleccion === user?.consejoElectoral?.idTipoConsejoElectoral &&
                        row.ultimaBitacora?.idConsejoElectoral === user?.consejoElectoral.idConsejoElectoral ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={async () =>
                              await onOpenDialog(row.idActa, "S")
                            }
                          >
                            Sacar
                          </Button>
                        ) :  (row.ultimaBitacora?.idConsejoElectoral !== user?.consejoElectoral.idConsejoElectoral ? <Button
                          variant="contained"
                          color="secondary"
                          onClick={async () =>
                            await onOpenDialog(row.idActa, "E")
                          }
                        >
                          Almacenar
                        </Button> : "")
                      ) :
                        buttonSacarAlmacenar[
                          user?.consejoElectoral?.idTipoConsejoElectoral
                          ].includes(row.idTipoEleccion) ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={async () =>
                              await onOpenDialog(row.idActa, "E")
                            }
                          >
                            Almacenar
                          </Button>
                        ) : (
                          ""
                        )
                    ,
                  },
                ]}
              />
            ) : (
              <Typography
                align="center"
                style={{ marginTop: 50, fontSize: 28 }}
              >
                <Link
                  color="secondary"
                  href="#"
                  onClick={() => handleChange(null, value)}
                >
                  De clic aquí para actualizar la lista.
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </PageLayout>
      <DialogPaqueteDetail
        time={format(
          new Date(
            new Date().toLocaleString(
              "en-US",
              Intl.DateTimeFormat().resolvedOptions().timeZone
            )
          ),
          "HH:mm"
        )}
        idAgendaEntrega={idAgendaEntrega}
        idAgendaRecibe={idAgendaRecibe}
        agendaEntrega={agendaEntrega}
        agendaRecibe={agendaRecibe}
        type={tipoBitacora}
        nombre={
          idActa &&
          binnacles.find((b) => b.idActa === idActa) &&
          binnacles.find((b) => b.idActa === idActa).nombreConTipoEleccion
        }
        open={Boolean(idActa)}
        onClose={(mensaje) => {
          if (mensaje) {
            setBinnacles(binnacles.filter((b) => b.idActa !== idActa));
            showAlert({
              severity: "success",
              message: mensaje,
            });

            actualizarTotales();
          }

          setIdActa("");
        }}
        idActa={idActa}
        idConsejoElectoral={user?.idConsejoElectoral || ""}
        onCreateAgendaEntrega={(entry) => {
          setAgendaEntrega(entry);
        }}
        onCreateAgendaRecibe={(entry) => {
          setAgendaRecibe(entry);
        }}
      />
      <AlertDialog
        open={openAlertDialog}
        title="Bodega cerrada!"
        content="¿Desea ir a la Apertura de Bodega?"
        onClose={() => setOpenAlerDialog(false)}
        onAccept={() =>
          history.push(
            "/bodegas/apertura?idConsejoElectoral=" + user?.idConsejoElectoral
          )
        }
      />
    </>
  );
};

export default BitacoraPaquetes;
