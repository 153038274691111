import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  TableContainer,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { NON_DIGIT, tipoVotos } from "../../constants/mockData";
import Loader from "../../components/Loader";

import { useQuery } from "../../hooks/useQuery";
import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import Services from "../../services";

import PageLayout from "../../components/PageLayout";
import { FindInPage } from "@material-ui/icons";
import AlertDialog from "../../components/AlertDialog";
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
    paddingBottom: "1%",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2) * -1,
    color: "#000",
  },
  successButton: {
    float: "right",
    top: 10,
    backgroundColor: "green",
    color: "white",
  },
  formControl: {
    minWidth: "100%",
  },
  table: {
    minWidth: 700,
    tableLayout: "auto",
  },
  act: { fontSize: 30 },
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  fieldText: { fontSize: 42 },
  panelEspecialText: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
  },
  sinLectura: {
    height: 100,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#76777b",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const breadcrumbs = [
  {
    to: `/cotejo`,
    icon: <FindInPage fontSize="small" />,
    label: `Historial de cotejo`,
  },
  {
    to: `/cotejo/nuevo`,
    icon: <FindInPage fontSize="small" />,
    label: `Sesión de cómputo: cotejar acta`,
  },
];

const CotejoDetail = ({ match, history }) => {
  const classes = useStyles();
  const query = useQuery();
  const idActa = query.get("a");
  const [open, setOpen] = React.useState(false);
  const [validating, setValidating] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [motivoRecuentoSeleccionado, setMotivoRecuentoSeleccionado] =
    React.useState("");
  const [votosParticipantesPoliticos, setVotosParticipantesPoliticos] =
    React.useState([]);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [recuentoLoading, setRecuentoLoading] = React.useState(false);
  const [printOpen, setPrintOpen] = React.useState(false);

  const { model, modelLoading } = useModel({
    name: "acta",
    id: idActa || null,
    redirectOnPost: true,
    expand:
      "votosParticipantes,votosParticipantesRecuento,participantesPoliticos,votosGeneralesRecuento,fechaComputo,esEspecial",
  });

  const [motivoRecuento, motivoRecuentoLoading] = useModels({
    name: "motivo-recuento",
  });

  React.useEffect(() => {
    if (model && !modelLoading) {
      if (model.participantesPoliticos.length === 0) {
        return;
      }
      let tmp = model.participantesPoliticos.map((e, i) => ({
        ...e,
        resultado: model.fechaComputo
          ? model.votosParticipantesRecuento[i].votos.toString()
          : "",
      }));
      const votosCandidatosNoRegistrados =
        model?.votosGeneralesRecuento?.votosCandidatosNoRegistrados || "0";
      const votosNulos = model?.votosGeneralesRecuento?.votosNulos || "0";
      const votosTotales = model?.votosGeneralesRecuento?.votosTotales || "0";
      tmp.push({
        resultado: model.fechaComputo ? votosCandidatosNoRegistrados : "",
      });
      tmp.push({ resultado: model.fechaComputo ? votosNulos : "" });
      tmp.push({ resultado: model.fechaComputo ? votosTotales : "" });
      setVotosParticipantesPoliticos([...tmp]);
    }
  }, [model, modelLoading]);

  const onEnviarRecuentoClicked = () => {
    setOpen(true);
  };

  const onEnviarRecuentoClosed = () => {
    setMotivoRecuentoSeleccionado("");
    setOpen(false);
  };

  const onEnviarRecuentoAccepted = () => {
    if (motivoRecuentoSeleccionado !== "") {
      setRecuentoLoading(true);
      const body = {
        idActa,
        idMotivoRecuento: motivoRecuentoSeleccionado,
      };
      Services.post("acta-registro-voto-computo/enviar-recuento", body)
        .then((result) => {
          if (result) {
            history.push("/cotejo/nuevo");
          }
        })
        .finally(() => setRecuentoLoading(false));
    }
  };

  const submitDetail = (sumaVotos) => {
    setButtonLoading(true);
    const votosCandidatosNoRegistrados =
      votosParticipantesPoliticos[votosParticipantesPoliticos.length - 3]
        .resultado;
    const votosNulos =
      votosParticipantesPoliticos[votosParticipantesPoliticos.length - 2]
        .resultado;
    const votosTotales = sumaVotos;
    const votos = votosParticipantesPoliticos
      .slice(0, votosParticipantesPoliticos.length - 3)
      .map((e) => e.resultado);
    // console.log(votos);
    // console.log(votosParticipantesPoliticos);
    const obj = {
      idActa: model.idActa,
      idTipoActa: model.idTipoActa,
      votosNulos,
      votosCandidatosNoRegistrados,
      votosTotales,
      votosParticipante: votos.map((v, i) => ({
        votos: v,
        idParticipantePolitico:
          votosParticipantesPoliticos &&
          votosParticipantesPoliticos[i] &&
          votosParticipantesPoliticos[i].idParticipantePolitico
            ? votosParticipantesPoliticos[i].idParticipantePolitico
            : "",
      })),
    };
    // console.log(obj);
    Services.post("acta-registro-voto-computo/guardar-cotejo", obj)
      .then((result) => {
        if (result) {
          setPrintOpen(true);
          history.push("/cotejo/nuevo");
        }
      })
      .finally(() => setButtonLoading(false));
  };

  const onSubmitPressed = () => {
    setValidating(true);
    if (
      [
        ...votosParticipantesPoliticos.filter(
          (v, i) => i < votosParticipantesPoliticos.length - 1
        ),
      ].some((v) => v.resultado === "")
    ) {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    } else {
      setConfirmOpen(true);
    }
  };

  if (!model || modelLoading || motivoRecuentoLoading) return <Loader />;

  const motivoRecuentoTexto = motivoRecuento.find(
    (m) => m.idMotivoRecuento === parseInt(motivoRecuentoSeleccionado)
  )?.nombre;

  let sumaVotos = 0;
  votosParticipantesPoliticos.forEach((e, i) => {
    if (i < votosParticipantesPoliticos.length - 1 && e.resultado !== "")
      sumaVotos += parseInt(e.resultado);
  });

  return (
    <PageLayout
      title={`SESIÓN DE COMPUTO: COTEJO DE ACTA ${
        model ? model.nombreCompletoActa : ""
      } ${model?.fechaComputo ? ` - COTEJADA: ${model?.fechaComputo}` : ""}`}
      links={[
        ...breadcrumbs,
        {
          to: `/cotejo/nuevo/detalle?id=${model ? model.idActa : null}`,
          icon: <FindInPage fontSize="small" />,
          label: `${model ? model.nombreCompletoActa : ""}`,
        },
      ]}
      history={history}
      SearchComponents={<></>}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
        alignItems="flex-end"
      >
        <Grid item xs={9}>
          {!open && motivoRecuentoTexto && (
            <>
              <Typography>
                <strong>MOTIVO DE RECUENTO SELECIONADO</strong>
              </Typography>

              <Typography>{motivoRecuentoTexto}</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          <Button
            color="secondary"
            variant="contained"
            style={{ float: "right", marginLeft: "1%" }}
            onClick={onEnviarRecuentoClicked}
            disabled={model?.fechaComputo}
          >
            ENVIAR A RECUENTO
          </Button>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontSize: 24 }}
                  >
                    RESULTADOS DE LA VOTACIÓN DE CASILLA:
                    <b> {model ? model.nombreCompletoActa : ""}</b>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={6} style={{ textAlign: "center" }}>
                    Digite los votos para cada partido político, coalición,
                    cantidatura común, candidato independiente, candidatos no
                    registrados y total de votos. <br /> En caso de no haber
                    votos en alguno de los campos, digite el número cero.
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={1}>No.</StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">
                    Partido Político, Candidatura Común, Coalición o Candidato
                    Independiente
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} align="center">
                    RESULTADOS DE LA VOTACIÓN
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {model &&
                model.participantesPoliticos &&
                model.participantesPoliticos.length > 0 ? (
                  [
                    ...model.participantesPoliticos.sort((a, b) =>
                      a.orden && b.orden ? a.orden - b.orden : 1
                    ),
                    ...tipoVotos,
                  ].map((p, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell colSpan={1}>
                        {index < model.participantesPoliticos.length
                          ? index + 1
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1} align="center">
                        {p.logo !== "" ? (
                          <img
                            alt={p.nombre}
                            src={p.logo}
                            style={{ width: 100 }}
                          />
                        ) : (
                          <div className={classes.panelEspecial}>
                            <Typography className={classes.panelEspecialText}>
                              {p.nombre}
                            </Typography>
                          </div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell colSpan={4} align="right">
                        <form
                          autoComplete="off"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <TextField
                            id={index.toString()}
                            label="Número del 0 al 999"
                            fullWidth
                            variant="standard"
                            required
                            value={
                              votosParticipantesPoliticos[index]
                                ? index ===
                                  votosParticipantesPoliticos.length - 1
                                  ? sumaVotos
                                  : votosParticipantesPoliticos[index].resultado
                                : ""
                            }
                            error={
                              (!model.esEspecial &&
                                index ===
                                  votosParticipantesPoliticos.length - 1 &&
                                sumaVotos > 999) ||
                              (model.esEspecial &&
                                index ===
                                  votosParticipantesPoliticos.length - 1 &&
                                sumaVotos > 9999)
                            }
                            disabled={
                              index ===
                                votosParticipantesPoliticos.length - 1 ||
                              model?.fechaComputo
                            }
                            InputProps={{ className: classes.fieldText }}
                            onChange={(e) => {
                              const { value } = e.target;
                              const intValue = parseInt(
                                value.toString().replace(NON_DIGIT, "")
                              );
                              let pEntry = isNaN(intValue) ? "" : intValue;
                              setVotosParticipantesPoliticos((v) => {
                                let arr = [...v];
                                if (
                                  model.esEspecial &&
                                  arr.length > 0 &&
                                  value.length <= 4
                                ) {
                                  arr[index].resultado = pEntry;
                                } else if (
                                  !model.esEspecial &&
                                  arr.length > 0 &&
                                  value.length <= 3
                                ) {
                                  arr[index].resultado = pEntry;
                                }
                                return arr;
                              });
                            }}
                          />
                        </form>
                        {motivoRecuentoSeleccionado === "" &&
                          p.resultado === "" && (
                            <FormHelperText className={classes.tableHelperText}>
                              Favor de ingresar un número entero.
                            </FormHelperText>
                          )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <div />
                )}
                {model &&
                  model.participantesPoliticos &&
                  model.participantesPoliticos.length === 0 && (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={5}
                        align="center"
                        className={classes.sinLectura}
                      >
                        Acta sin Lectura de votos, no puede cotejarse.
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={
              model?.fechaComputo ||
              buttonLoading ||
              (!model.esEspecial && sumaVotos > 999) ||
              (model.esEspecial && sumaVotos > 9999) ||
              (model &&
                model.participantesPoliticos &&
                model.participantesPoliticos.length === 0) ||
              (motivoRecuentoSeleccionado === "" &&
                [
                  ...votosParticipantesPoliticos.filter(
                    (v, i) => i < votosParticipantesPoliticos.length - 1
                  ),
                ].some((v) => v.resultado === ""))
            }
            color="primary"
            variant="contained"
            style={{ float: "right", marginLeft: "1%" }}
            onClick={onSubmitPressed}
          >
            {buttonLoading ? "CARGANDO" : "GUARDAR"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={onEnviarRecuentoClosed}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              {"¿ESTÁ SEGURO QUE DESEA ENVIAR EL ACTA "}
              {model && model.nombreCompletoActa}
              {" A RECUENTO?"}
            </DialogTitle>
            <DialogContent style={{ minHeight: 120 }}>
              <DialogContentText style={{ color: "black" }}>
                Motivo de recuento
              </DialogContentText>
              <Grid container>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    error={validating && motivoRecuentoSeleccionado === ""}
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <InputLabel id="plantilla-label">
                      Seleccione el motivo de recuento
                    </InputLabel>
                    <Select
                      labelId="plantilla-label"
                      label="Seleccione el motivo de recuento"
                      value={motivoRecuentoSeleccionado}
                      onChange={(e) => {
                        setMotivoRecuentoSeleccionado(e.target.value);
                      }}
                    >
                      {motivoRecuento.map((motivo, index) => (
                        <MenuItem key={index} value={motivo.idMotivoRecuento}>
                          {motivo.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    {validating && motivoRecuentoSeleccionado === "" && (
                      <FormHelperText>
                        Favor de seleccionar el motivo de recuento.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onEnviarRecuentoClosed}>Cancelar</Button>
              <Button
                onClick={onEnviarRecuentoAccepted}
                color="primary"
                disabled={motivoRecuentoSeleccionado === ""}
              >
                {recuentoLoading ? "Cargando" : "GUARDAR Y ACEPTAR"}
              </Button>
            </DialogActions>
          </Dialog>
          <AlertDialog
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            title={`CONFIRMACIÓN DE COTEJO DE ACTA ${
              model ? model.nombreCompletoActa : ""
            }`}
            loading={buttonLoading}
            content="¿Está seguro que desea guardar la información capturada?"
            onAccept={() => submitDetail(sumaVotos)}
          />
          <AlertDialog
            open={printOpen}
            title={`COTEJO DE ACTA ${
              model ? model.nombreCompletoActa : ""
            } GUARDADO CORRECTAMENTE.`}
            titleColor="#60c45a"
            onAccept={(_) => history.push("/cotejo/nuevo")}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default CotejoDetail;
