import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  GridList,
  GridListTile,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CardMedia from "@material-ui/core/CardMedia";

import Loading from "../../components/FullScreenLoader";
import DatePicker from "../../components/DatePicker";
import CustomDialog from "../../components/CustomDialog";
import PageHeader from "../../components/PageHeader";

import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import { useQuery } from "../../hooks/useQuery";
import { photoBaseUrl } from "../../utils/variables";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function CoalicionesDetail({ match, history }) {
  const classes = useStyles();
  const query = useQuery();
  const idCoalicion = query.get("idCoalicion");
  const editing = !!idCoalicion;

  const [nombre, setNombre] = React.useState("");
  const [acuerdoAprobacion, setAcuerdoAprobacion] = React.useState("");
  const [fechaAcuerdo, setFechaAcuerdo] = React.useState(new Date());
  const [orden, setOrden] = React.useState("");
  const [claveCoalicion, setClaveCoalicion] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "coalicion",
    id: idCoalicion,
    expand: "",
    redirectOnPost: true,
  });

  const [recursos] = useModels({ name: "recurso" });

  React.useEffect(() => {
    let mounted = true;
    if (!modelLoading && model && modelError) {
      setNombre(model.nombre);
      setAcuerdoAprobacion(model.acuerdoAprobacion);
      setFechaAcuerdo(model.fechaAcuerdo);
      setOrden(model.orden);
      setClaveCoalicion(model.claveCoalicion);
      setDescripcion(model.descripcion);
      setLogo(model.logo);
    }
    return () => {
      mounted = false;
    };
  }, [model, modelLoading, modelError]);

  const handleClose = () => {
    setIsOpen((v) => !v);
  };

  const onSaveClicked = async () => {
    let body = {
      nombre,
      acuerdoAprobacion,
      fechaAcuerdo,
      orden,
      claveCoalicion,
      descripcion,
      logo,
    };
    if (editing) {
      body.idCoalicion = idCoalicion;
    }
    body = { ...body };
    updateModel(body);
  };

  const setFechaAcuerdoCallback = React.useCallback(
    (date) => {
      setFechaAcuerdo(date);
    },
    [setFechaAcuerdo]
  );

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE COALICIÓN"
        model="coaliciones"
        editing={editing}
        links={[
          {
            to: "/coaliciones",
            label: "Coaliciones",
          },
          {
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nombre"
            variant="outlined"
            size="small"
            required
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Acuerdo Aprobación"
            size="small"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setAcuerdoAprobacion(e.target.value)}
            helperText={modelError.acuerdoAprobacion}
            error={Boolean(modelError.acuerdoAprobacion)}
            inputProps={{
              value: acuerdoAprobacion,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <DatePicker
            label="Fecha Acuerdo"
            value={fechaAcuerdo}
            onChange={setFechaAcuerdoCallback}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Orden"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setOrden(e.target.value)}
            helperText={modelError.orden}
            error={Boolean(modelError.orden)}
            inputProps={{
              value: orden,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Clave Coalición"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setClaveCoalicion(e.target.value)}
            helperText={modelError.claveCoalicion}
            error={Boolean(modelError.claveCoalicion)}
            inputProps={{
              value: claveCoalicion,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Descripción"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setDescripcion(e.target.value)}
            helperText={modelError.descripcion}
            error={Boolean(modelError.descripcion)}
            inputProps={{
              value: descripcion,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClose()}
          >
            Seleccionar Logo
          </Button>
          <CustomDialog
            isOpen={isOpen}
            handleClose={handleClose}
            title="Selección de Logo"
          >
            <GridList cellHeight={160} cols={10}>
              {recursos.map((recurso) => (
                <GridListTile key={recurso.idRecurso} cols={1}>
                  <img
                    src={`${photoBaseUrl}${recurso.ruta}`}
                    alt={recurso.nombre}
                    onClick={() => {
                      setLogo(recurso.ruta);
                      handleClose();
                    }}
                    style={{ width: "100%", height: "auto" }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </CustomDialog>
        </Grid>

        {logo !== "" && (
          <Grid item xs={12} md={12}>
            <CardMedia
              style={{ width: "auto" }}
              component="img"
              alt="Imagen de la coalición"
              height="300"
              image={`${photoBaseUrl}${logo}`}
              title="Imagen de la coalición"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSaveClicked}
            disabled={updateModelLoading}
            style={{ float: "right" }}
            startIcon={<SaveIcon />}
          >
            {editing ? "GUARDAR COALICIÓN" : "CREAR COALICIÓN"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default CoalicionesDetail;
