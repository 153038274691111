import React, { useEffect, useState } from "react";
import BookIcon from "@material-ui/icons/Book";
import SendIcon from "@material-ui/icons/Send";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  Typography,
  Card,
  MenuItem,
} from "@material-ui/core";
import Services from "../../../services";
import Loading from "../../../components/FullScreenLoader";
import DialogEnvioPaquetes from "./dialogEnvioPaquetes";
import { useAlert } from "../../../hooks/useAlert";
import TablePagination from "../../../components/TablePagination";
import { useAuth } from "../../../hooks/useAuth";
import { format } from "date-fns";
import PageLayout from "../../../components/PageLayout";
import { useModels } from "./../../../hooks/useModels";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import PdfDialog from "../../../components/PdfDialog";
import { baseUrl, baseUrlPdf } from "../../../utils/variables";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { auth } from "../../../firebase";
import AlertDialog from "../../../components/AlertDialog";

const url = "acta/envio-consejo";
const EnvioPaquete = ({ history }) => {
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const { user, userLoading } = useAuth();
  const idConsejoElectoral = user?.consejoElectoral?.idConsejoElectoral;

  const [estatales, setEstatales] = useState([]);
  const [distritales, setDistritales] = useState([]);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [printOpen, setPrintOpen] = useState(false);
  const [tipoPdf, setTipoPdf] = useState("envio");
  const [idEnvioRecepcion, setIdEnvioRecepcion] = useState(null);
  const [consejos, setConsejos] = useState([]);
  const { showAlert } = useAlert();
  const [idConsejoRecibe, setIdConsejoRecibe] = useState(0);
  const [cargandoPaquetes, setCargandoPaquetes] = useState(false);
  const [tipoEnvio, setTipoEnvio] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [link, setLink] = React.useState("");
  let tipo = "";

  const id = auth.currentUser.uid;

  const envioRecepcionRequest = React.useMemo(() => ({
    name: "envio-recepcion",
    extraParams: { idConsejoRecibe },
    expand: "consejoRecibe,consejoEnvia"
  }), [idConsejoRecibe]);

  const [
    envioPaquetes,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels(envioRecepcionRequest);

  useEffect(() => {
    let mounted = true;

    const obtenerConsejos = async () => {
      if (user && user.consejoElectoral) {
        try {
          const _idTipoConsejoElectoral =
            user?.consejoElectoral?.idTipoConsejoElectoral;
          const _tipoEnvio =
            _idTipoConsejoElectoral === 2 ? "Distrital" : "Estatal";
          let urlConsejosDistrito =
            "consejo-electoral?conDistritos=true&limite-1";

          if (_idTipoConsejoElectoral !== 2) {
            urlConsejosDistrito = "consejo-electoral?idConsejoElectoral=94";
          }

          const responseConsejos = await Services.get(urlConsejosDistrito);

          if (mounted) {
            setTipoEnvio(_tipoEnvio);
            setConsejos(responseConsejos.resultado);
          }
        } catch (error) {
          console.log(error);
        } finally {
          if (mounted) setLoading(false);
        }
      }
    };

    obtenerConsejos();

    return () => (mounted = false);
  }, [user]);

  if (userLoading || loading) return <Loading />;

  const sendPackages = async () => {
    try {
      if (saving) return;

      setSaving(true);

      const _distrital = distritales.map((a) => a.idActa);
      const _estatal = estatales.map((a) => a.idActa);
      const _actas = [..._distrital, ..._estatal];
      const data = {
        idConsejoRecibe: idConsejoRecibe,
        tipoEnvio: tipoEnvio,
        actas: _actas,
      };

      const response = await Services.post("acta/guardar-envio", data);
      if(response && response.detalle) {
        setIdEnvioRecepcion(response.detalle.idEnvioRecepcion);
        setPrintOpen(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
      setOpen(false);
    }
  };

  const onChangeConsejos = async (newValue) => {
    try {
      setIdConsejoRecibe(newValue ? newValue.idConsejoElectoral : 0);

      if (!newValue || cargandoPaquetes) return;

      setCargandoPaquetes(true);

      const response = await Services.get(
        `${url}?tipoEnvio=${tipoEnvio}&idConsejoRecibe=${newValue.idConsejoElectoral}`
      );

      if(response && response.resultado) {
        setEstatales(response.resultado.filter((r) => r.idTipoEleccion === 1));
        setDistritales(
          response.resultado.filter((r) => [3, 4].includes(r.idTipoEleccion))
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCargandoPaquetes(false);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "envio-recepcion",
      expand: "consejoRecibe,consejoEnvia",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  console.log(`${baseUrlPdf}paquete-acta-circunstanciada/${tipoPdf}-paquetes?idEnvioRecepcion=${idEnvioRecepcion}&idUsuario=${id}`)
  return (
    <PageLayout
      title={"Envio a Consejo " + tipoEnvio}
      history={history}
      links={[
        {
          to: "/bodegas/bitacora-de-paquetes",
          icon: <BookIcon fontSize="small" />,
          label: "Bitácora de paquetes",
        },
        {
          to: "/bodegas/bitacora-de-paquetes/envio-paquetes",
          icon: <SendIcon fontSize="small" />,
          label: "Envio de paquetes",
        },
      ]}
    >
      <Autocomplete
        size="small"
        options={consejos}
        getOptionLabel={(option) => option.nombre}
        style={{ width: "50%" }}
        renderInput={(params) => (
          <TextField
            style={{ backgroundColor: "white" }}
            {...params}
            label="Consejos Electorales"
            variant="outlined"
          />
        )}
        onChange={async (event, newValue) => await onChangeConsejos(newValue)}
        style={{ marginBottom: 20 }}
      />

      {cargandoPaquetes && <Loading />}

      { !cargandoPaquetes && idConsejoRecibe > 0 && (
        <TablePagination
          header={[]}
          data={envioPaquetes}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombreEnvia",
              name: "Consejo Envia",
              cell: (row) => (
                <>
                  {row.consejoEnvia
                    ? row.consejoEnvia.nombre
                    : "Consejo Envia"}
                </>
              ),
            },
            {
              prop: "nombreRecibe",
              name: "Consejo Recibe",
              cell: (row) => (
                <>
                  {row.consejoRecibe
                    ? row.consejoRecibe.nombre
                    : "Consejo Recibe"}
                </>
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    {idConsejoElectoral === row.consejoRecibe?.idConsejoElectoral ? (
                      <MenuItem
                        onClick={() => {
                          console.log(row);
                          setIdEnvioRecepcion(row.idEnvioRecepcion);
                          setTipoPdf("recepcion");
                          setPdfDialogOpen(true);
                        }}
                      >
                        <PictureAsPdfIcon style={{ paddingRight: 5 }} />
                        Imprimir
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          console.log(row);
                          setIdEnvioRecepcion(row.idEnvioRecepcion);
                          setTipoPdf("envio");
                          setPdfDialogOpen(true);
                        }}
                      >
                        <PictureAsPdfIcon style={{ paddingRight: 5 }} />
                        Imprimir
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      )}

      {!cargandoPaquetes && idConsejoRecibe > 0 && (distritales.length > 0 || estatales.length > 0) && (
        <>
          <br />
          <Button
            variant="contained"
            color="secondary"
            style={{ float: "right" }}
            onClick={() => {
              if (!estatales.length && !distritales.length) {
                showAlert({
                  severity: "warning",
                  message: "No tiene paquetes para enviar.",
                });

                return;
              }

              if (!idConsejoRecibe) {
                showAlert({
                  severity: "warning",
                  message: "Favor de seleccionar un Consejo Electoral.",
                });
                return;
              }

              setOpen(true);
            }}
          >
            ENVIAR A CONSEJO
          </Button>
          <br />

          {tipoEnvio === "Distrital" ? (
            <>
              <Typography>
                <b>Diputaciones</b>
              </Typography>
              {distritales.length ? (
                <TablePagination
                  header={[]}
                  data={distritales}
                  extraRows={[
                    {
                      prop: "nombre",
                      name: "Sección-Casilla",
                      cell: (row) => (
                        <>
                          {" "}
                          {row.idSeccion} - {row.tipoCasilla}{" "}
                          {row.numeroContigua ? " - " + row.numeroContigua : ""}
                        </>
                      ),
                    },
                    {
                      prop: "fecha",
                      name: "Fecha Recepción o Ingreso a Bodega",
                      cell: (row) =>
                        row.fecha
                          ? format(new Date(row.fecha), "dd-MM-yyyy hh:mm:ss")
                          : "Sin Recibo Capturado",
                    },
                  ]}
                />
              ) : (
                <Card style={{ padding: 5 }}>
                  <Typography align="center">
                    Sin paquetes de Diputaciones.
                  </Typography>
                </Card>
              )}
            </>
          ) : null}

          <Typography style={{ marginTop: 30 }}>
            <b>Gubernatura</b>{" "}
          </Typography>
          {estatales.length ? (
            <TablePagination
              header={[]}
              data={estatales}
              extraRows={[
                {
                  prop: "nombre",
                  name: "Sección-Casilla",
                  cell: (row) => (
                    <>
                      {" "}
                      {row.idSeccion} - {row.tipoCasilla}{" "}
                      {row.numeroContigua ? "-" + row.numeroContigua : ""}
                    </>
                  ),
                },
                {
                  prop: "fecha",
                  name: "Fecha Recepción o Ingreso a Bodega",
                  cell: (row) =>
                    row.fecha
                      ? format(new Date(row.fecha), "dd-MM-yyyy hh:mm:ss")
                      : "Sin Recibo Capturado",
                },
              ]}
            />
          ) : (
            <Card style={{ padding: 5 }}>
              <Typography align="center">
                Sin paquetes de Gubernatura.
              </Typography>
            </Card>
          )}

          <DialogEnvioPaquetes
            open={open}
            onClose={() => setOpen(false)}
            title={`¿Seguro que desea enviar los paquetes al ${
              idConsejoRecibe
                ? consejos.find((c) => c.idConsejoElectoral === idConsejoRecibe)
                    .nombre
                : ""
            }?`}
            paquetes={{
              estatales,
              distritales,
            }}
            saving={saving}
            onAccept={sendPackages}
          />
        </>
      )}
      <AlertDialog
        open={printOpen}
        onClose={() => history.push("/bodegas/administracion")}
        title={`GUARDADO CORRECTAMENTE.`}
        titleColor="#60c45a"
        cancelText="Finalizar y continuar"
        content="Imprimir el listado de Paquestes Electorales"
        onAccept={(_) => {
          setPdfDialogOpen(true);
        }}
      />
      <PdfDialog
        source={`${baseUrlPdf}paquete-acta-circunstanciada/${tipoPdf}-paquetes?idEnvioRecepcion=${idEnvioRecepcion}&idUsuario=${id}`}
        open={pdfDialogOpen}
        setOpen={setPdfDialogOpen}
      />
    </PageLayout>
  );
};

export default EnvioPaquete;
