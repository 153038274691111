import React from "react";
import { Link, useHistory } from "react-router-dom";

import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Add from "@material-ui/icons/Add";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import SingleSelect from "../../components/SingleSelect";

import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";

const Agendas = ({
  dialog,
  setEditingAgenda,
  setSelectedAgenda,
  handleClose,
}) => {
  const history = useHistory();
  const header = [];
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "agenda",
    expand:
      "distrito,seccion,municipio,tipoAgenda,usuario,nombreCompleto,consejoElectoral",
  });

  const [sinUsuario, setSinUsuario] = React.useState("");
  const [busqueda, setBusqueda] = React.useState("");
  const [apellidoPaterno, setApellidoPaterno] = React.useState("");
  const [apellidoMaterno, setApellidoMaterno] = React.useState("");
  const [idDistritoFederal, setIdDistritoFederal] = React.useState("");
  const [idDistritoLocal, setIdDistritoLocal] = React.useState("");
  const [clearDistritoLocal, setClearDistritoLocal] = React.useState(false);
  const [idSeccion, setIdSeccion] = React.useState("");
  const [clearSeccion, setClearSeccion] = React.useState(false);
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [idConsejoElectoral, setIdConsejo] = React.useState("");
  const [clearConsejo, setClearConsejo] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();
  const [mostrar, setMostrar] = React.useState(false);

  const federalParamsMemo = React.useMemo(
    () => ({
      tipo: 1,
      ordenar: "idDistritoFederal.asc",
      expand: "distritoFederal",
    }),
    []
  );

  const localParamsMemo = React.useMemo(
    () => ({
      tipo: 2,
      ordenar: "idDistrito.asc",
      expand: "",
      limite: 21,
    }),
    []
  );

  const seccionParamsMemo = React.useMemo(
    () => ({
      idDistrito: idDistritoLocal,
      ordenar: "nombre.asc",
    }),
    [idDistritoLocal]
  );

  const consejoParamsMemo = React.useMemo(
    () => ({
      idDistrito: idDistritoLocal,
      idMunicipio: idMunicipio,
      limite: 93,
    }),
    [idDistritoLocal, idMunicipio]
  );

  const setIdDistritoFederalCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdDistritoFederal(v.idDistritoFederal);
    } else {
      setIdDistritoFederal("");
    }
    setIdDistritoLocal("");
    setClearDistritoLocal((v) => !v);
    setIdSeccion("");
    setClearSeccion((v) => !v);
    setIdConsejo("");
    setClearConsejo((v) => !v);
  }, []);

  const setIdDistritoLocalCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdDistritoLocal(v.idDistritoLocal);
    } else {
      setIdDistritoLocal("");
    }
    setIdSeccion("");
    setClearSeccion((v) => !v);
    setIdConsejo("");
    setClearConsejo((v) => !v);
  }, []);

  const setIdSeccionCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdSeccion(v.idSeccion);
    } else {
      setIdSeccion("");
    }
    setIdConsejo("");
    setClearConsejo((v) => !v);
  }, []);

  const setIdMunicipioCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
    } else {
      setIdMunicipio("");
    }
    setIdConsejo("");
    setClearConsejo((v) => !v);
  }, []);

  const setIdConsejoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdConsejo(v.idConsejoElectoral);
      } else {
        setIdConsejo("");
      }
    },
    [setIdConsejo]
  );

  const setUsuarioLigadoCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      if (value !== "" && value) {
        setSinUsuario(value);
      }
    },
    [setSinUsuario]
  );

  const onSearchClicked = async () => {
    let params = {
      isCargando: false,
    };
    if (busqueda.length > 0) params = { ...params, busqueda };
    // if (idDistritoLocal > 0) params = { ...params, idDistrito: idDistritoLocal };
    // if (idSeccion > 0) params = { ...params, idSeccion };
    // if (idMunicipio > 0) params = { ...params, idMunicipio };
    if (idConsejoElectoral > 0) params = { ...params, idConsejoElectoral };
    if (sinUsuario.valor > 0)
      params = { ...params, sinUsuario: sinUsuario.valor };
    await refreshModels(true, params);
  };

  const onDeleteClicked = (row) => {
    try {
      showDialog({
        onCancel: closeDialog,
        onOk: () => {
          setOpenMenuRow(null);
          deleteModel(row.idAgenda);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "agenda",
      busqueda,
      idConsejoElectoral,
      // ordenar: "idCoalicion.desc",
      isCargando: false,
      pagina: page + 1,
      limite: 20,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="CONTROL DE AGENDAS"
      model="agenda"
      history={history}
      actionButton={{
        to: "agendas/nuevo",
        icon: <Add />,
        label: "Nueva Agenda",
      }}
      links={[
        {
          to: "/agendas",
          label: "Agendas",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              fullWidth
              size="small"
              label="Nombre o Correo"
              onChange={(event) => setBusqueda(event.target.value)}
              // onBlur={(_) => {
              //   if (validateEmail(correo)) {
              //     refreshModels(null, { correo });
              //   }
              // }}
              variant="outlined"
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Apellido Paterno"
              onChange={(event) => setApellidoPaterno(event.target.value)}
              variant="outlined"
              // onBlur={(_) => {
              //   if (nombre.length > 0) {
              //     const nombres = nombre.split(" ");
              //     if (nombres && nombres.length > 0)
              //       refreshModels(null, { nombre });
              //   }
              // }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Apellido Materno"
              onChange={(event) => setApellidoMaterno(event.target.value)}
              variant="outlined"
              // onBlur={(_) => {
              //   if (nombre.length > 0) {
              //     const nombres = nombre.split(" ");
              //     if (nombres && nombres.length > 0)
              //       refreshModels(null, { nombre });
              //   }
              // }}
            />
          </Grid> */}

          {/* <Grid item xs={4}>
            <AsyncAutocompleteSearch
              label="Distrito Federal"
              name="distrito-rel/distritos-federales"
              labelProp="distritoFederal.nombre"
              onChange={setIdDistritoFederalCallback}
              extraParams={federalParamsMemo}
            />
          </Grid> */}

          {/* <Grid item xs={4}>
            <AsyncAutocompleteSearch
              label="Distrito Local"
              name="distrito"
              labelProp="nombre"
              onChange={setIdDistritoLocalCallback}
              extraParams={localParamsMemo}
              clearInput={clearDistritoLocal}
            />
          </Grid>

          <Grid item xs={4}>
            <AsyncAutocompleteSearch
              label="Sección"
              name="seccion"
              labelProp="nombre"
              onChange={setIdSeccionCallback}
              extraParams={seccionParamsMemo}
              clearInput={clearSeccion}
            />
          </Grid>

          <Grid item xs={4}>
            <AsyncAutocompleteSearch
              label="Municipio"
              name="municipio"
              labelProp="nombre"
              onChange={setIdMunicipioCallback}
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={4}>
            <AsyncAutocompleteSearch
              label="Consejo"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoCallback}
              extraParams={consejoParamsMemo}
              clearInput={clearConsejo}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <SingleSelect
              label="Por usuario vinculado"
              value={sinUsuario}
              onChange={setUsuarioLigadoCallback}
              options={[
                { nombre: "Todos", valor: 1 },
                { nombre: "Con usuario", valor: 2 },
                { nombre: "Sin usuario", valor: 3 },
              ]}
            />
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre",
              cell: (row, index) => <div>{row.nombreCompleto}</div>,
            },
            /*{
              prop: "distrito",
              name: "Distrito",
              cell: (row, index) =>
                row.distrito ? (
                  <div>{row.distrito.nombre}</div>
                ) : (
                  <i>Sin distrito</i>
                ),
            },
            {
              prop: "seccion",
              name: "Sección",
              cell: (row, index) =>
                row.seccion ? (
                  <div>{row.seccion.nombre}</div>
                ) : (
                  <i>Sin sección</i>
                ),
            },
            {
              prop: "municipio",
              name: "Municipio",
              cell: (row, index) =>
                row.municipio ? (
                  <div>{row.municipio.nombre}</div>
                ) : (
                  <i>Sin municipio</i>
                ),
            },*/
            {
              prop: "usuario",
              name: "Cuenta vinculada",
              cell: (row, index) =>
                row.usuario ? (
                  <Link to={`usuarios/${row.usuario.idUsuario}`}>
                    {row.usuario.correo}
                  </Link>
                ) : (
                  <i>Sin cuenta</i>
                ),
            },
            {
              prop: "consejoElectoral",
              name: "Consejo Electoral",
              cell: (row, index) =>
                row.consejoElectoral ? (
                  <div>{row.consejoElectoral.nombre}</div>
                ) : (
                  <i>-</i>
                ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    {dialog && (
                      <MenuItem
                        onClick={() => {
                          setSelectedAgenda(row);
                          handleClose();
                        }}
                      >
                        <Sync style={{ paddingRight: 5 }} />
                        Ligar
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() =>
                        history.push(`/agendas/detalle?id=${row.idAgenda}`)
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Agendas;
