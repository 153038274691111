import React from 'react';
import { Box, Radio, Grid, FormControlLabel } from '@material-ui/core';

const RowClausuraCasilla = ({text, value, onChange}) => {
  return (
    <>
      <div style={{ width: '100', marginBottom: -20 }}>
        <Box display="flex" p={1}>
          <Box flexGrow={1} style={{marginTop: 15}}>
            <b>{text}</b>
          </Box>
          <Box >
            <Grid container>
              <Grid item md={6} xl={6} xs={6} sm={6} >
                <FormControlLabel
                  control={
                    <Radio 
                      checked={value && value === "si" ? true : false} 
                      onChange={() => onChange("si")} 
                      color="primary" 
                    />
                  }
                  label="Si"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={6} xl={6} xs={6} sm={6} >
                <FormControlLabel
                  control={
                    <Radio 
                      checked={value && value === "no" ? true : false} 
                      onChange={() => onChange("no")} 
                      color="primary" 
                    />}
                  label="No"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
      <hr />
    </>
  )
}

export default RowClausuraCasilla
