import React, { useEffect, useMemo, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { Grid, Paper, Typography } from "@material-ui/core";
import TablePagination from "../../components/TablePagination";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { generateDefaultChartOptions2D, getPercentage } from "../../utils";
import { useModels } from "../../hooks/useModels";
import Loader from "../../components/Loader";

const AvanceRecepcion = () => {
  let chartOptions = generateDefaultChartOptions2D();

  const [idConsejoE, setIdConsejoE] = useState();
  const [maxInterval, setMaxInterval] = React.useState(0);
  const [totales, setTotales] = React.useState({});
  const [nombreConsejo, setNombreConsejo] = React.useState("");

  const objExtraParamLectura = useMemo(
    () => ({
      idConsejoElectoral: idConsejoE,
      limite: 1,
    }),
    [idConsejoE]
  );
  const [
    models,
    modelsLoading,
    modelsErrorLectura,
    modelsPageLectura,
    refreshModelsLectura,
    deleteModelLectura,
  ] = useModels({
    name: "consejo-electoral/estadistico-bodega",
    extraParams: objExtraParamLectura,
  });

  const reqProperties = {
    name: "consejo-electoral",
    idEstado: 26,
    limite: -1,
    ordenar: "nombre.asc",
  };

  const [request, setRequest] = React.useState(reqProperties);

  const [
    consejos,
    modelsConsejosLoading,
    modelsConsejosError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels(request);

  const esperadas =
    models && models[0]
      ? parseInt(models[0]?.actasEsperadas.toString().replace(",", ""))
      : 0;

  const almacenadas =
    models && models[0]
      ? parseInt(models[0]?.actasAlmacenadas.toString().replace(",", ""))
      : 0;
  const entregadas =
    models && models[0]
      ? parseInt(models[0]?.actasEntregadas.toString().replace(",", ""))
      : 0;

  let extraOptions = {
    colors: ["#9E9E9E", "#ad2624"],
    title: {
      text: "AVANCE DE BODEGA ELECTORAL",
    },
    subtitle: {
      text: "TOTALIZADOR DE PAQUETES POR ESTATUS EN BODEGA",
    },
    plotOptions: {
      pie: {
        size: 300,
      },
    },
    series: [
      {
        name: "PAQUETES",
        data: [
          ["Esperadas", esperadas],
          ["Almacenadas", almacenadas],
          ["Entregadas", entregadas],
        ],
      },
    ],
  };
  chartOptions = { ...chartOptions, ...extraOptions };

  useEffect(() => {
    const consejoFilter = consejos.filter(
      (e) => e.idDistrito == null && e.idConsejoElectoral != 94
    );
    const idConsejoElectoral = consejoFilter[0]?.idConsejoElectoral;

    if (idConsejoElectoral && idConsejoElectoral !== "") {
      let mounted = true;

      if (maxInterval == 0) {
        setTotales(models.length > 0 ? models[0] : {});
        setIdConsejoE(idConsejoElectoral);
        setNombreConsejo(consejoFilter[0]?.nombre);
      }

      let timeout = setInterval(() => {
        if (mounted) {
          setTotales(models.length > 0 ? models[0] : {});
          setIdConsejoE(consejoFilter[maxInterval]?.idConsejoElectoral);
          setNombreConsejo(consejoFilter[maxInterval]?.nombre);
          setMaxInterval(maxInterval + 1);
          if (maxInterval == 71) {
            setMaxInterval(0);
          }
        }
      }, 30000);
      return () => {
        if (timeout !== null) {
          clearInterval(timeout);
        }
        mounted = false;
      };
    }
  }, [maxInterval, consejos, models]);

  if (modelsLoading) return <Loader />;

  console.log("models", models);
  return (
    <PageLayout title="AVANCE DE RECEPCIÓN DE PAQUETES">
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="h3">{nombreConsejo && nombreConsejo}</Typography>
          <br></br>
          <br></br>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4">
            <div style={{ color: "black" }}>
              PAQUETES TOTAL:
              <b style={{ color: "red" }}>
                {models[0] > 0 ? models[0]?.actasTotal : " 0"}
              </b>
            </div>
            <div style={{ color: "black" }}>
              PAQUETES ESPERADOS:
              <b style={{ color: "red" }}>
                {models[0] > 0 ? models[0]?.actasEsperadas : " 0"}
              </b>
            </div>
            <div style={{ color: "black" }}>
              PAQUETES ALMACENADOS:
              <b style={{ color: "red" }}>
                {models[0] > 0 ? models[0]?.actasAlmacenadas : " 0"}
              </b>
            </div>
            <div style={{ color: "black" }}>
              PAQUETES ENTREGADOS:
              <b style={{ color: "red" }}>
                {models[0] > 0 ? models[0]?.actasEntregadas : " 0"}
              </b>
            </div>
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {models && models[0] && (
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default AvanceRecepcion;
