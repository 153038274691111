import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useModel } from "../../hooks/useModel";
import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import swal from "sweetalert";
import CustomSelect from "../../components/CustomInput/CustomSelect.js";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Casillas = ({
  dialog,
  setEditingCasillas,
  setSelectedCasillas,
  handleClose,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const header = [
    {
      prop: "tipoCasilla",
      name: "Tipo Casilla",
    },
    {
      prop: "casilla",
      name: "Casilla",
    },
  ];

  const [casilla, setCasilla] = useState("");
  const [idTipoCasilla, setIdTipoCasilla] = useState("");

  const [seccionesF, setSeccionesF] = React.useState([]);
  const [idSeccion, setIdSeccion] = React.useState("");

  const [seccionL, setSeccionL] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();

  const [modelSeccion, modelSeccionLoading] = useModels({
    name: "seccion",
    expand: "",
    ordenar: "nombre.asc",
    limite: -1,
  });

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "casilla",
    expand: "distrito,seccion",
    ordenar: "idCasilla.desc",
  });

  const [modelTipoCasilla] = useModels({
    name: "tipo-casilla",
    expand: "",
    ordenar: "nombre.desc",
    limite: -1,
  });

  const onSearchClicked = async () => {
    let params = {};
    if (idTipoCasilla > 0) params = { ...params, idTipoCasilla };
    if (idSeccion > 0) params = { ...params, idSeccion };
    if (casilla.length > 0) params = { ...params, casilla };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
  };

  const tipoCasillaSelectChange = (e) => {
    const { value } = e.target;

    setIdTipoCasilla(value);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar este casilla?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        await deleteModel(row.idCasilla);
        await refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "casilla",
      ordenar: "idCasilla.desc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      idTipoCasilla,
      idSeccion,
      casilla,
    };
    refreshModels(true, params);
  };

  React.useEffect(() => {
    if (modelSeccion) {
      setSeccionesF(modelSeccion);
    }
  }, [modelSeccion]);

  return (
    <TablePageLayout
      title="CASILLAS"
      actionButton={{
        to: "casillas/nuevo",
        icon: <AddIcon />,
        label: "Nueva Casilla",
      }}
      links={[
        {
          to: "/casillas",
          icon: <AllInboxIcon />,
          label: "Casillas",
        },
      ]}
      history={history}
      SearchComponents={
        <>
          <Grid item xs={12} sm={3}>
            <CustomSelect
              labelText="Tipo Casilla"
              options={
                modelTipoCasilla
                  ? modelTipoCasilla.map((e) => ({
                      value: e.idTipoCasilla,
                      label: e.nombre,
                    }))
                  : []
              }
              formControlProps={{
                fullWidth: true,
                style: { top: 10 },
              }}
              inputProps={{
                value: idTipoCasilla,
                onChange: (e) => tipoCasillaSelectChange(e),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              size="small"
              label="Casilla"
              onChange={(event) => setCasilla(event.target.value)}
              variant="outlined"
              onBlur={(_) => {
                if (casilla.length > 0) {
                  const casillas = casilla.split(" ");
                  if (casillas && casillas.length > 0)
                    refreshModels(null, { casilla });
                }
              }}
              variant="outlined"
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Autocomplete
              value={seccionL}
              size="small"
              onChange={(event, newValue) => {
                if (newValue) {
                  setSeccionL(newValue);
                  setIdSeccion(newValue.idSeccion);
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.idSeccion}
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              options={seccionesF}
              getOptionLabel={(secc) => (secc ? secc.nombre : "")}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Secciones"
                />
              )}
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            // {
            //   prop: "distrito",
            //   name: "Distrito Federal",
            //   cell: (row) => (
            //     <>
            //       {row.distritoFederal
            //         ? row.distritoFederal
            //         : "Sin Distrito Federal"}
            //     </>
            //   ),
            // },
            {
              prop: "idDistrito",
              name: "Distrito Local",
              cell: (row) => (
                <>
                  {row.distrito.descripcion
                    ? row.distrito.descripcion
                    : "Sin Distrito Local"}
                </>
              ),
            },
            {
              prop: "seccion",
              name: "Sección",
              cell: (row) => (
                <>{row.seccion ? row.seccion.nombre : "Sin Sección"}</>
              ),
            },
            {
              prop: "claveINE",
              name: "Clave INE",
              cell: (row) => <>{row.claveINE ? row.claveINE : "Sin INE"}</>,
            },
            {
              prop: "activo",
              name: "Activo",
              cell: (row) => <>{row.activo == 0 ? "Inactivo" : "Activo"}</>,
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(`/casillas/detalle?id=${row.idCasilla}`)
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Casillas;
