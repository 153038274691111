import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import PERMISSIONS_BASE, {
  permisoToSpanish,
} from "../../constants/permissions";
import { useAuth } from "../../hooks/useAuth";
import { capitalizeFirst, checkPermissionPreset } from "../../utils";
import AutocompleteCheckbox from "../../components/AutocompleteCheckbox";

const PermissionsTable = ({ onChange, editing, user, preset }) => {
  const { user: currentUser } = useAuth();
  const [permisos, setPermisos] = React.useState([]);
  const [permisoActual, setPermisoActual] = React.useState({});

  const onPermissionChange = React.useCallback(
    (row, newValues) => {
      if (!row || !newValues) return;
      const cpy = { ...permisoActual };
      if (cpy[row.key]) delete cpy[row.key];
      cpy[row.key] = newValues;
      setPermisoActual(cpy);
      onChange(cpy);
    },
    [permisoActual, setPermisoActual, onChange]
  );

  React.useEffect(() => {
    if (!currentUser) return;
    const { permiso, permisoExtra } = currentUser;
    if (permiso) {
      let newPermisos = [];
      let currentPermissions = {};
      for (const [key, value] of Object.entries(permiso)) {
        if (
          !isNaN(value) &&
          (value & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ
        ) {
          const opts = [];
          for (const [permKey, permVal] of Object.entries(PERMISSIONS_BASE)) {
            if ((value & permVal) === permVal) {
              opts.push({ label: permKey, value: permVal });
            }
          }
          for (const [permExtraKey, permExtraVal] of Object.entries(
            permisoExtra
          )) {
            if (key === permExtraKey) {
              let permisoOffset = 32;
              permExtraVal.forEach((extraPerm) => {
                if ((value & permisoOffset) === permisoOffset) {
                  opts.push({ label: extraPerm, value: permisoOffset });
                }
                permisoOffset *= 2;
              });
            }
          }
          if (editing) {
            currentPermissions[key] = opts;
            const permisoRow = { ...currentPermissions };
            setPermisoActual(permisoRow);
          }
          newPermisos.push({
            key,
            label: capitalizeFirst(key),
            value: permiso ? permiso[key] : 0,
            options: opts.map((o) => ({
              ...o,
              label: permisoToSpanish(o),
            })),
          });
        }
      }
      setPermisos([...newPermisos]);
    }
  }, [currentUser, editing]);

  React.useEffect(() => {
    let newPermisos = {};
    permisos.forEach((row) => {
      newPermisos = {
        ...newPermisos,
        [row.key]: row.options.filter((e) => {
          let permiso = null;
          if (editing) permiso = user.permiso;
          const condi = editing && (permiso[row.key] & e.value) === e.value;
          return (
            condi ||
            checkPermissionPreset(preset, {
              permissionKey: row.key,
              permissionValue: e.value,
            })
          );
        }),
      };
    });
    setPermisoActual(newPermisos);
    onChange(newPermisos);
  }, [preset, permisos, onChange, editing, user]);

  return permisos.length > 0 ? (
    <TableContainer>
      <Table aria-label="permissions-table">
        <TableHead>
          <TableRow>
            <TableCell>Módulo</TableCell>
            <TableCell>Permisos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permisos
            .filter(
              (row) =>
                permisoActual &&
                permisoActual[row.key] &&
                !!permisoActual[row.key].length
            )
            .map((row) => (
              <TableRow key={`permiso-${row.key}`}>
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell component="th" scope="row" style={{ width: "100%" }}>
                  <AutocompleteCheckbox
                    value={permisoActual[row.key] ? permisoActual[row.key] : []}
                    onChange={(_, newValues) =>
                      onPermissionChange(row, newValues)
                    }
                    options={row.options}
                    label="Seleccione los permisos"
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default PermissionsTable;
