import React from "react";

import { Dialog } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { propertyAccesor } from "../utils/index";

import { useModels } from "../hooks/useModels";

const textAgregar = "Agregar nuevo";

const filter = createFilterOptions();

function AsyncCreatableAutocomplete({
  label,
  name,
  defaultValue,
  labelProp,
  onChange,
  extraParams,
  clearInput,
  index,
  propId = "",
  ignoreIds = [],
  dialogForm: DialogForm,
  dialogProps,
  propDefaultValue,
  onSubmitted,
  value,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const [models, modelsLoading, modelsError, modelsPage, modelsRefresh] =
    useModels({
      name,
      extraParams,
    });

  React.useEffect(() => {
    setInput("");
  }, [clearInput]);

  React.useEffect(() => {
    if (defaultValue) {
      setInput(propertyAccesor(defaultValue, labelProp));
    }
  }, [defaultValue, labelProp]);

  React.useEffect(() => {
    if (ignoreIds && models.length > 0) {
      setOptions(
        ignoreIds.length
          ? models.filter((m) => !ignoreIds.includes(m[propId]))
          : models || []
      );
    }
    // else if (models.length === 0) setOptions([]);
  }, [propId, ignoreIds, models]);

  const refreshSearch = React.useCallback(
    async (params) => {
      await modelsRefresh(true, params);
    },
    [modelsRefresh]
  );

  const onKeyPressCallback = async (e) => {
    setSelectedValue(e.target.value);
    let params = { busqueda: selectedValue };
    await refreshSearch(params);
  };

  const handleDialogClose = () => {
    setDialogOpen((v) => !v);
  };

  const onFilterOptions = (options, params) => {
    if (params && params.inputValue === undefined) params.inputValue = "";
    const filtered = filter(options, params);

    filtered.push({
      inputValue: params.inputValue ? params.inputValue : null,
      [labelProp]: params.inputValue
        ? `Agregar "${params.inputValue}"`
        : textAgregar,
    });
    return filtered;
  };

  return (
    <>
      <Autocomplete
        size="small"
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        onKeyUp={onKeyPressCallback}
        onChange={(e, v) => {
          if ((v && v.inputValue) || (v && v[labelProp] === textAgregar)) {
            handleDialogClose();
          }
          isNaN(index) ? onChange(e, v) : onChange(index, e, v);
        }}
        defaultValue={defaultValue && defaultValue}
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={(option) => {
          return propertyAccesor(option, labelProp) || "";
        }}
        loading={modelsLoading}
        selectOnFocus
        filterOptions={onFilterOptions}
        clearOnBlur={false}
        inputValue={input}
        onInputCapture={(e) => {
          setInput(e.target.value);
          onChange();
        }}
        onInputChange={(e, r) => setInput(r)}
        noOptionsText="No hay opciones"
        loadingText="Cargando..."
        handleHomeEndKeys
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {modelsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...rest}
      />
      {DialogForm ? (
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          PaperProps={{
            style: { boxShadow: "none" },
          }}
          BackdropProps={{
            style: {
              backgroundColor: "#000",
              opacity: 0.25,
            },
          }}
          maxWidth="md"
        >
          <DialogForm
            {...dialogProps}
            index={index}
            onCloseClicked={(_) => handleDialogClose((v) => !v)}
            // onSubmitted={(_) => console.log(_)}
          />
        </Dialog>
      ) : null}
    </>
  );
}

export default AsyncCreatableAutocomplete;
