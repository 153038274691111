import { Typography, Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
}));

const ModoMultiple = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          SELECCIONE LAS ACTA POR EDITAR
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ModoMultiple;
