import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = () => {
  return (
    <div 
      style={{ 
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: 30
      }}
    > 
      <CircularProgress />
    </div>
  )
}   

export default Loading
