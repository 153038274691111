import React from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import YesNoButtonNested from "../../components/YesNoButtonNested";
import Loader from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  tableHelperText: { textAlign: "left", color: theme.palette.error.dark },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d1835a",
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 18,
    height: 50,
    color: "#000",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const tipoValores = {
  conFirma: undefined,
  muestraAlteracion: undefined,
  cintaDeSeguridad: undefined,
  sobreParaPREP: undefined,
  bolsaConsejo: undefined,
};

const ActasTable = ({ actas = [], validating = false, ...rest }) => {
  const classes = useStyles();
  const { loading } = rest;
  const [valores, setValores] = React.useState({});
  const { onChange } = rest;

  React.useEffect(() => {
    let tmp = {};
    actas.forEach((acta) => {
      tmp[acta.nombreTipoEleccion] = {
        ...tipoValores,
        idActa: acta.idActa,
        idTipoEleccion: acta.idTipoEleccion,
      };
    });
    setValores(tmp);
  }, [actas]);

  const onYesNoButtonChanged = (key, tipoEleccion) => {
    let clon = { ...valores };
    clon[tipoEleccion] = { ...clon[tipoEleccion], ...key };
    setValores(clon);
    onChange(clon);
  };

  if (loading || actas.length === 0) {
    return <Loader />;
  }

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        className={classes.table}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">
              El paquete electoral se entregó
            </StyledTableCell>
            {actas.map((acta, i) => (
              <StyledTableCell key={i} align="center">
                {acta.nombreTipoEleccion}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" align="left">
              Con firma
            </StyledTableCell>
            {actas.map((acta, i) => (
              <StyledTableCell key={i} align="center">
                <YesNoButtonNested
                  validating={validating}
                  value={
                    valores[acta.nombreTipoEleccion]
                      ? valores[acta.nombreTipoEleccion]["conFirma"]
                      : undefined
                  }
                  target={"conFirma"}
                  onChange={(v) =>
                    onYesNoButtonChanged(v(), acta.nombreTipoEleccion)
                  }
                />
              </StyledTableCell>
            ))}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" align="left">
              Con muestras de alteración
            </StyledTableCell>
            {actas.map((acta, i) => (
              <StyledTableCell key={i} align="center">
                <YesNoButtonNested
                  inverted
                  validating={validating}
                  value={
                    valores[acta.nombreTipoEleccion]
                      ? valores[acta.nombreTipoEleccion]["muestraAlteracion"]
                      : undefined
                  }
                  target={"muestraAlteracion"}
                  onChange={(v) =>
                    onYesNoButtonChanged(v(), acta.nombreTipoEleccion)
                  }
                />
              </StyledTableCell>
            ))}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" align="left">
              Con cinta o etiqueta de seguridad
            </StyledTableCell>
            {actas.map((acta, i) => (
              <StyledTableCell key={i} align="center">
                <YesNoButtonNested
                  validating={validating}
                  value={
                    valores[acta.nombreTipoEleccion]
                      ? valores[acta.nombreTipoEleccion]["cintaDeSeguridad"]
                      : undefined
                  }
                  target={"cintaDeSeguridad"}
                  onChange={(v) =>
                    onYesNoButtonChanged(v(), acta.nombreTipoEleccion)
                  }
                />
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableBody>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">
              Por fuera del paquete se recibieron:
            </StyledTableCell>
            {actas.map((acta, i) => (
              <StyledTableCell key={i} align="center">
                {acta.nombreTipoEleccion}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" align="left">
              Un sobre para el PREP
            </StyledTableCell>
            {actas.map((acta, i) => (
              <StyledTableCell key={i} align="center">
                <YesNoButtonNested
                  validating={validating}
                  value={
                    valores[acta.nombreTipoEleccion]
                      ? valores[acta.nombreTipoEleccion]["sobreParaPREP"]
                      : undefined
                  }
                  target={"sobreParaPREP"}
                  onChange={(v) =>
                    onYesNoButtonChanged(v(), acta.nombreTipoEleccion)
                  }
                />
              </StyledTableCell>
            ))}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" align="left">
              Una bolsa que va por fuera del paquete electoral para el Consejo
              Municipal
            </StyledTableCell>
            {actas.map((acta, i) => (
              <StyledTableCell key={i} align="center">
                <YesNoButtonNested
                  validating={validating}
                  value={
                    valores[acta.nombreTipoEleccion]
                      ? valores[acta.nombreTipoEleccion]["bolsaConsejo"]
                      : undefined
                  }
                  target={"bolsaConsejo"}
                  onChange={(v) =>
                    onYesNoButtonChanged(v(), acta.nombreTipoEleccion)
                  }
                />
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActasTable;
