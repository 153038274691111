import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    color: "#000",
  },
  multilineColor: {
    color: "red",
  },
}));

export default function TimePicker({
  label,
  value,
  onChange,
  error,
  helperText,
  disabled,
  ...rest
}) {
  const classes = useStyles();
  const textFieldInnerRef = React.useRef(null);
  const textFieldInputRef = React.useRef(null);
  const inputLabelPropsInnerRef = React.useRef(null);

  return (
    <TextField
      // onClick={() => {
      //   console.log({
      //     textFieldInnerRef: textFieldInnerRef.current,
      //     textFieldInputRef: textFieldInputRef.current,
      //     inputLabelPropsInnerRef: inputLabelPropsInnerRef.current,
      //   });
      // }}
      disabled={disabled}
      id="time"
      size="small"
      label={label}
      fullWidth
      format={"h:m:s"}
      type="time"
      variant="outlined"
      value={value}
      error={error}
      helperText={helperText}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
        innerRef: inputLabelPropsInnerRef,
      }}
      // onFocus={(e) => console.log("algo")}
      inputProps={{
        step: 300, // 5 min
      }}
      innerRef={textFieldInnerRef}
      inputRef={textFieldInputRef}
      {...rest}
    />
  );
}
