import React from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import { useAuth } from "../../hooks/useAuth";

const Agendas = ({
  dialog,
  setEditingAgenda,
  setSelectedAgenda,
  handleClose,
}) => {
  const history = useHistory();
  const header = [];

  const { user } = useAuth();
  const [sinUsuario, setSinUsuario] = React.useState("");
  const [busqueda, setBusqueda] = React.useState("");
  const [apellidoPaterno, setApellidoPaterno] = React.useState("");
  const [apellidoMaterno, setApellidoMaterno] = React.useState("");
  const [idDistritoFederal, setIdDistritoFederal] = React.useState("");
  const [idDistritoLocal, setIdDistritoLocal] = React.useState("");
  const [clearDistritoLocal, setClearDistritoLocal] = React.useState(false);
  const [idSeccion, setIdSeccion] = React.useState("");
  const [clearSeccion, setClearSeccion] = React.useState(false);
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [idConsejoElectoral, setIdConsejo] = React.useState("");
  const [clearConsejo, setClearConsejo] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();
  const [mostrar, setMostrar] = React.useState(false);
  const [modelos, setModelos] = React.useState([]);
  const [agendasRequest, setAgendasRequest] = React.useState({});
  const [buscando, setBuscando] = React.useState(false);
  let aux = {};
  if (user)
    aux = {
      limite: 93,
      consejoUser: user.consejoElectoral.idConsejoElectoral,
      consejoExtra: user.consejosExtra,
    };
  else
    aux = {
      limite: 0,
    };

  const consejoParamsMemo = React.useMemo(() => aux, [
    idDistritoLocal,
    idMunicipio,
  ]);

  const agendaMemo = React.useMemo(() => {
    if (buscando && idConsejoElectoral) {
      return {
        name: "agenda",
        expand: "distrito,seccion,municipio,tipoAgenda,usuario,nombreCompleto",
        extraParams: {
          idConsejoElectoral: idConsejoElectoral,
        },
      };
    } else {
      return {};
    }
  }, [buscando, idConsejoElectoral]);

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels(agendaMemo);

  const setIdConsejoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdConsejo(v.idConsejoElectoral);
      } else {
        setIdConsejo("");
      }
    },
    [setIdConsejo]
  );

  const onSearchClicked = async () => {
    let params = {};
    setBuscando(true);
    if (busqueda.length > 0) params = { ...params, busqueda };
    if (idConsejoElectoral > 0) params = { ...params, idConsejoElectoral };
    if (sinUsuario.valor > 0)
      params = { ...params, sinUsuario: sinUsuario.valor };
    console.log("parametros a buscar", params);
    //    await refreshModels(true, params);
  };
  return (
    <TablePageLayout
      title="Módulo de Datos RAC"
      model="agenda"
      history={history}
      SearchComponents={
        <>
          <Grid item xs={12} sm={12} md={8}>
            <AsyncAutocompleteSearch
              label="Consejo"
              name="consejo-electoral-rac"
              labelProp="nombre"
              onChange={setIdConsejoCallback}
              extraParams={consejoParamsMemo}
              clearInput={clearConsejo}
            />
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre",
              cell: (row, index) => <div>{row.nombreCompleto}</div>,
            },
            {
              prop: "distrito",
              name: "Distrito",
              cell: (row, index) =>
                row.distrito ? (
                  <div>{row.distrito.nombre}</div>
                ) : (
                  <i>Sin distrito</i>
                ),
            },
            {
              prop: "seccion",
              name: "Sección",
              cell: (row, index) =>
                row.seccion ? (
                  <div>{row.seccion.nombre}</div>
                ) : (
                  <i>Sin sección</i>
                ),
            },
            {
              prop: "municipio",
              name: "Municipio",
              cell: (row, index) =>
                row.municipio ? (
                  <div>{row.municipio.nombre}</div>
                ) : (
                  <i>Sin municipio</i>
                ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    {dialog && (
                      <MenuItem
                        onClick={() => {
                          setSelectedAgenda(row);
                          handleClose();
                        }}
                      >
                        <Sync style={{ paddingRight: 5 }} />
                        Ligar
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() =>
                        history.push(`/consejeros/detalle?id=${row.idAgenda}`)
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Agendas;
