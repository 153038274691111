import React from "react";
import { useHistory } from "react-router";

import { Grid, Paper, Typography } from "@material-ui/core";
import { Autorenew, CloudDownload } from "@material-ui/icons";

import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import { generateDefaultChartOptions, getPercentage } from "../../utils";
import FullScreenLoader from "../../components/FullScreenLoader";
import PageLayout from "../../components/PageLayout";
import TablePagination from "../../components/TablePagination";
import { auth } from "../../firebase";

import { useModels } from "../../hooks/useModels";

highcharts3d(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

const ResultadosPreliminaresDetail = () => {
  const header = [];
  const history = useHistory();
  let chartOptions = generateDefaultChartOptions();
  let chartOptionsGenerales = generateDefaultChartOptions();

  const [models, modelsLoading, modelsError] = useModels({
    name: "lectura-voto/resultado",
  });

  const totales = models.length > 0 ? models[0] : {};

  let extraOptions = {
    colors: ["#9E9E9E", "#ad2624"],
    title: {
      text: "LECTURA DE ACTAS",
    },
    subtitle: {
      text: "CASILLAS TERMINADAS Y FALTANTES",
    },
    series: [
      {
        name: "Actas",
        data: [
          ["Sin Lectura", totales.sinlectura],
          ["Leídas", totales.leidas],
          // ["Sin Recibo Paquete", totales.sinrpe],
        ],
      },
    ],
  };
  let extraOptionsGenerales = {
    colors: ["#9E9E9E", "#ad2624"],
    title: {
      text: "CAPTURA DE DATOS GENERALES",
    },
    subtitle: {
      text: "ACTAS POR ESTATUS DE CAPTURA",
    },
    series: [
      {
        name: "ACTAS",
        data: [
          ["SIN DATOS GENERALES", totales.leidas - totales.datosGenerales],
          ["CON DATOS GENERALES", totales.datosGenerales],
          // ["Sin Recibo Paquete", totales.sinrpe],
        ],
      },
    ],
  };
  chartOptions = { ...chartOptions, ...extraOptions };
  chartOptionsGenerales = {
    ...chartOptionsGenerales,
    ...extraOptionsGenerales,
  };

  const datos = [
    {
      name: "Total",
      value: totales.esperados,
      percentage: "100.00%",
    },
    {
      name: "Sin Lectura",
      value: totales.sinlectura,
      percentage: getPercentage(
        parseInt(totales.sinlectura),
        parseInt(totales.sinlectura) + parseInt(totales.leidas)
      ),
    },
    {
      name: "Leídos",
      value: totales.leidas,
      percentage: getPercentage(
        parseInt(totales.leidas),
        parseInt(totales.sinlectura) + parseInt(totales.leidas)
      ),
    },
    {
      name: "Captura datos generales",
      value: totales.datosGenerales || 0,
      percentage: getPercentage(
        parseInt(totales.datosGenerales),
        parseInt(totales.leidas)
      ),
    },
  ];

  const downloadClicked = (_) => {
    const idUsuario = auth.currentUser.uid;
    window.open(
      "https://api.computosonora.com/v1/excel/reporte-lectura?uid=" + idUsuario,
      "_blank"
    );
  };

  if (modelsLoading) return <FullScreenLoader />;

  return (
    <PageLayout
      title="AVANCE DE LECTURA DE ACTAS Y CAPTURA DE DATOS GENERALES"
      history={history}
      links={[
        {
          to: `/lectura-de-votos/resultados-preliminares`,
          icon: <Autorenew fontSize="small" />,
          label: `Resultados Preliminares`,
        },
      ]}
    >
      <Grid container component={Paper} spacing={3}>
        <Grid item xs={4} style={{ marginTop: 100, paddingLeft: 25 }}>
          <TablePagination
            header={header}
            data={datos}
            extraRows={[
              {
                prop: "name",
                name: "",
                cell: (row, index) => (
                  <Typography style={{ fontSize: 24 }}>
                    <strong>{row.name}</strong>
                  </Typography>
                ),
              },
              {
                prop: "value",
                name: "CANTIDAD",
                cell: (row, index) => (
                  <Typography style={{ fontSize: 20 }}>{row.value}</Typography>
                ),
              },
              {
                prop: "percentage",
                name: "PORCENTAJE",
                cell: (row, index) => (
                  <Typography style={{ fontSize: 20 }}>
                    {row.percentage}
                  </Typography>
                ),
              },
            ]}
          />
        </Grid>

        <Grid item xs={8}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={8}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptionsGenerales}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ResultadosPreliminaresDetail;
