import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import PrivateRoute from "../components/PrivateRoute";
import DashboardLayout from "../components/DashboardLayout";
import FullScreenLoader from "../components/FullScreenLoader";
import { useAuth } from "../hooks/useAuth";
import { useConfirmDialog } from "../hooks/useConfirmDialog";

import Home from "../views/home";
import Perfil from "../views/cuenta/perfil";
import Configuracion from "../views/cuenta/configuracion";
import Usuarios from "../views/usuarios";
import UsuarioDetalle from "../views/usuarios/detail";
import Agendas from "../views/agendas";
import Consejeros from "../views/consejeros";
import AgendasDetalle from "../views/agendas/detail";
import ConsejerosDetalle from "../views/consejeros/detail";
import Casillas from "../views/casillas";
import CasillaDetalle from "../views/casillas/detail";
import TiposEleccion from "../views/tiposEleccion";
import TipoEleccionDetalle from "../views/tiposEleccion/detail";
import TiposCasila from "../views/tiposCasilla";
import TipoCasilaDetalle from "../views/tiposCasilla/detail";
import TiposActa from "../views/tiposActa";
import TipoActaDetalle from "../views/tiposActa/detail";
import Estados from "../views/estados";
import EstadoDetalle from "../views/estados/detail";
import ModelosActa from "../views/modelosActa";
import ConsejoPersonal from "../views/consejoPersonal/detail";
import ClausuraCasillaCasillas from "../views/clausuraCasilla/casillas";
import ClausuraCasilla from "../views/clausuraCasilla/clausuraCasilla";
import Municipios from "../views/municipios";
import MunicipioDetalle from "../views/municipios/detail";
import Consejos from "../views/consejos";
import ConsejoDetalle from "../views/consejos/detail";
import Qr from "../views/qr";
import RecepcionesMunicipales from "../views/recepcionesMunicipales";
import NuevaRecepcionMunicipal from "../views/recepcionesMunicipales/nuevaRecepcion";
import DetalleRecepcion from "../views/recepcionesMunicipales/detalleRecepcion";
import AvanceBodegaElectoral from "../views/estadisticos/avanceBodegaElectoral/avanceBodegaElectoral";
import ActasDetalle from "../views/actas";
import ActasLecturaDeVotosDetalle from "../views/actas/detail";
import DatosGenerales from "../views/actas/datosGeneralesIndex";
import DatosGeneralesDetalle from "../views/actas/datosGeneralesDetail";
import ResultadosPreliminaresDetalle from "../views/actas/resultadosDetail";
import Log from "../views/log";
import Bodegas from "../views/bodegas/index";
import BodegasDetalle from "../views/bodegas/bodegasDetail";
import MovimientosBodegas from "../views/bodegas/movimientosBodegas/movimientosBodegas";
import BodegasAdministracionDetalle from "../views/bodegas/administracionDetail";
import BodegasAdministracionBodegaDetalle from "../views/bodegas/administracionBodegaDetail";
import BodegasAdministracionPaquetesDetalle from "../views/bodegas/administracionPaquetesDetail";
import BodegasAdministracionEnvioDistritalDetalle from "../views/bodegas/administracionEnvioDistritalDetail";
import BitacoraPaquetesDetalle from "../views/bodegas/paquetesDetail";
import EnvioPaquete from "../views/bodegas/envioPaquetes/envioPaquete";
import RecepcionPaquetes from "../views/bodegas/recepcionPaquetes/recepcionPaquetes";
import BodegasAperturaDetalle from "../views/bodegas/aperturaDetail";
import BodegasCierreDetalle from "../views/bodegas/cierreDetail";
import AvanceRecepcionPaquetesDetalle from "../views/estadisticos/paquetesDetail";
import ReunionDeTrabajoDetalle from "../views/reunionDeTrabajo";
import AvanceGruposTrabajoDetalle from "../views/reunionDeTrabajo/reportes";
import MotivoAperturaCierres from "../views/motivoAperturaCierre/index";
import MotivoAperturaCierreDetalle from "../views/motivoAperturaCierre/detail";
import TipoDomicilio from "../views/tiposDomicilio/index";
import TiposDomicilioDetalle from "../views/tiposDomicilio/detail";
import Recursos from "../views/recursos/index";
import RecursosDetail from "../views/recursos/detail";
import Archivos from "../views/archivos/index";
import Pdfs from "../views/archivos/pdf";
import ResultadosVotacionDetalle from "../views/computo/resultadosDetail";
import Scripts from "../views/scripts";
import PartidosPoliticos from "../views/partidosPoliticos/index";
import PartidosPoliticosDetail from "../views/partidosPoliticos/detail";
import Candidatos from "../views/candidatos/index";
import CandidatosDetail from "../views/candidatos/detail";
import CandidatosIndependientes from "../views/candidatosIndependientes/index";
import CandidatosIndependientesDetail from "../views/candidatosIndependientes/detail";
import Coaliciones from "../views/coaliciones/index";
import CoalicionesDetail from "../views/coaliciones/detail";
import CombinacionCoalicion from "../views/combinacionCoalicion/index";
import CombinacionCoalicionDetail from "../views/combinacionCoalicion/detail";
import CandidaturaComun from "../views/candidaturaComun/index";
import CandidaturaComunDetail from "../views/candidaturaComun/detail";
import Secciones from "../views/secciones/index";
import SeccionesDetail from "../views/secciones/detail";
import Distritos from "../views/distritos/index";
import DistritosDetail from "../views/distritos/detail";
import TipoAsistentes from "../views/tipoAsistente/index";
import TipoAsistentesDetail from "../views/tipoAsistente/detail";
import CalculoDeRecuento from "../views/gruposTrabajo/index";
import GruposTrabajo from "../views/gruposTrabajo/grupos/gruposTrabajo";
import PuntosRecuento from "../views/gruposTrabajo/grupos/puntosRecuento";
import AdministracionGruposTrabajo from "../views/gruposTrabajo/administracionGruposTrabajo/administracionGruposTrabajo";
import SesionExtraordinaria from "../views/sesionExtraordinaria";
import GruposTrabajoDetail from "../views/gruposTrabajo/detail";
import IntegrantesDetail from "../views/gruposTrabajo/grupos/integrantes";
import Cotejo from "../views/computo/cotejo";
import CotejoListado from "../views/computo/cotejoListado";
import Recuento from "../views/computo/recuento";
import RecuentoListado from "../views/computo/recuentoListado";
import CotejoDetail from "../views/computo/cotejoDetail";
import RecuentoDetail from "../views/computo/recuentoDetail";
import SesionExtraordinariaDetail from "../views/sesionExtraordinaria/index";
import SignOut from "../views/SignOut";
import NotFound from "../views/shared/NotFound";
import RecuentoGt from "../views/computo/recuentoGt";
import RecuentoGtListado from "../views/computo/recuentoGtListado";
import RecuentoGtReservado from "../views/computo/recuentoGtReservado";
import RecuentoGtReservadoListado from "../views/computo/recuentoGtReservadoListado";
import RecuentoGtDetail from "../views/computo/recuentoGtDetail";
import RecuentoGtReservadoDetail from "../views/computo/recuentoGtReservadoDetail";
import PaquetesConsejoDetail from "../views/estadisticos/paquetesConsejoDetail";
import ReporteEIEDEA from "../views/estadisticos/reporteEIEDEA";
import informeRecuentoDetail from "../views/estadisticos/informeRecuentoDetail";
import InformeRecuentoConsejo from "../views/estadisticos/informeRecuentoConsejo";
import EstadisticoLecturaVotos from "../views/estadisticos/lecturaVotos";
import AvanceLecturaActas from "../views/estadisticos/avanceLecturaActas ";
import ReporteGtp from "../views/estadisticos/reporteGtp";
import AvanceLecturaActasGubernatura from "../views/estadisticos/avanceLecturaActasGubernatura";
import AvanceRecepcionEstadistica from "../views/avanceRecepcion/avanceRecepcion";
import AvanceRecepcionEstadisticaGubernatura from "../views/avanceRecepcionGubernatura";
import NuevaRectificacion from "../views/reunionDeTrabajo/nuevaRectificacion";
import LecturaListado from "../views/actas/listado";
import DatosGeneralesListado from "../views/actas/datosGeneralesListado";
import ControlCapacitacion from "../views/ControlCapacitacion";
import BitacoraEntradaPaquete from "../views/bitacoraEntrada";
import BitacoraEntradaPaqueteGubernatura from "../views/bitacoraEntradaGubernatura";
import MonitorEleccion from "../views/estadisticos/monitorEleccion/monitorEleccion";
import EstadisticoCotejoRecuento from "../views/estadisticos/cotejoRecuento";
import EstadisticosGlobal from "../views/estadisticos/global";
import AvanceRecepcion from "../views/recepcionesMunicipales/avanceRecepcion";
import EstadisticoGruposTrabajo from "../views/estadisticos/gruposTrabajo";
import EstadisticoInformacionComplementaria from "../views/estadisticos/informacionComplementaria";
import ConsejoElectoralPermiso from "../views/consejos/permisos";
import ConfiguracionMenu from "../views/ConfiguracionMenu";
import AcreditacionRepresentantes from "../views/computo/acreditacionRepresentantes";
import ConteoPreliminar from "../views/conteoPreliminar";
import ReporteClausura from "../views/estadisticos/reporteClausura/reporteClausura";
import NoAutorizado from "../views/NoAutorizado";
import ControlActas from "../views/controlActas/ControlActas";
import DescargarActasComputo from "../views/descargarActasComputo";
import Planilla from "../views/planillas";
import PlanillaDetail from "../views/planillas/detail";
import AvanceBodegasGubernatura from "../views/estadisticos/avanceBodegasGubernatura";
import LecturaVirtual from "../views/LecturaVirtual";

const PrivateRoutes = () => {
  const { user, userLoading } = useAuth();
  const {
    open: openDialog,
    dialogTitle,
    dialogMessage,
    closeDialog,
  } = useConfirmDialog();

  const ConfirmDialogMemo = React.useMemo(
    () => (
      <Dialog
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="confirm-dialog-id"
      >
        <DialogTitle id="confirm-dialog-id">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Correo electrónico"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={closeDialog} color="secondary" variant="outlined">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [dialogTitle, dialogMessage, openDialog, closeDialog]
  );

  if (!user && userLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Router>
      <DashboardLayout>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <PrivateRoute exact path="/bodegas" component={Bodegas} /> */}
          {/*<PrivateRoute exact path="/bodega/nuevo" component={BodegaDetail} />
          <PrivateRoute
            exact
            path="/bodega/editar/:id"
            component={(props) => <BodegaDetail {...props} />}
          />
          <PrivateRoute exact path="/paquetes" component={Paquetes} />
          <PrivateRoute exact path="/paquete/nuevo" component={PaqueteDetail} />
          <PrivateRoute
            exact
            path="/paquete/editar/:id"
            component={(props) => <PaqueteDetail {...props} />}
          />
          <PrivateRoute exact path="/estados" component={Estados} />
          <PrivateRoute exact path="/estados/nuevo" component={EstadoDetail} />
          <PrivateRoute
            exact
            path="/estado/editar/:id"
            component={(props) => <EstadoDetail {...props} />}
          />
          <PrivateRoute exact path="/municipios" component={Municipios} />
          <PrivateRoute
            exact
            path="/municipio/nuevo"
            component={MunicipioDetail}
          />
          <PrivateRoute
            exact
            path="/municipio/editar/:id"
            component={(props) => <MunicipioDetail {...props} />}
          /> */}
          {/* USUARIOS*/}
          {/* CUENTA */}
          <Route exact path="/perfil" component={Perfil} />
          <Route exact path="/configuracion" component={Configuracion} />
          {/* USUARIOS */}
          <PrivateRoute
            exact
            path="/usuarios"
            component={Usuarios}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/usuarios/nuevo"
            component={UsuarioDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/usuarios/:idUsuario"
            component={UsuarioDetalle}
            permisoCheck="panelControl"
          />
          {/* AGENDAS */}
          <PrivateRoute
            exact
            path="/agendas"
            component={Agendas}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/agendas/nuevo"
            component={AgendasDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/agendas/detalle"
            component={AgendasDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/consejeros/detalle"
            component={ConsejerosDetalle}
            permisoCheck="panelControl"
          />
          {/* ESTADOS*/}
          <PrivateRoute
            exact
            path="/estados"
            component={Estados}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/estados/nuevo"
            component={EstadoDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/estados/:id"
            component={EstadoDetalle}
            permisoCheck="panelControl"
          />
          {/*MUNICIPIOS*/}
          <PrivateRoute
            exact
            path="/municipios"
            component={Municipios}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/municipios/nuevo"
            component={MunicipioDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/municipios/:id"
            component={MunicipioDetalle}
            permisoCheck="panelControl"
          />
          {/*CONSEJOS*/}
          <PrivateRoute
            exact
            path="/consejos-electorales"
            component={Consejos}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/consejos-electorales/nuevo"
            component={ConsejoDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/consejos-electorales/permisos"
            component={ConsejoElectoralPermiso}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/consejos-electorales/:id"
            component={ConsejoDetalle}
            permisoCheck="panelControl"
          />
          {/*TIPOS CASILLA*/}
          <PrivateRoute
            exact
            path="/tipos-casilla"
            component={TiposCasila}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipos-casilla/nuevo"
            component={TipoCasilaDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipos-casilla/:id"
            component={TipoCasilaDetalle}
            permisoCheck="panelControl"
          />
          {/*CASILLA*/}
          <PrivateRoute
            exact
            path="/casillas"
            component={Casillas}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/casillas/nuevo"
            component={CasillaDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/casillas/:id"
            component={CasillaDetalle}
            permisoCheck="panelControl"
          />
          {/*TIPOS ACTA*/}
          <PrivateRoute
            exact
            path="/tipos-acta"
            component={TiposActa}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipos-acta/nuevo"
            component={TipoActaDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipos-acta/:id"
            component={TipoActaDetalle}
            permisoCheck="panelControl"
          />
          {/*TIPOS ELECCIÓN*/}
          <PrivateRoute
            exact
            path="/tipos-eleccion"
            component={TiposEleccion}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipos-eleccion/nuevo"
            component={TipoEleccionDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipos-eleccion/:id"
            component={TipoEleccionDetalle}
            permisoCheck="panelControl"
          />
          {/*MOTIVO APERTURA CIERRE*/}
          <PrivateRoute
            exact
            path="/motivo-apertura-cierre"
            component={MotivoAperturaCierres}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/motivo-apertura-cierre/nuevo"
            component={MotivoAperturaCierreDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/motivo-apertura-cierre/:id"
            component={MotivoAperturaCierreDetalle}
            permisoCheck="panelControl"
          />
          {/*TIPO DOMICILIO*/}
          <PrivateRoute
            exact
            path="/tipos-domicilio"
            component={TipoDomicilio}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipos-domicilio/nuevo"
            component={TiposDomicilioDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipos-domicilio/:id"
            component={TiposDomicilioDetalle}
            permisoCheck="panelControl"
          />
          {/* ARCHIVOS */}
          <PrivateRoute
            exact
            path="/archivos"
            component={Archivos}
            permisoCheck="panelControl"
          />
          {/* ARCHIVOS PDF */}
          <PrivateRoute
            exact
            path="/pdfs"
            component={Pdfs}
            permisoCheck="panelControl"
          />
          {/* PAQUETES ELECTORALES - RECEPCIONES */}
          <PrivateRoute
            exact
            path="/bodegas/bitacora-de-paquetes/recepcion-paquetes"
            component={RecepcionPaquetes}
            permisoCheck="bodegaPaquetes"
          />
          <PrivateRoute
            exact
            path="/recepciones-municipales"
            component={RecepcionesMunicipales}
            permisoCheck="paquetesRecepciones"
          />
          <PrivateRoute
            exact
            path="/recepciones-municipales/nueva"
            component={NuevaRecepcionMunicipal}
            permisoCheck="paquetesRecepciones"
          />
          <PrivateRoute
            exact
            path="/recepciones-municipales/detalle"
            component={DetalleRecepcion}
            permisoCheck="paquetesRecepciones"
          />
          <PrivateRoute
            exact
            path="/recepciones-municipales/avance"
            component={AvanceRecepcion}
            permisoCheck="paquetesRecepciones"
          />
          {/* PAQUETES ELECTORALES - LECTURAS ESTATALES */}
          <PrivateRoute
            exact
            path="/lectura-estatal"
            component={LecturaListado}
            permisoCheck="lecturaEstatal"
          />
          <PrivateRoute
            exact
            path="/lectura-estatal/nueva"
            component={ActasDetalle}
            permisoCheck="lecturaEstatal"
          />
          <PrivateRoute
            exact
            path="/lectura-estatal/detalle"
            component={ActasLecturaDeVotosDetalle}
            permisoCheck="lecturaEstatal"
          />
          <PrivateRoute
            exact
            path="/lectura-estatal/datos-generales"
            component={DatosGeneralesListado}
            permisoCheck="lecturaEstatal"
          />
          <PrivateRoute
            exact
            path="/lectura-estatal/datos-generales/nueva"
            component={DatosGenerales}
            permisoCheck="lecturaEstatal"
          />
          <PrivateRoute
            exact
            path="/lectura-estatal/datos-generales/detalle"
            component={DatosGeneralesDetalle}
            permisoCheck="lecturaEstatal"
          />
          {/* ACTAS */}
          <PrivateRoute
            exact
            path="/lectura-de-votos"
            component={LecturaListado}
            permisoCheck="actaLectura"
          />
          <PrivateRoute
            exact
            path="/lectura-de-votos/nueva"
            component={ActasDetalle}
            permisoCheck="actaLectura"
          />
          <PrivateRoute
            exact
            path="/lectura-de-votos/detalle"
            component={ActasLecturaDeVotosDetalle}
            permisoCheck="actaLectura"
          />
          <PrivateRoute
            exact
            path="/lectura-de-votos/datos-generales"
            component={DatosGeneralesListado}
            permisoCheck="actaDatosGenerales"
          />
          <PrivateRoute
            exact
            path="/lectura-de-votos/datos-generales/nueva"
            component={DatosGenerales}
            permisoCheck="actaDatosGenerales"
          />
          <PrivateRoute
            exact
            path="/lectura-de-votos/datos-generales/detalle"
            component={DatosGeneralesDetalle}
            permisoCheck="actaDatosGenerales"
          />
          <PrivateRoute
            exact
            path="/lectura-de-votos/resultados-preliminares"
            component={ResultadosPreliminaresDetalle}
            permisoCheck="actaResultados"
          />
          <PrivateRoute
            exact
            path={"/consejeros"}
            component={Consejeros}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path={"/logs"}
            component={Log}
            permisoCheck="panelControl"
          />
          {/* BODEGAS */}
          <PrivateRoute
            exact
            path="/bodegas/administracion"
            component={BodegasAdministracionDetalle}
            permisoCheck="bodegaAdministracion"
          />
          <PrivateRoute
            exact
            path="/bodegas/administracion/bodega"
            component={BodegasAdministracionBodegaDetalle}
            permisoCheck="bodegaAdministracion"
          />
          <PrivateRoute
            exact
            path="/bodegas/administracion/paquete"
            component={BodegasAdministracionPaquetesDetalle}
            permisoCheck="bodegaAdministracion"
          />
          <PrivateRoute
            exact
            path="/bodegas/administracion/envio-distrital"
            component={BodegasAdministracionEnvioDistritalDetalle}
            permisoCheck="bodegaAdministracion"
          />
          <PrivateRoute
            exact
            path="/bodegas/bitacora-de-paquetes"
            component={BitacoraPaquetesDetalle}
            permisoCheck="bodegaPaquetes"
          />
          <PrivateRoute
            exact
            path="/bodegas/bitacora-de-paquetes/envio-paquetes"
            component={EnvioPaquete}
            permisoCheck="bodegaPaquetes"
          />
          <PrivateRoute
            exact
            path="/bodegas/bitacora-de-paquetes/recepcion-paquetes"
            component={RecepcionPaquetes}
            permisoCheck="bodegaPaquetes"
          />
          <PrivateRoute
            exact
            path="/bodegas/apertura"
            component={BodegasAperturaDetalle}
            permisoCheck="bodegaAdministracion"
          />
          <PrivateRoute
            exact
            path="/bodegas/cierre"
            component={BodegasCierreDetalle}
            permisoCheck="bodegaAdministracion"
          />
          <PrivateRoute
            exact
            path="/bodegas"
            component={Bodegas}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/bodegas/nuevo"
            component={BodegasDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/bodegas/detalle"
            component={BodegasDetalle}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/bodegas/movimientos-de-bodegas"
            component={MovimientosBodegas}
            permisoCheck="bodegaPaquetes"
          />
          <PrivateRoute
            exact
            path="/estadisticos/reportes-eiedea"
            component={ReporteEIEDEA}
            permisoCheck="estadisticaCausalesAritmeticas"
          />
          <PrivateRoute
            exact
            path="/estadisticos/avance-recepcion-paquetes"
            component={AvanceRecepcionPaquetesDetalle}
            permisoCheck="estadisticaAvanceRecepcion"
          />
          <PrivateRoute
            exact
            path="/estadisticos/avance-recepcion-paquetes/detalle"
            component={PaquetesConsejoDetail}
            permisoCheck="estadisticaAvanceRecepcion"
          />
          <PrivateRoute
            exact
            path="/estadisticos/avance-bodega-electoral"
            component={AvanceBodegaElectoral}
            permisoCheck="estadisticaAvanceBodega"
          />
          <PrivateRoute
            exact
            path="/estadisticos/cotejo-recuento"
            component={EstadisticoCotejoRecuento}
            permisoCheck="estadisticaCotejoRecuento"
          />
          <PrivateRoute
            exact
            path="/estadisticos/informe-recuento"
            component={informeRecuentoDetail}
            permisoCheck="estadisticaInformeRecuento"
          />
          <PrivateRoute
            exact
            path="/estadisticos/informe-recuento/detalle"
            component={InformeRecuentoConsejo}
            permisoCheck="estadisticaInformeRecuento"
          />
          <PrivateRoute
            exact
            path="/estadisticos/lectura-votos"
            component={EstadisticoLecturaVotos}
            permisoCheck="estadisticaLecturaVotos"
          />
          <PrivateRoute
            exact
            path="/estadisticos/avance-lectura-acta"
            component={AvanceLecturaActas}
            permisoCheck="estadisticaLecturaCarrusel"
          />
          <PrivateRoute
            exact
            path="/estadisticos/avance-lectura-acta-gubernatura"
            component={AvanceLecturaActasGubernatura}
            permisoCheck="estadisticaLecturaCarruselGub"
          />
          <PrivateRoute
            exact
            path="/estadisticos/avance-bodega-gubernatura"
            component={AvanceBodegasGubernatura}
            permisoCheck="estadisticaLecturaCarruselGub"
          />
          <PrivateRoute
            exact
            path="/estadisticos/lectura-de-votos"
            component={AvanceLecturaActasGubernatura}
            permisoCheck="estadisticaLecturaVotos"
          />
          <PrivateRoute
            exact
            path="/estadisticos/informacion-complementaria"
            component={EstadisticoInformacionComplementaria}
            permisoCheck="estadisticaInfoComplementaria"
          />
          <PrivateRoute
            exact
            path="/estadisticos/monitor-eleccion"
            component={MonitorEleccion}
            permisoCheck="estadisticaMonitorEleccion"
          />
          <PrivateRoute
            exact
            path="/estadisticos/grupos-trabajo"
            component={EstadisticoGruposTrabajo}
            permisoCheck="estadisticaGT"
          />
          <PrivateRoute
            exact
            path="/estadisticos/reporte-gtp"
            component={ReporteGtp}
            permisoCheck="estadisticaGT"
          />
          <PrivateRoute
            exact
            path="/estadisticos/global"
            component={EstadisticosGlobal}
            permisoCheck="estadisticaGlobal"
          />
          <PrivateRoute
            exact
            path="/estadisticos/reporte-clausura"
            component={ReporteClausura}
            permisoCheck="estadisticaReportesClausura"
          />
          <PrivateRoute
            exact
            path="/reunion-de-trabajo"
            component={ReunionDeTrabajoDetalle}
            permisoCheck="reunionTrabajoReportes"
          />
          <PrivateRoute
            exact
            path="/rectificacion"
            component={NuevaRectificacion}
            permisoCheck="reunionTrabajoRectificacion"
          />
          {/* <PrivateRoute
            exact
            path="/rectificacion/:estatus"
            component={RectificacionSeccionDetalle}
          />
          <PrivateRoute
            exact
            path="/rectificacion/:estatus/:seccion"
            component={RectificacionSeccionCasillaDetalle}
          /> */}
          <PrivateRoute
            exact
            path="/reunion-de-trabajo/informe"
            component={AvanceGruposTrabajoDetalle}
            permisoCheck="reunionTrabajoReportes"
          />
          <PrivateRoute
            exact
            path="/recursos"
            component={Recursos}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/recursos/nuevo"
            component={RecursosDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/recursos/:idRecurso"
            component={RecursosDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/computo/resultados"
            component={ResultadosVotacionDetalle}
            permisoCheck="computoResultadosVotacion"
          />
          <PrivateRoute
            exact
            path="/recuento-gt"
            component={RecuentoGtListado}
            permisoCheck="computoRecuentoGt"
          />
          <PrivateRoute
            exact
            path="/recuento-gt/nuevo"
            component={RecuentoGt}
            permisoCheck="computoRecuentoGt"
          />
          <PrivateRoute
            exact
            path="/recuento-gt/nuevo/detalle"
            component={RecuentoGtDetail}
            permisoCheck="computoRecuentoGt"
          />
          <PrivateRoute
            exact
            path="/recuento-gt-reservado"
            component={RecuentoGtReservadoListado}
            permisoCheck="computoRecuentoGtRes"
          />
          <PrivateRoute
            exact
            path="/recuento-gt-reservado/nuevo"
            component={RecuentoGtReservado}
            permisoCheck="computoRecuentoGtRes"
          />
          <PrivateRoute
            exact
            path="/recuento-gt-reservado/nuevo/detalle"
            component={RecuentoGtReservadoDetail}
            permisoCheck="computoRecuentoGtRes"
          />
          <Route
            exact
            path="/control-capacitacion"
            component={ControlCapacitacion}
          />
          <PrivateRoute
            exact
            path="/scripts"
            component={Scripts}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/partidos-politicos"
            component={PartidosPoliticos}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/partidos-politicos/nuevo"
            component={PartidosPoliticosDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/partidos-politicos/detalle"
            component={PartidosPoliticosDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatos"
            component={Candidatos}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatos/nuevo"
            component={CandidatosDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatos/detalle"
            component={CandidatosDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatos-independientes"
            component={CandidatosIndependientes}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatos-independientes/nuevo"
            component={CandidatosIndependientesDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatos-independientes/detalle"
            component={CandidatosIndependientesDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/coaliciones"
            component={Coaliciones}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/coaliciones/nuevo"
            component={CoalicionesDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/coaliciones/detalle"
            component={CoalicionesDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/secciones"
            component={Secciones}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/secciones/nuevo"
            permisoCheck="panelControl"
            component={SeccionesDetail}
          />
          <PrivateRoute
            exact
            path="/secciones/detalle"
            component={SeccionesDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/distritos"
            component={Distritos}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/distritos/nuevo"
            component={DistritosDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/distritos/detalle"
            component={DistritosDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipo-asistentes"
            component={TipoAsistentes}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipo-asistentes/nuevo"
            component={TipoAsistentesDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/tipo-asistentes/detalle"
            component={TipoAsistentesDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/combinacion-coalicion"
            component={CombinacionCoalicion}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/combinacion-coalicion/nuevo"
            component={CombinacionCoalicionDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/combinacion-coalicion/detalle"
            component={CombinacionCoalicionDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatura-comun"
            component={CandidaturaComun}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatura-comun/nuevo"
            component={CandidaturaComunDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/candidatura-comun/detalle"
            component={CandidaturaComunDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/grupos-trabajo"
            component={CalculoDeRecuento}
            permisoCheck="gruposDeTrabajoRepresentantes"
          />
          <PrivateRoute
            exact
            path="/grupos-trabajo/grupos"
            component={GruposTrabajo}
            permisoCheck="gruposDeTrabajoRepresentantes"
          />
          <PrivateRoute
            exact
            path="/grupos-trabajo/recuentos-pr"
            component={PuntosRecuento}
            permisoCheck="gruposDeTrabajoRepresentantes"
          />
          <PrivateRoute
            exact
            path="/administracion-grupos-trabajo"
            component={AdministracionGruposTrabajo}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/sesion-extraordinaria"
            component={SesionExtraordinaria}
            permisoCheck="sesionExtraordinaria"
          />
          <PrivateRoute
            exact
            path="/grupos-trabajo/detalle"
            component={GruposTrabajoDetail}
            permisoCheck="gruposDeTrabajoRepresentantes"
          />
          <PrivateRoute
            exact
            path="/grupos-trabajo/grupos/integrantes"
            component={IntegrantesDetail}
            permisoCheck="gruposDeTrabajoRepresentantes"
          />
          <PrivateRoute
            exact
            path="/cotejo"
            component={CotejoListado}
            permisoCheck="computoCotejo"
          />
          <PrivateRoute
            exact
            path="/cotejo/nuevo"
            component={Cotejo}
            permisoCheck="computoCotejo"
          />
          <PrivateRoute
            exact
            path="/cotejo/nuevo/detalle"
            component={CotejoDetail}
            permisoCheck="computoCotejo"
          />
          <PrivateRoute
            exact
            path="/recuento"
            component={RecuentoListado}
            permisoCheck="computoRecuento"
          />
          <PrivateRoute
            exact
            path="/recuento/nuevo"
            component={Recuento}
            permisoCheck="computoRecuento"
          />
          <PrivateRoute
            exact
            path="/recuento/nuevo/detalle"
            component={RecuentoDetail}
            permisoCheck="computoRecuento"
          />
          <PrivateRoute
            exact
            path="/acreditacion-representantes"
            component={AcreditacionRepresentantes}
            permisoCheck="acreditacionRep"
          />
          <PrivateRoute
            exact
            path="/modelos-acta"
            component={ModelosActa}
            permisoCheck="panelControl"
          />
          <Route
            exact
            path="/clausura-casillas"
            component={ClausuraCasillaCasillas}
          />
          <Route
            exact
            path="/clausura-casillas/casilla/:idCasilla"
            component={ClausuraCasilla}
          />
          <Route
            exact
            path="/consejo-personal/:id"
            component={ConsejoPersonal}
          />
          <PrivateRoute
            exact
            path="/sesion-extraordinaria"
            component={SesionExtraordinariaDetail}
            permisoCheck="sesionExtraordinaria"
          />
          {/*Qr*/}
          <PrivateRoute
            exact
            path="/qr"
            component={Qr}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/conteo-preliminar"
            component={ConteoPreliminar}
            permisoCheck="estadisticaPreliminar"
          />
          <PrivateRoute
            exact
            path="/control-actas"
            component={ControlActas}
            permisoCheck="controlActas"
          />
          <PrivateRoute
            exact
            path="/estadisticos/avance-recepcion"
            component={AvanceRecepcionEstadistica}
            permisoCheck="estadisticaRecepcionCarrusel"
          />
          <PrivateRoute
            exact
            path="/estadisticos/avance-recepcion-gubernatura"
            component={AvanceRecepcionEstadisticaGubernatura}
            permisoCheck="estadisticaRecepcionCarruselGub"
          />
          <PrivateRoute
            exact
            path="/estadisticos/bitacora-entrada-paquetes"
            component={BitacoraEntradaPaquete}
            permisoCheck="estadisticaBitacoraCarrusel"
          />
          <PrivateRoute
            exact
            path="/estadisticos/bitacora-entrada-paquetes-gubernatura"
            component={BitacoraEntradaPaqueteGubernatura}
            permisoCheck="estadisticaBitacoraCarruselGub"
          />
          <PrivateRoute
            exact
            path="/avance-computo/descargar"
            component={DescargarActasComputo}
            permisoCheck="panelControl"
          />
          <Route exact path="/lectura-virtual" component={LecturaVirtual} />
          {/* planillas*/}
          <PrivateRoute
            exact
            path="/planillas"
            component={Planilla}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/planillas/nuevo"
            component={PlanillaDetail}
            permisoCheck="panelControl"
          />
          <PrivateRoute
            exact
            path="/planillas/:id"
            component={PlanillaDetail}
            permisoCheck="panelControl"
          />
          {/* RUTA DE NO ENCONTRADO */}
          <Route path="/logout" component={SignOut} />
          <PrivateRoute
            path="/config-menu"
            component={ConfiguracionMenu}
            permisoCheck="controlMenuLateral"
          />
          <Route path="/no-autorizado" component={NoAutorizado} />
          <Route path="*" component={NotFound} />
        </Switch>
        {ConfirmDialogMemo}
      </DashboardLayout>
    </Router>
  );
};

export default PrivateRoutes;
