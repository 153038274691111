import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Switch,
  Box,
  IconButton,
  Card,
} from "@material-ui/core";
import {
  CloseOutlined,
  Person,
  Save,
  SignalCellularAltSharp,
  Add,
  Close,
} from "@material-ui/icons";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import ListAltIcon from "@material-ui/icons/ListAlt";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AsyncSelect from "../../../components/AsyncSelect";
import AsyncAutocompleteSearch from "../../../components/AsyncAutocompleteSearch";
import CustomDialog from "../../../components/CustomDialog";
import PageHeader from "../../../components/PageHeader";
import RegexTextField from "../../../components/regexTextField";
import FullScreenLoader from "../../../components/FullScreenLoader";
import SingleSelect from "../../../components/SingleSelect";
import { useModel } from "../../../hooks/useModel";
import { useModels } from "../../../hooks/useModels";
import { useQuery } from "../../../hooks/useQuery";
import services from "../../../services";
import { tipoPuesto } from "../../../constants/mockData";
import Radio from "@material-ui/core/Radio";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../hooks/useAuth";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useAlert } from "../../../hooks/useAlert";
import Services from "../../../services";
import DatePicker from "../../../components/DatePicker";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertDialog from "../../../components/AlertDialog";
import DialogReprecentante from "./dialogReprecentante";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TablePagination from "../../../components/TablePagination";
import SearchIcon from "@material-ui/icons/Search";
import PdfDialog from "../../../components/PdfDialog";
import { auth } from "../../../firebase";
import { baseUrl, baseUrlPdf } from "../../../utils/variables";
import CircularProgress from "@material-ui/core/CircularProgress";
import AsyncCreatableAutocomplete from "../../../components/AsyncCreatableAutocomplete";
import CreateEntryDialog from "../../../components/CreateEntryDialog";

const onlyAlphanumericRegex = /[^0-9]/gim;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
    fontSize: 20,
  },
  formControl: {
    width: "100%",
  },
}));

const idsPuestoReprensete = [11, 12];

function IntegrantesDetail({
  match,
  history,
  dialog,
  editingConsejoElectoral,
  setConsejoElectoralSearch,
}) {
  const { showAlert } = useAlert();
  const { user, userLoading } = useAuth();
  const classes = useStyles();
  const query = useQuery();
  const idGrupoTrabajo = query.get("id") || undefined;
  const editing = !!idGrupoTrabajo;
  const n = query.get("n") || undefined;

  const [saving, setSaving] = useState(false);

  const [idGt, setIdGt] = useState("");
  const [idActa, setIdActa] = useState("");
  const [integrantes, setIntegrantes] = useState([]);
  const [puntosRecuento, setPuntosRecuento] = useState([]);
  const [representantes, setRepresentantes] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [indexRepresentante, setIndexRepresentante] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [sacar, setSacar] = useState([]);
  const [sacarId, setSacarId] = useState(null);
  const [participantesPolitico, setParticipantesPolitico] = useState([]);
  const [indexSacar, setIndexSacar] = useState(null);
  const [agendaIntegrantesUsadas, setAgendaIntegrantesUsadas] = useState([]);
  const header = [];
  const [totalGT, setTotalGT] = useState([]);
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const id = auth.currentUser.uid;
  const [loading2, setLoading2] = React.useState(false);

  const setIdActaCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdActa(v.idActa);
    } else {
      setIdActa("");
    }
  }, []);

  const params = {
    idGrupoTrabajo: idGrupoTrabajo,
    fields: "idActa,nombreCompletoActa",
  };

  const actaParamsMemo = React.useMemo(
    () => ({
      idActa: idActa,
      ordenar: "idActa.asc",
      expand: "agenda",
      idGrupoTrabajo: idGt,
    }),
    []
  );

  const gtprParamsMemo = React.useMemo(
    () => ({
      name: "grupo-trabajo-paquete-recuento",
      extraParams: {
        idGrupoTrabajo: idGrupoTrabajo,
      },
      expand: "acta",
    }),
    [idGrupoTrabajo]
  );

  const [
    modelsGTPR,
    modelsLoadingGTPR,
    modelsErrorGTPR,
    modelsPageGTPR,
    refreshModelsGTPR,
    deleteModelGTPR,
  ] = useModels(gtprParamsMemo);

  const changePageGTPR = (page) => {
    let params = {
      name: "grupo-trabajo-paquete-recuento",
      expand: "acta",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      idGrupoTrabajo: idGrupoTrabajo,
    };
    refreshModelsGTPR(true, params);
  };

  const agendaMiembrosMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda: 6,
    }),
    []
  );

  const agendaConUsuarioMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda2: [2, 3, 4, 6, 9, 10, 11].join(","),
      sinUsuario: 2,
    }),
    []
  );

  const agendaMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda2: [2, 3, 4, 6, 9, 10].join(","),
    }),
    []
  );

  const agendaRepresentanteMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda: 12,
    }),
    []
  );

  const grupoTrabajoRepresentante = React.useMemo(
    () => ({
      name: "grupo-trabajo-representante",
      extraParams: {
        ordenar: "idGrupoTrabajoRepresentante.asc",
        idGrupoTrabajo: idGrupoTrabajo,
      },
      expand: "agenda",
    }),
    []
  );

  const [modelsR, modelsLoadingR, refreshModelsR] = useModels(
    grupoTrabajoRepresentante
  );

  const grupoTrabajo = React.useMemo(
    () => ({
      name: "grupo-trabajo-integrante",
      extraParams: {
        ordenar: "idGrupoTrabajoPuesto.asc,numeroPR.asc",
        idGrupoTrabajo: idGrupoTrabajo,
        ignorarGenerales: true,
      },
      expand: "agenda",
    }),
    []
  );

  const [models, modelsLoading, refreshModels] = useModels(grupoTrabajo);

  const total_GT = React.useCallback(async () => {
    const responseGT = await Services.post(
      "grupo-trabajo/total-actas-por-grupo",
      {
        idGrupoTrabajo: idGrupoTrabajo,
      }
    );
    setTotalGT(responseGT.resultado[0].total);
  }, [idGrupoTrabajo]);

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      try {
        const response = await Services.get("grupo-trabajo-puesto");
        const _participantesPolitico = await Services.get(
          "participante-politico"
        );

        if (mounted) {
          setParticipantesPolitico(_participantesPolitico.resultado);
          setPuestos(response.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    getData();

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let mounted = true;
    let _integrantes = [];
    if (mounted && models) {
      total_GT();
      setIntegrantes(
        models.map((g) => ({
          ..._integrantes,
          ...g,
        }))
      );
    }

    let _representantes = [];
    if (mounted && modelsR) {
      setRepresentantes(
        modelsR.map((g) => ({
          ..._representantes,
          ...g,
        }))
      );
    }

    return () => {
      mounted = false;
    };
  }, [models, modelsR, refreshModelsR, refreshModels]);

  useEffect(() => {
    let arr = [];

    integrantes.forEach((g) => {
      if (g?.idAgenda) {
        arr = [...arr, { idAgenda: g?.idAgenda }];
      }
    });

    puntosRecuento.forEach((r) => {
      if (r?.idAgenda) {
        arr = [...arr, { idAgenda: r?.idAgenda }];
      }
    });

    let sinDuplicados = arr.filter((item, index) => {
      return arr.indexOf(item) === index;
    });

    setAgendaIntegrantesUsadas(sinDuplicados);
  }, [integrantes, puntosRecuento]);

  const guardar = async () => {
    try {
      if (representantes.some((r) => !r.puesto)) {
        showAlert({
          severity: "warning",
          message: "Favor de verificar los puestos de los Representantes.",
        });

        return;
      }

      if (integrantes.some((r) => r.idAgenda > 0)) {
      } else {
        showAlert({
          severity: "warning",
          message: "Favor de verificar los integrantes del grupo.",
        });

        return;
      }
      setLoading2(true);
      setSaving(true);

      await Services.post("grupo-trabajo-integrante/guardar3", {
        integrantes: integrantes,
      });

      await Services.post("grupo-trabajo-integrante/guardar3", {
        puntosRecuento: puntosRecuento,
        idGrupoTrabajo: idGrupoTrabajo,
      });

      await Services.post("grupo-trabajo-representante/guardar3", {
        representantes: representantes,
        sacar: sacar,
      });

      showAlert({
        severity: "success",
        message: "Conformación guardada con exito.",
      });

      setLoading2(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  const onSearchClicked = async () => {
    let params = {};
    if (idActa > 0) params = { ...params, idActa };
    params = { ...params, pagina: 0 };
    await refreshModelsGTPR(true, params);
  };

  if (modelsLoadingR || modelsLoading) return <FullScreenLoader />;

  return (
    <>
      <PageHeader
        history={history}
        title="GRUPOS DE TRABAJO"
        editing={true}
        dialog={dialog}
        links={[
          {
            to: "/grupos-trabajo",
            icon: <GroupWorkIcon />,
            label: "Grupos-Trabajo",
          },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Box display="flex" p={1}>
            <Typography
              variant="h3"
              color="secondary"
              className={classes.sectionTitle}
            >
              <div style={{ fontSize: 20 }}>
                CONFORMACIÓN DEL GRUPO DE TRABAJO {n}
              </div>
            </Typography>
            <Box flexGrow={1}></Box>
            <Box mr={1}>
              <div style={{ fontSize: 18, marginLeft: 20 }}>
                Total de Paquetes:{" "}
                <Typography variant="b" style={{ color: "red" }}>
                  <b>&nbsp; {totalGT} &nbsp;</b>
                </Typography>
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b style={{ fontSize: 14 }}>Puesto</b>
                  </TableCell>
                  <TableCell>
                    <b style={{ fontSize: 14 }}>Persona</b>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="middle"
                      onClick={() => {
                        setIdGt(idGrupoTrabajo);
                        setOpen2(true);
                      }}
                    >
                      Paquetes
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {models &&
                  models.map((int, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" style={{ width: "20%" }}>
                        {int.idGrupoTrabajoPuesto === 3 ? (
                          <p style={{ fontSize: 18, color: "red" }}>
                            {int.nombrePuesto}:
                          </p>
                        ) : (
                          <p style={{ fontSize: 18 }}>{int.nombrePuesto}:</p>
                        )}
                      </TableCell>
                      <TableCell component="th" style={{ width: "70%" }}>
                        {int.idGrupoTrabajoPuesto === 1 && (
                          <AsyncAutocompleteSearch
                            label="Persona"
                            name="agenda"
                            labelProp="nombreCompleto"
                            onChange={(index, e, v) => {
                              if (v) {
                                setIntegrantes((g) =>
                                  g.map((integrante, index) =>
                                    i === index
                                      ? {
                                          ...integrante,
                                          nombre: v.nombre,
                                          apellidoPaterno: v.apellidoPaterno,
                                          apellidoMaterno: v.apellidoMaterno,
                                          idAgenda: v.idAgenda,
                                        }
                                      : integrante
                                  )
                                );
                              }
                            }}
                            extraParams={agendaMiembrosMemo}
                            index={i}
                            ignoreIds={integrantes
                              .filter((a) => a.idAgenda)
                              .map((a) => a.idAgenda)}
                            propId="idAgenda"
                            defaultValue={int?.agenda}
                          />
                        )}
                        {int.idGrupoTrabajoPuesto === 8 && (
                          <AsyncAutocompleteSearch
                            label="Persona"
                            name="agenda"
                            labelProp="nombreCompletoConCorreo"
                            onChange={(index, e, v) => {
                              if (v) {
                                setIntegrantes((g) =>
                                  g.map((integrante, index) =>
                                    i === index
                                      ? {
                                          ...integrante,
                                          nombre: v.nombre,
                                          apellidoPaterno: v.apellidoPaterno,
                                          apellidoMaterno: v.apellidoMaterno,
                                          idAgenda: v.idAgenda,
                                        }
                                      : integrante
                                  )
                                );
                              }
                            }}
                            extraParams={agendaConUsuarioMemo}
                            index={i}
                            ignoreIds={integrantes
                              .filter((a) => a.idAgenda)
                              .map((a) => a.idAgenda)}
                            propId="idAgenda"
                            defaultValue={int?.agenda}
                          />
                        )}
                        {int.idGrupoTrabajoPuesto !== 1 &&
                          int.idGrupoTrabajoPuesto !== 8 && (
                            <AsyncCreatableAutocomplete
                              label="Persona"
                              name="agenda"
                              labelProp="nombreCompleto"
                              onChange={(index, v) => {
                                if (v) {
                                  setIntegrantes((g) =>
                                    g.map((integrante, index) =>
                                      i === index
                                        ? {
                                            ...integrante,
                                            nombre: v.nombre,
                                            apellidoPaterno: v.apellidoPaterno,
                                            apellidoMaterno: v.apellidoMaterno,
                                            idAgenda: v.idAgenda,
                                          }
                                        : integrante
                                    )
                                  );
                                }
                              }}
                              extraParams={agendaMemo}
                              //clearInput={asistentes[i].idTipoAgenda}
                              dialogForm={CreateEntryDialog}
                              //defaultValue={int?.agenda}
                              defaultValue={
                                integrantes &&
                                integrantes[i] &&
                                integrantes[i]?.agenda
                                  ? integrantes[i]?.agenda
                                  : int?.agenda
                              }
                              dialogProps={{
                                setArr: setIntegrantes,
                                setEntry: (entry) => {
                                  setIntegrantes((v) =>
                                    v.map((integrante, index) =>
                                      i === index
                                        ? {
                                            ...integrante,
                                            nombre: entry.nombre,
                                            apellidoPaterno:
                                              entry.apellidoPaterno,
                                            apellidoMaterno:
                                              entry.apellidoMaterno,
                                            idAgenda: entry.idAgenda,
                                            agenda: {
                                              nombreCompleto: `${entry.puesto} - ${entry.nombre} ${entry.apellidoPaterno} ${entry.apellidoMaterno}`,
                                            },
                                          }
                                        : integrante
                                    )
                                  );
                                },
                                idConsejoElectoral:
                                  user?.consejoElectoral?.idConsejoElectoral,
                                ignoreIds: [0, 1, 5, 7, 8, 11, 12, 13, 14],
                                tipoAgenda: {
                                  idTipoAgenda: [2, 3, 4, 6, 9, 10],
                                  tipoAgenda: "",
                                },
                              }}
                              propId="idAgenda"
                              ignoreIds={integrantes
                                .filter((a) => a.idAgenda)
                                .map((a) => a.idAgenda)}
                            />
                          )}
                      </TableCell>
                      <TableCell component="th" style={{ width: "20%" }}>
                        {int.idGrupoTrabajoPuesto === 3 && (
                          <Typography variant="b" style={{ color: "red" }}>
                            <b style={{ fontSize: 20 }}>
                              &nbsp; PR {int.numeroPR} &nbsp;
                            </b>
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                {puntosRecuento.map((rp, indexPR) => (
                  <TableRow key={indexPR}>
                    <TableCell component="th" style={{ width: "20%" }}>
                      <p style={{ fontSize: 14 }}>Auxiliar de Recuento (PR):</p>
                      :
                    </TableCell>
                    <TableCell component="th" style={{ width: "70%" }}>
                      <AsyncAutocompleteSearch
                        label="Persona"
                        name="agenda"
                        labelProp="nombreCompleto"
                        onChange={(index, e, v) => {
                          if (v) {
                            setPuntosRecuento((r) =>
                              r.map((rpp, index) =>
                                indexPR === index
                                  ? {
                                      ...rpp,
                                      nombre: v.nombre,
                                      apellidoPaterno: v.apellidoPaterno,
                                      apellidoMaterno: v.apellidoMaterno,
                                      idAgenda: v.idAgenda,
                                      idGrupoTrabajo: idGrupoTrabajo,
                                      idGrupoTrabajoPuesto: 3,
                                      nombrePuesto: "Auxiliar de Recuento (PR)",
                                    }
                                  : rpp
                              )
                            );
                          }
                        }}
                        extraParams={agendaMemo}
                        index={indexPR}
                        ignoreIds={agendaIntegrantesUsadas}
                        propId="idAgenda"
                        defaultValue={rp?.agenda}
                      />
                    </TableCell>
                    <TableCell component="th" style={{ width: "20%" }}>
                      <IconButton
                        onClick={() => {
                          setPuntosRecuento((v) =>
                            v.filter((o, index) => index !== indexPR)
                          );
                        }}
                      >
                        <Close color="secondary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />
          <hr />
        </Grid>

        <div style={{ width: "100%", marginTop: 20 }}>
          <Box display="flex" p={1}>
            <Box flexGrow={1}>
              <Typography color="secondary" className={classes.sectionTitle}>
                REPRESENTANTES DEL PARTIDO {}
              </Typography>
            </Box>
            <Box mr={1}>
              <Button
                variant="contained"
                size="middle"
                color="secondary"
                onClick={() => {
                  setRepresentantes((v) => [
                    ...v,
                    {
                      idGrupoTrabajoRepresentante: null,
                      idGrupoTrabajo: idGrupoTrabajo,
                      fechaAcreditacion: "",
                      puesto: "",
                      nombre: "",
                      apellidoPaterno: "",
                      apellidoMaterno: "",
                      idAgenda: null,
                    },
                  ]);
                }}
              >
                AGREGAR REPRESENTANTE
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                size="middle"
                color="secondary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                CREAR NUEVO REPRESENTANTE
              </Button>
            </Box>
          </Box>
        </div>
        <Grid item xs={12}>
          {representantes?.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Puesto en Grupo</TableCell>
                  <TableCell>Persona</TableCell>
                  <TableCell>Participante Político</TableCell>
                  <TableCell>Fecha acreditación</TableCell>
                  <TableCell>Salida</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {representantes.map((row, indexR) => (
                  <TableRow key={indexR}>
                    <TableCell component="th" style={{ width: "10%" }}>
                      {!row.idGrupoTrabajoRepresentante ? (
                        <FormControl fullWidth variant="outlined" size="small">
                          <Select
                            value={row.puesto}
                            onChange={(event) => {
                              setRepresentantes(
                                representantes.map((g, ig) =>
                                  ig === indexR
                                    ? {
                                        ...g,
                                        puesto: puestos.find(
                                          (p) => p.puesto === event.target.value
                                        ).puesto,
                                        idGrupoTrabajo: idGrupoTrabajo,
                                      }
                                    : g
                                )
                              );
                            }}
                          >
                            {puestos
                              .filter((p) =>
                                idsPuestoReprensete.includes(
                                  p.idGrupoTrabajoPuesto
                                )
                              )
                              .map((p) => (
                                <MenuItem
                                  key={p.idGrupoTrabajoPuesto}
                                  value={p.puesto}
                                >
                                  {p.puesto}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>{row.puesto}</Typography>
                      )}
                    </TableCell>
                    <TableCell component="th" style={{ width: "30%" }}>
                      {!row?.idGrupoTrabajoRepresentante ? (
                        <AsyncAutocompleteSearch
                          disabled={!!row.idGrupoTrabajoRepresentante}
                          label="Persona"
                          name="agenda"
                          labelProp="nombreCompletoSinPuesto"
                          onChange={(index, e, v) => {
                            if (v) {
                              setRepresentantes((g) =>
                                representantes.map((rep, i) =>
                                  i === indexR
                                    ? {
                                        ...rep,
                                        idAgenda: v.idAgenda,
                                        nombre: v.nombre,
                                        apellidoPaterno: v.apellidoPaterno,
                                        apellidoMaterno: v.apellidoMaterno,
                                        idParticipantePolitico:
                                          v.idParticipantePolitico,
                                        participantePolitico:
                                          v.participantePolitico,
                                      }
                                    : rep
                                )
                              );
                            }
                          }}
                          extraParams={agendaRepresentanteMemo}
                          index={indexR}
                          ignoreIds={representantes
                            .filter((a) => a.idAgenda)
                            .map((a) => a.idAgenda)}
                          propId="idAgenda"
                          defaultValue={row?.agenda}
                        />
                      ) : (
                        <Typography>
                          {row && row?.agenda
                            ? row?.agenda.nombreCompletoSinPuesto
                            : row?.nombreCompleto}
                        </Typography>
                      )}
                      {/* salida */}
                    </TableCell>
                    <TableCell component="th" style={{ width: "20%" }}>
                      <Typography>
                        {row?.agenda?.participantePolitico ||
                          row?.participantePolitico}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" style={{ width: "20%" }}>
                      {!row.idGrupoTrabajoRepresentante ? (
                        <FormControl fullWidth>
                          <DatePicker
                            disabled={!!row.idGrupoTrabajoRepresentante}
                            disableFuture={false}
                            label="Fecha acreditación"
                            value={row.fechaAcreditacion || new Date()}
                            onChange={(value) => {
                              setRepresentantes(
                                representantes.map((g, i) =>
                                  i === indexR
                                    ? {
                                        ...g,
                                        fechaAcreditacion: value,
                                      }
                                    : g
                                )
                              );
                            }}
                          />
                        </FormControl>
                      ) : (
                        <Typography>{row?.fechaAcreditacion}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="large"
                        color="secondary"
                        onClick={() => {
                          setIndexRepresentante(indexR);
                          if (row.idGrupoTrabajoRepresentante > 0) {
                            setOpenAlertDialog(true);
                            setSacarId(row.idGrupoTrabajoRepresentante);
                            setIndexSacar(indexR);
                          } else {
                            setRepresentantes((v) =>
                              v.filter((o, index) => index !== indexR)
                            );
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Card style={{ margin: 10 }}>
              <Typography align="center">Sin Representantes.</Typography>
            </Card>
          )}
        </Grid>
        <DialogReprecentante
          open={open}
          onClose={() => setOpen(false)}
          onAccept={(representante) => {
            setRepresentantes([
              ...representantes,
              {
                idAgenda: null,
                idParticipantePolitico: representante.idParticipantePolitico,
                participantePolitico: representante.participantePolitico,
                puesto: "",
                idGrupoTrabajoPuesto: "",
                idGrupoTrabajoRepresentante: null,
                fechaAcreditacion: representante.fechaAcreditacion,
                idGrupoTrabajo: "",
                nombre: representante.nombre,
                apellidoPaterno: representante.apellidoPaterno,
                apellidoMaterno: representante.apellidoMaterno,
                claveElector: representante.claveElector,
                agenda: {
                  nombreCompletoSinPuesto: `${representante.nombre} ${representante.apellidoPaterno} ${representante.apellidoMaterno}`,
                },
              },
            ]);
            setOpen(false);
          }}
          participantesPolitico={participantesPolitico.filter(
            (a) => a.idCombinacionCoalicion === null
          )}
        />
        <Dialog
          fullWidth={true}
          maxWidth={true}
          open={open2}
          onClose={() => setOpen2(false)}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            PAQUETES A RECONTAR <br />
            Grupo Trabajo {n}
          </DialogTitle>
          <DialogContent>
            <Grid container style={{ marginBottom: 20 }}>
              <Grid item xs={6}>
                <AsyncAutocompleteSearch
                  label="Seleccione el acta deseada"
                  name="acta"
                  searchParam="busqueda"
                  extraParams={params}
                  labelProp="nombreCompletoActa"
                  onChange={setIdActaCallback}
                  noOptionsText="No hay paquetes para recontar."
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  style={{ marginLeft: 10 }}
                  size="middle"
                  variant="contained"
                  color="secondary"
                  onClick={onSearchClicked}
                  disabled={modelsLoadingGTPR}
                  endIcon={<SearchIcon />}
                >
                  Buscar
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" p={1}>
                  <Box flexGrow={1}></Box>
                  <Box mr={1}></Box>
                  <Box>
                    <Button
                      variant="contained"
                      size="middle"
                      color="secondary"
                      onClick={() => {
                        setPdfDialogOpen(true);
                        setLink(
                          `${baseUrlPdf}paquete-grupo-trabajo?idUsuario=${id}&idGrupoTrabajo=${idGrupoTrabajo}&n=${n}`
                        );
                      }}
                    >
                      Imprimir Relación
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <DialogContentText>
              {idGrupoTrabajo && (
                <TablePagination
                  header={header}
                  data={modelsGTPR}
                  paginatedApi
                  changePageApi={changePageGTPR}
                  count={modelsPageGTPR ? modelsPageGTPR.total : 0}
                  extraRows={[
                    {
                      prop: "nombreActa",
                      name: "Seccion Casilla",
                      cell: (row) => (
                        <>{row.acta ? row.acta.nombreCompletoActa : ""}</>
                      ),
                    },
                    {
                      prop: "estatus",
                      name: "Estatus",
                      cell: (row) => (
                        <>
                          {row.fechaComputo
                            ? "Recontada: " + row.fechaComputo.slice(0, 19)
                            : "Sin recontar"}
                        </>
                      ),
                    },
                  ]}
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen2(false)} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        <AlertDialog
          open={openAlertDialog}
          onClose={() => setOpenAlertDialog(false)}
          content={""}
          title="Seguro que deseas sacar este Representante del grupo."
          onAccept={() => {
            setRepresentantes((v) =>
              v.filter((o, index) => index !== indexSacar)
            );
            setSacar((g) => [
              ...sacar,
              {
                idGrupoTrabajoRepresentante: sacarId,
              },
            ]);
            setOpenAlertDialog(false);
          }}
        />
        <PdfDialog
          source={link}
          open={pdfDialogOpen}
          setOpen={setPdfDialogOpen}
        />
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={3}>
              <Button
                disabled={loading2}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={false}
                endIcon={loading2 ? <CircularProgress size={25} /> : <Save />}
                onClick={async () => {
                  await guardar();
                }}
              >
                {loading2 ? "GUARDANDO" : "GUARDAR CONFORMACIÓN"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default IntegrantesDetail;
