import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import * as deLocale from "date-fns/locale/es/index";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CardMedia,
} from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { ArrowBack, Save, TableChart } from "@material-ui/icons";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";

import { useHttp } from "../../hooks/useHttp";
import { useModel } from "../../hooks/useModel";

import { emptyRequest, postRequest } from "../../constants/request";
import { useQuery } from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import CustomDialog from "../../components/CustomDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

function GruposTrabajoDetail({
  match,
  history,
  dialog,
  editingGruposTrabajo,
  setGruposTrabajoSearch,
}) {
  const classes = useStyles();
  const query = useQuery();
  const idGrupoTrabajo = query.get("id");
  const numeroGrupo = query.get("num");
  const editing = !!idGrupoTrabajo;
  const { showAlert } = useAlert();

  const [validating, setValidating] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(true);
  const [activo, setActivo] = React.useState(0);

  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);

  // const {
  //   model,
  //   modelLoading,
  //   modelError,
  //   updateModel,
  //   updateModelLoading,
  // } = useModel({
  //   name: "estado",
  //   id: idEstado,
  //   redirectOnPost: true,
  // });

  // React.useEffect(() => {
  //   let mounted = true;
  //   if (mounted && model) {
  //     setNombre(model.nombre);
  //     setAbreviacion(model.abreviacion);
  //     setClave(model.clave);
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  // }, [model]);

  // const onSaveClicked = () => {
  //   setValidating(true);
  //   const body = {
  //     nombre,
  //     abreviacion,
  //     clave,
  //   };

  //   if (editing) {
  //     body.idEstado = idEstado;
  //   }

  //   updateModel(body);
  // };

  const handleClose = () => {
    setIsOpen(false);
  };

  const setActivollback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setActivo(v.activo);
    } else {
      setActivo("");
    }
  }, []);

  // if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="GRUPOS TRABAJO"
        links={[
          {
            to: "/grupos-trabajo",
            icon: <AssignmentIndIcon />,
            label: "Grupos-Trabajo",
          },
          {
            to: "/grupos-trabajo/nuevo",
            icon: <ListAltIcon />,
            label: "Integrantes GT " + numeroGrupo,
          },
        ]}
        editing={editing}
        dialog={dialog}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
        alignItems="flex-end"
      >
        <Grid item item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Typography className={classes.subtitle}>
                Conformación de Grupo de Trabajo
              </Typography>
            </Grid>

            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Typography className={classes.subtitle}>
                Representante de Partidos ante GT
              </Typography>
            </Grid>

            <Grid item xs={1} style={{ textAlign: "right" }}>
              <Typography className={classes.subtitle}>Puesto</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Presidente del Grupo
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Puesto
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Puesto"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>
              <MenuItem key={1} value={1}>
                Presidente(a) del Grupo
              </MenuItem>
              <MenuItem key={2} value={2}>
                Consejo Electoral
              </MenuItem>
              <MenuItem key={3} value={3}>
                Auxiliar de Recursos
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid style={{ textAlign: "right" }} item xs={2}>
          <Typography className={classes.subtitle}>
            Partido Acción Nacional
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Puesto
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Puesto"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>
              <MenuItem key={1} value={1}>
                Presidente(a) del Grupo
              </MenuItem>
              <MenuItem key={2} value={2}>
                Consejo Electoral
              </MenuItem>
              <MenuItem key={3} value={3}>
                Auxiliar de Recursos
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Representante: 1
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Puesto
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Puesto"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>
              <MenuItem key={1} value={1}>
                Presidente(a) del Grupo
              </MenuItem>
              <MenuItem key={2} value={2}>
                Consejo Electoral
              </MenuItem>
              <MenuItem key={3} value={3}>
                Auxiliar de Recursos
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid style={{ textAlign: "right" }} item xs={2}>
          <Typography className={classes.subtitle}>
            Partido Revolucionario Institucional
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Puesto
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Puesto"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>
              <MenuItem key={1} value={1}>
                Presidente(a) del Grupo
              </MenuItem>
              <MenuItem key={2} value={2}>
                Consejo Electoral
              </MenuItem>
              <MenuItem key={3} value={3}>
                Auxiliar de Recursos
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Recuento: 1
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Puesto
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Puesto"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>
              <MenuItem key={1} value={1}>
                Presidente(a) del Grupo
              </MenuItem>
              <MenuItem key={2} value={2}>
                Consejo Electoral
              </MenuItem>
              <MenuItem key={3} value={3}>
                Auxiliar de Recursos
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid style={{ textAlign: "right" }} item xs={2}>
          <Typography className={classes.subtitle}>
            Partido del Trabajo
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Puesto
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Puesto"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>
              <MenuItem key={1} value={1}>
                Presidente(a) del Grupo
              </MenuItem>
              <MenuItem key={2} value={2}>
                Consejo Electoral
              </MenuItem>
              <MenuItem key={3} value={3}>
                Auxiliar de Recursos
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Recuento: 2
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Traslado: 1
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Documentación: 1
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Captura: 1
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Verificación: 1
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Control de Grupo: 1
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Control de Bodega
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Acreditación y Sustitución
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={2}>
          <Typography className={classes.subtitle}>
            Auxiliar de Seguimiento
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Usuario
            </InputLabel>
            <Select
              value={activo}
              style={{ textAlign: "left" }}
              onChange={setActivollback}
              label="Usuario"
            >
              <MenuItem key={0} value={0}>
                Ninguno
              </MenuItem>

              <MenuItem key={1} value={1}>
                Brandon David Balderrama
              </MenuItem>
              <MenuItem key={2} value={2}>
                Abraham Campillo Serrano
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}></Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                endIcon={<Save />}
              >
                Crear Integrante
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default GruposTrabajoDetail;
