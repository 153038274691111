import React from "react";
import PageLayout from "../../components/PageLayout";
import { Grid, Paper, Typography } from "@material-ui/core";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { generateDefaultChartOptions } from "../../utils";
import { useModels } from "../../hooks/useModels";
import Loader from "../../components/Loader";

const AvanceRecepcion = () => {
  let chartOptions2 = generateDefaultChartOptions("column", {
    title: "CON RECEPCIÓN",
    subtitle: " ",
    inverted: true,
  });

  const [models, modelsLoading, modelsError] = useModels({
    name: "lectura-voto/resultado",
  });

  const totales = models.length > 0 ? models[0] : {};

  const extraOptions2 = {
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0,
        pointWidth: 40,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      headerFormat:
        '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} Paquetes</b><br/>',
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -10,
        allowOverlap: true,
        enabled: false,
        style: {
          wordBreak: "break-all",
          textOverflow: "allow",
        },
      },
      categories: function () {
        var value = this.value;
        return value;
      },
    },
    yAxis: [
      {
        title: {
          text: " ",
        },
        showFirstLabel: false,
      },
    ],
    series: [
      {
        color: "rgb(158, 159, 163)",
        pointPlacement: -0.2,
        linkedTo: "main",
        cursor: "pointer",
        point: {
          events: {
            /*
            click: function () {
              console.log(this.options);
              history.push(
                `/estadisticos/informe-recuento/detalle?id=${this.options.id}`
              );
              //call function passing this values as arguments
            }, // */
          },
        },
        data: ["Esperados", parseInt(totales.esperados) * 3],
        name: "Paquetes Esperados",
      },
      {
        name: "Paquetes con Recepción",
        id: "main",
        dataSorting: {
          enabled: true,
          matchByName: true,
        },
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: "16px",
            },
          },
        ],
        color: "#a13800",
        cursor: "pointer",
        point: {
          events: {
            /*
            click: function () {
              history.push(
                `/estadisticos/informe-recuento/detalle?id=${this.options.id}`
              );
              //call function passing this values as arguments
            }, // */
          },
        },
        data: ["Con recepción", totales.almacenados],
      },
    ],
    exporting: {
      allowHTML: true,
    },
  };

  chartOptions2 = {
    ...chartOptions2,
    ...extraOptions2,
  };

  if (modelsLoading) return <Loader />;
  return (
    <PageLayout title="AVANCE DE RECEPCIÓN DE PAQUETES">
      <Grid xs={12}>
        <Typography style={{ fontSize: 24 }}>
          {`PAQUETES ESPERADOS: ${totales.esperados * 3}`}
        </Typography>
        <Typography style={{ fontSize: 24 }}>
          {`PAQUETES RECIBIDOS: ${totales.almacenados}`}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions2} />
      </Grid>
    </PageLayout>
  );
};

export default AvanceRecepcion;
