import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Card,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";

import React from "react";
import PageLayout from "../components/PageLayout";
import { useModels } from "../hooks/useModels";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import AsyncAutocompleteSearch from "../components/AsyncAutocompleteSearch";
import { Search } from "@material-ui/icons";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import * as histogram from "highcharts/modules/histogram-bellcurve";

import { generateDefaultChartOptions, capitalizeFirst } from "../utils";

highcharts3d(Highcharts);
histogram(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  panelEspecialText: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
  },
  panelText: {
    color: "#000",
    fontSize: 24,
    fontWeight: "bold",
  },
  table: {
    width: "50%",
  },
}));

const reqProperties = {
  name: "conteo-preliminar",
};

const ConteoPreliminar = () => {
  const classes = useStyles();
  // let chartOptions = generateDefaultChartOptions("column");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [request, setRequest] = React.useState(reqProperties);

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels(request);

  React.useEffect(() => {
    let mounted = true;
    let timeout = setInterval(() => {
      if (mounted) {
        refreshModels();
      }
    }, 30000);
    return () => {
      if (timeout !== null) {
        clearInterval(timeout);
      }
      mounted = false;
    };
  }, [refreshModels]);

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    (e, v) => {
      if (!v) return;
      const { idConsejoElectoral } = v;
      if (idConsejoElectoral && idConsejoElectoral !== "") {
        const req = { ...reqProperties };
        req.extraParams = { ...req.extraParams, idConsejoElectoral };
        setRequest(req);
        setIdConsejoElectoral(idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setRequest]
  );

  const onSearchClicked = async () => {
    if (idConsejoElectoral && idConsejoElectoral !== "") {
      const req = { ...reqProperties };
      req.extraParams = { ...req.extraParams, idConsejoElectoral };
      setRequest(req);
    }
  };

  let chartOptions = generateDefaultChartOptions("column");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getData(data) {
    const d = data.map(function (participantePolitico, i) {
      return {
        name: i + 1,
        y: participantePolitico[1],
      };
    });
    return [...d];
  }

  let extraOptions = React.useMemo(() => {
    return {
      title: {
        text: "RESULTADOS ",
        style: {
          color: "#000",
          fontSize: 18,
        },
        align: "left",
      },
      legend: {
        enabled: false,
      },
      subtitle: {
        text: " ",
        style: {
          color: "#000",
          fontSize: 20,
        },
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: " ",
        },
      },
      xAxis: {
        type: "category",
        labels: {
          useHTML: true,
          animate: true,
          formatter: function () {
            let value = this.value;
            let img = "";
            let filterModel = models.filter((e) => e.nombre == "Total");
            let count = "";
            let total = filterModel[0].votos;

            models.forEach(function (p, i) {
              if (value === i + 1) {
                if (p.logo == "VN") {
                  if (p.nombre == "Total") {
                    img = `<b style="font-size: 30px;">TOTAL <br></b>`;

                    count = Math.round((p.votos * 100) / p.votos).toFixed(2);
                  } else {
                    img = `<img  src="/VN.png?fecha=${new Date()}" style="width: 60px; height: 60px;"/>`;
                    count = Math.floor(((p.votos * 100) / total) * 100) / 100;
                  }
                } else if (p.logo == "CNR") {
                  img = `<img  src="/NR.png?fecha=${new Date()}" style="width: 60px; height: 60px;"/>`;

                  count = Math.floor(((p.votos * 100) / total) * 100) / 100;
                } else {
                  img = `<img  src="${
                    p.logo
                  }?fecha=${new Date()}" style="width: 60px; height: 60px;"/>`;
                  count = Math.floor(((p.votos * 100) / total) * 100) / 100;
                }
              }
            });

            return (
              `<span><br>${img}<br><br></span> <br>` +
              `<h2 style="color:#000">${isNaN(count) ? 0 : count}%</h2>`
            );
          },
        },
      },
      series: [
        {
          dataLabels: [
            {
              enabled: true,
              inside: true,
              style: {
                fontSize: 22,
                color: "#fff",
              },
            },
          ],
          colors: ["#cd5d23"],
          colorByPoint: true,
          name: "Resultados ",
          data: getData(models.map((p) => [p.nombre, p.votos]).slice()),
        },
      ],
      exporting: {
        allowHTML: true,
      },
    };
  }, [models, getData]);

  chartOptions = { ...chartOptions, ...extraOptions };

  chartOptions.chart.backgroundColor = "#fff";

  chartOptions.chart.scrollablePlotArea = {
    minWidth: 1000,
  };

  return (
    <PageLayout
      title="ESTADÍSTICOS DE CONTEOS PRELIMINARES"
      SearchComponents={
        <>
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10, width: "100%" }}
        alignItems="flex-end"
      >
        {/* <Grid item xs={12}>
          <TableContainer>
            <Table size={"small"} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "75%" }}>
                    <Typography className={classes.panelText}>
                      Partido, Candidatura Común, Coalición o Candidato
                      Independiente
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.panelText}>Votos</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {models &&
                  models.map((row) => (
                    <TableRow key={row.nombre}>
                      <TableCell component="th" scope="row">
                        {row.logo !== "VN" && row.logo !== "CNR" && (
                          <img
                            alt={row.nombre}
                            src={row.logo}
                            style={{ height: 50 }}
                          />
                        )}
                        {(row.logo === "VN" || row.logo === "CNR") && (
                          <Typography className={classes.panelText}>
                            {row.nombre}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.panelEspecialText}>
                          {row.votos}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> */}

        <Grid item lg={12} xs={12} className={classes.section}>
          <Grid container component={Card} className={classes.graphCardContent}>
            <Grid item xs={12}>
              <Typography className={classes.cardTitle} align="center">
                RESULTADOS DE LA VOTACIÓN
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ConteoPreliminar;
