import React from "react";
import Iframe from "react-iframe";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import FullScreenLoader from "./FullScreenLoader";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.dark,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PdfDialog = ({ open, setOpen, source, onClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
    if(typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Visualizador de PDF
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={{ height: "100%" }}>
          {loading ? <FullScreenLoader /> : null}
          <div style={{ display: loading ? "none" : "inline" }}>
            <Iframe
              url={source}
              width="100%"
              height="100%"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
              onLoad={() => setLoading(false)}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PdfDialog;
