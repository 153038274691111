import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormHelperText,
  CardMedia,
  GridList,
  GridListTile,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";

import { photoBaseUrl } from "../../utils/variables";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";
import CustomDialog from "../../components/CustomDialog";

import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import { useQuery } from "../../hooks/useQuery";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function CandidatoIndependienteDetail({ history, dialog }) {
  const classes = useStyles();
  const query = useQuery();
  const idCandidatoIndependiente = query.get("id");
  const editing = !!idCandidatoIndependiente;

  const [isOpen, setIsOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState("");
  const [claveCandidato, setClaveCandidato] = React.useState("");
  const [rutaLogo, setRutaLogo] = React.useState("");
  const [idMunicipio, setIdMunicipio] = React.useState("26");
  const [idDistrito, setIdDistrito] = React.useState("");
  const [cargo, setCargo] = React.useState("");

  const municipioParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1 }),
    []
  );

  const distritoParamsMemo = React.useMemo(
    () => ({
      tipo: 2,
      ordenar: "nombre.asc",
    }),
    []
  );

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "candidato-independiente",
    expand: "distrito,municipio",
    id: idCandidatoIndependiente,
    redirectOnPost: true,
  });
  const [recursos] = useModels({ name: "recurso" });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setNombre(model.nombre);
      setClaveCandidato(model.claveCandidato);
      setIdMunicipio(model.idMunicipio);
      setIdDistrito(model.idDistrito);
      setCargo(model.cargo);
      setRutaLogo(model.rutaLogo);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSaveClicked = () => {
    let modelSend = {
      nombre,
      claveCandidato,
      idMunicipio,
      idDistrito,
      cargo,
      logo: rutaLogo,
    };
    if (editing) {
      modelSend.idCandidatoIndependiente = idCandidatoIndependiente;
    }

    updateModel(modelSend);
  };

  const setNombreCallback = React.useCallback(
    (e) => {
      setNombre(e.target.value);
    },
    [setNombre]
  );

  const setClaveCandidatoCallback = React.useCallback(
    (e) => {
      setClaveCandidato(e.target.value);
    },
    [setClaveCandidato]
  );

  const setIdMunicipioCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
    } else {
      setIdMunicipio("");
    }
  }, []);

  const setIdDistritoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdDistrito(v.idDistrito);
      } else {
        setIdDistrito("");
      }
    },
    [setIdDistrito]
  );

  const setCargoCallback = React.useCallback(
    (e) => {
      setCargo(e.target.value);
    },
    [setCargo]
  );

  const handleClose = () => {
    setIsOpen((v) => !v);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE CANDIDATO INDEPENDIENTE"
        model="candidatos-independiente"
        editing={editing}
        dialog={dialog}
        links={[
          {
            to: "/candidatos-independientes",
            label: "Cantidatos Independientes",
          },
          {
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nombre"
            size="small"
            variant="outlined"
            fullWidth
            onChange={setNombreCallback}
            inputProps={{
              value: nombre,
            }}
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            label="Clave Candidato"
            onChange={setClaveCandidatoCallback}
            inputProps={{
              value: claveCandidato && claveCandidato,
            }}
            variant="outlined"
            helperText={modelError.claveCandidato}
            error={Boolean(modelError.claveCandidato)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncAutocompleteSearch
            label="Distrito"
            name="distrito"
            defaultValue={model && model.distrito}
            labelProp="nombre"
            onChange={setIdDistritoCallback}
            extraParams={distritoParamsMemo}
          />
          {modelError.idDistrito ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idDistrito}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item xs={4}>
          <AsyncAutocompleteSearch
            label="Municipio"
            name="municipio"
            labelProp="nombre"
            defaultValue={model && model.municipio}
            onChange={setIdMunicipioCallback}
            extraParams={municipioParamsMemo}
          />
          {modelError.idMunicipio ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idMunicipio}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            label="Cargo"
            onChange={setCargoCallback}
            inputProps={{
              value: cargo,
            }}
            variant="outlined"
            helperText={modelError.cargo}
            error={Boolean(modelError.cargo)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClose()}
          >
            Seleccionar Logo
          </Button>
          <CustomDialog
            isOpen={isOpen}
            handleClose={handleClose}
            title="Selección de Logo"
          >
            <GridList cellHeight={160} cols={10}>
              {recursos.map((recurso) => (
                <GridListTile key={recurso.idRecurso} cols={1}>
                  <img
                    src={`${photoBaseUrl}${recurso.ruta}`}
                    alt={recurso.nombre}
                    onClick={() => {
                      setRutaLogo(recurso.ruta);
                      handleClose();
                    }}
                    style={{ width: "100%", height: "auto" }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </CustomDialog>
        </Grid>
        {rutaLogo !== "" && (
          <Grid item xs={12} md={12}>
            <CardMedia
              component="img"
              alt="Imagen del candidat(o/a)"
              height="300"
              style={{ width: "auto" }}
              image={`${photoBaseUrl}${rutaLogo}`}
              title="Imagen del candidat(o/a)"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSaveClicked}
              >
                {editing ? "EDITAR" : "CREAR"} CANDIDATO INDEPENDIENTE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CandidatoIndependienteDetail;
