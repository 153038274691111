import React from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import YesNoButton from "../../components/YesNoButton";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  tableHelperText: { textAlign: "left", color: theme.palette.error.dark },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d1835a",
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 18,
    height: 50,
    color: "#000",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const YesNoTable = (props) => {
  const classes = useStyles();
  const { title, value, onChange, validating } = props;

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        className={classes.table}
        aria-label="Tabla de si o no"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{title}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell align="center">
              <YesNoButton
                value={value}
                onChange={onChange}
                validating={validating}
              />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default YesNoTable;
