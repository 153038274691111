import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
} from "../../utils";
import PageHeader from "../../components/PageHeader";
import Loading from "../../components/FullScreenLoader";
import PermissionsTable from "./permissionsTable";

import { useModel } from "../../hooks/useModel";
import { useAlert } from "../../hooks/useAlert";
import CustomDialog from "../../components/CustomDialog";
import { ArrowBack } from "@material-ui/icons";
import Agendas from "../agendas";
import AgendasDetail from "../agendas/detail";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

const UsersDetail = ({ match, history, dialog, editingUser, setSearch }) => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const editing =
    match.params.idUsuario !== undefined || editingUser !== undefined;
  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelResult,
    updateModelLoading,
  } = useModel({
    name: "usuario",
    id: match.params.idUsuario || editingUser,
    expand: "permiso",
    redirectOnPost: true,
  });
  // const [editingTitle, setEditingTitle] = React.useState("Cargando...");
  const [correo, setCorreo] = React.useState("");
  const [clave, setClave] = React.useState("");
  const [clave2, setClave2] = React.useState("");
  const setCorreoCallback = React.useCallback(
    (e) => setCorreo(e.target.value),
    [setCorreo]
  );

  const [validating, setValidating] = React.useState(false);
  const [agendasSearch, setAgendasSearch] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedAgenda, setSelectedAgenda] = React.useState(undefined);
  const [editingAgenda, setEditingAgenda] = React.useState(undefined);
  const [permiso, setPermiso] = React.useState(null);
  const [rolPlantilla, setRolPlantilla] = React.useState(0);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setCorreo(model.correo);
      setPermiso(model.permiso);
      // setEditingTitle(`Editando: ${model.correo}`);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  React.useEffect(() => {
    if (updateModelResult && !updateModelLoading && dialog) {
      setSearch(true);
    }
  }, [updateModelResult, updateModelLoading, dialog, setSearch]);

  const handleError = (test, field, error) =>
    !test(field) ? Boolean(validating) : Boolean(error);

  const handleHelperText = (text, test, field, error) => {
    if (validating && !test(field)) {
      return text;
    } else if (error) {
      return error;
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSaveClicked = async () => {
    setValidating(true);
    let permisoConRol = { ...permiso };
    if (rolPlantilla === 1) {
      permisoConRol = { ...permiso, rol: "Encargado de bodega" };
    } else if (rolPlantilla === 2) {
      permisoConRol = { ...permiso, rol: "Presidente de consejo" };
    } else if (rolPlantilla === 3) {
      permisoConRol = { ...permiso, rol: "Recepción de paquetes municipales" };
    } else if (rolPlantilla === 4) {
      permisoConRol = { ...permiso, rol: "Recepción de paquetes distritales" };
    } else if (rolPlantilla === 5) {
      permisoConRol = {
        ...permiso,
        rol: "Recepción de paquetes gubernamentales",
      };
    }
    let body = {
      correo,
      permiso: permisoConRol,
    };
    if (!editing) {
      if (clave === clave2) {
        body = { ...body, clave };
      }
    }
    if (editing) {
      body = {
        ...body,
        idUsuario: dialog ? editingUser : match.params.idUsuario,
      };
    }
    if (!Object.values(body).some((e) => !e) && clave === clave2) {
      updateModel(body, false);
    } else {
      if (!dialog) {
        window["scrollTo"]({ top: 0, behavior: "smooth" });
        showAlert({
          message: "Favor de revisar los campos llenados.",
          severity: "warning",
        });
      }
    }
  };

  const MemoTable = React.useMemo(() => {
    const onPermissionsTableChange = (perms) => {
      let cpy = {};
      for (const [key, array] of Object.entries(perms)) {
        cpy[key] = array.map((e) => e.value).reduce((a, b) => a + b, 0);
      }
      setPermiso(cpy);
    };

    return (
      <Grid item xs={12}>
        <PermissionsTable
          user={model}
          onChange={onPermissionsTableChange}
          preset={rolPlantilla}
          editing={editing}
        />
      </Grid>
    );
  }, [model, rolPlantilla, editing]);

  const onPlantillaSelectChange = (event) => {
    setRolPlantilla(event.target.value);
  };

  const CustomDialogContent = (props) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {agendasSearch ? (
            <>
              <Grid container>
                <Button
                  variant="contained"
                  color="primary"
                  fullwidth
                  style={{ marginBottom: 10, marginTop: 10 }}
                  onClick={() => {
                    setAgendasSearch(false);
                    setEditingAgenda(undefined);
                  }}
                >
                  CREAR AGENDA
                </Button>
              </Grid>
              <Agendas
                dialog={true}
                setEditingAgenda={setEditingAgenda}
                setAgendasSearch={setAgendasSearch}
                setSelectedAgenda={setSelectedAgenda}
                handleClose={handleClose}
              />
            </>
          ) : (
            <>
              <Grid container>
                <Button
                  onClick={() => {
                    setAgendasSearch(true);
                  }}
                >
                  <ArrowBack color="primary" />
                </Button>
              </Grid>
              <AgendasDetail
                match={match}
                history={history}
                dialog={true}
                editingAgenda={editingAgenda}
                setAgendasSearch={setAgendasSearch}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE USUARIOS"
        model="usuario"
        editing={editing}
        dialog={dialog}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        {!dialog && (
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={10}>
                <>
                  <Typography>
                    {selectedAgenda
                      ? "Agenda selecionada:"
                      : "No hay agenda seleccionada"}
                  </Typography>
                  {selectedAgenda && (
                    <>
                      <Typography>{`Nombre: ${selectedAgenda.nombre} ${selectedAgenda.apellidoPaterno} ${selectedAgenda.apellidoMaterno}`}</Typography>
                      <Typography>{`Tipo de Agenda: ${selectedAgenda.tipoAgenda.nombre}`}</Typography>
                    </>
                  )}
                </>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    selectedAgenda
                      ? setSelectedAgenda(undefined)
                      : setIsOpen(true);
                  }}
                >
                  {selectedAgenda
                    ? "DESLIGAR AGENDA A CUENTA"
                    : "LIGAR AGENDA A CUENTA"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        <CustomDialog
          isOpen={isOpen}
          handleClose={handleClose}
          title="ASOCIAR AGENDA A CUENTA"
          subtitle="Vincula una agenda a la cuenta"
        >
          <CustomDialogContent />
        </CustomDialog>

        <Grid item xs={12}>
          <Typography color="secondary" className={classes.sectionTitle}>
            INFORMACIÓN DE CUENTA
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Correo electrónico"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={handleHelperText(
              "Favor de revisar el formato del correo",
              validateEmail,
              correo,
              modelError.idUsuario
            )}
            error={handleError(validateEmail, correo, modelError.idUsuario)}
            onChange={setCorreoCallback}
            inputProps={{
              value: correo,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Contraseña"
            size="small"
            type="password"
            variant="outlined"
            fullWidth
            helperText={handleHelperText(
              "Error de Formato: Debe tener al menos 1 mayúscula, 1 numérico y 1 signo.",
              validatePassword,
              clave,
              modelError.clave
            )}
            error={handleError(validatePassword, clave, modelError.clave)}
            required={!editing}
            onChange={(e) => setClave(e.target.value)}
            inputProps={{
              value: clave,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Confirmar contraseña"
            size="small"
            type="password"
            variant="outlined"
            fullWidth
            helperText={handleHelperText(
              "Contraseñas diferentes, confirme de nuevo.",
              validateConfirmPassword,
              clave2,
              modelError.clave
            )}
            error={handleError(
              validateConfirmPassword,
              clave2,
              modelError.clave
            )}
            required={!editing}
            onChange={(e) => setClave2(e.target.value)}
            inputProps={{
              value: clave2,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography color="secondary" className={classes.sectionTitle}>
            ASIGNACIÓN DE PERMISOS
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            size="small"
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="plantilla-label">Plantilla de rol</InputLabel>
            <Select
              labelId="plantilla-label"
              id="plantilla"
              value={rolPlantilla}
              onChange={onPlantillaSelectChange}
              label="Plantilla de Rol"
            >
              <MenuItem value={0}>
                <em>
                  Sin plantilla{" "}
                  {editing ? "(Permisos actuales del usuario)" : ""}
                </em>
              </MenuItem>
              <MenuItem value={1}>Encargado de bodega</MenuItem>
              <MenuItem value={2}>Presidente de consejo</MenuItem>
              <MenuItem value={3}>Recepción de paquetes municipales</MenuItem>
              <MenuItem value={4}>Recepción de paquetes distritales</MenuItem>
              <MenuItem value={5}>
                Recepción de paquetes gubernamentales
              </MenuItem>
              <MenuItem value={12}>Presidente Distrital</MenuItem>
              <MenuItem value={6}>Presidente Municipal</MenuItem>
              <MenuItem value={8}>Auxiliar Datos Generales</MenuItem>
              <MenuItem value={9}>Auxiliar Captura</MenuItem>
              <MenuItem value={10}>Auxiliar Recepcion</MenuItem>
              <MenuItem value={11}>Auxiliar Lectura</MenuItem>
              <MenuItem value={13}>
                Auxiliar de Acreditación y Sustitución
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {MemoTable}
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onSaveClicked}
                fullWidth
                disabled={updateModelLoading}
                endIcon={<SaveIcon />}
              >
                {editing ? "GUARDAR DATOS" : "CREAR USUARIO"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UsersDetail;
