import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useAuth } from "../hooks/useAuth";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
    //marginTop: "30",
  },
});

export default function HomeCard(props) {
  const history = useHistory();
  const classes = useStyles();
  const { title, description, image, link, ignoreImageLink } = props;
  const { user } = useAuth();

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => {
          history.push(ignoreImageLink ? "#" : `/${link}`);
        }}
      >
        <CardMedia className={classes.media} image={image} title="Image" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" align="left">
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            align="left"
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {link === "reunion-de-trabajo/informe" && (
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {(user && user.permiso["reunionTrabajoReportes"] & 1) === 1 && (
              <Grid item md={4} sm={6}>
                <Button
                  size="small"
                  fullWidth
                  style={{ float: "left" }}
                  color="primary"
                  onClick={() => {
                    history.push("/reunion-de-trabajo/informe");
                  }}
                >
                  Informe
                </Button>
              </Grid>
            )}
            {(user && user.permiso["reunionTrabajoRectificacion"] & 1) ===
              1 && (
              <Grid item md={4} sm={6}>
                <Button
                  size="small"
                  fullWidth
                  style={{ float: "left" }}
                  color="primary"
                  onClick={() => {
                    history.push("/rectificacion");
                  }}
                >
                  Rectificación
                </Button>
              </Grid>
            )}
            {(user && user.permiso["gruposDeTrabajoRepresentantes"] & 1) ===
              1 && (
              <Grid item md={4} sm={6}>
                <Button
                  size="small"
                  fullWidth
                  style={{ float: "left" }}
                  color="primary"
                  onClick={() => {
                    history.push("/grupos-trabajo");
                  }}
                >
                  Grupos de trabajo
                </Button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      )}
      {link === "cotejo" && (
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {(user && user.permiso["computoCotejo"] & 1) === 1 && (
              <Grid item md={3} sm={6}>
                <Button
                  size="small"
                  fullWidth
                  style={{ float: "left" }}
                  color="primary"
                  onClick={() => {
                    history.push("/cotejo/nuevo");
                  }}
                >
                  Cotejo
                </Button>
              </Grid>
            )}
            {(user && user.permiso["computoRecuento"] & 1) === 1 && (
              <Grid item md={3} sm={6}>
                <Button
                  size="small"
                  fullWidth
                  style={{ float: "left" }}
                  color="primary"
                  onClick={() => {
                    history.push("/recuento/nuevo");
                  }}
                >
                  Recuento
                </Button>
              </Grid>
            )}
            {(user && user.permiso["computoRecuentoGt"] & 1) === 1 && (
              <Grid item md={3} sm={6}>
                <Button
                  size="small"
                  fullWidth
                  style={{ float: "left" }}
                  color="primary"
                  onClick={() => {
                    history.push("/recuento-gt/nuevo");
                  }}
                >
                  Recuento GT
                </Button>
              </Grid>
            )}
            {(user && user.permiso["computoRecuentoGtRes"] & 1) === 1 && (
              <Grid item md={3} sm={6}>
                <Button
                  size="small"
                  fullWidth
                  style={{ float: "left" }}
                  color="primary"
                  onClick={() => {
                    history.push("/recuento-gt-reservado/nuevo");
                  }}
                >
                  Recuento GT Reservado
                </Button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      )}
    </Card>
  );
}
