import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import {
  Breadcrumbs,
  Button,
  Chip,
  Collapse,
  emphasize,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import {ExpandLess, ExpandMore, Home, Save} from "@material-ui/icons";

import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loading from "../../components/FullScreenLoader";
import DatePicker from "../../components/DatePicker";

import {NON_DIGIT, sexos} from "../../constants/mockData";
import {emptyRequest, postRequest} from "../../constants/request";
import {formatPhoneNumber, validateEmail, validateRfc} from "../../utils";

import {useHttp} from "../../hooks/useHttp";
import {useModels} from "../../hooks/useModels";
import {useAlert} from "../../hooks/useAlert";
import {useAuth} from "../../hooks/useAuth";
import { auth } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
  profileImg: {
    width: 120,
    borderRadius: 100,
    backgroundColor: theme.palette.grey[200],
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

function Perfil({match, history}) {
  const classes = useStyles();
  const {showAlert} = useAlert();
  const {user} = useAuth();

  const [validating, setValidating] = React.useState(false);
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [clearMunicipio, setClearMunicipio] = React.useState(false);
  const [idSeccion, setIdSeccion] = React.useState("");
  const [clearSeccion, setClearSeccion] = React.useState(false);
  const [localidad, setLocalidad] = React.useState("");
  const [colonia, setColonia] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [apellidoPaterno, setApellidoPaterno] = React.useState("");
  const [apellidoMaterno, setApellidoMaterno] = React.useState("");
  const [calle, setCalle] = React.useState("");
  const [numeroExterior, setNumeroExterior] = React.useState("");
  const [numeroInterior, setNumeroInterior] = React.useState("");
  const [codigoPostal, setCodigoPostal] = React.useState("");
  const [claveElector, setClaveElector] = React.useState("");
  const [folioElector, setFolioElector] = React.useState("");
  const [curp, setCurp] = React.useState("");
  const [rfc, setRfc] = React.useState("");
  const [alias, setAlias] = React.useState("");
  const [sexo, setSexo] = React.useState("");
  const [telefonoHogar, setTelefonoHogar] = React.useState("");
  const [telefonoTrabajo, setTelefonoTrabajo] = React.useState("");
  const [telefonoCelular, setTelefonoCelular] = React.useState("");
  const [telefonoOtro, setTelefonoOtro] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [observaciones, setObservaciones] = React.useState("");
  const [edad, setEdad] = React.useState("");
  const [idDistrito, setIdDistrito] = React.useState("");
  const [fechaNacimiento, setFechaNacimiento] = React.useState(new Date());
  const [lugarNacimiento, setLugarNacimiento] = React.useState("");
  const [rutaFotografia, setRutaFotografia] = React.useState("");
  const [idEtnia, setIdEtnia] = React.useState("");
  const [idEstadoNacimiento, setIdEstadoNacimiento] = React.useState("");
  const [idMunicipioNacimiento, setIdMunicipioNacimiento] = React.useState("");
  const [idAgenda, setIdAgenda] = React.useState("");
  const [idUsuario, setIdUsuario] = React.useState("");
  const [
    clearMunicipioNacimiento,
    setClearMunicipioNacimiento,
  ] = React.useState(false);
  const [idEstadoCivil, setIdEstadoCivil] = React.useState("");
  const [residenciaMeses, setResidenciaMeses] = React.useState("");
  const [residenciaAnios, setResidenciaAnios] = React.useState("");
  const [idEstadoDomicilio, setIdEstadoDomicilio] = React.useState("");
  const [puesto, setPuesto] = React.useState("");
  const [passwd, setPasswd] = React.useState("");
  const [openContacto, setOpenContacto] = React.useState(false);
  const [openOficial, setOpenOficial] = React.useState(false);
  const [openDireccion, setOpenDireccion] = React.useState(false);

  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);

  const agenda = React.useMemo(
    () => ({
      name: "agenda",
      extraParams: {
        idAgenda: user?.idAgenda,
      }
    }),
    [user]
  );

  const [models, modelsLoading, refreshModels] = useModels(
    agenda
  );

 /* const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "usuario",
    id: user.idUsuario,
    expand:
      "municipio,etnia,seccion,distrito,estadoNacimiento,nacimientoMunicipio,estadoCivil,estadoDomicilio",
  });*/
  
  React.useEffect(() => {
    let mounted = true;
    if (mounted && user && models && auth) {
      setIdAgenda(user.idAgenda);
      setIdUsuario(auth?.currentUser.uid);
      setNombre(models[0]?.nombre);
      setApellidoPaterno(models[0]?.apellidoPaterno);
      setApellidoMaterno(models[0]?.apellidoMaterno);
      
    }
    return () => {
      mounted = false;
    };
  }, [user, models, auth]);

  const municipioNacimientoParamsMemo = React.useMemo(
    () => ({
      idEstado: idEstadoNacimiento,
    }),
    [idEstadoNacimiento]
  );

  const municipioParamsMemo = React.useMemo(
    () => ({
      idEstado: idEstadoDomicilio,
    }),
    [idEstadoDomicilio]
  );

  const distritoParamsMemo = React.useMemo(
    () => ({
      tipo: 2,
      ordenar: "nombre.asc",
    }),
    []
  );

  const seccionParamsMemo = React.useMemo(
    () => ({
      idDistrito,
      ordenar: "nombre.asc",
    }),
    [idDistrito]
  );

  const onSubmitPressed = () => {
    setValidating(true);
    const obj = {
      // idMunicipio,
      // idSeccion,
      // localidad,
      // colonia,
      idAgenda,
      idUsuario,
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      //calle,
      // numeroExterior,
      // numeroInterior,
      // codigoPostal,
      // claveElector,
      // folioElector,
      // curp,
      // rfc,
      // alias,
      // sexo,
      // telefonoHogar,
      // telefonoTrabajo,
      // telefonoCelular,
      // telefonoOtro,
      //correo,
      // fechaNacimiento,
      // observaciones,
      // edad,
      // idDistrito,
      // lugarNacimiento,
      // idEtnia,
      // idEstadoNacimiento,
      // idMunicipioNacimiento,
      // idEstadoCivil,
      // residenciaMeses,
      // residenciaAnios,
      // idEstadoDomicilio,
      // puesto,
    };
    if (
      nombre != "" &&
      apellidoPaterno != "" &&
      apellidoMaterno != "" &&
      idAgenda != "" &&
      idUsuario != ""
    ) {
      const request = postRequest("agenda/guardar", {
        ...obj,
      });
      request.alert = true;
      setSaveRequest(request);
      if (!responseError && !responseLoading) {
        setTimeout(() => {
          history.push("/");
        }, 1000);
      }
    } else {
      window["scrollTo"]({top: 0, behavior: "smooth"});
      showAlert({
        message: "Favor de revisar los campos llenados",
        severity: "warning",
      });
    }
  };

  const setIdMunicipioCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdMunicipio(v.idMunicipio);
      } else {
        setIdMunicipio("");
      }
    },
    [setIdMunicipio]
  );

  const setIdSeccionCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdSeccion(v.idSeccion);
      } else {
        setIdSeccion("");
      }
    },
    [setIdSeccion]
  );

  const setLocalidadCallback = React.useCallback(
    (e) => {
      setLocalidad(e.target.value);
    },
    [setLocalidad]
  );

  const setColoniaCallback = React.useCallback(
    (e) => {
      setColonia(e.target.value);
    },
    [setColonia]
  );

  const setNombreCallback = React.useCallback(
    (e) => {
      setNombre(e.target.value);
    },
    [setNombre]
  );

  const setApellidoPaternoCallback = React.useCallback(
    (e) => {
      setApellidoPaterno(e.target.value);
    },
    [setApellidoPaterno]
  );

  const setApellidoMaternoCallback = React.useCallback(
    (e) => {
      setApellidoMaterno(e.target.value);
    },
    [setApellidoMaterno]
  );

  const setCalleCallback = React.useCallback(
    (e) => {
      setCalle(e.target.value);
    },
    [setCalle]
  );

  const setNumeroExteriorCallback = React.useCallback(
    (e) => {
      setNumeroExterior(e.target.value);
    },
    [setNumeroExterior]
  );

  const setNumeroInteriorCallback = React.useCallback(
    (e) => {
      setNumeroInterior(e.target.value);
    },
    [setNumeroInterior]
  );

  const setCodigoPostalCallback = React.useCallback(
    (e) => {
      const {value} = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setCodigoPostal((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setCodigoPostal]
  );

  const setClaveElectorCallback = React.useCallback(
    (e) => {
      setClaveElector(e.target.value);
    },
    [setClaveElector]
  );

  const setFolioElectorCallback = React.useCallback(
    (e) => {
      setFolioElector(e.target.value);
    },
    [setFolioElector]
  );

  const setCurpCallback = React.useCallback(
    (e) => {
      setCurp(e.target.value);
    },
    [setCurp]
  );

  const setRfcCallback = React.useCallback(
    (e) => {
      setRfc(e.target.value);
    },
    [setRfc]
  );

  const setAliasCallback = React.useCallback(
    (e) => {
      setAlias(e.target.value);
    },
    [setAlias]
  );

  const setSexoCallback = React.useCallback(
    (e) => {
      setSexo(e.target.value);
    },
    [setSexo]
  );

  const setTelefonoHogarCallback = React.useCallback(
    (e) => {
      const {value} = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoHogar((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoHogar]
  );

  const setTelefonoTrabajoCallback = React.useCallback(
    (e) => {
      const {value} = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoTrabajo((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoTrabajo]
  );

  const setTelefonoCelularCallback = React.useCallback(
    (e) => {
      const {value} = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoCelular((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoCelular]
  );

  const setTelefonoOtroCallback = React.useCallback(
    (e) => {
      const {value} = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoOtro((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoOtro]
  );

  const setCorreoCallback = React.useCallback(
    (e) => {
      setCorreo(e.target.value);
    },
    [setCorreo]
  );

  const setObservacionesCallback = React.useCallback(
    (e) => {
      setObservaciones(e.target.value);
    },
    [setObservaciones]
  );

  const setEdadCallback = React.useCallback(
    (e) => {
      const {value} = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setEdad((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setEdad]
  );

  const setIdDistritoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdDistrito(v.idDistrito);
      } else {
        setIdDistrito("");
      }
      setIdSeccion("");
      setClearSeccion((v) => !v);
    },
    [setIdDistrito]
  );

  const setFechaNacimientoCallback = React.useCallback(
    (date) => {
      setFechaNacimiento(date);
    },
    [setFechaNacimiento]
  );

  const setLugarNacimientoCallback = React.useCallback(
    (e) => {
      setLugarNacimiento(e.target.value);
    },
    [setLugarNacimiento]
  );

  const setRutaFotografiaCallback = React.useCallback(
    (e) => {
      setRutaFotografia(e.target.value);
    },
    [setRutaFotografia]
  );

  const setIdEtniaCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEtnia(v.idEtnia);
      } else {
        setIdEtnia("");
      }
    },
    [setIdEtnia]
  );

  const setIdEstadoNacimientoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEstadoNacimiento(v.idEstado);
      } else {
        setIdEstadoNacimiento("");
      }
      setIdMunicipioNacimiento("");
      setClearMunicipioNacimiento((v) => !v);
    },
    [setIdEstadoNacimiento]
  );

  const setIdMunicipioNacimientoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdMunicipioNacimiento(v.idMunicipio);
      } else {
        setIdMunicipioNacimiento("");
      }
    },
    [setIdMunicipioNacimiento]
  );

  const setIdEstadoCivilCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEstadoCivil(v.idEstadoCivil);
      } else {
        setIdEstadoCivil("");
      }
    },
    [setIdEstadoCivil]
  );

  const setResidenciaMesesCallback = React.useCallback(
    (e) => {
      const {value} = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setResidenciaMeses((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setResidenciaMeses]
  );

  const setResidenciaAniosCallback = React.useCallback(
    (e) => {
      const {value} = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setResidenciaAnios((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setResidenciaAnios]
  );

  const setIdEstadoDomicilioCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEstadoDomicilio(v.idEstado);
      } else {
        setIdEstadoDomicilio("");
      }
      setIdMunicipio("");
      setClearMunicipio((v) => !v);
    },
    [setIdEstadoDomicilio]
  );

  const setPuestoCallback = React.useCallback(
    (e) => {
      setPuesto(e.target.value);
    },
    [setPuesto]
  );

  const setPasswdCallback = React.useCallback(
    (e) => {
      setPasswd(e.target.value);
    },
    [setPasswd]
  );

  
  if (modelsLoading) return <Loading/>;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{display: "inline-block"}}>
        <StyledBreadcrumb
          component="a"
          label="Inicio"
          icon={<Home fontSize="small"/>}
          onClick={() => history.push("/")}
        />
        <StyledBreadcrumb
          component="a"
          label="Perfil"
          onClick={() => {
            history.push("/perfil");
          }}
        />
      </Breadcrumbs>
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{marginTop: 10}}
      >
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.sectionTitle}>
            INFORMACIÓN DE LA PERSONA
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <img
              alt="Perfil"
              className={classes.profileImg}
              src="https://firebasestorage.googleapis.com/v0/b/xiutech-99ec2.appspot.com/o/app-settings%2Fuser%2Fdefault_user.svg?alt=media&token=6be88838-57f0-4b88-9550-f22f90a393b8"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nombre"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.nombre}
            error={Boolean(responseError && responseError.nombre)}
            onChange={setNombreCallback}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Apellido Paterno"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.apellidoPaterno}
            error={Boolean(responseError && responseError.apellidoPaterno)}
            onChange={setApellidoPaternoCallback}
            inputProps={{
              value: apellidoPaterno,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Apellido Materno"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.apellidoMaterno}
            error={Boolean(responseError && responseError.apellidoMaterno)}
            onChange={setApellidoMaternoCallback}
            inputProps={{
              value: apellidoMaterno,
            }}
          />
        </Grid>

        {/* <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Correo electrónico"
            size="small"
            variant="outlined"
            fullWidth
            type="email"
            helperText={responseError && responseError.correo}
            error={Boolean(responseError && responseError.correo)}
            onChange={setCorreoCallback}
            inputProps={{
              value: correo,
            }}
          />
        </Grid> */}

        {/*<Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Contraseña"
            InputProps={{
              readOnly: true,
            }}
            size="small"
            required
            type="password"
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.puesto}
            error={Boolean(responseError && responseError.puesto)}
            onChange={setPasswdCallback}
            inputProps={{
              value: passwd,
            }}
          />
        </Grid>*/}

        {/* <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Alias"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.alias}
            error={Boolean(responseError && responseError.alias)}
            onChange={setAliasCallback}
            inputProps={{
              value: alias,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Edad"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.edad}
            error={Boolean(responseError && responseError.edad)}
            onChange={setEdadCallback}
            inputProps={{
              value: edad,
              min: 0,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <FormControl
            className={classes.formControl}
            error={Boolean(responseError && responseError.sexo)}
            variant="outlined"
            size="small"
          >
            <InputLabel id="plantilla-label">Sexo</InputLabel>
            <Select
              labelId="plantilla-label"
              label="Sexo"
              value={sexo}
              onChange={setSexoCallback}
            >
              {sexos.map((tipo, index) => (
                <MenuItem key={index} value={tipo}>
                  {tipo}
                </MenuItem>
              ))}
            </Select>
            {responseError && (
              <FormHelperText>{responseError.sexo}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncAutocompleteSearch
            label="Etnia"
            name="etnia"
            defaultValue={user && user.etnia}
            labelProp="nombre"
            onChange={setIdEtniaCallback}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncAutocompleteSearch
            label="Estado Civil"
            name="estado-civil"
            defaultValue={user && user.estadoCivil}
            labelProp="nombre"
            onChange={setIdEstadoCivilCallback}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <DatePicker
            label="Fecha de Nacimiento"
            helperText={responseError && responseError.fechaNacimiento}
            value={fechaNacimiento}
            onChange={setFechaNacimientoCallback}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncAutocompleteSearch
            label="Estado de Nacimiento"
            name="estado"
            defaultValue={user && user.estadoNacimiento}
            labelProp="nombre"
            onChange={setIdEstadoNacimientoCallback}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncAutocompleteSearch
            label="Municipio de Nacimiento"
            name="municipio"
            defaultValue={user && user.nacimientoMunicipio}
            labelProp="nombre"
            onChange={setIdMunicipioNacimientoCallback}
            clearInput={clearMunicipioNacimiento}
            extraParams={municipioNacimientoParamsMemo}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Lugar de Nacimiento"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.lugarNacimiento}
            error={Boolean(responseError && responseError.lugarNacimiento)}
            onChange={setLugarNacimientoCallback}
            inputProps={{
              value: lugarNacimiento,
            }}
          />
        </Grid> */}

        {/* <ListItem
          style={{backgroundColor: "#e0e0e0", color: "#a13800"}}
          button
          onClick={() => setOpenOficial(!openOficial)}
        >
          {openOficial ? <ExpandLess/> : <ExpandMore/>}
          <ListItemText  primary={"INFORMACIÓN OFICIAL"}/>
        </ListItem>
        <Collapse in={openOficial} timeout="auto" unmountOnExit>
          <Grid
            container
            spacing={3}
            className={classes.paper}
            style={{marginTop: 10}}>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Clave de Elector"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.claveElector}
                error={Boolean(responseError && responseError.claveElector)}
                onChange={setClaveElectorCallback}
                inputProps={{
                  value: claveElector,
                  maxLength: 18,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Folio Elector"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.folioElector}
                error={Boolean(responseError && responseError.folioElector)}
                onChange={setFolioElectorCallback}
                inputProps={{
                  value: folioElector,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Curp"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.curp}
                error={Boolean(responseError && responseError.curp)}
                onChange={setCurpCallback}
                inputProps={{
                  value: curp,
                  maxLength: 18,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="RFC"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={
                  !validateRfc(rfc) && validating && "Favor de verificar el RFC"
                }
                error={validating && !validateRfc(rfc)}
                onChange={setRfcCallback}
                inputProps={{
                  value: rfc,
                  maxLength: 13,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Puesto"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.puesto}
                error={Boolean(responseError && responseError.puesto)}
                onChange={setPuestoCallback}
                inputProps={{
                  value: puesto,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Observaciones"
                size="small"
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.observaciones}
                error={Boolean(responseError && responseError.observaciones)}
                onChange={setObservacionesCallback}
                inputProps={{
                  value: observaciones,
                }}
              />
            </Grid>

          </Grid>
        </Collapse>
        <ListItem
          style={{backgroundColor: "#e0e0e0", color: "#a13800"}}
          button
          onClick={() => setOpenDireccion(!openDireccion)}
        >
          {openDireccion ? <ExpandLess/> : <ExpandMore/>}
          <ListItemText  primary={"INFORMACIÓN DE LA DIRECCIÓN"}/>
        </ListItem>
        <Collapse in={openDireccion} timeout="auto" unmountOnExit>
          <Grid
            container
            spacing={3}
            className={classes.paper}
            style={{marginTop: 10}}>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Calle"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.calle}
                error={Boolean(responseError && responseError.calle)}
                onChange={setCalleCallback}
                inputProps={{
                  value: calle,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Número Exterior"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.numeroExterior}
                error={Boolean(responseError && responseError.numeroExterior)}
                onChange={setNumeroExteriorCallback}
                inputProps={{
                  value: numeroExterior,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Número Interior"
                size="small"
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.numeroInterior}
                error={Boolean(responseError && responseError.numeroInterior)}
                onChange={setNumeroInteriorCallback}
                inputProps={{
                  value: numeroInterior,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Colonia"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.colonia}
                error={Boolean(responseError && responseError.colonia)}
                onChange={setColoniaCallback}
                inputProps={{
                  value: colonia,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Estado"
                name="estado"
                defaultValue={user && user.estadoDomicilio}
                labelProp="nombre"
                onChange={setIdEstadoDomicilioCallback}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Municipio"
                name="municipio"
                defaultValue={user && user.municipio}
                labelProp="nombre"
                onChange={setIdMunicipioCallback}
                clearInput={clearMunicipio}
                extraParams={municipioParamsMemo}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Localidad"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.localidad}
                error={Boolean(responseError && responseError.localidad)}
                onChange={setLocalidadCallback}
                inputProps={{
                  value: localidad,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Distrito"
                name="distrito"
                defaultValue={user && user.distrito}
                labelProp="nombre"
                onChange={setIdDistritoCallback}
                extraParams={distritoParamsMemo}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Sección"
                name="seccion"
                defaultValue={user && user.seccion}
                labelProp="nombre"
                onChange={setIdSeccionCallback}
                clearInput={clearSeccion}
                extraParams={seccionParamsMemo}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Código Postal"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.codigoPostal}
                error={Boolean(responseError && responseError.codigoPostal)}
                onChange={setCodigoPostalCallback}
                inputProps={{
                  value: codigoPostal,
                  maxLength: 5,
                  min: 0,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Meses de Residencia"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.residenciaMeses}
                error={Boolean(responseError && responseError.residenciaMeses)}
                onChange={setResidenciaMesesCallback}
                inputProps={{
                  value: residenciaMeses,
                  min: 0,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Años de Residencia"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.residenciaAnios}
                error={Boolean(responseError && responseError.residenciaAnios)}
                onChange={setResidenciaAniosCallback}
                inputProps={{
                  value: residenciaAnios,
                  min: 0,
                }}
              />
            </Grid>
          </Grid>
        </Collapse>

        <ListItem
          style={{backgroundColor: "#e0e0e0", color: "#a13800"}}
          button
          onClick={() => setOpenContacto(!openContacto)}
        >
          {openContacto ? <ExpandLess/> : <ExpandMore/>}
          <ListItemText primary={"INFORMACIÓN DE CONTACTO"}/>
        </ListItem>
        <Collapse in={openContacto} timeout="auto" unmountOnExit>
          <Grid
            container
            spacing={3}
            className={classes.paper}
            style={{marginTop: 10}}>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono Hogar"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.telefonoHogar}
                error={Boolean(responseError && responseError.telefonoHogar)}
                onChange={setTelefonoHogarCallback}
                inputProps={{
                  value: formatPhoneNumber(telefonoHogar),
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono Trabajo"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.telefonoTrabajo}
                error={Boolean(responseError && responseError.telefonoTrabajo)}
                onChange={setTelefonoTrabajoCallback}
                inputProps={{
                  value: formatPhoneNumber(telefonoTrabajo),
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono Celular"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.telefonoCelular}
                error={Boolean(responseError && responseError.telefonoCelular)}
                onChange={setTelefonoCelularCallback}
                inputProps={{
                  value: formatPhoneNumber(telefonoCelular),
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono Otro"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.telefonoOtro}
                error={Boolean(responseError && responseError.telefonoOtro)}
                onChange={setTelefonoOtroCallback}
                inputProps={{
                  value: formatPhoneNumber(telefonoOtro),
                  maxLength: 10,
                }}
              />
            </Grid>
          </Grid>
        </Collapse> */}


        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                // disabled={updateModelLoading}
                endIcon={<Save/>}
                onClick={onSubmitPressed}
              >
                GUARDAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Perfil;
