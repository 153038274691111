import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { Add } from "@material-ui/icons";
import { IconButton, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";

import { photoBaseUrl } from "../../utils/variables";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";

import { useModels } from "../../hooks/useModels";

const CandidaturaComun = () => {
  const history = useHistory();

  const [nombre, setNombre] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "candidatura-comun",
    ordenar: "idCandidaturaComun.desc",
    pagina: 0,
  });

  const onSearchClicked = async (page) => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar esta candidatura común?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        deleteModel(row.idCandidaturaComun);
        refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "candidatura-comun",
      ordenar: "idCandidaturaComun.desc",
      nombre,
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  console.log(models)
  return (
    <TablePageLayout
      title="CONTROL DE CANDIDATURA COMÚN"
      model="partido-politico"
      history={history}
      actionButton={{
        to: "candidatura-comun/nuevo",
        icon: <Add />,
        label: "Agregar Candidatura común",
      }}
      links={[
        {
          label: "Candidatura Común",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={8}>
            <TextField
              fullWidth
              size="small"
              label="Candidatura Común"
              onChange={(event) => setNombre(event.target.value)}
              variant="outlined"
              onBlur={(_) => {
                if (nombre.length > 0) {
                  const nombres = nombre.split(" ");
                  if (nombres && nombres.length > 0)
                    refreshModels(null, { nombre });
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              startIcon={<SearchIcon />}
              style={{ float: "right" }}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={[]}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage !== null ? modelsPage.total : 0}
          labelRowsPerPage={"Renglones por página"}
          extraRows={[
            {
              prop: "claveCandidaturaComun",
              name: "Clave Común",
              cell: (row) => <>{row.claveCandidaturaComun}</>,
            },{
              prop: "nombre",
              name: "Candidatura Común",
              cell: (row) => <>{row.nombre}</>,
            },{
              prop: "nombreAcuerdo",
              name: "Nombre Acuerdo",
              cell: (row) => <>{row.nombreAcuerdo}</>,
            },
            {
              prop: "logo",
              name: "Imagen",
              cell: (row) => (
                <a
                  href={`${photoBaseUrl}${row.logo}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${photoBaseUrl}${row.logo}`}
                    height={80}
                    alt="logo"
                    style={{ borderRadius: 5 }}
                    align="center"
                  />
                </a>
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/candidatura-comun/detalle?idCandidaturaComun=${row.idCandidaturaComun}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default CandidaturaComun;
