import React from "react";
import Grid from "@material-ui/core/Grid";
import FindInPage from "@material-ui/icons/FindInPage";
import AsyncSelect from "../../components/AsyncSelect";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import PageLayout from "../../components/PageLayout";
import {
  BarChart,
  Group,
  History,
  Inbox,
  Pages,
  Receipt,
  Work,
} from "@material-ui/icons";
import { auth } from "../../firebase";
import { baseUrl, baseUrlPdf } from "../../utils/variables";
import AlertDialog from "../../components/AlertDialog";
import {
  Button,
  Typography,
  Paper,
  CardActionArea,
  CardActions,
  CardContent,
  Card,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import PdfDialog from "../../components/PdfDialog";
import { useModels } from "../../hooks/useModels";
import FullScrennLoader from "../../components/FullScreenLoader";
import { useAuth } from "../../hooks/useAuth";
import SeleccionarActa from "../../components/SeleccionarActa";

const params = {
  estatusGT: "con-recuento",
  estatusComputo: "sin-fecha-computo",
  fields: "idActa,nombreCompletoActa,token",
};

const breadcrumbs = [
  {
    to: `/recuento-gt`,
    icon: <FindInPage fontSize="small" />,
    label: `Historial de recuento con GT`,
  },
  {
    to: `/recuento-gt/nuevo`,
    icon: <FindInPage fontSize="small" />,
    label: `Sesión de cómputo: recontar acta con GT`,
  },
];

const requestGT = {
  name: "grupo-trabajo",
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    width: "100%",
  },

  roo2: {
    width: "100%",
    maxWidth: 1000,
  },
}));

const RecuentoGt = ({ history }) => {
  const classes = useStyles();
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const [modelsGT, modelsGTLoading] = useModels(requestGT);
  const [idGrupoTrabajo, setIdGrupoTrabajo] = React.useState(null);
  const id = auth.currentUser.uid;
  const [open, setOpen] = React.useState(false);

  const { user } = useAuth();
  const onEntryChange = (_, value) => {
    if (value && value !== "") {
      history.push(
        `/recuento-gt/nuevo/detalle?id=${value.token}&a=${value.idActa}`
      );
    }
  };

  const setIdGrupoTrabajoCallback = React.useCallback(
    (e, v) => {
      setIdGrupoTrabajo(v);
      setPdfDialogOpen(true);
      setLink(
        `${baseUrlPdf}acta-circunstanciada-grupo-trabajo?idUsuario=${id}&idGrupoTrabajo=${v}`
      );
    },
    [id]
  );

  if (modelsGTLoading) return <FullScrennLoader />;

  return (
    <>
      <PageLayout
        title="SESIÓN DE CÓMPUTO: SELECCIONE EL ACTA POR RECONTAR POR GRUPO DE TRABAJO"
        links={breadcrumbs}
        history={history}
        actionButton={{
          to: `/recuento-gt`,
          icon: <History />,
          label: `HISTORIAL DE RECUENTOS CON GT`,
        }}
        SearchComponents={
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <AsyncAutocompleteSearch
                    label="Seleccione el acta deseada"
                    searchParam="busqueda"
                    name="acta"
                    disabled={modelsGT.length === 0}
                    extraParams={params}
                    labelProp="nombreCompletoActa"
                    onChange={onEntryChange}
                    noOptionsText="No hay actas para recuento con grupo de trabajo."
                  />
                </Grid>
              </Grid>
            </Grid>

            {user &&
              user.puesto === "CONSEJERO(A) PRESIDENTE(A)" &&
              (modelsGT && modelsGT.length ? (
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={3}
                    className={classes.paper}
                    style={{ marginTop: 10 }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.sectionTitle}
                      >
                        <div style={{ fontSize: 24 }}>
                          <strong>GRUPOS DE TRABAJO</strong>
                        </div>
                      </Typography>
                    </Grid>
                    <Grid container>
                      {modelsGT.map((g, i) => (
                        <Grid item xs={12} lg={4} style={{ padding: "10px" }}>
                          <Grid
                            container
                            //component="a"
                            //href={`/grupos-trabajo/grupos/integrantes?id=${g.idGrupoTrabajo}&n=${g.numeroGrupoTrabajo}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Card className={classes.root}>
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  Grupo de Trabajo {g.numeroGrupoTrabajo}
                                  <br />
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  justify="flex-end"
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    size="small"
                                    color="primary"
                                    style={{ color: "white" }}
                                    onClick={() => {
                                      setIdGrupoTrabajo(g.idGrupoTrabajo);
                                      setPdfDialogOpen(true);
                                      setLink(
                                        `${baseUrlPdf}acta-circunstanciada-grupo-trabajo/avance-grupo?idUsuario=${id}&idGrupoTrabajo=${g.idGrupoTrabajo}&n=${g.numeroGrupoTrabajo}`
                                      );
                                    }}
                                    endIcon={<BarChart />}
                                  >
                                    Avance del Grupo
                                  </Button>
                                  <Button
                                    size="small"
                                    color="primary"
                                    style={{ color: "white" }}
                                    onClick={() => {
                                      setIdGrupoTrabajo(g.idGrupoTrabajo);
                                      setPdfDialogOpen(true);
                                      setLink(
                                        `${baseUrlPdf}paquete-grupo-trabajo?idUsuario=${id}&idGrupoTrabajo=${g.idGrupoTrabajo}&n=${g.numeroGrupoTrabajo}`
                                      );
                                    }}
                                    endIcon={<Work />}
                                  >
                                    Paquetes
                                  </Button>
                                  <Button
                                    size="small"
                                    color="primary"
                                    style={{ color: "white" }}
                                    onClick={() => {
                                      setIdGrupoTrabajo(g.idGrupoTrabajo);
                                      setPdfDialogOpen(true);
                                      setLink(
                                        `${baseUrlPdf}acta-circunstanciada-grupo-trabajo?idUsuario=${id}&idGrupoTrabajo=${g.idGrupoTrabajo}`
                                      );
                                    }}
                                    endIcon={<Receipt />}
                                  >
                                    Acta Circunstanciada
                                  </Button>
                                  <Button
                                    size="small"
                                    color="primary"
                                    style={{ color: "white" }}
                                    component="a"
                                    href={`/grupos-trabajo/grupos/integrantes?id=${g.idGrupoTrabajo}&n=${g.numeroGrupoTrabajo}`}
                                    endIcon={<Group />}
                                  >
                                    Conformación
                                  </Button>
                                </Grid>
                              </CardActions>
                            </Card>
                            <div>
                              <br></br>
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ textAlign: "center" }}
                >
                  <Typography
                    variant="h3"
                    color="secondary"
                    className={classes.sectionTitle}
                    style={{ fontSize: 20 }}
                  >
                    <br></br> <hr />
                    Sin Grupos de Trabajo
                  </Typography>
                </Grid>
              ))}
          </>
        }
      >
        <Grid item xs={12}>
          <SeleccionarActa />
        </Grid>
      </PageLayout>
      <AlertDialog
        open={open}
        title="ACTAS CIRCUNSTANCIADAS POR GRUPO DE TRABAJO"
        content={
          <Grid item xs={12}>
            <AsyncSelect
              label="Grupos Trabajo"
              name="grupo-trabajo"
              searchParam="busqueda"
              labelProp="nombreCompleto"
              onChange={setIdGrupoTrabajoCallback}
              extraParams={{
                ordenar: "numeroGrupoTrabajo.asc",
                expand: "nombreCompleto",
              }}
            />
          </Grid>
        }
        onAccept={() => setOpen(false)}
      />
      <PdfDialog
        source={link}
        open={pdfDialogOpen}
        setOpen={setPdfDialogOpen}
      />
    </>
  );
};

export default RecuentoGt;
