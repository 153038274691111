import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack, FreeBreakfastOutlined, Save } from "@material-ui/icons";

import Usuarios from "../usuarios";
import UsersDetail from "../usuarios/detail";

import { formatPhoneNumber } from "../../utils";
import { NON_DIGIT, sexos, tipoPuesto } from "../../constants/mockData";
import { emptyRequest, postRequest } from "../../constants/request";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";

import CustomDialog from "../../components/CustomDialog";
import DatePicker from "../../components/DatePicker";
import AsyncSelect from "../../components/AsyncSelect";
import SingleSelect from "../../components/SingleSelect";

import { useAlert } from "../../hooks/useAlert";
import { useQuery } from "../../hooks/useQuery";
import { useHttp } from "../../hooks/useHttp";
import { useModel } from "../../hooks/useModel";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

function AgendasDetail({
                         match,
                         history,
                         dialog,
                         editingAgenda,
                         setAgendasSearch,
                       }) {
  const classes = useStyles();
  const query = useQuery();
  const idAgenda = query.get("id");
  const editing = !!idAgenda;
  const { showAlert } = useAlert();

  const [validating, setValidating] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(undefined);
  const [editingUser, setEditingUser] = React.useState(undefined);
  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(true);
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [clearMunicipio, setClearMunicipio] = React.useState(false);
  const [idSeccion, setIdSeccion] = React.useState("");
  const [clearSeccion, setClearSeccion] = React.useState(false);
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [clearConsejo, setClearConsejo] = React.useState("");
  const [localidad, setLocalidad] = React.useState("");
  const [colonia, setColonia] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [apellidoPaterno, setApellidoPaterno] = React.useState("");
  const [apellidoMaterno, setApellidoMaterno] = React.useState("");
  const [calle, setCalle] = React.useState("");
  const [numeroExterior, setNumeroExterior] = React.useState("");
  const [numeroInterior, setNumeroInterior] = React.useState("");
  const [codigoPostal, setCodigoPostal] = React.useState("");
  const [claveElector, setClaveElector] = React.useState("");
  const [folioElector, setFolioElector] = React.useState("");
  const [curp, setCurp] = React.useState("");
  const [rfc, setRfc] = React.useState("");
  const [alias, setAlias] = React.useState("");
  const [sexo, setSexo] = React.useState("");
  const [telefonoHogar, setTelefonoHogar] = React.useState("");
  const [telefonoTrabajo, setTelefonoTrabajo] = React.useState("");
  const [telefonoCelular, setTelefonoCelular] = React.useState("");
  const [telefonoOtro, setTelefonoOtro] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [observaciones, setObservaciones] = React.useState("");
  const [edad, setEdad] = React.useState("");
  const [idDistrito, setIdDistrito] = React.useState("");
  const [clearDistrito, setClearDistrito] = React.useState(false);
  const [fechaNacimiento, setFechaNacimiento] = React.useState(new Date());
  const [lugarNacimiento, setLugarNacimiento] = React.useState("");
  // const [rutaFotografia, setRutaFotografia] = React.useState("");
  const [idEstadoNacimiento, setIdEstadoNacimiento] = React.useState("");
  const [idMunicipioNacimiento, setIdMunicipioNacimiento] = React.useState("");
  const [
    clearMunicipioNacimiento,
    setClearMunicipioNacimiento,
  ] = React.useState(false);
  const [idEstadoDomicilio, setIdEstadoDomicilio] = React.useState("");
  const [idTipoAgenda, setIdTipoAgenda] = React.useState("");
  const [puesto, setPuesto] = React.useState("");

  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);
  const [opcionesPuesto, setOpcionesPuesto] = React.useState([
    {nombre: "CONSEJERO PRESIDENTE"},
    {nombre: "SECRETARIO TÉCNICO"},
    {nombre: "CONSEJERO PROPIETARIO"},
    {nombre: "CONSEJERO SUPLENTE"},
  ]);

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "agenda",
    id: idAgenda,
    expand:
      "municipio,seccion,distrito,estadoNacimiento,nacimientoMunicipio,estadoDomicilio,tipoAgenda,usuario,consejoElectoral",
  });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setIdMunicipio(model.idMunicipio);
      setIdSeccion(model.idSeccion);
      setIdConsejoElectoral(model.idConsejoElectoral);
      setLocalidad(model.localidad);
      setColonia(model.colonia);
      setNombre(model.nombre);
      setApellidoPaterno(model.apellidoPaterno);
      setApellidoMaterno(model.apellidoMaterno);
      setCalle(model.calle);
      setNumeroExterior(model.numeroExterior);
      setNumeroInterior(model.numeroInterior);
      setCodigoPostal(model.codigoPostal);
      setClaveElector(model.claveElector);
      setFolioElector(model.folioElector);
      setCurp(model.curp);
      setRfc(model.rfc);
      setAlias(model.alias);
      setSexo(model.sexo);
      setTelefonoHogar(model.telefonoHogar);
      setTelefonoTrabajo(model.telefonoTrabajo);
      setTelefonoCelular(model.telefonoCelular);
      setTelefonoOtro(model.telefonoOtro);
      setCorreo(model.correo);
      setObservaciones(model.observaciones);
      setEdad(model.edad);
      setIdDistrito(model.idDistrito);
      setFechaNacimiento(model.fechaNacimiento);
      setLugarNacimiento(model.lugarNacimiento);
      //setRutaFotografia
      setIdEstadoNacimiento(model.idEstadoNacimiento);
      setIdMunicipioNacimiento(model.idMunicipioNacimiento);
      setIdEstadoDomicilio(model.idEstadoDomicilio);
      setIdTipoAgenda(model.idTipoAgenda);
      setPuesto(model.puesto);
      setSelectedUser(model.usuario);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const municipioNacimientoParamsMemo = React.useMemo(
    () => ({
      idEstado: idEstadoNacimiento,
    }),
    [idEstadoNacimiento]
  );

  const municipioParamsMemo = React.useMemo(
    () => ({
      idEstado: idEstadoDomicilio,
    }),
    [idEstadoDomicilio]
  );

  const distritoParamsMemo = React.useMemo(
    () => ({
      tipo: 2,
      ordenar: "nombre.asc",
    }),
    []
  );

  const seccionParamsMemo = React.useMemo(
    () => ({
      idDistrito,
      ordenar: "nombre.asc",
    }),
    [idDistrito]
  );

  //por usar para filtrar consejos por criterio de distrito,seccion, municipio, etc.
  const consejoParamsMemo = React.useMemo(() => ({}), []);

  React.useEffect(() => {
    if (!responseError && !responseLoading && response) {
      history.push("/consejeros");
    }
  }, [responseError, responseLoading, response, history]);

  const onSubmitPressed = () => {
    setValidating(true);
    const agenda = {
      idMunicipio,
      idSeccion,
      localidad,
      colonia,
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      calle,
      numeroExterior,
      numeroInterior,
      codigoPostal,
      claveElector,
      folioElector,
      curp,
      rfc,
      alias,
      sexo,
      telefonoHogar,
      telefonoTrabajo,
      telefonoCelular,
      telefonoOtro,
      correo,
      fechaNacimiento,
      observaciones,
      edad,
      idDistrito,
      idConsejoElectoral,
      lugarNacimiento,
      idEstadoNacimiento,
      idMunicipioNacimiento,
      idEstadoDomicilio,
      idTipoAgenda,
      puesto,
      idUsuario: selectedUser ? selectedUser.idUsuario : null,
    };
    if (editing) {
      agenda.idAgenda = idAgenda;
    }
    console.log(agenda);
    // if (
    //   Object.values(agenda).indexOf("") === -1 &&
    //   !Object.values(agenda).some((e) => !e) &&
    //   validateEmail(correo) &&
    //   validateRfc(rfc)
    // ) {
    //   const request = postRequest("agenda/guardar", agenda);
    //   if (!dialog) {
    //     request.alert = true;
    //   }
    //   setSaveRequest(request);
    // } else {
    //   if (!dialog) {
    //     window["scrollTo"]({ top: 0, behavior: "smooth" });
    //     showAlert({
    //       message: "Favor de revisar los campos llenados",
    //       severity: "warning",
    //     });
    //   }
    // }
    if (
      (agenda.nombre !== "" && agenda.nombrePaterno !== "",
      agenda.nombreMaterno !== "",
      idConsejoElectoral !== "")
    ) {
      const request = postRequest("agenda/guardar", agenda);
      setSaveRequest(request);
      if (!dialog) {
        request.alert = true;
      }
    } else {
      if (!dialog) {
        window["scrollTo"]({ top: 0, behavior: "smooth" });
        showAlert({
          message: "Favor de revisar los campos llenados",
          severity: "warning",
        });
      }
    }
  };

  const setIdMunicipioCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdMunicipio(v.idMunicipio);
      } else {
        setIdMunicipio("");
      }
      setIdConsejoElectoral("");
      setClearConsejo((v) => !v);
    },
    [setIdMunicipio]
  );

  const setIdSeccionCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdSeccion(v.idSeccion);
      } else {
        setIdSeccion("");
      }
      setIdMunicipio("");
      setClearMunicipio((v) => !v);
      setIdConsejoElectoral("");
      setClearConsejo((v) => !v);
    },
    [setIdSeccion]
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdConsejoElectoral(v.idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setIdConsejoElectoral]
  );

  const setLocalidadCallback = React.useCallback(
    (e) => {
      setLocalidad(e.target.value);
    },
    [setLocalidad]
  );

  const setColoniaCallback = React.useCallback(
    (e) => {
      setColonia(e.target.value);
    },
    [setColonia]
  );

  const setNombreCallback = React.useCallback(
    (e) => {
      setNombre(e.target.value);
    },
    [setNombre]
  );

  const setApellidoPaternoCallback = React.useCallback(
    (e) => {
      setApellidoPaterno(e.target.value);
    },
    [setApellidoPaterno]
  );

  const setApellidoMaternoCallback = React.useCallback(
    (e) => {
      setApellidoMaterno(e.target.value);
    },
    [setApellidoMaterno]
  );

  const setCalleCallback = React.useCallback(
    (e) => {
      setCalle(e.target.value);
    },
    [setCalle]
  );

  const setNumeroExteriorCallback = React.useCallback(
    (e) => {
      setNumeroExterior(e.target.value);
    },
    [setNumeroExterior]
  );

  const setNumeroInteriorCallback = React.useCallback(
    (e) => {
      setNumeroInterior(e.target.value);
    },
    [setNumeroInterior]
  );

  const setCodigoPostalCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setCodigoPostal((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setCodigoPostal]
  );

  const setClaveElectorCallback = React.useCallback(
    (e) => {
      setClaveElector(e.target.value);
    },
    [setClaveElector]
  );

  const setFolioElectorCallback = React.useCallback(
    (e) => {
      setFolioElector(e.target.value);
    },
    [setFolioElector]
  );

  const setCurpCallback = React.useCallback(
    (e) => {
      setCurp(e.target.value);
    },
    [setCurp]
  );

  const setRfcCallback = React.useCallback(
    (e) => {
      setRfc(e.target.value);
    },
    [setRfc]
  );

  const setAliasCallback = React.useCallback(
    (e) => {
      setAlias(e.target.value);
    },
    [setAlias]
  );

  const setSexoCallback = React.useCallback(
    (e) => {
      setSexo(e.target.value);
    },
    [setSexo]
  );

  const setTelefonoHogarCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoHogar((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoHogar]
  );

  const setTelefonoTrabajoCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoTrabajo((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoTrabajo]
  );

  const setTelefonoCelularCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoCelular((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoCelular]
  );

  const setTelefonoOtroCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoOtro((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoOtro]
  );

  const setCorreoCallback = React.useCallback(
    (e) => {
      setCorreo(e.target.value);
    },
    [setCorreo]
  );

  const setObservacionesCallback = React.useCallback(
    (e) => {
      setObservaciones(e.target.value);
    },
    [setObservaciones]
  );

  const setEdadCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setEdad((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setEdad]
  );

  const setIdDistritoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdDistrito(v.idDistrito);
      } else {
        setIdDistrito("");
      }
      setIdSeccion("");
      setClearSeccion((v) => !v);
      setIdConsejoElectoral("");
      setClearConsejo((v) => !v);
    },
    [setIdDistrito]
  );

  const setFechaNacimientoCallback = React.useCallback(
    (date) => {
      setFechaNacimiento(date);
    },
    [setFechaNacimiento]
  );

  const setLugarNacimientoCallback = React.useCallback(
    (e) => {
      setLugarNacimiento(e.target.value);
    },
    [setLugarNacimiento]
  );

  // const setRutaFotografiaCallback = React.useCallback(
  //   (e) => {
  //     setRutaFotografia(e.target.value);
  //   },
  //   [setRutaFotografia]
  // );

  const setIdEstadoNacimientoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEstadoNacimiento(v.idEstado);
      } else {
        setIdEstadoNacimiento("");
      }
      setIdMunicipioNacimiento("");
      setClearMunicipioNacimiento((v) => !v);
    },
    [setIdEstadoNacimiento]
  );

  const setIdMunicipioNacimientoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdMunicipioNacimiento(v.idMunicipio);
      } else {
        setIdMunicipioNacimiento("");
      }
    },
    [setIdMunicipioNacimiento]
  );

  const setIdEstadoDomicilioCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEstadoDomicilio(v.idEstado);
      } else {
        setIdEstadoDomicilio("");
      }

      setIdMunicipio("");
      setClearMunicipio((v) => !v);
      setIdDistrito("");
      setClearDistrito((v) => !v);
      setIdSeccion("");
      setClearSeccion((v) => !v);
      setIdConsejoElectoral("");
      setClearConsejo((v) => !v);
    },
    [setIdEstadoDomicilio]
  );

  const setIdTipoAgendaCallback = React.useCallback(
    (e) => {
      setIdTipoAgenda(e.target.value.id);
      setOpcionesPuesto();
    },
    [setIdTipoAgenda]
  );

  const setPuestoCallback = React.useCallback(
    (e) => {
      setPuesto(e.target.value.nombre);
    },
    [setPuesto]
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  const CustomDialogContent = (props) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {search ? (
            <>
              <Grid container>
                <Button
                  variant="contained"
                  color="primary"
                  fullwidth
                  style={{ marginBottom: 10, marginTop: 10 }}
                  onClick={() => {
                    setSearch(false);
                    setEditingUser(undefined);
                  }}
                >
                  CREAR USUARIO
                </Button>
              </Grid>
              <Usuarios
                dialog={true}
                setEditingUser={setEditingUser}
                setSearch={setSearch}
                setSelectedUser={setSelectedUser}
                handleClose={handleClose}
              />
            </>
          ) : (
            <>
              <Grid container>
                <Button
                  onClick={() => {
                    setSearch(true);
                  }}
                >
                  <ArrowBack color="primary" />
                </Button>
              </Grid>
              <UsersDetail
                match={match}
                history={history}
                dialog={true}
                editingUser={editingUser}
                setSearch={setSearch}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  console.log(opcionesPuesto);
  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={"Editar " + nombre + " " + apellidoPaterno + " " + apellidoMaterno}
        model="agenda"
        editing={editing}
        dialog={dialog}
        links={[
          {
            to: "/consejeros",
            label: "Agendas",
          },
          {
            to: "/consejeros",
            label: nombre + " " + apellidoPaterno + " " + apellidoMaterno,
          },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <CustomDialog
          isOpen={isOpen}
          handleClose={handleClose}
          title="ASOCIAR CUENTA A AGENDA"
          subtitle="Vincula una cuenta a la agenda"
        >
          <CustomDialogContent />
        </CustomDialog>
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.sectionTitle}>
            INFORMACIÓN DE LA PERSONA
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nombre"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.nombre}
            error={Boolean(responseError && responseError.nombre)}
            onChange={setNombreCallback}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Apellido Paterno"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.apellidoPaterno}
            error={Boolean(responseError && responseError.apellidoPaterno)}
            onChange={setApellidoPaternoCallback}
            inputProps={{
              value: apellidoPaterno,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Apellido Materno"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={responseError && responseError.apellidoMaterno}
            error={Boolean(responseError && responseError.apellidoMaterno)}
            onChange={setApellidoMaternoCallback}
            inputProps={{
              value: apellidoMaterno,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <SingleSelect
            label="Puesto"
            options={opcionesPuesto}
            onChange={setPuestoCallback}
            helperText={responseError && responseError.puesto}
            error={Boolean(responseError && responseError.puesto)}
            defaultValue={model && model.puesto}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSubmitPressed}
              >
                {editing ? "EDITAR" : "CREAR"} AGENDA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AgendasDetail;
