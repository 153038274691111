import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Paper,
  Typography,
  IconButton,
  Box
} from "@material-ui/core";
import { baseUrl, baseUrlPdf } from "../../utils/variables";
import { auth } from "../../firebase";
import { useAuth } from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import { useQuery } from "../../hooks/useQuery";
import PdfDialog from "../../components/PdfDialog";
import Services from "../../services";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { useAlert } from "../../hooks/useAlert";
import {
  Close
} from "@material-ui/icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    color: "white",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    marginTop: 20,
  },
}));

const GUBERNATURA = 1;
const AYUNTAMIENTO = 2;
const DISTRITAL_MR = 3;
const DISTRITAL_RP = 4;

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const ResultadosDetail = () => {
  const query = useQuery();
  const { user, userLoading } = useAuth();
  const history = useHistory();
  const classes = useStyles();
  const id = auth.currentUser.uid;
  const todos = query.get("todos") == 1;
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [nombreConsejo, setNombreConsejo] = React.useState("");
  const [planilla, setPlanilla] = React.useState([]);
  const [regidores, setRegidores] = React.useState([]);
  const [presidente, setPresidente] = React.useState("");
  const [sindicoPro, setSindicoPro] = React.useState("");
  const [sindicoSup, setSindicoSup] = React.useState("");
  const { showAlert } = useAlert();
  const [open, setOpen] = React.useState(false);
  const [idTipoConsejoElectoral, setIdTipoConsejoElectoral] = React.useState(null);
  const [tipo, setTipo] = React.useState([]);
  const [consejoPlanilla, setConsejoPlanilla] = React.useState(0);

  const paquetes = async () => {
    const response3 = await Services.get("grupo-trabajo");
    setTotal(response3.resultado);
  };

  const consejoPlanillaExiste = async () => {
    const response7 = await Services.post("consejo-electoral/consejo-planilla",{
        idConsejoElectoral: user?.consejoElectoral?.idConsejoElectoral
      }
    );
    setConsejoPlanilla(response7.resultado[0]);
  };

  React.useEffect(() => {
    if (!user) return;
    paquetes();
    consejoPlanillaExiste();
    setIdTipoConsejoElectoral(user?.consejoElectoral?.idTipoConsejoElectoral);
    if(idTipoConsejoElectoral === 2)
      setNombreConsejo("AYUNTAMIENTO");
    if(idTipoConsejoElectoral === 3 || idTipoConsejoElectoral === 4)
      setNombreConsejo("DIPUTACIÓN");
  }, [user]);
  
  const handleChange = (cargo, evento) => {
    const nombre = evento.target.name;
    const valor = evento.target.value;

    planilla[nombre] = {
      "cargo": cargo,
      "orden": nombre,
      "nombre": valor
    };

    setPlanilla([...planilla]);
  };

  const handleChange2 = (cargo, evento) => {
    const nombre = evento.target.name;
    const valor = evento.target.value;

    regidores[nombre] = {
      "cargo": cargo,
      "orden": nombre,
      "nombre": valor
    };

    setRegidores([...regidores]);
  };

  const guardar = async () => {
    try {
      let _planilla = [];
      if(idTipoConsejoElectoral == 2)
        _planilla = [...planilla, ...regidores];
      if(idTipoConsejoElectoral == 3 || idTipoConsejoElectoral == 4)
        _planilla = [...regidores];
      
      await Services.post("consejo-electoral/guardar-planilla", {
        planilla: _planilla,
        idConsejoElectoral: user?.consejoElectoral?.idConsejoElectoral,
        idTipoConsejoElectoral: idTipoConsejoElectoral,
      });

      showAlert({
        severity: "success",
        message: "Planilla guardada con éxito.",
      });

    } catch (error) {
      console.log(error);
    } finally {
      
    }
  };

  if (!user || userLoading) return <Loading />;

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ display: "inline-block" }}>
        <StyledBreadcrumb
          component="a"
          label="Inicio"
          icon={<HomeIcon fontSize="small" />}
          onClick={() => {
            history.push("/");
          }}
        />
        <StyledBreadcrumb component="a" label="Resultados" onClick={() => {}} />
      </Breadcrumbs>

      <Grid container spacing={3} component={Paper} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Typography>
            SE HA REALIZADO EL CÓMPUTO DE LA TOTALIDAD DE CASILLAS.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Grid
            component="a"
            onClick={() => {
              setPdfDialogOpen(true);
              setLink(`${baseUrlPdf}computo-final?idUsuario=${id}`);
            }}
            style={{ textDecoration: "none" }}
          >
            <Card className={classes.card}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Acta de Cómputo
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid container justify="flex-end">
                    <Button
                      size="small"
                      color="primary"
                      style={{ color: "white" }}
                    >
                      Imprimir
                    </Button>
                  </Grid>
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        {(user.consejoElectoral?.idTipoConsejoElectoral === DISTRITAL_RP ||
          user.consejoElectoral?.idTipoConsejoElectoral === DISTRITAL_MR ||
          todos) && (
          <Grid item xs={12} lg={4}>
            <Grid
              component="a"
              onClick={() => {
                setPdfDialogOpen(true);
                setLink(`${baseUrlPdf}acta-computo-rp?idUsuario=${id}`);
              }}
              style={{ textDecoration: "none" }}
            >
              <Card className={classes.card}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Acta de Cómputo Distrital de la elección para las&nbsp;
                      <b>DIPUTACIONES LOCALES DE REPRESENTACIÓN PROPORCIONAL</b>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid container justify="flex-end">
                      <Button
                        size="small"
                        color="primary"
                        style={{ color: "white" }}
                      >
                        Imprimir
                      </Button>
                    </Grid>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        )}
        
        {
          total && total.length ? (
            <Grid item xs={12} lg={4}>
              <Grid
                component="a"
                onClick={() => {
                  setPdfDialogOpen(true);
                  setLink(
                    `${baseUrlPdf}acta-circunstanciada-voto-reservado?idUsuario=${id}`
                  );
                }}
                style={{ textDecoration: "none" }}
              >
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Acta circunstanciada de votos reservados
                        <br />
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Grid container justify="flex-end">
                        <Button
                          size="small"
                          color="primary"
                          style={{ color: "white" }}
                        >
                          Imprimir
                        </Button>
                      </Grid>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          ) : (null)
        }

        <Grid item xs={12} lg={4}>
          <Grid
            component="a"
            onClick={() => {
              setPdfDialogOpen(true);
              setLink(
                `${baseUrlPdf}constancia-mayoria-validez?idUsuario=${id}`
              );
            }}
            style={{ textDecoration: "none" }}
          >
            <Card className={classes.card}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Constancia de Mayoría y Validez
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid container justify="flex-end">
                    <Button
                      size="small"
                      color="primary"
                      style={{ color: "white" }}
                    >
                      Imprimir
                    </Button>
                  </Grid>
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {
        user && idTipoConsejoElectoral === 2 && (
          
            <Grid container spacing={3} component={Paper} style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Typography >
                <div style={{ fontSize: 20 }}>
                  CONFORMACIÓN DE PLANILLA TIPO {nombreConsejo}
                </div>
              </Typography>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b style={{ fontSize: 14 }}>PUESTO</b>
                    </TableCell>
                    <TableCell>
                      <b style={{ fontSize: 14 }}>PERSONA</b>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setRegidores((v) => [
                            ...v,
                            { cargo: "", nombre: "" },
                          ]);
                        }}
                      >
                        <Typography>AGREGAR REGIDOR(A)</Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" style={{ width: "20%" }}>
                    <p style={{ fontSize: 18 }}>
                      PRESIDENTE(A) MUNICIPAL
                    </p>
                    </TableCell>
                    <TableCell component="th" style={{ width: "70%" }}>
                      <TextField
                        name={0}
                        label="Persona"
                        size="small"
                        required
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange("PRESIDENTE(A) MUNICIPAL", e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" style={{ width: "20%" }}>
                    <p style={{ fontSize: 18 }}>
                      SINDICO(A) PROPIETARIO
                    </p>
                    </TableCell>
                    <TableCell component="th" style={{ width: "70%" }}>
                      <TextField
                        name={1}
                        label="Persona"
                        size="small"
                        required
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange("SINDICO(A) PROPIETARIO", e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" style={{ width: "20%" }}>
                    <p style={{ fontSize: 18 }}>
                      SINDICO(A) SUPLENTE
                    </p>
                    </TableCell>
                    <TableCell component="th" style={{ width: "70%" }}>
                      <TextField
                        name={2}
                        label="Persona"
                        size="small"
                        required
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange("SINDICO(A) SUPLENTE", e)}
                      />
                    </TableCell>
                  </TableRow>
                  {regidores.map((reg, indexReg) => (
                    <>
                      <TableRow key={indexReg}>
                        <TableCell component="th" style={{ width: "20%" }}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            style={{ width: "100%" }}
                          >
                            <Select
                              id={indexReg}
                              labelId="plantilla-label"
                              label="TIPO REGIDOR(A)"
                              value={tipo[indexReg]}
                              onChange={(v) => {
                                setTipo(t => {
                                  let copia = [... t]
                                  copia[indexReg] = v.target.value
                                  return copia
                                });
                              }}
                            >
                              <MenuItem value="REGIDOR(A) PROPIETARIO">
                              REGIDOR(A) PROPIETARIO
                              </MenuItem>
                              <MenuItem value="REGIDOR(A) SUPLENTE">
                              REGIDOR(A) SUPLENTE
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell component="th" style={{ width: "70%" }}>
                          <TextField
                            name={indexReg}
                            label="Persona"
                            size="small"
                            required
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              
                                handleChange2(tipo[indexReg], e);
                              console.log(regidores)
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" style={{ width: "20%" }}>
                        <IconButton
                          onClick={() => {
                            setRegidores((v) =>
                              v.filter((o, index) => index !== indexReg)
                            );
                          }}
                        >
                          <Close color="secondary" />
                        </IconButton>
                      </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={false}
              onClick={async () => {
                await guardar();
              }}
            >
              {"GUARDAR PLANILLA"}
            </Button>
            </Grid>
          </Grid>
        ) 
      }
      {
        user && (idTipoConsejoElectoral === 3 ||
        idTipoConsejoElectoral === 4) &&(
          <Grid container spacing={3} component={Paper} style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Typography >
                <div style={{ fontSize: 20 }}>
                  CONFORMACIÓN DE PLANILLA TIPO {nombreConsejo}
                </div>
              </Typography>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b style={{ fontSize: 14 }}>PUESTO</b>
                    </TableCell>
                    <TableCell>
                      <b style={{ fontSize: 14 }}>PERSONA</b>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setRegidores((v) => [
                            ...v,
                            { cargo: "", nombre: "" },
                          ]);
                        }}
                      >
                        <Typography>AGREGAR DIPUTADOS</Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {regidores.map((reg, indexReg) => (
                    <>
                      <TableRow key={indexReg}>
                        <TableCell component="th" style={{ width: "20%" }}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            style={{ width: "100%" }}
                          >
                            <Select
                              id={indexReg}
                              labelId="plantilla-label"
                              label="TIPO DIPUTADO(A)"
                              value={tipo[indexReg]}
                              onChange={(v) => {
                                setTipo(t => {
                                  let copia = [... t]
                                  copia[indexReg] = v.target.value
                                  return copia
                                });
                              }}
                            >
                              <MenuItem value="DIPUTADO(A) PROPIETARIO">
                              DIPUTADO(A) PROPIETARIO
                              </MenuItem>
                              <MenuItem value="DIPUTADO(A) SUPLENTE">
                              DIPUTADO(A) SUPLENTE
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell component="th" style={{ width: "70%" }}>
                          <TextField
                            name={indexReg}
                            label="Persona"
                            size="small"
                            required
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              
                                handleChange2(tipo[indexReg], e);
                              console.log(regidores)
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" style={{ width: "20%" }}>
                        <IconButton
                          onClick={() => {
                            setRegidores((v) =>
                              v.filter((o, index) => index !== indexReg)
                            );
                          }}
                        >
                          <Close color="secondary" />
                        </IconButton>
                      </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid item xs={3}>
            <Box display="flex" p={1}>
              <Box flexGrow={1}></Box>
              <Box mr={1}>
              <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={false}
              onClick={async () => {
                await guardar();
              }}
            >
              {"GUARDAR PLANILLA"}
            </Button>
              </Box>
            </Box>
            </Grid>
          </Grid>
        )
      }
      <PdfDialog
        source={link}
        open={pdfDialogOpen}
        setOpen={setPdfDialogOpen}
      />
      {/* <Dialog
        open={open}
        onClose={setOpen(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Grid>
            <DialogTitle id="max-width-dialog-title">
              <div>
                <h3 >{``}</h3>
                <h4>{``}</h4>   
              </div>
            </DialogTitle>
          </Grid>          
        </DialogContent>
        <DialogActions>
          <Button onClick={setOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default ResultadosDetail;
