import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { validateEmail } from "../../utils";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import swal from "sweetalert";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

const Bodegas = () => {
  const history = useHistory();
  const header = [
    {
      prop: "nombre",
      name: "Nombre",
    },
  ];
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "bodega",
    expand: "distritoLocal,distritoFederal,municipio,consejoElectoral",
  });
  const [nombre, setNombre] = useState("");
  const [idDistritoFederal, setIdDistritoFederal] = useState("");
  const [idDistritoLocal, setIdDistritoLocal] = useState("");
  const [idMunicipio, setIdMunicipio] = useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [numero, setNumero] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();


  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    if (idDistritoFederal.toString().length > 0) params = { ...params, idDistritoFederal };
    if (idDistritoLocal.toString().length > 0) params = { ...params, idDistritoLocal };
    if (idMunicipio.toString().length > 0) params = { ...params, idMunicipio };
    if (idConsejoElectoral.toString().length > 0) params = { ...params, idConsejoElectoral };
    await refreshModels(true, params);
  };

  const distritosParamsMemo = React.useMemo(() => ({ tipo: 1 }), []);

  const [modelDistrito, modelDistritoLoading] = useModels({
    name: "distrito",
    ordenar: "idDistrito.asc",
    limite: -1,
    extraParams: distritosParamsMemo
  });

  const [
    modelDistritoLocal,
    modelDistritoLocalLoading,
    modelDistritoLocalError,
    modelDistritoLocalPage,
    modelDistritoLocalRefresh,
  ] = useModels({
    name: "distrito",
    expand: "distritosLocales",
    ordenar: "idDistrito.asc",
    limite: -1,
    extraParams: distritosParamsMemo
  });

  const distritosFederalesCambio = (event) => {
    const { value } = event.target;
    let params = {};
    setIdDistritoFederal(value);
    params = { ...params,idDistrito: value }
    modelDistritoLocalRefresh(true,params)
  };

  const distritosLocalesCambio = (event) => {
    const { value } = event.target;
    setIdDistritoLocal(value);
  };

  const municipioCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
    } else {
      setIdMunicipio("");
    }
  }, []);

  const consejoCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdConsejoElectoral(v.idConsejoElectoral);
    } else {
      setIdConsejoElectoral("");
    }
  }, []);

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Deseas eliminar esta bodega?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        deleteModel(row.idBodega);
        refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "bodega",
      expand: "distritoLocal,distritoFederal,municipio,consejoElectoral",
      nombre,
      idDistritoFederal,
      idDistritoLocal,
      idMunicipio,
      idConsejoElectoral,
      ordenar: "idBodega.asc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="CONTROL DE BODEGAS"
      model="bodega"
      history={history}
      SearchComponents={
        <>
         <Grid item xs={12} sm={4}>
            <FormControl  variant="outlined" fullWidth size="small" style={{margin:"0px 0px"}}>
            <InputLabel id="demo-simple-select-outlined-label">Distritos Federales</InputLabel>
                <Select
                value={idDistritoFederal}
                style={{ textAlign: "left" }}
                variant="outlined"
                onChange={distritosFederalesCambio}
                label="Distritos Federales"
                >
                {modelDistrito !== null ? 
                      modelDistrito.map((opt, index) => (
                        <MenuItem key={index} value={opt.idDistrito}>
                        {opt.nombre}
                        </MenuItem>
                    ))
                  : []}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
          <FormControl  variant="outlined" fullWidth size="small" style={{margin:"0px 0px"}}>
            <InputLabel id="demo-simple-select-outlined-label">Distritos Locales</InputLabel>
                <Select
                disabled={!idDistritoFederal}
                value={idDistritoLocal}
                style={{ textAlign: "left" }}
                variant="outlined"
                onChange={distritosLocalesCambio}
                label="Distritos Locales"
                >
                    {modelDistritoLocal && 
                      modelDistritoLocal[0] && modelDistritoLocal[0].distritosLocales
                    ? modelDistritoLocal[0].distritosLocales.map((opt, index) => (
                        <MenuItem
                          key={index}
                          value={opt.idDistrito}
                        >
                          {opt.nombre}
                        </MenuItem>
                    ))
                  : []}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <AsyncAutocompleteSearch
              label="Municipio"
              name="municipio"
              labelProp="nombre"
              onChange={municipioCambio}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AsyncAutocompleteSearch
              label="Consejos Electorales"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={consejoCambio}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Buscar por nombre de bodega"
              variant="outlined"
              size="small"
              onChange={(event) => setNombre(event.target.value)}
              onBlur={(_) => {
                if (validateEmail(nombre)) {
                  refreshModels(null, { nombre });
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage !== null ? modelsPage.total : 0}
          labelRowsPerPage={"Renglones por página"}
          extraRows={[
            {
              prop: "calle",
              name: "Domicilio",
              cell: (row, index) => (
                <div>
                  {row.calle} {row.numero} {row.colonia} {row.codigoPostal}
                  <br />
                  Entre: {row.entreCalles}
                </div>
              ),
            },
            {
              prop: "telefono",
              name: "Teléfono de contacto",
              cell: (row, index) => <div>{row.telefono}</div>,
            },
            {
              prop: "distritoFederal",
              name: "Distrito Federal",
              cell: (row, index) => (
                <>
                  {row.distritoFederal ? row.distritoFederal.nombre : "Sin distrito Federal"}
                </>
              ),
            },
            {
              prop: "distritoLocal",
              name: "Distrito Local",
              cell: (row, index) => (
                <>
                  {row.distritoLocal ? row.distritoLocal.nombre : "Sin distrito Local"}
                </>
              ),
            },
            {
              prop: "municipio",
              name: "Municipio",
              cell: (row, index) => (
                <div>
                  {row.municipio ? row.municipio.nombre : "Sin municipio"}
                </div>
              ),
            },
            {
              prop: "consejo",
              name: "Consejo Electoral",
              cell: (row, index) => (
                <div>
                  {row.consejoElectoral ? row.consejoElectoral.nombre : "Sin Consejo Electoral"}
                </div>
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() => history.push(`/bodegas/detalle?idBodega=${row.idBodega}`)}
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Bodegas;
