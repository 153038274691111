import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import PageHeader from "../../components/PageHeader";
import Loading from "../../components/FullScreenLoader";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import { useModel } from "../../hooks/useModel";
import { useQuery } from "../../hooks/useQuery";
import { useModels } from "../../hooks/useModels";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

const BodegasDetail = ({ match, history }) => {
  const classes = useStyles();
  const query = useQuery();
  const idBodega = query.get("idBodega");
  const editing = !!idBodega;
  const [editingTitle, setEditingTitle] = React.useState("Cargando...");

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "bodega",
    expand: "municipio,consejoElectoral",
    id: idBodega,
    redirectOnPost: true,
  });

  const [nombre, setNombre] = React.useState("");
  const [calle, setCalle] = React.useState("");
  const [numero, setNumero] = React.useState("");
  const [entreCalles, setEntreCalles] = React.useState("");
  const [colonia, setColonia] = React.useState("");
  const [codigoPostal, setCodigoPostal] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [idDistritoLocal, setIdDistritoLocal] = React.useState("");
  const [idDistritoFederal, setIdDistritoFederal] = React.useState("");
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [idEstado, setIdEstado] = React.useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  //const [activo, setActivo] = React.useState("");

  const distritosParamsMemo = React.useMemo(() => ({ tipo: 1 }), []);

  const [modelDistrito, modelDistritoLoading] = useModels({
    name: "distrito",
    ordenar: "idDistrito.asc",
    limite: -1,
    extraParams: distritosParamsMemo,
  });

  const [
    modelDistritoLocal,
    modelDistritoLocalLoading,
    modelDistritoLocalError,
    modelDistritoLocalPage,
    modelDistritoLocalRefresh,
  ] = useModels({
    name: "distrito",
    expand: "distritosLocales",
    ordenar: "idDistrito.asc",
    limite: -1,
    extraParams: distritosParamsMemo,
  });

  const distritosFederalesCambio = (event) => {
    const { value } = event.target;
    let params = {};
    setIdDistritoFederal(value);
    params = { ...params, idDistrito: value };
    modelDistritoLocalRefresh(true, params);
  };

  const distritosLocalesCambio = (event) => {
    const { value } = event.target;
    setIdDistritoLocal(value);
  };

  const municipioCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
      setIdEstado(v.idEstado);
    } else {
      setIdMunicipio("");
      setIdEstado("");
    }
  }, []);

  const consejoCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdConsejoElectoral(v.idConsejoElectoral);
    } else {
      setIdConsejoElectoral("");
    }
  }, []);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      let params = {};
      params = { ...params, idDistrito: model.idDistritoFederal };
      modelDistritoLocalRefresh(true, params);
      setNombre(model.nombre);
      setCalle(model.calle);
      setNumero(model.numero);
      setEntreCalles(model.entreCalles);
      setColonia(model.colonia);
      setCodigoPostal(model.codigoPostal);
      setTelefono(model.telefono);
      setIdDistritoLocal(model.idDistritoLocal);
      setIdDistritoFederal(model.idDistritoFederal);
      setIdMunicipio(model.idMunicipio);
      setIdEstado(model.municipio.idEstado);
      setIdConsejoElectoral(model.idConsejoElectoral);
      setEditingTitle(`Editando: ${model.nombre}`);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSaveClicked = () => {
    let body = {
      nombre,
      calle,
      numero,
      entreCalles,
      colonia,
      codigoPostal,
      telefono,
      idDistritoLocal,
      idDistritoFederal,
      idMunicipio,
      idEstado,
      idConsejoElectoral,
    };
    if (editing) {
      body.idBodega = idBodega;
    }
    body = { ...body };
    updateModel(body);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE BODEGAS"
        model="bodega"
        editing={editing}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.sectionTitle}>
            INFORMACIÓN GENERAL DE BODEGA
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nombre"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            onChange={(e) => setNombre(e.target.value)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Calle"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.calle}
            error={Boolean(modelError.calle)}
            required={!editing}
            onChange={(e) => setCalle(e.target.value)}
            inputProps={{
              value: calle,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Número de domicilio"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.numero}
            error={Boolean(modelError.numero)}
            required={!editing}
            onChange={(e) => setNumero(e.target.value)}
            inputProps={{
              value: numero,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Referencia domiciliar"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.entreCalles}
            error={Boolean(modelError.entreCalles)}
            required={!editing}
            onChange={(e) => setEntreCalles(e.target.value)}
            inputProps={{
              value: entreCalles,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Colonia"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.colonia}
            error={Boolean(modelError.colonia)}
            required={!editing}
            onChange={(e) => setColonia(e.target.value)}
            inputProps={{
              value: colonia,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Código postal"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.codigoPostal}
            error={Boolean(modelError.codigoPostal)}
            required={!editing}
            onChange={(e) => setCodigoPostal(e.target.value)}
            inputProps={{
              value: codigoPostal,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Teléfono de contacto"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.telefono}
            error={Boolean(modelError.telefono)}
            required={!editing}
            onChange={(e) => setTelefono(e.target.value)}
            inputProps={{
              value: telefono,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography color="secondary" className={classes.sectionTitle}>
            INFORMACIÓN DISTRITAL
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Distritos Federal
            </InputLabel>
            <Select
              value={idDistritoFederal}
              style={{ textAlign: "left" }}
              onChange={distritosFederalesCambio}
              label="Distritos Federal"
              error={Boolean(modelError.idDistritoFederal)}
            >
              {modelDistrito !== null
                ? modelDistrito.map((opt, index) => (
                    <MenuItem key={index} value={opt.idDistrito}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idDistritoFederal ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idDistritoFederal}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Distritos Local
            </InputLabel>
            <Select
              disabled={!idDistritoFederal}
              value={idDistritoLocal}
              style={{ textAlign: "left" }}
              onChange={distritosLocalesCambio}
              label="Distritos Local"
              error={Boolean(modelError.idDistritoLocal)}
            >
              {modelDistritoLocal &&
              modelDistritoLocal[0] &&
              modelDistritoLocal[0].distritosLocales
                ? modelDistritoLocal[0].distritosLocales.map((opt, index) => (
                    <MenuItem key={index} value={opt.idDistrito}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idDistritoLocal ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idDistritoLocal}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AsyncAutocompleteSearch
            label="Municipio"
            name="municipio"
            defaultValue={model && model.municipio}
            labelProp="nombre"
            error={modelError.idMunicipio ? "idMunicipio" : ""}
            campoError="idMunicipio"
            onChange={municipioCambio}
          />
          {modelError.idMunicipio ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idMunicipio}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <AsyncAutocompleteSearch
            label="Consejo Electoral"
            name="consejo-electoral"
            defaultValue={model && model.consejoElectoral}
            labelProp="nombre"
            error={modelError.idConsejoElectoral ? "idConsejoElectoral" : ""}
            campoError="idConsejoElectoral"
            onChange={consejoCambio}
          />
          {modelError.idConsejoElectoral ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idConsejoElectoral}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onSaveClicked}
                fullWidth
                disabled={updateModelLoading}
                endIcon={<SaveIcon />}
              >
                {editing ? "GUARDAR DATOS" : "CREAR BODEGA"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BodegasDetail;
