import React from "react";
import { Ballot } from "@material-ui/icons";
import { Button, Grid, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { generateDefaultChartOptions, getPercentage } from "../../utils";
import { useModels } from "../../hooks/useModels";
import PageLayout from "../../components/PageLayout";
import Services from '../../services';
import GetAppIcon from "@material-ui/icons/GetApp";

const actasRequestDefault = {
  name: "consejo-electoral/estadistico-recepcion",
  extraParams: {},
};

const EstadisticoAvanceRecepcion = ({ history }) => {
  const header = [];
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [actasRequest, setActasRequest] = React.useState(actasRequestDefault);
  const [actas, actasLoading] = useModels(actasRequest);
  let chartOptions = generateDefaultChartOptions("column", {
    title: "CON RECEPCIÓN",
    subtitle: " ",
    inverted: true,
  });

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    async  (e, v) => {
      if (!v) return;
      const { idConsejoElectoral } = v;
      if (idConsejoElectoral && idConsejoElectoral !== "") {
        const req = { ...actasRequestDefault };
        req.extraParams = { ...req.extraParams, idConsejoElectoral };
        setActasRequest(req);
        setIdConsejoElectoral(idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setActasRequest]
  );

  const onSearchClicked = async () => {
    if (idConsejoElectoral && idConsejoElectoral !== "") {
      const req = { ...actasRequestDefault };
      req.extraParams = { ...req.extraParams, idConsejoElectoral };
      setActasRequest(req);
    }
  };

  const sinRecepcion =
    actas && actas[0]
      ? parseInt(actas[0]?.actasTotal.toString().replace(",", "")) -
        parseInt(actas[0]?.actasConRecepcion.toString().replace(",", ""))
      : 0;

  const conRecepcion =
    actas && actas[0]
      ? parseInt(actas[0]?.actasConRecepcion.toString().replace(",", ""))
      : 0;

  const extraOptions = {
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0,
        pointWidth: 40,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      headerFormat:
        '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} Paquetes</b><br/>',
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -10,
        allowOverlap: true,
        enabled: false,
        style: {
          wordBreak: "break-all",
          textOverflow: "allow",
        },
      },
      categories: function () {
        var value = this.value;
        return value;
      },
    },
    yAxis: [
      {
        title: {
          text: " ",
        },
        showFirstLabel: false,
      },
    ],
    series: [
      {
        color: "rgb(158, 159, 163)",
        pointPlacement: -0.2,
        linkedTo: "main",
        cursor: "pointer",
        point: {
          events: {
            /*
                click: function () {
                  console.log(this.options);
                  history.push(
                    `/estadisticos/informe-recuento/detalle?id=${this.options.id}`
                  );
                  //call function passing this values as arguments
                }, // */
          },
        },
        data: ["Esperados", parseInt(actas[0]?.actasTotal.toString().replace(",", ""))],
        name: "Paquetes Esperados",
      },
      {
        name: "Paquetes con Recepción",
        id: "main",
        dataSorting: {
          enabled: true,
          matchByName: true,
        },
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: "16px",
            },
          },
        ],
        color: "#a13800",
        cursor: "pointer",
        point: {
          events: {
            /*
                click: function () {
                  history.push(
                    `/estadisticos/informe-recuento/detalle?id=${this.options.id}`
                  );
                  //call function passing this values as arguments
                }, // */
          },
        },
        data: ["Con recepción", conRecepcion],
      },
    ],
    exporting: {
      allowHTML: true,
    },
  };
  chartOptions = { ...chartOptions, ...extraOptions };

  const downloadExcel = async () => {
    try {
      await Services.downloadBlob("excel/avance-recepcion-paquetes", {idConsejoElectoral : idConsejoElectoral}, "AvanceRecepcionPaquetes.xlsx")
    } catch (error) {
      console.log(error);      
    }
  }

  return (
    <PageLayout
      title="ESTADÍSTICOS: AVANCE DE RECEPCIÓN DE PAQUETES ELECTORALES"
      model="consejos-electorales"
      history={history}
      links={[
        {
          to: "/lectura-votos",
          icon: <Ballot />,
          label: "Estadísticos: Avance de recepciones",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={8}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={!idConsejoElectoral}
              fullWidth
              style={{ backgroundColor: "#4caf50", color: "white", float: "right" }}
              variant="contained"
              onClick={downloadExcel}
              startIcon={<GetAppIcon />}
            >
              Excel
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={actasLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
    >
      <Grid container>
        {actas && actas[0] && (
          <>
            <Grid xs={12}>
              <Typography style={{ fontSize: 24 }}>
                {`PAQUETES ESPERADOS: ${parseInt(actas[0]?.actasTotal.toString().replace(",", ""))}`}
              </Typography>
              <Typography style={{ fontSize: 24 }}>
                {`PAQUETES RECIBIDOS: ${conRecepcion}`}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </Grid>
          </>
        )}
      </Grid>
    </PageLayout>
  );
};

export default EstadisticoAvanceRecepcion;
