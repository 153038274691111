import React from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import HomeCard from "../../components/HomeCard";
import { useNotifications } from "../../hooks/useNotifications";
import { useAuth } from "../../hooks/useAuth";
import { photoBaseUrl } from "../../utils/variables";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    justifyContent: "flex-start",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const snapshotOptions = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

const nombreColeccion =
  window.location.host === "2021.computosonora.com"
    ? "menu-lateral"
    : "menu-lateral-dev";

const HomeView = () => {
  const history = useHistory();
  const classes = useStyles();
  const { setNotifications } = useNotifications();
  const { session, sessionLoading, user, userLoading } = useAuth();
  const refMemo = React.useMemo(
    () =>
      user && user.consejoElectoral && user.consejoElectoral.idConsejoElectoral
        ? firebase
            .firestore()
            .doc(
              `${nombreColeccion}/${user.consejoElectoral.idConsejoElectoral}`
            )
        : undefined,
    [user]
  );
  const [doc, docLoading] = useDocument(refMemo, snapshotOptions);
  if (!session || sessionLoading) return null;
  if (!user || userLoading) return null;

  let configFirebase = {};
  if (doc && !docLoading) {
    configFirebase = doc.data() || {};
  }

  return (
    <>
      <div className={classes.root}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ display: "inline-block" }}
        >
          <StyledBreadcrumb
            component="a"
            label="Inicio"
            icon={<HomeIcon fontSize="small" />}
            onClick={() => history.push("/")}
          />
        </Breadcrumbs>
        <Grid
          component={Paper}
          container
          spacing={3}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          {(configFirebase["paquetes"] === true ||
            configFirebase["paquetes"] === undefined) &&
            (user && user.permiso["paquetesRecepciones"] & 1) === 1 && (
              <Grid item xs={8} lg={4}>
                <HomeCard
                  onClicked={() => setNotifications((val) => [...val, 1])}
                  title="Recepción de Paquetes Electorales"
                  description="Registrar los recibos de entrega de los paquetes electorales, así como el estado en el que fueron recibidos"
                  image={`${photoBaseUrl}/images/home/paquetes-electorales.png`}
                  link="recepciones-municipales"
                />
              </Grid>
            )}
          {(configFirebase["actas"] === true ||
            configFirebase["actas"] === undefined) &&
            user &&
            ((user.permiso["actaLectura"] & 1) === 1 ||
              (user.permiso["lecturaEstatal"] & 1) === 1) && (
              <Grid item xs={8} lg={4}>
                <HomeCard
                  title="Registro de Actas/Lectura de Votos"
                  description="Capturar la información de las Actas de Escrutinio y Cómputo que pertenecen a cada tipo de elección para su lectura"
                  image={`${photoBaseUrl}/images/home/registro-de-actas.png`}
                  link={
                    (user.permiso["lecturaEstatal"] & 1) === 1
                      ? "lectura-estatal"
                      : "lectura-de-votos"
                  }
                />
              </Grid>
            )}
          {(configFirebase["bodegas"] === true ||
            configFirebase["bodegas"] === undefined) &&
            (user && user.permiso["bodegaPaquetes"] & 1) === 1 && (
              <Grid item xs={8} lg={4}>
                <HomeCard
                  title="Bodega Electoral"
                  description="Registrar la bitácora de apertura y cierre de bodega, así como el estatus de los paquetes electorales"
                  image={`${photoBaseUrl}/images/home/bodega-electoral.png`}
                  link="bodegas/administracion"
                />
              </Grid>
            )}
          {(configFirebase["reunionTrabajo"] === true ||
            configFirebase["reunionTrabajo"] === undefined) &&
            (user && user.permiso["reunionTrabajoReportes"] & 1) === 1 && (
              <Grid item xs={8} lg={4}>
                <HomeCard
                  title="Reunión de Trabajo"
                  description="Realizar ratificación y ratificación de actas"
                  image={`${photoBaseUrl}/images/home/reunion-de-trabajo.png`}
                  link="reunion-de-trabajo/informe"
                  ignoreImageLink
                />
              </Grid>
            )}
          {(configFirebase["sesionExtraordinaria"] === true ||
            configFirebase["sesionExtraordinaria"] === undefined) &&
            (user && user.permiso["sesionExtraordinaria"] & 1) === 1 && (
              <Grid item xs={8} lg={4}>
                <HomeCard
                  title="Sesión Extraordinaria"
                  description="Presentar el análisis preliminar sobre la clasificación de los paquetes electorales y de las actas de escrutinio y cómputo."
                  image={`${photoBaseUrl}/images/home/sesion-extraordinaria.png`}
                  link="sesion-extraordinaria"
                />
              </Grid>
            )}
          {(configFirebase["computo"] === true ||
            configFirebase["computo"] === undefined) &&
            (user && user.permiso["computoCotejo"] & 1) === 1 && (
              <Grid item xs={8} lg={4}>
                <HomeCard
                  title="Sesión de Cómputo"
                  description="Realizar cotejo, recuento de actas de escrutinio y cómputo para publicación de resultados de cada tipo de elección"
                  image={`${photoBaseUrl}/images/home/sesion-de-computo.png`}
                  link="cotejo"
                  ignoreImageLink
                />
              </Grid>
            )}
          {/* 
          <Grid item xs={8} lg={4}>
            <HomeCard
              title="Reportes"
              description="Descarga de diversos reportes para RED INE y Transparencia"
              image="https://api.computosonora.com/images/home/reportes.png"
              link=""
            />
          </Grid>

          <Grid item xs={8} lg={4}>
            <HomeCard
              title="Estadísticos"
              description="Dar seguimiento a las estadísticas"
              image="https://api.computosonora.com/images/home/reportes.png"
              link=""
            />
          </Grid>

          <Grid item xs={8} lg={4}>
            <HomeCard
              title="Monitoreo"
              description="Dar seguimiento a los procesos de cómputo"
              image="https://api.computosonora.com/images/home/monitoreo.png"
              link=""
            />
          </Grid> */}
        </Grid>
      </div>
    </>
  );
};

export default HomeView;
