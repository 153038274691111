import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import SearchIcon from "@material-ui/icons/Search";
import LandscapeIcon from "@material-ui/icons/Landscape";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Services from "../../services";
import { useAlert } from "../../hooks/useAlert";

const ModelosActa = ({
  dialog,
  setEditingEstado,
  setSelectedEstado,
  handleClose,
}) => {
  const history = useHistory();
  const { showAlert } = useAlert();
  const [idConsejoElectoral, setIdConsejoElectoral] = useState("");
  const [idTipoActa, setIdTipoActa] = useState("");

  const consejosParamsMemo = React.useMemo(
    () => ({ ordenar: "nombre.asc" }),
    []
  );

  const consejoCambio = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdConsejoElectoral(v.idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setIdConsejoElectoral]
  );

  const tipoActaCambio = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdTipoActa(v.idTipoActa);
      } else {
        setIdTipoActa("");
      }
    },
    [setIdTipoActa]
  );

  const editarActa = () => {
    if (idConsejoElectoral || idTipoActa) {
      const obj = {
        idConsejoElectoral: idConsejoElectoral,
        idTipoActa: idTipoActa,
      };
      Services.post("acta/guardar-modelo-acta", obj);

      showAlert({
        severity: "success",
        message: "Modelo de Acta actualizado.",
      });
    }
  };

  return (
    <TablePageLayout
      title="MODELOS ACTA"
      links={[
        {
          to: "/modelos-acta",
          icon: <LandscapeIcon />,
          label: "Modelos Acta",
        },
      ]}
      history={history}
      SearchComponents={
        <>
          <Grid item xs={4}>
            <AsyncAutocompleteSearch
              label="Consejo"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={consejoCambio}
              extra={consejosParamsMemo}
            />
          </Grid>
          <Grid item xs={4}>
            <AsyncAutocompleteSearch
              label="TipoActa"
              name="tipo-acta"
              labelProp="nombre"
              onChange={tipoActaCambio}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={editarActa}
              endIcon={<SearchIcon />}
            >
              Modificar
            </Button>
          </Grid>
        </>
      }
    />
  );
};

export default ModelosActa;
