import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: 40,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  cover: {
    height: 40,
    width: 40,
  },
  content: {
    flex: "1 0 auto",
  },
}));

export default function MiniCardEntry({ logo, value, onChange, disabled }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={logo}
        title="Live from space album cover"
      />
      <div className={classes.details}>
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Número"
            disabled={disabled}
            value={value}
            onChange={onChange}
          />
        </form>
      </div>
    </div>
  );
}
