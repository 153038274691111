import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogText: {
    fontSize: 18,
    textAlign: "flex-start",
  },
}));

const CustomDialog = ({
  isOpen,
  handleClose,
  handleSave,
  title,
  subtitle,
  children,
  actions,
  defaultActions,
  saveDisabled,
  saveLoading,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth
        disableBackdropClick={true}
        PaperProps={{
          style: { boxShadow: "none" },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "#000",
            opacity: 0.25,
          },
        }}
        open={isOpen}
        onClose={handleClose}
        {...rest}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogText}>
            {subtitle}
          </DialogContentText>
          {children}
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
        {defaultActions && (
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Cerrar
            </Button>
            <Button
              onClick={handleSave}
              disabled={saveDisabled || saveLoading}
              color="primary"
            >
              {saveLoading ? "Cargando" : "Guardar"}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

CustomDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default CustomDialog;
