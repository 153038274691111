import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";

import { validateEmail } from "../../utils";
import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";

import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { Sync } from "@material-ui/icons";
import SingleSelect from "../../components/SingleSelect";

const Usuarios = ({
  dialog,
  setEditingUser,
  setSearch,
  setSelectedUser,
  handleClose,
}) => {
  const history = useHistory();
  const header = [
    {
      prop: "correo",
      name: "Correo electrónico",
    },
    {
      prop: "creado",
      name: "Fecha creación",
    },
    {
      prop: "ultimaSesion",
      name: "Última sesión",
    },
  ];

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ name: "usuario", expand: "permisoRol,agenda" });

  const [sinAgenda, setSinAgenda] = React.useState("");
  const [correo, setCorreo] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();

  const onSearchClicked = async () => {
    let params = {};
    if (correo.length > 0) params = { ...params, correo };
    if (sinAgenda && sinAgenda.valor.toString().length > 0)
      params = { ...params, sinAgenda: sinAgenda.valor };
    await refreshModels(true, params);
  };

  const onDeleteClicked = (row) => {
    try {
      showDialog({
        onCancel: closeDialog,
        onOk: () => {
          setOpenMenuRow(null);
          deleteModel(row.idUsuario);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setAgendaLigadaCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      if (value !== "" && value) {
        setSinAgenda(value);
      }
    },
    [setSinAgenda]
  );

  return (
    <TablePageLayout
      title="CONTROL DE USUARIOS"
      model="usuario"
      history={history}
      actionButton={{
        to: "usuarios/nuevo",
        icon: <AddIcon />,
        label: "Nuevo Usuario",
      }}
      links={[
        {
          to: "/usuarios",
          label: "Usuarios",
        },
      ]}
      dialog={dialog}
      SearchComponents={
        <>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Buscar por correo electrónico"
              onChange={(event) => setCorreo(event.target.value)}
              onBlur={(_) => {
                if (validateEmail(correo)) {
                  refreshModels(null, { correo });
                }
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <SingleSelect
              label="Por agenda vinculada"
              value={sinAgenda}
              onChange={setAgendaLigadaCallback}
              options={[
                { nombre: "Todos", valor: "" },
                { nombre: "Con agenda", valor: 0 },
                { nombre: "Sin agenda", valor: 1 },
              ]}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              fullWidth
              size="small"
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          dialog={dialog}
          data={models}
          paginatedApi
          count={modelsPage !== null ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "agenda",
              name: "Nombre de agenda",
              cell: (row, index) =>
                row.agenda ? (
                  <Link to={`agendas/detalle?id=${row.idAgenda}`}>
                    {row.agenda.nombreCompleto}
                  </Link>
                ) : (
                  <i>Sin agenda</i>
                ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "Admin"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    {dialog && (
                      <MenuItem
                        onClick={() => {
                          setSelectedUser(row);
                          handleClose();
                        }}
                      >
                        <Sync style={{ paddingRight: 5 }} />
                        Ligar
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        if (dialog) {
                          setEditingUser(row.idUsuario);
                          setSearch(false);
                        } else {
                          history.push(`/usuarios/${row.idUsuario}`);
                        }
                      }}
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Usuarios;
