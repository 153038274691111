import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete, FindInPage, Save } from "@material-ui/icons";
import DatePicker from "../../components/DatePicker";
// import { DatePicker } from "@material-ui/pickers";
import React from "react";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loader from "../../components/Loader";
import PageLayout from "../../components/PageLayout";
import { useAuth } from "../../hooks/useAuth";
import { useModels } from "../../hooks/useModels";
import { useQuery } from "../../hooks/useQuery";
import services from "../../services";
import DialogReprecentante from "../gruposTrabajo/grupos/dialogReprecentante";
import { useAlert } from "../../hooks/useAlert";
import AlertDialog from "../../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  card: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    width: "100%",
    cursor: "pointer",
  },
  cardDisabled: {
    backgroundColor: theme.palette.grey[500],
    color: "white",
    width: "100%",
    cursor: "pointer",
  },
}));

const breadcrumbs = [
  {
    to: `/acreditacion-representantes`,
    icon: <FindInPage fontSize="small" />,
    label: "Acreditación representantes",
  },
];

const requestGT = {
  name: "grupo-trabajo",
};

const idsPuestoReprensete = [11, 12];

const AcreditacionRepresentantes = ({ history }) => {
  const classes = useStyles();
  const query = useQuery();
  const { showAlert } = useAlert();
  const [integrantes, setIntegrantes] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const [definidos, setDefinidos] = React.useState([]);
  const [representantes, setRepresentantes] = React.useState([]);
  const [puestos, setPuestos] = React.useState([]);
  const [modelsGT, modelsGTLoading] = useModels(requestGT);
  const [open, setOpen] = React.useState(false);
  const [participantesPolitico, setParticipantesPolitico] = React.useState([]);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [indexRepresentante, setIndexRepresentante] = React.useState(0);
  const [indexSacar, setIndexSacar] = React.useState(null);
  const [sacarId, setSacarId] = React.useState(null);
  const [sacar, setSacar] = React.useState([]);
  const [totalGT, setTotalGT] = React.useState([]);
  const [idGrupoTrabajo, setIdGrupoTrabajo] = React.useState("");
  const [loading2, setLoading2] = React.useState(false);

  const { user, userLoading } = useAuth();
  const grupoTrabajoRepresentante = React.useMemo(
    () =>
      idGrupoTrabajo !== ""
        ? {
            name: "grupo-trabajo-representante",
            extraParams: {
              ordenar: "idGrupoTrabajoRepresentante.asc",
              idGrupoTrabajo: idGrupoTrabajo,
            },
            expand: "agenda",
          }
        : { name: null },
    [idGrupoTrabajo]
  );

  const [modelsR, modelsLoadingR, refreshModelsR] = useModels(
    grupoTrabajoRepresentante
  );

  const grupoTrabajo = React.useMemo(
    () => ({
      name: "grupo-trabajo-integrante",
      extraParams: {
        ordenar: "idGrupoTrabajoPuesto.asc,numeroPR.asc",
        idGrupoTrabajo: idGrupoTrabajo,
        ignorarGenerales: true,
      },
      expand: "agenda",
    }),
    []
  );
  const [models, modelsLoading, refreshModels] = useModels(grupoTrabajo);

  const agendaRepresentanteMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda: 12,
    }),
    []
  );

  const total_GT = React.useCallback(async () => {
    if (idGrupoTrabajo === "") return;
    const responseGT = await services.post(
      "grupo-trabajo/total-actas-por-grupo",
      {
        idGrupoTrabajo: idGrupoTrabajo,
      }
    );
    setTotalGT(responseGT.resultado[0].total);
  }, [idGrupoTrabajo]);

  React.useEffect(() => {
    let mounted = true;

    const getData = async () => {
      try {
        const response = await services.get("grupo-trabajo-puesto");
        const _participantesPolitico = await services.get(
          "participante-politico"
        );

        if (mounted) {
          setParticipantesPolitico(_participantesPolitico.resultado);
          setPuestos(response.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        // if (mounted) setLoading(false);
      }
    };

    getData();

    return () => (mounted = false);
  }, []);

  React.useEffect(() => {
    if (!user) return;
    services
      .post("grupo-trabajo/definidos-por-grupo", {
        idConsejoElectoral: user?.consejoElectoral.idConsejoElectoral,
      })
      .then((resp) => {
        setDefinidos(resp.resultado);
      });
  }, [user]);

  React.useEffect(() => {
    total_GT();
  }, [total_GT]);

  React.useEffect(() => {
    let mounted = true;
    let _integrantes = [];
    if (mounted && models) {
      setIntegrantes(
        models.map((g) => ({
          ..._integrantes,
          ...g,
        }))
      );
    }

    let _representantes = [];
    if (mounted && modelsR) {
      setRepresentantes(
        modelsR.map((g) => ({
          ..._representantes,
          ...g,
        }))
      );
    }

    return () => {
      mounted = false;
    };
  }, [models, modelsR]);

  const guardar = async () => {
    try {
      if (representantes.some((r) => !r.puesto)) {
        showAlert({
          severity: "warning",
          message: "Favor de verificar los puestos de los Representantes.",
        });

        return;
      }
      setLoading2(true);
      setSaving(true);

      // await services.post("grupo-trabajo-integrante/guardar3", {
      //   integrantes: integrantes,
      // });

      // await services.post("grupo-trabajo-integrante/guardar3", {
      //   puntosRecuento: puntosRecuento,
      //   idGrupoTrabajo: idGrupoTrabajo,
      // });

      await services.post("grupo-trabajo-representante/guardar3", {
        representantes: representantes,
        sacar: sacar,
      });

      showAlert({
        severity: "success",
        message: "Conformación guardada con exito.",
      });

      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
      setLoading2(false);
    }
  };

  if (modelsGTLoading || userLoading || modelsLoading) return <Loader />;

  return (
    <PageLayout
      title={"ACREDITACIÓN DE REPRESENTANTES"}
      links={breadcrumbs}
      history={history}
      SearchComponents={<></>}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        // style={{ marginTop: 10 }}
        alignItems="flex-end"
      >
        {modelsGT && modelsGT.length > 0 && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  color="secondary"
                  className={classes.sectionTitle}
                >
                  <div style={{ fontSize: 20 }}>
                    <strong>GRUPOS DE TRABAJO</strong>
                  </div>
                </Typography>
              </Grid>
              {modelsGT.map((g, i) => (
                <Grid item xs={12} lg={4} style={{ padding: "10px" }}>
                  <Grid container style={{ textDecoration: "none" }}>
                    <Card
                      className={
                        idGrupoTrabajo === g.idGrupoTrabajo
                          ? classes.card
                          : classes.cardDisabled
                      }
                      onClick={() => setIdGrupoTrabajo(g.idGrupoTrabajo)}
                    >
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Grupo de Trabajo {g.numeroGrupoTrabajo}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Grid container justify="flex-end">
                          <Button
                            size="small"
                            color="primary"
                            style={{ color: "white" }}
                            disabled
                          >
                            {definidos?.map((d, ind) =>
                              d.idGrupo === g.idGrupoTrabajo ? (
                                <div>
                                  Por Definir: {d.falta} - Definidos: {d.total}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Button>
                        </Grid>
                      </CardActions>
                    </Card>
                    <div>
                      <br></br>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {modelsGT && modelsGT.length === 0 && (
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                No hay grupos de trabajo disponibles en este momento, favor de
                comunicarse con su CAE.
              </Typography>
            </Grid>
          </Grid>
        )}
        <div style={{ width: "100%", marginTop: 20 }}>
          <Box display="flex" p={1}>
            <Box flexGrow={1}>
              <Typography color="secondary" className={classes.sectionTitle}>
                <strong style={{ fontSize: 20 }}>
                  REPRESENTANTES DEL PARTIDO
                </strong>
              </Typography>
            </Box>
            <Box mr={1}>
              <Button
                variant="contained"
                size="middle"
                color="secondary"
                onClick={() => {
                  setRepresentantes((v) => [
                    ...v,
                    {
                      idGrupoTrabajoRepresentante: null,
                      idGrupoTrabajo: idGrupoTrabajo,
                      fechaAcreditacion: "",
                      puesto: "",
                      nombre: "",
                      apellidoPaterno: "",
                      apellidoMaterno: "",
                      idAgenda: null,
                    },
                  ]);
                }}
                disabled={idGrupoTrabajo === ""}
              >
                AGREGAR REPRESENTANTE
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                size="middle"
                color="secondary"
                onClick={() => {
                  setOpen(true);
                }}
                disabled={idGrupoTrabajo === ""}
              >
                CREAR NUEVO REPRESENTANTE
              </Button>
            </Box>
          </Box>
        </div>
        <Grid item xs={12}>
          {idGrupoTrabajo !== "" && representantes?.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Puesto en Grupo</TableCell>
                  <TableCell>Persona</TableCell>
                  <TableCell>Participante Político</TableCell>
                  <TableCell>Fecha acreditación</TableCell>
                  <TableCell>Salida</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {representantes.map((row, indexR) => (
                  <TableRow key={indexR}>
                    <TableCell component="th" style={{ width: "10%" }}>
                      {!row.idGrupoTrabajoRepresentante ? (
                        <FormControl fullWidth variant="outlined" size="small">
                          <Select
                            value={row.puesto}
                            onChange={(event) => {
                              setRepresentantes(
                                representantes.map((g, ig) =>
                                  ig === indexR
                                    ? {
                                        ...g,
                                        puesto: puestos.find(
                                          (p) => p.puesto === event.target.value
                                        ).puesto,
                                        idGrupoTrabajo: idGrupoTrabajo,
                                      }
                                    : g
                                )
                              );
                            }}
                          >
                            {puestos
                              .filter((p) =>
                                idsPuestoReprensete.includes(
                                  p.idGrupoTrabajoPuesto
                                )
                              )
                              .map((p) => (
                                <MenuItem
                                  key={p.idGrupoTrabajoPuesto}
                                  value={p.puesto}
                                >
                                  {p.puesto}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>{row.puesto}</Typography>
                      )}
                    </TableCell>
                    <TableCell component="th" style={{ width: "30%" }}>
                      {!row?.idGrupoTrabajoRepresentante ? (
                        <AsyncAutocompleteSearch
                          disabled={!!row.idGrupoTrabajoRepresentante}
                          label="Persona"
                          name="agenda"
                          labelProp="nombreCompletoSinPuesto"
                          onChange={(index, e, v) => {
                            if (v) {
                              setRepresentantes((g) =>
                                representantes.map((rep, i) =>
                                  i === indexR
                                    ? {
                                        ...rep,
                                        idAgenda: v.idAgenda,
                                        nombre: v.nombre,
                                        apellidoPaterno: v.apellidoPaterno,
                                        apellidoMaterno: v.apellidoMaterno,
                                        idParticipantePolitico:
                                          v.idParticipantePolitico,
                                        participantePolitico:
                                          v.participantePolitico,
                                      }
                                    : rep
                                )
                              );
                            }
                          }}
                          extraParams={agendaRepresentanteMemo}
                          index={indexR}
                          ignoreIds={representantes
                            .filter((a) => a.idAgenda)
                            .map((a) => a.idAgenda)}
                          propId="idAgenda"
                          defaultValue={row?.agenda}
                        />
                      ) : (
                        <Typography>
                          {row && row?.agenda
                            ? row?.agenda.nombreCompletoSinPuesto
                            : row.nombreCompleto}
                        </Typography>
                      )}
                      {/* salida */}
                    </TableCell>
                    <TableCell component="th" style={{ width: "20%" }}>
                      <Typography>
                        {row?.agenda?.participantePolitico ||
                          row?.participantePolitico}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" style={{ width: "20%" }}>
                      {!row.idGrupoTrabajoRepresentante ? (
                        <FormControl fullWidth>
                          <DatePicker
                            disabled={!!row.idGrupoTrabajoRepresentante}
                            disableFuture={false}
                            label="Fecha acreditación"
                            value={row.fechaAcreditacion || new Date()}
                            onChange={(value) => {
                              setRepresentantes(
                                representantes.map((g, i) =>
                                  i === indexR
                                    ? {
                                        ...g,
                                        fechaAcreditacion: value,
                                      }
                                    : g
                                )
                              );
                            }}
                          />
                        </FormControl>
                      ) : (
                        <Typography>{row?.fechaAcreditacion}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="large"
                        color="secondary"
                        onClick={() => {
                          setIndexRepresentante(indexR);
                          if (row.idGrupoTrabajoRepresentante > 0) {
                            setOpenAlertDialog(true);
                            setSacarId(row.idGrupoTrabajoRepresentante);
                            setIndexSacar(indexR);
                          } else {
                            setRepresentantes((v) =>
                              v.filter((o, index) => index !== indexR)
                            );
                          }
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography
              align="center"
              style={{
                color: "black",
                fontSize: 16,
                height: 50,
                verticalAlign: "center",
                marginTop: 35,
              }}
            >
              Grupo de trabajo seleccionado sin representantes de partido.
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container justify="flex-end" style={{ marginTop: 25 }}>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            endIcon={<Save />}
            disabled={
              idGrupoTrabajo === "" ||
              !(idGrupoTrabajo !== "" && representantes?.length > 0)
            }
            endIcon={loading2 ? <CircularProgress size={25} /> : <Save />}
            onClick={async () => {
              await guardar();
            }}
          >
            {idGrupoTrabajo ? "GUARDAR" : "CREAR"} CONFORMACIÓN
          </Button>
        </Grid>
      </Grid>
      <DialogReprecentante
        open={open}
        onClose={() => setOpen(false)}
        onAccept={(representante) => {
          setRepresentantes([
            ...representantes,
            {
              idAgenda: null,
              idParticipantePolitico: representante.idParticipantePolitico,
              participantePolitico: representante.participantePolitico,
              puesto: "",
              idGrupoTrabajoPuesto: "",
              idGrupoTrabajoRepresentante: null,
              fechaAcreditacion: representante.fechaAcreditacion,
              idGrupoTrabajo: "",
              nombre: representante.nombre,
              apellidoPaterno: representante.apellidoPaterno,
              apellidoMaterno: representante.apellidoMaterno,
              claveElector: representante.claveElector,
              agenda: {
                nombreCompletoSinPuesto: `${representante.nombre} ${representante.apellidoPaterno} ${representante.apellidoMaterno}`,
              },
            },
          ]);
          setOpen(false);
        }}
        participantesPolitico={participantesPolitico.filter(
          (a) => a.idCombinacionCoalicion === null
        )}
      />
      <AlertDialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        content={""}
        title="¿Seguro que deseas sacar este Representante del grupo?"
        onAccept={() => {
          setRepresentantes((v) =>
            v.filter((o, index) => index !== indexSacar)
          );
          setSacar((g) => [
            ...sacar,
            {
              idGrupoTrabajoRepresentante: sacarId,
            },
          ]);
          setOpenAlertDialog(false);
        }}
      />
    </PageLayout>
  );
};

export default AcreditacionRepresentantes;
