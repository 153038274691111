import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function AlertDialog({
  open,
  onClose,
  onAccept,
  loading,
  title,
  content,
  titleColor = "#000",
  acceptText = "Aceptar",
  cancelText = "Cancelar",
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color={titleColor}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button variant="contained" onClick={onClose} color="default">
            {cancelText}
          </Button>
        )}
        {onAccept && (
          <Button
            disabled={loading}
            onClick={onAccept}
            color="secondary"
            autoFocus
            variant="contained"
          >
            {loading ? <CircularProgress size={25} /> : acceptText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
