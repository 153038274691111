import React from "react";
import {
  Typography,
  Paper,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import ReplayIcon from '@material-ui/icons/Replay';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useModel } from "../../hooks/useModel";
import Actas from "./actas";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
    paddingBottom: "1%",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  saveButtonContainer: { marginTop: theme.spacing(4) },
  topic: { fontSize: 28 },
  card: {
    height: 250,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  icon: { fontSize: 54, color: "#fff" },
  formControl: {
    minWidth: "100%",
  },
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  tableText: {
    fontSize: 22,
    color: "#000",
  },
  subtitle: { fontWeight: "bold" },
  tableButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
  },
  buttonIcon: { color: "#fff", fontWeight: "bold" },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 22,
  },
  body: {
    height: 80,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TablePackagesPagination() {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  return (
    <TablePagination
      component="div"
      count={100}
      page={page}
      onChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}

const Scripts = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "acta/reiniciar-actas",
    redirectOnPost: true,
  });
  const [packages] = React.useState([
    {
      number: 1,
      script: "REINICIAR ACTAS",
      accion: "reiniciar",
    },
  ]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ display: "inline-block" }}
        >
          <StyledBreadcrumb
            component="a"
            label="Inicio"
            icon={<HomeIcon fontSize="small" />}
            onClick={() => history.push("/")}
          />
          <StyledBreadcrumb
            component="a"
            label="Soporte TI"
            onClick={() => history.push("/scripts")}
          />
        </Breadcrumbs>

        <Grid
          component={Paper}
          container
          spacing={3}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12}>
            <Typography className={classes.title}>
              Soporte TI
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">#</StyledTableCell>
                    <StyledTableCell align="left">
                      Script
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Acción
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {packages.map((e, i) => (
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        <Typography className={classes.tableText}>
                          {e.number}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className={classes.tableText}>
                          {e.script}
                        </Typography>
                      </StyledTableCell>      
                      <StyledTableCell align="left">
                        <Button variant="contained" color="primary" onClick={handleClickOpen}>
                           <ReplayIcon className={classes.buttonIcon} />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableCell colSpan={6}>
                      <TablePackagesPagination />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            disableBackdropClick = "true"
            fullWidth
            maxWidth="xl"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            size
          >
            <DialogTitle id="alert-dialog-title">{"Actas"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Selecciona el acta que deseas reiniciar.
                </DialogContentText>
                <Actas></Actas>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    CERRAR
                </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </>
  );
};

export default Scripts;
