import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  TableContainer,
  Checkbox,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FindInPage } from "@material-ui/icons";

import { NON_DIGIT, tipoVotos } from "../../constants/mockData";
import Loader from "../../components/Loader";
import AsyncSelect from "../../components/AsyncSelect";
import TimePicker from "../../components/TimePicker";
import DatePicker from "../../components/DatePicker";
import PageLayout from "../../components/PageLayout";
import PdfDialog from "../../components/PdfDialog";
import { useQuery } from "../../hooks/useQuery";
import { useModel } from "../../hooks/useModel";
import AlertDialog from "../../components/AlertDialog";
import services from "../../services";
import { baseUrlPdf } from "../../utils/variables";
import MiniCardEntry from "../../components/MiniCardEntry";
import SingleSelect from "../../components/SingleSelect";
import { useModels } from "../../hooks/useModels";
import { auth } from "../../firebase";
import TIPO_ELECCION from "../../utils/tipoEleccion";
import { photoBaseUrl } from "../../utils/variables";

const diasFecha = [
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
  },
  text: {
    fontSize: 18,
    color: "#000",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2) * -1,
    color: "#000",
  },
  icon: { height: "80%", width: "80%", color: theme.palette.primary.main },
  subtitle: { paddingBottom: 5, color: "#000", fontSize: 14 },
  prSubtitle: {
    paddingBottom: 5,
    color: theme.palette.error.main,
    fontSize: 14,
  },
  successButton: {
    float: "right",
    top: 10,
    backgroundColor: "green",
    color: "white",
  },
  formControl: {
    minWidth: "100%",
  },
  fieldText: { fontSize: 42 },
  table: {
    minWidth: 700,
    tableLayout: "auto",
  },
  act: { fontSize: 30 },
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  panelEspecialText: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
  },
}));

const gExtra = { expand: "nombreCompleto" };

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#76777b",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const breadcrumbs = [
  {
    to: `/recuento-gt`,
    icon: <FindInPage fontSize="small" />,
    label: `Historial de recuento con GT`,
  },
  {
    to: `/recuento-gt/nuevo`,
    icon: <FindInPage fontSize="small" />,
    label: `Sesión de cómputo: recontar acta con GT`,
  },
];

const opcionesHora = [...Array(24).keys()].map((e) => (e < 10 ? "0" + e : e));
const opcionesMinuto = [...Array(60).keys()].map((e) => (e < 10 ? "0" + e : e));

// const filtroProtesta = (p) =>
//   p.idPartidoPolitico !== null || p.idCandidatoIndependiente !== null;

const RecuentoGtDetail = ({ match, history }) => {
  const id = auth.currentUser.uid;
  const classes = useStyles();
  const query = useQuery();
  const idActa = query.get("a");
  const [validando, setValidando] = React.useState(false);

  const [resolverEnPleno, setResolverEnPleno] = React.useState(false);
  const [encontroBoletas, setEncontroBoletas] = React.useState(false);

  const [grupoTrabajo, setGrupoTrabajo] = React.useState({});
  const [grupoTrabajoId, setGrupoTrabajoId] = React.useState("");
  const [puntosRecuento, setPuntosRecuento] = React.useState([]);
  const [puntoDeRecuento, setPuntoDeRecuento] = React.useState("");
  // const [numeroPR, setNumeroPR] = React.useState("");
  const [boletasSobrantes, setBoletasSobrantes] = React.useState("");
  const [votosReservados, setVotosReservados] = React.useState("");
  const [horaInicio, setHoraInicio] = React.useState("");
  const [minutoInicio, setMinutoInicio] = React.useState("");
  const [fechaInicio, setFechaInicio] = React.useState("");
  const [horaFinal, setHoraFinal] = React.useState("");
  const [minutoFinal, setMinutoFinal] = React.useState("");
  const [fechaFinal, setFechaFinal] = React.useState("");

  const [votosParticipantesPoliticos, setVotosParticipantesPoliticos] =
    React.useState([]);
  const [numProtesta, setNumProtesta] = React.useState([]);
  const [otrasBoletasG, setOtrasBoletasG] = React.useState("");
  const [otrasBoletasD, setOtrasBoletasD] = React.useState("");
  const [otrasBoletasM, setOtrasBoletasM] = React.useState("");
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [printOpen, setPrintOpen] = React.useState(false);
  const [postLoading, setPostLoading] = React.useState(false);
  const [participantesProtesta, setParticipantesProtesta] = React.useState([]);

  const { model: acta, modelLoading: actaLoading } = useModel({
    name: "acta",
    id: idActa,
    expand:
      "tipoEleccion,votosGenerales,motivoDeRecuento,participantesPoliticos,recuentoGT,puntosRecuento,participantesProtesta,esEspecial",
    redirectOnPost: true,
  });

  React.useEffect(() => {
    if (!actaLoading && acta) {
      if (acta.recuentoGT) {
        const {
          nombreGrupo,
          idGrupoTrabajo,
          // nombrePuntoRecuento,
          // numeroPR,
        } = acta.recuentoGT;
        setGrupoTrabajo({
          nombreCompleto: nombreGrupo,
          idGrupoTrabajo: idGrupoTrabajo,
        });
        // setGrupoTrabajoId(idGrupoTrabajo);
        // setPuntoDeRecuento(nombrePuntoRecuento);
        // setNumeroPR(numeroPR);
      }
      if (acta.participantesProtesta && acta.participantesProtesta.length > 0) {
        setParticipantesProtesta([...acta.participantesProtesta]);
        setNumProtesta([...acta.participantesProtesta]);
      }
      /**
       * 
       * 
       * 
       *               <AsyncSelect
                label="Grupo de trabajo"
                name="grupo-trabajo"
                labelProp="nombreCompleto"
                onChange={(e) => setGrupoTrabajo(e.target.value)}
                propId="idGrupoTrabajo"
                value={grupoTrabajo}
                extraParams={gExtra}
              />
       */
      if (
        acta.participantesPoliticos &&
        acta.participantesPoliticos.length > 0
      ) {
        const merged = [...acta.participantesPoliticos, ...tipoVotos];
        const conResultado = merged.map((entry) => ({
          ...entry,
          resultado: "",
        }));
        // const conResultado2 = acta.participantesPoliticos
        //   .filter(filtroProtesta)
        //   .map((entry) => ({
        //     ...entry,
        //     resultado: "",
        //   }));
        setVotosParticipantesPoliticos(conResultado);
      }
      if (acta.puntosRecuento) {
        setPuntosRecuento([...acta.puntosRecuento]);
      }
    }
  }, [acta, actaLoading]);

  React.useEffect(() => {
    if (grupoTrabajo) {
      const {
        idGrupoTrabajo,
        //     numeroPuntosRecuento,
        // fechaInicio: fechaInicioOriginal,
        // fechaFin: fechaFinOriginal,
      } = grupoTrabajo;
      if (idGrupoTrabajo) {
        setGrupoTrabajoId(idGrupoTrabajo.toString());
      }
      // if (numeroPuntosRecuento) {
      //   setPuntoDeRecuento(numeroPuntosRecuento.toString());
      // }
      // const fechaInicioTmp = Date.parse(fechaInicioOriginal);
      // const horaInicioTmp = new Date(fechaInicioOriginal).toLocaleTimeString(
      //   "es-MX"
      // );
      // const fechaFinTmp = Date.parse(fechaFinOriginal);
      // const horaFinTmp = new Date(fechaFinOriginal).toLocaleTimeString("es-MX");
      // setFechaInicio(fechaInicioTmp);
      // setHoraInicio(horaInicioTmp);
      // setFechaFinal(fechaFinTmp);
      // setHoraFinal(horaFinTmp);
    }
  }, [grupoTrabajo]);

  React.useEffect(() => {
    if (!resolverEnPleno) setVotosReservados("");
  }, [resolverEnPleno]);

  React.useEffect(() => {
    setMinutoFinal("");
  }, [minutoInicio]);

  React.useEffect(() => {
    setHoraFinal("");
  }, [horaInicio]);

  React.useEffect(() => {
    setMinutoFinal("");
  }, [horaFinal]);

  React.useEffect(() => {
    setHoraFinal("");
    setMinutoFinal("");
  }, [fechaFinal]);

  const onSubmitRecuentoGT = (sumaVotos) => {
    setPostLoading(true);
    const mapper = (v) => ({
      idParticipantePolitico: v.idParticipantePolitico,
      votos: v.resultado,
    });
    const votosCandidatosNoRegistrados =
      votosParticipantesPoliticos[votosParticipantesPoliticos.length - 3]
        .resultado;
    const votosNulos =
      votosParticipantesPoliticos[votosParticipantesPoliticos.length - 2]
        .resultado;
    const votosTotales = sumaVotos;
    const inicioRecuento = new Date(
      2021,
      5,
      parseInt(fechaInicio),
      parseInt(horaInicio),
      parseInt(minutoInicio),
      0
    );
    const finRecuento = new Date(
      2021,
      5,
      parseInt(fechaFinal),
      parseInt(horaFinal),
      parseInt(minutoFinal),
      0
    );
    // SUMAR HORAS A FECHAS
    const obj = {
      idActa,
      idTipoActa: acta.idTipoActa,
      idGrupoTrabajo: grupoTrabajoId,
      bResolverEnPleno: 0,
      bBoletasOtrasElecciones: 0,
      puntoRecuento: puntoDeRecuento.idGrupoTrabajoIntegrante,
      numeroBoletasSobrantes: boletasSobrantes,
      fechaInicioRecuento: inicioRecuento.toLocaleString("en-US", {
        timeZone: "America/Hermosillo",
      }),
      fechaFinRecuento: finRecuento.toLocaleString("en-US", {
        timeZone: "America/Hermosillo",
      }),
      votosNulos,
      votosCandidatosNoRegistrados,
      votosTotales,
      votosProtesta: numProtesta.map((e) => ({
        idPartidoPolitico: e.idPartido,
        votos: e.resultado,
      })),
      votosParticipante: votosParticipantesPoliticos
        .filter((v) => v.idParticipantePolitico !== undefined)
        .map(mapper),
    };
    if (resolverEnPleno) {
      obj.bResolverEnPleno = 1;
      obj.numeroVotosReservados = votosReservados;
    }
    if (encontroBoletas) {
      obj.bBoletasOtrasElecciones = 1;
      obj.boletasOtrasElecciones = [
        {
          tipoEleccion: TIPO_ELECCION.GUBERNATURA,
          boletas: parseInt(otrasBoletasG),
        },
        {
          tipoEleccion: TIPO_ELECCION.DIPUTACIONES_LOCALES,
          boletas: parseInt(otrasBoletasD),
        },
        {
          tipoEleccion: TIPO_ELECCION.AYUNTAMIENTO,
          boletas: parseInt(otrasBoletasM),
        },
      ];
    }
    services
      .post("acta-registro-voto-computo/guardar-recuento-gt", obj)
      .then((res) => {
        if (res) setPrintOpen(true);
      })
      .finally((_) => setPostLoading(false));
  };

  const onSaveClicked = () => {
    setValidando(true);
    if (
      postLoading ||
      fechaInicio === "" ||
      minutoInicio === "" ||
      puntoDeRecuento === "" ||
      fechaFinal === "" ||
      horaInicio === "" ||
      minutoFinal === "" ||
      horaFinal === "" ||
      grupoTrabajoId === "" ||
      boletasSobrantes === "" ||
      (resolverEnPleno && votosReservados === "") ||
      !boletasEncontradasValido ||
      [...votosParticipantesPoliticos]
        .filter((_, i) => i !== votosParticipantesPoliticos.length - 1)
        .some((p) => p.resultado === "")
    ) {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    } else {
      setConfirmOpen(true);
    }
  };

  if (!acta || actaLoading) return <Loader />;

  let sumaVotos = 0;
  votosParticipantesPoliticos.forEach((e, i) => {
    if (
      i < votosParticipantesPoliticos.length - 1 &&
      e.resultado &&
      e.resultado !== ""
    )
      sumaVotos += parseInt(e.resultado);
  });

  let boletasEncontradasValido = true;
  if (
    encontroBoletas &&
    acta.tipoEleccion &&
    (acta.tipoEleccion.idTipoEleccion === TIPO_ELECCION.DIPUTACIONES_LOCALES ||
      acta.tipoEleccion.idTipoEleccion ===
        TIPO_ELECCION.DIPUTACIONES_LOCALES_RP)
  ) {
    boletasEncontradasValido = otrasBoletasG !== "" && otrasBoletasM !== "";
  }
  if (
    encontroBoletas &&
    acta.tipoEleccion &&
    acta.tipoEleccion.idTipoEleccion === TIPO_ELECCION.GUBERNATURA
  ) {
    boletasEncontradasValido = otrasBoletasD !== "" && otrasBoletasM !== "";
  }
  if (
    encontroBoletas &&
    acta.tipoEleccion &&
    acta.tipoEleccion.idTipoEleccion === TIPO_ELECCION.AYUNTAMIENTO
  ) {
    boletasEncontradasValido = otrasBoletasG !== "" && otrasBoletasD !== "";
  }

  return (
    <PageLayout
      title={`CONSTANCIA INDIVIDUAL DE RESULTADOS ELECTORALES POR PUNTO DE RECUENTO: ${
        acta ? acta.nombreCompletoActa : ""
      }`}
      links={[
        ...breadcrumbs,
        {
          to: `/recuento-gt/nuevo/detalle?id=${idActa}`,
          icon: <FindInPage fontSize="small" />,
          label: `${acta ? acta.nombreCompletoActa : ""}`,
        },
      ]}
      history={history}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.subtitle}>
                Grupo de trabajo que ejercerá el recuento
              </Typography>
              <AsyncSelect
                label="Grupo de trabajo"
                name="grupo-trabajo"
                labelProp="nombreCompleto"
                onChange={(e) => setGrupoTrabajo(e.target.value)}
                propId="idGrupoTrabajo"
                disabled={grupoTrabajoId !== ""}
                value={grupoTrabajo}
                extraParams={gExtra}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                className={
                  puntoDeRecuento === "" ? classes.prSubtitle : classes.subtitle
                }
              >
                Seleccione punto de recuento
              </Typography>
              <SingleSelect
                label="Punto de recuento"
                value={puntoDeRecuento}
                error={puntoDeRecuento === ""}
                options={puntosRecuento.filter(
                  (e) => e.idGrupoTrabajo === parseInt(grupoTrabajoId)
                )}
                onChange={(e) => setPuntoDeRecuento(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                  <Typography className={classes.subtitle}>
                    Fecha de inicio del recuento
                  </Typography>
                  <SingleSelect
                    disabled={grupoTrabajoId === ""}
                    label="Día"
                    value={fechaInicio}
                    error={validando && fechaInicio === ""}
                    onChange={(e) => {
                      diasFecha.includes(e.target.value)
                        ? setFechaInicio(e.target.value)
                        : setFechaInicio("");
                      setFechaFinal("");
                    }}
                    options={diasFecha}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.subtitle}>
                    de Junio del 2021
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.subtitle}>
                    Hora y tiempo de inicio del recuento
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SingleSelect
                        label="Hora"
                        disabled={grupoTrabajoId === ""}
                        value={horaInicio}
                        error={validando && horaInicio === ""}
                        onChange={(e) => setHoraInicio(e.target.value)}
                        options={opcionesHora}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <SingleSelect
                        label="Minuto"
                        disabled={grupoTrabajoId === ""}
                        value={minutoInicio}
                        error={validando && minutoInicio === ""}
                        onChange={(e) => setMinutoInicio(e.target.value)}
                        options={opcionesMinuto}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                  <Typography className={classes.subtitle}>
                    Fecha de finalización de recuento
                  </Typography>
                  <SingleSelect
                    label="Día"
                    disabled={grupoTrabajoId === ""}
                    value={fechaFinal}
                    error={validando && fechaFinal === ""}
                    onChange={(e) => {
                      if (diasFecha.includes(e.target.value)) {
                        setFechaFinal(e.target.value);
                      } else {
                        setFechaFinal("");
                      }
                    }}
                    options={diasFecha.filter(
                      (d) => parseInt(d) >= parseInt(fechaInicio)
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.subtitle}>
                    de Junio del 2021
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.subtitle}>
                    Hora y tiempo de finalización de recuento
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SingleSelect
                        label="Hora"
                        disabled={grupoTrabajoId === ""}
                        value={horaFinal}
                        error={validando && horaFinal === ""}
                        onChange={(e) => setHoraFinal(e.target.value)}
                        options={opcionesHora.filter(
                          (h) =>
                            h >=
                              parseInt(horaInicio === "" ? "0" : horaInicio) ||
                            fechaFinal !== fechaInicio
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SingleSelect
                        label="Minuto"
                        disabled={grupoTrabajoId === ""}
                        value={minutoFinal}
                        error={validando && minutoFinal === ""}
                        onChange={(e) => setMinutoFinal(e.target.value)}
                        options={opcionesMinuto.filter(
                          (m) =>
                            m >=
                              parseInt(
                                minutoInicio === "" ? "0" : minutoInicio
                              ) || horaFinal !== horaInicio
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.subtitle}>
                Número de boletas sobrantes de la casilla
              </Typography>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <TextField
                  id="boletas-sobrantes"
                  size="small"
                  error={validando && boletasSobrantes === ""}
                  disabled={grupoTrabajoId === ""}
                  fullWidth
                  label="Boletas sobrantes"
                  placeholder="Ingrese la cantidad de boletas sobrantes"
                  helperText={
                    validando &&
                    boletasSobrantes === "" &&
                    "Favor de ingresar un número entero"
                  }
                  variant="outlined"
                  value={boletasSobrantes}
                  onChange={(e) => {
                    const { value } = e.target;
                    const intValue = parseInt(
                      value.toString().replace(NON_DIGIT, "")
                    );
                    let pEntry = isNaN(intValue) ? "" : intValue;
                    setBoletasSobrantes((v) => {
                      if (isNaN(intValue)) return "";
                      else if (value.length <= 3) return pEntry;
                      else return v;
                    });
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>
                NÚMERO ESCRITO DE PROTESTA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {acta &&
                acta.participantesPoliticos &&
                acta.participantesPoliticos.length > 0 ? (
                  [
                    ...participantesProtesta.sort((a, b) =>
                      a.orden && b.orden ? a.orden - b.orden : 1
                    ),
                  ]
                    // .filter(filtroProtesta)
                    .map((p, index) => {
                      return (
                        <Grid item xs={2} key={index}>
                          <Grid
                            container
                            component={Paper}
                            style={{ alignItems: "center" }}
                          >
                            {p.logo !== "" ? (
                              <>
                                <Grid item xs={12}>
                                  <MiniCardEntry
                                    logo={photoBaseUrl + p.logo}
                                    value={
                                      numProtesta[index]
                                        ? numProtesta[index].resultado
                                        : ""
                                    }
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      const intValue = parseInt(
                                        value.toString().replace(NON_DIGIT, "")
                                      );
                                      let pEntry = isNaN(intValue)
                                        ? ""
                                        : intValue;
                                      setNumProtesta((v) => {
                                        let arr = [...v];
                                        if (
                                          arr.length > 0 &&
                                          value.length <= 3
                                        ) {
                                          arr[index].resultado = pEntry;
                                        }
                                        return arr;
                                      });
                                    }}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <div className={classes.panelEspecial}>
                                <Typography
                                  className={classes.panelEspecialText}
                                >
                                  {p.nombre}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })
                ) : (
                  <div />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container style={{ alignItems: "center" }}>
            <Checkbox
              value={encontroBoletas}
              onChange={(_) => setEncontroBoletas((v) => !v)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography className={classes.text}>
              ENCONTRÓ BOLETAS DE OTRAS ELECCIONES
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {acta &&
              acta.tipoEleccion &&
              acta.tipoEleccion.idTipoEleccion !== 1 && (
                <Grid item xs={4}>
                  <Typography
                    className={classes.subtitle}
                    style={{ color: encontroBoletas ? "#000" : "#939393" }}
                  >
                    Boletas de GUBERNATURA encontradas
                  </Typography>
                  <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      id="filled-basic"
                      size="small"
                      disabled={!encontroBoletas}
                      error={
                        validando && encontroBoletas && otrasBoletasG === ""
                      }
                      fullWidth
                      label="Boletas de GUBERNATURA"
                      helperText={
                        validando &&
                        otrasBoletasG === "" &&
                        "Favor de ingresar un número entero"
                      }
                      variant="outlined"
                      value={otrasBoletasG}
                      onChange={(e) => {
                        const { value } = e.target;
                        const intValue = parseInt(
                          value.toString().replace(NON_DIGIT, "")
                        );
                        let pEntry = isNaN(intValue) ? "" : intValue;
                        setOtrasBoletasG((v) => {
                          if (isNaN(intValue)) return "";
                          else if (value.length <= 3) return pEntry;
                          else return v;
                        });
                      }}
                    />
                  </form>
                </Grid>
              )}

            {acta &&
              acta.tipoEleccion &&
              acta.tipoEleccion.idTipoEleccion !== 3 &&
              acta.tipoEleccion.idTipoEleccion !== 4 && (
                <Grid item xs={4}>
                  <Typography
                    className={classes.subtitle}
                    style={{ color: encontroBoletas ? "#000" : "#939393" }}
                  >
                    Boletas DISTRITALES encontradas
                  </Typography>
                  <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      id="filled-basic"
                      size="small"
                      disabled={!encontroBoletas}
                      error={
                        validando && encontroBoletas && otrasBoletasD === ""
                      }
                      fullWidth
                      label={`Boletas DISTRITALES`}
                      helperText={
                        validando &&
                        otrasBoletasD === "" &&
                        "Favor de ingresar un número entero"
                      }
                      variant="outlined"
                      value={otrasBoletasD}
                      onChange={(e) => {
                        const { value } = e.target;
                        const intValue = parseInt(
                          value.toString().replace(NON_DIGIT, "")
                        );
                        let pEntry = isNaN(intValue) ? "" : intValue;
                        setOtrasBoletasD((v) => {
                          if (isNaN(intValue)) return "";
                          else if (value.length <= 3) return pEntry;
                          else return v;
                        });
                      }}
                    />
                  </form>
                </Grid>
              )}

            {acta &&
              acta.tipoEleccion &&
              acta.tipoEleccion.idTipoEleccion !== 2 && (
                <Grid item xs={4}>
                  <Typography
                    className={classes.subtitle}
                    style={{ color: encontroBoletas ? "#000" : "#939393" }}
                  >
                    Boletas de AYUNTAMIENTO encontradas
                  </Typography>
                  <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      id="filled-basic"
                      size="small"
                      disabled={!encontroBoletas}
                      error={
                        validando && encontroBoletas && otrasBoletasM === ""
                      }
                      fullWidth
                      label="Boletas de AYUNTAMIENTO"
                      helperText={
                        validando &&
                        otrasBoletasM === "" &&
                        "Favor de ingresar un número entero"
                      }
                      variant="outlined"
                      value={otrasBoletasM}
                      onChange={(e) => {
                        const { value } = e.target;
                        const intValue = parseInt(
                          value.toString().replace(NON_DIGIT, "")
                        );
                        let pEntry = isNaN(intValue) ? "" : intValue;
                        setOtrasBoletasM((v) => {
                          if (isNaN(intValue)) return "";
                          else if (value.length <= 3) return pEntry;
                          else return v;
                        });
                      }}
                    />
                  </form>
                </Grid>
              )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontSize: 24 }}
                  >
                    RESULTADOS DE LA VOTACIÓN DE CASILLA:
                    {` ${acta ? acta.nombreCompletoActa : ""}`}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={6} style={{ textAlign: "center" }}>
                    Digite los votos para cada partido politico, coalición,
                    cantidatura común, candidato independiente, candidatos no
                    registrados y total de votos. <br /> En caso de no haber
                    votos en alguno de los campos, digite el número cero.
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={1}>No.</StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">
                    Partido Político, Candidatura Común, Coalición o Candidato
                    Independiente
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} align="center">
                    RESULTADOS DE LA VOTACIÓN
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {acta &&
                acta.participantesPoliticos &&
                acta.participantesPoliticos.length > 0 ? (
                  [
                    ...acta.participantesPoliticos.sort((a, b) =>
                      a.orden && b.orden ? a.orden - b.orden : 1
                    ),
                    ...tipoVotos,
                  ].map((p, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell colSpan={1}>
                        {index < acta.participantesPoliticos.length
                          ? index + 1
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1} align="center">
                        {p.logo !== "" ? (
                          <img
                            alt={p.nombre}
                            src={p.logo}
                            style={{ width: 100 }}
                          />
                        ) : (
                          <div className={classes.panelEspecial}>
                            <Typography className={classes.panelEspecialText}>
                              {p.nombre}
                            </Typography>
                          </div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell colSpan={4} align="right">
                        <form
                          autoComplete="off"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <TextField
                            id={index.toString()}
                            label="0 al 999"
                            fullWidth
                            variant="standard"
                            required
                            value={
                              votosParticipantesPoliticos[index]
                                ? index ===
                                  votosParticipantesPoliticos.length - 1
                                  ? sumaVotos
                                  : votosParticipantesPoliticos[index].resultado
                                : ""
                            }
                            disabled={
                              index === votosParticipantesPoliticos.length - 1
                            }
                            InputProps={{
                              className: classes.fieldText,
                            }}
                            error={
                              index ===
                                votosParticipantesPoliticos.length - 1 &&
                              sumaVotos > 1000
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              const intValue = parseInt(
                                value.toString().replace(NON_DIGIT, "")
                              );
                              let pEntry = isNaN(intValue) ? "" : intValue;
                              setVotosParticipantesPoliticos((v) => {
                                let arr = [...v];
                                if (arr.length > 0 && value.length <= 3) {
                                  arr[index].resultado = pEntry;
                                }
                                return arr;
                              });
                            }}
                          />
                        </form>
                        {validando && p.resultado === "" && (
                          <FormHelperText className={classes.tableHelperText}>
                            Favor de ingresar un número entero
                          </FormHelperText>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <div />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={8}>
          <Grid container style={{ alignItems: "center" }}>
            <Checkbox
              value={resolverEnPleno}
              onChange={(_) => setResolverEnPleno((v) => !v)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography className={classes.text}>
              ANEXAR PARA LA RESOLUCIÓN EN EL PLENO (CON VOTOS RESERVADOS)
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {resolverEnPleno && (
            <>
              <Typography
                className={classes.subtitle}
                style={{ color: resolverEnPleno ? "#000" : "#939393" }}
              >
                Número de votos reservados
              </Typography>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <TextField
                  id="votos-reservados"
                  size="small"
                  disabled={!resolverEnPleno}
                  error={validando && resolverEnPleno && votosReservados === ""}
                  fullWidth
                  label="Votos reservados"
                  placeholder="Ingrese la cantidad de votos reservados"
                  helperText={
                    validando &&
                    resolverEnPleno &&
                    votosReservados === "" &&
                    "Favor de ingresar un número entero"
                  }
                  variant="outlined"
                  value={votosReservados}
                  onChange={(e) => {
                    const { value } = e.target;
                    const intValue = parseInt(
                      value.toString().replace(NON_DIGIT, "")
                    );
                    let pEntry = isNaN(intValue) ? "" : intValue;
                    setVotosReservados((v) => {
                      if (isNaN(intValue)) return "";
                      else if (value.length <= 3) return pEntry;
                      else return v;
                    });
                  }}
                />
              </form>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            disabled={
              postLoading ||
              grupoTrabajoId === "" ||
              puntoDeRecuento === "" ||
              minutoInicio === "" ||
              minutoFinal === "" ||
              fechaInicio === "" ||
              fechaFinal === "" ||
              horaInicio === "" ||
              horaFinal === "" ||
              (!acta.esEspecial && sumaVotos > 999) ||
              (acta.esEspecial && sumaVotos > 9999) ||
              boletasSobrantes === "" ||
              (resolverEnPleno &&
                (votosReservados === "" || votosReservados === 0)) ||
              !boletasEncontradasValido ||
              [...votosParticipantesPoliticos]
                .filter((_, i) => i !== votosParticipantesPoliticos.length - 1)
                .some((p) => p.resultado === "")
            }
            color="primary"
            variant="contained"
            style={{ float: "right", marginLeft: "1%" }}
            onClick={onSaveClicked}
          >
            {postLoading ? "CARGANDO" : "GUARDAR"}
          </Button>
        </Grid>

        <AlertDialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          title={`CONFIRMACIÓN DE RECUENTO CON GRUPO DE TRABAJO DE ACTA ${
            acta ? acta.nombreCompletoActa : ""
          }`}
          loading={postLoading}
          content="¿Está seguro que desea guardar la información capturada?"
          onAccept={(_) => onSubmitRecuentoGT(sumaVotos)}
        />
        <AlertDialog
          open={printOpen}
          onClose={() => history.push("/recuento-gt/nuevo")}
          title={`${
            acta ? acta.nombreCompletoActa : ""
          } GUARDADO CORRECTAMENTE.`}
          cancelText="Finalizar y continuar"
          titleColor="#60c45a"
          content="¿Desea imprimir constancia de la información capturada?"
          onAccept={(_) => {
            setPdfDialogOpen(true);
            setLink(
              `${baseUrlPdf}constancia-individual?idActa=${idActa}&idUsuario=${id}`
            );
          }}
        />
        <PdfDialog
          source={link}
          open={pdfDialogOpen}
          setOpen={setPdfDialogOpen}
        />
      </Grid>
    </PageLayout>
  );
};

export default RecuentoGtDetail;
