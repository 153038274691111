import React from "react";
import { auth } from "../firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { emptyRequest, getRequest } from "../constants/request";
import { useHttp } from "./useHttp";
import { useApp } from "./useApp";

const AuthContext = React.createContext();

const empty = emptyRequest();

export function AuthProvider(props) {
  const { token, setToken } = useApp();
  const [session, sessionLoading, sessionError] = useAuthState(auth);
  const [agendaRequest, setAgendaRequest] = React.useState(empty);
  const [agendaResponse, agendaResponseLoading, agendaError] =
    useHttp(agendaRequest);

  const onTokenChange = React.useCallback(
    (userCredential) => {
      // console.log("useAuth: [React.useCallback] onTokenChange");
      if (userCredential) {
        userCredential.getIdToken().then((newToken) => {
          setToken(newToken);
        });
      }
    },
    [setToken]
  );

  const signIn = React.useCallback(async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const signOut = React.useCallback(async () => {
    try {
      await auth.signOut();
    } catch (e) {
      console.error(e);
    }
  }, []);

  React.useEffect(() => {
    let mounted = true;
    let unsub = null;
    if (mounted && session) {
      unsub = auth.onIdTokenChanged(onTokenChange);
      // auth.currentUser.getIdTokenResult(true).then(onTokenChange);
    }
    return () => {
      mounted = false;
      if (unsub) unsub();
    };
  }, [session, onTokenChange]);

  React.useEffect(() => {
    if (token) {
      const agendaReq = getRequest("agenda/cuenta", {
        expand: "permiso,agenda,consejoElectoral,consejosExtra",
      });
      // agendaReq.alert = true;
      setAgendaRequest(() => agendaReq);
    } else {
      setAgendaRequest(empty);
    }
  }, [token]);

  const memData = React.useMemo(() => {
    return {
      session,
      sessionLoading,
      user:
        agendaResponse &&
        agendaResponse.resultado &&
        agendaResponse.resultado.length > 0
          ? {
              ...agendaResponse.resultado[0],
              permisoExtra: agendaResponse.detalle,
            }
          : null,
      userLoading: agendaResponseLoading,
      userError: agendaError || sessionError,
      signIn,
      signOut,
    };
  }, [
    session,
    sessionLoading,
    sessionError,
    agendaResponse,
    agendaResponseLoading,
    agendaError,
    signIn,
    signOut,
  ]);

  return <AuthContext.Provider value={memData} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: auth context not defined.";
  }
  return context;
}
