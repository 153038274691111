import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { validateEmail } from "../../utils";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { Create } from "@material-ui/icons";

const Recursos = () => {
  const history = useHistory();
  const header = [];
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ name: "recurso" });
  // const [correo, setCorreo] = useState("");
  const [nombre, setNombre] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();

  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    await refreshModels(true, params);
  };

  const onDeleteClicked = (row) => {
    try {
      showDialog({
        onCancel: closeDialog,
        onOk: () => {
          setOpenMenuRow(null);
          deleteModel(row.idUsuario);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TablePageLayout
      title="ADMINISTRACIÓN DE RECURSOS"
      model="recurso"
      history={history}
      actionButton={{
        to: "/recursos/nuevo",
        icon: <Create />,
        label: "Nuevo recurso",
      }}
      SearchComponents={
        <>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Buscar por nombre"
              onChange={(event) => setNombre(event.target.value)}
              variant="outlined"
              onBlur={(_) => {
                if (nombre.length > 0) {
                  const nombres = nombre.split(" ");
                  if (nombres && nombres.length > 0)
                    refreshModels(null, { nombre });
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              fullWidth
              size="small"
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          count={modelsPage !== null ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre oficial",
              cell: (row) => row.nombre,
            },
            {
              prop: "rutaCompleta",
              name: "Ruta en servidor",
              cell: (row) => (
                <a href={row.rutaCompleta} target="_blank" rel="noreferrer">
                  <img
                    src={row.rutaCompleta}
                    alt={row.nombre}
                    height={80}
                    style={{ borderRadius: 5 }}
                    align="center"
                  />
                </a>
              ),
            },
            {
              prop: "creado",
              name: "Fecha de creación",
              cell: (row) => row.creado,
            },
            {
              prop: "tipo",
              name: "Tipo del recurso",
              cell: (row) => row.tipo,
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() => history.push(`/recursos/${row.idRecurso}`)}
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Recursos;
