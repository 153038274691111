import React, { useEffect, useState } from "react";
import ArchiveIcon from "@material-ui/icons/Archive";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import FullScreenLoader from "../../components/FullScreenLoader";
import Services from "../../services";
import TablePagination from "../../components/TablePagination";
import PageLayout from "../../components/PageLayout";

const urlCasillas =
  "casilla?ordernar=idCasilla.desc&expand=distrito,seccion,nombreCompleto,casillaClausura";

const Casillas = ({ history }) => {
  const [cargando, setCargando] = useState(true);
  const [casillas, setCasillas] = useState([]);
  const [total, setTotal] = useState(0);
  const [indexAbrirMenu, setIndexAbrirMenu] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    let mounted = true;

    const obtenerCasillas = async () => {
      try {
        const respuesta = await Services.get(urlCasillas);

        if (mounted) {
          setCasillas(respuesta.resultado);
          setTotal(respuesta.paginacion.total);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setCargando(false);
      }
    };

    obtenerCasillas();

    return () => (mounted = false);
  }, []);

  if (cargando) return <FullScreenLoader />;

  const cambiarPagina = async (pagina) => {
    try {
      const respuesta = await Services.get(
        urlCasillas + "&pagina=" + (pagina + 1)
      );

      setCasillas(respuesta.resultado);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageLayout
      title="CASILLAS"
      links={[
        {
          to: "/clausura-casillas",
          icon: <ArchiveIcon />,
          label: "Casillas",
        },
      ]}
      history={history}
    >
      <TablePagination
        data={casillas}
        paginatedApi
        changePageApi={cambiarPagina}
        count={total}
        extraRows={[
          {
            prop: "nombreCompleto",
            name: "Casilla",
            cell: (row) => <>{row.nombreCompleto}</>,
          },
          {
            prop: "seccion",
            name: "Sección",
            cell: (row) => (
              <>{row.seccion ? row.seccion.nombre : "Sin Sección"}</>
            ),
          },
          {
            prop: "casillaClausura",
            name: "Clasurado",
            cell: (row) => (
              <>{row.casillaClausura ? "Clausurado" : "Pendiente"}</>
            ),
          },
          {
            prop: "acciones",
            name: "Acciones",
            cell: (row, index) => (
              <div>
                <IconButton
                  disabled={row.permisoRol === "soporte"}
                  onClick={(event) => {
                    setIndexAbrirMenu(index);
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={index === indexAbrirMenu}
                  onClose={() => setIndexAbrirMenu(null)}
                >
                  <MenuItem
                    onClick={() =>
                      history.push(
                        `/clausura-casillas/casilla/${row.idCasilla}`
                      )
                    }
                  >
                    <AllInboxIcon style={{ paddingRight: 5 }} />
                    Clausurar
                  </MenuItem>
                </Menu>
              </div>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default Casillas;
