import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  CardMedia,
  GridList,
  GridListTile,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import { photoBaseUrl } from "../../utils/variables";
import Loading from "../../components/FullScreenLoader";
import CustomDialog from "../../components/CustomDialog";
import PageHeader from "../../components/PageHeader";

import { useModel } from "../../hooks/useModel";
import { useQuery } from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import { useModels } from "../../hooks/useModels";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function PartidosPoliticosDetail({ history }) {
  const { showAlert } = useAlert();
  const classes = useStyles();
  const query = useQuery();

  const idPartidoPolitico = query.get("idPartidoPolitico");
  const editing = !!idPartidoPolitico;

  const [isOpen, setIsOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState("");
  const [domicilio, setDomicilio] = React.useState("");
  const [abreviatura, setAbreviatura] = React.useState("");
  const [rutaLogo, setRutaLogo] = React.useState("");

  const [recursos] = useModels({ name: "recurso" });
  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "partido-politico",
    id: idPartidoPolitico,
    expand: "",
    redirectOnPost: true,
  });

  React.useEffect(() => {
    if (!modelLoading && model && modelError) {
      setNombre(model.nombre);
      setDomicilio(model.domicilio);
      setAbreviatura(model.abreviatura);
      setRutaLogo(model.rutaLogo);
    }
  }, [model, modelLoading, modelError]);

  const onSaveClicked = async () => {
    if (nombre === "" || abreviatura === "" || rutaLogo === "") {
      if (nombre === "")
        modelError.nombre = "El nombre del partido es requerido";
      else modelError.nombre = "";
      if (abreviatura === "")
        modelError.abreviatura = "La abreviatura del partido es requerida";
      else modelError.abreviatura = "";
      if (rutaLogo === "")
        modelError.rutaLogo = "La foto del partido es requerida";
      else modelError.rutaLogo = "";

      window["scrollTo"]({ top: 0, behavior: "smooth" });
      showAlert({
        message: "Favor de revisar los campos llenados",
        severity: "warning",
      });
    } else {
      let body = {
        nombre,
        domicilio,
        abreviatura,
        rutaLogo,
      };
      if (editing) {
        body.idPartidoPolitico = idPartidoPolitico;
      }
      body = { ...body };
      updateModel(body);
    }
  };

  const handleClose = () => {
    setIsOpen((v) => !v);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE PARTIDO POLITICO"
        model="partidos-politicos"
        editing={editing}
        links={[
          {
            to: "/partidos-politicos",
            label: "Partidos Politicos",
          },
          {
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nombre"
            size="small"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Domicilio"
            size="small"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setDomicilio(e.target.value)}
            helperText={modelError.domicilio}
            error={Boolean(modelError.domicilio)}
            inputProps={{
              value: domicilio,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Abreviatura"
            size="small"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setAbreviatura(e.target.value)}
            helperText={modelError.abreviatura}
            error={Boolean(modelError.abreviatura)}
            inputProps={{
              value: abreviatura,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClose()}
          >
            Seleccionar Logo
          </Button>
          <CustomDialog
            isOpen={isOpen}
            maxWidth="lg"
            handleClose={handleClose}
            title="Selección de Logo"
          >
            <GridList cellHeight={160} cols={10}>
              {recursos.map((recurso) => (
                <GridListTile key={recurso.idRecurso} cols={1}>
                  <img
                    src={`${photoBaseUrl}${recurso.ruta}`}
                    alt={recurso.nombre}
                    onClick={() => {
                      setRutaLogo(recurso.ruta);
                      handleClose();
                    }}
                    style={{ width: "100%", height: "auto" }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </CustomDialog>
        </Grid>
        {rutaLogo !== "" && (
          <Grid item xs={12}>
            <CardMedia
              style={{ width: "auto" }}
              component="img"
              alt="Imagen del Partido Político"
              height="300"
              image={`${photoBaseUrl}${rutaLogo}`}
              title="Imagen del Partido Político"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSaveClicked}
            disabled={updateModelLoading}
            style={{
              float: "right",
            }}
            startIcon={<SaveIcon />}
          >
            {editing ? "GUARDAR  PARTIDO POLITICO" : "CREAR PARTIDO POLITICO"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default PartidosPoliticosDetail;
