import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import Loading from "../../components/FullScreenLoader";
import SaveIcon from "@material-ui/icons/Save";

import { useModel } from "../../hooks/useModel";
import { useQuery } from "../../hooks/useQuery";
import PageHeader from "../../components/PageHeader";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function DistritosDetail({ match, history }) {
  const classes = useStyles();
  const query = useQuery();
  const idDistrito = query.get("idDistrito");
  const editing = !!idDistrito;

  const [nombre, setNombre] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [tipo, setTipo] = React.useState("");

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "distrito",
    id: idDistrito,
    expand: "",
    redirectOnPost: true,
  });

  React.useEffect(() => {
    if (!modelLoading && model && modelError) {
      setNombre(model.nombre);
      setDescripcion(model.descripcion);
      setTipo(model.tipo);
    }
  }, [model, modelLoading, modelError]);

  const onSaveClicked = async () => {
    let body = {
      nombre,
      descripcion,
      tipo,
    };
    if (editing) {
      body.idDistrito = idDistrito;
    }
    body = { ...body };
    updateModel(body);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE DISTRITO"
        model="distrito"
        editing={editing}
        links={[
          {
            to: "/distritos",
            label: "Distritos",
          },
          {
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nombre"
            variant="outlined"
            size="small"
            required
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Descripcion"
            size="small"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setDescripcion(e.target.value)}
            helperText={modelError.descripcion}
            error={Boolean(modelError.descripcion)}
            inputProps={{
              value: descripcion,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Tipo"
            size="small"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setTipo(e.target.value)}
            helperText={modelError.tipo}
            error={Boolean(modelError.tipo)}
            inputProps={{
              value: tipo,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSaveClicked}
            disabled={updateModelLoading}
            style={{ float: "right" }}
            startIcon={<SaveIcon />}
          >
            {editing ? "GUARDAR DISTRITO" : "CREAR DISTRITO"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default DistritosDetail;
