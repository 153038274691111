import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { Add } from "@material-ui/icons";
import { IconButton, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";

import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";

import { useModels } from "../../hooks/useModels";

const TipoAsistentes = () => {
  const history = useHistory();
  const header = [
    {
      prop: "tipo",
      name: "Tipo del Asistente",
    },
  ];

  const [tipo, setTipo] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "tipo-asistente",
    ordenar: "idTipoAsistente.desc",
    pagina: 0,
  });

  const onSearchClicked = async (page) => {
    let params = {};
    if (tipo.length > 0) params = { ...params, tipo };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar este tipo asistente?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        deleteModel(row.idTipoAsistente);
        refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "coalicion",
      ordenar: "idTipoAsistente.desc",
      tipo,
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="CONTROL DE TIPOS DE ASISTENTE"
      model="tipo-asistentes"
      history={history}
      actionButton={{
        to: "/tipo-asistentes/nuevo",
        icon: <Add />,
        label: "Agregar tipo asistente",
      }}
      links={[
        {
          label: "Tipo Asistente",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={8}>
            <TextField
              fullWidth
              size="small"
              label="Tipo Asistente"
              onChange={(event) => setTipo(event.target.value)}
              variant="outlined"
              onBlur={(_) => {
                if (tipo.length > 0) {
                  const tipos = tipo.split(" ");
                  if (tipos && tipos.length > 0) refreshModels(null, { tipo });
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              style={{ float: "right" }}
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage !== null ? modelsPage.total : 0}
          labelRowsPerPage={"Renglones por página"}
          extraRows={[
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/tipo-asistentes/detalle?idTipoAsistente=${row.idTipoAsistente}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default TipoAsistentes;
