import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import PageHeader from "./PageHeader";
import Loading from "../components/Loader";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const TablePageLayout = ({
  SearchComponents,
  TableComponent,
  Dialog,
  preActionButton,
  actionButton,
  bottomActionButton,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { title, links, tableLoading, tableErrors, children } = props;

  return (
    <div className={classes.root}>
      <PageHeader
        history={history}
        title={title}
        links={links}
        preActionButton={preActionButton}
        actionButton={actionButton}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            alignItems="flex-end"
            justify="space-between"
          >
            {SearchComponents}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {tableLoading ? (
            <Loading />
          ) : tableErrors ? (
            <div>Error al cargar la tabla</div>
          ) : (
            TableComponent
          )}
        </Grid>
      </Grid>

      {!tableLoading && (
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={2}>
            {bottomActionButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  if (bottomActionButton.to) {
                    history.push(bottomActionButton.to);
                  } else if (bottomActionButton.onClick) {
                    bottomActionButton.onClick(e);
                  }
                }}
                style={{
                  float: "right",
                }}
                size="small"
                endIcon={bottomActionButton.icon}
                {...bottomActionButton.buttonProps}
              >
                {bottomActionButton.label}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {children}
      {Dialog}
    </div>
  );
};

export default TablePageLayout;
