import React from "react";
import Undefined from "./Undefined";
import { TableCell } from "@material-ui/core";

const isDefined = (content) => {
  return content !== null && content !== undefined;
};

const Cell = ({ children, name, main }) => {
  return (
    <TableCell
      style={{
        fontWeight: "normal",
        color: "black",
        fontSize: 14,
      }}
      className={(main && "main") || ""}
    >
      {isDefined(children) ? <span>{children}</span> : <Undefined />}
      <span className="name">{name}</span>
    </TableCell>
  );
};

export default Cell;
