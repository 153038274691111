import React from "react";
import Grid from "@material-ui/core/Grid";
import FindInPage from "@material-ui/icons/FindInPage";
import AsyncSelect from "../../components/AsyncSelect";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import PageLayout from "../../components/PageLayout";
import { History } from "@material-ui/icons";
import { auth } from "../../firebase";
import { baseUrl, baseUrlPdf } from "../../utils/variables";
import AlertDialog from "../../components/AlertDialog";
import { Button, Typography } from "@material-ui/core";
import SeleccionarActa from "../../components/SeleccionarActa";

const params = {
  estatusComputo: "con-fecha-computo",
  estatusGtReservado: "gt-reservado",
  fields: "idActa,nombreCompletoActa",
};

const breadcrumbs = [
  {
    to: `/recuento-gt-reservado`,
    icon: <FindInPage fontSize="small" />,
    label: `Historial de recuento con GT reservado`,
  },
  {
    to: `/recuento-gt-reservado/nuevo`,
    icon: <FindInPage fontSize="small" />,
    label: `Sesión de cómputo: recontar acta con GT reservado`,
  },
];

const RecuentoGtReservado = ({ history }) => {
  const [idGrupoTrabajo, setIdGrupoTrabajo] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const id = auth.currentUser.uid;
  const onEntryChange = (_, value) => {
    if (value && value !== "") {
      history.push(
        `/recuento-gt-reservado/nuevo/detalle?id=${value.token}&a=${value.idActa}`
      );
    }
  };

  const setIdGrupoTrabajoCallback = React.useCallback(
    (e) => {
      setIdGrupoTrabajo(e.target.value.idGrupoTrabajo);

      window.open(
        `${baseUrlPdf}acta-circunstanciada-voto-reservado?idUsuario=${id}&idGrupoTrabajo=${e.target.value.idGrupoTrabajo}`,
        "_blank"
      );
    },
    [id]
  );

  return (
    <PageLayout
      title="SESIÓN DE CÓMPUTO: SELECCIONE EL ACTA CON VOTOS RESERVADOS PARA SU DELIBERACIÓN"
      links={breadcrumbs}
      history={history}
      actionButton={{
        to: `/recuento-gt-reservado`,
        icon: <History />,
        label: `VER HISTORIAL DE VOTOS RESERVADOS`,
      }}
      SearchComponents={
        <>
          <Grid item xs={6}>
            <AsyncAutocompleteSearch
              label="Seleccione el acta deseada"
              searchParam="busqueda"
              name="acta"
              extraParams={params}
              labelProp="nombreCompletoActa"
              onChange={onEntryChange}
              noOptionsText="No hay actas para recuento gt reservado."
            />
          </Grid>
        </>
      }
    >
      <SeleccionarActa />
    </PageLayout>
  );
};

export default RecuentoGtReservado;
