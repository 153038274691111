import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FindInPage } from "@material-ui/icons";
import React from "react";
import AlertDialog from "../components/AlertDialog";
import PageLayout from "../components/PageLayout";
import services from "../services";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  root: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    width: "100%",
  },
}));

const breadcrumbs = [
  {
    to: `/control-capacitacion`,
    icon: <FindInPage fontSize="small" />,
    label: `Control de capacitación`,
  },
];

const escenarios = [
  {
    nombre: "ARRANQUE DE JORNADA",
  },
  {
    nombre: "RECUENTO PARCIAL (PLENO)",
  },
  {
    nombre: "RECUENTO PARCIAL (GT)",
  },
  {
    nombre: "RECUENTO TOTAL",
  },
  {
    nombre: "COTEJO TOTAL",
  },
];

const ControlCapacitacion = ({ history }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [scenario, setScenario] = React.useState(0);

  const cardClicked = (scen = 0) => {
    setConfirmOpen(true);
    setScenario(scen);
  };

  const onDialogAccept = () => {
    let postUrl = "capacitacion/escenario-" + scenario;
    setLoading(true);
    const onFinish = () => {
      setConfirmOpen(() => {
        setScenario(() => {
          setLoading(() => false);
          return 0;
        });
        return false;
      });
    };
    services
      .post(postUrl, {})
      .then((e) => e)
      .finally(onFinish);
  };
  const getMessage = () =>
    `¿Desea ejecutar el escenario de capacitación #0${scenario}?`;
  return (
    <PageLayout
      title="CONTROL DE ESCENARIOS DE CAPACITACIÓN"
      links={breadcrumbs}
      history={history}
    >
      <Grid container spacing={3} className={classes.paper} direction="row">
        {escenarios.map((escenario, index) => (
          <Grid item xs={4}>
            <Card
              key={index}
              className={classes.root}
              onClick={() => cardClicked(index + 1)}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {escenario.nombre}
                    <br />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid container justify="flex-end">
                    <Button
                      size="small"
                      color="primary"
                      style={{ color: "white" }}
                      disabled
                    >
                      ESCENARIO #0{index + 1}
                    </Button>
                  </Grid>
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <AlertDialog
        title={getMessage()}
        content="Todas los movimientos de acta y la información será modificado acorde al escenario seleccionado."
        open={confirmOpen}
        onAccept={onDialogAccept}
        loading={loading}
      />
    </PageLayout>
  );
};

export default ControlCapacitacion;
