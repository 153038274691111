import React from "react";
import Grid from "@material-ui/core/Grid";
import FindInPage from "@material-ui/icons/FindInPage";

import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import PageLayout from "../../components/PageLayout";
import { Button } from "@material-ui/core";
import { History } from "@material-ui/icons";
import SeleccionarActa from "../../components/SeleccionarActa";

const params = {
  estatusLectura: "con-lectura",
  estatusCaptura: "sin-captura",
  fields: "idActa,nombreCompletoActa,token",
};

const breadcrumbs = [
  {
    to: `/lectura-de-votos/datos-generales`,
    icon: <FindInPage fontSize="small" />,
    label: `Historial: Captura datos generales`,
  },
  {
    to: `/lectura-de-votos/datos-generales/nueva`,
    icon: <FindInPage fontSize="small" />,
    label: `Captura de datos generales`,
  },
];

const Recepciones = ({ history }) => {
  const onEntryChange = (_, value) => {
    if (value && value !== "") {
      history.push(
        `/lectura-de-votos/datos-generales/detalle?id=${value.token}&a=${value.idActa}`
      );
    }
  };

  return (
    <PageLayout
      title="CAPTURA DE DATOS GENERALES DE ACTA"
      links={breadcrumbs}
      history={history}
      actionButton={{
        to: `/lectura-de-votos/datos-generales`,
        icon: <History />,
        label: `VER HISTORIAL DE CAPTURA`,
      }}
      SearchComponents={
        <Grid container>
          <Grid item xs={6}>
            <AsyncAutocompleteSearch
              label="Seleccione el acta deseada"
              name="acta"
              searchParam="busqueda"
              extraParams={params}
              labelProp="nombreCompletoActa"
              onChange={onEntryChange}
              noOptionsText="No hay actas con lectura que necesiten llenado de datos generales."
            />
          </Grid>
        </Grid>
      }
    >
      <SeleccionarActa />
    </PageLayout>
  );
};

export default Recepciones;
