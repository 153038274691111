import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";

import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";
import CustomDialog from "../../components/CustomDialog";

import { useQuery } from "../../hooks/useQuery";
import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function CandidatoDetail({ history, dialog }) {
  const classes = useStyles();
  const query = useQuery();
  const idCandidato = query.get("id");
  const editing = !!idCandidato;

  const [isOpen, setIsOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState("");
  const [idTipoEleccion, setIdTipoEleccion] = React.useState("");
  const [tipoEleccion, setTipoEleccion] = React.useState("");
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [idDistrito, setIdDistrito] = React.useState("");
  const [formula, setFormula] = React.useState("");
  const [idPartidoPolitico, setIdPartidoPolitico] = React.useState("");
  const [idCoalicion, setIdCoalicion] = React.useState("");
  const [
    idCandidatoIndependiente,
    setIdCandidatoIndependiente,
  ] = React.useState("");
  const [idCandidaturaComun, setIdCandidaturaComun] = React.useState("");

  const municipiosParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const distritosParamsMemo = React.useMemo(
    () => ({
      tipo: 2,
      ordenar: "nombre.asc",
    }),
    []
  );

  const [tipoElecciones] = useModels({ name: "tipo-eleccion" });
  const [partidosPoliticos] = useModels({ name: "partido-politico" });
  const [coalicion] = useModels({ name: "coalicion" });
  const [candidatoIndependiente] = useModels({
    name: "candidato-independiente",
  });
  const [candidaturaComun] = useModels({
    name: "candidatura-comun",
  });

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "candidato",
    expand: "distrito,municipio,",
    id: idCandidato,
    redirectOnPost: true,
  });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setNombre(model.nombre);
      setIdTipoEleccion(model.idTipoEleccion);
      setTipoEleccion(model.tipoEleccion);
      setIdMunicipio(model.idMunicipio);
      setIdDistrito(model.idDistrito);
      setIdCoalicion(model.idCoalicion);
      setIdCandidaturaComun(model.idCandidaturaComun);
      setIdCandidatoIndependiente(model.idCandidatoIndependiente);
      setFormula(model.formula);
      setIdPartidoPolitico(model.idPartidoPolitico);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSaveClicked = () => {
    const body = {
      nombre,
      idTipoEleccion,
      tipoEleccion,
      idMunicipio,
      idDistrito,
      formula,
      idPartidoPolitico,
      idCoalicion,
      idCandidatoIndependiente,
      idCandidaturaComun,
    };
    if (editing) {
      body.idCandidato = idCandidato;
    }

    updateModel(body);
  };

  const setIdDistritoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdDistrito(v.idDistrito);
      } else {
        setIdDistrito("");
      }
    },
    [setIdDistrito]
  );

  const partidosPoliticosCambio = (event) => {
    const { value } = event.target;
    setIdPartidoPolitico(value);
  };

  const coalicionCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdCoalicion(v.props.value);
    } else {
      setIdCoalicion("");
    }
  }, []);

  const candidatoIndependienteCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdCandidatoIndependiente(v.props.value);
    } else {
      setIdCandidatoIndependiente("");
    }
  }, []);

  const candidaturaComunCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdCandidaturaComun(v.props.value);
    } else {
      setIdCandidaturaComun("");
    }
  }, []);

  const tipoEleccionCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdTipoEleccion(v.props.value);
      setTipoEleccion(v.props.children);
    } else {
      setIdTipoEleccion("");
      setTipoEleccion("");
    }
  }, []);

  const municipioCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
    } else {
      setIdMunicipio("");
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE CANDIDATO"
        editing={editing}
        dialog={dialog}
        links={[
          {
            to: "/candidatos",
            label: "Candidatos",
          },
          { label: `${editing ? "Detalle" : "Nuevo"}` },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <CustomDialog
          isOpen={isOpen}
          handleClose={handleClose}
          title="Candidato"
        ></CustomDialog>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Nombre"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Candidatura Común
            </InputLabel>
            <Select
              value={idCandidaturaComun ? idCandidaturaComun : "Ninguno"}
              style={{ textAlign: "left" }}
              onChange={candidaturaComunCambio}
              error={Boolean(modelError.idCandidaturaComun)}
              label="Candidatura Común"
            >
              <MenuItem selected value="Ninguno" key={0}>
                Ninguno
              </MenuItem>
              {candidaturaComun !== null
                ? candidaturaComun.map((opt, index) => (
                    <MenuItem key={index} value={opt.idCandidaturaComun}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idCandidaturaComun ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idCandidaturaComun}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Candidato Independiente
            </InputLabel>
            <Select
              value={
                idCandidatoIndependiente ? idCandidatoIndependiente : "Ninguno"
              }
              style={{ textAlign: "left" }}
              onChange={candidatoIndependienteCambio}
              error={Boolean(modelError.idCandidatoIndependiente)}
              label="Candidato Independiente"
            >
              <MenuItem selected value="Ninguno" key={0}>
                Ninguno
              </MenuItem>
              {candidatoIndependiente !== null
                ? candidatoIndependiente.map((opt, index) => (
                    <MenuItem key={index} value={opt.idCandidatoIndependiente}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idCandidatoIndependiente ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idCandidatoIndependiente}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Coalición
            </InputLabel>
            <Select
              value={idCoalicion ? idCoalicion : "Ninguno"}
              style={{ textAlign: "left" }}
              onChange={coalicionCambio}
              error={Boolean(modelError.idCoalicion)}
              label="Coalición"
            >
              <MenuItem selected value="Ninguno" key={0}>
                Ninguno
              </MenuItem>
              {coalicion !== null
                ? coalicion.map((opt, index) => (
                    <MenuItem key={index} value={opt.idCoalicion}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idCoalicion ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idCoalicion}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo Elección
            </InputLabel>
            <Select
              value={idTipoEleccion ? idTipoEleccion : "Ninguno"}
              style={{ textAlign: "left" }}
              onChange={tipoEleccionCambio}
              error={Boolean(modelError.idTipoEleccion)}
              label="Tipo Elección"
            >
              <MenuItem selected value="Ninguno" key={0}>
                Ninguno
              </MenuItem>
              {tipoElecciones !== null
                ? tipoElecciones.map((opt, index) => (
                    <MenuItem key={index} value={opt.idTipoEleccion}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idTipoEleccion ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idTipoEleccion}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AsyncAutocompleteSearch
            label="Distrito"
            name="distrito"
            defaultValue={model && model.distrito}
            labelProp="nombre"
            onChange={setIdDistritoCallback}
            extraParams={distritosParamsMemo}
            error={modelError.idDistrito ? "idDistrito" : ""}
            campoError="idDistrito"
          />
          {modelError.idDistrito ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idDistrito}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AsyncAutocompleteSearch
            label="Municipio"
            name="municipio"
            defaultValue={model && model.municipio}
            labelProp="nombre"
            error={modelError.idMunicipio ? "idMunicipio" : ""}
            campoError="idMunicipio"
            onChange={municipioCambio}
            extraParams={municipiosParamsMemo}
          />
          {modelError.idMunicipio ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idMunicipio}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel>Partidos Politicos</InputLabel>
            <Select
              value={idPartidoPolitico}
              variant="outlined"
              style={{ textAlign: "left" }}
              error={Boolean(modelError.idPartidoPolitico)}
              onChange={partidosPoliticosCambio}
              label="Partidos Politicos"
            >
              {partidosPoliticos !== null
                ? partidosPoliticos.map((opt, index) => (
                    <MenuItem key={index} value={opt.idPartidoPolitico}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idPartidoPolitico ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idPartidoPolitico}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Formula"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(e) => setFormula(e.target.value)}
            helperText={modelError.formula}
            error={Boolean(modelError.formula)}
            inputProps={{
              value: formula,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSaveClicked}
                style={{ float: "right" }}
              >
                {editing ? "EDITAR" : "CREAR"} CANDIDATO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CandidatoDetail;
