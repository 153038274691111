import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { Add } from "@material-ui/icons";
import { IconButton, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";

import { useModels } from "../../hooks/useModels";

const Distritos = () => {
  const history = useHistory();
  const header = [
    {
      prop: "nombre",
      name: "Nombre del Distrito",
    },
    {
      prop: "descripcion",
      name: "Descripion",
    },
  ];

  const [nombre, setNombre] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "distrito",
    ordenar: "idDistrito.asc",
    pagina: 0,
  });

  const onSearchClicked = async (page) => {
    let params = {};
    if (nombre.length > 0) params = { ...params, buscador: nombre };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar este distrito?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        deleteModel(row.idDistrito);
        refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "distrito",
      ordenar: "idDistrito.asc",
      buscador: nombre,
      descripcion,
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="CONTROL DE DISTRITOS"
      model="partido-politico"
      history={history}
      actionButton={{
        to: "/distritos/nuevo",
        icon: <Add />,
        label: "Agregar distrito",
      }}
      links={[
        {
          to: "/distritos",
          label: "Distritos",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={5}>
            <TextField
              fullWidth
              size="small"
              label="Nombre del Distrito"
              onChange={(event) => setNombre(event.target.value)}
              variant="outlined"
              onBlur={(_) => {
                if (nombre.length > 0) {
                  const nombres = nombre.split(" ");
                  if (nombres && nombres.length > 0)
                    refreshModels(null, { nombre });
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              size="small"
              label="Descripcion"
              onChange={(event) => setDescripcion(event.target.value)}
              variant="outlined"
              onBlur={(_) => {
                if (descripcion.length > 0) {
                  const descripciones = descripcion.split(" ");
                  if (descripciones && descripciones.length > 0)
                    refreshModels(null, { descripcion });
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              fullWidth
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage !== null ? modelsPage.total : 0}
          labelRowsPerPage={"Renglones por página"}
          extraRows={[
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/distritos/detalle?idDistrito=${row.idDistrito}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Distritos;
