import React, { useEffect, useState } from "react";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import DeleteIcon from "@material-ui/icons/Delete";
import PageLayout from "../../../components/PageLayout";
import AlertDialog from "../../../components/AlertDialog";
import TablePagination from "../../../components/TablePagination";
import FullScrennLoader from "../../../components/FullScreenLoader";
import Services from "../../../services";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Card,
  CircularProgress,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";

const AdministracionGruposTrabajo = ({ history }) => {
  const [cargando, setCargando] = useState(true);
  const [consejos, setConsejos] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [cargandoGrupos, setCargandoGrupos] = useState(false);
  const [idGrupo, setIdGrupo] = useState(0);
  const [borrandoGrupo, setBorrandoGrupo] = useState(false);

  useEffect(() => {
    let montado = true;

    const obtener = async () => {
      try {
        const respuesta = await Services.get("consejo-electoral?limite=-1");

        if (montado) setConsejos(respuesta.resultado);
      } catch (error) {
        console.error(error);
      } finally {
        if (montado) setCargando(false);
      }
    };

    obtener();

    return () => (montado = false);
  }, []);

  if (cargando) return <FullScrennLoader />;

  const obtenerGrupos = async (idConsejoElectoral) => {
    try {
      setGrupos([]);
      setCargandoGrupos(true);

      const respuesta = await Services.get(
        "grupo-trabajo?idConsejoElectoral=" + idConsejoElectoral
      );

      if (respuesta.resultado) {
        setGrupos(respuesta.resultado);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCargandoGrupos(false);
    }
  };

  const borrarGrupo = async () => {
    try {
      setBorrandoGrupo(true);

      await Services.delete("grupo-trabajo/eliminar", {
        idGrupoTrabajo: idGrupo,
      });

      setGrupos(grupos.filter((g) => g.idGrupoTrabajo !== idGrupo));
    } catch (error) {
      console.error(error);
    } finally {
      setBorrandoGrupo(false);
      setIdGrupo(0);
    }
  };

  return (
    <PageLayout
      title="Administración Grupos de Trabajo"
      history={history}
      links={[
        {
          to: "/administracion-grupos-trabajo",
          icon: <GroupWorkIcon />,
          label: "Grupos-Trabajo",
        },
      ]}
    >
      <Autocomplete
        size="small"
        options={consejos}
        getOptionLabel={(option) => option.nombre}
        style={{ width: "50%" }}
        renderInput={(params) => (
          <TextField
            style={{ backgroundColor: "white" }}
            {...params}
            label="Consejos Electorales"
            variant="outlined"
          />
        )}
        onChange={async (event, newValue) => {
          if (newValue) await obtenerGrupos(newValue.idConsejoElectoral);
        }}
      />
      <br />
      {!grupos.length ? (
        <Card style={{ padding: 10 }}>
          {cargandoGrupos ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={25} style={{ textAlign: "center" }} />
            </Box>
          ) : (
            <Typography style={{ textAlign: "center" }}>
              Sin grupos...
            </Typography>
          )}
        </Card>
      ) : (
        <TablePagination
          header={[]}
          data={grupos}
          extraRows={[
            {
              prop: "numero",
              name: "Grupo",
              cell: (row) => (
                <>{"Grupo de Trabajo " + row.numeroGrupoTrabajo} </>
              ),
            },
            {
              prop: "eliminar",
              name: "Eliminar",
              cell: (row) => (
                <IconButton onClick={() => setIdGrupo(row.idGrupoTrabajo)}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              ),
            },
          ]}
        />
      )}
      <AlertDialog
        open={Boolean(idGrupo)}
        title="¿Seguro que deseas borrar este Grupo de Trabajo?"
        onClose={() => setIdGrupo(0)}
        onAccept={async () => await borrarGrupo()}
        loading={borrandoGrupo}
      />
    </PageLayout>
  );
};

export default AdministracionGruposTrabajo;
