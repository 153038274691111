import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import * as deLocale from "date-fns/locale/es/index";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import { ArrowBack, Save } from "@material-ui/icons";

import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";

import { useHttp } from "../../hooks/useHttp";
import { useModel } from "../../hooks/useModel";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import { emptyRequest, postRequest } from "../../constants/request";
import { useQuery } from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import CustomDialog from "../../components/CustomDialog";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LandscapeIcon from "@material-ui/icons/Landscape";
import FormControl from "@material-ui/core/FormControl";
import AsyncSelect from "../../components/AsyncSelect";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

function PlanillaDetail({
  match,
  history,
  dialog,
  editingEstado,
  setEstadosSearch,
}) {
  const classes = useStyles();
  const query = useQuery();
  const idPlanilla = query.get("id");
  const editing = !!idPlanilla;
  // const { showAlert } = useAlert();

  const [validating, setValidating] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState("");
  const [puesto, setPuesto] = React.useState("");
  const [contenido, setContenido] = React.useState("");
  const [municipio, setMunicipio] = React.useState("");
  const [participantePolitico, setParticipantePolitico] = React.useState("");
  const [representantes, setRepresentantes] = React.useState([]);

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "planilla",
      id: idPlanilla,
      redirectOnPost: true,
    });

  const municipioParamsMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
    }),
    []
  );

  const asyncSelectParams = React.useMemo(
    () =>
      municipio === ""
        ? null
        : {
            expand: "nombreParticipante",
            ordenar: "nombre.asc",
            idMunicipio: municipio.idMunicipio,
          },
    [municipio]
  );

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setMunicipio(model.idEstado);
      setNombre(model.nombre);
      setContenido(model.contenido);
      setParticipantePolitico(model.participantePolitico);
      // setNombreTipoActa(model.nombreParticipante);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  React.useEffect(() => {
    setParticipantePolitico("");
  }, [municipio]);

  const onSaveClicked = () => {
    setValidating(true);
    const body = {
      nombre,
      contenido,
      municipio,
      participantePolitico,
    };

    if (editing) {
      body.idPlanilla = idPlanilla;
    }

    updateModel(body);
  };

  const setNombreCallback = React.useCallback(
    (e) => {
      setNombre(e.target.value);
    },
    [setNombre]
  );

  const setContenidoCallback = React.useCallback(
    (e) => {
      setContenido(e.target.value);
    },
    [setContenido]
  );

  const setMunicipioCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setMunicipio(v);
    } else {
      setMunicipio("");
    }
  }, []);

  const setParticipantePoliticoCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setParticipantePolitico(v);
      // setNombreTipoActa(v.nombreParticipante);
    } else {
      setParticipantePolitico("");
      // setNombreTipoActa("");
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onAddClicked = () => {
    setRepresentantes((rp) => [...rp, { nombre, puesto }]);
    setNombre("");
    setPuesto("");
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="PLANILLA"
        links={[
          {
            to: "/planillas",
            icon: <LandscapeIcon />,
            label: "Planillas",
          },
          {
            to: "/planillas/nuevo",
            icon: <ListAltIcon />,
            label: "Nuevo",
          },
        ]}
        editing={editing}
        dialog={dialog}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={4}>
          <TextField
            label="Nombre"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            onChange={setNombreCallback}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AsyncAutocompleteSearch
            label="Municipio"
            name="municipio"
            labelProp="nombre"
            onChange={setMunicipioCallback}
            value={municipio}
            extraParams={municipioParamsMemo}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl className={classes.formControl} fullWidth>
            <AsyncSelect
              size="small"
              label="Participante político"
              name={municipio === "" ? null : "tipo-acta/por-municipio"}
              labelProp="nombreParticipante"
              valueProp="participantePolitico"
              onChange={setParticipantePoliticoCallback}
              extraParams={asyncSelectParams}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="text" color="secondary">
                <strong>FECHA DE RECTIFICACIÓN</strong>
              </Typography>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Nombre(s)"
                    onChange={(e) => setNombre(e.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Puesto"
                    onChange={(e) => setPuesto(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onAddClicked}
                  >
                    AGREGAR
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {representantes.map((representante) => (
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={5}>
                    {representante?.nombre}
                  </Grid>
                  <Grid item xs={5}>
                    {representante?.puesto}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSaveClicked}
              >
                {editing ? "EDITAR" : "CREAR"} PLANILLA
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <CustomDialog
          isOpen={isOpen}
          handleClose={handleClose}
          title="Planilla"
        />
      </Grid>
    </>
  );
}

/**
 * 
 * 
 *         
        

        

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            rows={6}
            multiline
            label="Contenido"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.contenido}
            error={Boolean(modelError.contenido)}
            onChange={setContenidoCallback}
            inputProps={{
              value: contenido,
            }}
          />
        </Grid>
 * 
 */

export default PlanillaDetail;
