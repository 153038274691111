const opciones = [
  {
    label: "Bodegas",
    key: "bodegas",
    value: undefined,
  },
  {
    label: "Paquetes",
    key: "paquetes",
    value: undefined,
  },
  {
    label: "Actas",
    key: "actas",
    value: undefined,
  },
  {
    label: "Reunión de Trabajo",
    key: "reunionTrabajo",
    value: undefined,
  },
  {
    label: "Sesión extraordinaria",
    key: "sesionExtraordinaria",
    value: undefined,
  },
  {
    label: "Cómputo",
    key: "computo",
    value: undefined,
  },
  {
    label: "Control de capacitación",
    key: "controlCapacitacion",
    value: undefined,
  },
  {
    label: "Estadísticos",
    key: "estadisticos",
    value: undefined,
  },
  {
    label: "Panel de control",
    key: "panelControl",
    value: undefined,
  },
];

export default opciones;
