import React from "react";
import { Button, FormHelperText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  yesButton: { backgroundColor: theme.palette.success.light, color: "#fff" },
  noButton: { backgroundColor: theme.palette.error.light, color: "#fff" },
  yesButtonOutlined: {
    borderColor: "#76777b",
    color: "#76777b",
  },
  noButtonOutlined: {
    borderColor: "#76777b",
    color: "#76777b",
  },
}));

const YesNoButton = ({
  yesText = "SÍ",
  noText = "NO",
  value,
  target,
  onChange,
  validating,
  inverted,
}) => {
  const classes = useStyles();

  return (
    <>
      <Button
        variant={value ? "contained" : "outlined"}
        onClick={() =>
          onChange((e) => ({
            ...e,
            [target]: true,
          }))
        }
        size="small"
        className={
          value
            ? inverted
              ? classes.noButton
              : classes.yesButton
            : inverted
            ? classes.noButtonOutlined
            : classes.yesButtonOutlined
        }
      >
        {yesText}
      </Button>

      <Button
        variant={value === undefined || value ? "outlined" : "contained"}
        onClick={() =>
          onChange((e) => ({
            ...e,
            [target]: false,
          }))
        }
        size="small"
        className={
          value === undefined || value
            ? inverted
              ? classes.yesButtonOutlined
              : classes.noButtonOutlined
            : inverted
            ? classes.yesButton
            : classes.noButton
        }
      >
        {noText}
      </Button>
      {validating && value === undefined && (
        <FormHelperText className={classes.tableHelperText}>
          Favor de seleccionar "SÍ" o "NO"
        </FormHelperText>
      )}
    </>
  );
};

export default YesNoButton;
