import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { Add } from "@material-ui/icons";
import { IconButton, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";

import { useModels } from "../../hooks/useModels";

const Secciones = () => {
  const history = useHistory();
  const [idDistritoFederal, setIdDistritoFederal] = useState("");
  const [idDistritoLocal, setIdDistritoLocal] = useState("");
  const [idMunicipio, setIdMunicipio] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "seccion",
    expand: "distrito,municipio",
    ordenar: "idSeccion.asc",
    pagina: 0,
  });

  const distritosParamsMemo = React.useMemo(() => ({ tipo: 1 }), []);

  const municipioParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const [modelDistrito, modelDistritoLoading] = useModels({
    name: "distrito",
    ordenar: "idDistrito.asc",
    limite: -1,
    extraParams: distritosParamsMemo,
  });

  const [
    modelDistritoLocal,
    modelDistritoLocalLoading,
    modelDistritoLocalError,
    modelDistritoLocalPage,
    modelDistritoLocalRefresh,
  ] = useModels({
    name: "distrito",
    expand: "distritosLocales",
    ordenar: "idDistrito.asc",
    limite: -1,
    extraParams: distritosParamsMemo,
  });

  const distritosFederalesCambio = (event) => {
    const { value } = event.target;
    let params = {};
    setIdDistritoFederal(value);
    params = { ...params, idDistrito: value };
    modelDistritoLocalRefresh(true, params);
  };

  const distritosLocalesCambio = (event) => {
    const { value } = event.target;
    setIdDistritoLocal(value);
  };
  const municipioCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
    } else {
      setIdMunicipio("");
    }
  }, []);

  const onSearchClicked = async (page) => {
    let params = {};
    if (idDistritoFederal.length > 0) params = { ...params, idDistritoFederal };
    if (idDistritoLocal.toString().length > 0)
      params = { ...params, idDistrito: idDistritoLocal };
    if (idMunicipio.toString().length > 0) params = { ...params, idMunicipio };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Deseas eliminar esta seccion?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        deleteModel(row.idSeccion);
        refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "seccion",
      expand: "distrito,municipio",
      ordenar: "idSeccion.asc",
      idDistrito: idDistritoLocal,
      idMunicipio,
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="CONTROL DE SECCIONES"
      model="seccion"
      history={history}
      actionButton={{
        to: "secciones/nuevo",
        icon: <Add />,
        label: "Agregar Sección",
      }}
      links={[
        {
          label: "Secciones",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "0px 0px" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Distritos Federales
              </InputLabel>
              <Select
                value={idDistritoFederal}
                style={{ textAlign: "left" }}
                onChange={distritosFederalesCambio}
                label="Distritos Federales"
              >
                {modelDistrito !== null
                  ? modelDistrito.map((opt, index) => (
                      <MenuItem key={index} value={opt.idDistrito}>
                        {opt.nombre}
                      </MenuItem>
                    ))
                  : []}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "0px 0px" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Distritos Locales
              </InputLabel>
              <Select
                disabled={!idDistritoFederal}
                value={idDistritoLocal}
                style={{ textAlign: "left" }}
                onChange={distritosLocalesCambio}
                label="Distritos Locales"
              >
                {modelDistritoLocal &&
                modelDistritoLocal[0] &&
                modelDistritoLocal[0].distritosLocales
                  ? modelDistritoLocal[0].distritosLocales.map((opt, index) => (
                      <MenuItem key={index} value={opt.idDistrito}>
                        {opt.nombre}
                      </MenuItem>
                    ))
                  : []}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <AsyncAutocompleteSearch
              label="Municipio"
              name="municipio"
              labelProp="nombre"
              onChange={municipioCambio}
              extraParams={municipioParamsMemo}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              style={{ float: "right" }}
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={[]}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage !== null ? modelsPage.total : 0}
          labelRowsPerPage={"Renglones por página"}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre de la Seccion",
              cell: (row, index) => <>{row.nombre}</>,
            },
            {
              prop: "descripcion",
              name: "Descripcion",
              cell: (row, index) => <>{row.descripcion}</>,
            },
            {
              prop: "distrito",
              name: "Distrito Local",
              cell: (row, index) => <>{row.distrito.nombre}</>,
            },
            {
              prop: "municipio",
              name: "Municipio",
              cell: (row, index) => <>{row.municipio.nombre}</>,
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/secciones/detalle?idSeccion=${row.idSeccion}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Secciones;
