import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Paper,
  FormControl,
  TextField,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  ListSubheader,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TableChart } from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LockIcon from "@material-ui/icons/Lock";
import AddIcon from "@material-ui/icons/Add";
import { emptyRequest, postRequest } from "../../constants/request";
import YesNoButton from "../../components/YesNoButton";
import AsyncCreatableAutocomplete from "../../components/AsyncCreatableAutocomplete";
import TimePicker from "../../components/TimePicker";
import PageLayout from "../../components/PageLayout";
import CreateEntryDialog from "../../components/CreateEntryDialog";
import { useModels } from "../../hooks/useModels";
import { useHttp } from "../../hooks/useHttp";
import { useQuery } from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "#cd5d23 !important",
    color: "white",
    fontWeight: "bold",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
    paddingBottom: "1%",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: "#000",
    fontWeight: "bold",
  },
  saveButtonContainer: { marginTop: theme.spacing(4) },
  icon: { fontSize: 54, color: "#fff" },
  formControl: {
    minWidth: "100%",
  },
  subtitle: { color: "#000" },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 22,
  },
  body: {
    fontSize: 18,
    height: 80,
    width: "33%",
    color: "#000",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const motivosDespuesJornada = [5, 6, 11];
const idsTipoBodega = [0, 1, 2, 3, 4, 5, 7, 9, 10, 11, 13];
const days = [...Array(31).keys()];
const date = new Date();

const AperturaBodega = () => {
  const history = useHistory();
  const classes = useStyles();
  const query = useQuery();
  const idConsejoElectoral = query.get("idConsejoElectoral");

  const [saving, setSaving] = useState(false);
  const [validating, setValidating] = useState(false);
  const [horaApertura, setHoraApertura] = useState(
    new Date().toLocaleTimeString("es-MX")
  );
  const [idMotivoAperturaCierre, setIdMotivoAperturaCierre] = useState([]);
  const [aplicoSelloPuerta, setAplicoSelloPuerta] = useState(undefined);
  const [aplicoSelloVentana, setAplicoSelloVentana] = useState(undefined);
  const [observaciones, setObservaciones] = useState("");
  const [asistentes, setAsistentes] = useState([{}]);
  const [tipo, setTipo] = useState("");
  const [saveRequest, setSaveRequest] = useState(emptyRequest);
  const [errors, setErrors] = useState([]);
  const [day, setDay] = useState(date.getDate().toString());
  const [month, setMonth] = useState("06");
  const { showAlert } = useAlert();
  const { user, userLoading } = useAuth();

  const agendaParamsMemo = useMemo(() => ({ idTipoAgenda: tipo }), [tipo]);

  const [response, responseLoading, responseError] = useHttp(saveRequest);

  const [modelsMotivoApertura] = useModels({
    name: "motivo-apertura-cierre",
    ordenar: "idMotivoAperturaCierre.desc",
  });

  const [modelsTipoAsistente] = useModels({
    name: "agenda/tipo-agenda",
    ordenar: "idTipoAsistente.desc",
  });

  const onSubmitPressed = () => {
    setValidating(true);

    if (saving) return;

    if (!idMotivoAperturaCierre) {
      setErrors(["idMotivoAperturaCierre"]);
    } else {
      setErrors([]);
    }
    if (
      !idMotivoAperturaCierre ||
      aplicoSelloPuerta === undefined ||
      aplicoSelloVentana === undefined
    ) {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    } else {
      if (!asistentes.length || asistentes.some((a) => !a.idAgenda)) {
        showAlert({
          severity: "warning",
          message: "Favor de verificar los Asistentes de apertura.",
        });
        return;
      }

      try {
        let date;

        if (day > 9) {
          date = "2021-" + month + "-" + day.toString();
        } else {
          date = "2021-" + month + "-" + ("0" + day.toString());
        }

        const body = {
          fechaApertura: date + " " + horaApertura,
          idsMotivoAperturaCierre: [idMotivoAperturaCierre],
          aplicoSelloPuerta,
          aplicoSelloVentana,
          observaciones,
          asistentes,
          idConsejoElectoral,
        };

        const request = postRequest("bodega/apertura-bodega", body);
        request.alert = true;
        setSaveRequest(request);
        if (!responseError && !responseLoading) {
          setTimeout(() => {
            history.push("/bodegas/administracion");
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
      }
    }
  };

  const onAsistentesChange = (i, e, v) => {
    setAsistentes(
      asistentes.map((a, index) =>
        index === i ? { ...a, idAgenda: v ? v.idAgenda : "" } : a
      )
    );
  };

  const StyledSmallTable = (props) => {
    const { title, value, onChange, validating } = props;

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">{title}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="center">
                <YesNoButton
                  value={value}
                  onChange={onChange}
                  validating={validating}
                />
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <PageLayout
          title="ADMINISTRACIÓN DE APERTURA DE BODEGA DEL CONSEJO"
          history={history}
          links={[
            {
              to: "/bodegas/administracion",
              icon: <AssignmentIcon fontSize="small" />,
              label: "Administración",
            },
            {
              to: "/bodegas/apertura?idConsejoElectoral=" + idConsejoElectoral,
              icon: <LockIcon fontSize="small" />,
              label: "Apertura",
            },
          ]}
        >
          <Grid
            component={Paper}
            container
            spacing={3}
            className={classes.paper}
            style={{ marginTop: 10 }}
          >
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    Fecha y hora de Apertura
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <Select
                          value={month}
                          onChange={(event) => setMonth(event.target.value)}
                        >
                          <MenuItem value={"05"}>Mayo</MenuItem>
                          <MenuItem value={"06"}>Junio</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <Select
                          value={day}
                          onChange={(event) => setDay(event.target.value)}
                        >
                          {days
                            .filter((d) => {
                              if (d == 30 && month === "06") {
                                return false;
                              } else {
                                return true;
                              }
                            })
                            .map((m) => (
                              <MenuItem key={m} value={m + 1}>
                                {m + 1}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TimePicker
                    label="Hora de apertura"
                    value={horaApertura}
                    onChange={setHoraApertura}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                size="small"
                style={{ margin: "0px 0px" }}
                error={errors.includes("idMotivoAperturaCierre")}
              >
                <InputLabel>Motivo de Apertura</InputLabel>
                <Select
                  value={idMotivoAperturaCierre}
                  style={{ textAlign: "left" }}
                  variant="outlined"
                  onChange={(event) =>
                    setIdMotivoAperturaCierre(event.target.value)
                  }
                  label="Motivo de Apertura"
                >
                  {user?.consejoElectoral?.idTipoConsejoElectoral === 2 && (
                    <ListSubheader>ANTES DE LA JORNADA ELECTORAL</ListSubheader>
                  )}
                  {user?.consejoElectoral?.idTipoConsejoElectoral === 2 &&
                  modelsMotivoApertura?.length
                    ? modelsMotivoApertura
                        .sort((a, b) => (a.motivo < b.motivo ? 1 : -1))
                        .filter(
                          (m) =>
                            !motivosDespuesJornada.includes(
                              m.idMotivoAperturaCierre
                            )
                        )
                        .map((opt, index) => (
                          <MenuItem
                            classes={{ selected: classes.selected }}
                            key={index}
                            value={opt.idMotivoAperturaCierre}
                          >
                            {opt.motivo}
                          </MenuItem>
                        ))
                    : null}
                  <ListSubheader>DESPUÉS DE LA JORNADA ELECTORAL</ListSubheader>
                  {modelsMotivoApertura?.length
                    ? modelsMotivoApertura
                        .sort((a, b) => (a.motivo > b.motivo ? 1 : -1))
                        .filter((m) =>
                          motivosDespuesJornada.includes(
                            m.idMotivoAperturaCierre
                          )
                        )
                        .map((opt, index) => (
                          <MenuItem
                            classes={{ selected: classes.selected }}
                            key={index}
                            value={opt.idMotivoAperturaCierre}
                          >
                            {opt.motivo}
                          </MenuItem>
                        ))
                    : []}
                </Select>
                <FormHelperText>Favor de seleccionar un motivo</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <StyledSmallTable
                title="¿Tenía sello en la puerta?"
                value={aplicoSelloPuerta}
                onChange={setAplicoSelloPuerta}
                validating={validating}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledSmallTable
                title="¿Tenía sello en la ventana?"
                value={aplicoSelloVentana}
                onChange={setAplicoSelloVentana}
                validating={validating}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                error={validating && observaciones === ""}
              >
                <TextField
                  fullWidth
                  label="Escribe tus observaciones"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={observaciones}
                  onChange={(e) => {
                    setObservaciones(e.currentTarget.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.subtitle}>
                <TableChart color="secondary" /> ASISTENTES EN LA APERTURA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Tipo</StyledTableCell>
                      <StyledTableCell align="center">
                        Asistente
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => {
                            setAsistentes((v) => [
                              ...v,
                              { idTipoAgenda: "", idAgenda: "" },
                            ]);
                          }}
                          endIcon={<AddIcon />}
                        >
                          <Typography>AGREGAR ASISTENTE</Typography>
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {asistentes.map((e, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                            size="small"
                            style={{ margin: "0px 0px" }}
                          >
                            <InputLabel>Tipo Asistente</InputLabel>
                            <Select
                              style={{ textAlign: "left" }}
                              variant="outlined"
                              value={
                                asistentes[i].idTipoAgenda
                                  ? {
                                      id: asistentes[i].idTipoAgenda,
                                      nombre: asistentes[i].tipoAgenda,
                                    }
                                  : ""
                              }
                              renderValue={(val) => val.nombre}
                              onChange={(e, v) => {
                                setAsistentes(
                                  asistentes.map((a, index) =>
                                    index === i
                                      ? {
                                          ...a,
                                          idTipoAgenda: v.props.value.id,
                                          tipoAgenda: v.props.value.nombre,
                                        }
                                      : a
                                  )
                                );
                              }}
                              label="Tipo Asistente"
                            >
                              {modelsTipoAsistente
                                ? modelsTipoAsistente
                                    .filter(
                                      (a) => !idsTipoBodega.includes(a.id)
                                    )
                                    .map((opt) => (
                                      <MenuItem
                                        key={opt.id}
                                        value={opt}
                                        onClick={() => setTipo(opt.id)}
                                      >
                                        {opt.nombre}
                                      </MenuItem>
                                    ))
                                : []}
                            </Select>
                          </FormControl>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <AsyncCreatableAutocomplete
                            disabled={!asistentes[i].idTipoAgenda}
                            label="Asistentes"
                            name="agenda"
                            labelProp="nombreCompleto"
                            onChange={onAsistentesChange}
                            extraParams={agendaParamsMemo}
                            index={i}
                            clearInput={asistentes[i].idTipoAgenda}
                            dialogForm={CreateEntryDialog}
                            defaultValue={
                              asistentes[i].entry ? asistentes[i].entry : null
                            }
                            dialogProps={{
                              setArr: setAsistentes,
                              idConsejoElectoral,
                              ignoreIds: [
                                0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 13,
                              ],
                              tipoAgenda: {
                                idTipoAgenda: asistentes[i].idTipoAgenda,
                                tipoAgenda: asistentes[i].tipoAgenda,
                              },
                            }}
                            propId="idAgenda"
                            ignoreIds={asistentes
                              .filter((a) => a.idAgenda)
                              .map((a) => a.idAgenda)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setAsistentes((v) =>
                                v.filter((e, index) => index !== i)
                              );
                            }}
                          >
                            <Typography>QUITAR ASISTENTE</Typography>
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.saveButtonContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ float: "right" }}
                  onClick={onSubmitPressed}
                >
                  {saving ? (
                    <CircularProgress style={{ color: "white" }} size={25} />
                  ) : (
                    "GUARDAR"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </PageLayout>
      </div>
    </>
  );
};

export default AperturaBodega;
