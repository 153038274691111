import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import * as deLocale from "date-fns/locale/es/index";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  FormControl,
} from "@material-ui/core";

import { ArrowBack, Save } from "@material-ui/icons";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";
import InputLabel from "@material-ui/core/InputLabel";
import { useHttp } from "../../hooks/useHttp";
import { useModel } from "../../hooks/useModel";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import { emptyRequest, postRequest } from "../../constants/request";
import { useQuery } from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import CustomDialog from "../../components/CustomDialog";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LandscapeIcon from "@material-ui/icons/Landscape";
import { useModels } from "../../hooks/useModels";
import RegexTextField from "../../components/regexTextField";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import { emphasize, withStyles } from "@material-ui/core/styles";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 56px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const onlyAlphanumericRegex = /[^a-z0-9]/gi;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

function CasillaDetail({
  match,
  history,
  dialog,
  editingCasilla,
  setCasillaSearch,
}) {
  const classes = useStyles();
  const query = useQuery();
  const idCasilla = query.get("id");
  const editing = !!idCasilla;
  const { showAlert } = useAlert();

  const [validating, setValidating] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(true);
  const [clearDistrito, setClearDistrito] = React.useState(false);
  const [idTipoCasilla, setIdTipoCasilla] = React.useState("");
  const [tipoCasilla, setTipoCasilla] = React.useState("");
  const [idDistrito, setIdDistrito] = React.useState("");

  const [idSeccion, setIdSeccion] = React.useState("");
  const [tipoSeccion, setTipoSeccion] = React.useState("");

  const [casilla, setCasilla] = React.useState("");
  const [casillaCorta, setCasillaCorta] = React.useState("");
  const [padron, setPadron] = React.useState("");
  const [listaNominal, setListaNominal] = React.useState("");
  const [ubicacion, setUbicacion] = React.useState("");
  const [referencia, setReferencia] = React.useState("");
  const [activo, setActivo] = React.useState(1);
  const [idMunicipio, setIdMunicipio] = useState("");
  const [numeroContigua, setNumeroContigua] = React.useState("");
  const [numeroExtraordinaria, setnumeroExtraordinaria] = React.useState("");
  const [numeroEspecial, setNumeroEspecial] = React.useState("");
  const [bCasillaInstalada, setBCasillaInstalada] = React.useState("");
  const [municipio, setMunicipio] = useState("");
  const [seccion, setSeccion] = useState("");
  const [domicilio, setDomicilio] = React.useState("");
  const [anuencia, setAnuencia] = React.useState("");
  const [localidadManzana, setLocalidadManzana] = React.useState("");
  const [numeroBoletasRecibidas, setNumeroBoletasRecibidas] = React.useState(
    ""
  );
  const [idTipoDomicilio, setIdTipoDomicilio] = React.useState("");
  const [claveINE, setClaveINE] = React.useState("");
  const [grupo, setGrupo] = React.useState("");
  const [ordenCasilla, setOrdenCasilla] = React.useState("");
  const [
    numeroBoletasRepresentantes,
    setNumeroBoletasRepresentantes,
  ] = React.useState("");

  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);

  const distritosParamsMemo = React.useMemo(
    () => ({
      tipo: 2,
      ordenar: "nombre.asc",
    }),
    []
  );

  const [modelSeccion, modelSeccionLoading] = useModels({
    name: "seccion",
    expand: "",
    ordenar: "nombre.asc",
    limite: -1,
  });

  const [
    modelMunicipio,
    modelMunicipiosLoading,
    modelMunicipiosLError,
  ] = useModels({
    name: "municipio",
    expand: "",
    ordenar: "nombre.asc",
    limite: -1,
  });

  const [modelDomicilio, modelDomicilioLoading] = useModels({
    name: "tipo-domicilio",
    expand: "",
    ordenar: "nombre.asc",
    limite: -1,
  });

  const [municipios, setMunicipios] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [distritosFederales, setDistritosFederales] = useState([]);
  const [distritosLocales, setDistritosLocales] = useState([]);

  const [modelTipoCasilla] = useModels({
    name: "tipo-casilla",
    expand: "",
    ordenar: "nombre.desc",
  });

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "casilla",
    expand: "seccion,distrito,municipio,tipoCasilla",
    id: idCasilla,
    redirectOnPost: true,
  });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setCasilla(model.casilla);
      setIdDistrito(model.idDistrito);
      setIdTipoCasilla(model.idTipoCasilla);
      setTipoCasilla(model.tipoCasilla);
      setLocalidadManzana(model.localidadManzana);
      setTipoSeccion(model.tipoSeccion);
      setCasillaCorta(model.casillaCorta);
      setPadron(model.padron);
      setListaNominal(model.listaNominal);
      setUbicacion(model.ubicacion);
      setReferencia(model.referencia);
      setActivo(model.activo);
      setNumeroContigua(model.numeroContigua);
      setnumeroExtraordinaria(model.numeroExtraordinaria);
      setNumeroEspecial(model.numeroEspecial);
      setBCasillaInstalada(model.bCasillaInstalada);
      setDomicilio(model.domicilio);
      setAnuencia(model.anuencia);
      setNumeroBoletasRecibidas(model.numeroBoletasRecibidas);
      setIdTipoDomicilio(model.idTipoDomicilio);
      setClaveINE(model.claveINE);
      setGrupo(model.grupo);
      setOrdenCasilla(model.ordenCasilla);
      setNumeroBoletasRepresentantes(model.numeroBoletasRepresentantes);
      setIdMunicipio(model.idMunicipio);
      setMunicipio(model.municipio);
      setIdSeccion(model.idSeccion);
      setSeccion(model.seccion);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSaveClicked = () => {
    setValidating(true);
    const body = {
      idTipoCasilla,
      tipoCasilla,
      idDistrito,
      idSeccion,
      casilla,
      casillaCorta,
      padron,
      listaNominal,
      ubicacion,
      referencia,
      activo,
      numeroContigua,
      numeroExtraordinaria,
      numeroEspecial,
      bCasillaInstalada,
      domicilio,
      anuencia,
      numeroBoletasRecibidas,
      claveINE,
      grupo,
      ordenCasilla,
      numeroBoletasRepresentantes,
      idMunicipio,
      tipoSeccion,
      localidadManzana,
      idTipoDomicilio,
    };

    if (editing) {
      body.idCasilla = idCasilla;
    }

    updateModel(body);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const setIdDistritoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdDistrito(v.idDistrito);
      } else {
        setIdDistrito("");
      }
    },
    [setIdDistrito]
  );

  const tipoDomicilioSelectChange = (e) => {
    const { value } = e.target;
    setIdTipoDomicilio(value);
  };

  const tipoCasillaSelectChange = (e) => {
    const { value } = e.target;
    setIdTipoCasilla(value);
    const obj = modelTipoCasilla.filter((e) => e.idTipoCasilla == value);
    setTipoCasilla(obj[0].nombre);
  };

  const handleActiveChange = async (event) => {
    let val = event.target.value;
    setActivo(val);
  };

  React.useEffect(() => {
    if (!modelMunicipiosLoading && modelMunicipio) {
      setMunicipios(modelMunicipio);
    }
  }, [modelMunicipio, modelMunicipiosLoading]);

  React.useEffect(() => {
    if ((modelSeccion, !modelSeccionLoading)) {
      setSecciones(modelSeccion);
    }
  }, [modelSeccion, modelSeccionLoading]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CASILLA"
        links={[
          {
            to: "/casillas",
            icon: <AllInboxIcon />,
            label: "Casillas",
          },
          {
            to: "/casillas/nuevo",
            icon: <ListAltIcon />,
            label: "Nuevo",
          },
        ]}
        editing={editing}
        dialog={dialog}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <CustomDialog
          isOpen={isOpen}
          handleClose={handleClose}
          title="Casilla"
        ></CustomDialog>
        <Grid item xs={12} sm={12} md={3}>
          <AsyncAutocompleteSearch
            label="Distrito"
            name="distrito"
            defaultValue={model && model.distrito}
            labelProp="descripcion"
            onChange={setIdDistritoCallback}
            clearInput={clearDistrito}
            extraParams={distritosParamsMemo}
            error={modelError.idDistrito ? "idDistrito" : ""}
            campoError="idDistrito"
          />
          {modelError.idDistrito ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idDistrito}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl} fullWidth size="small">
            <Autocomplete
              size="small"
              error={Boolean(modelError.idMunicipio)}
              value={municipio}
              onChange={(event, newValue) => {
                if (newValue) {
                  setMunicipio(newValue);
                  setIdMunicipio(newValue.idMunicipio);
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.idMunicipio}
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              options={municipios.filter((e) => e.idEstado == "26")}
              getOptionLabel={(munici) => (munici ? munici.nombre : "")}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Municipios"
                  variant="outlined"
                  size="small"
                />
              )}
            />
            {modelError.idMunicipio ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idMunicipio}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl} fullWidth size="small">
            <Autocomplete
              size="small"
              error={Boolean(modelError.idSeccion)}
              value={seccion}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSeccion(newValue);
                  setIdSeccion(newValue.idSeccion);
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.idSeccion}
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              options={secciones}
              getOptionLabel={(secc) => (secc ? secc.nombre : "")}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Secciones"
                  variant="outlined"
                  size="small"
                />
              )}
            />
            {modelError.idSeccion ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idSeccion}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Tipo Sección"
            size="small"
            required
            fullWidth
            onChange={(e) => setTipoSeccion(e.target.value)}
            helperText={modelError.tipoSeccion}
            error={Boolean(modelError.tipoSeccion)}
            inputProps={{
              value: tipoSeccion,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Padrón"
            size="small"
            required
            fullWidth
            onChange={(e) => setPadron(e.target.value)}
            helperText={modelError.padron}
            error={Boolean(modelError.padron)}
            inputProps={{
              value: padron,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Lista Nominal"
            size="small"
            required
            fullWidth
            onChange={(e) => setListaNominal(e.target.value)}
            helperText={modelError.listaNominal}
            error={Boolean(modelError.listaNominal)}
            inputProps={{
              value: listaNominal,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Casilla"
            size="small"
            required
            fullWidth
            onChange={(e) => setCasilla(e.target.value)}
            helperText={modelError.casilla}
            error={Boolean(modelError.casilla)}
            inputProps={{
              value: casilla,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
            fullWidth
            style={{}}
          >
            <InputLabel>Tipo Casilla</InputLabel>
            <Select
              variant="outlined"
              value={idTipoCasilla}
              style={{ textAlign: "left" }}
              onChange={tipoCasillaSelectChange}
              error={Boolean(modelError.idTipoCasilla)}
            >
              {modelTipoCasilla !== null
                ? modelTipoCasilla.map((opt, index) => (
                    <MenuItem key={index} value={opt.idTipoCasilla}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idTipoCasilla ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idTipoCasilla}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Casilla Corta"
            size="small"
            required
            fullWidth
            onChange={(e) => setCasillaCorta(e.target.value)}
            helperText={modelError.casillaCorta}
            error={Boolean(modelError.casillaCorta)}
            inputProps={{
              value: casillaCorta,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Domicilio"
            size="small"
            required
            fullWidth
            onChange={(e) => setDomicilio(e.target.value)}
            helperText={modelError.domicilio}
            error={Boolean(modelError.domicilio)}
            inputProps={{
              value: domicilio,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Localidad Manzana"
            size="small"
            required
            fullWidth
            onChange={(e) => setLocalidadManzana(e.target.value)}
            helperText={modelError.localidadManzana}
            error={Boolean(modelError.localidadManzana)}
            inputProps={{
              value: localidadManzana,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Ubicación"
            size="small"
            required
            fullWidth
            onChange={(e) => setUbicacion(e.target.value)}
            helperText={modelError.ubicacion}
            error={Boolean(modelError.ubicacion)}
            inputProps={{
              value: ubicacion,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Referencia"
            size="small"
            required
            fullWidth
            onChange={(e) => setReferencia(e.target.value)}
            helperText={modelError.referencia}
            error={Boolean(modelError.referencia)}
            inputProps={{
              value: referencia,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
            fullWidth
            style={{}}
          >
            <InputLabel>Tipo Domicilio</InputLabel>
            <Select
              variant="outlined"
              value={idTipoDomicilio}
              style={{ textAlign: "left" }}
              onChange={tipoDomicilioSelectChange}
              error={Boolean(modelError.idTipoDomicilio)}
            >
              {modelDomicilio !== null
                ? modelDomicilio.map((opt, index) => (
                    <MenuItem key={index} value={opt.idTipoDomicilio}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idTipoDomicilio ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idTipoDomicilio}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <NativeSelect
            variant="outlined"
            size="small"
            style={{ marginTop: 8 }}
            fullWidth
            value={activo}
            onChange={async (e) => await handleActiveChange(e)}
            input={<BootstrapInput />}
          >
            <option value={1}>Activo</option>
            <option value={0}>Inactivo</option>
          </NativeSelect>
        </Grid>

        <Grid item xs={12} sm={3}>
          <RegexTextField
            label="Número Contigua"
            regex={onlyAlphanumericRegex}
            style={{ top: 5 }}
            placeholder="N23Y"
            size="small"
            value={numeroContigua}
            helperText={modelError.numeroContigua}
            error={Boolean(modelError.numeroContigua)}
            onChange={(e) => setNumeroContigua(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <RegexTextField
            label="Número Extraordinario"
            regex={onlyAlphanumericRegex}
            style={{ top: 5 }}
            placeholder="A02R"
            size="small"
            value={numeroExtraordinaria}
            helperText={modelError.numeroExtraordinaria}
            error={Boolean(modelError.numeroExtraordinaria)}
            onChange={(e) => setnumeroExtraordinaria(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Número Especial"
            size="small"
            required
            fullWidth
            onChange={(e) => setNumeroEspecial(e.target.value)}
            helperText={modelError.numeroEspecial}
            error={Boolean(modelError.numeroEspecial)}
            inputProps={{
              value: numeroEspecial,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="B Casilla Instalada"
            size="small"
            required
            fullWidth
            onChange={(e) => setBCasillaInstalada(e.target.value)}
            helperText={modelError.bCasillaInstalada}
            error={Boolean(modelError.bCasillaInstalada)}
            inputProps={{
              value: bCasillaInstalada,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Anuencia"
            size="small"
            required
            fullWidth
            onChange={(e) => setAnuencia(e.target.value)}
            helperText={modelError.anuencia}
            error={Boolean(modelError.anuencia)}
            inputProps={{
              value: anuencia,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Numero Boletas Recibidas"
            size="small"
            required
            fullWidth
            onChange={(e) => setNumeroBoletasRecibidas(e.target.value)}
            helperText={modelError.numeroBoletasRecibidas}
            error={Boolean(modelError.numeroBoletasRecibidas)}
            inputProps={{
              value: numeroBoletasRecibidas,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Clave INE"
            size="small"
            required
            fullWidth
            onChange={(e) => setClaveINE(e.target.value)}
            helperText={modelError.claveINE}
            error={Boolean(modelError.claveINE)}
            inputProps={{
              value: claveINE,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Grupo"
            size="small"
            required
            fullWidth
            onChange={(e) => setGrupo(e.target.value)}
            helperText={modelError.grupo}
            error={Boolean(modelError.grupo)}
            inputProps={{
              value: grupo,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Orden Casilla"
            size="small"
            required
            fullWidth
            onChange={(e) => setOrdenCasilla(e.target.value)}
            helperText={modelError.ordenCasilla}
            error={Boolean(modelError.ordenCasilla)}
            inputProps={{
              value: ordenCasilla,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="outlined"
            label="Número Boletas Representantes"
            size="small"
            required
            fullWidth
            onChange={(e) => setNumeroBoletasRepresentantes(e.target.value)}
            helperText={modelError.numeroBoletasRepresentantes}
            error={Boolean(modelError.numeroBoletasRepresentantes)}
            inputProps={{
              value: numeroBoletasRepresentantes,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSaveClicked}
              >
                {editing ? "EDITAR" : "CREAR"} CASILLA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CasillaDetail;
