import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useModel } from "../../hooks/useModel";
import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import LandscapeIcon from "@material-ui/icons/Landscape";
import CircularProgress from "@material-ui/core/CircularProgress";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";

const TiposCasilla = ({
  dialog,
  setEditingTiposCasilla,
  setSelectedTiposCasilla,
  handleClose,
}) => {
  const history = useHistory();
  const header = [
    {
      prop: "nombre",
      name: "Nombre",
    },
  ];

  const [nombre, setNombre] = useState("");
  const [clave, setClave] = useState("");
  const [claveINE, setClaveINE] = useState("");
  const [grupo, setGrupo] = useState("");
  const [orden, setOrden] = useState("");
  const [claveCompleta, setClaveCompleta] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "tipo-casilla",
    ordenar: "idTipoCasilla.desc",
  });

  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    if (clave.length > 0) params = { ...params, clave };
    if (claveINE.length > 0) params = { ...params, claveINE };
    if (grupo.length > 0) params = { ...params, grupo };
    if (orden.length > 0) params = { ...params, orden };
    if (claveCompleta.length > 0) params = { ...params, claveCompleta };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar este tipo casilla?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        await deleteModel(row.idTipoCasilla);
        await refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "tipo-casilla",
      ordenar: "idTipoCasilla.desc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      nombre,
      clave,
      claveINE,
      grupo,
      orden,
      claveCompleta,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="TIPOS CASILLA"
      actionButton={{
        to: "tipos-casilla/nuevo",
        icon: <AddIcon />,
        label: "Nuevo Tipo Casilla",
      }}
      links={[
        {
          to: "/tipos-casilla",
          icon: <DynamicFeedIcon />,
          label: "Tipos Casilla",
        },
      ]}
      history={history}
      SearchComponents={
        <>
          {" "}
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                size="small"
                label="Nombre"
                onChange={(event) => setNombre(event.target.value)}
                variant="outlined"
                onBlur={(_) => {
                  if (nombre.length > 0) {
                    const nombres = nombre.split(" ");
                    if (nombres && nombres.length > 0)
                      refreshModels(null, { nombre });
                  }
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                fullWidth
                size="small"
                label="Clave"
                onChange={(event) => setClave(event.target.value)}
                variant="outlined"
                onBlur={(_) => {
                  if (clave.length > 0) {
                    const claves = clave.split(" ");
                    if (claves && claves.length > 0)
                      refreshModels(null, { clave });
                  }
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                fullWidth
                label="Clave INE"
                onChange={(event) => setClaveINE(event.target.value)}
                variant="outlined"
                onBlur={(_) => {
                  if (claveINE.length > 0) {
                    const claveINEs = claveINE.split(" ");
                    if (claveINEs && claveINEs.length > 0)
                      refreshModels(null, { claveINE });
                  }
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                fullWidth
                label="Grupo"
                onChange={(event) => setGrupo(event.target.value)}
                variant="outlined"
                onBlur={(_) => {
                  if (grupo.length > 0) {
                    const grupos = grupo.split(" ");
                    if (grupos && grupos.length > 0)
                      refreshModels(null, { grupo });
                  }
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                fullWidth
                label="orden"
                type="number"
                size="small"
                onChange={(event) => setOrden(event.target.value)}
                variant="outlined"
                onBlur={(_) => {
                  if (orden.length > 0) {
                    const ordens = orden.split(" ");
                    if (ordens && ordens.length > 0)
                      refreshModels(null, { orden });
                  }
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                size="small"
                label="Clave Completa"
                onChange={(event) => setClaveCompleta(event.target.value)}
                variant="outlined"
                onBlur={(_) => {
                  if (claveCompleta.length > 0) {
                    const claveCompletas = claveCompleta.split(" ");
                    if (claveCompletas && claveCompletas.length > 0)
                      refreshModels(null, { claveCompleta });
                  }
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSearchClicked}
                disabled={modelsLoading}
                fullWidth
                startIcon={<SearchIcon />}
              >
                {modelsLoading ? (
                  <CircularProgress
                    size={25}
                    style={{
                      marginRight: 13,
                      marginLeft: 13,
                      color: "white",
                    }}
                  />
                ) : (
                  "Buscar"
                )}
              </Button>
            </Grid>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "clave",
              name: "Clave",
              cell: (row) => <>{row.clave ? row.clave : "Sin clave"}</>,
            },
            {
              prop: "claveINE",
              name: "Clave INE",
              cell: (row) => (
                <>{row.claveINE ? row.claveINE : "Sin clave INE"}</>
              ),
            },
            {
              prop: "grupo",
              name: "Grupo",
              cell: (row) => <>{row.grupo ? row.grupo : "Sin grupo"}</>,
            },
            {
              prop: "orden",
              name: "Orden",
              cell: (row) => <>{row.orden ? row.orden : "Sin orden"}</>,
            },
            {
              prop: "claveCompleta",
              name: "Clave Completa",
              cell: (row) => (
                <>
                  {row.claveCompleta ? row.claveCompleta : "Sin clave completa"}
                </>
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/tipos-casilla/detalle?id=${row.idTipoCasilla}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default TiposCasilla;
