import React, { useEffect } from "react";
import { Button, Grid, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import { generateDefaultChartOptions } from "../../utils";
import PageLayout from "../../components/PageLayout";
import FullScreenLoader from "../../components/FullScreenLoader";
import { useModels } from "../../hooks/useModels";
import Services from '../../services';
import { useAuth } from "../../hooks/useAuth";
import GetAppIcon from "@material-ui/icons/GetApp";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const height = [null, "100%", "300%"];
const tiposEleccion = [1, 3, 2];

const InformeRecuentoDetail = ({ history }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [value, setValue] = React.useState(0);
  const [idTipoConsejoElectoral, setIdTipoConsejoElectoral] = React.useState(1);
  const [chartHeight, setChartHeight] = React.useState(null);

  let chartOptions = generateDefaultChartOptions("column", {
    title: "Recuento de Paquetes",
    subtitle: " ",
    inverted: true,
    chartHeight: chartHeight,
  });

  const extraParams = React.useMemo(
    () => ({
      idTipoConsejoElectoral: idTipoConsejoElectoral,
    }),
    [idTipoConsejoElectoral]
  );

  const [datos, datosLoading] = useModels({
    name: "recuento/informe",
    extraParams,
  });

  const detalle = datos && datos.length > 0 ? datos[0]["detalle"] : [];
  const recuento = datos && datos.length > 0 ? datos[0]["recuento"] : [];
  const total = datos && datos.length > 0 ? datos[0]["total"] : [];

  const extraOptions = {
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0,
        pointWidth: 40,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      headerFormat:
        '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} Actas</b><br/>',
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -10,
        allowOverlap: true,
        style: {
          wordBreak: "break-all",
          textOverflow: "allow",
        },
      },
      categories: function () {
        var value = this.value,
          output;

        detalle.forEach(function (consejo) {
          if (consejo.name === value) {
            output = consejo.name;
          }
        });

        return output;
      },
    },
    yAxis: [
      {
        title: {
          text: "Actas Totales y a Recuento",
        },
        showFirstLabel: false,
      },
    ],
    series: [
      {
        color: "rgb(158, 159, 163)",
        pointPlacement: -0.2,
        linkedTo: "main",
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              console.log(this.options);
              history.push(
                `/estadisticos/informe-recuento/detalle?id=${this.options.id}`
              );
              //call function passing this values as arguments
            },
          },
        },
        data: getData(total),
        name: "Total",
      },
      {
        name: "Recuento",
        id: "main",
        dataSorting: {
          enabled: true,
          matchByName: true,
        },
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: "16px",
            },
          },
        ],
        color: "#a13800",
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              history.push(
                `/estadisticos/informe-recuento/detalle?id=${this.options.id}`
              );
              //call function passing this values as arguments
            },
          },
        },
        data: getData(recuento),
      },
    ],
    exporting: {
      allowHTML: true,
    },
  };

  chartOptions = {
    ...chartOptions,
    ...extraOptions,
  };

  function getData(data) {
    return data.map(function (consejo, i) {
      return {
        name: consejo[0],
        y: consejo[1],
        id: detalle[i][1],
      };
    });
  }

  const downloadExcel = async () => {
    try {
      await Services.downloadBlob("excel/informe-recuento", {idTipoConsejoElectoral : idTipoConsejoElectoral, consejoElectoral: user.consejoElectoral.nombre }, "InformeRecuento.xlsx")
    } catch (error) {
      console.log(error);      
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIdTipoConsejoElectoral(tiposEleccion[newValue]);
    setChartHeight(height[newValue]);
  };

  if (!user || datosLoading || !datos || datos === [] || datos.length === 0)
    return <FullScreenLoader />;

  return (
    <PageLayout
      history={history}
      title="INFORME DE RECUENTO "
      model="partidos-politicos"
      links={[
        {
          label: "Informe Recuento",
        },
      ]}
    >
      <Button
        style={{ backgroundColor: "#4caf50", color: "white", float: "right" }}
        variant="contained"
        onClick={downloadExcel}
        startIcon={<GetAppIcon />}
      >
        Excel
      </Button>
      <br />
      <br />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Gubernatura" />
            <Tab label="Diputaciones" />
            <Tab label="Ayuntamiento" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default InformeRecuentoDetail;
