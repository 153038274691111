import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import DatePicker from "../../../components/DatePicker";

const DialogReprecentante = ({
  open,
  onClose,
  onAccept,
  participantesPolitico = [],
}) => {
  const [representante, setRepresentante] = useState({
    claveElector: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombre: "",

    fechaAcreditacion: new Date(),
    idParticipantePolitico: "",
    participantePolitico: "",
  });
  const [errors, setErrors] = useState([]);

  React.useEffect(() => {
    setRepresentante((r) => ({
      ...r,
      nombreCompleto: `${representante.nombre} ${representante.apellidoPaterno} ${representante.apellidoMaterno}`,
    }));
  }, [
    representante.nombre,
    representante.apellidoPaterno,
    representante.apellidoMaterno,
  ]);

  const handleAccept = () => {
    let _errors = [];

    for (const key in representante) {
      if (
        !representante[key] &&
        key !== "claveElector" &&
        key !== "participantePolitico"
      ) {
        _errors.push(key);
      }
    }

    const regexCE = new RegExp("/[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}/g");

    /*   if(!regexCE.test(representante.claveElector)) {
      _errors.push("claveElector");
    } */

    setErrors(_errors);

    if (_errors.length) return;

    onAccept(representante);
    setRepresentante({
      claveElector: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      nombre: "",
      fechaAcreditacion: new Date(),
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>AGREGAR NUEVO REPRESENTANTE</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6} sm={6} xl={6}>
            <TextField
              error={errors.includes("claveElector")}
              helperText="Favor de escribir la Clave elector válida."
              variant="outlined"
              size="small"
              fullWidth
              label="Clave elector"
              onChange={(event) =>
                setRepresentante({
                  ...representante,
                  claveElector: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6} xl={6}>
            <TextField
              error={errors.includes("apellidoPaterno")}
              helperText="Favor de escribir el Apellido Paterno."
              variant="outlined"
              size="small"
              fullWidth
              label="Apellido paterno"
              onChange={(event) =>
                setRepresentante({
                  ...representante,
                  apellidoPaterno: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6} xl={6}>
            <TextField
              error={errors.includes("apellidoMaterno")}
              helperText="Favor de escribir el Apellido Materno."
              variant="outlined"
              size="small"
              fullWidth
              label="Apellido materno"
              onChange={(event) =>
                setRepresentante({
                  ...representante,
                  apellidoMaterno: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6} xl={6}>
            <TextField
              error={errors.includes("nombre")}
              helperText="Favor de escribir el Nombre(s)."
              variant="outlined"
              size="small"
              fullWidth
              label="Nombres"
              onChange={(event) =>
                setRepresentante({
                  ...representante,
                  nombre: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6} xl={6}>
            <DatePicker
              disableFuture={false}
              label="Fecha acreditación"
              value={representante.fechaAcreditacion}
              onChange={(value) =>
                setRepresentante({ ...representante, fechaAcreditacion: value })
              }
            />
          </Grid>
          <Grid item md={6} xs={6} sm={6} xl={6}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={errors.includes("idParticipantePolitico")}
            >
              <InputLabel>Participante Político</InputLabel>
              <Select
                value={representante.idParticipantePolitico}
                onChange={(event) =>
                  setRepresentante({
                    ...representante,
                    idParticipantePolitico: event.target.value,
                    participantePolitico: participantesPolitico.find(
                      (p) => p.idParticipantePolitico === event.target.value
                    ).nombre,
                  })
                }
                label="Participante Politico"
              >
                {participantesPolitico.map((p, i) => (
                  <MenuItem key={i} value={p.idParticipantePolitico}>
                    {p.nombre}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Favor de seleccionar un Participante Político.
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setRepresentante({
              claveElector: "",
              apellidoPaterno: "",
              apellidoMaterno: "",
              nombre: "",
              fechaAcreditacion: new Date(),
            });
            onClose();
          }}
          variant="contained"
          size="small"
        >
          CANCELAR
        </Button>
        <Button
          onClick={handleAccept}
          color="secondary"
          variant="contained"
          size="small"
        >
          GUARDAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogReprecentante;
