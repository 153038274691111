import React from "react";
import { Breadcrumbs, Chip } from "@material-ui/core";
import { emphasize, withStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const UrlBreadcrumb = ({ history, links = [], ...props }) => {
  return (
    <Breadcrumbs>
      {[
        { label: "Inicio", icon: <HomeIcon fontSize="small" />, to: "/" },
        ...links,
      ].map((link, index) => {
        // const Icon = (props) => ({ ...link.icon, fontSize: "small" });
        return (
          <StyledBreadcrumb
            key={index}
            label={link.label}
            icon={link.icon}
            onClick={() => {
              history.push(link.to ? link.to : "#");
            }}
          />
        );
      })}
    </Breadcrumbs>
  );
};

export default UrlBreadcrumb;
