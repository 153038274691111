const tipoVotos = [
  {
    nombre: "CANDIDATO NO REGISTRADO",
    logo: "",
    resultado: "0",
  },
  {
    nombre: "VOTO NULO",
    logo: "",
    resultado: "0",
  },
  {
    nombre: "TOTAL DE VOTOS",
    logo: "",
    resultado: "0",
  },
];

const modeloAgendaFuncionario = {
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  cargo: "",
  telefono: "",
  correo: "",
};

const funcionariosSeleccionar = [
  {
    nombre: "ABBY ISISELA VILLA BALTIERREZ",
    ApellidoPaterno: "",
    ApellidoMaterno: "",
    cargo: "",
  },
  {
    nombre: "ABELARDO MEZA VARELA",
    ApellidoPaterno: "",
    ApellidoMaterno: "",
    cargo: "",
  },
  {
    nombre: "ABIGAIL LUCERO MEDINA",
    ApellidoPaterno: "",
    ApellidoMaterno: "",
    cargo: "",
  },
];

const cargos = [
  "Presidente",
  "Primer Escrutador",
  "Primer Secretario",
  "Primer Suplente General",
  "Segundo Escrutador",
  "Segundo Secretario",
  "Segundo Suplente General",
  "Tercer Escrutador",
  "Tercer Suplente General",
  "Otro",
];

const casillas = ["0005 BASICA"];

const recepcionTipos = [
  "Paquete recibido en tiempo",
  "Paquete recibido de forma extemporanea CON causa justificada",
  "Paquete recibido de forma extemporanea SIN causa justificada",
];

const condicionesEntrega = [
  "Sin muestras de alteración y firmado:",
  "Sin muestras de alteración y sin firmas:",
  "Con muestras de alteración y firmado:",
  "Con muestras de alteración y sin firmas:",
];

const actas = [
  "0003-CONTIGUA 1",
  "0005-BASICA",
  "0006-BASICA",
  "0007-BASICA",
  "0007-CONTIGUA 1",
];

const sexos = ["Masculino", "Femenino"];

const NON_DIGIT = "/[^d]/g";

const tipoPuesto = {
  1: [{ nombre: "ADMIN" }],
  2: [{ nombre: "CAEL" }],
  3: [{ nombre: "CAEL" }],
  4: [{ nombre: "ENCARGADO(A) DE BODEGA" }],
  5: [{ nombre: "ASISTENTE DE BODEGA" }],
  6: [
    { nombre: "CONSEJERO(A) PRESIDENTE(A)" },
    { nombre: "SECRETARIO(A) TÉCNICO(A)" },
    { nombre: "CONSEJERO(A) PROPIETARIO(A)" },
    { nombre: "CONSEJERO(A) SUPLENTE" },
  ],
  7: [
    { nombre: "PRESIDENTE" },
    { nombre: "PRIMER SECRETARIO" },
    { nombre: "SEGUNDO SECRETARIO" },
    { nombre: "PRIMER ESCRUTADOR" },
    { nombre: "SEGUNDO ESCRUTADOR" },
    { nombre: "TERCER ESCRUTADOR" },
    { nombre: "PRIMER SUPLENTE" },
    { nombre: "SEGUNDO SUPLENTE" },
    { nombre: "TERCER SUPLENTE" }
  ],
  8: [
    { nombre: "PRENSA" },
    { nombre: "RADIO" },
    { nombre: "TELEVISIÓN" },
    { nombre: "INTERNET" },
  ],
  9: [{ nombre: "SEL" }],
  10: [{ nombre: "AUXILIAR DE RECEPCIÓN" }],
  11: [{ nombre: "AUXILIAR DE CAPTURA" }],
  12: [{ nombre: "REPRESENTANTE DE PARTIDO" }],
  13: [{ nombre: "AUXILIAR DE ACREDITACIÓN" }],
  15: [{ nombre: "AUXILIAR DE TRASLADO" }],
};

export {
  tipoVotos,
  modeloAgendaFuncionario,
  funcionariosSeleccionar,
  cargos,
  casillas,
  recepcionTipos,
  condicionesEntrega,
  actas,
  sexos,
  NON_DIGIT,
  tipoPuesto,
};
