import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Grid, Menu, MenuItem, TextField } from "@material-ui/core";
import { Edit, MoreVert } from "@material-ui/icons";
import { useModels } from "../../hooks/useModels";
import TablePagination from "../../components/TablePagination";
import Loader from "../../components/Loader";

const tableHeader = [
  {
    prop: "nombreCompletoActa",
    name: "Nombre del acta",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  totalText: {
    fontSize: 22,
  },
  headerText: {
    fontSize: 18,
    color: "white",
  },
  header: {
    backgroundColor: theme.palette.primary.light,
  },
  collapsibleRowText: {
    fontSize: 18,
  },
}));

function CollapsibleRow(props) {
  const classes = useStyles();
  const { row, onActionClicked } = props;
  const [actasRequest, setActasRequest] = React.useState({ name: null });
  const [open, setOpen] = React.useState(false);
  const [buscar, setBuscar] = React.useState("");
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);

  const [models, modelsLoading, _, modelsPage, refreshModels] =
    useModels(actasRequest);

  const generateRequest = React.useCallback(
    (inline = {}) => ({
      name: "acta",
      limite: 5,
      extraParams: {
        expand: "conRecepcion,fechaRecepcion",
        ...row.extraParams,
        ...inline,
      },
    }),
    [row]
  );

  React.useEffect(() => {
    const req = generateRequest({ busqueda: buscar });
    if (buscar === "") delete req.extraParams.busqueda;
    setActasRequest(req);
  }, [buscar, generateRequest]);

  React.useEffect(() => {
    if (open) {
      setActasRequest(generateRequest());
    } else {
      setActasRequest({ name: null });
    }
  }, [open, generateRequest]);

  const changePage = (page) => {
    let params = {
      name: "acta",
      ordenar: "idActa.desc",
      pagina: page + 1,
      limite: 5,
      isCargando: false,
      busqueda: buscar,
    };
    refreshModels(true, params);
  };

  const handleToggleCollapse = () => {
    if (parseInt(row["cantidad"]) === 0) return;
    if (!open) {
      refreshModels();
    }
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        onClick={handleToggleCollapse}
        style={parseInt(row["cantidad"]) === 0 ? {} : { cursor: "pointer" }}
        disabled={parseInt(row["cantidad"]) === 0}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleToggleCollapse}
            disabled={parseInt(row["cantidad"]) === 0}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          <Typography className={classes.collapsibleRowText}>
            {row.acta}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.collapsibleRowText}>
            {row.cantidad}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.collapsibleRowText}>
            {row.revisadas}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Grid container justify="flex-end" style={{ marginBottom: 35 }}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Buscar por nombre"
                    placeholder="Nombre de acta"
                    onChange={(e) => setBuscar(e.target.value)}
                  />
                </Grid>
              </Grid>

              {modelsLoading ? (
                <Loader />
              ) : (
                <TablePagination
                  size="small"
                  header={tableHeader}
                  data={models}
                  paginatedApi
                  count={modelsPage !== null ? modelsPage.total : 0}
                  changePageApi={changePage}
                  paginationPageSize={5}
                  extraRows={[
                    {
                      prop: "recuentoCotejo",
                      name: "Estatus Actual",
                      cell: (currRow) =>
                        currRow.recuentoCotejo ? (
                          currRow.recuentoCotejo === "R" ? (
                            currRow.recuentoGT ? (
                              "RECUENTO GT"
                            ) : (
                              "RECUENTO"
                            )
                          ) : (
                            "COTEJO"
                          )
                        ) : (
                          <i>-</i>
                        ),
                    },
                    {
                      prop: "nombreEstatusPaquete",
                      name: "Estatus del paquete",
                      cell: (currRow) =>
                        currRow.estatusPaquete ? (
                          currRow.estatusPaquete.nombre
                        ) : (
                          <i>-</i>
                        ),
                    },
                    {
                      prop: "nombreEstatusActa",
                      name: "Estatus del acta",
                      cell: (currRow) =>
                        currRow.estatusActa ? (
                          currRow.estatusActa.nombre
                        ) : (
                          <i>-</i>
                        ),
                    },
                    {
                      prop: "conRecepcion",
                      name: "Fecha de rectificación",
                      cell: (currRow) =>
                        currRow.fechaRectificacion ? (
                          <i>{currRow.fechaRectificacion}</i>
                        ) : (
                          <i>-</i>
                        ),
                    },
                    {
                      prop: "acciones",
                      name: "Acciones",
                      cell: (currRow, index) => (
                        <div>
                          <IconButton
                            disabled={
                              currRow.fechaComputo ||
                              currRow.recuentoGT ||
                              !row.action
                            }
                            onClick={(event) => {
                              setOpenMenuRow(index);
                              setMenuAnchor(event.currentTarget);
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            anchorEl={menuAnchor}
                            keepMounted
                            open={index === openMenuRow}
                            onClose={() => setOpenMenuRow(null)}
                          >
                            <MenuItem
                              onClick={() =>
                                onActionClicked({
                                  ...currRow,
                                  abiertoEn: row.acta,
                                })
                              }
                            >
                              <Edit style={{ paddingRight: 5 }} />
                              Rectificar
                            </MenuItem>
                          </Menu>
                        </div>
                      ),
                    },
                  ]}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function RectificacionTable({
  headers = [],
  collapsibleRows = [],
  onSubrowClicked,
  showTotal = false,
  columnsForTotal = [],
}) {
  const classes = useStyles();

  if (collapsibleRows.length === 0) return null;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell />
            {headers.map((header) => (
              <TableCell>
                <Typography className={classes.headerText}>
                  <strong>{header}</strong>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {collapsibleRows.map((row, rowIndex) => (
            <CollapsibleRow
              key={rowIndex}
              row={row}
              onActionClicked={onSubrowClicked}
            />
          ))}
          {showTotal && columnsForTotal.length > 0 && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography className={classes.totalText}>
                  <strong>TOTAL</strong>
                </Typography>
              </TableCell>
              {columnsForTotal.map((column, index) => (
                <TableCell>
                  <Typography className={classes.totalText}>
                    {collapsibleRows
                      .map((c) => parseInt(c[column]))
                      .reduce((a, b) => a + b)}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
