import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const matchNothingRegex = /(?!)/;

const RegexTextField = ({
  regex,
  onChange,
  style,
  placeholder,
  size,
  value,
  helperText,
  label,
  type,
  error,
  ...rest
}) => {
  const handleChange = useCallback(
    (e) => {
      e.currentTarget.value = e.currentTarget.value.replace(regex, "");
      onChange(e);
    },
    [onChange, regex]
  );

  return (
    <TextField
      label={label}
      style={style}
      placeholder={placeholder}
      fullWidth
      size={size}
      variant="outlined"
      value={value}
      error={error}
      required
      helperText={helperText}
      type={type}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default React.memo(RegexTextField);

RegexTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  regex: PropTypes.instanceOf(RegExp),
};

RegexTextField.defaultProps = {
  regex: matchNothingRegex,
};
