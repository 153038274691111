import React, { useEffect, useState } from "react";
import { Card, Grid, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import * as histogram from "highcharts/modules/histogram-bellcurve";
import { generateDefaultChartOptions } from "../../../utils";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PageLayout from "../../../components/PageLayout";
import Services from "../../../services";
import Loading from "../../../components/FullScreenLoader";
import Autocomplete from "@material-ui/lab/Autocomplete";

highcharts3d(Highcharts);
histogram(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}
const dataEstatal = {};

const MonitorEleccion = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [consejos, setConsejos] = useState([]);
  const [obteniendoComputoFinal, setObteniendoComputoFinal] = useState(false);
  const [computoFinal, setComputoFinal] = useState([]);

  useEffect(() => {
    let mounted = true;

    const obtenerConsejos = async () => {
      try {
        const response = await Services.getWithOutToken(
          "https://api.computosonora.com/publico/consejo-electoral"
        );

        if (mounted && response.cuerpo) {
          setConsejos(response.cuerpo.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    obtenerConsejos();

    return () => (mounted = false);
  }, []);

  if (loading) return <Loading />;

  let chartOptions = generateDefaultChartOptions("column");
  const extraOptions = {
    title: {
      text: dataEstatal.nombre,
      style: {
        fontSize: 18,
      },
      align: "left",
    },
    legend: {
      enabled: false,
    },
    subtitle: {
      text: " ",
      style: {
        fontSize: 20,
      },
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: " ",
      },
    },
    xAxis: {
      type: "category",
      labels: {
        useHTML: true,
        animate: true,
        formatter: function (obj) {
          return (
            '<span><img src="' +
            computoFinal[obj.pos].logo +
            '" style="width: 40px; height: 40px;"/><br></span>' +
            `${computoFinal[obj.pos].porcentaje.toFixed(2)} %`
          );
        },
      },
    },
    series: [
      {
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: "22px",
            },
          },
        ],
        colors: ["#cd5d23"],
        colorByPoint: true,
        name: "Resultados Preliminares",
        data: computoFinal,
      },
    ],
    exporting: {
      allowHTML: true,
    },
  };

  chartOptions = { ...chartOptions, ...extraOptions };

  const onChangeConsejos = async (newValue) => {
    try {
      if (!newValue || obteniendoComputoFinal) return;

      setObteniendoComputoFinal(true);

      const response = await Services.getWithOutToken(
        "https://api.computosonora.com/publico/computo-final?idConsejoElectoral=" +
          newValue.idConsejoElectoral
      );

      let participantes = response.computo.map((c) => ({
        name: c.nombre,
        y: c.votos,
        logo: c.logo,
        porcentaje: c.porcentaje,
      }));

      participantes.push({
        name: "Candidatos no Registrados",
        y: response.totalVotosCandidatosNoRegistrados,
        logo: `/NR.png?fecha=${new Date()}`,
        porcentaje:
          Math.floor(response.totalVotosCandidatosNoRegistrados * 100) /
          response.totalVotos,
      });

      participantes.push({
        name: "Votos Nulos",
        y: response.totalVotosNulos,
        logo: `/VN.png?fecha=${new Date()}`,
        porcentaje:
          Math.floor(response.totalVotosNulos * 100) / response.totalVotos,
      });

      setComputoFinal(participantes);
    } catch (error) {
      console.error(error);
    } finally {
      setObteniendoComputoFinal(false);
    }
  };

  return (
    <PageLayout
      title={"Monitor elección"}
      history={history}
      links={[
        {
          to: "/estadisticos/monitor-eleccion",
          icon: <EqualizerIcon fontSize="small" />,
          label: "Monitor elección",
        },
      ]}
    >
      <Autocomplete
        size="small"
        options={consejos}
        getOptionLabel={(option) => option.nombre}
        renderInput={(params) => (
          <TextField
            style={{ backgroundColor: "white" }}
            {...params}
            label="Consejos Electorales"
            variant="outlined"
          />
        )}
        onChange={async (event, newValue) => await onChangeConsejos(newValue)}
        style={{ marginBottom: 20 }}
      />
      {computoFinal.length ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container component={Card}>
                  <Grid item xs={12}>
                    <Typography align="center">
                      RESULTADOS DE LA VOTACIÓN
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOptions}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography align="center">SIN RESULTADOS DE VOTACIÓN</Typography>
      )}
    </PageLayout>
  );
};

export default MonitorEleccion;
