import React, { useState, useEffect } from "react";
import BookIcon from "@material-ui/icons/Book";
import InputIcon from "@material-ui/icons/Input";
import PageLayout from "../../../components/PageLayout";
import TablePagination from "../../../components/TablePagination";
import {
  Typography,
  Button,
  Card,
  Menu,
  MenuItem,
  IconButton,
  CardContent,
} from "@material-ui/core";
import { format } from "date-fns";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Services from "../../../services";
import Loading from "../../../components/FullScreenLoader";
import DialogEnvioPaquetes from "../envioPaquetes/dialogEnvioPaquetes";
import { useAlert } from "../../../hooks/useAlert";
import { useAuth } from "../../../hooks/useAuth";
import PdfDialog from "../../../components/PdfDialog";
import { baseUrlPdf } from "../../../utils/variables";
import AlertDialog from "../../../components/AlertDialog";
import { auth } from "../../../firebase";

// const url = "acta/recepcion-consejo";
const url = "acta/recepcion-consejo?expand=consejoEnvia,actas";
const header = [];
const GUBERNATURA = 1;
const DISTRITO = [3, 4];

const RecepcionPaquetes = ({ history }) => {
  const { user, userLoading } = useAuth();
  const [estatales, setEstatales] = useState([]);
  const [distritales, setDistritales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const { showAlert } = useAlert();
  const [tipoEnvio, setTipoEnvio] = useState("");
  const [idEnvioRecepcion, setIdEnvioRecepcion] = useState(null);
  const [models, setModels] = useState(null);
  const [modelsPage, setModelsPage] = useState(null);
  const [consejoEnvia, setConsejoEnvia] = useState(null);
  const [printOpen, setPrintOpen] = useState(false);
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  useEffect(() => {
    let mounted = true;

    if (!user) return;

    const getPaquetes = async () => {
      try {
        const response = await Services.get(url);

        if (mounted) {
          setModels(response.resultado);
          setModelsPage(response.paginacion);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    getPaquetes();

    return () => (mounted = false);
  }, [user]);

  if (loading || userLoading || !user) return <Loading />;
  const idUsuario = auth.currentUser.uid;

  const recibirPaquetes = async () => {
    try {
      setSaving(true);

      const data = {
        idEnvioRecepcion,
        tipoEnvio: tipoEnvio,
      };

      // await Services.downloadBlob("pdf-blob/recepcion-paquetes", data, "RecepcionPaquetes.pdf");
      const response = await Services.post("acta/guardar-recepcion", data);
      setPrintOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
      setOpen(false);
    }
  };

  return (
    <PageLayout
      title={"Recepción de Consejo " + tipoEnvio}
      history={history}
      links={[
        {
          to: "/bodegas/bitacora-de-paquetes",
          icon: <BookIcon fontSize="small" />,
          label: "Bitácora de paquetes",
        },
        {
          to: "/bodegas/bitacora-de-paquetes/envio-paquetes",
          icon: <InputIcon fontSize="small" />,
          label: "Recepcion de paquetes",
        },
      ]}
    >
      {models && models.length ? (
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={() => {}}
          count={modelsPage !== null ? modelsPage.total : 0}
          labelRowsPerPage={"Renglones por página"}
          extraRows={[
            {
              prop: "nombreCompletoActa",
              name: "Consejo que Envía",
              cell: (row, index) => <>{row?.consejoEnvia?.nombre}</>,
            },
            {
              prop: "actas",
              name: "Total de paquetes enviados",
              cell: (row, index) => <>{row?.actas?.length}</>,
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (!row.actas.length) {
                      showAlert({
                        severity: "warning",
                        message: "No tiene paquetes para recibir.",
                      });
                      return;
                    }
                    let _estatales = [];
                    let _distrital = [];
                    for (let i in row.actas) {
                      let acta = row.actas[i];
                      if (acta.idTipoEleccion === GUBERNATURA) {
                        _estatales.push(acta);
                      } else if (DISTRITO.includes(acta.idTipoEleccion)) {
                        _distrital.push(acta);
                      }
                    }

                    setIdEnvioRecepcion(row.idEnvioRecepcion);
                    setConsejoEnvia(row.consejoEnvia);
                    setTipoEnvio(row.tipoEnvio);
                    setDistritales(_distrital);
                    setEstatales(_estatales);
                    setOpen(true);
                  }}
                >
                  RECIBIR PAQUETES
                </Button>
              ),
            },
          ]}
        />
      ) : (
        <Card>
          <CardContent>No hay recepciones</CardContent>
        </Card>
      )}
      <DialogEnvioPaquetes
        open={open}
        onClose={() => {
          setOpen(false);
          setDistritales([]);
          setEstatales([]);
        }}
        title={`¿Confirma la recepción de Paquetes del ${consejoEnvia?.nombre}?`}
        paquetes={{
          estatales,
          distritales,
        }}
        saving={saving}
        onAccept={recibirPaquetes}
      />
      <AlertDialog
        disableBackdropClick
        disableEscapeKeyDown
        open={printOpen}
        onClose={() => history.push("/bodegas/bitacora-de-paquetes")}
        title={`GUARDADO CORRECTAMENTE.`}
        titleColor="#60c45a"
        cancelText="Finalizar y continuar"
        content="Imprimir el listado de Paquestes Electorales"
        onAccept={(_) => {
          setPdfDialogOpen(true);
        }}
      />
      <PdfDialog
        source={`${baseUrlPdf}paquete-acta-circunstanciada/recepcion-paquetes?idEnvioRecepcion=${idEnvioRecepcion}&idUsuario=${idUsuario}`}
        open={pdfDialogOpen}
        setOpen={setPdfDialogOpen}
      />
    </PageLayout>
  );
};

export default RecepcionPaquetes;
