import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Sidebar from "../components/Sidebar";
import Badge from "@material-ui/core/Badge";
import PersonIcon from "@material-ui/icons/Person";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";

import { useAuth } from "../hooks/useAuth";
import { useNotifications } from "../hooks/useNotifications";
import { useCacheBuster } from "../hooks/CacheBuster";
import { baseUrl } from "../utils/variables";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.dark,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(9),
  },
  badge: {
    color: theme.palette.error.main,
  },
}));

export default function DashboardLayout(props) {
  const classes = useStyles();
  const { user, userLoading, signOut } = useAuth();

  const { refreshCacheAndRedirect } = useCacheBuster();

  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const history = useHistory();
  const buttonRef = React.useRef(null);
  const { notifications } = useNotifications();

  const [open, setOpen] = React.useState(true);

  if (!user && userLoading) return null;

  const accountMenuClicked = () => {
    setAccountMenuOpen(true);
  };

  const accountMenuBlur = () => {
    setAccountMenuOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = (_, value) => {
    _.preventDefault();
    switch (value) {
      case "profile":
        history.push("/perfil");
        break;
      case "consejo":
        history.push(
          "/consejo-personal/casilla?id=" + user?.idConsejoElectoral
        );
        break;
      case "logout":
        signOut();
        history.push("/");
        break;
      default:
        break;
    }
    accountMenuBlur();
  };

  const onRefreshPressed = async (uri) => {
    try {
      await refreshCacheAndRedirect("/");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <Grid item>
            <Tooltip placement="bottom" title="Colapsar menú" arrow>
              {open === true ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerClose}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: !open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Tooltip>
          </Grid>

          <Grid item>
            <img
              src={process.env.PUBLIC_URL + "/logo_iee_full.png"}
              alt=""
              onClick={(_) => onRefreshPressed(history.location.pathname)}
              style={{
                height: 50,
                width: "auto",
                cursor: "pointer",
              }}
            />
          </Grid>

          <Grid item>
            <Tooltip
              placement="right"
              title="Número de versión actual"
              arrow
              onClick={() => onRefreshPressed(history.location.pathname)}
            >
              <Typography
                style={{
                  textAlign: "center",
                  marginLeft: 15,
                  fontSize: 14,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                v{global.appVersion}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid container alignItems="center" justify="flex-end">
            <Grid item>
              <Typography variant="subtitle1" style={{ textAlign: "end" }}>
                {user ? user.correo : "Error al obtener agenda"}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "end", fontWeight: "bold" }}
              >
                {user
                  ? `${
                      user.consejoElectoral
                        ? user.consejoElectoral.nombre
                        : "SIN CONSEJO ELECTORAL"
                    } - ${user.nombreCompleto}`
                  : "CUENTA SIN AGENDA LIGADA"}
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 15 }}>
              <Tooltip placement="bottom" title="Opciones de perfil" arrow>
                <IconButton
                  color="inherit"
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  onClick={accountMenuClicked}
                  ref={buttonRef}
                >
                  <Badge badgeContent={notifications.length} color="error">
                    <PersonIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Menu
            id="profile-menu"
            anchorEl={buttonRef.current}
            keepMounted
            open={accountMenuOpen}
            onBlur={accountMenuBlur}
            onClose={handleClose}
          >
            <MenuItem key="profile" onClick={(e) => handleClose(e, "profile")}>
              Perfil
            </MenuItem>
            {user?.puesto === "CONSEJERO(A) PRESIDENTE(A)" ? (
              <MenuItem
                key="consejo"
                onClick={(e) => handleClose(e, "consejo")}
              >
                Consejo Electoral
              </MenuItem>
            ) : null}

            <MenuItem onClick={(e) => handleClose(e, "logout")}>
              Cerrar sesión
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <Sidebar isOpen={open} />
        </Drawer>
      </div>
      <main className={classes.content}>
        <Container className={classes.container} style={{ maxWidth: "100%" }}>
          {props.children}
        </Container>
      </main>
    </div>
  );
}
