import React from "react";
import { useEffect, useState } from "react";

import {
  Breadcrumbs,
  Chip,
  emphasize,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Home } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { baseUrl, baseUrlPdf } from "../../utils/variables";
import { auth } from "../../firebase";
import { useAuth } from "../../hooks/useAuth";
import { useModels } from "../../hooks/useModels";
import Loading from "../../components/Loading";
import Services from "../../services";
import PdfDialog from "../../components/PdfDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    width: "100%",
  },

  roo2: {
    width: "100%",
    maxWidth: 1000,
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const SesionExtraordinaria = ({ history }) => {
  const { user, userLoading } = useAuth();
  const classes = useStyles();
  const id = auth.currentUser.uid;
  const [pleno, setPleno] = useState(false);
  const [i, setI] = useState(null);
  const [total, setTotal] = useState(0);
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState("");

  const consejo = React.useMemo(
    () => ({
      idConsejoElectoral: i,
      limite: 1,
    }),
    [i]
  );

  const [
    modelsCE,
    modelsLoadingCE,
    modelsErrorCE,
    modelsPageCE,
    refreshModelsCE,
    deleteModelCE,
  ] = useModels({
    name: "consejo-electoral",
    extraParams: consejo,
  });

  const paquetes = async () => {
    const response3 = await Services.get("grupo-trabajo");
    setTotal(response3.resultado);
  };

  useEffect(() => {
    if (!user || !modelsCE) return;
    paquetes();
    setI(user?.consejoElectoral.idConsejoElectoral);
    setPleno(modelsCE[0]?.pleno);
  }, [user, modelsCE]);

  if (userLoading) return <Loading />;
  return (
    <>
      <div className={classes.root2}>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1%" }}>
          <StyledBreadcrumb
            component="a"
            label="Inicio"
            icon={<Home fontSize="small" />}
            onClick={() => {
              history.push("/");
            }}
          />
        </Breadcrumbs>
        <Grid container style={{ marginBottom: "1%" }}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="h4"
              component="h2"
              style={{ fontSize: 24, fontWeight: "bold" }}
            >
              SESIÓN EXTRAORDINARIA
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Grid
              component="a"
              style={{ textDecoration: "none" }}
              onClick={() => {
                setPdfDialogOpen(true);
                setLink(`${baseUrlPdf}informe?idUsuario=${id}`);
              }}
            >
              <Card className={classes.root}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Informe del Presidente
                      <br />
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid container justify="flex-end">
                      <Button
                        size="small"
                        color="primary"
                        style={{ color: "white" }}
                      >
                        Análisis
                      </Button>
                    </Grid>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Grid
              component="a"
              onClick={() => {
                setPdfDialogOpen(true);
                setLink(`${baseUrlPdf}vf6-acuerdo?idUsuario=${id}`);
              }}
              style={{ textDecoration: "none" }}
            >
              <Card className={classes.root}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Casillas Recuento
                      <br />
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid container justify="flex-end">
                      <Button
                        size="small"
                        color="primary"
                        style={{ color: "white" }}
                      >
                        Acuerdo
                      </Button>
                    </Grid>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>

          {total && total?.length ? (
            <Grid item xs={12} lg={4}>
              <Grid
                component="a"
                onClick={() => {
                  setPdfDialogOpen(true);
                  setLink(`${baseUrlPdf}vf5-acuerdo?idUsuario=${id}`);
                }}
                style={{ textDecoration: "none" }}
              >
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Integración Grupos de Trabajo
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Grid container justify="flex-end">
                        <Button
                          size="small"
                          color="primary"
                          style={{ color: "white" }}
                        >
                          Acuerdo
                        </Button>
                      </Grid>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <>
              {user && user?.permiso && user?.permiso?.rol === "Admin" ? (
                <Grid item xs={12} lg={4}>
                  <Grid
                    component="a"
                    onClick={() => {
                      setPdfDialogOpen(true);
                      setLink(`${baseUrlPdf}vf5-acuerdo?idUsuario=${id}`);
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Card className={classes.root}>
                      <CardActionArea>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            Integración Grupos de Trabajo
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Grid container justify="flex-end">
                            <Button
                              size="small"
                              color="primary"
                              style={{ color: "white" }}
                            >
                              Acuerdo
                            </Button>
                          </Grid>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </>
          )}

          {total && total?.length ? (
            <Grid item xs={12} lg={4}>
              <Grid
                component="a"
                onClick={() => {
                  setPdfDialogOpen(true);
                  setLink(`${baseUrlPdf}vf7-acuerdo?idUsuario=${id}`);
                }}
                style={{ textDecoration: "none" }}
              >
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Espacios para la instalación de Grupos de Trabajo
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Grid container justify="flex-end">
                        <Button
                          size="small"
                          color="primary"
                          style={{ color: "white" }}
                        >
                          Acuerdo
                        </Button>
                      </Grid>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <>
              {user && user?.permiso && user?.permiso?.rol === "Admin" ? (
                <Grid item xs={12} lg={4}>
                  <Grid
                    component="a"
                    onClick={() => {
                      setPdfDialogOpen(true);
                      setLink(`${baseUrlPdf}vf7-acuerdo?idUsuario=${id}`);
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Card className={classes.root}>
                      <CardActionArea>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            Espacios para la instalación de Grupos de Trabajo
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Grid container justify="flex-end">
                            <Button
                              size="small"
                              color="primary"
                              style={{ color: "white" }}
                            >
                              Acuerdo
                            </Button>
                          </Grid>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </>
          )}

          <Grid item xs={12} lg={4}>
            <Grid
              component="a"
              onClick={() => {
                setPdfDialogOpen(true);
                setLink(`${baseUrlPdf}vf4-acuerdo?idUsuario=${id}`);
              }}
              style={{ textDecoration: "none" }}
            >
              <Card className={classes.root}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Participantes que auxiliarán al propio Consejo
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid container justify="flex-end">
                      <Button
                        size="small"
                        color="primary"
                        style={{ color: "white" }}
                      >
                        Acuerdo
                      </Button>
                    </Grid>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <PdfDialog
          source={link}
          open={pdfDialogOpen}
          setOpen={setPdfDialogOpen}
        />
      </div>
    </>
  );
};

export default SesionExtraordinaria;
