import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack, ExpandLess, ExpandMore, Save } from "@material-ui/icons";

import Usuarios from "../usuarios";
import UsersDetail from "../usuarios/detail";

import { formatPhoneNumber } from "../../utils";
import { NON_DIGIT, sexos, tipoPuesto } from "../../constants/mockData";
import { emptyRequest, postRequest } from "../../constants/request";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";
import CustomDialog from "../../components/CustomDialog";
import DatePicker from "../../components/DatePicker";
import AsyncSelect from "../../components/AsyncSelect";
import SingleSelect from "../../components/SingleSelect";

import { useAlert } from "../../hooks/useAlert";
import { useQuery } from "../../hooks/useQuery";
import { useHttp } from "../../hooks/useHttp";
import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

const asyncSelectParams = {
  ordenar: "nombre.asc",
};

function AgendasDetail({
  match,
  history,
  dialog,
  editingAgenda,
  setAgendasSearch,
}) {
  const classes = useStyles();
  const query = useQuery();
  const idAgenda = query.get("id");
  const editing = !!idAgenda;
  const { showAlert } = useAlert();

  const [validating, setValidating] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(undefined);
  const [editingUser, setEditingUser] = React.useState(undefined);
  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(true);
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [clearMunicipio, setClearMunicipio] = React.useState(false);
  const [idSeccion, setIdSeccion] = React.useState("");
  const [clearSeccion, setClearSeccion] = React.useState(false);
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [clearConsejo, setClearConsejo] = React.useState("");
  const [localidad, setLocalidad] = React.useState("");
  const [colonia, setColonia] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [apellidoPaterno, setApellidoPaterno] = React.useState("");
  const [apellidoMaterno, setApellidoMaterno] = React.useState("");
  const [calle, setCalle] = React.useState("");
  const [numeroExterior, setNumeroExterior] = React.useState("");
  const [numeroInterior, setNumeroInterior] = React.useState("");
  const [codigoPostal, setCodigoPostal] = React.useState("");
  const [claveElector, setClaveElector] = React.useState("");
  const [folioElector, setFolioElector] = React.useState("");
  const [curp, setCurp] = React.useState("");
  const [rfc, setRfc] = React.useState("");
  const [alias, setAlias] = React.useState("");
  const [sexo, setSexo] = React.useState("");
  const [telefonoHogar, setTelefonoHogar] = React.useState("");
  const [telefonoTrabajo, setTelefonoTrabajo] = React.useState("");
  const [telefonoCelular, setTelefonoCelular] = React.useState("");
  const [telefonoOtro, setTelefonoOtro] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [observaciones, setObservaciones] = React.useState("");
  const [edad, setEdad] = React.useState("");
  const [idDistrito, setIdDistrito] = React.useState("");
  const [clearDistrito, setClearDistrito] = React.useState(false);
  const [fechaNacimiento, setFechaNacimiento] = React.useState(new Date());
  const [lugarNacimiento, setLugarNacimiento] = React.useState("");
  // const [rutaFotografia, setRutaFotografia] = React.useState("");
  const [idEstadoNacimiento, setIdEstadoNacimiento] = React.useState("");
  const [idMunicipioNacimiento, setIdMunicipioNacimiento] = React.useState("");
  const [clearMunicipioNacimiento, setClearMunicipioNacimiento] =
    React.useState(false);
  const [idEstadoDomicilio, setIdEstadoDomicilio] = React.useState("");
  const [idTipoAgenda, setIdTipoAgenda] = React.useState("");
  const [puesto, setPuesto] = React.useState("");

  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);
  const [opcionesPuesto, setOpcionesPuesto] = React.useState([]);
  const [idParticipantePolitico, setIdParticipantePolitico] =
    React.useState(null);
  const [participantePolitico, setParticipantePolitico] = React.useState("");
  const [participante, setParticipante] = React.useState(false);

  const [openPersona, setOpenPersona] = React.useState(true);
  const [openOficial, setOpenOficial] = React.useState(true);
  const [openDireccion, setOpenDireccion] = React.useState(true);
  const [openContacto, setOpenContacto] = React.useState(true);

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "agenda",
      id: idAgenda,
      expand:
        "municipio,seccion,distrito,estadoNacimiento,nacimientoMunicipio,estadoDomicilio,tipoAgenda,usuario,consejoElectoral",
    });

  const participanteParamsMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
    }),
    [participante]
  );

  const [
    modelsParticipante,
    modelsLoadingParticipante,
    modelsErrorParticipante,
    modelsPageParticipante,
    refreshModelsParticipante,
    deleteModelParticipante,
  ] = useModels({
    name: "participante-politico",
    expand: "",
    ordenar: "nombre.asc",
    extraParams: participanteParamsMemo,
  });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setIdMunicipio(model.idMunicipio);
      setIdSeccion(model.idSeccion);
      setIdConsejoElectoral(model.idConsejoElectoral);
      setLocalidad(model.localidad);
      setColonia(model.colonia);
      setNombre(model.nombre);
      setApellidoPaterno(model.apellidoPaterno);
      setApellidoMaterno(model.apellidoMaterno);
      setCalle(model.calle);
      setNumeroExterior(model.numeroExterior);
      setNumeroInterior(model.numeroInterior);
      setCodigoPostal(model.codigoPostal);
      setClaveElector(model.claveElector);
      setFolioElector(model.folioElector);
      setCurp(model.curp);
      setRfc(model.rfc);
      setAlias(model.alias);
      setSexo(model.sexo);
      setTelefonoHogar(model.telefonoHogar);
      setTelefonoTrabajo(model.telefonoTrabajo);
      setTelefonoCelular(model.telefonoCelular);
      setTelefonoOtro(model.telefonoOtro);
      setCorreo(model.correo);
      setObservaciones(model.observaciones);
      setEdad(model.edad);
      setIdDistrito(model.idDistrito);
      setFechaNacimiento(model.fechaNacimiento);
      setLugarNacimiento(model.lugarNacimiento);
      //setRutaFotografia
      setIdEstadoNacimiento(model.idEstadoNacimiento);
      setIdMunicipioNacimiento(model.idMunicipioNacimiento);
      setIdEstadoDomicilio(model.idEstadoDomicilio);
      setIdTipoAgenda(model.idTipoAgenda);
      setPuesto(model.puesto);
      setSelectedUser(model.usuario);
      if (model.idTipoAgenda == 12) setParticipante(true);
      else setParticipante(false);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const municipioNacimientoParamsMemo = React.useMemo(
    () => ({
      idEstado: idEstadoNacimiento,
    }),
    [idEstadoNacimiento]
  );

  const municipioParamsMemo = React.useMemo(
    () => ({
      idEstado: idEstadoDomicilio,
    }),
    [idEstadoDomicilio]
  );

  const distritoParamsMemo = React.useMemo(
    () => ({
      tipo: 2,
      ordenar: "nombre.asc",
    }),
    []
  );

  const seccionParamsMemo = React.useMemo(
    () => ({
      idDistrito,
      ordenar: "nombre.asc",
    }),
    [idDistrito]
  );

  //por usar para filtrar consejos por criterio de distrito,seccion, municipio, etc.
  const consejoParamsMemo = React.useMemo(() => ({}), []);

  React.useEffect(() => {
    if (!responseError && !responseLoading && response) {
      history.push("/agendas");
    }
  }, [responseError, responseLoading, response, history]);

  const onSubmitPressed = () => {
    setValidating(true);
    const agenda = {
      idMunicipio,
      idSeccion,
      localidad,
      colonia,
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      calle,
      numeroExterior,
      numeroInterior,
      codigoPostal,
      claveElector,
      folioElector,
      curp,
      rfc,
      alias,
      sexo,
      telefonoHogar,
      telefonoTrabajo,
      telefonoCelular,
      telefonoOtro,
      correo,
      fechaNacimiento,
      observaciones,
      edad,
      idDistrito,
      idConsejoElectoral,
      lugarNacimiento,
      idEstadoNacimiento,
      idMunicipioNacimiento,
      idEstadoDomicilio,
      idTipoAgenda,
      puesto,
      idParticipantePolitico,
      participantePolitico,
      idUsuario: selectedUser ? selectedUser.idUsuario : null,
    };
    if (editing) {
      agenda.idAgenda = idAgenda;
    }
    if (
      (agenda.nombre !== "" && agenda.nombrePaterno !== "",
      agenda.nombreMaterno !== "",
      idConsejoElectoral !== "" && idTipoAgenda !== "")
    ) {
      const request = postRequest("agenda/guardar", agenda);
      setSaveRequest(request);
      if (!dialog) {
        request.alert = true;
      }
    } else {
      if (!dialog) {
        window["scrollTo"]({ top: 0, behavior: "smooth" });
        showAlert({
          message: "Favor de revisar los campos llenados",
          severity: "warning",
        });
      }
    }
  };

  const setIdMunicipioCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdMunicipio(v.idMunicipio);
      } else {
        setIdMunicipio("");
      }
      setIdConsejoElectoral("");
      setClearConsejo((v) => !v);
    },
    [setIdMunicipio]
  );

  const setIdSeccionCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdSeccion(v.idSeccion);
      } else {
        setIdSeccion("");
      }
      setIdMunicipio("");
      setClearMunicipio((v) => !v);
      setIdConsejoElectoral("");
      setClearConsejo((v) => !v);
    },
    [setIdSeccion]
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdConsejoElectoral(v.idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setIdConsejoElectoral]
  );

  const setLocalidadCallback = React.useCallback(
    (e) => {
      setLocalidad(e.target.value);
    },
    [setLocalidad]
  );

  const setColoniaCallback = React.useCallback(
    (e) => {
      setColonia(e.target.value);
    },
    [setColonia]
  );

  const setNombreCallback = React.useCallback(
    (e) => {
      setNombre(e.target.value);
    },
    [setNombre]
  );

  const setApellidoPaternoCallback = React.useCallback(
    (e) => {
      setApellidoPaterno(e.target.value);
    },
    [setApellidoPaterno]
  );

  const setApellidoMaternoCallback = React.useCallback(
    (e) => {
      setApellidoMaterno(e.target.value);
    },
    [setApellidoMaterno]
  );

  const setCalleCallback = React.useCallback(
    (e) => {
      setCalle(e.target.value);
    },
    [setCalle]
  );

  const setNumeroExteriorCallback = React.useCallback(
    (e) => {
      setNumeroExterior(e.target.value);
    },
    [setNumeroExterior]
  );

  const setNumeroInteriorCallback = React.useCallback(
    (e) => {
      setNumeroInterior(e.target.value);
    },
    [setNumeroInterior]
  );

  const setCodigoPostalCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setCodigoPostal((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setCodigoPostal]
  );

  const setClaveElectorCallback = React.useCallback(
    (e) => {
      setClaveElector(e.target.value);
    },
    [setClaveElector]
  );

  const setFolioElectorCallback = React.useCallback(
    (e) => {
      setFolioElector(e.target.value);
    },
    [setFolioElector]
  );

  const setCurpCallback = React.useCallback(
    (e) => {
      setCurp(e.target.value);
    },
    [setCurp]
  );

  const setRfcCallback = React.useCallback(
    (e) => {
      setRfc(e.target.value);
    },
    [setRfc]
  );

  const setAliasCallback = React.useCallback(
    (e) => {
      setAlias(e.target.value);
    },
    [setAlias]
  );

  const setSexoCallback = React.useCallback(
    (e) => {
      setSexo(e.target.value);
    },
    [setSexo]
  );

  const setTelefonoHogarCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoHogar((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoHogar]
  );

  const setTelefonoTrabajoCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoTrabajo((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoTrabajo]
  );

  const setTelefonoCelularCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoCelular((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoCelular]
  );

  const setTelefonoOtroCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setTelefonoOtro((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setTelefonoOtro]
  );

  const setCorreoCallback = React.useCallback(
    (e) => {
      setCorreo(e.target.value);
    },
    [setCorreo]
  );

  const setObservacionesCallback = React.useCallback(
    (e) => {
      setObservaciones(e.target.value);
    },
    [setObservaciones]
  );

  const setEdadCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      const intValue = parseInt(value.toString().replace(NON_DIGIT, ""));
      setEdad((v) => (isNaN(intValue) ? "" : intValue.toString()));
    },
    [setEdad]
  );

  const setIdDistritoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdDistrito(v.idDistrito);
      } else {
        setIdDistrito("");
      }
      setIdSeccion("");
      setClearSeccion((v) => !v);
      setIdConsejoElectoral("");
      setClearConsejo((v) => !v);
    },
    [setIdDistrito]
  );

  const setFechaNacimientoCallback = React.useCallback(
    (date) => {
      setFechaNacimiento(date);
    },
    [setFechaNacimiento]
  );

  const setLugarNacimientoCallback = React.useCallback(
    (e) => {
      setLugarNacimiento(e.target.value);
    },
    [setLugarNacimiento]
  );

  // const setRutaFotografiaCallback = React.useCallback(
  //   (e) => {
  //     setRutaFotografia(e.target.value);
  //   },
  //   [setRutaFotografia]
  // );

  const setIdEstadoNacimientoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEstadoNacimiento(v.idEstado);
      } else {
        setIdEstadoNacimiento("");
      }
      setIdMunicipioNacimiento("");
      setClearMunicipioNacimiento((v) => !v);
    },
    [setIdEstadoNacimiento]
  );

  const setIdMunicipioNacimientoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdMunicipioNacimiento(v.idMunicipio);
      } else {
        setIdMunicipioNacimiento("");
      }
    },
    [setIdMunicipioNacimiento]
  );

  const setIdEstadoDomicilioCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEstadoDomicilio(v.idEstado);
      } else {
        setIdEstadoDomicilio("");
      }

      setIdMunicipio("");
      setClearMunicipio((v) => !v);
      setIdDistrito("");
      setClearDistrito((v) => !v);
      setIdSeccion("");
      setClearSeccion((v) => !v);
      setIdConsejoElectoral("");
      setClearConsejo((v) => !v);
    },
    [setIdEstadoDomicilio]
  );

  const setIdTipoAgendaParticipanteCallback = React.useCallback(
    (e) => {
      setIdParticipantePolitico(e.target.value.idParticipantePolitico);
      setParticipantePolitico(e.target.value.nombre);
    },
    [setIdParticipantePolitico]
  );

  const setIdTipoAgendaCallback = React.useCallback(
    (e) => {
      if (e.target.value.id == 12) {
        setParticipante(true);
        setIdTipoAgenda(e.target.value.id);
      } else {
        setParticipante(false);
        setIdParticipantePolitico(null);
        setParticipantePolitico(null);
        setIdTipoAgenda(e.target.value.id);
        setOpcionesPuesto([]);
        setOpcionesPuesto(tipoPuesto[e.target.value.id]);
      }
    },
    [setIdTipoAgenda]
  );

  const setPuestoCallback = React.useCallback(
    (e) => {
      if (participante) setPuesto("Representante de Partido");
      else {
        setPuesto([]);
        setPuesto(e.target.value.nombre);
      }
    },
    [setPuesto]
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  const CustomDialogContent = (props) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {search ? (
            <>
              <Grid container>
                <Button
                  variant="contained"
                  color="primary"
                  fullwidth
                  style={{ marginBottom: 10, marginTop: 10 }}
                  onClick={() => {
                    setSearch(false);
                    setEditingUser(undefined);
                  }}
                >
                  CREAR USUARIO
                </Button>
              </Grid>
              <Usuarios
                dialog={true}
                setEditingUser={setEditingUser}
                setSearch={setSearch}
                setSelectedUser={setSelectedUser}
                handleClose={handleClose}
              />
            </>
          ) : (
            <>
              <Grid container>
                <Button
                  onClick={() => {
                    setSearch(true);
                  }}
                >
                  <ArrowBack color="primary" />
                </Button>
              </Grid>
              <UsersDetail
                match={match}
                history={history}
                dialog={true}
                editingUser={editingUser}
                setSearch={setSearch}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONTROL DE AGENDA"
        model="agenda"
        editing={editing}
        dialog={dialog}
        links={[
          {
            to: "/agendas",
            label: "Agendas",
          },
          {
            to: "/agendas/nuevo",
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        {!dialog && (
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={10}>
                <>
                  <Typography>
                    {selectedUser
                      ? "Usuario selecionado:"
                      : "No hay usuario seleccionado"}
                  </Typography>
                  {selectedUser && (
                    <Typography>{`Correo: ${selectedUser.correo}`}</Typography>
                  )}
                </>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    selectedUser ? setSelectedUser(null) : setIsOpen(true);
                  }}
                >
                  {selectedUser
                    ? "DESLIGAR CUENTA A AGENDA"
                    : "LIGAR CUENTA A AGENDA"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <CustomDialog
          isOpen={isOpen}
          handleClose={handleClose}
          title="ASOCIAR CUENTA A AGENDA"
          subtitle="Vincula una cuenta a la agenda"
        >
          <CustomDialogContent />
        </CustomDialog>

        <ListItem
          style={{ backgroundColor: "#e0e0e0", color: "#a13800" }}
          button
          onClick={() => setOpenPersona(!openPersona)}
        >
          {openPersona ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={"INFORMACIÓN DE LA PERSONA"} />
        </ListItem>
        <Collapse in={openPersona} timeout="auto" unmountOnExit>
          <Grid
            container
            spacing={3}
            className={classes.paper}
            style={{ marginTop: 10 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Nombre"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.nombre}
                error={Boolean(responseError && responseError.nombre)}
                onChange={setNombreCallback}
                inputProps={{
                  value: nombre,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Apellido Paterno"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.apellidoPaterno}
                error={Boolean(responseError && responseError.apellidoPaterno)}
                onChange={setApellidoPaternoCallback}
                inputProps={{
                  value: apellidoPaterno,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Apellido Materno"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.apellidoMaterno}
                error={Boolean(responseError && responseError.apellidoMaterno)}
                onChange={setApellidoMaternoCallback}
                inputProps={{
                  value: apellidoMaterno,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Consejo"
                name="consejo-electoral"
                labelProp="nombre"
                onChange={setIdConsejoElectoralCallback}
                defaultValue={model && model.consejoElectoral}
                extraParams={consejoParamsMemo}
                clearInput={clearConsejo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Alias"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.alias}
                error={Boolean(responseError && responseError.alias)}
                onChange={setAliasCallback}
                inputProps={{
                  value: alias,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Edad"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.edad}
                error={Boolean(responseError && responseError.edad)}
                onChange={setEdadCallback}
                inputProps={{
                  value: edad,
                  min: 0,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl
                className={classes.formControl}
                error={Boolean(responseError && responseError.sexo)}
                variant="outlined"
                size="small"
              >
                <InputLabel id="plantilla-label">Sexo</InputLabel>
                <Select
                  labelId="plantilla-label"
                  label="Sexo"
                  value={sexo}
                  onChange={setSexoCallback}
                >
                  {sexos.map((tipo, index) => (
                    <MenuItem key={index} value={tipo}>
                      {tipo}
                    </MenuItem>
                  ))}
                </Select>
                {responseError && (
                  <FormHelperText>{responseError.sexo}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <DatePicker
                label="Fecha de Nacimiento"
                value={fechaNacimiento}
                onChange={setFechaNacimientoCallback}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Estado de Nacimiento"
                name="estado"
                defaultValue={model && model.estadoNacimiento}
                labelProp="nombre"
                onChange={setIdEstadoNacimientoCallback}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Municipio de Nacimiento"
                name="municipio"
                defaultValue={model && model.nacimientoMunicipio}
                labelProp="nombre"
                onChange={setIdMunicipioNacimientoCallback}
                clearInput={clearMunicipioNacimiento}
                extraParams={municipioNacimientoParamsMemo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Lugar de Nacimiento"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.lugarNacimiento}
                error={Boolean(responseError && responseError.lugarNacimiento)}
                onChange={setLugarNacimientoCallback}
                inputProps={{
                  value: lugarNacimiento,
                }}
              />
            </Grid>
          </Grid>
        </Collapse>

        <ListItem
          style={{ backgroundColor: "#e0e0e0", color: "#a13800" }}
          button
          onClick={() => setOpenOficial(!openOficial)}
        >
          {openOficial ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={"INFORMACIÓN OFICIAL"} />
        </ListItem>
        <Collapse in={openOficial} timeout="auto" unmountOnExit>
          <Grid
            container
            spacing={3}
            className={classes.paper}
            style={{ marginTop: 10 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <AsyncSelect
                label="Tipo de Agenda"
                name="agenda/tipo-agenda"
                labelProp="nombre"
                defaultValue={
                  model && { id: model.idTipoAgenda, nombre: model.tipoAgenda }
                }
                onChange={setIdTipoAgendaCallback}
                extraParams={asyncSelectParams}
                ignoreIds={[2]}
                propId="id"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {participante && modelsParticipante ? (
                <AsyncSelect
                  label="Participante Politico"
                  name="participante-politico"
                  labelProp="nombre"
                  defaultValue={
                    model && {
                      id: model.idParticipantePolitico,
                      nombre: model.participantePolitico,
                    }
                  }
                  onChange={setIdTipoAgendaParticipanteCallback}
                  extraParams={asyncSelectParams}
                />
              ) : (
                <SingleSelect
                  label="Puesto"
                  options={opcionesPuesto}
                  onChange={setPuestoCallback}
                  helperText={responseError && responseError.puesto}
                  error={Boolean(responseError && responseError.puesto)}
                  defaultValue={model && model.puesto}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Clave de Elector"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.claveElector}
                error={Boolean(responseError && responseError.claveElector)}
                onChange={setClaveElectorCallback}
                inputProps={{
                  value: claveElector,
                  maxLength: 18,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Folio Elector"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.folioElector}
                error={Boolean(responseError && responseError.folioElector)}
                onChange={setFolioElectorCallback}
                inputProps={{
                  value: folioElector,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Curp"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.curp}
                error={Boolean(responseError && responseError.curp)}
                onChange={setCurpCallback}
                inputProps={{
                  value: curp,
                  maxLength: 18,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="RFC"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.rfc}
                error={Boolean(responseError && responseError.rfc)}
                onChange={setRfcCallback}
                inputProps={{
                  value: rfc,
                  maxLength: 13,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Observaciones"
                size="small"
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.observaciones}
                error={Boolean(responseError && responseError.observaciones)}
                onChange={setObservacionesCallback}
                inputProps={{
                  value: observaciones,
                }}
              />
            </Grid>
          </Grid>
        </Collapse>
        <ListItem
          style={{ backgroundColor: "#e0e0e0", color: "#a13800" }}
          button
          onClick={() => setOpenDireccion(!openDireccion)}
        >
          {openDireccion ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={"INFORMACIÓN DE LA DIRECCIÓN"} />
        </ListItem>
        <Collapse in={openDireccion} timeout="auto" unmountOnExit>
          <Grid
            container
            spacing={3}
            className={classes.paper}
            style={{ marginTop: 10 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Calle"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.calle}
                error={Boolean(responseError && responseError.calle)}
                onChange={setCalleCallback}
                inputProps={{
                  value: calle,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Número Exterior"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.numeroExterior}
                error={Boolean(responseError && responseError.numeroExterior)}
                onChange={setNumeroExteriorCallback}
                inputProps={{
                  value: numeroExterior,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Número Interior"
                size="small"
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.numeroInterior}
                error={Boolean(responseError && responseError.numeroInterior)}
                onChange={setNumeroInteriorCallback}
                inputProps={{
                  value: numeroInterior,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Colonia"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.colonia}
                error={Boolean(responseError && responseError.colonia)}
                onChange={setColoniaCallback}
                inputProps={{
                  value: colonia,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Estado"
                name="estado"
                defaultValue={model && model.estadoDomicilio}
                labelProp="nombre"
                onChange={setIdEstadoDomicilioCallback}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Distrito"
                name="distrito"
                defaultValue={model && model.distrito}
                labelProp="nombre"
                onChange={setIdDistritoCallback}
                clearInput={clearDistrito}
                extraParams={distritoParamsMemo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Sección"
                name="seccion"
                defaultValue={model && model.seccion}
                labelProp="nombre"
                onChange={setIdSeccionCallback}
                clearInput={clearSeccion}
                extraParams={seccionParamsMemo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AsyncAutocompleteSearch
                label="Municipio"
                name="municipio"
                defaultValue={model && model.municipio}
                labelProp="nombre"
                onChange={setIdMunicipioCallback}
                clearInput={clearMunicipio}
                extraParams={municipioParamsMemo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Localidad"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.localidad}
                error={Boolean(responseError && responseError.localidad)}
                onChange={setLocalidadCallback}
                inputProps={{
                  value: localidad,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Código Postal"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.codigoPostal}
                error={Boolean(responseError && responseError.codigoPostal)}
                onChange={setCodigoPostalCallback}
                inputProps={{
                  value: codigoPostal,
                  maxLength: 5,
                  min: 0,
                }}
              />
            </Grid>
          </Grid>
        </Collapse>
        <ListItem
          style={{ backgroundColor: "#e0e0e0", color: "#a13800" }}
          button
          onClick={() => setOpenContacto(!openContacto)}
        >
          {openContacto ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={"INFORMACIÓN DE CONTACTO"} />
        </ListItem>
        <Collapse in={openContacto} timeout="auto" unmountOnExit>
          <Grid
            container
            spacing={3}
            className={classes.paper}
            style={{ marginTop: 10 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Correo electrónico"
                size="small"
                required
                variant="outlined"
                fullWidth
                type="email"
                helperText={responseError && responseError.correo}
                error={Boolean(responseError && responseError.correo)}
                onChange={setCorreoCallback}
                inputProps={{
                  value: correo,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono Hogar"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.telefonoHogar}
                error={Boolean(responseError && responseError.telefonoHogar)}
                onChange={setTelefonoHogarCallback}
                inputProps={{
                  value: formatPhoneNumber(telefonoHogar),
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono Trabajo"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.telefonoTrabajo}
                error={Boolean(responseError && responseError.telefonoTrabajo)}
                onChange={setTelefonoTrabajoCallback}
                inputProps={{
                  value: formatPhoneNumber(telefonoTrabajo),
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono Celular"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.telefonoCelular}
                error={Boolean(responseError && responseError.telefonoCelular)}
                onChange={setTelefonoCelularCallback}
                inputProps={{
                  value: formatPhoneNumber(telefonoCelular),
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono Otro"
                size="small"
                required
                variant="outlined"
                fullWidth
                helperText={responseError && responseError.telefonoOtro}
                error={Boolean(responseError && responseError.telefonoOtro)}
                onChange={setTelefonoOtroCallback}
                inputProps={{
                  value: formatPhoneNumber(telefonoOtro),
                  maxLength: 10,
                }}
              />
            </Grid>
          </Grid>
        </Collapse>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSubmitPressed}
              >
                {editing ? "EDITAR" : "CREAR"} AGENDA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AgendasDetail;
