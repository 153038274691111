import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import { Add, Close, TableChart } from "@material-ui/icons";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  List,
  ListItem,
  Collapse,
  ListItemText,
  CircularProgress,
  Card,
  Box,
  IconButton,
  TextField,
} from "@material-ui/core";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useAlert } from "../../../hooks/useAlert";
import { useAuth } from "../../../hooks/useAuth";
import { useModels } from "../../../hooks/useModels";
import TablePageLayout from "../../../components/TablePageLayout";
import Services from "../../../services";
import FullScrennLoader from "../../../components/FullScreenLoader";
import CreatableAutocomplete from "../../../components/CreatableAutocomplete";
import AlertDialog from "../../../components/AlertDialog";
import DatePicker from "../../../components/DatePicker";
import AsyncAutocompleteSearch from "../../../components/AsyncAutocompleteSearch";
import DialogReprecentante from "./dialogReprecentante";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TablePagination from "../../../components/TablePagination";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import { add } from "date-fns/esm";

const request = {
  name: "grupo-trabajo",
  extraParams: {
    ordenar: "numeroGrupoTrabajo.asc",
  },
  expand: "integrantes,representantes.agenda",
};

const requestActa = {
  name: "acta",
  extraParams: {
    estatusMotivoRecuento: "con-motivo-recuento",
  },
  expand: "motivoDeRecuento,actaRegistroVoto.estatusActa",
};

const funcionariosRequest = {
  name: "agenda",
  fields:
    "idAgenda, nombreCompleto, puesto, nombre, apellidoPaterno, apellidoMaterno, usuario",
  extraParams: {
    limit: -1,
    idTipoAgenda2: [2, 3, 4, 6, 9, 10].join(","),
  },
  expand: "usuario",
};

const idsPuestoReprensete = [11, 12];

const GruposTrabajo = () => {
  const history = useHistory();
  const { user, userLoading } = useAuth();
  const [models, modelsLoading, modelsError, modelsPage, refreshModels] =
    useModels(request);
  const [modelsActa, modelsActaLoading] = useModels(requestActa);
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(true);
  const [puestos, setPuestos] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [funcionarios, funcionariosLoading] = useModels(funcionariosRequest);
  const [integrantes, setIntegrantes] = useState([]);
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [representante, setRepresentante] = useState(null);
  const [indexRepresentante, setIndexRepresentante] = useState(0);
  const [participantesPolitico, setParticipantesPolitico] = useState([]);
  const [nuevoPR, setNuevoPR] = useState(false);
  const [puntosRecuento, setPuntosRecuento] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [idIntegrantePR, setIdIntegrantePR] = useState(null);
  const [nombreIntegrantePR, setNombreIntegrantePR] = useState(null);
  const [idGrupoTrabajo, setIdGrupoTrabajo] = useState(null);
  const [totalGT, setTotalGT] = useState([]);
  const [numeroGrupoTrabajo, setNumeroGrupoTrabajo] = useState(null);
  const [idGrupoTrabajoPR, setIdGrupoTrabajoPR] = useState(0);
  const [representantesGT, setRepresentantesGT] = useState([]);
  const [agendasUsadas, setAgendasUsadas] = React.useState([]);
  const [indiceGT, setIndiceGT] = React.useState([]);

  const [idGT, setIdGT] = useState(null);

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const gtprParamsMemo = React.useMemo(
    () => ({
      idGrupoTrabajo: idGrupoTrabajo,
    }),
    [idGrupoTrabajo]
  );

  const [
    modelsGTPR,
    modelsLoadingGTPR,
    modelsErrorGTPR,
    modelsPageGTPR,
    refreshModelsGTPR,
    deleteModelGTPR,
  ] = useModels({
    name: "grupo-trabajo-paquete-recuento",
    expand: "acta",
    ordenar: "idActa.asc",
    extraParams: gtprParamsMemo,
  });

  const changePageGTPR = (page) => {
    let params = {
      name: "grupo-trabajo-paquete-recuento",
      expand: "acta",
      ordenar: "idActa.asc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      idGrupoTrabajo: idGrupoTrabajo,
    };
    refreshModelsGTPR(true, params);
  };
  const header = [];

  const agendaParamsMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda: 12,
    }),
    []
  );

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      try {
        const response = await Services.get("grupo-trabajo-puesto");
        const _participantesPolitico = await Services.get(
          "participante-politico"
        );

        if (mounted) {
          setParticipantesPolitico(_participantesPolitico.resultado);
          setPuestos(response.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    getData();

    return () => (mounted = false);
  }, []);

  const paquetes = async () => {
    const response3 = await Services.post("grupo-trabajo/paquetes-por-consejo");
    setTotal(response3);
  };

  const total_GT = React.useCallback(async () => {
    const responseGT = await Services.post(
      "grupo-trabajo/total-actas-por-grupo",
      {
        idConsejoElectoral: user?.consejoElectoral.idConsejoElectoral,
      }
    );
    setTotalGT(responseGT);
  }, [user]);

  useEffect(() => {
    paquetes();
    if (models.length && user) {
      total_GT();
      let _integrantes = [];
      let _representantesConSalida = [];
      models.forEach((g) => {
        _integrantes = [
          ..._integrantes,
          ...g?.integrantes.map((i) => i.idAgenda).filter((i) => i),
        ];
        _representantesConSalida = [
          ..._representantesConSalida,
          ...g?.representantes.filter((r) => r.fechaSalida),
        ];
      });

      setIntegrantes(_integrantes);
      setGrupos(
        models.map((m) => ({
          ...m,
          total: 0,
          expanded: false,
          integrantes: m.integrantes.sort((a, b) =>
            a.idGrupoTrabajoPuesto > b.idGrupoTrabajoPuesto ? 1 : -1
          ),
          representantes: m.representantes
            .filter((r) => !r.fechaSalida)
            .map((r) => ({
              ...r,
              participantePolitico: r.agenda.participantePolitico,
              idParticipantePolitico: r.agenda.idParticipantePolitico,
              idGrupoTrabajoNuevo: "",
            })),
          representantesConSalida: _representantesConSalida,
        }))
      );
    }
  }, [models, total_GT, user]);

  React.useEffect(() => {
    setAgendasUsadas((usadas) => {
      let arr = [...usadas];
      grupos.forEach((g) => {
        g.representantes.forEach((r) => {
          if (r?.agenda?.idAgenda) {
            arr = [...arr, r?.agenda?.idAgenda];
          }
        });
      });
      // eliminar duplicados antes del return - optimizacion
      return arr;
    });
  }, [grupos]);

  if (loading || userLoading || modelsActaLoading) return <FullScrennLoader />;

  const guardar = async (_idGrupoTrabajo) => {
    try {
      const grupo = grupos.find((g) => g.idGrupoTrabajo === _idGrupoTrabajo);

      if (grupo.representantes.some((r) => !r.puesto)) {
        showAlert({
          severity: "warning",
          message: "Favor de verificar los puestos de los Representantes.",
        });

        return;
      }

      setSaving(true);

      const data = {
        misOtrosGrupos: grupos
          .filter((g) => g.idGrupoTrabajo !== _idGrupoTrabajo)
          .map((g) => g.idGrupoTrabajo),
        integrantes: grupos.find((g) => g.idGrupoTrabajo === _idGrupoTrabajo)
          .integrantes,
        idGrupoTrabajo: _idGrupoTrabajo,
      };

      const dataR = {
        idGrupoTrabajo: _idGrupoTrabajo,
        representantes: grupo.representantes,
        representantesSarcar: grupo.representantesConSalida,
      };

      const dataPR = {
        puntosRecuento: puntosRecuento,
        idGrupoTrabajo: idGrupoTrabajoPR,
      };

      await Services.post("grupo-trabajo-representante/guardar2", dataR);
      await Services.post("grupo-trabajo-integrante/guardar", data);
      await Services.post("grupo-trabajo-integrante/guardar", dataPR);

      showAlert({
        severity: "success",
        message: "Conformación guardada con exito.",
      });

      await refresh();
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const refresh = async () => {
    const params = {
      name: "grupo-trabajo",
      extraParams: {
        ordenar: "numeroGrupoTrabajo.asc",
      },
      expand: "integrantes,representantes.agenda",
      isCargando: false,
    };

    await refreshModels(true, params);
  };

  const sacarRepresentante = () => {
    let _grupos = [...grupos];

    if (representante.idGrupoTrabajoRepresentante) {
      _grupos = _grupos.map((g) => ({
        ...g,
        representantesConSalida: [
          ...g.representantesConSalida,
          { ...representante, idGrupoTrabajoNuevo: "" },
        ],
      }));
    }

    setGrupos(
      _grupos.map((g) => ({
        ...g,
        representantes: g.representantes.filter(
          (r, i) => i !== indexRepresentante
        ),
      }))
    );
    setOpenAlertDialog(false);
  };

  return (
    <TablePageLayout
      title="GRUPOS DE TRABAJO"
      links={[
        {
          to: "/grupos-trabajo",
          icon: <GroupWorkIcon />,
          label: "Grupos-Trabajo",
        },
        {
          to: "/grupos-trabajo/grupos",
          icon: <ListIcon />,
          label: "Lista de Grupos",
        },
        {
          to: "/grupos-trabajo/recuentos-pr",
          icon: <GroupWorkIcon />,
          label: "Lista de Recuentos",
        },
      ]}
      history={history}
      SearchComponents={<></>}
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <Card>
          <Grid container style={{ padding: 20 }}>
            {total &&
            total.resultado &&
            total.resultado.length &&
            total.resultado[0] >= 1 &&
            total.resultado[0] <= 24 ? (
              <Typography variant="h5" style={{ color: "black" }}>
                Se ha detectado
                <Typography variant="b" style={{ color: "red" }}>
                  &nbsp; {total && total?.resultado[0]} &nbsp;
                </Typography>
                Paquetes para Recuento, se definen a continuación sus
                Integrantes para Pleno
              </Typography>
            ) : (
              <>
                <Typography variant="h5" style={{ color: "black" }}>
                  Se ha detectado
                  <label style={{ color: "red" }}>
                    &nbsp;{total && total?.resultado[0]}&nbsp;
                  </label>
                  Paquetes para Recuento, se definen a continuación sus
                  <label style={{ color: "red" }}>
                    &nbsp;{grupos?.length}&nbsp;
                  </label>
                  Grupos de Trabajo y{" "}
                  <label style={{ color: "red" }}>
                    {grupos?.length ? grupos[0].numeroPuntosRecuento : 0}
                  </label>{" "}
                  Puntos de Recuento
                </Typography>
              </>
            )}
            <List style={{ width: "100%" }}>
              {grupos &&
                grupos.length &&
                grupos.map((grupo, indexG) => (
                  <div key={indexG}>
                    <ListItem
                      style={{ backgroundColor: "#e0e0e0" }}
                      button
                      onClick={() => {
                        setIdGrupoTrabajo(grupo.idGrupoTrabajo);
                        setNumeroGrupoTrabajo(grupo.numeroGrupoTrabajo);
                        setGrupos(
                          grupos.map((g, i) => ({
                            ...g,
                            expanded: i === indexG ? !g.expanded : g.expanded,
                          }))
                        );
                      }}
                    >
                      {total &&
                      total.resultado &&
                      total.resultado.length &&
                      total.resultado[0] >= 1 &&
                      total.resultado[0] <= 24 ? (
                        <ListItemText
                          style={{ color: "black" }}
                          primary={<b style={{ fontSize: 17 }}>{"Pleno"} </b>}
                        />
                      ) : (
                        <ListItemText
                          style={{ color: "black" }}
                          primary={
                            <b style={{ fontSize: 17 }}>
                              {"Grupo de Trabajo " + grupo.numeroGrupoTrabajo}
                            </b>
                          }
                        />
                      )}
                      {totalGT &&
                      totalGT.resultado &&
                      totalGT.resultado.length ? (
                        <ListItemSecondaryAction
                          style={{ paddingRight: "11%", color: "black" }}
                        >
                          <b
                            style={{ fontSize: 17 }}
                          >{`Total de Paquetes: ${totalGT?.resultado[indexG]?.total}`}</b>
                        </ListItemSecondaryAction>
                      ) : (
                        ""
                      )}

                      {grupo.expanded ? (
                        <ExpandLess style={{ color: "black" }} />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItem>
                    <Collapse in={grupo.expanded} timeout="auto" unmountOnExit>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Puesto</TableCell>
                              <TableCell>Persona</TableCell>
                              <TableCell>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    setIdGrupoTrabajo(grupo.idGrupoTrabajo);

                                    // if(row.nombre != null && row.apellidoPaterno != null)
                                    //   setNombreIntegrantePR(`${row.nombre} ${row.apellidoPaterno} ${row.apellidoMaterno}`);
                                    // else
                                    //   setNombreIntegrantePR(`Sin persona asignada.`);

                                    handleClickOpen3();
                                  }}
                                >
                                  Paquetes
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {grupo?.integrantes?.map((row, indexI) => (
                              <TableRow key={indexI}>
                                <TableCell
                                  component="th"
                                  style={{ width: "25%" }}
                                >
                                  {row.idGrupoTrabajoPuesto === 3
                                    ? puestos
                                        .filter(
                                          (p) =>
                                            !idsPuestoReprensete.includes(
                                              p.idGrupoTrabajoPuesto
                                            ) &&
                                            p.idGrupoTrabajoPuesto ==
                                              row.idGrupoTrabajoPuesto
                                        )
                                        .map((p) => (
                                          <Typography>
                                            {p.puesto} {row.numeroPR}:
                                          </Typography>
                                        ))
                                    : puestos
                                        .filter(
                                          (p) =>
                                            !idsPuestoReprensete.includes(
                                              p.idGrupoTrabajoPuesto
                                            ) &&
                                            p.idGrupoTrabajoPuesto ==
                                              row.idGrupoTrabajoPuesto
                                        )
                                        .map((p) => (
                                          <Typography>{p.puesto}:</Typography>
                                        ))}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  style={{ width: "100%" }}
                                >
                                  <FormControl
                                    disabled={funcionariosLoading}
                                    fullWidth
                                  >
                                    <CreatableAutocomplete
                                      entry={
                                        funcionarios.find(
                                          (f) =>
                                            row.idAgenda &&
                                            f.idAgenda === row.idAgenda
                                        )
                                          ? funcionarios.find(
                                              (f) =>
                                                row.idAgenda &&
                                                f.idAgenda === row.idAgenda
                                            )
                                          : ""
                                      }
                                      label="Selecciona una persona"
                                      data={funcionarios.filter(
                                        (f) => !integrantes.includes(f.idAgenda)
                                      )}
                                      labelKey="nombreCompleto"
                                      onChange={(agenda) => {
                                        const idAgenda = agenda
                                          ? agenda.idAgenda
                                          : "";
                                        let funcionario = {};

                                        if (idAgenda) {
                                          setIntegrantes([
                                            ...integrantes,
                                            idAgenda,
                                          ]);
                                          funcionario = funcionarios.find(
                                            (f) => f.idAgenda === idAgenda
                                          );
                                        } else {
                                          setIntegrantes(
                                            integrantes.filter(
                                              (i) => i !== row.idAgenda
                                            )
                                          );
                                          funcionario = {
                                            idAgenda: "",
                                            nombre: "",
                                            apellidoMaterno: "",
                                            apellidoPaterno: "",
                                          };
                                        }

                                        setGrupos(
                                          grupos.map((g, i) => ({
                                            ...g,
                                            integrantes:
                                              i === indexG
                                                ? g.integrantes.map((inte, i) =>
                                                    i === indexI
                                                      ? {
                                                          ...inte,
                                                          idAgenda: idAgenda,
                                                          nombre:
                                                            funcionario.nombre,
                                                          apellidoMaterno:
                                                            funcionario.apellidoMaterno,
                                                          apellidoPaterno:
                                                            funcionario.apellidoPaterno,
                                                        }
                                                      : inte
                                                  )
                                                : g.integrantes,
                                          }))
                                        );
                                      }}
                                      dialogForm={null}
                                      loading={funcionariosLoading}
                                    />
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            ))}

                            {puntosRecuento.map((h, n) => (
                              <TableRow key={n}>
                                <TableCell
                                  component="th"
                                  style={{ width: "25%" }}
                                >
                                  <Typography>
                                    Auxiliar de Recuento (PR) :
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  style={{ width: "100%" }}
                                >
                                  <FormControl
                                    disabled={funcionariosLoading}
                                    fullWidth
                                  >
                                    <CreatableAutocomplete
                                      index={n}
                                      label="Selecciona una persona"
                                      data={funcionarios.filter(
                                        (f) => !integrantes.includes(f.idAgenda)
                                      )}
                                      labelKey="nombreCompleto"
                                      onChange={(agenda) => {
                                        const idAgenda = agenda
                                          ? agenda.idAgenda
                                          : "";
                                        let funcionario = {};

                                        if (idAgenda) {
                                          funcionario = funcionarios.find(
                                            (f) => f.idAgenda === idAgenda
                                          );
                                        } else {
                                          funcionario = {
                                            idAgenda: "",
                                            nombre: "",
                                            apellidoMaterno: "",
                                            apellidoPaterno: "",
                                          };
                                        }
                                        setPuntosRecuento(
                                          puntosRecuento.map((a, index) =>
                                            index === n
                                              ? {
                                                  ...a,
                                                  idGrupoTrabajoPuesto: 3,
                                                  idGrupoTrabajo:
                                                    grupo.idGrupoTrabajo,
                                                  nombre: funcionario.nombre,
                                                  apellidoMaterno:
                                                    funcionario.apellidoMaterno,
                                                  apellidoPaterno:
                                                    funcionario.apellidoPaterno,
                                                  idAgenda:
                                                    funcionario.idAgenda,
                                                }
                                              : a
                                          )
                                        );
                                      }}
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      setPuntosRecuento((v) =>
                                        v.filter((o, index) => index !== n)
                                      );
                                    }}
                                  >
                                    <Close color="secondary" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <br />
                      <Box display="flex" p={1}>
                        <Box flexGrow={1}></Box>
                        <Box mr={1}>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() => {
                              setNuevoPR(true);
                              setPuntosRecuento((v) => [
                                ...v,
                                {
                                  idGrupoTrabajoPuesto: "",
                                  idGrupoTrabajo: "",
                                  nombre: "",
                                  apellidoPaterno: "",
                                  apellidoMaterno: "",
                                  idAgenda: "",
                                },
                              ]);
                            }}
                          >
                            AGREGAR PR
                            <Add />
                          </Button>
                        </Box>
                      </Box>
                      <hr />
                      <div style={{ width: "100%", marginTop: 20 }}>
                        <Box display="flex" p={1}>
                          <Box flexGrow={1}>
                            <Typography>Representantes del Partido</Typography>
                          </Box>
                          <Box mr={1}>
                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() => {
                                setGrupos((g) =>
                                  g.map((grupo, i) =>
                                    i === indexG
                                      ? {
                                          ...grupo,
                                          representantes: [
                                            ...grupo.representantes,
                                            {
                                              idGrupoTrabajoRepresentante: null,
                                            },
                                          ],
                                        }
                                      : grupo
                                  )
                                );
                              }}
                            >
                              AGREGAR REPRESENTANTE
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() => {
                                setIdGrupoTrabajo(grupo.idGrupoTrabajo);
                                setOpen(true);
                              }}
                            >
                              CREAR NUEVO REPRESENTANTE
                            </Button>
                          </Box>
                        </Box>
                      </div>
                      {grupo?.representantes?.length ? (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Puesto en Grupo</TableCell>
                              <TableCell>Persona</TableCell>
                              <TableCell>Participante Político</TableCell>
                              <TableCell>Fecha acreditación</TableCell>
                              <TableCell>Borrar</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {grupo.representantes.map((row, indexI) => (
                              <TableRow key={indexI}>
                                <TableCell
                                  component="th"
                                  style={{ width: "10%" }}
                                >
                                  {!row.idGrupoTrabajoRepresentante ? (
                                    <FormControl
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                    >
                                      <Select
                                        disabled={
                                          !!row.idGrupoTrabajoRepresentante
                                        }
                                        value={row.puesto}
                                        onChange={(event) => {
                                          setGrupos(
                                            grupos.map((g, ig) => ({
                                              ...g,
                                              representantes:
                                                ig === indexG
                                                  ? g.representantes.map(
                                                      (rep, i) =>
                                                        i === indexI
                                                          ? {
                                                              ...rep,
                                                              puesto:
                                                                puestos.find(
                                                                  (p) =>
                                                                    p.puesto ===
                                                                    event.target
                                                                      .value
                                                                ).puesto,
                                                            }
                                                          : rep
                                                    )
                                                  : g.representantes,
                                            }))
                                          );
                                        }}
                                      >
                                        {puestos
                                          .filter((p) =>
                                            idsPuestoReprensete.includes(
                                              p.idGrupoTrabajoPuesto
                                            )
                                          )
                                          .map((p) => (
                                            <MenuItem
                                              key={p.idGrupoTrabajoPuesto}
                                              value={p.puesto}
                                            >
                                              {p.puesto}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    <Typography>{row.puesto}</Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  style={{ width: "30%" }}
                                >
                                  {!row.idGrupoTrabajoRepresentante ? (
                                    <AsyncAutocompleteSearch
                                      disabled={
                                        !!row.idGrupoTrabajoRepresentante
                                      }
                                      label="Persona"
                                      name="agenda"
                                      labelProp="nombreCompleto"
                                      onChange={(index, e, v) => {
                                        if (v) {
                                          setGrupos((g) =>
                                            g.map((grupo, i) =>
                                              i === indexG
                                                ? {
                                                    ...grupo,
                                                    representantes:
                                                      grupo.representantes.map(
                                                        (r, indexR) =>
                                                          indexR === index
                                                            ? {
                                                                ...r,
                                                                agenda: v,
                                                                idParticipantePolitico:
                                                                  v.idParticipantePolitico,
                                                                participantesPolitico:
                                                                  v.participantePolitico,
                                                              }
                                                            : r
                                                      ),
                                                  }
                                                : grupo
                                            )
                                          );
                                        }
                                      }}
                                      extraParams={agendaParamsMemo}
                                      index={indexI}
                                      ignoreIds={agendasUsadas}
                                      propId="idAgenda"
                                      defaultValue={
                                        grupos[indexG].representantes[indexI]
                                          ?.agenda
                                      }
                                    />
                                  ) : (
                                    <Typography>
                                      {row?.agenda?.nombreCompleto}
                                    </Typography>
                                  )}
                                  {/* salida */}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  style={{ width: "20%" }}
                                >
                                  <Typography>
                                    {row?.agenda?.participantePolitico}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  style={{ width: "20%" }}
                                >
                                  {!row.idGrupoTrabajoRepresentante ? (
                                    <FormControl fullWidth>
                                      <DatePicker
                                        disabled={
                                          !!row.idGrupoTrabajoRepresentante
                                        }
                                        disableFuture={false}
                                        label="Fecha acreditación"
                                        value={
                                          row.fechaAcreditacion || new Date()
                                        }
                                        onChange={(value) =>
                                          setGrupos(
                                            grupos.map((g, i) => ({
                                              ...g,
                                              representantes:
                                                i === indexG
                                                  ? g.representantes.map(
                                                      (rep, i) =>
                                                        i === indexI
                                                          ? {
                                                              ...rep,
                                                              fechaAcreditacion:
                                                                value,
                                                            }
                                                          : rep
                                                    )
                                                  : g.representantes,
                                            }))
                                          )
                                        }
                                      />
                                    </FormControl>
                                  ) : (
                                    <Typography>
                                      {row?.fechaAcreditacion}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => {
                                      setRepresentante(row);
                                      setIndexRepresentante(indexI);
                                      setOpenAlertDialog(true);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <Card style={{ margin: 10 }}>
                          <Typography align="center">
                            Sin Representantes.
                          </Typography>
                        </Card>
                      )}
                      <Button
                        style={{ margin: 10, float: "right" }}
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={async () => {
                          setIdGrupoTrabajoPR(grupo.idGrupoTrabajo);
                          await guardar(grupo.idGrupoTrabajo);
                        }}
                        disabled={saving}
                      >
                        {saving ? (
                          <CircularProgress
                            size={25}
                            style={{ color: "white" }}
                          />
                        ) : (
                          "GUARDAR CONFORMACIONES"
                        )}
                      </Button>
                    </Collapse>
                    <hr style={{ margin: 0, padding: 0 }} />
                  </div>
                ))}
            </List>
          </Grid>
          <DialogReprecentante
            open={open}
            onClose={() => setOpen(false)}
            onAccept={(representante) => {
              setGrupos(
                grupos.map((g, i) =>
                  idGrupoTrabajo === g.idGrupoTrabajo
                    ? {
                        ...g,
                        representantes: [
                          ...g.representantes,
                          {
                            agenda: representante,
                            idParticipantePolitico:
                              representante.idParticipantePolitico,
                            participantePolitico:
                              representante.participantePolitico,
                            puesto: "",
                            idGrupoTrabajoPuesto: "",
                            idGrupoTrabajoRepresentante: "",
                          },
                        ],
                      }
                    : { ...g }
                )
              );
              setOpen(false);
            }}
            participantesPolitico={participantesPolitico}
          />
          <AlertDialog
            open={openAlertDialog}
            onClose={() => setOpenAlertDialog(false)}
            content={""}
            title="Seguro que deseas sacar este Representante del grupo."
            onAccept={sacarRepresentante}
          />
          <Dialog
            fullWidth={true}
            maxWidth={true}
            open={open3}
            onClose={handleClose3}
            aria-labelledby="max-width-dialog-title"
          >
            {total?.resultado[0] >= 1 || total?.resultado[0] <= 24 ? (
              <DialogTitle id="max-width-dialog-title">
                {" "}
                PAQUETES A RECONTAR
              </DialogTitle>
            ) : (
              <DialogTitle id="max-width-dialog-title">
                Grupo Trabajo {numeroGrupoTrabajo} <br /> PAQUETES A RECONTAR
              </DialogTitle>
            )}
            <DialogContent>
              <DialogContentText>
                {idGrupoTrabajo && (
                  <TablePagination
                    header={header}
                    data={modelsGTPR}
                    paginatedApi
                    changePageApi={changePageGTPR}
                    count={modelsPageGTPR ? modelsPageGTPR.total : 0}
                    extraRows={[
                      {
                        prop: "nombreActa",
                        name: "Seccion Casilla",
                        cell: (row) => (
                          <>{row.acta ? row.acta.nombreCompletoActa : ""}</>
                        ),
                      },
                      {
                        prop: "estatus",
                        name: "Estatus",
                        cell: (row) => (
                          <>{row.recuento ? "RECONTADA" : "SIN RECONTAR"}</>
                        ),
                      },
                      {
                        prop: "acciones",
                        name: "Acciones",
                        cell: (row, index) => (
                          <div>
                            <Button variant="contained" color="primary">
                              Recuento
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                  />
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose3} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      }
    />
  );
};

export default GruposTrabajo;
