import React from "react";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/es";

const DatePicker = ({
  error,
  helperText,
  disabled,
  minDate,
  maxDate,
  disableFuture = true,
  ...rest
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <KeyboardDatePicker
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        size="small"
        variant="inline"
        // keyboardIcon={<DateRangeRounded fontSize="small" />}
        disableFuture={disableFuture}
        error={error}
        helperText={helperText}
        inputVariant="outlined"
        format="dd/MM/yyyy"
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
