const PERMISSIONS_BASE = {
  READ: 1,
  CREATE: 2,
  EDIT: 4,
  DELETE: 8,
  READ_ALL: 16,
};

const permisoExtraBodegaAdministracion = {
  ...PERMISSIONS_BASE,
  OPEN: 32,
  CLOSE: 64,
  STORE: 128,
  EXTRACT: 256,
};

const permisoExtraRecepcionPaquetes = {
  ...PERMISSIONS_BASE,
  MUNICIPAL: 32,
  DISTRITAL: 64,
  GUBERNATURA: 128,
};

function permisoToSpanish(permiso) {
  switch (permiso.value) {
    case PERMISSIONS_BASE.READ:
      return "VER";
    case PERMISSIONS_BASE.CREATE:
      return "CREAR";
    case PERMISSIONS_BASE.EDIT:
      return "EDITAR";
    case PERMISSIONS_BASE.DELETE:
      return "ELIMINAR";
    case PERMISSIONS_BASE.READ_ALL:
      return "VER TODO";
    default:
      return permiso.label;
  }
}

const encargadoDeBodega = [
  "bodegaAdministracion",
  "bodegaPaquetes",
  "bodegaBitacora",
];
const presidenteDeConsejo = [
  "paquetesRecepciones",
  "actaLectura",
  "actaDatosGenerales",
  "actaResultados",
  "reunionTrabajoRectificacion",
  "reunionTrabajoGruposDeTrabajo",
  "reunionTrabajoAvance",
  "reunionTrabajoReportes",
  "reunionTrabajoRepresentantes",
  "computoCotejo",
  "computoRecuento",
  "computoResultadosVotacion",
];


const presidenteDistrital = [
  "bodegaAdministracion",
  "bodegaPaquetes",
  "paquetesRecepciones",
  "actaLectura",
  "actaDatosGenerales",
  "actaResultados",
  "reunionTrabajoRectificacion",
  "reunionTrabajoGruposDeTrabajo",
  "reunionTrabajoAvance",
  "reunionTrabajoReportes",
  "reunionTrabajoRepresentantes",
  "computoCotejo",
  "computoRecuento",
  "computoResultadosVotacion",
  "gruposDeTrabajoRepresentantes",
  "sesionExtraordinaria",
  "computoRecuentoGt",
  "computoRecuentoGtRes",
  "controlCapacitacion",
];

const presidenteMunicipal = [
  "bodegaAdministracion",
  "bodegaPaquetes",
  "bodegaBitacora",
  "paquetesRecepciones",
  "actaLectura",
  "actaDatosGenerales",
  "actaResultados",
  "reunionTrabajoRectificacion",
  "reunionTrabajoGruposDeTrabajo",
  "reunionTrabajoAvance",
  "reunionTrabajoReportes",
  "reunionTrabajoRepresentantes",
  "computoCotejo",
  "computoRecuento",
  "computoResultadosVotacion",
  "gruposDeTrabajoRepresentantes",
  "sesionExtraordinaria",
  "computoRecuentoGt",
  "computoRecuentoGtRes",
  "controlCapacitacion",
];

const auxAcreditacion = [
  "gruposDeTrabajoRepresentantes",
];

const auxDatosGenerales = [
  "actaDatosGenerales",
];

const auxCaptura = [
  "computoCotejo",
  "computoRecuentoGt",
  "computoRecuento",
];

const auxRecepcion = [
  "paquetesRecepciones",
];

const auxLectura = [
  "actaLectura",
];


const recepcionPaquetes = ["paquetesRecepciones"];

export {
  // PERMISOS EXTRA
  permisoExtraBodegaAdministracion,
  permisoExtraRecepcionPaquetes,
  // METODOS
  permisoToSpanish,
  // HELPERS DE PRESET
  encargadoDeBodega,
  presidenteDeConsejo,
  presidenteMunicipal,
  presidenteDistrital,
  auxDatosGenerales,
  auxCaptura,
  auxRecepcion,
  auxLectura,
  recepcionPaquetes,
  auxAcreditacion,
};

export default PERMISSIONS_BASE;
