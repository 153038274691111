import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
  DialogContent,
  FormControl,
  TextField,
  DialogTitle,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import DialogFuncionario from "./dialogFuncionario";
import AsyncCreatableAutocomplete from "../../components/AsyncCreatableAutocomplete";
import CreateEntryDialog from "../../components/CreateEntryDialog";
import Services from "../../services";
import { useAlert } from "../../hooks/useAlert";

const miStorage = window.localStorage;
const titles = [
  "Almacenar paquete en la Bodega.",
  "Sacar paquete de la Bodega.",
  "",
];
const subTitles = ["Fecha y hora de ingreso.", "Fecha y hora de salida.", ""];
const days = [...Array(31).keys()];
const _date = new Date();

const DialogPaqueteDetail = ({
  type = "",
  nombre = "",
  open,
  onClose,
  idActa = "",
  idConsejoElectoral,
  idAgendaEntrega = "",
  idAgendaRecibe = "",
  agendaEntrega = "",
  agendaRecibe = "",
  time,
  onCreateAgendaEntrega = null,
  onCreateAgendaRecibe = null,
}) => {
  const [data, setData] = useState({
    idActa: "",
    day: _date.getDate().toString(),
    month: "06",
    time: "",
    idMotivoPaquete: "",
    idAgendaEntrega: "",
    idAgendaRecibe: "",
    agendaEntrega: "",
    agendaRecibe: "",
  });
  const [motivos, setMotivos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [responseError, setResponseError] = React.useState(null);
  const [openDialogFuncionario, setOpenDialogFuncionario] = useState(false);
  const { showAlert } = useAlert();

  const ignoreIds = React.useMemo(
    () => [1, 2, 7, 8, 12, data.idAgendaEntrega],
    [data]
  );

  const agendaParamsMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
      idTipoAgenda2: [3, 4, 5, 6, 9, 10, 11, 13].join(","),
    }),
    []
  );

  useEffect(() => {
    setData((dataViejo) => ({
      ...dataViejo,
      idAgendaEntrega: idAgendaEntrega ? idAgendaEntrega : agendaEntrega?.idAgenda,
      idAgendaRecibe: idAgendaRecibe ? idAgendaRecibe : agendaRecibe?.idAgenda,
      idActa: idActa,
      time: time,
      agendaEntrega: agendaEntrega,
      agendaRecibe: agendaRecibe,
    }));
  }, [
    idAgendaEntrega,
    idAgendaRecibe,
    idActa,
    time,
    agendaEntrega,
    agendaRecibe,
  ]);

  useEffect(() => {
    if (motivos.length && type === "E") {
      setData((dataViejo) => ({
        ...dataViejo,
        idMotivoPaquete: motivos[1].idMotivoPaquete,
      }));
    }
  }, [type, motivos]);

  useEffect(() => {
    let mounted = true;

    const getMotivoPaquete = async () => {
      try {
        const response = await Services.get("motivo-paquete");

        if (mounted) {
          setMotivos(response.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    getMotivoPaquete();

    return () => (mounted = false);
  }, []);

  if (loading) return null;

  const handleAccept = async () => {
    try {
      let _errors = [];

      for (const key in data) {
        if (!data[key]) {
          _errors.push(key);
        }
      }

      if (_errors.length) return;

      setSaving(true);

      let date;

      if (data.day > 9) {
        date = "2021-" + data.month + "-" + data.day.toString();
      } else {
        date = "2021-" + data.month + "-" + ("0" + data.day.toString());
      }

      const response = await Services.post("bitacora-paquete/guardar", {
        idActa: idActa,
        fecha: date + " " + data.time,
        idMotivoPaquete: data.idMotivoPaquete,
        idAgendaEntrega: data.idAgendaEntrega,
        idAgendaRecibe: data.idAgendaRecibe,
      });

      if (response.errores) {
        setResponseError(response.errores);
      }

      setData({
        idActa: "",
        time: format(
          new Date(
            new Date().toLocaleString(
              "en-US",
              Intl.DateTimeFormat().resolvedOptions().timeZone
            )
          ),
          "HH:mm"
        ),
        idMotivoPaquete: "",
        idAgendaEntrega: data.idAgendaEntrega.toString(),
        idAgendaRecibe: data.idAgendaRecibe.toString(),
        month: "06",
        day: _date.getDate().toString(),
      });

      if (motivos.length && type === "E") {
        setData({ ...data, idMotivoPaquete: motivos[1].idMotivoPaquete });
      }

      miStorage.setItem(
        "AgendaEntregaComputo21",
        JSON.stringify(data.agendaEntrega || "{}")
      );
      miStorage.setItem(
        "AgendaRecibeComputo21",
        JSON.stringify(data.agendaRecibe || "{}")
      );

      onClose(response.mensaje);
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle> {titles[type]} </DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: 10 }} color="secondary">
            {nombre}
          </Typography>
          <Typography style={{ marginBottom: 10 }}>
            {subTitles[type]}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <Select
                      value={data.month}
                      onChange={(event) =>
                        setData({ ...data, month: event.target.value })
                      }
                    >
                      <MenuItem value={"05"}>Mayo</MenuItem>
                      <MenuItem value={"06"}>Junio</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <Select
                      value={data.day}
                      onChange={(event) =>
                        setData({ ...data, day: event.target.value })
                      }
                    >
                      {days
                        .filter((d) => {
                          if (d == 30 && data.month === "06") {
                            return false;
                          } else {
                            return true;
                          }
                        })
                        .map((m) => (
                          <MenuItem key={m} value={m + 1}>
                            {m + 1}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Hora"
                type="time"
                size="small"
                value={data.time}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) =>
                  setData({ ...data, time: event.target.value })
                }
                helperText="Favor de seleccionar la hora."
                error={errors.includes("time")}
              />
            </Grid>
            <Grid item xs={6}>
              <AsyncCreatableAutocomplete
                helperText="Proporcione datos de la persona."
                error={Boolean(responseError && responseError.idAgendaEntrega)}
                label="Entrega"
                name="agenda"
                labelProp="nombreCompleto"
                onChange={(e, v) =>
                  setData((oldData) => ({
                    ...oldData,
                    idAgendaEntrega: v ? v.idAgenda : "",
                    agendaEntrega: v,
                  }))
                }
                dialogForm={CreateEntryDialog}
                dialogProps={{
                  idConsejoElectoral: idConsejoElectoral
                    ? idConsejoElectoral
                    : "",
                  setEntry: (entry) => {
                    setData((v) => ({
                      ...v,
                      idAgendaEntrega: entry.idAgenda,
                      nuevaAgendaEntrega: entry,
                    }));
                    if (typeof onCreateAgendaEntrega === "function") {
                      onCreateAgendaEntrega(entry);
                    }
                  },
                  ignoreIds: [1, 2, 7, 8, 12],
                }}
                ignoreIds={[1, 2, 7, 8, 12]}
                propId="idAgenda"
                value={idAgendaEntrega}
                defaultValue={agendaEntrega}
                propDefaultValue={"idAgenda"}
                extraParams={agendaParamsMemo}
              />
            </Grid>
            <Grid item xs={6}>
              <AsyncCreatableAutocomplete
                helperText="Proporcione datos de la persona."
                error={Boolean(responseError && responseError.idAgendaRecibe)}
                label="Recibe"
                name="agenda"
                labelProp="nombreCompleto"
                onChange={(e, v) =>
                  setData((oldData) => ({
                    ...oldData,
                    idAgendaRecibe: v ? v.idAgenda : "",
                    agendaRecibe: v,
                  }))
                }
                dialogForm={CreateEntryDialog}
                dialogProps={{
                  idConsejoElectoral: idConsejoElectoral
                    ? idConsejoElectoral
                    : "",
                  setEntry: (entry) => {
                    setData((v) => ({
                      ...v,
                      idAgendaRecibe: entry.idAgenda,
                      nuevaAgendaRecibe: entry,
                    }));
                    if (typeof onCreateAgendaRecibe === "function") {
                      onCreateAgendaRecibe(entry);
                    }
                  },
                  ignoreIds: [1, 2, 7, 8, 12],
                }}
                ignoreIds={ignoreIds}
                propId="idAgenda"
                value={idAgendaRecibe}
                defaultValue={agendaRecibe}
                propDefaultValue={"idAgenda"}
                extraParams={agendaParamsMemo}
              />
            </Grid>
            <Grid item style={{ marginTop: 10 }} xs={12}>
              {motivos ? (
                <>
                  <Typography style={{ fontSize: 12, color: "grey" }}>
                    Motivo:
                  </Typography>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={errors.includes("idMotivoPaquete")}
                  >
                    <Select
                      onChange={(event) =>
                        setData({
                          ...data,
                          idMotivoPaquete: event.target.value,
                        })
                      }
                      value={data.idMotivoPaquete}
                    >
                      {motivos?.length && type === "E" ? (
                        <MenuItem value={motivos[1].idMotivoPaquete}>
                          {motivos[1].descripcion}
                        </MenuItem>
                      ) : motivos?.length && type === "S" ? (
                        motivos
                          .filter((m) => m.tipo === type)
                          .map((motivo, index) => (
                            <MenuItem
                              key={index}
                              value={motivo.idMotivoPaquete}
                            >
                              {motivo.descripcion}
                            </MenuItem>
                          ))
                      ) : null}
                    </Select>
                    <FormHelperText>
                      Favor de seleccionar el motivo.
                    </FormHelperText>
                  </FormControl>
                </>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              onClose("");
              setErrors([]);
              setData({
                idActa: "",
                day: _date.getDate().toString(),
                month: "06",
                time: format(
                  new Date(
                    new Date().toLocaleString(
                      "en-US",
                      Intl.DateTimeFormat().resolvedOptions().timeZone
                    )
                  ),
                  "HH:mm"
                ),
                idMotivoPaquete: "",
                idAgendaEntrega: "",
                idAgendaRecibe: "",
              });
            }}
            color="default"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleAccept}
            color="primary"
            disabled={saving}
          >
            {saving ? <CircularProgress /> : "Aceptar"}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogFuncionario
        open={openDialogFuncionario}
        onClose={(message) => {
          if (message) {
            showAlert({
              severity: "success",
              message: message,
            });
          }

          setOpenDialogFuncionario(false);
        }}
      />
    </>
  );
};

export default DialogPaqueteDetail;
