import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { ArrowBack, Save } from "@material-ui/icons";

import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";

import { useHttp } from "../../hooks/useHttp";
import { useModel } from "../../hooks/useModel";

import { emptyRequest, postRequest } from "../../constants/request";
import { useQuery } from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import CustomDialog from "../../components/CustomDialog";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LandscapeIcon from "@material-ui/icons/Landscape";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

function MunicipiosDetail({
  match,
  history,
  dialog,
  editingMunicipio,
  setMunicipiosSearch,
}) {
  const classes = useStyles();
  const query = useQuery();
  const idMunicipio = query.get("id");
  const editing = !!idMunicipio;
  const { showAlert } = useAlert();
  const [validating, setValidating] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(true);
  const [idEstado, setIdEstado] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [numero, setNumero] = React.useState("");
  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "municipio",
    expand: "estado",
    id: idMunicipio,
    redirectOnPost: true,
  });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setIdEstado(model.idEstado);
      setNombre(model.nombre);
      setNumero(model.numero);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSubmitPressed = () => {
    setValidating(true);
    const body = {
      idEstado,
      nombre,
      numero,
    };
    if (editing) {
      body.idMunicipio = idMunicipio;
    }

    updateModel(body);
  };

  const setIdEstadoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdEstado(v.idEstado);
      } else {
        setIdEstado("");
      }
    },
    [setIdEstado]
  );

  const setNombreCallback = React.useCallback(
    (e) => {
      setNombre(e.target.value);
    },
    [setNombre]
  );

  const setNumeroCallback = React.useCallback(
    (e) => {
      setNumero(e.target.value);
    },
    [setNumero]
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="MUNICIPIO"
        links={[
          {
            to: "/municipios",
            icon: <LandscapeIcon />,
            label: "Municipio",
          },
          {
            to: "/municipios/nuevo",
            icon: <ListAltIcon />,
            label: "Nuevo",
          },
        ]}
        editing={editing}
        dialog={dialog}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <CustomDialog
          isOpen={isOpen}
          handleClose={handleClose}
          title="Municipio"
        ></CustomDialog>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nombre"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            onChange={setNombreCallback}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncAutocompleteSearch
            label="Estados"
            name="estado"
            defaultValue={model && model.estado}
            labelProp="nombre"
            onChange={setIdEstadoCallback}
            error={modelError.idEstado ? "idEstado" : ""}
            campoError="idEstado"
          />
          {modelError.idEstado ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idEstado}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Numero"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.numero}
            error={Boolean(modelError.numero)}
            onChange={setNumeroCallback}
            inputProps={{
              value: numero,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSubmitPressed}
              >
                {editing ? "EDITAR" : "CREAR"} MUNICIPIO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default MunicipiosDetail;
