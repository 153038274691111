import React from "react";
import { useHistory } from "react-router-dom";

import {
  Typography,
  Paper,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormHelperText,
  Badge,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import { baseUrlPdf } from "../../utils/variables";

import AlertDialog from "../../components/AlertDialog";
import { useModel } from "../../hooks/useModel";
import { useQuery } from "../../hooks/useQuery";
import { emptyRequest, postRequest } from "../../constants/request";
import { useHttp } from "../../hooks/useHttp";
import Loader from "../../components/Loader";
import { FindInPage, HowToReg, HowToVote, People } from "@material-ui/icons";
import PageLayout from "../../components/PageLayout";
import Services from "../../services";
import { tipoVotos } from "../../constants/mockData";
import { auth } from "../../firebase";
import PdfDialog from "../../components/PdfDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  miniTitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  title: {
    color: "#000",
    fontSize: 22,
    paddingBottom: "1%",
  },
  icon: { height: "60%", width: "60%", color: theme.palette.primary.main },
  tableHelperText: { color: theme.palette.error.dark },
  subtitle: { paddingBottom: "2%", color: "#000" },
  fieldText: { fontSize: 42 },
  panelEspecialText: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
  },
  badge2: {
    backgroundColor: "#f40090",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
  badge3: {
    backgroundColor: "#fb971f",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
  badge4: {
    backgroundColor: "#702c8f",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
  badge7: {
    backgroundColor: "#d1acfc",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#76777b",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const breadcrumbs = [
  {
    to: `/recuento`,
    icon: <FindInPage fontSize="small" />,
    label: `Historial de recuento`,
  },
  {
    to: `/recuento/nuevo`,
    icon: <FindInPage fontSize="small" />,
    label: `Sesión de cómputo: recontar acta`,
  },
];

const RecuentoDetail = ({ history }) => {
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const classes = useStyles();
  const query = useQuery();
  const idActa = query.get("a") ? query.get("a") : null;
  const id = auth.currentUser.uid;

  const { model, modelLoading } = useModel({
    name: "acta",
    id: idActa,
    expand:
      "tipoEleccion,votosParticipantes,votosGenerales,datosGenerales,motivosRecuento,participantesPoliticos,fechaComputo,esEspecial",
    redirectOnPost: true,
  });

  const [validating, setValidating] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [motivoDeRecuento, setMotivoDeRecuento] = React.useState([]);
  const [boletasSobrantes, setBoletasSobrantes] = React.useState("");
  const [votosParticipantesPoliticos, setVotosParticipantesPoliticos] =
    React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [printOpen, setPrintOpen] = React.useState(false);

  React.useEffect(() => {
    if (model && !modelLoading) {
      if (model.fechaComputo) history.push("/recuento/nuevo");
      let tmp = model.participantesPoliticos.map((e, i) => {
        return {
          ...e,
          resultado: "",
        };
      });
      tmp.push({ resultado: "" });
      tmp.push({ resultado: "" });
      tmp.push({ resultado: "" });
      if (model.motivosRecuento) {
        setMotivoDeRecuento(model.motivosRecuento);
      }
      setVotosParticipantesPoliticos([...tmp]);
    }
  }, [model, modelLoading, history]);

  const submitDetail = (sumaVotos) => {
    const votosCandidatosNoRegistrados =
      votosParticipantesPoliticos[votosParticipantesPoliticos.length - 3]
        .resultado;
    const votosNulos =
      votosParticipantesPoliticos[votosParticipantesPoliticos.length - 2]
        .resultado;
    const votosTotales = sumaVotos;
    const votos = votosParticipantesPoliticos
      .slice(0, votosParticipantesPoliticos.length - 3)
      .map((e) => e.resultado);
    const obj = {
      idActa: model.idActa,
      idTipoActa: model.idTipoActa,
      votosNulos,
      votosCandidatosNoRegistrados,
      votosTotales,
      votosParticipante: votos.map((v, i) => ({
        votos: v,
        idParticipantePolitico:
          model.participantesPoliticos[i].idParticipantePolitico,
      })),
      numeroBoletasSobrantes: boletasSobrantes,
    };
    setOpen(false);
    setButtonLoading(true);
    Services.post("acta-registro-voto-computo/guardar-recuento", obj)
      .then((res) => {
        setPrintOpen(true);
      })
      .finally(() => setButtonLoading(false));
  };

  const onSubmitPressed = () => {
    setValidating(true);
    if (
      boletasSobrantes === "" ||
      [...votosParticipantesPoliticos]
        .filter((_, i) => i !== votosParticipantesPoliticos.length - 1)
        .some((v) => v.resultado === "")
    ) {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    } else {
      setOpen(true);
    }
  };

  const NON_DIGIT = "/[^d]/g";
  if (!model || modelLoading) return <Loader />;

  let sumaVotos = 0;
  votosParticipantesPoliticos.forEach((e, i) => {
    if (
      i < votosParticipantesPoliticos.length - 1 &&
      e.resultado &&
      e.resultado !== ""
    )
      sumaVotos += parseInt(e.resultado);
  });

  return (
    <PageLayout
      title={`SESIÓN DE CÓMPUTO: RECUENTO DE ACTA ${
        model ? model.nombreCompletoActa : ""
      }`}
      links={[
        ...breadcrumbs,
        {
          to: `/recuento/nuevo/detalle?id=${model ? model.idActa : null}`,
          icon: <FindInPage fontSize="small" />,
          label: `${model ? model.nombreCompletoActa : ""}`,
        },
      ]}
      history={history}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Typography className={classes.miniTitle}>
            <strong>MOTIVO DE RECUENTO</strong>
          </Typography>
          <Typography className={classes.title}>
            <strong>
              {motivoDeRecuento.map((mr, i) => (
                <>
                  {`${i + 1}. ${mr.nombre}`}
                  <br />
                </>
              ))}
            </strong>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography
            // className={classes.miniTitle}
            style={{ marginBottom: 10 }}
          >
            Número de boletas sobrantes de la casilla
          </Typography>
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <TextField
              id="filled-basic"
              error={validating && boletasSobrantes === ""}
              fullWidth
              label="Número de boletas"
              helperText={
                validating &&
                boletasSobrantes === "" &&
                "Favor de ingresar un número entero"
              }
              required
              size="small"
              variant="outlined"
              value={boletasSobrantes}
              inputProps={{
                min: 0,
              }}
              InputProps={{
                // className: classes.fieldText,
                form: { autoComplete: "off" },
              }}
              onChange={(e) => {
                const { value } = e.target;
                const intValue = parseInt(
                  value.toString().replace(NON_DIGIT, "")
                );
                let pEntry = isNaN(intValue) ? "" : intValue;
                setBoletasSobrantes((v) => {
                  if (isNaN(intValue)) return "";
                  else if (model.esEspecial && value.length <= 4) return pEntry;
                  else if (!model.esEspecial && value.length <= 3)
                    return pEntry;
                  else return v;
                });
              }}
            />
          </form>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontSize: 24 }}
                  >
                    RESULTADOS DE LA VOTACIÓN DE CASILLA:
                    <b> {model ? model.nombreCompletoActa : ""}</b>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={6} style={{ textAlign: "center" }}>
                    Digite los votos para cada partido político, coalición,
                    cantidatura común, candidato independiente, candidatos no
                    registrados y total de votos. <br /> En caso de no haber
                    votos en alguno de los campos, digite el número cero.
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={1}>No.</StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">
                    Partido Político, Candidatura Común, Coalición o Candidato
                    Independiente
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} align="center">
                    RESULTADOS DE LA VOTACIÓN
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {model &&
                model.participantesPoliticos &&
                model.participantesPoliticos.length > 0 ? (
                  [
                    ...model.participantesPoliticos.sort((a, b) =>
                      a.orden && b.orden ? a.orden - b.orden : 1
                    ),
                    ...tipoVotos,
                  ].map((p, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell colSpan={1}>
                        {index < model.participantesPoliticos.length
                          ? index + 1
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1} align="center">
                        {p.logo !== "" ? (
                          <img
                            alt={p.nombre}
                            src={p.logo}
                            style={{ width: 100 }}
                          />
                        ) : (
                          <div className={classes.panelEspecial}>
                            <Typography className={classes.panelEspecialText}>
                              {p.nombre}
                            </Typography>
                          </div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell colSpan={4} align="right">
                        <form
                          autoComplete="off"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <TextField
                            id={index.toString()}
                            label={
                              model.esEspecial
                                ? "Número del 0 al 9999"
                                : "Número del 0 al 999"
                            }
                            fullWidth
                            variant="standard"
                            required
                            value={
                              votosParticipantesPoliticos[index]
                                ? index ===
                                  votosParticipantesPoliticos.length - 1
                                  ? sumaVotos
                                  : votosParticipantesPoliticos[index].resultado
                                : ""
                            }
                            disabled={
                              index === votosParticipantesPoliticos.length - 1
                            }
                            InputProps={{
                              className: classes.fieldText,
                              form: { autoComplete: "off" },
                            }}
                            error={
                              index ===
                                votosParticipantesPoliticos.length - 1 &&
                              !model.esEspecial &&
                              sumaVotos > 1000
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              const intValue = parseInt(
                                value.toString().replace(NON_DIGIT, "")
                              );
                              let pEntry = isNaN(intValue) ? "" : intValue;
                              setVotosParticipantesPoliticos((v) => {
                                let arr = [...v];
                                if (
                                  model.esEspecial &&
                                  arr.length > 0 &&
                                  value.length <= 4
                                ) {
                                  arr[index].resultado = pEntry;
                                } else if (
                                  !model.esEspecial &&
                                  arr.length > 0 &&
                                  value.length <= 3
                                ) {
                                  arr[index].resultado = pEntry;
                                }
                                return arr;
                              });
                            }}
                          />
                        </form>
                        {validating && p.resultado === "" && (
                          <FormHelperText className={classes.tableHelperText}>
                            Favor de ingresar un número entero.
                          </FormHelperText>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <div />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Button
            disabled={
              buttonLoading ||
              boletasSobrantes === "" ||
              (!model.esEspecial && sumaVotos > 999) ||
              (model.esEspecial && sumaVotos > 9999)
            }
            color="primary"
            variant="contained"
            style={{ float: "right", marginLeft: "1%" }}
            onClick={onSubmitPressed}
          >
            {buttonLoading ? "CARGANDO" : "GUARDAR"}
          </Button>
        </Grid>
        <AlertDialog
          open={open}
          onClose={() => setOpen(false)}
          title={`CONFIRMACIÓN DE RECUENTO DE ACTA ${
            model ? model.nombreCompletoActa : ""
          }`}
          loading={buttonLoading}
          content="¿Está seguro que desea guardar la información capturada?"
          onAccept={(_) => submitDetail(sumaVotos)}
        />
        <AlertDialog
          open={printOpen}
          onClose={() => history.push("/recuento/nuevo")}
          title={`${
            model ? model.nombreCompletoActa : ""
          } GUARDADO CORRECTAMENTE.`}
          titleColor="#60c45a"
          cancelText="Finalizar y continuar"
          content="¿Desea imprimir constancia de la información capturada?"
          onAccept={(_) => {
            setPdfDialogOpen(true);
            setLink(
              `${baseUrlPdf}acta-escrutinio-computo?idActa=${idActa}&idUsuario=${id}`
            );
          }}
        />
        <PdfDialog
          source={link}
          open={pdfDialogOpen}
          setOpen={setPdfDialogOpen}
        />
      </Grid>
    </PageLayout>
  );
};

export default RecuentoDetail;
