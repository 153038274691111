import React from "react";

import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";

import { generateDefaultChartOptions } from "../../utils";
import TablePagination from "../../components/TablePagination";
import FullScreenLoader from "../../components/FullScreenLoader";
import PageLayout from "../../components/PageLayout";

import { useQuery } from "../../hooks/useQuery";
import { useModels } from "../../hooks/useModels";
import services from "../../services";
import AlertDialog from "../../components/AlertDialog";

highcharts3d(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const InformeRecuentoConsejo = ({ history }) => {
  const classes = useStyles();
  let chartOptions = generateDefaultChartOptions();
  const query = useQuery();
  const idConsejoElectoral = query.get("id");
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [actasDetail, setActasDetail] = React.useState([]);
  const [indexMotivo, setIndexMotivo] = React.useState(0);

  const extraParams = React.useMemo(
    () => ({
      idConsejoElectoral,
    }),
    [idConsejoElectoral]
  );

  React.useEffect(() => {
    if (!detailOpen) {
    }
  }, [detailOpen]);

  const [actasPorMotivo, actasPorMotivoLoading] = useModels({
    name: "recuento/por-consejo-electoral",
    extraParams,
  });

  const extraOptions = {
    colors: ["#ad2624", "#9E9E9E", "#eaeaea"],
    title: {
      text: `ACTAS POR MOTIVO DE RECUENTO DEL CONSEJO: ${
        actasPorMotivo && actasPorMotivo.length > 0
          ? actasPorMotivo[0]["detalle"].nombre
          : ""
      }`,
    },
    subtitle: {
      text: `TOTAL: ${
        actasPorMotivo && actasPorMotivo.length > 0
          ? actasPorMotivo[0]["resultado"].reduce((acc, e) => acc + e.total, 0)
          : ""
      }`,
    },
    series: [
      {
        name: "Actas",
        data:
          actasPorMotivo && actasPorMotivo.length > 0
            ? actasPorMotivo[0]["resultado"].map((motivo) => [
                motivo.nombre,
                motivo.total,
              ])
            : [],
      },
    ],
  };

  const verActasClicked = (row, index) => {
    const { idMotivoRecuento } = row;
    if (idMotivoRecuento) {
      services
        .get("recuento/actas-por-motivo?idMotivoRecuento=" + idMotivoRecuento)
        .then((response) => {
          const { resultado } = response;
          setActasDetail(resultado);
          if (resultado.length > 0) {
            setDetailOpen(true);
          }
        });
    }
    setIndexMotivo(index);
  };

  chartOptions = { ...chartOptions, ...extraOptions };

  if (actasPorMotivoLoading) return <FullScreenLoader />;

  return (
    <PageLayout
      history={history}
      title="INFORME DE RECUENTO POR CONSEJO"
      model="consejo-electoral"
      links={[
        {
          label: "Informe Recuento",
          to: "/estadisticos/informe-recuento",
        },
        {
          label: "Detalle",
        },
      ]}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Typography style={{ fontSize: 22, color: "#000" }}>
            {`TOTAL DE ACTAS A RECUENTO: ${
              actasPorMotivo && actasPorMotivo.length > 0
                ? actasPorMotivo[0]["resultado"].reduce(
                    (acc, e) => acc + e.total,
                    0
                  )
                : ""
            }`}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TablePagination
            header={[]}
            data={
              actasPorMotivo && actasPorMotivo.length > 0
                ? actasPorMotivo[0]["resultado"]
                : []
            }
            extraRows={[
              {
                prop: "nombre",
                name: "Motivo",
                cell: (row, index) => <div>{row.nombre}</div>,
              },
              {
                prop: "value",
                name: "Total",
                cell: (row, index) => <div>{row.total}</div>,
              },
              {
                prop: "value1",
                name: "Total",
                cell: (row, index) => (
                  <Button onClick={() => verActasClicked(row, index)}>
                    Ver Actas
                  </Button>
                ),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Grid>
      </Grid>
      <AlertDialog
        open={detailOpen}
        title={`ACTAS CON MOTIVO DE RECUENTO: ${
          indexMotivo > 0 &&
          actasPorMotivo &&
          actasPorMotivo.length > 0 &&
          actasPorMotivo[indexMotivo]
            ? actasPorMotivo[indexMotivo].nombre
            : ""
        }`}
        content={
          <>
            {actasDetail.map((e) => (
              <>
                {e.nombreCompleto}
                <br />
              </>
            ))}
          </>
        }
        onAccept={() => {
          setDetailOpen(false);
          setActasDetail([]);
          setIndexMotivo(0);
        }}
      />
    </PageLayout>
  );
};

export default InformeRecuentoConsejo;
