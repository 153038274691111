import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import SignIn from "../views/SignIn";
// const NotFound = AsyncComponent(() => import("../views/shared/NotFound"));

const PublicRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route path="*" component={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
};

export default PublicRoutes;
