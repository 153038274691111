import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Loading from "../../components/FullScreenLoader";
import SaveIcon from "@material-ui/icons/Save";
import { useModel } from "../../hooks/useModel";
import { useApp } from "../../hooks/useApp";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PageHeader from "../../components/PageHeader";
import { DeleteForever, PhotoCamera } from "@material-ui/icons";
import { baseUrl } from "../../utils/variables";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
  input: {
    display: "none",
  },
}));

const RecursosDetail = ({ match, history }) => {
  const classes = useStyles();
  const editing = match.params.idRecurso !== undefined;
  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "recurso",
      id: match.params.idRecurso,
      redirectOnPost: true,
    });
  const { token } = useApp();
  // const [editingTitle, setEditingTitle] = React.useState("Cargando...");
  const [nombre, setNombre] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setNombre(model.nombre);
      setTipo(model.tipo);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSaveClicked = async () => {
    try {
      let resBody = null;
      if (file) {
        const formData = new FormData();
        if (editing) {
          formData.append("idRecurso", match.params.idRecurso);
        }
        formData.append("nombre", nombre);
        formData.append("tipo", tipo);
        formData.append("archivo", file);
        const res = await fetch(`${baseUrl}/v1/recurso/guardar`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        resBody = await res.json();
        if (resBody) {
          history.push("/recursos");
        }
      }
    } catch (error) {}
  };

  const onTipoSelectChange = (event) => {
    const { value } = event.target;
    setTipo(value);
  };

  const onFileInputChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setFileName(file.name);
    let fileReader = new FileReader();
    fileReader.onload = (e) => setFileUrl(e.target.result);
    fileReader.readAsDataURL(file);
  };

  const onDeleteFileClicked = () => {
    setFile(null);
    setFileName(null);
    setFileUrl(null);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="ADMINISTRACIÓN DE RECURSOS"
        model="recurso"
        editing={editing}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.sectionTitle}>
            INFORMACIÓN DEL RECURSO
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nombre oficial"
            size="small"
            required
            variant="outlined"
            fullWidth
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            onChange={(e) => setNombre(e.target.value)}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormControl
            size="small"
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="tipo-label">Tipo de recurso</InputLabel>
            <Select
              labelId="tipo-label"
              id="plantilla"
              value={tipo}
              variant="outlined"
              onChange={onTipoSelectChange}
              label="Tipo del archivo por agregar"
              required
            >
              <MenuItem value={""}>
                <em>Sin seleccionar</em>
              </MenuItem>
              <MenuItem value={"imagen"}>Imágen</MenuItem>
              <MenuItem value={"pdf"}>Archivo PDF</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          {!fileUrl && (
            <>
              <input
                accept="image/*, file/pdf"
                className={classes.input}
                id="icon-button-file"
                type="file"
                onChange={onFileInputChange}
                name="archivo"
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="Subir archivo"
                  component="span"
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </>
          )}
          {fileUrl && fileName && (
            <Grid container direction="column">
              <img
                src={fileUrl}
                alt={fileName}
                style={{
                  width: "66%",
                  height: "auto",
                }}
              />
              <label>Haz click aquí para eliminar el archivo</label>
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="Eliminar archivo"
                  component="span"
                  onClick={onDeleteFileClicked}
                >
                  <DeleteForever />
                </IconButton>
              </label>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onSaveClicked}
                fullWidth
                disabled={updateModelLoading}
                endIcon={<SaveIcon />}
              >
                {editing ? "GUARDAR DATOS" : "CREAR RECURSO"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

RecursosDetail.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default RecursosDetail;
