import React from "react";
import { Button, FormHelperText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  yesButton: { backgroundColor: theme.palette.success.light, color: "#fff" },
  noButton: { backgroundColor: theme.palette.error.light, color: "#fff" },
  yesButtonOutlined: {
    borderColor: "#76777b",
    color: "#76777b",
  },
  noButtonOutlined: {
    borderColor: "#76777b",
    color: "#76777b",
  },
}));

const YesNoButton = ({
  yesText = "SÍ",
  noText = "NO",
  value,
  onChange,
  validating,
}) => {
  const classes = useStyles();

  return (
    <>
      <>
        <Button
          variant={value ? "contained" : "outlined"}
          onClick={() => onChange(true)}
          size="small"
          className={value ? classes.yesButton : classes.yesButtonOutlined}
        >
          {yesText}
        </Button>

        <Button
          variant={value === undefined || value ? "outlined" : "contained"}
          onClick={() => onChange(false)}
          size="small"
          className={
            value === undefined || value
              ? classes.noButtonOutlined
              : classes.noButton
          }
        >
          {noText}
        </Button>
      </>
      {validating && value === undefined && (
        <FormHelperText className={classes.tableHelperText}>
          {`Favor de seleccionar "${yesText}" o "${noText}"`}
        </FormHelperText>
      )}
    </>
  );
};

export default YesNoButton;
