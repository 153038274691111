import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  Typography,
  FormHelperText,
  FormControl,
  TextField,
} from "@material-ui/core";

import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import TouchApp from "@material-ui/icons/TouchApp";

import YesNoTable from "./YesNoTable";
import ActasTable from "./ActasTable";
import { emptyRequest } from "../../constants/request";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import PageLayout from "../../components/PageLayout";
import DatePicker from "../../components/DatePicker";
import TimePicker from "../../components/TimePicker";
import { useModels } from "../../hooks/useModels";

import AsyncCreatableAutocomplete from "../../components/AsyncCreatableAutocomplete";
import CreateEntryDialog from "../../components/CreateEntryDialog";

import Services from "../../services";
import { useAuth } from "../../hooks/useAuth";
import SingleSelect from "../../components/SingleSelect";
import { Inbox } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { convertTime12to24 } from "../../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    color: "#000",
    marginBottom: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  submitButton: {
    color: "#fff",
  },
}));

const breadcrumbs = [
  {
    to: `/recepciones-municipales`,
    icon: <MoveToInboxIcon fontSize="small" />,
    label: `Recepciones municipales`,
  },
  {
    to: "#",
    icon: <MoveToInboxIcon fontSize="small" />,
    label: `Nueva recepción`,
  },
];

const estatusDePaqueteRequest = {
  name: "estatus-paquete",
  fields: "idEstatusPaquete,nombre,bloqueaForma",
  ordenar: "idEstatusPaquete.asc",
  extraParams: {
    proceso: "recepcion",
  },
};

const casillasExtraParams = {
  fields: "idCasilla",
  expand: "nombreCompleto",
  estatusRecepcion: "sin-recepcion",
};

const entregaExtraParams = {
  idTipoAgenda2: "3,7,9",
};

const recibeExtraParams = {
  idTipoAgenda2: "6,10,11,13",
};

const emptyReq = emptyRequest();

const opcionesHora = [...Array(12).keys()].map((e) =>
  e < 9 ? "0" + (e + 1) : (e + 1).toString()
);
const opcionesMinuto = [...Array(60).keys()].map((e) =>
  e < 10 ? "0" + e : e.toString()
);
const opcionesApmpm = ["AM", "PM"];

const NuevaRecepcion = ({ history }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const idConsejoElectoral =
    user && user.idConsejoElectoral ? user.idConsejoElectoral : "";
  // const [funcionarios, refreshFuncionarios] = useModels(funcionariosRequest);
  const [actasRequest, setActasRequest] = React.useState(emptyReq);
  const [actas, actasLoading] = useModels(actasRequest);

  const [validando, setValidando] = React.useState(false);
  const [estadoEntrega, setEstadoEntrega] = React.useState("");
  // const [fecha, setFecha] = React.useState(new Date());
  const [dia, setDia] = React.useState("");
  // const [tiempo, setTiempo] = React.useState(
  // new Date().toLocaleTimeString("es-MX")
  // ""
  // );
  const [estatusesDePaquete, estatusesDePaqueteLoading] = useModels(
    estatusDePaqueteRequest
  );
  const [hora, setHora] = React.useState("");
  const [minuto, setMinuto] = React.useState("");
  const [ampm, setAmpm] = React.useState("");
  const [funcionarioEntrega, setFuncionarioEntrega] = React.useState("");
  const [casilla, setCasilla] = React.useState("");
  const [funcionarioRecibe, setFuncionarioRecibe] = React.useState("");
  const [estatusActas, setEstatusActas] = React.useState({});
  const [funcionarioFirmo, setFuncionarioFirmo] = React.useState(undefined);
  const [guardarCargando, setGuardarCargando] = React.useState(false);
  const [tmpObj, setTmpObj] = React.useState({});

  React.useEffect(() => {
    let mounted = true;
    if (casilla !== "") {
      const nuevoReq = {
        name: "acta",
        fields: "idActa,idTipoEleccion,nombreCompletoActa",
        expand: "nombreTipoEleccion",
        ordenar: "idActa.desc",
        extraParams: {
          idCasilla: casilla,
          idTipoEleccion: -1,
          recepcion: 1,
        },
      };
      if (mounted) {
        setActasRequest(nuevoReq);
      }
    } else {
      setActasRequest(emptyReq);
    }
    return () => {
      mounted = false;
    };
  }, [casilla]);

  React.useEffect(() => {
    if (dia !== "" && hora !== "" && ampm !== "") {
      let horaInt = parseInt(hora);
      if (ampm === "PM") horaInt += 12;
      if (dia === "6" || (dia === "7" && horaInt <= 11)) {
        // PAQUETE RECIBIDO EN TIEMPO
        setEstadoEntrega(2);
      } else {
        // PAQUETE RECIBIDO DE FORMA EXTEMPORANEA CON CAUSA JUSTIFICADA
        setEstadoEntrega(4);
      }
    }
  }, [dia, hora, ampm]);

  const extraEntregaParamMemo = React.useMemo(
    () =>
      casilla === ""
        ? entregaExtraParams
        : { ...entregaExtraParams, idCasilla: casilla },
    [casilla]
  );

  const entregaDialogPropMemo = React.useMemo(
    () => ({
      idConsejoElectoral: idConsejoElectoral ? idConsejoElectoral : "",
      setEntry: (entry) => {
        if (entry) {
          setFuncionarioEntrega(entry);
          setTmpObj((o) => ({ ...o, entrega: entry }));
        }
      },
      ignoreIds: [0, 1, 2, 4, 5, 6, 8, 10, 11, 12, 13],
    }),
    [idConsejoElectoral]
  );

  const onEntregaChanged = React.useCallback(
    (e, v) => {
      // console.log(v);
      if (v) {
        setFuncionarioEntrega(v);
      } else {
        setFuncionarioEntrega("");
      }
    },
    [setFuncionarioEntrega]
  );

  const recibeDialogPropMemo = React.useMemo(
    () => ({
      idConsejoElectoral: idConsejoElectoral ? idConsejoElectoral : "",

      setEntry: (entry) => {
        if (entry) {
          setFuncionarioRecibe(entry);
          setTmpObj((o) => ({ ...o, recibe: entry }));
        }
      },
      ignoreIds: [0, 1, 2, 3, 4, 5, 7, 8, 9, 12],
    }),
    [idConsejoElectoral]
  );

  const onRecibeChanged = React.useCallback(
    (e, v) => {
      if (v) {
        setFuncionarioRecibe(v);
      } else {
        setFuncionarioRecibe("");
      }
    },
    [setFuncionarioRecibe]
  );

  const isValid = () => {
    if (
      casilla === "" ||
      dia === "" ||
      hora === "" ||
      minuto === "" ||
      estadoEntrega === "" ||
      funcionarioEntrega === "" ||
      Object.keys(estatusActas).length === 0 ||
      funcionarioRecibe === "" ||
      funcionarioFirmo === undefined
    )
      return false;
    for (const value of Object.values(estatusActas)) {
      for (const innerValue of Object.values(value)) {
        if (innerValue === undefined) return false;
      }
    }
    return true;
  };

  const onSaveClicked = async (e) => {
    e.preventDefault();
    if (!validando) {
      setValidando(true);
    }
    if (!isValid()) return;
    const body = [];
    for (const value of Object.values(estatusActas)) {
      const formatted = convertTime12to24(`${hora}:${minuto} ${ampm}`);
      const [hr, min] = formatted.split(":");
      const fechaTmp = new Date(2021, 5, dia, hr, min, 0);
      const recepcionActa = {
        idEstatusPaquete: estadoEntrega,
        idFuncionarioEntrega: funcionarioEntrega.idAgenda,
        nombreFuncionarioEntrega: funcionarioEntrega.nombreCompleto,
        idFuncionarioRecibe: funcionarioRecibe.idAgenda,
        nombreFuncionarioRecibe: funcionarioRecibe.nombreCompleto,
        cargoFuncionarioEntrega: funcionarioEntrega.puesto,
        firmaFuncionario: value.conFirma ? 1 : 0,
        conAlteracion: value.muestraAlteracion ? 1 : 0,
        cintaSeguridad: value.cintaDeSeguridad ? 1 : 0,
        actaPrep: value.sobreParaPREP ? 1 : 0,
        actaEscrutinioComputo: value.bolsaConsejo ? 1 : 0,
        firmaFuncionarioConsejo: funcionarioFirmo ? 1 : 0,
        idTipoEleccion: value.idTipoEleccion,
        idActa: value.idActa,
        fecha: fechaTmp.toLocaleString("en-US", {
          timeZone: "America/Hermosillo",
        }),
      };
      body.push(recepcionActa);
    }
    try {
      setGuardarCargando(true);
      const response = await Services.post("recepcion/guardar", [...body]);
      if (response) {
        window.location.reload();
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setGuardarCargando(false);
    }
  };

  const onActasTableChanged = (actas) => {
    setEstatusActas({ ...actas });
  };

  return (
    <PageLayout
      title="RECEPCIÓN DE PAQUETES ELECTORALES"
      history={history}
      loading={false}
      links={breadcrumbs}
      actionButton={{
        to: `/recepciones-municipales`,
        icon: <Inbox />,
        label: `VER HISTORIAL`,
      }}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle}>
                Fecha y hora de recepción:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                label="Día"
                value={dia}
                error={validando && dia === ""}
                onChange={(e, val) =>
                  val === "6" || val === "7" ? setDia(val) : setDia("")
                }
                disableClearable
                options={["6", "7"]}
                getOptionLabel={(e) => e.toString()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Día"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography style={{ verticalTextAlign: "center", fontSize: 20 }}>
                de Junio del 2021
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Autocomplete
                label="Hora"
                value={hora}
                error={validando && dia === ""}
                onChange={(e, val) => setHora(val)}
                options={opcionesHora}
                disableClearable
                getOptionLabel={(e) => e.toString()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Hora"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <Autocomplete
                label="Minuto"
                value={minuto}
                error={validando && dia === ""}
                onChange={(e, val) => setMinuto(val)}
                options={opcionesMinuto}
                disableClearable
                MenuProps={{ style: { height: 500 } }}
                getOptionLabel={(e) => e.toString()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Min"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <Autocomplete
                label="AM/PM"
                value={ampm}
                error={validando && ampm === ""}
                disableClearable
                onChange={(e, val) => setAmpm(val)}
                options={opcionesApmpm}
                getOptionLabel={(e) => e.toString()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="AM/PM"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ height: 25, marginTop: -5 }}>
          <Typography>
            {estatusesDePaquete
              ?.filter((e) => e.idEstatusPaquete === estadoEntrega)
              .map((e) => e.nombre)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.sectionTitle}>
            Seleccione Casilla y Tipo de Elección:
          </Typography>
          <Grid container>
            <Grid item xs={7}>
              <FormControl
                className={classes.formControl}
                error={validando && casilla === ""}
              >
                <AsyncAutocompleteSearch
                  label="Casilla - Tipo"
                  name="casilla"
                  extraParams={casillasExtraParams}
                  labelProp="nombreCompleto"
                  searchParam="busqueda"
                  error={validando && casilla === ""}
                  onChange={(_, val) => {
                    console.log(val);
                    if (val) {
                      setCasilla(val.idCasilla);
                    } else {
                      setCasilla("");
                    }
                  }}
                />
                {validando && casilla === "" && (
                  <FormHelperText>
                    Es necesario seleccionar el paquete de la casilla.
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.sectionTitle}>
            Funcionario(a) que entrega el paquete:
          </Typography>
          <Grid container>
            <Grid item xs={7}>
              <AsyncCreatableAutocomplete
                label="Entrega"
                name="agenda"
                labelProp="nombreCompleto"
                disabled={casilla === ""}
                onChange={onEntregaChanged}
                dialogForm={CreateEntryDialog}
                dialogProps={entregaDialogPropMemo}
                extraParams={extraEntregaParamMemo}
                propId="idTipoAgenda"
                defaultValue={tmpObj.entrega ? tmpObj.entrega : ""}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {casilla === "" ? (
            <Grid container direction="column" alignItems="center">
              <TouchApp
                style={{ fontSize: 48, marginTop: 20, marginBottom: 20 }}
              />
              <Typography>
                Es necesario seleccionar sección y tipo de casilla.
              </Typography>
            </Grid>
          ) : (
            <ActasTable
              validating={validando}
              actas={actas}
              loading={actasLoading}
              onChange={onActasTableChanged}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {casilla !== "" && !actasLoading && actas.length !== 0 && (
            <YesNoTable
              title="¿El Funcionario(a) que recibe en el Consejo, firmó?"
              value={funcionarioFirmo}
              onChange={(v) => setFuncionarioFirmo(v)}
              validating={validando}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.sectionTitle}>
            Funcionario(a) de consejo que recibe:
          </Typography>
          <Grid container>
            <Grid item xs={7}>
              <AsyncCreatableAutocomplete
                label="Recibe"
                name="agenda"
                labelProp="nombreCompleto"
                onChange={onRecibeChanged}
                dialogForm={CreateEntryDialog}
                dialogProps={recibeDialogPropMemo}
                extraParams={recibeExtraParams}
                propId="idTipoAgenda"
                defaultValue={tmpObj.recibe ? tmpObj.recibe : ""}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            className={classes.submitButton}
            variant="contained"
            style={{ float: "right" }}
            onClick={onSaveClicked}
            disabled={guardarCargando || !isValid()}
            color="primary"
          >
            {guardarCargando ? "Cargando" : "Guardar"}
          </Button>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

NuevaRecepcion.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default NuevaRecepcion;
