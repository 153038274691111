import React from "react";
import PageLayout from "../../components/PageLayout";
import { Grid, makeStyles } from "@material-ui/core";
import ModoCard from "./modoCard";
import ModoIndividual from "./modoIndividual";
import ModoMultiple from "./modoMultiple";

const modos = [
  { value: 1, nombre: "SELECCIONAR ACTA INDIVIDUAL" },
  { value: 2, nombre: "SELECCIONAR MULTIPLES ACTAS" },
];

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    marginBottom: theme.spacing(3),
    marginTop: -theme.spacing(3),
  },
}));

const ControlActas = () => {
  const classes = useStyles();
  const [modo, setModo] = React.useState(null);
  const [guardando, setGuardando] = React.useState();

  const guardarModoIndividual = React.useCallback(async () => {
    try {
      setGuardando(true);
    } catch (error) {
    } finally {
      setGuardando(false);
    }
  }, [setGuardando]);

  const guardarModoMultiple = React.useCallback(async () => {
    try {
      setGuardando(true);
    } catch (error) {
    } finally {
      setGuardando(false);
    }
  }, [setGuardando]);

  return (
    <PageLayout title="ADMINISTRACIÓN Y CONTROL DE ACTAS">
      <Grid container justify="space-evenly" className={classes.cardsContainer}>
        {modos.map((m, i) => (
          <Grid key={i} item xs={3}>
            <ModoCard
              nombre={m.nombre}
              onActivateClicked={() => setModo(m.value)}
              active={m.value === modo}
            />
          </Grid>
        ))}
      </Grid>
      {modo === 1 && (
        <ModoIndividual
          onSaveClicked={guardarModoIndividual}
          cargando={guardando}
        />
      )}
      {modo === 2 && (
        <ModoMultiple
          onSaveClicked={guardarModoMultiple}
          cargando={guardando}
        />
      )}
    </PageLayout>
  );
};

export default ControlActas;
