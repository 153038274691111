import React from "react";
import AlertDialog from "../../components/AlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Switch,
} from "@material-ui/core";
import { CloseOutlined, Save } from "@material-ui/icons";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AsyncSelect from "../../components/AsyncSelect";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import CustomDialog from "../../components/CustomDialog";
import PageHeader from "../../components/PageHeader";
import RegexTextField from "../../components/regexTextField";
import FullScreenLoader from "../../components/FullScreenLoader";
import SingleSelect from "../../components/SingleSelect";
import { useModel } from "../../hooks/useModel";
import { useModels } from "../../hooks/useModels";
import { useQuery } from "../../hooks/useQuery";
import services from "../../services";
import { tipoPuesto } from "../../constants/mockData";
import { useAuth } from "../../hooks/useAuth";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useAlert } from "../../hooks/useAlert";

const onlyAlphanumericRegex = /[^0-9]/gim;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

function ConsejoElectoralDetail({
  match,
  history,
  dialog,
  editingConsejoElectoral,
  setConsejoElectoralSearch,
}) {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { showAlert } = useAlert();
  const classes = useStyles();
  const query = useQuery();
  const idConsejoElectoral = query.get("id") || undefined;
  const editing = !!idConsejoElectoral;
  const [validating, setValidating] = React.useState(false);
  const [selectedEntry, setSelectedEntry] = React.useState({});
  const { user, userLoading, signOut } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(true);
  const [nombre, setNombre] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [idTipoConsejoElectoral, setIdTipoConsejoElectoral] =
    React.useState("");
  const [puesto, setPuesto] = React.useState("");

  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [idEstado, setIdEstado] = React.useState("");
  const [idDistrito, setIdDistrito] = React.useState("");
  const [clearDistrito, setClearDistrito] = React.useState(false);
  const [numeroConsejeros, setNumeroConsejeros] = React.useState("");
  const [domicilio, setDomicilio] = React.useState("");
  const [nombreMunicipio, setNombreMunicipio] = React.useState("");
  const [modelo, setModelo] = React.useState();
  const [opcionesPuesto, setOpcionesPuesto] = React.useState([]);
  const [idAgenda, setIdAgenda] = React.useState("");
  const [idTipoAgenda, setIdTipoAgenda] = React.useState("");
  const [codigoGuardar, setCodigoGuardar] = React.useState("");
  const [codigo] = React.useState("2501");
  const [miembros, setMiembros] = React.useState([]);

  const municipiosParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const handleClickOpenCodigo = () => {
    setOpen(true);
  };

  const handleCloseCodigo = () => {
    setOpen(false);
  };

  const distritosParamsMemo = React.useMemo(
    () => ({
      tipo: 2,
      ordenar: "nombre.asc",
    }),
    []
  );

  const consejoElectoralRequestMemo = React.useMemo(
    () =>
      editing
        ? {
            name: "consejo-electoral",
            expand:
              "estado,municipio,distrito,tipoConsejoElectoral,miembros,miembros.tipoAgenda",
            id: idConsejoElectoral,
            redirectOnPost: true,
          }
        : {},
    [editing, idConsejoElectoral]
  );

  const agendaParamsMemo = React.useMemo(
    () => ({
      idAgenda: idAgenda,
    }),
    [idAgenda]
  );

  const [modelTipoConsejoElectoral, modelTipoConsejoElectoralLoading] =
    useModels({
      name: "tipo-consejo-electoral",
      expand: "tipoConsejoElectoral",
      ordenar: "idTipoConsejoElectoral.desc",
    });

  const {
    modelAgenda,
    modelAgendaLoading,
    modelAgendaError,
    updateAgendaModel,
    updateAgendaModelLoading,
  } = useModel({
    name: "agenda",
    expand: "tipoAgenda",
    extraParams: agendaParamsMemo,
  });

  const {
    model,
    modelLoading,
    modelError,
    refreshModel,
    updateModel,
    updateModelLoading,
  } = useModel(consejoElectoralRequestMemo);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setNombre(model.nombre);
      setDescripcion(model.descripcion);
      setIdMunicipio(model.idMunicipio);
      setIdDistrito(model.idDistrito);
      setDomicilio(model.domicilio);
      setIdTipoConsejoElectoral(model.idTipoConsejoElectoral);
      setNumeroConsejeros(model.numeroConsejeros);
      setIdEstado(model.idEstado);
      setNombreMunicipio(model.nombreMunicipio);
      setMiembros(model.miembros);
      setModelo(model.modelo);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const setIdDistritoCallback = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdDistrito(v.idDistrito);
      } else {
        setIdDistrito("");
      }
    },
    [setIdDistrito]
  );

  const municipioCambio = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
      setNombreMunicipio(v.nombre);
      setIdEstado(v.idEstado);
    } else {
      setIdMunicipio("");
      setIdEstado("");
      setNombreMunicipio("");
    }
  }, []);

  const tipoConsejoElectoralSelectChange = (e) => {
    const { value } = e.target;
    setIdTipoConsejoElectoral(value);
  };

  const setModeloCallback = React.useCallback(
    (e) => {
      const { value } = e.target;
      if (value !== "" && value) {
        setModelo(value);
      }
    },
    [setModelo]
  );

  const onSaveClicked = () => {
    if (codigoGuardar != codigo) {
      showAlert({
        severity: "error",
        message: "Código inválido",
      });
      return;
    }
    setValidating(true);
    const body = {
      nombre,
      nombreMunicipio,
      descripcion,
      domicilio,
      idMunicipio,
      idEstado,
      idDistrito,
      numeroConsejeros,
      idTipoConsejoElectoral,
      miembros,
      modelo,
    };
    if (editing) {
      body.idConsejoElectoral = idConsejoElectoral;
    }
    updateModel(body);
  };

  const handleClose = () => {
    setIsOpen((v) => !v);
  };

  const onDeleteClicked = (entry, index) => {
    entry.index = index;
    setSelectedEntry(entry);
    console.log(entry);
    handleClose();
  };

  const onDeleteCompleted = () => {
    setMiembros((m) => {
      let i = selectedEntry.index;
      let arr = [...m];
      arr.splice(i, 1);
      return arr;
    });
    const obj = { ...selectedEntry, idConsejoElectoral: null };
    services.post("agenda/guardar", obj);
    handleClose();
  };

  const setIdTipoAgendaCallback = React.useCallback(
    (i, e) => {
      // setIdTipoAgenda(e.target.value.id);
      // setOpcionesPuesto(tipoPuesto[e.target.value.id]);
      setMiembros((v) =>
        v.map((miembro, index) =>
          i === index
            ? { ...miembro, idTipoAgenda: e.target.value.id }
            : miembro
        )
      );
    },
    [setIdTipoAgenda]
  );

  if (editing && !model) return <FullScreenLoader />;

  return (
    <>
      <PageHeader
        history={history}
        title="CONSEJO ELECTORAL"
        editing={editing}
        dialog={dialog}
        links={[
          {
            to: "/consejos-electorales",
            icon: <AssignmentReturnedIcon />,
            label: "Consejos-Electorales",
          },
          {
            to: "/consejos-electorales/nuevo",
            icon: <ListAltIcon />,
            label: `${editing ? "Detalle" : "Nuevo"}`,
          },
        ]}
      />
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.sectionTitle}>
            INFORMACIÓN GENERAL
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            hidden={true}
            label="Nombre"
            size="small"
            required
            fullWidth
            variant="outlined"
            onChange={(e) => setNombre(e.target.value)}
            inputProps={{
              value: nombre,
            }}
            helperText={modelError.nombre || ""}
            error={Boolean(modelError.nombre)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Descripción"
            size="small"
            required
            fullWidth
            variant="outlined"
            onChange={(e) => setDescripcion(e.target.value)}
            inputProps={{
              value: descripcion,
            }}
            helperText={modelError.descripcion || ""}
            error={Boolean(modelError.descripcion)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo Consejo Electoral
            </InputLabel>
            <Select
              value={idTipoConsejoElectoral}
              style={{ textAlign: "left" }}
              onChange={tipoConsejoElectoralSelectChange}
              error={Boolean(modelError.idTipoConsejoElectoral)}
              label="Tipo Consejo Electoral"
            >
              {modelTipoConsejoElectoral !== null
                ? modelTipoConsejoElectoral.map((opt, index) => (
                    <MenuItem key={index} value={opt.idTipoConsejoElectoral}>
                      {opt.nombre}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {modelError.idTipoConsejoElectoral ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.idTipoConsejoElectoral || ""}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncAutocompleteSearch
            label="Distrito"
            name="distrito"
            defaultValue={model && model.distrito}
            labelProp="nombre"
            onChange={setIdDistritoCallback}
            clearInput={clearDistrito}
            extraParams={distritosParamsMemo}
            error={modelError.idDistrito ? "idDistrito" : ""}
            campoError="idDistrito"
          />
          {modelError.idDistrito ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idDistrito}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <AsyncAutocompleteSearch
            label="Municipio"
            name="municipio"
            defaultValue={model && model.municipio}
            labelProp="nombre"
            error={modelError.idMunicipio ? "idMunicipio" : ""}
            campoError="idMunicipio"
            onChange={municipioCambio}
            extraParams={municipiosParamsMemo}
          />
          {modelError.idMunicipio ? (
            <FormHelperText style={{ color: "red" }}>
              {modelError.idMunicipio}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginTop: -5 }}>
          <RegexTextField
            label="Número Consejeros"
            regex={onlyAlphanumericRegex}
            style={{ top: 5 }}
            placeholder="0"
            size="small"
            value={numeroConsejeros}
            helperText={modelError.numeroConsejeros}
            error={Boolean(modelError.numeroConsejeros)}
            onChange={(e) => setNumeroConsejeros(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Domicilio"
            size="small"
            required
            variant="outlined"
            fullWidth
            onChange={(e) => setDomicilio(e.target.value)}
            inputProps={{
              value: domicilio,
            }}
            helperText={modelError.domicilio}
            error={Boolean(modelError.domicilio)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Modelo
            </InputLabel>
            <Select
              defaultValue={model && model.modelo}
              style={{ textAlign: "left" }}
              onChange={setModeloCallback}
              error={Boolean(modelError.modelo)}
              label="Modelo"
            >
              <MenuItem value={1}>{"Modelo 1"}</MenuItem>
              <MenuItem value={2}>{"Modelo 2"}</MenuItem>
              <MenuItem value={3}>{"Modelo 3"}</MenuItem>
              <MenuItem value={4}>{"Modelo 4"}</MenuItem>
              <MenuItem value={5}>{"Modelo 5"}</MenuItem>
              <MenuItem value={6}>{"Modelo 6"}</MenuItem>
              <MenuItem value={7}>{"Modelo 7"}</MenuItem>
              <MenuItem value={8}>{"Modelo 8"}</MenuItem>
              <MenuItem value={9}>{"Modelo 9"}</MenuItem>
              <MenuItem value={10}>{"Modelo 10"}</MenuItem>
            </Select>
            {modelError.modelo ? (
              <FormHelperText style={{ color: "red" }}>
                {modelError.modelo || ""}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={12}>
              <Typography>
                <strong>
                  {miembros.length >= 1
                    ? "MIEMBROS DEL CONSEJO"
                    : "NO HAY MIEMBROS EN EL CONSEJO"}
                </strong>
              </Typography>
            </Grid>
            {miembros.map((m, i) => (
              <Grid container xs={12} style={{ marginTop: 10 }}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid>
                      <Button
                        onClick={() => {
                          onDeleteClicked(m, i);
                        }}
                        style={{
                          borderTop: "1px solid black",
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                    </Grid>

                    <Grid item xs direction="column">
                      <Grid item xs>
                        <Typography variant="h6" align="center">
                          Miembro
                        </Typography>

                        <Typography gutterBottom variant="subtitle1">
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <TextField
                                style={{ marginTop: "10px" }}
                                label="Nombre"
                                size="small"
                                required
                                variant="outlined"
                                fullWidth
                                // helperText={
                                //   responseError && responseError.claveElector
                                // }
                                // error={Boolean(
                                //   responseError && responseError.claveElector
                                // )}
                                onChange={(e) =>
                                  setMiembros(
                                    miembros.map((miem, index) =>
                                      i === index
                                        ? {
                                            ...miem,
                                            nombre: e.target.value,
                                          }
                                        : miem
                                    )
                                  )
                                }
                                inputProps={{
                                  value: m.nombre,
                                  maxLength: 100,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                style={{ marginTop: "10px" }}
                                label="Apellido Paterno"
                                size="small"
                                required
                                variant="outlined"
                                fullWidth
                                // helperText={
                                //   responseError && responseError.claveElector
                                // }
                                // error={Boolean(
                                //   responseError && responseError.claveElector
                                // )}
                                onChange={(e) =>
                                  setMiembros(
                                    miembros.map((miem, index) =>
                                      i === index
                                        ? {
                                            ...miem,
                                            apellidoPaterno: e.target.value,
                                          }
                                        : miem
                                    )
                                  )
                                }
                                inputProps={{
                                  value: m.apellidoPaterno,
                                  maxLength: 100,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                style={{ marginTop: "10px" }}
                                label="Apellido Materno"
                                size="small"
                                required
                                variant="outlined"
                                fullWidth
                                // helperText={
                                //   responseError && responseError.claveElector
                                // }
                                // error={Boolean(
                                //   responseError && responseError.claveElector
                                // )}
                                onChange={(e) =>
                                  setMiembros(
                                    miembros.map((miem, index) =>
                                      i === index
                                        ? {
                                            ...miem,
                                            apellidoMaterno: e.target.value,
                                          }
                                        : miem
                                    )
                                  )
                                }
                                inputProps={{
                                  value: m.apellidoMaterno,
                                  maxLength: 100,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Typography>

                        <Typography variant="body2" color="textSecondary">
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <AsyncSelect
                                disabled={true}
                                label="Tipo de Agenda"
                                name="agenda/tipo-agenda"
                                labelProp="nombre"
                                defaultValue={{
                                  id: m.idTipoAgenda,
                                  nombre: m.tipoAgenda,
                                }}
                                onChange={(e) => setIdTipoAgendaCallback(i, e)}
                                extraParams={{
                                  ordenar: "nombre.asc",
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <SingleSelect
                                value={m.puesto}
                                label="Puesto"
                                options={
                                  m ? tipoPuesto[miembros[i].idTipoAgenda] : []
                                }
                                onChange={(e) => {
                                  setMiembros(
                                    miembros.map((miem, index) =>
                                      i === index
                                        ? {
                                            ...miem,
                                            puesto: e.target.value.nombre,
                                          }
                                        : miem
                                    )
                                  );
                                }}
                                // helperText={responseError && responseError.puesto}
                                // error={Boolean(responseError && responseError.puesto)}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="b" align="center">
                                Firma Acta
                              </Typography>
                              <Switch
                                checked={m.firmarActa}
                                onChange={(e) =>
                                  setMiembros(
                                    miembros.map((miem, index) =>
                                      i === index
                                        ? {
                                            ...miem,
                                            firmarActa: e.target.checked,
                                          }
                                        : miem
                                    )
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
            <CustomDialog
              isOpen={isOpen}
              handleClose={handleClose}
              title={`¿Estás seguro de querer quitar a ${
                selectedEntry ? selectedEntry.nombreCompleto : ""
              } del ${model && model.nombre ? model.nombre : ""}"`}
            >
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onDeleteCompleted}
                >
                  Confirmar
                </Button>
                <Button variant="contained" onClick={handleClose}>
                  Cancelar
                </Button>
              </Grid>
            </CustomDialog>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={handleClickOpenCodigo}
              >
                {editing ? "EDITAR" : "CREAR"} CONSEJO ELECTORAL
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseCodigo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {" Favor de ingresar la siguiente clave para poder continua."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              style={{ marginTop: "10px" }}
              label="Código"
              size="small"
              required
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setCodigoGuardar(e.target.value);
              }}
              inputProps={{
                maxLength: 10,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseCodigo} color="primary">
            Cancelar
          </Button>
          <Button onClick={onSaveClicked} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={open2}
        onClose={() => setOpen2(false)}
        title={`Error`}
        content="Codigo Inválido"
      />
      ;
    </>
  );
}

export default ConsejoElectoralDetail;
