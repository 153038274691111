import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useModel } from "../../hooks/useModel";
import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import LandscapeIcon from "@material-ui/icons/Landscape";

const Municipio = ({
  dialog,
  setEditingMunicipio,
  setSelectedMunicipio,
  handleClose,
}) => {
  const history = useHistory();
  const header = [];
  const [nombre, setNombre] = React.useState("");
  const [idEstado, setIdEstado] = React.useState("26");
  const [clearEstado, setClearEstado] = React.useState(false);
  const [nombreEstado, setNombreEstado] = useState("Sonora");
  const [numero, setNumero] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();

  const municipioParamsMemo = React.useMemo(() => ({ idEstado: 26 }), []);

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "municipio",
    expand: "estado",
    idEstado: 26,
    ordenar: "idMunicipio.desc",
  });

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "estado",
    expand: "",
    id: "26",
  });

  const estadoParamsMemo = React.useMemo(
    () => ({
      ordenar: "nombre.asc",
    }),
    [idEstado]
  );

  const setIdEstadoCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdEstado(v.idEstado);
    } else {
      setIdEstado("");
    }
  }, []);

  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    if (numero.length > 0) params = { ...params, numero };
    if (idEstado > 0) params = { ...params, idEstado };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
  };

  const onDeleteClicked = async (row) => {
    try {
      const res = await swal({
        title: "Eliminar",
        text: "¿Desea eliminar este municipio?",
        icon: "error",
        buttons: ["No", "Si"],
        onOpen: () => {
          swal.showLoading();
        },
      });

      if (res) {
        setOpenMenuRow(null);
        await deleteModel(row.idMunicipio);
        await refreshModels();
      }
    } catch (error) {
      console.modelsError(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "municipio",
      expand: "estado",
      ordenar: "idMunicipio.desc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      nombre,
      numero,
      idEstado,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="MUNICIPIOS"
      actionButton={{
        to: "municipios/nuevo",
        icon: <AddIcon />,
        label: "Nuevo Municipio",
      }}
      links={[
        {
          to: "/municipios",
          icon: <LandscapeIcon />,
          label: "Municipios",
        },
      ]}
      history={history}
      SearchComponents={
        <>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Nombre"
              onChange={(event) => setNombre(event.target.value)}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              type="number"
              min={0}
              size="small"
              label="Número"
              onChange={(event) => setNumero(event.target.value)}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <AsyncAutocompleteSearch
              label="Estado"
              name="estado"
              labelProp="nombre"
              defaultValue={model && model}
              onChange={setIdEstadoCallback}
              extraParams={estadoParamsMemo}
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre",
              cell: (row, index) => <div>{`${row.nombre}`}</div>,
            },
            {
              prop: "numero",
              name: "Numero",
              cell: (row, index) => (
                <div>{`${row.numero ? row.numero : ""}`}</div>
              ),
            },
            {
              prop: "estado",
              name: "Estado",
              cell: (row, index) => <div>{row?.estado?.nombre}</div>,
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/municipios/detalle?id=${row.idMunicipio}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Municipio;
