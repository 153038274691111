import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import * as deLocale from "date-fns/locale/es/index";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";

import { ArrowBack, Save } from "@material-ui/icons";

import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";

import { useHttp } from "../../hooks/useHttp";
import { useModel } from "../../hooks/useModel";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import { emptyRequest, postRequest } from "../../constants/request";
import { useQuery } from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import CustomDialog from "../../components/CustomDialog";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LandscapeIcon from "@material-ui/icons/Landscape";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

function MotivoAperturaCierreDetail({
  match,
  history,
  dialog,
  editingMotivoAperturaCierre,
  setMotivoAperturaCierreSearch,
}) {
  const classes = useStyles();
  const query = useQuery();
  const idMotivoAperturaCierre = query.get("id");
  const editing = !!idMotivoAperturaCierre;
  const { showAlert } = useAlert();

  const [validating, setValidating] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(true);

  const [motivo, setMotivo] = React.useState("");

  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "motivo-apertura-cierre",
    id: idMotivoAperturaCierre,
    redirectOnPost: true,
  });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setMotivo(model.motivo);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSaveClicked = () => {
    setValidating(true);
    const body = {
      motivo,
    };

    if (editing) {
      body.idMotivoAperturaCierre = idMotivoAperturaCierre;
    }

    updateModel(body);
  };

  const setMotivoCallback = React.useCallback(
    (e) => {
      setMotivo(e.target.value);
    },
    [setMotivo]
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="MOTIVO APERTURA CIERRE"
        links={[
          {
            to: "/motivo-apertura-cierre",
            icon: <AllInboxIcon />,
            label: "Motivo Apertura Cierre",
          },
          {
            to: "/motivo-apertura-cierre/nuevo",
            icon: <ListAltIcon />,
            label: "Nuevo",
          },
        ]}
        editing={editing}
        dialog={dialog}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <CustomDialog
          isOpen={isOpen}
          handleClose={handleClose}
          title="Motivo Apertura Cierre"
        ></CustomDialog>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Motivo"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.motivo}
            error={Boolean(modelError.motivo)}
            onChange={setMotivoCallback}
            inputProps={{
              value: motivo,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSaveClicked}
              >
                {editing ? "EDITAR" : "CREAR"} MOTIVO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default MotivoAperturaCierreDetail;
