import React from "react";

import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Inbox, Search } from "@material-ui/icons";

import { baseUrlPdf } from "../../utils/variables";
import FullScreenLoader from "../../components/FullScreenLoader";
import PdfDialog from "../../components/PdfDialog";

import { auth } from "../../firebase";
import { useAuth } from "../../hooks/useAuth";
import { useModels } from "../../hooks/useModels";
import TablePagination from "../../components/TablePagination";
import { useModel } from "../../hooks/useModel";
import Loader from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  logo: {
    width: "100%",
  },
  title: {
    fontSize: 20,
    paddingLeft: 60,
    paddingRight: 60,
  },
  docPadding: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  description: {
    fontSize: 18,
    textAlign: "justify",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontSize: 22,
  },
  body: {
    fontSize: 18,
    color: "#000",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const getReq = {
  name: null,
  // extraParams: {
  //   informe: 1,
  //   limite: -1,
  // },
  // fields:
  //   "actaEstatusGlobal.estatusActa,actaEstatusGlobal.estatusPaquete,motivosRecuento,actaRegistroVoto.participantes",
  // expand:
  //   "actaEstatusGlobal.estatusActa,actaEstatusGlobal.estatusPaquete,motivosRecuento,actaRegistroVoto.participantes",
};

const hoy = new Date();

const tableHeader = [
  {
    prop: "nombreCompletoActa",
    name: "Paquetes/Actas",
  },
];

const reqDiferencia = {
  name: "acta/reporte-diferencia-porcentual",
  id: -1,
  override: true,
};

const Reportes = () => {
  const classes = useStyles();
  const { user, userLoading } = useAuth();
  const id = auth.currentUser.uid;
  const [request, setRequest] = React.useState(getReq);
  const [busqueda, setBusqueda] = React.useState("");

  const [
    actas,
    actasLoading,
    actasError,
    actasPage,
    refreshActas,
    deleteActas,
  ] = useModels(request);

  const { model: porcentual, modelLoading: porcentualLoading } =
    useModel(reqDiferencia);

  React.useEffect(() => {
    setRequest({
      name: "acta",
      extraParams: {
        informe: 1,
      },
      fields:
        "nombreCompletoActa,actaEstatusGlobal.estatusActa,actaEstatusGlobal.estatusPaquete,motivosRecuento,actaRegistroVoto.participantes",
      expand:
        "actaEstatusGlobal.estatusActa,actaEstatusGlobal.estatusPaquete,motivosRecuento,actaRegistroVoto.participantes",
    });
  }, []);

  const changePage = (page) => {
    let params = {
      name: "acta",
      informe: 1,
      pagina: page + 1,
      isCargando: false,
      fields:
        "nombreCompletoActa,actaEstatusGlobal.estatusActa,actaEstatusGlobal.estatusPaquete,motivosRecuento,actaRegistroVoto.participantes",
      expand:
        "actaEstatusGlobal.estatusActa,actaEstatusGlobal.estatusPaquete,motivosRecuento,actaRegistroVoto.participantes",
    };
    refreshActas(true, params);
  };

  const onSearchClicked = React.useCallback(async () => {
    let params = {};
    if (busqueda.length > 0) params = { ...params, busqueda };
    await refreshActas(false, params);
  }, [busqueda, refreshActas]);

  if (!user || userLoading || porcentualLoading) return <FullScreenLoader />;

  return (
    <div className={classes.root}>
      <Grid
        container
        component={Paper}
        spacing={3}
        justify="center"
        className={classes.docPadding}
      >
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <img
                src={process.env.PUBLIC_URL + "/iee_logo_color.png"}
                alt="logo"
                className={classes.logo}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography align="center" className={classes.title}>
                INFORME DEL ANÁLISIS PRELIMINAR SOBRE LA CLASIFICACIÓN DE LOS
                PAQUETES ELECTORALES Y LAS ACTAS DE ESCRUTINIO Y CÓMPUTO
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {/* <Button
                variant="contained"
                color="secondary"
                component="a"
                onClick={() => {
                  setPdfDialogOpen(true);
                }}
                target="_blank"
                style={{
                  float: "right",
                }}
                size="small"
                endIcon={<Inbox />}
              >
                Informe del Presidente
              </Button> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.description}>
            En términos de lo establecido en el artículo 245 fracción V de la
            Ley de Instituciones y Procedimientos Electorales para el Estado de
            Sonora, así como en los apartados II.2 numeral 4 inciso c) y II.4 de
            los Lineamientos que Regulan el Desarrollo de las Sesiones de
            Cómputos del Proceso Electoral Ordinario Local 2020-2021 en Sonora,
            se tiene que la Presidencia debe presentar un informe con un
            análisis preliminar sobre la clasificación de los paquetes
            electorales con y sin muestras de alteración; de las actas de
            casilla que no coincidan, de aquellas en que se detectaran
            alteraciones, errores o inconsistencias evidentes en los distintos
            elementos de las actas; de aquellas en las que no exista en el
            expediente de casilla ni obre en poder de la Presidencia el acta de
            escrutinio y cómputo; y aquellas en las que exista causa para
            determinar la posible realización de un nuevo escrutinio y cómputo;
            asimismo, el informe debe incluir un apartado sobre la presencia o
            no del indicio consistente en una diferencia igual o menos al uno
            por ciento en los resultados correspondientes a los lugares primero
            y segundo de la votación respectiva de la elección, como requisitos
            para el recuento total de votos.
            <br />
            <br />
          </Typography>
          <Typography className={classes.description}>
            En atención a los anterior, el/la C.{" "}
            {`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`},
            en su carácter de Consejero(a) Presidente(a) del{" "}
            {user.consejoElectoral.nombre}, en la Reunión de trabajo celebrada
            en fecha {hoy.toLocaleString("default", { day: "numeric" })} de
            junio de 2021, presenta el Informe de Análisis Preliminar sobre la
            Clasificación de los Paquetes Electorales y las Actas de Escrutinio
            y Cómputo, en el proceso electoral ordinario local 2020-2021, en los
            siguientes términos:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {actasLoading ? null : (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: 15 }}
              justify="flex-end"
            >
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  label="Buscar por nombre o tipo"
                  value={busqueda}
                  onChange={(event) => setBusqueda(event.target.value)}
                  variant="outlined"
                  onKeyUp={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      onSearchClicked();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onSearchClicked}
                  disabled={actasLoading}
                  fullWidth
                  size="small"
                  endIcon={<Search />}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          )}

          <TableContainer component={Paper}>
            {actasLoading ? (
              <Paper>
                <Loader />
              </Paper>
            ) : (
              <TablePagination
                header={tableHeader}
                data={actas}
                paginatedApi
                changePageApi={changePage}
                size="small"
                count={actasPage !== null ? actasPage.total : 0}
                extraRows={[
                  {
                    prop: "nombreEstatusPaquete",
                    name: "Estatus Paquete",
                    cell: (row) =>
                      row?.actaEstatusGlobal?.estatusPaquete?.nombre,
                  },
                  {
                    prop: "nombreEstatusActa",
                    name: "Estatus Acta",
                    cell: (row) => row?.actaEstatusGlobal?.estatusActa?.nombre,
                  },
                  {
                    prop: "nombreMotivoRecuento",
                    name: "Motivo Recuento",
                    cell: (row) =>
                      row?.motivosRecuento?.length > 0 ? (
                        row?.motivosRecuento?.map((motivo) => (
                          <>
                            {motivo.nombre}
                            <br />
                          </>
                        ))
                      ) : (
                        <i>-</i>
                      ),
                  },
                ]}
              />
            )}

            {/*             
            <Table aria-label="customized table" size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={4}>
                    <Typography>
                      <strong>
                        Clasificación de Paquetes Electorales y Actas de
                        Escrutinio y Cómputo
                      </strong>
                    </Typography>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>
                    <Typography>
                      <strong>Paquetes/Actas</strong>
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>
                      <strong>Estatus Paquete</strong>
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>
                      <strong>Estatus Acta</strong>
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>
                      <strong>Motivo Recuento</strong>
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actas.map((acta, i) => (
                  <StyledTableRow key={`actas-${i}`}>
                    <StyledTableCell>{acta.nombreCompletoActa}</StyledTableCell>
                    <StyledTableCell>
                      {acta.actaEstatusGlobal.idEstatusPaquete in [2, 4, 5]
                        ? acta.actaEstatusGlobal.estatusPaquete.nombre
                        : "PAQUETE NO RECIBIDO"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {acta.actaEstatusGlobal.estatusActa?.nombre}
                    </StyledTableCell>
                    <StyledTableCell>
                      {acta.motivosRecuento.length > 0
                        ? acta.motivosRecuento.map((m) => (
                            <>{`- ${m.nombre}`}</>
                          ))
                        : "-"}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>
                    <strong>Total: {actas && actasPage.total}</strong>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
           */}
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.description}>
            En relación a los resultados señalados con antelación, se tiene que
            en cuanto a los dos candidatos que encabezan la votación del
            presente proceso electoral 2021, el reporte de diferencia porcentual
            es el que se señala a continuación:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table" size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={2}>
                    <Typography>
                      <strong>Reporte de Diferencia Porcentual</strong>
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>Primer Lugar</StyledTableCell>
                  <StyledTableCell>
                    {porcentual?.primerLugar} votos
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Segundo Lugar</StyledTableCell>
                  <StyledTableCell>
                    {porcentual?.segundoLugar} votos
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>
                    Total de Votos de Todos los Candidatos
                  </StyledTableCell>
                  <StyledTableCell>
                    {porcentual?.totalVotos} votos
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>
                    Diferencia entre Primer y Segundo Lugar
                  </StyledTableCell>
                  <StyledTableCell>
                    {porcentual?.diferencia} votos
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Diferencia porcentual</StyledTableCell>
                  <StyledTableCell>
                    {porcentual?.diferenciaPorcentual}%
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {porcentual && porcentual.diferenciaPorcentual > 1 ? (
            <Typography style={{ marginTop: 15 }}>
              Al no presentarse una diferencia porcentual menor o igual al 1%
              del total de los votos, no será necesario que este Consejo
              Electoral realice un recuento total para la elección que le
              corresponde.
            </Typography>
          ) : (
            <Typography style={{ marginTop: 15 }}>
              Al presentarse una diferencia porcentual menor o igual al 1% del
              total de los votos, será necesario que este Consejo Electoral
              realice un recuento total para la elección que le corresponde.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.description}>
            Sin más por el momento, queda este Instituto a la orden para
            cualquier duda o aclaración al respecto.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center">
            <strong>
              A T E N T A M E N T E<br />
              <br />
              <br />
              {`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`}
              <br />
              {user.puesto}
              <br />
              {user.consejoElectoral.nombre}, SONORA
            </strong>
          </Typography>
        </Grid>
      </Grid>
      {/* <PdfDialog
        source={`${baseUrlPdf}informe?idUsuario=${id}`}
        open={pdfDialogOpen}
        setOpen={setPdfDialogOpen}
      /> */}
    </div>
  );
};

export default Reportes;
