import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import PageLayout from "../components/PageLayout";
import { useModels } from "../hooks/useModels";
import AsyncAutocompleteSearch from "../components/AsyncAutocompleteSearch";
import { Search } from "@material-ui/icons";
import {baseUrlPdf} from "../utils/variables";
import { auth } from "../firebase";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  panelEspecialText: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
  },
  panelText: {
    color: "#000",
    fontSize: 24,
    fontWeight: "bold",
  },
  table: {
    width: "50%",
  },
}));

const reqProperties = {
  name: "",
};

const DescargarActasComputo = () => {
  const classes = useStyles();
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [request, setRequest] = React.useState(reqProperties);
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels(request);
  const idUsuario = auth.currentUser.uid;

  React.useEffect(() => {
    let mounted = true;
    let timeout = setInterval(() => {
      if (mounted) {
        refreshModels();
      }
    }, 30000);
    return () => {
      if (timeout !== null) {
        clearInterval(timeout);
      }
      mounted = false;
    };
  }, [refreshModels]);

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    (e, v) => {
      if (!v) return;
      const { idConsejoElectoral } = v;
      if (idConsejoElectoral && idConsejoElectoral !== "") {
        const req = { ...reqProperties };
        req.extraParams = { ...req.extraParams, idConsejoElectoral };
        setRequest(req);
        setIdConsejoElectoral(idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setRequest]
  );

  const onSearchClicked = async () => {
    window.open(`${baseUrlPdf}computo-final/descargar-boletas?idConsejoElectoral=${idConsejoElectoral}&idUsuario=${idUsuario}`, '_blank');
  };

  return (
    <PageLayout
      title="Avance Cómputo"
      SearchComponents={
        <>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <AsyncAutocompleteSearch
                label="Consejo Electoral"
                name="consejo-electoral"
                labelProp="nombre"
                onChange={setIdConsejoElectoralCallback}
                extraParams={consejoElectoralParamsMemo}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onSearchClicked}
                disabled={modelsLoading}
                endIcon={<Search />}
              >
                Descargar
              </Button>
            </Grid>
          </Grid>
        </>
      }
    >
    </PageLayout>
  );
};

export default DescargarActasComputo;
