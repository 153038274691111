import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
  DialogContent,
  Typography,
  Box,
} from "@material-ui/core";
import TablePagination from "../../../components/TablePagination";
// import { format } from 'date-fns';

const extraRows = [
  {
    prop: "nombre",
    name: "Sección-Casilla",
    cell: (row) => (
      <div>
        {" "}
        {row.nombreCompletoActa ? (
          row.nombreCompletoActa
        ) : (
          <>
            {row.idSeccion} - {row.tipoCasilla}{" "}
            {row.numeroContigua ? " - " + row.numeroContigua : ""}{" "}
          </>
        )}
      </div>
    ),
  },
  // {
  //   prop: "recibido",
  //   name: "Recibido",
  //   cell: (row) => row.fecha ? format(new Date(row.fecha), "dd-MM-yyyy hh:mm:ss") : "Sin Recibo Capturado",
  // }
];

const DialogEnvioPaquetes = ({
  open,
  paquetes = {},
  onClose,
  onAccept,
  saving,
  title,
}) => {
  return (
    <Dialog fullWidth open={open} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {paquetes.distritales.length ? (
          <>
            <Typography>
              <Box fontWeight="fontWeightBold">Diputaciones</Box>
            </Typography>
            <TablePagination
              data={paquetes.distritales}
              extraRows={extraRows}
            />
          </>
        ) : null}
        {paquetes.estatales.length ? (
          <>
            <Typography style={{ marginTop: 30 }}>
              <Box fontWeight="fontWeightBold">Gubernatura</Box>{" "}
            </Typography>

            <TablePagination data={paquetes.estatales} extraRows={extraRows} />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        {saving ? null : (
          <Button onClick={() => onClose()} color="primary">
            Cerrar
          </Button>
        )}
        <Button onClick={onAccept} color="primary" disabled={saving}>
          {saving ? <CircularProgress /> : "Aceptar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEnvioPaquetes;
