import React from "react";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import Loader from "./Loader";

const filter = createFilterOptions();

export default function CreatableAutocomplete({
  label,
  title,
  subtitle,
  error,
  data,
  setData,
  labelKey,
  model,
  entry,
  onChange,
  dialogForm: DialogForm,
  ...props
}) {
  const [open, toggleOpen] = React.useState(false);
  const [loading] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    ...model,
  });

  const handleClose = () => {
    setDialogValue({ ...model });
    toggleOpen(false);
  };

  const onInputChange = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      setDialogValue((_) => {
        let entry = { ...model };
        entry[labelKey] = newValue.inputValue;
        return { ...entry };
      });
      toggleOpen(true);
    }
    if (onChange) onChange(newValue);
  };

  const onFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        [labelKey]: `Agregar "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  const onGetOptionLabel = (option) => {
    if (option.inputValue) {
      return option.inputValue;
    }
    return option[labelKey] || "";
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Autocomplete
            fullWidth
            size="small"
            value={entry}
            onChange={onInputChange}
            filterOptions={onFilterOptions}
            options={data || []}
            getOptionLabel={onGetOptionLabel}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) => option[labelKey]}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                error={error}
              />
            )}
          />
          {DialogForm ? (
            <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: { boxShadow: "none" },
              }}
              BackdropProps={{
                style: {
                  backgroundColor: "#000",
                  opacity: 0.25,
                },
              }}
              maxWidth="md"
              children={{ ...DialogForm }}
            ></Dialog>
          ) : null}
        </>
      )}
    </>
  );
}
