import React from "react";
import { Ballot } from "@material-ui/icons";
import { IconButton, Button, Grid, Menu, MenuItem } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import { generateDefaultChartOptions, getPercentage } from "../../utils";
import { useModels } from "../../hooks/useModels";

const actasRequestDefault = {
  name: "consejo-electoral/estadistico-lectura",
  // fields: "idActa,nombreCompletoActa",
  extraParams: {
    // estatusLectura: "con-lectura",
    // expand: "fechaLectura",
  },
};

const EstadisticoLecturaVotos = ({ history }) => {
  const header = [];
  const [nombre, setNombre] = React.useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [actasRequest, setActasRequest] = React.useState(actasRequestDefault);
  const [actas, actasLoading] = useModels(actasRequest);
  const [models, modelsLoading, modelsError] = useModels({
    name: "lectura-voto/resultado",
  });
  let chartOptions = generateDefaultChartOptions();

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    (e, v) => {
      if (!v) return;
      const { idConsejoElectoral } = v;
      if (idConsejoElectoral && idConsejoElectoral !== "") {
        const req = { ...actasRequestDefault };
        req.extraParams = { ...req.extraParams, idConsejoElectoral };
        setActasRequest(req);
        setIdConsejoElectoral(idConsejoElectoral);
      }
    },
    [setActasRequest]
  );

  const onSearchClicked = async () => {
    if (idConsejoElectoral && idConsejoElectoral !== "") {
      const req = { ...actasRequestDefault };
      req.extraParams = { ...req.extraParams, idConsejoElectoral };
      setActasRequest(req);
    }
  };

  const totales = models.length > 0 ? models[0] : {};

  const sinLecturaInt =
    actas && actas[0]
      ? parseInt(actas[0]?.actasTotal.toString().replace(",", "")) -
        parseInt(actas[0]?.actasLectura.toString().replace(",", ""))
      : 0;

  const leidasInt =
    actas && actas[0]
      ? parseInt(actas[0]?.actasLectura.toString().replace(",", ""))
      : 0;

  let extraOptions = {
    colors: ["#9E9E9E", "#ad2624"],
    title: {
      text: "LECTURA DE VOTOS",
    },
    subtitle: {
      text: "TOTALIZADOR DE ACTAS POR LECTURA DE VOTOS",
    },
    series: [
      {
        name: "Actas",
        data: [
          ["Sin Lectura", sinLecturaInt],
          ["Leídas", leidasInt],
          // ["Sin Recibo Paquete", totales.sinrpe],
        ],
      },
    ],
  };
  chartOptions = { ...chartOptions, ...extraOptions };

  return (
    <TablePageLayout
      title="ESTADÍSTICOS: LECTURA DE VOTOS POR CONSEJO ELECTORAL"
      model="consejos-electorales"
      history={history}
      links={[
        {
          to: "/lectura-votos",
          icon: <Ballot />,
          label: "Estadísticos: Lectura de votos",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={actasLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={actasLoading}
      // tableErrors={consejosElectoralesError}

      TableComponent={
        <Grid container>
          <Grid item xs={6} style={{ marginTop: 100 }}>
            <TablePagination
              header={header}
              data={actas}
              size="small"
              paginatedApi
              // changePageApi={changePage}
              // count={modelsPage ? modelsPage.total : 0}
              extraRows={[
                {
                  prop: "actasTotal",
                  name: "TOTAL ACTAS",
                  cell: (row) => <>{row.actasTotal}</>,
                },
                {
                  prop: "actasLectura",
                  name: "CON LECTURA",
                  cell: (row) => <>{row.actasLectura}</>,
                },
                {
                  prop: "actasDatosGenerales",
                  name: "CON DATOS GENERALES",
                  cell: (row) => <>{row.actasDatosGenerales}</>,
                },
                {
                  prop: "porcentajeCompletadas",
                  name: "FALTANTES LECTURA",
                  cell: (row) => (
                    <>
                      {row.actasFaltantesLectura}
                      {/* {parseFloat(
                        (((parseInt(
                          row.actasLectura.toString().replace(",", "")
                        ) +
                          parseInt(
                            row.actasDatosGenerales.toString().replace(",", "")
                          )) *
                          100) /
                          parseInt(
                            row.actasTotal.toString().replace(",", "")
                          )) *
                          2
                      ).toFixed(2) + " %"} */}
                    </>
                  ),
                },
              ]}
            />
          </Grid>

          <Grid item xs={6}>
            {idConsejoElectoral !== "" && (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default EstadisticoLecturaVotos;
