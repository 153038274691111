import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import FullScreenLoader from "./FullScreenLoader";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, userLoading } = useAuth();
  const { permisoCheck } = rest;

  if (!user || user === null || userLoading) {
    return <FullScreenLoader />;
  }
  if (user && !user.permiso[permisoCheck])
    return <Redirect to="/no-autorizado" />;
  if (user && user.permiso[permisoCheck])
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  return null;
};

PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
};

export default PrivateRoute;
