import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { ArrowBack, Save } from "@material-ui/icons";

import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";

import { useHttp } from "../../hooks/useHttp";
import { useModel } from "../../hooks/useModel";

import { emptyRequest, postRequest } from "../../constants/request";
import { useQuery } from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import CustomDialog from "../../components/CustomDialog";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LandscapeIcon from "@material-ui/icons/Landscape";
import FilterNoneIcon from "@material-ui/icons/FilterNone";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2) * -1,
  },
  formControl: {
    width: "100%",
  },
}));

function TipoActaDetail({
  match,
  history,
  dialog,
  editingTipoActa,
  setTipoActasSearch,
}) {
  const classes = useStyles();
  const query = useQuery();
  const idTipoActa = query.get("id");
  const editing = !!idTipoActa;
  const { showAlert } = useAlert();
  const [validating, setValidating] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(true);

  const [nombre, setNombre] = React.useState("");

  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);

  const {
    model,
    modelLoading,
    modelError,
    updateModel,
    updateModelLoading,
  } = useModel({
    name: "tipo-acta",
    expand: "",
    id: idTipoActa,
    redirectOnPost: true,
  });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setNombre(model.nombre);
    }
    return () => {
      mounted = false;
    };
  }, [model]);

  const onSubmitPressed = () => {
    setValidating(true);
    const body = {
      nombre,
    };
    if (editing) {
      body.idTipoActa = idTipoActa;
    }

    updateModel(body);
  };

  const setNombreCallback = React.useCallback(
    (e) => {
      setNombre(e.target.value);
    },
    [setNombre]
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title="TIPO ACTA"
        links={[
          {
            to: "/tipos-acta",
            icon: <FilterNoneIcon />,
            label: "Tipos Acta",
          },
          {
            to: "/tipos-acta/nuevo",
            icon: <ListAltIcon />,
            label: "Nuevo",
          },
        ]}
        editing={editing}
        dialog={dialog}
      />

      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nombre"
            size="small"
            variant="outlined"
            fullWidth
            helperText={modelError.nombre}
            error={Boolean(modelError.nombre)}
            onChange={setNombreCallback}
            inputProps={{
              value: nombre,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateModelLoading}
                endIcon={<Save />}
                onClick={onSubmitPressed}
              >
                {editing ? "EDITAR" : "CREAR"} TIPO ACTA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default TipoActaDetail;
