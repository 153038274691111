import React from "react";

import { CssBaseline, Snackbar, ThemeProvider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import MuiTheme from "./theme/MuiTheme";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";

import { CacheBusterProvider } from "./hooks/CacheBuster";
import { AlertProvider, useAlert } from "./hooks/useAlert";
import { AppProvider } from "./hooks/useApp";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { ConfirmDialogProvider } from "./hooks/useConfirmDialog";
import { NotificationsProvider } from "./hooks/useNotifications";

const AppRouting = () => {
  const { session, sessionLoading } = useAuth();
  const { open, position, severity, message } = useAlert();

  const AlertMemo = React.useMemo(
    () => (
      <Snackbar open={open} anchorOrigin={position}>
        <Alert severity={severity} elevation={6} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    ),
    [open, position, severity, message]
  );

  if (sessionLoading) return null;

  return (
    <>
      {session ? <PrivateRoutes /> : <PublicRoutes />}
      {AlertMemo}
    </>
  );
};

const App = () => {
  return (
    <CacheBusterProvider>
      <AppProvider>
        <ThemeProvider theme={MuiTheme}>
          <CssBaseline />
          <AlertProvider>
            <ConfirmDialogProvider>
              <NotificationsProvider>
                <AuthProvider>
                  <AppRouting />
                </AuthProvider>
              </NotificationsProvider>
            </ConfirmDialogProvider>
          </AlertProvider>
        </ThemeProvider>
      </AppProvider>
    </CacheBusterProvider>
  );
};

export default App;
