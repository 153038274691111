import React, { useState } from "react";
import TablePagination from "../../components/TablePagination";
import { Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Loading from "../../components/FullScreenLoader";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useModels } from "../../hooks/useModels";
import SearchIcon from "@material-ui/icons/Search";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import { useAuth } from "../../hooks/useAuth";
import { format } from "date-fns";
import PageLayout from "../../components/PageLayout";
import GetAppIcon from "@material-ui/icons/GetApp";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ListIcon from "@material-ui/icons/List";
import { baseUrl } from "../../utils/variables";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const AdministracionBodegaDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const [fechaApertura, setFechaApertura] = useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = useState("");
  const [fechaCierre, setFechaCierre] = useState("");
  const { user, userLoading } = useAuth();
  const [models, modelsLoading, modelsError, modelsPage, refreshModels] =
    useModels({
      name: "bitacora-apertura-cierre",
      ordenar: "idBitacoraAperturaCierre.desc",
      expand: "consejoElectoral,motivosAperturaCierre",
      pagina: 0,
    });

  const consejoCambio = React.useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setIdConsejoElectoral(v.idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setIdConsejoElectoral]
  );

  const onSearchClicked = async () => {
    let params = {};
    if (fechaApertura !== "") params = { ...params, fechaApertura };
    if (fechaCierre !== "") params = { ...params, fechaCierre };
    if (idConsejoElectoral.toString().length > 0)
      params = { ...params, idConsejoElectoral };
    await refreshModels(true, params);
  };

  const changePage = (page) => {
    let params = {
      name: "bitacora-apertura-cierre",
      ordenar: "idBitacoraAperturaCierre.desc",
      expand: "consejoElectoral,motivosAperturaCierre",
      fechaApertura,
      fechaCierre,
      idConsejoElectoral,
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  return (
    <>
      <div className={classes.root}>
        <PageLayout
          title="BITÁCORA"
          history={history}
          links={[
            {
              to: "/bodegas/administracion",
              icon: <AssignmentIcon fontSize="small" />,
              label: "Administración",
            },
            {
              to: "/bodegas/administracion/bodega",
              icon: <ListIcon fontSize="small" />,
              label: "Bodega",
            },
          ]}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
            style={{ margin: "3px 0px" }}
          >
            <Grid item xs={12} sm={3}>
              <TextField
                id="datetime-local"
                label="Fecha de Apertura"
                variant="outlined"
                fullWidth
                size="small"
                type="date"
                onChange={(event) => setFechaApertura(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="datetime-local"
                label="Fecha de Cierre"
                variant="outlined"
                fullWidth
                size="small"
                type="date"
                onChange={(event) => setFechaCierre(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {user && user.permiso && user.permiso.rol === "Admin" && (
              <Grid item xs={2}>
                <AsyncAutocompleteSearch
                  label="Consejo"
                  name="consejo-electoral"
                  labelProp="nombre"
                  onChange={consejoCambio}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSearchClicked}
                disabled={modelsLoading}
                startIcon={<SearchIcon />}
              >
                {modelsLoading ? (
                  <CircularProgress
                    size={25}
                    style={{
                      marginRight: 13,
                      marginLeft: 13,
                      color: "white",
                    }}
                  />
                ) : (
                  "Buscar"
                )}
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                component="a"
                style={{ backgroundColor: "#4caf50", color: "white" }}
                fullWidth
                variant="contained"
                disabled={modelsLoading}
                startIcon={<GetAppIcon />}
                href={`${baseUrl}excel/excel-bitacora-apertura-cierre?nombre=Bitacora bodega ${
                  user && user.consejoElectoral
                    ? user.consejoElectoral.nombre
                    : "SIN CONSEJO ELECTORAL"
                } &fechaApertura=${fechaApertura}&fechaCierre=${fechaCierre}&idConsejoElectoral=${
                  user?.idConsejoElectoral
                }`}
              >
                Excel
              </Button>
            </Grid>
            <Grid item xs={12}>
              {modelsLoading ? (
                <Loading />
              ) : (
                <TablePagination
                  header={[]}
                  data={models || []}
                  paginatedApi
                  changePageApi={changePage}
                  count={modelsPage !== null ? modelsPage.total : 0}
                  labelRowsPerPage={"Renglones por página"}
                  extraRows={[
                    {
                      prop: "consejoElectoral",
                      name: "Consejo Electoral",
                      cell: (row) => (
                        <div>
                          {row.consejoElectoral
                            ? row.consejoElectoral.nombre
                            : ""}
                        </div>
                      ),
                    },
                    {
                      prop: "motivoAperturaCierre",
                      name: "Motivo de Apertura y Cierre",
                      cell: (row) => (
                        <div>
                          {row.motivosAperturaCierre
                            ? row.motivosAperturaCierre.map((m, i) => (
                                <div>{m.motivo}</div>
                              ))
                            : "Cierre de bodega"}
                        </div>
                      ),
                    },
                    {
                      prop: "fechaApertura",
                      name: "Fecha de Apertura",
                      cell: (row) => (
                        <div>
                          {row.fechaApertura
                            ? format(
                                new Date(row.fechaApertura),
                                "dd-MM-yyyy hh:mm:ss"
                              )
                            : "-"}
                        </div>
                      ),
                    },
                    {
                      prop: "fechaCierre",
                      name: "Fecha de Cierre",
                      cell: (row) => (
                        <div>
                          {row.fechaCierre
                            ? format(
                                new Date(row.fechaCierre),
                                "dd-MM-yyyy hh:mm:ss"
                              )
                            : "-"}
                        </div>
                      ),
                    },
                  ]}
                />
              )}
            </Grid>
          </Grid>
        </PageLayout>
      </div>
    </>
  );
};

export default AdministracionBodegaDetail;
