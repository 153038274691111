import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { photoBaseUrl } from "../../utils/variables";
import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import { useModel } from "../../hooks/useModel";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useModels } from "../../hooks/useModels";

const CandidatoIndependiente = () => {
  const history = useHistory();
  const header = [];

  const [nombre, setNombre] = React.useState("");
  const [claveCandidato, setClaveCandidato] = React.useState("");
  const [idMunicipio, setIdMunicipio] = React.useState("");
  const [cargo, setCargo] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const { showDialog, closeDialog } = useConfirmDialog();

  const municipioParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "candidato-independiente",
    expand: "municipio,distrito",
    ordenar: "idCandidatoIndependiente.desc",
  });

  const {
    modelMunicipio,
    modelMunicipioLoading,
    modelMunicipioError,
    updateMunicipioModel,
    updateMunicipioModelLoading,
  } = useModel({
    name: "municipio",
    expand: "estado",
    ordenar: "idMunicipio.desc",
    extraParams: municipioParamsMemo,
  });

  const setIdMunicipioCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      setIdMunicipio(v.idMunicipio);
    } else {
      setIdMunicipio("");
    }
  }, []);

  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    if (claveCandidato.length > 0) params = { ...params, claveCandidato };
    if (cargo.length > 0) params = { ...params, cargo };
    if (idMunicipio > 0) params = { ...params, idMunicipio };
    params = { ...params };
    await refreshModels(true, params);
  };

  const onDeleteClicked = (row) => {
    try {
      showDialog({
        onCancel: closeDialog,
        onOk: () => {
          setOpenMenuRow(null);
          deleteModel(row.idCandidatoIndependiente);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "candidato-independiente",
      ordenar: "idCandidatoIndependiente.desc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      nombre,
      idMunicipio,
      claveCandidato,
      cargo,
    };
    refreshModels(true, params);
  };

  return (
    <TablePageLayout
      title="CONTROL DE CANDIDATOS INDEPENDIENTES"
      model="candidatos-independientes"
      history={history}
      links={[
        {
          label: "Candidatos Independientes",
        },
      ]}
      actionButton={{
        to: "candidatos-independientes/nuevo",
        icon: <Add />,
        label: "Agregar candidato",
      }}
      SearchComponents={
        <>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Nombre"
              onChange={(event) => setNombre(event.target.value)}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Clave Candidato"
              onChange={(event) => setClaveCandidato(event.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <AsyncAutocompleteSearch
              label="Municipio"
              name="municipio"
              labelProp="nombre"
              defaultValue={modelMunicipio && modelMunicipio}
              onChange={setIdMunicipioCallback}
              extraParams={municipioParamsMemo}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Cargo"
              onChange={(event) => setCargo(event.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <TablePagination
          header={header}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "nombre",
              name: "Nombre",
              cell: (row, index) => <div>{`${row.nombre}`}</div>,
            },
            {
              prop: "claveCandidato",
              name: "Clave Candidato",
              cell: (row, index) => (
                <div>{`${row.claveCandidato && row.claveCandidato}`}</div>
              ),
            },
            {
              prop: "municipio",
              name: "Municipio",
              cell: (row, index) => (
                <div>{row.municipio && row.municipio.nombre}</div>
              ),
            },
            {
              prop: "cargo",
              name: "Cargo",
              cell: (row, index) => <div>{`${row.cargo && row.cargo}`}</div>,
            },
            {
              prop: "logo",
              name: "Imagen",
              cell: (row) => (
                <a
                  href={`${photoBaseUrl}${row.logo}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${photoBaseUrl}${row.logo}`}
                    alt="logo"
                    height={80}
                    style={{ borderRadius: 5 }}
                    align="center"
                  />
                </a>
              ),
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    disabled={row.permisoRol === "soporte"}
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/candidatos-independientes/detalle?id=${row.idCandidatoIndependiente}`
                        )
                      }
                    >
                      <EditIcon style={{ paddingRight: 5 }} />
                      Editar
                    </MenuItem>

                    <MenuItem onClick={() => onDeleteClicked(row)}>
                      <DeleteIcon style={{ paddingRight: 5 }} />
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default CandidatoIndependiente;
