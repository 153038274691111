import { Grid, Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import React from "react";
import { NavLink } from "react-router-dom";

const NoAutorizado = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ marginTop: 200 }}
    >
      <Grid item>
        <Error style={{ fontSize: 120, color: "#808080" }} />
      </Grid>
      <Grid item>
        <Typography style={{ fontSize: 40, color: "#808080" }}>
          No estás autorizado para acceder al recurso solicitado.
        </Typography>
        <Typography style={{ fontSize: 25, color: "#808080" }}>
          Haz click <NavLink to="/">aquí</NavLink> para volver al inicio.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoAutorizado;
