import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import { baseUrl, baseUrlPdf } from "../../utils/variables";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const Archivos = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ display: "inline-block" }}
        >
          <StyledBreadcrumb
            component="a"
            label="Inicio"
            icon={<HomeIcon fontSize="small" />}
            onClick={() => {
              history.push("/");
            }}
          />
          <StyledBreadcrumb
            component="a"
            label="Archivos"
            onClick={() => {
              history.push("/archivos");
            }}
          />
        </Breadcrumbs>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12} sm={5}>
                <FormControl className={classes.formControl} size="small">
                  <InputLabel htmlFor="grouped-select">Excels</InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    variant="outlined"
                    label="Excels"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <ListSubheader
                      style={{ fontWeight: "bold", color: "black" }}
                    >
                      18.4 RECEPCION DE PAQUETES ELECTORALES
                    </ListSubheader>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href={`${baseUrl}excel/excel-g1-l1?nombre=18.4-APERTURA_CIERRE_BODEGAS`}
                      value={1}
                    >
                      18.4-APERTURA_CIERRE_BODEGAS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g1-l2?nombre=18.4-BITACORA_PAQUETES_ELECT"
                      value={2}
                    >
                      18.4-BITACORA_PAQUETES_ELECT
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g1-l3?nombre=18.4-CLAUSURA_CASILLAS"
                      value={3}
                    >
                      18.4-CLAUSURA_CASILLAS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g1-l4?nombre=18.4-DETALLE_PRESENCIA_APERT"
                      value={4}
                    >
                      18.4-DETALLE_PRESENCIA_APERT
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g1-l5?nombre=18.4-FUNCIONAMIENTO_CRYT_DAT"
                      value={5}
                    >
                      18.4-FUNCIONAMIENTO_CRYT_DAT
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g1-l6?nombre=18.4-PRESENCIA_APERT_BODEGAS"
                      value={6}
                    >
                      18.4-PRESENCIA_APERT_BODEGAS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g1-l7?nombre=18.4-PRESENCIA_PARTIDOS_CLAUS"
                      value={7}
                    >
                      18.4-PRESENCIA_PARTIDOS_CLAUS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g1-l8?nombre=18.4-RECEPCIONES"
                      value={8}
                    >
                      18.4-RECEPCIONES
                    </MenuItem>
                    <ListSubheader
                      style={{ fontWeight: "bold", color: "black" }}
                    >
                      18.6 REGISTRO DE ACTAS DE ESCRUTINIO Y COMPUTO
                    </ListSubheader>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g2-l1?nombre=18.6-CASILLAS_AYUNTAMIENTOS"
                      value={9}
                    >
                      18.6-CASILLAS_AYUNTAMIENTOS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g2-l2?nombre=18.6-CASILLAS_DIPUTADOS_LOC"
                      value={10}
                    >
                      18.6-CASILLAS_DIPUTADOS_LOC
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g2-l3?nombre=CASILLAS_GOBERNADOR"
                      value={11}
                    >
                      18.6-CASILLAS_GOBERNADOR
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g2-l4?nombre=18.6-PROYECCION_GRUPOS_RECUENTO"
                      value={12}
                    >
                      18.6-PROYECCION_GRUPOS_RECUENTO
                    </MenuItem>
                    <ListSubheader
                      style={{ fontWeight: "bold", color: "black" }}
                    >
                      18.7 SISTEMA DE COMPUTOS DISTRITALES, MUNICIPALES, DE
                      CIRCUNSCRIPCION Y DE ENTIDAD FEDERATIVA
                    </ListSubheader>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g3-l1?nombre=18.7-AYUNTAMIENTO_ENCABEZADO_CASILLAS"
                      value={13}
                    >
                      18.7-AYUNTAMIENTO_ENCABEZADO_CASILLAS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g3-l2?nombre=18.7-CASILLAS_AYUNTAMIENTOS"
                      value={14}
                    >
                      18.7-CASILLAS_AYUNTAMIENTOS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g3-l3?nombre=18.7-DIPUTADOS_LOCALES_ENCABEZADO_CASILLAS"
                      value={15}
                    >
                      18.7-DIPUTADOS_LOCALES_ENCABEZADO_CASILLAS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g3-l4?nombre=18.7-CASILLAS_DIPUTADO_LOCALES"
                      value={16}
                    >
                      18.7-CASILLAS_DIPUTADO_LOCALES
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g3-l5?nombre=18.7-GOBERNADOR_ENCABEZADO_CASILLAS"
                      value={17}
                    >
                      18.7-GOBERNADOR_ENCABEZADO_CASILLAS
                    </MenuItem>
                    <MenuItem
                      component="a"
                      target="_blank"
                      href="https://api.computosonora.com/v1/excel/excel-g3-l6?nombre=18.7-CASILLAS_GOBERNADOR"
                      value={18}
                    >
                      18.7-CASILLAS_GOBERNADOR
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Archivos;
