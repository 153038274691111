import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextField
} from "@material-ui/core";
import SingleSelect from "../../components/SingleSelect";
import PageLayout from "../../components/PageLayout";
import TimePicker from "../../components/TimePicker";
import RowClausuraCasilla from "./rowClausuraCasilla";
import { useAlert } from "../../hooks/useAlert";
import Services from "../../services";
import FullScreenLoader from "../../components/FullScreenLoader";
import NoMeetingRoomIcon from "@material-ui/icons/NoMeetingRoom";
import ArchiveIcon from "@material-ui/icons/Archive";

const url = "casilla-clausura";
const ignoreKeys = [
  "idCasillaClausura",
  "fechaClausura",
  "creado",
  "idCasilla",
  'motivo'
];

const ClausuraCasillas = ({ history, match }) => {
  const { showAlert } = useAlert();
  const [dia, setDia] = useState("");
  const [tiempo, setTiempo] = useState();
  const [clausuraCasilla, setClausuraCasilla] = useState({
    idCasillaClausura: null,
    idCasilla: match.params.idCasilla,
    fechaClausura: null,
    pPresidente: null,
    pSecretario: null,
    pPrimerEscrutador: null,
    pSegundoEscrutador: null,
    generacionConstancia: null,
    motivoCambio: null,
    na: null,
    morena: null,
    pan: null,
    mc: null,
    pes: null,
    pri: null,
    pt: null,
    fs: null,
    rsp: null,
    pvem: null,
    prd: null,
    motivo: ''
  });
  const [cargando, setCargando] = useState(true);
  const [guardando, setGuardando] = useState(false);

  useEffect(() => {
    let mounted = true;

    const obtenerCasillaClausura = async () => {
      try {
        const respuesta = await Services.get(
          url + "?idCasilla=" + match.params.idCasilla
        );

        if (mounted && respuesta.resultado[0]) {
          const _clausuraCasillas = { ...clausuraCasilla };
          const clausura = { ...respuesta.resultado[0] };
          const fechaClausura = respuesta.resultado[0].fechaClausura;

          for (const key in clausura) {
            if (!ignoreKeys.includes(key)) {
              _clausuraCasillas[key] = clausura[key] ? "si" : "no";
            }
          }

          _clausuraCasillas.idCasillaClausura = clausura.idCasillaClausura;

          setDia(fechaClausura.split("-")[1] === "06" ? "6" : "5");
          setTiempo(fechaClausura.split(" ")[1]);
          setClausuraCasilla(_clausuraCasillas);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setCargando(false);
      }
    };

    obtenerCasillaClausura();

    return () => (mounted = false);
  }, []);

  if (cargando) return <FullScreenLoader />;

  const guardar = async () => {
    try {
      if (guardando) return;

      if (!dia || !tiempo) {
        showAlert({
          severity: "warning",
          message: "Favor de revisar la fecha hora de recepción.",
        });

        return;
      }

      const _clausuraCasilla = { ...clausuraCasilla };

      for (const key in _clausuraCasilla) {
        if (_clausuraCasilla[key] === null && !ignoreKeys.includes(key)) {
          showAlert({
            severity: "warning",
            message: "Favor de revisar la clausura.",
          });

          return;
        }

        if (!ignoreKeys.includes(key)) {
          _clausuraCasilla[key] = _clausuraCasilla[key] === "si" ? true : false;
        }
      }

      setGuardando(true);

      _clausuraCasilla.fechaClausura = "2021-06-" + ("0" + dia.toString()) + " " + tiempo;

      await Services.post("casilla-clausura/guardar", _clausuraCasilla);

      history.push("/clausura-casillas");
    } catch (error) {
      console.log(error);
    } finally {
      setGuardando(false);
    }
  };

  return (
    <PageLayout
      title="CLAUSURA CASILLA"
      links={[
        {
          to: "/clausura-casillas",
          icon: <ArchiveIcon />,
          label: "Casillas",
        },
        {
          to: `/clausura-casillas/casilla/${match.params.idCasilla}`,
          icon: <NoMeetingRoomIcon />,
          label: "Clausura Casillas",
        },
      ]}
      history={history}
    >
      <Typography>
        <b>Fecha y hora</b>
      </Typography>
      <br />
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <SingleSelect
              label="Día"
              value={dia}
              onChange={(e) => setDia(e.target.value)}
              options={["6", "7"]}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography style={{ verticalTextAlign: "center", fontSize: 20 }}>
              de Junio del 2021
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TimePicker
              label="Hora de recepción"
              value={tiempo}
              onChange={(e) => {
                const tmp = e.split(":");
                const hora = parseInt(tmp[0]);
                setTiempo(e);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Typography>
        <b>Casilla:</b>
      </Typography>
      <Card style={{ padding: 20 }}>
        <RowClausuraCasilla
          text={"Presencia del Presidente"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pPresidente: value,
            });
          }}
          value={clausuraCasilla.pPresidente}
        />
        <RowClausuraCasilla
          text={"Presencia del Secretario"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pSecretario: value,
            });
          }}
          value={clausuraCasilla.pSecretario}
        />
        <RowClausuraCasilla
          text={"Presencia del Primer Escrutador"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pPrimerEscrutador: value,
            });
          }}
          value={clausuraCasilla.pPrimerEscrutador}
        />
        <RowClausuraCasilla
          text={"Presencia del Segundo Escrutador"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pSegundoEscrutador: value,
            });
          }}
          value={clausuraCasilla.pSegundoEscrutador}
        />
        <RowClausuraCasilla
          text={"Generación de la Constancia de Clausura de la Casilla"}
          onChange={(value) => 
            setClausuraCasilla({
              ...clausuraCasilla,
              generacionConstancia: value,
            })
          }
          value={clausuraCasilla.generacionConstancia}
        />
        <TextField 
          style={{marginTop: 10}}
          multiline
          fullWidth
          variant="outlined"
          size="small"
          onChange={(event) => setClausuraCasilla({
            ...clausuraCasilla,
            motivo: event.target.value,
          })}
          value={clausuraCasilla.motivo}
          label="Motivo de Cambio de la Casilla"
        />
      </Card>
      <br />
      <Typography>
        <b>Partidos:</b>{" "}
      </Typography>
      <Card style={{ padding: 20 }}>
        <RowClausuraCasilla
          text={"NA - PARTIDO NUEVA ALIANZA"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              na: value,
            });
          }}
          value={clausuraCasilla.na}
        />
        <RowClausuraCasilla
          text={"MORENA"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              morena: value,
            });
          }}
          value={clausuraCasilla.morena}
        />
        <RowClausuraCasilla
          text={"PAN - PARTICO ACCIÓN NACIONAL"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pan: value,
            });
          }}
          value={clausuraCasilla.pan}
        />
        <RowClausuraCasilla
          text={"MC - MOVIMIENTO CIUDADANO"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              mc: value,
            });
          }}
          value={clausuraCasilla.mc}
        />
        <RowClausuraCasilla
          text={"PES - PARTIDO ENCUENTRO SOLIDARIO"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pes: value,
            });
          }}
          value={clausuraCasilla.pes}
        />
        <RowClausuraCasilla
          text={"PRI - PARTIDO REVOLUCIONARIO INSTITUCIONAL"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pri: value,
            });
          }}
          value={clausuraCasilla.pri}
        />
        <RowClausuraCasilla
          text={"PT - PARTIDO DEL TRABAJO"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pt: value,
            });
          }}
          value={clausuraCasilla.pt}
        />
        <RowClausuraCasilla
          text={"FS - FUERZA POR MÉXICO"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              fs: value,
            });
          }}
          value={clausuraCasilla.fs}
        />
        <RowClausuraCasilla
          text={"RSP - REDES SOCIALES PROGRESISTAS"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              rsp: value,
            });
          }}
          value={clausuraCasilla.rsp}
        />
        <RowClausuraCasilla
          text={"PVEM - PARTIDO VERDE ECOLOGISTA DE MÉXICO"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              pvem: value,
            });
          }}
          value={clausuraCasilla.pvem}
        />
        <RowClausuraCasilla
          text={"PRD - PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA"}
          onChange={(value) => {
            setClausuraCasilla({
              ...clausuraCasilla,
              prd: value,
            });
          }}
          value={clausuraCasilla.prd}
        />
      </Card>
      <br />
      <Button
        color="primary"
        variant="contained"
        style={{ float: "right" }}
        onClick={guardar}
      >
        {guardando ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "GUARDAR"
        )}
      </Button>
    </PageLayout>
  );
};

export default ClausuraCasillas;
