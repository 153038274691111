import React from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";

const SingleSelect = ({
  label = "",
  value,
  onChange,
  editing = false,
  error = false,
  helperText = "",
  options = [],
  defaultValue,
  ...rest
}) => {
  return (
    <FormControl fullWidth error={error} variant="outlined" size="small">
      <InputLabel id="plantilla-label">{label}</InputLabel>
      <Select
        labelId="plantilla-label"
        id="plantilla"
        defaultValue={defaultValue && defaultValue}
        value={value}
        renderValue={(val) => val.nombre || val}
        onChange={onChange}
        label={label}
        {...rest}
      >
        {defaultValue && (
          <MenuItem value={defaultValue}>
            {defaultValue.nombre || defaultValue}
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option.nombre || option}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SingleSelect;
