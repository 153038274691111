import React from "react";

import { Typography, Paper, TextField, Button, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { FindInPage, HowToReg, HowToVote, People } from "@material-ui/icons";

import AlertDialog from "../../components/AlertDialog";
import Loader from "../../components/Loader";
import PageLayout from "../../components/PageLayout";

import Services from "../../services";
import { useModel } from "../../hooks/useModel";
import { useQuery } from "../../hooks/useQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  saveButtonContainer: { marginTop: theme.spacing(4) },
  title: {
    color: "#000",
    fontSize: 20,
    paddingBottom: "1%",
  },
  topic: { fontSize: 22, color: "#000" },
  card: {
    height: 250,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  icon: { height: "60%", width: "60%", color: theme.palette.primary.main },
  formControl: {
    width: "100%",
    paddingBottom: "2%",
  },
  tableHelperText: { color: theme.palette.error.dark },
  subtitle: { paddingBottom: "2%", color: "#000" },
  tableButton: { backgroundColor: theme.palette.error.dark, borderRadius: 50 },
  buttonIcon: { color: "#fff", fontWeight: "bold" },
  fieldText: { fontSize: 42 },
  badge2: {
    backgroundColor: "#f40090",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
  badge3: {
    backgroundColor: "#fb971f",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
  badge4: {
    backgroundColor: "#702c8f",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
  badge4gray: {
    backgroundColor: "#a3a3a3",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
  badge7: {
    backgroundColor: "#d1acfc",
    color: "white",
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 20,
    marginRight: 25,
  },
}));

const breadcrumbs = [
  {
    to: `/lectura-de-votos/datos-generales`,
    icon: <FindInPage fontSize="small" />,
    label: `Captura de datos`,
  },
];

const DatosGeneralesDetail = ({ history }) => {
  const classes = useStyles();
  const query = useQuery();

  const { model, modelLoading } = useModel({
    name: "acta",
    id: query.get("a"),
    expand: "tipoEleccion,esEspecial,datosGenerales",
    redirectOnPost: true,
  });
  const [validating, setValidating] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [remainingBallots, setRemainingBallots] = React.useState("");
  const [voters, setVoters] = React.useState("");
  const [nominalExcludedRepresentatives, setNominalExcludedRepresentatives] =
    React.useState("");
  const [cityCounselVotes, setCityCounselVotes] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [preFill, setPreFill] = React.useState(false);

  React.useEffect(() => {
    if (model && !modelLoading) {
      if (model.datosGenerales) {
        const {
          numeroBoletasSobrantes,
          numeroPersonasVotaron,
          repPpCanIndVotCasNoLn,
          numeroTotalVotosUrna,
        } = model.datosGenerales;
        setPreFill(true);
        setRemainingBallots(numeroBoletasSobrantes);
        setVoters(numeroPersonasVotaron);
        setNominalExcludedRepresentatives(repPpCanIndVotCasNoLn);
        setCityCounselVotes(numeroTotalVotosUrna);
      }
    }
  }, [model, modelLoading]);

  const submitDetail = () => {
    const obj = {
      idActa: model.idActa,
      numeroBoletasSobrantes: remainingBallots,
      numeroPersonasVotaron: voters,
      repPpCanIndVotCasNoLn:
        nominalExcludedRepresentatives === ""
          ? 0
          : nominalExcludedRepresentatives,
      numeroTotalVotosUrna: cityCounselVotes,
    };
    setOpen(false);
    setButtonLoading(true);
    Services.post("acta-registro-detalle/guardar", obj)
      .then((res) => {
        if (res) {
          history.push("/lectura-de-votos/datos-generales/nueva");
        }
      })
      .finally(() => setButtonLoading(false));
  };

  const onSubmitPressed = () => {
    setValidating(true);
    if (
      remainingBallots === "" ||
      voters === "" ||
      (nominalExcludedRepresentatives === "" && !model?.esEspecial) ||
      cityCounselVotes === ""
    ) {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    } else {
      setOpen(true);
    }
  };

  const NON_DIGIT = "/[^d]/g";
  if (modelLoading || !model) return <Loader />;

  return (
    <PageLayout
      title="CAPTURA DE DATOS GENERALES DE ACTA"
      links={[
        ...breadcrumbs,
        {
          to: `/lectura-de-votos/datos-generales/detalle?id=${
            model ? model.idActa : null
          }`,
          icon: <FindInPage fontSize="small" />,
          label: `${model ? model.nombreCompletoActa : ""}`,
        },
      ]}
      history={history}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Typography className={classes.title}>
            <b>CASILLA:</b> {model ? model.nombreCompletoActa : ""}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4}>
              <HowToVote className={classes.icon} />
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.subtitle}>
                <Badge badgeContent={2} classes={{ badge: classes.badge2 }} />
                Boletas sobrantes de la elección para{" "}
                <strong>
                  {model && model.tipoEleccion ? model.tipoEleccion.nombre : ""}
                </strong>
              </Typography>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <TextField
                  id="filled-basic"
                  error={validating && remainingBallots === ""}
                  fullWidth
                  label="Boletas"
                  helperText={
                    validating &&
                    remainingBallots === "" &&
                    "Favor de ingresar un número entero"
                  }
                  disabled={preFill}
                  variant="standard"
                  required
                  value={remainingBallots}
                  InputProps={{ className: classes.fieldText }}
                  onChange={(e) => {
                    const { value } = e.target;
                    const intValue = parseInt(
                      value.toString().replace(NON_DIGIT, "")
                    );
                    let pEntry = isNaN(intValue) ? "" : intValue;
                    setRemainingBallots((v) => {
                      if (isNaN(intValue)) return "";
                      else if (model.esEspecial && value.length <= 4)
                        return pEntry;
                      else if (!model.esEspecial && value.length <= 3)
                        return pEntry;
                      else return v;
                    });
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4}>
              <People className={classes.icon} />
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.subtitle}>
                <Badge badgeContent={3} classes={{ badge: classes.badge3 }} />
                Personas que votaron de la elección para{" "}
                <strong>{model?.tipoEleccion?.nombre}</strong>
              </Typography>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <TextField
                  id="filled-basic"
                  error={validating && voters === ""}
                  fullWidth
                  label="Personas"
                  helperText={
                    validating &&
                    voters === "" &&
                    "Favor de ingresar un número entero"
                  }
                  variant="standard"
                  required
                  value={voters}
                  disabled={preFill}
                  inputProps={{
                    min: 0,
                  }}
                  InputProps={{ className: classes.fieldText }}
                  onChange={(e) => {
                    const { value } = e.target;
                    const intValue = parseInt(
                      value.toString().replace(NON_DIGIT, "")
                    );
                    let pEntry = isNaN(intValue) ? "" : intValue;
                    setVoters((v) => {
                      if (isNaN(intValue)) return "";
                      else if (model.esEspecial && value.length <= 4)
                        return pEntry;
                      else if (!model.esEspecial && value.length <= 3)
                        return pEntry;
                      else return v;
                    });
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4}>
              <HowToReg className={classes.icon} />
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.subtitle}
                style={{ color: model?.esEspecial ? "gray" : "" }}
              >
                <Badge
                  badgeContent={4}
                  classes={{
                    badge: model?.esEspecial
                      ? classes.badge4gray
                      : classes.badge4,
                  }}
                />
                Representantes de partidos políticos y candidaturas
                independientes que votaron en la casilla
              </Typography>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <TextField
                  id="filled-basic"
                  error={
                    !model?.esEspecial &&
                    validating &&
                    nominalExcludedRepresentatives === ""
                  }
                  fullWidth
                  label="Representantes"
                  disabled={model?.esEspecial || preFill}
                  helperText={
                    validating &&
                    nominalExcludedRepresentatives === "" &&
                    "Favor de ingresar un número entero"
                  }
                  variant="standard"
                  required
                  value={nominalExcludedRepresentatives}
                  inputProps={{
                    min: 0,
                  }}
                  InputProps={{ className: classes.fieldText }}
                  onChange={(e) => {
                    const { value } = e.target;
                    const intValue = parseInt(
                      value.toString().replace(NON_DIGIT, "")
                    );
                    let pEntry = isNaN(intValue) ? "" : intValue;
                    setNominalExcludedRepresentatives((v) => {
                      if (isNaN(intValue)) return "";
                      else if (model.esEspecial && value.length <= 4)
                        return pEntry;
                      else if (!model.esEspecial && value.length <= 3)
                        return pEntry;
                      else return v;
                    });
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4}>
              <HowToVote className={classes.icon} />
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.subtitle}>
                <Badge
                  badgeContent={model?.esEspecial ? 5 : 7}
                  classes={{ badge: classes.badge7 }}
                />
                Votos de la elección{" "}
                <strong>
                  {model && model.tipoEleccion ? model.tipoEleccion.nombre : ""}
                </strong>{" "}
                sacados de la urna
              </Typography>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <TextField
                  id="filled-basic"
                  error={validating && cityCounselVotes === ""}
                  fullWidth
                  label="Votos"
                  helperText={
                    validating &&
                    cityCounselVotes === "" &&
                    "Favor de ingresar un número entero"
                  }
                  disabled={preFill}
                  variant="standard"
                  required
                  value={cityCounselVotes}
                  inputProps={{
                    min: 0,
                  }}
                  InputProps={{ className: classes.fieldText }}
                  onChange={(e) => {
                    const { value } = e.target;
                    const intValue = parseInt(
                      value.toString().replace(NON_DIGIT, "")
                    );
                    let pEntry = isNaN(intValue) ? "" : intValue;
                    setCityCounselVotes((v) => {
                      if (isNaN(intValue)) return "";
                      else if (model.esEspecial && value.length <= 4)
                        return pEntry;
                      else if (!model.esEspecial && value.length <= 3)
                        return pEntry;
                      else return v;
                    });
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={
              preFill ||
              buttonLoading ||
              remainingBallots === "" ||
              voters === "" ||
              (nominalExcludedRepresentatives === "" && !model?.esEspecial) ||
              cityCounselVotes === ""
            }
            color="primary"
            variant="contained"
            style={{ float: "right", marginLeft: "1%" }}
            onClick={onSubmitPressed}
          >
            {buttonLoading ? "CARGANDO" : "GUARDAR"}
          </Button>
        </Grid>
        <AlertDialog
          open={open}
          onClose={() => setOpen(false)}
          title={`CONFIRMACIÓN DE CAPTURA DE DATOS GENERALES DE ACTA ${
            model ? model.nombreCompletoActa : ""
          }`}
          content="¿Está seguro que desea guardar la información capturada?"
          onAccept={submitDetail}
        />
      </Grid>
    </PageLayout>
  );
};

export default DatosGeneralesDetail;
