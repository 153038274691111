import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import { Add, Close, TableChart } from "@material-ui/icons";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  List,
  ListItem,
  Collapse,
  ListItemText,
  CircularProgress,
  Card,
  Box,
  IconButton,
  TextField,
} from "@material-ui/core";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useAlert } from "../../../hooks/useAlert";
import { useAuth } from "../../../hooks/useAuth";
import { useModels } from "../../../hooks/useModels";
import TablePageLayout from "../../../components/TablePageLayout";
import Services from "../../../services";
import FullScrennLoader from "../../../components/FullScreenLoader";
import CreatableAutocomplete from "../../../components/CreatableAutocomplete";
import AlertDialog from "../../../components/AlertDialog";
import DatePicker from "../../../components/DatePicker";
import AsyncCreatableAutocomplete from "../../../components/AsyncCreatableAutocomplete";
import DialogReprecentante from "./dialogReprecentante";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TablePagination from "../../../components/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";

import { add } from "date-fns/esm";

const requestActa = {
  name: "acta",
  extraParams: {
    estatusMotivoRecuento: "con-motivo-recuento",
  },
  expand: "motivoDeRecuento,actaRegistroVoto.estatusActa",
};

const funcionariosRequest = {
  name: "agenda",
  fields:
    "idAgenda, nombreCompleto, puesto, nombre, apellidoPaterno, apellidoMaterno, usuario",
  extraParams: {
    limit: -1,
  },
  expand: "usuario",
};

const idsPuestoPR = [3];

const PuntosRecuento = () => {
  const history = useHistory();
  const { user, userLoading } = useAuth();
  const [idConsejoElectoral, setIdConsejoElectoral] = useState(null);
  const [idGrupoTrabajoIntegrante, setIdGrupoTrabajoIntegrante] =
    useState(null);
  const [nombreIntegrante, setNombreIntegrante] = useState("");
  const [open2, setOpen2] = useState(false);

  const gtprParamsMemo = React.useMemo(
    () => ({
      idGrupoTrabajoIntegrante: idGrupoTrabajoIntegrante,
      ordenar: "idActa.asc",
    }),
    [idGrupoTrabajoIntegrante]
  );

  const [
    modelsGTPR,
    modelsLoadingGTPR,
    modelsErrorGTPR,
    modelsPageGTPR,
    refreshModelsGTPR,
    deleteModelGTPR,
  ] = useModels({
    name: "grupo-trabajo-paquete-recuento",
    expand: "acta",
    ordenar: "acta.nombreCompletoActa.asc",
    extraParams: gtprParamsMemo,
  });

  const gtParamsMemo = React.useMemo(
    () => ({
      idGrupoTrabajoPuesto: 3,
      idConsejoElectoral: idConsejoElectoral,
      ordenar: "numeroGrupoTrabajo.asc",
    }),
    [idConsejoElectoral]
  );
  const [models, modelsLoading, modelsError, modelsPage, refreshModels] =
    useModels({
      name: "grupo-trabajo",
      extraParams: gtParamsMemo,
      expand: "integrantes,representantes.agenda",
    });

  const [modelsActa, modelsActaLoading] = useModels(requestActa);

  const header = [];
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(true);
  const [puestos, setPuestos] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [funcionarios, funcionariosLoading] = useModels(funcionariosRequest);
  const [integrantes, setIntegrantes] = useState([]);
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [representante, setRepresentante] = useState(null);
  const [indexRepresentante, setIndexRepresentante] = useState(0);
  const [participantesPolitico, setParticipantesPolitico] = useState([]);
  const [nuevoPR, setNuevoPR] = useState(false);
  const [puntosRecuento, setPuntosRecuento] = useState([]);
  const [idGT, setIdGT] = useState(null);
  const [checked, setChecked] = useState(false);
  const [recuentos, setRecuentos] = useState([]);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen2(false);
  };

  const changePageGTPR = (page) => {
    let params = {
      name: "grupo-trabajo-paquete-recuento",
      expand: "acta",
      ordenar: "idActa.asc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      idIntegrantePR: idGrupoTrabajoIntegrante,
    };
    refreshModelsGTPR(true, params);
  };

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      try {
        const response = await Services.get("grupo-trabajo-puesto");
        const _participantesPolitico = await Services.get(
          "participante-politico"
        );

        if (mounted) {
          setParticipantesPolitico(_participantesPolitico.resultado);
          setPuestos(response.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    getData();

    return () => (mounted = false);
  }, []);

  const paquetes = async () => {
    const response3 = await Services.post("grupo-trabajo/paquetes-por-consejo");
    setTotal(response3);
  };

  useEffect(() => {
    setIdConsejoElectoral(user?.consejoElectoral.idConsejoElectoral);
    paquetes();
    if (models.length) {
      let _integrantes = [];
      let _representantesConSalida = [];
      models.forEach((g) => {
        _integrantes = [
          ..._integrantes,
          ...g?.integrantes.map((i) => i.idAgenda).filter((i) => i),
        ];
        _representantesConSalida = [
          ..._representantesConSalida,
          ...g?.representantes.filter((r) => r.fechaSalida),
        ];
      });

      setIntegrantes(_integrantes);
      setGrupos(
        models.map((m) => ({
          ...m,
          expanded: false,
          integrantes: m.integrantes.sort((a, b) =>
            a.idGrupoTrabajoPuesto > b.idGrupoTrabajoPuesto ? 1 : -1
          ),
          representantes: m.representantes
            .filter((r) => !r.fechaSalida)
            .map((r) => ({
              ...r,
              participantePolitico: r.agenda.participantePolitico,
              idParticipantePolitico: r.agenda.idParticipantePolitico,
              idGrupoTrabajoNuevo: "",
            })),
          representantesConSalida: _representantesConSalida,
        }))
      );
    }
  }, [models]);

  if (loading || userLoading || modelsActaLoading) return <FullScrennLoader />;

  const guardar = async (idGrupoTrabajo) => {
    try {
      const grupo = grupos.find((g) => g.idGrupoTrabajo === idGrupoTrabajo);

      if (grupo.representantes.some((r) => !r.puesto)) {
        showAlert({
          severity: "warning",
          message: "Favor de verificar los puestos de los Representantes.",
        });

        return;
      }

      setSaving(true);

      const data = {
        misOtrosGrupos: grupos
          .filter((g) => g.idGrupoTrabajo !== idGrupoTrabajo)
          .map((g) => g.idGrupoTrabajo),
        integrantes: grupos.find((g) => g.idGrupoTrabajo === idGrupoTrabajo)
          .integrantes,
        idGrupoTrabajo: idGrupoTrabajo,
      };

      const dataR = {
        idGrupoTrabajo: idGrupoTrabajo,
        representantes: grupo.representantes,
        representantesSarcar: grupo.representantesConSalida,
      };

      const dataPR = {
        puntosRecuento: puntosRecuento,
      };

      await Services.post("grupo-trabajo-representante/guardar", dataR);
      await Services.post("grupo-trabajo-integrante/guardar", data);
      await Services.post("grupo-trabajo-integrante/guardar", dataPR);

      showAlert({
        severity: "success",
        message: "Conformación guardada con exito.",
      });

      await refresh();
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const refresh = async () => {
    const params = {
      name: "grupo-trabajo",
      extraParams: {
        ordenar: "numeroGrupoTrabajo.asc",
      },
      expand: "integrantes,representantes.agenda",
      isCargando: false,
    };

    await refreshModels(true, params);
  };

  const handleChangeChecked = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <TablePageLayout
      title="GRUPOS DE TRABAJO"
      links={[
        {
          to: "/grupos-trabajo",
          icon: <GroupWorkIcon />,
          label: "Grupos-Trabajo",
        },
        {
          to: "/grupos-trabajo/grupos",
          icon: <ListIcon />,
          label: "Lista de Grupos",
        },
        {
          to: "/grupos-trabajo/recuentos-pr",
          icon: <ListIcon />,
          label: "Lista de Recuentos",
        },
      ]}
      history={history}
      SearchComponents={<></>}
      tableLoading={modelsLoading}
      tableErrors={modelsError}
      TableComponent={
        <Card>
          <Grid container style={{ padding: 20 }}>
            <Typography variant="h5" style={{ color: "black" }}>
              Lista de Recuentos Por Grupos
            </Typography>

            <List style={{ width: "100%" }}>
              {grupos &&
                grupos.map((grupo, indexG) => (
                  <div key={indexG}>
                    <ListItem
                      style={{ backgroundColor: "#e0e0e0" }}
                      button
                      onClick={() =>
                        setGrupos(
                          grupos.map((g, i) => ({
                            ...g,
                            expanded: i === indexG ? !g.expanded : g.expanded,
                          }))
                        )
                      }
                    >
                      {total &&
                      total.resultado[0] >= 1 &&
                      total &&
                      total.resultado[0] <= 24 ? (
                        <ListItemText
                          style={{ color: "black" }}
                          primary={"Pleno"}
                        />
                      ) : (
                        <ListItemText
                          style={{ color: "black" }}
                          primary={
                            "Grupo de Trabajo " + grupo.numeroGrupoTrabajo
                          }
                        />
                      )}

                      {grupo.expanded ? (
                        <ExpandLess style={{ color: "black" }} />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItem>
                    <Collapse in={grupo.expanded} timeout="auto" unmountOnExit>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Puesto</TableCell>
                              <TableCell>Persona</TableCell>
                              <TableCell>Paquetes</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {grupo.integrantes.map((row, indexI) => (
                              <TableRow key={indexI}>
                                {row.idGrupoTrabajoPuesto === 3 ? (
                                  <>
                                    <TableCell
                                      component="th"
                                      style={{ width: "30%" }}
                                    >
                                      {row.nombrePuesto}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      style={{ width: "70%" }}
                                    >
                                      {
                                        <Typography>
                                          {row.nombre} {row.apellidoPaterno}{" "}
                                          {row.apellidoMaterno}{" "}
                                        </Typography>
                                      }
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      style={{ width: "30%" }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                          setIdGrupoTrabajoIntegrante(
                                            row.idGrupoTrabajoIntegrante
                                          );
                                          setNombreIntegrante(
                                            `${row.nombre} ${row.apellidoPaterno} ${row.apellidoMaterno}`
                                          );
                                          setOpen2(true);
                                        }}
                                      >
                                        Listado
                                      </Button>
                                    </TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <hr />
                    </Collapse>
                    <hr style={{ margin: 0, padding: 0 }} />
                  </div>
                ))}
            </List>
          </Grid>
          <Dialog
            fullWidth={true}
            maxWidth={true}
            open={open2}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              PAQUETES A RECONTAR <br /> {nombreIntegrante}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {idGrupoTrabajoIntegrante && (
                  <TablePagination
                    header={header}
                    data={modelsGTPR && modelsGTPR}
                    paginatedApi
                    changePageApi={changePageGTPR}
                    count={modelsGTPR ? modelsGTPR.total : 0}
                    extraRows={[
                      {
                        prop: "nombreActa",
                        name: "Seccion Casilla",
                        cell: (row) => (
                          <>{row.acta ? row.acta.nombreCompletoActa : ""}</>
                        ),
                      },
                      {
                        prop: "estatus",
                        name: "Estatus",
                        cell: (row) => (
                          <>{row.recuento ? "RECONTADA" : "SIN RECONTAR"}</>
                        ),
                      },
                      {
                        prop: "",
                        name: "Recuento",
                        cell: (row, i) => (
                          <>
                            <div>
                              <Checkbox
                                checked={checked}
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </div>
                          </>
                        ),
                      },
                      {
                        prop: "acciones",
                        name: "Acciones",
                        cell: (row, index) => (
                          <div>
                            <Button variant="outlined" color="primary">
                              Asignar
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                  />
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      }
    />
  );
};

export default PuntosRecuento;
