import React from "react";
import { Ballot } from "@material-ui/icons";
import { IconButton, Button, Grid, Menu, MenuItem, Paper, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import { generateDefaultChartOptions, getPercentage } from "../../utils";
import { useModels } from "../../hooks/useModels";
import { ro } from "date-fns/locale";

const actasRequestDefault = {
  name: "grupo-trabajo/estadistico",
  // fields: "idActa,nombreCompletoActa",
  extraParams: {
    // estatusLectura: "con-lectura",
    // expand: "fechaLectura",
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    width: "100%",
  },

  roo2: {
    width: "100%",
    maxWidth: 1000,
  },
}));

const ReporteGtp = ({ history }) => {
  const header = [];
  const classes = useStyles();
  const [nombre, setNombre] = React.useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState(null);
  const [actasRequest, setActasRequest] = React.useState(actasRequestDefault);
  const [actas, actasLoading] = useModels(actasRequest);
  const [models, modelsLoading, modelsError] = useModels({
    name: "lectura-voto/resultado",
  });
  let chartOptions = generateDefaultChartOptions();

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    (e, v) => {
      if (!v) return;
      const { idConsejoElectoral } = v;
      if (idConsejoElectoral && idConsejoElectoral !== "") {
        const req = { ...actasRequestDefault };
        req.extraParams = { ...req.extraParams, idConsejoElectoral };
        setActasRequest(req);
        setIdConsejoElectoral(idConsejoElectoral);
      }
    },
    [setActasRequest]
  );

  const onSearchClicked = async () => {
    if (idConsejoElectoral && idConsejoElectoral !== "") {
      const req = { ...actasRequestDefault };
      req.extraParams = { ...req.extraParams, idConsejoElectoral};
      setActasRequest(req);
    }
  };

  const totales = models.length > 0 ? models[0] : {};

//   const sinLecturaInt =
//     actas && actas[0]
//       ? parseInt(actas[0]?.actasTotal.toString().replace(",", "")) -
//         parseInt(actas[0]?.actasLectura.toString().replace(",", ""))
//       : 0;

//   const leidasInt =
//     actas && actas[0]
//       ? parseInt(actas[0]?.actasLectura.toString().replace(",", ""))
//       : 0;

  let extraOptions = {
    colors: ["#9E9E9E", "#ad2624"],
    title: {
      text: "CÓMPUTO",
    },
    subtitle: {
      text: "TOTALIZADOR DEL CÓMPUTO",
    },
    series: [
      {
        name: "Paquetes",
        data: [
          ["Cotejo", actas[0]?.cotejo],
          ["Recuento", actas[0]?.recuentoPleno > 0  ? (actas[0]?.recuento + actas[0]?.recuentoPleno) : actas[0]?.recuento],
          // ["Sin Recibo Paquete", totales.sinrpe],
        ],
      },
    ],
  };
  chartOptions = { ...chartOptions, ...extraOptions };

  return (
    <TablePageLayout
      title="ESTADÍSTICOS: CÓMPUTO POR CONSEJO ELECTORAL"
      model="consejos-electorales"
      history={history}
      links={[
        {
          to: "/reporte-gtp",
          icon: <Ballot />,
          label: "Estadísticos: Cómputo",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          {/* <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={actasLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid> */}
        </>
      }
      tableLoading={actasLoading}
      // tableErrors={consejosElectoralesError}

      TableComponent={
        <>
          {
            actas.length > 0 ? (
              <>
              {
                actas[0].tipo === 'Grupos' && (
                  <Grid
                    component={Paper}
                    container
                    spacing={3}
                    className={classes.paper}
                    style={{ marginTop: 0 }}
                  >
                    <Grid item xs={6} style={{ marginTop: 0 }}>
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "grupos",
                            name: "Grupos - PR",
                            cell: (row) => <>{row.grupos + " GT " + " - " + row.pr + " PR"}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "cotejo",
                            name: "Cotejados/En Cotejo (Avance %)",
                            cell: (row) => <>{row.cotejados + "/" + row.cotejo + " (" + (row.porcentajeCotejados > 0 ? row.porcentajeCotejados.toFixed(2) : 0) + " %)"}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "recuento",
                            name: "Recontados/En Recuento (Avance %) en GT",
                            cell: (row) => <>{row.recontados + "/" + row.recuento + " (" + (row.porcentajeRecontados > 0 ? row.porcentajeRecontados.toFixed(2) : 0) + " %)"}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "recontadosPleno",
                            name: "Recontados/En Recuento (Avance %) en Pleno",
                            cell: (row) => <>{row.recontadosPleno + "/" + row.recuentoPleno + " (" + (row.porcentajeRecontadosPleno > 0 ? row.porcentajeRecontadosPleno.toFixed(2) : 0) + " %)"}</>,
                          },
                        ]}
                      />
                    </Grid>
          
                    
                      <Grid item xs={6}>
                        {idConsejoElectoral !== "" && (
                          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        )}
                      </Grid>
                  </Grid>
                )
              }
              {
                actas[0].tipo === 'Sin Grupos' && (
                  <Grid
                    component={Paper}
                    container
                    spacing={3}
                    className={classes.paper}
                    style={{ marginTop: 0 }}
                  >
                    <Grid item xs={6} style={{ marginTop: 0 }}>
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "grupos",
                            name: "Grupos - PR",
                            cell: (row) => <>{"Con Recuento, Por Asignar Grupos de Trabajo."}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "cotejo",
                            name: "Cotejados/En Cotejo (Avance %)",
                            cell: (row) => <>{row.cotejados + "/" + row.cotejo + " (" + (row.porcentajeCotejados > 0 ? row.porcentajeCotejados.toFixed(2) : 0) + " %)"}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "recuento",
                            name: "Recontados/En Recuento (Avance %) en GT",
                            cell: (row) => <>{row.recontados + "/" + row.recuento + " (" + (row.porcentajeRecontados > 0 ? row.porcentajeRecontados.toFixed(2) : 0) + " %)"}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "recontadosPleno",
                            name: "Recontados/Recuento (Avance %) en Pleno",
                            cell: (row) => <>{row.recontadosPleno + "/" + row.recuentoPleno + " (" + (row.porcentajeRecontadosPlens > 0 ? row.porcentajeRecontadosPleno.toFixed(2) : 0) + " %)"}</>,
                          },
                        ]}
                      />
                    </Grid>
          
                    
                      <Grid item xs={6}>
                        {idConsejoElectoral !== "" && (
                          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        )}
                      </Grid>
                  </Grid>
                )
              }
              {
                actas[0].tipo === 'Pleno' && (
                  <Grid
                    component={Paper}
                    container
                    spacing={3}
                    className={classes.paper}
                    style={{ marginTop: 0 }}
                  >
                    <Grid item xs={6} style={{ marginTop: 0 }}>
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "grupos",
                            name: "Grupos - PR",
                            cell: (row) => <>{"Sin Grupos de Trabajo, el Cómputo es en Pleno."}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "cotejo",
                            name: "Cotejados/ En Cotejo (Avance %)",
                            cell: (row) => <>{row.cotejados + "/" + row.cotejo + " (" + (row.porcentajeCotejados > 0 ? row.porcentajeCotejados.toFixed(2) : 0) + " %)"}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "recuento",
                            name: "Recontados/En Recuento (Avance %)",
                            cell: (row) => <>{row.recontados + "/" + row.recuento + " (" + (row.porcentajeRecontados > 0 ? row.porcentajeRecontados.toFixed(2) : 0) + " %)"}</>,
                          },
                        ]}
                      />
                    </Grid>                    
                      <Grid item xs={6}>
                        {idConsejoElectoral !== "" && (
                          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        )}
                      </Grid>
                  </Grid>
                )
              }
              {
                actas[0].tipo === 'Sin Recuento' && (
                  <Grid
                    component={Paper}
                    container
                    spacing={3}
                    className={classes.paper}
                    style={{ marginTop: 0 }}
                  >
                    <Grid item xs={6} style={{ marginTop: 0 }}>
                    <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "grupos",
                            name: "Grupos - PR",
                            cell: (row) => <>{"Sin Recuento."}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "cotejo",
                            name: "Cotejados/En Cotejo (Avance %)",
                            cell: (row) => <>{row.cotejo > 0 ? (row.cotejados + "/" + row.cotejo + " (" + (row.porcentajeCotejados > 0 ? row.porcentajeCotejados.toFixed(2) : 0) + " %)") : "0/0 (0 %)"}</>,
                          },
                        ]}
                      />
                      <TablePagination
                        header={header}
                        data={actas}
                        size="large"
                        paginatedApi
                        extraRows={[
                          {
                            prop: "recuento",
                            name: "Recontados/En Recuento (Avance %)",
                            cell: (row) => <>{"0/0 (0 %)"}</>,
                          },
                        ]}
                      />
                    </Grid>
          
                    
                      <Grid item xs={6}>
                        {idConsejoElectoral !== "" && (
                          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        )}
                      </Grid>
                  </Grid>
                )
              }
              </>
            ) : (
              <Grid item xs={12}>
                  <Typography align="center">
                      SIN RESULTADOS
                  </Typography>
              </Grid>
            )
          }
        </>
      }
    />
  );
};

export default ReporteGtp;