import React from "react";

import {
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
  DialogContent,
  TextField,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import Services from "../../services";
import { useAuth } from "../../hooks/useAuth";

const DialogFuncionario = ({ open, onClose }) => {
  const [errors, setErrors] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const [data, setData] = React.useState({
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    correo: "",
    telefonoCelular: "",
  });
  const { user } = useAuth();

  const handleAccept = async () => {
    try {
      setSaving(true);

      let _errors = [];

      if (!data.nombre) {
        _errors.push("nombre");
      }

      if (!data.apellidoPaterno) {
        _errors.push("apellidoPaterno");
      }

      if (!data.apellidoMaterno) {
        _errors.push("apellidoMaterno");
      }

      setErrors(_errors);

      if (_errors.length) return;

      const response = await Services.post("agenda/guardar", {
        ...data,
        idConsejoElectoral: user.idConsejoElectoral,
      });

      onClose(response.mensaje);
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog fullWidth open={open} maxWidth="sm">
      <DialogTitle> Agregar funcionario </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xl={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Nombre"
              type="text"
              size="small"
              value={data.nombre}
              onChange={(event) =>
                setData({ ...data, nombre: event.target.value })
              }
              helperText="Favor de escribir el Nombre."
              error={errors.includes("nombre")}
            />
          </Grid>
          <Grid item md={6} xl={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Apellido paterno"
              type="text"
              size="small"
              value={data.apellidoPaterno}
              onChange={(event) =>
                setData({ ...data, apellidoPaterno: event.target.value })
              }
              helperText="Favor de escribir el Apellido paterno."
              error={errors.includes("apellidoPaterno")}
            />
          </Grid>
          <Grid item md={6} xl={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Apellido materno"
              type="text"
              size="small"
              value={data.apellidoMaterno}
              onChange={(event) =>
                setData({ ...data, apellidoMaterno: event.target.value })
              }
              helperText="Favor de escribir el Apellido materno."
              error={errors.includes("apellidoMaterno")}
            />
          </Grid>
          <Grid item md={6} xl={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Correo electrónico"
              type="text"
              size="small"
              value={data.email}
              onChange={(event) =>
                setData({ ...data, correo: event.target.value })
              }
            />
          </Grid>
          <Grid item md={6} xl={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Teléfono"
              type="number"
              size="small"
              value={data.telefonoCelular}
              onChange={(event) => {
                if (event.target.value.length <= 10)
                  setData({ ...data, telefonoCelular: event.target.value });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose("");
            setErrors([]);
            setData({});
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={handleAccept} color="primary" disabled={saving}>
          {saving ? <CircularProgress /> : "Aceptar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFuncionario;
