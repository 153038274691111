import React, { useDebugValue } from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { useModels } from "../hooks/useModels";
import Loader from "./Loader";

const AsyncSelect = ({
  label,
  name,
  labelProp,
  value,
  onChange,
  extraParams,
  validating,
  validatingText = "Sin texto de validación.",
  disabled,
  defaultValue,
  ignoreIds = [],
  propId = "",
}) => {
  const [models, modelsLoading] = useModels({
    name,
    extraParams,
  });

  return (
    <FormControl
      fullWidth
      error={validating && useDebugValue === undefined}
      variant="outlined"
      size="small"
      disabled={modelsLoading || !models || models.length === 0 || disabled}
    >
      <InputLabel id="async-selector">{label}</InputLabel>
      <Select
        labelId="async-selector"
        defaultValue={defaultValue}
        renderValue={(val) => val[labelProp]}
        label={label}
        value={value ? value : undefined}
        onChange={onChange}
      >
        {modelsLoading ? (
          <Loader />
        ) : (
          models
            .filter((m) => !ignoreIds.includes(m[propId]))
            .map((model, index) => (
              <MenuItem key={index} value={model}>
                {model[labelProp] === "PENDIENTE DE CAPTURAR"
                  ? "ACTA CAPTURABLE"
                  : model[labelProp]}
              </MenuItem>
            ))
        )}
      </Select>
      {validating && value === undefined && (
        <FormHelperText>{validatingText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default AsyncSelect;
