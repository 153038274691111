import React from "react";
import {
  Typography,
  Paper,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Description, Menu, PictureAsPdf, Print } from "@material-ui/icons";
import PageLayout from "../../components/PageLayout";
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
    paddingBottom: "1%",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  saveButtonContainer: { marginTop: theme.spacing(4) },
  topic: { fontSize: 28 },
  card: {
    height: 250,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  icon: { fontSize: 54, color: "#fff" },
  formControl: {
    minWidth: "100%",
  },
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  tableText: {
    fontSize: 18,
    color: "#000",
  },
  subtitle: { fontWeight: "bold" },
  tableButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
  },
  buttonIcon: { color: "#fff", fontWeight: "bold" },
  tableIcon: { fontSize: 42 },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  body: {
    color: theme.palette.primary.main,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TablePackagesPagination() {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      component="div"
      count={100}
      page={page}
      onChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}

const AdministracionEnvioDistritalDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const [packages] = React.useState([
    {
      consejo: "CONSEJO MUNICIPAL DE AGUA PRIETA",
      fechaApertura: "2020-09-24 17:06:00",
      motivoApertura: "Recepción de Documentación Electoral",
      fechaCierre: "",
    },
    {
      consejo: "CONSEJO MUNICIPAL DE AGUA PRIETA",
      fechaApertura: "2020-09-24 17:06:00",
      motivoApertura: "Recepción de Documentación Electoral",
      fechaCierre: "2020-09-24 17:06:00",
    },
  ]);

  return (
    <>
      <div className={classes.root}>
      <PageLayout 
        title="LISTADO DE APERTURAS Y CIERRES DE BODEGA ELECTORAL" 
        history={history}
        links={[
          {
            to: "/bodegas/administracion",
            icon: <AssignmentIcon />,
            label: "Administracion",
          },
          {
            to: "/bodegas/administracion/envio-distrital",
            icon: <ListIcon />,
            label: "Envio distrital",
          }
        ]}
      >
        <Grid
          component={Paper}
          container
          spacing={3}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Consejo</StyledTableCell>
                    <StyledTableCell align="left">
                      Fecha Apertura
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Motivo Apertura
                    </StyledTableCell>
                    <StyledTableCell align="left">Fecha Cierre</StyledTableCell>
                    <StyledTableCell align="left">Opciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={3}
                      color="#fff"
                      style={{ flexDirection: "row" }}
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Buscar..."
                      />
                    </StyledTableCell>
                    <StyledTableCell colSpan={2} align="right">
                      <PictureAsPdf className={classes.tableIcon} />
                      <Print className={classes.tableIcon} />
                      <Description className={classes.tableIcon} />
                    </StyledTableCell>
                  </StyledTableRow>
                  {packages.map((e, i) => (
                    <StyledTableRow index={i} style={{ cursor: "pointer" }}>
                      <StyledTableCell align="left">
                        <Typography className={classes.tableText}>
                          {e.consejo}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className={classes.tableText}>
                          {e.fechaApertura}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className={classes.tableText}>
                          {e.motivoApertura}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className={classes.tableText}>
                          {e.fechaCierre}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Button className={classes.tableButton}>
                          <Menu className={classes.buttonIcon} />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableCell colSpan={6}>
                      <TablePackagesPagination />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </PageLayout>
      </div>
    </>
  );
};

export default AdministracionEnvioDistritalDetail;
