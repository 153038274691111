import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  TableContainer,
  Checkbox,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FindInPage, LeakAddTwoTone, VpnKey } from "@material-ui/icons";

import { actas, NON_DIGIT, tipoVotos } from "../../constants/mockData";
import Loader from "../../components/Loader";
import AsyncSelect from "../../components/AsyncSelect";
import TimePicker from "../../components/TimePicker";
import DatePicker from "../../components/DatePicker";
import PageLayout from "../../components/PageLayout";
import { baseUrlPdf, photoBaseUrl } from "../../utils/variables";
import services from "../../services";

import { useQuery } from "../../hooks/useQuery";
import { useModel } from "../../hooks/useModel";
import MiniCardEntry from "../../components/MiniCardEntry";
import AlertDialog from "../../components/AlertDialog";
import SingleSelect from "../../components/SingleSelect";
import { auth } from "../../firebase";
import TIPO_ELECCION from "../../utils/tipoEleccion";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    color: "#000",
    fontSize: 20,
  },
  text: {
    fontSize: 18,
    color: "#000",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2) * -1,
    color: "#000",
  },
  icon: { height: "80%", width: "80%", color: theme.palette.primary.main },
  subtitle: { paddingBottom: "2%", color: "#000" },
  successButton: {
    float: "right",
    top: 10,
    backgroundColor: "green",
    color: "white",
  },
  formControl: {
    minWidth: "100%",
  },
  fieldText: { fontSize: 42 },
  table: {
    minWidth: 700,
    tableLayout: "auto",
  },
  act: { fontSize: 30 },
  tableHelperText: { textAlign: "center", color: theme.palette.error.dark },
  panelEspecialText: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#76777b",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.75)", // (default alpha is 0.38)
    },
  },
})(TextField);

const gtExtraParams = { expand: "nombreCompleto" };
const diasFecha = [
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
];

const filtroProtesta = (p) =>
  p.idPartidoPolitico !== null || p.idCandidatoIndependiente !== null;

const opcionesHora = [...Array(24).keys()].map((e) => (e < 10 ? "0" + e : e));
const opcionesMinuto = [...Array(60).keys()].map((e) => (e < 10 ? "0" + e : e));

const RecuentoGtReservadoDetail = ({ match, history }) => {
  const id = auth.currentUser.uid;
  const classes = useStyles();
  const query = useQuery();
  const idActa = query.get("a");
  const [validando, setValidando] = React.useState(false);

  const [encontroBoletas, setEncontroBoletas] = React.useState(false);

  const [grupoTrabajo, setGrupoTrabajo] = React.useState("");
  const [grupoTrabajoId, setGrupoTrabajoId] = React.useState("");
  const [puntosRecuento, setPuntosRecuento] = React.useState([]);
  const [puntoDeRecuento, setPuntoDeRecuento] = React.useState("");
  // const [numeroPR, setNumeroPR] = React.useState("");
  const [boletasSobrantes, setBoletasSobrantes] = React.useState("");
  const [horaInicio, setHoraInicio] = React.useState("");
  const [minutoInicio, setMinutoInicio] = React.useState("");
  const [fechaInicio, setFechaInicio] = React.useState("");
  const [horaFinal, setHoraFinal] = React.useState("");
  const [minutoFinal, setMinutoFinal] = React.useState("");
  const [fechaFinal, setFechaFinal] = React.useState("");
  const [votosParticipantesPoliticos, setVotosParticipantesPoliticos] =
    React.useState([]);
  const [
    votosParticipantesPoliticosPrevios,
    setVotosParticipantesPoliticosPrevios,
  ] = React.useState([]);
  const [numProtesta, setNumProtesta] = React.useState([]);
  const [otrasBoletasG, setOtrasBoletasG] = React.useState("");
  const [otrasBoletasD, setOtrasBoletasD] = React.useState("");
  const [otrasBoletasM, setOtrasBoletasM] = React.useState("");

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [printOpen, setPrintOpen] = React.useState(false);
  const [postLoading, setPostLoading] = React.useState(false);
  const [numeroVotosReservados, setNumVotosReservados] = React.useState(0);
  const [participantesProtesta, setParticipantesProtesta] = React.useState([]);
  const [conVotos, setConVotos] = React.useState(false);

  const breadcrumbs = [
    {
      to: `/recuento-gt-reservado`,
      icon: <FindInPage fontSize="small" />,
      label: `Sesión de cómputo Actas Recuento Gt Reservado`,
    },
  ];

  const { model: acta, modelLoading: actaLoading } = useModel({
    name: "acta",
    id: query.get("a"),
    expand:
      "tipoEleccion,votosParticipantesRecuento,votosGeneralesRecuento,motivoDeRecuento,participantesPoliticos,numeroVotosReservados,recuentoGT,numerosProtesta,fechasRecuentoGt,puntosRecuento,detalleBoletas,participantesProtesta,votosReservados,actaRegistroVotoComputo",
    redirectOnPost: true,
  });

  React.useEffect(() => {
    if (!actaLoading && acta) {
      let puntoTmp = null;
      if (acta.recuentoGT) {
        const { nombreGrupo, idGrupoTrabajo, idIntegrantePR } = acta.recuentoGT;
        setGrupoTrabajo(nombreGrupo);
        setGrupoTrabajoId(idGrupoTrabajo);
        if (acta.puntosRecuento) {
          puntoTmp = acta.puntosRecuento.find(
            (e) => e.idGrupoTrabajoIntegrante === idIntegrantePR
          );
          setPuntoDeRecuento(puntoTmp);
        }
      }
      if (acta.puntosRecuento) {
        setPuntosRecuento([...acta.puntosRecuento]);
      }
      if (
        acta.participantesProtesta &&
        acta.participantesProtesta.length > 0 &&
        acta.participantesProtesta.length === acta.numerosProtesta.length
      ) {
        const mapped = [...acta.participantesProtesta].map((p, i) => ({
          ...p,
          resultado: acta.numerosProtesta[i].numeroProtestas,
        }));
        setParticipantesProtesta(mapped);
      }
      if (
        acta.participantesPoliticos &&
        acta.participantesPoliticos.length > 0
      ) {
        let tmp = acta.votosParticipantesRecuento.map((e) => ({
          ...e,
          resultado: e.votos ? e.votos.toString() : "0",
          // resultado: "",
        }));
        if (acta.votosGeneralesRecuento) {
          const { votosNulos, votosCandidatosNoRegistrados, votosTotales } =
            acta.votosGeneralesRecuento;
          tmp.push({ resultado: votosCandidatosNoRegistrados });
          tmp.push({ resultado: votosNulos });
          tmp.push({ resultado: votosTotales });
        }
        setNumVotosReservados(acta.numeroVotosReservados || 0);
        setVotosParticipantesPoliticosPrevios([...tmp]);
        const initial = acta.votosParticipantesRecuento.map((entry) => ({
          ...entry,
          resultado: "",
        }));
        initial.push({ tipoVoto: "CANDIDATO-NR", resultado: "" });
        initial.push({ tipoVoto: "NULO", resultado: "" });
        initial.push({ resultado: "" });
        if (acta.numerosProtesta) {
          const protestas = acta.numerosProtesta.map((entry) => ({
            resultado: entry.numeroProtestas,
          }));
          setNumProtesta([...protestas]);
        }
      }
      /*if (acta.detalleBoletas) {
        const {
          boletasSobrantes,
          boletasEncontradasAyuntamiento,
          boletasEncontradasGubernatura,
          boletasEncontradasDiputacion,
        } = acta.detalleBoletas;
        setBoletasSobrantes(boletasSobrantes);
        if (
          boletasEncontradasAyuntamiento ||
          boletasEncontradasGubernatura ||
          boletasEncontradasDiputacion
        ) {
          setEncontroBoletas(true);
          // debugger;
          setOtrasBoletasM(boletasEncontradasAyuntamiento);
          setOtrasBoletasG(boletasEncontradasGubernatura);
          setOtrasBoletasD(boletasEncontradasDiputacion);
        }
      } else*/ if(acta.actaRegistroVotoComputo) {
        setBoletasSobrantes(acta.actaRegistroVotoComputo?.numeroBoletasSobrantes);
      } else if (acta.actaRegistroVotoComputo) {
        setBoletasSobrantes(
          acta.actaRegistroVotoComputo.numeroBoletasSobrantes
        );
      } else {
        setBoletasSobrantes(0);
        setEncontroBoletas(false);
        setOtrasBoletasM(0);
        setOtrasBoletasG(0);
        setOtrasBoletasD(0);
      }
      if (acta.fechasRecuentoGt && acta.fechasRecuentoGt.length > 0) {
        const { fechaInicioRecuento, fechaFinRecuento } =
          acta.fechasRecuentoGt[0];
        const fechaInicioStr = fechaInicioRecuento?.split(" ")[0];
        const fechaFinStr = fechaFinRecuento?.split(" ")[0];
        const tiempoInicioStr = fechaInicioRecuento?.split(" ")[1];
        const tiempoFinStr = fechaFinRecuento?.split(" ")[1];
        const diaInicioStr = fechaInicioStr?.split("-")[2];
        const diaFinStr = fechaFinStr?.split("-")[2];
        const horaInicioStr = tiempoInicioStr?.split(":")[0];
        const horaFinStr = tiempoFinStr?.split(":")[0];
        const minutoInicioStr = tiempoInicioStr?.split(":")[1];
        const minutoFinStr = tiempoFinStr?.split(":")[1];
        setFechaInicio(diaInicioStr);
        setHoraInicio(horaInicioStr);
        setMinutoInicio(minutoInicioStr);
        setFechaFinal(diaFinStr);
        setHoraFinal(horaFinStr);
        setMinutoFinal(minutoFinStr);
      }
      if (acta.votosReservados && acta.votosReservados.length > 0) {
        setConVotos(true);
      }
      let res = [];
      acta.votosParticipantesRecuento.forEach((vP) => {
        const encontrado = acta.votosReservados.find(
          (vR) => vP.idParticipantePolitico === vR.idParticipantePolitico
        );
        const element = {
          ...vP,
          resultado: encontrado ? encontrado.votos : "",
        };
        res.push(element);
      });

      const candidatoNr = acta.votosReservados.find(
        (e) => e.tipoVoto === "CANDIDATO-NR"
      );
      const nulo = acta.votosReservados.find((e) => e.tipoVoto === "NULO");
      res.push({
        tipoVoto: "CANDIDATO-NR",
        resultado: candidatoNr?.votos?.toString(),
      });
      res.push({ tipoVoto: "NULO", resultado: nulo?.votos?.toString() });
      res.push({ resultado: "" });
      setVotosParticipantesPoliticos([...res]);
    }
  }, [acta, actaLoading]);

  // React.useEffect(() => {
  //   setMinutoFinal("");
  // }, [minutoInicio]);

  // React.useEffect(() => {
  //   setHoraFinal("");
  // }, [horaInicio]);

  // React.useEffect(() => {
  //   setMinutoFinal("");
  // }, [horaFinal]);

  // React.useEffect(() => {
  //   setHoraFinal("");
  //   setMinutoFinal("");
  // }, [fechaFinal]);

  // React.useEffect(() => {
  //   if (grupoTrabajo) {
  //     const {
  //       idGrupoTrabajo,
  //       numeroPuntosRecuento,
  //       fechaInicio: fechaInicioOriginal,
  //       fechaFin: fechaFinOriginal,
  //     } = grupoTrabajo;
  //     if (idGrupoTrabajo) {
  //       setGrupoTrabajoId(idGrupoTrabajo.toString());
  //     }
  //     if (numeroPuntosRecuento) {
  //       setPuntoDeRecuento(numeroPuntosRecuento.toString());
  //     }
  //     const fechaInicioTmp = Date.parse(fechaInicioOriginal);
  //     const horaInicioTmp = new Date(fechaInicioOriginal).toLocaleTimeString(
  //       "es-MX"
  //     );
  //     const fechaFinTmp = Date.parse(fechaFinOriginal);
  //     const horaFinTmp = new Date(fechaFinOriginal).toLocaleTimeString("es-MX");
  //     setFechaInicio(fechaInicioTmp);
  //     setHoraInicio(horaInicioTmp);
  //     setFechaFinal(fechaFinTmp);
  //     setHoraFinal(horaFinTmp);
  //   }
  // }, [grupoTrabajo]);

  const onSubmitRecuentoGT = (sumaVotos) => {
    setPostLoading(true);
    const mapper = (v) => ({
      idParticipantePolitico: v.idParticipantePolitico,
      votos: v.resultado,
    });
    const mapper2 = (v) => ({
      idParticipantePolitico: v.idParticipantePolitico,
      votos: v.resultado,
      tipoVoto: v.tipoVoto ? v.tipoVoto : "PARTICIPANTE",
    });
    const votosCandidatosNoRegistrados =
      votosParticipantesPoliticos[votosParticipantesPoliticos.length - 3]
        .resultado;
    const votosNulos =
      votosParticipantesPoliticos[votosParticipantesPoliticos.length - 2]
        .resultado;
    const votosTotales = sumaVotos;
    // SUMAR HORAS A FECHAS
    const obj = {
      idActa,
      idTipoActa: acta.idTipoActa,
      idGrupoTrabajo: grupoTrabajoId,
      // bBoletasOtrasElecciones: encontroBoletas,
      puntoRecuento: puntoDeRecuento.idGrupoTrabajoIntegrante,
      numeroBoletasSobrantes: boletasSobrantes,
      fechaInicioRecuento: new Date(fechaInicio),
      fechaFinRecuento: new Date(fechaFinal),
      votosNulos,
      votosCandidatosNoRegistrados,
      votosTotales,
      votosProtesta: numProtesta.map((e) => ({
        idPartidoPolitico: e.idPartido,
        votos: e.resultado,
      })),
      votosReservados: votosParticipantesPoliticos
        .filter((v) => v.resultado !== "")
        .map(mapper2),
      votosParticipante: votosParticipantesPoliticosPrevios
        .filter(
          (v) => v.idParticipantePolitico !== undefined && v.resultado !== ""
        )
        .map(mapper),
    };
    if (encontroBoletas) {
      obj.bBoletasOtrasElecciones = 1;
      obj.boletasOtrasElecciones = [
        {
          tipoEleccion: TIPO_ELECCION.GUBERNATURA,
          boletas: parseInt(otrasBoletasG),
        },
        {
          tipoEleccion: TIPO_ELECCION.DIPUTACIONES_LOCALES,
          boletas: parseInt(otrasBoletasD),
        },
        {
          tipoEleccion: TIPO_ELECCION.AYUNTAMIENTO,
          boletas: parseInt(otrasBoletasM),
        },
      ];
    }

    services
      .post("acta-registro-voto-computo/guardar-recuento-gt-rv", obj)
      .then((res) => {
        if (res) setPrintOpen(true);
      })
      .finally((_) => setPostLoading(false));
  };

  const onSaveClicked = () => {
    setValidando(true);
    if (
      postLoading ||
      puntoDeRecuento === "" ||
      grupoTrabajoId === "" ||
      boletasSobrantes === "" ||
      sumaVotosReservados !== numeroVotosReservados ||
      !boletasEncontradasValido ||
      [...votosParticipantesPoliticosPrevios]
        .filter((_, i) => i !== votosParticipantesPoliticosPrevios.length - 1)
        .some((p) => p.resultado === "")
    ) {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    } else {
      setConfirmOpen(true);
    }
  };

  const filterMinutes = React.useCallback(
    (m) =>
      m >= parseInt(minutoInicio === "" ? "0" : minutoInicio) ||
      horaFinal !== horaInicio,
    [minutoInicio, horaFinal, horaInicio]
  );

  if (!acta || actaLoading) return <Loader />;

  let sumaVotos = 0;
  votosParticipantesPoliticosPrevios.forEach((e, i) => {
    if (
      i < votosParticipantesPoliticosPrevios.length - 1 &&
      e.resultado &&
      e.resultado !== ""
    )
      sumaVotos += parseInt(e.resultado);
  });

  let sumaVotosReservados = 0;
  votosParticipantesPoliticos.forEach((e, i) => {
    if (
      i < votosParticipantesPoliticos.length - 1 &&
      e.resultado &&
      e.resultado !== ""
    )
      sumaVotosReservados += parseInt(e.resultado);
  });

  let boletasEncontradasValido = true;
  if (
    encontroBoletas &&
    acta.tipoEleccion &&
    (acta.tipoEleccion.idTipoEleccion === TIPO_ELECCION.DIPUTACIONES_LOCALES ||
      acta.tipoEleccion.idTipoEleccion ===
        TIPO_ELECCION.DIPUTACIONES_LOCALES_RP)
  ) {
    boletasEncontradasValido = otrasBoletasG !== "" && otrasBoletasM !== "";
  }
  if (
    encontroBoletas &&
    acta.tipoEleccion &&
    acta.tipoEleccion.idTipoEleccion === TIPO_ELECCION.GUBERNATURA
  ) {
    boletasEncontradasValido = otrasBoletasD !== "" && otrasBoletasM !== "";
  }
  if (
    encontroBoletas &&
    acta.tipoEleccion &&
    acta.tipoEleccion.idTipoEleccion === TIPO_ELECCION.AYUNTAMIENTO
  ) {
    boletasEncontradasValido = otrasBoletasG !== "" && otrasBoletasD !== "";
  }

  return (
    <PageLayout
      title={`RECUENTO DE ACTA CON VOTOS RESERVADOS POR GRUPO DE TRABAJO: ${
        acta ? acta.nombreCompletoActa : ""
      }`}
      links={[
        ...breadcrumbs,
        {
          to: `/recuento-gt/nuevo/detalle?id=${idActa}`,
          icon: <FindInPage fontSize="small" />,
          label: `${acta ? acta.nombreCompletoActa : ""}`,
        },
      ]}
      history={history}
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.subtitle}>
                Grupo de trabajo que ejercerá el recuento
              </Typography>
              <SingleSelect
                label="Grupo de trabajo"
                value={grupoTrabajo}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.subtitle}>
                Punto de recuento de la reunión de trabajo
              </Typography>
              <SingleSelect
                label="Punto de recuento"
                value={puntoDeRecuento}
                options={puntosRecuento}
                onChange={(e) => setPuntoDeRecuento(e.target.value)}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                  <Typography className={classes.subtitle}>
                    Fecha de inicio del recuento
                  </Typography>
                  <SingleSelect
                    label="Día"
                    value={fechaInicio}
                    error={validando && fechaInicio === ""}
                    onChange={(e) =>
                      diasFecha.includes(e.target.value)
                        ? setFechaInicio(e.target.value)
                        : setFechaInicio("")
                    }
                    options={diasFecha}
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.subtitle}>
                    de Junio del 2021
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.subtitle}>
                    Hora y tiempo de inicio del recuento
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SingleSelect
                        label="Hora"
                        disabled
                        value={horaInicio}
                        error={validando && horaInicio === ""}
                        onChange={(e) => setHoraInicio(e.target.value)}
                        options={opcionesHora}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SingleSelect
                        label="Minuto"
                        disabled
                        value={minutoInicio}
                        error={validando && minutoInicio === ""}
                        onChange={(e) => setMinutoInicio(e.target.value)}
                        options={opcionesMinuto}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                  <Typography className={classes.subtitle}>
                    Fecha de finalización de recuento
                  </Typography>
                  <SingleSelect
                    label="Día"
                    value={fechaFinal}
                    error={validando && fechaFinal === ""}
                    onChange={(e) =>
                      diasFecha.includes(e.target.value)
                        ? setFechaFinal(e.target.value)
                        : setFechaFinal("")
                    }
                    options={diasFecha}
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.subtitle}>
                    de Junio del 2021
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.subtitle}>
                    Hora de finalización de recuento
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SingleSelect
                        label="Hora"
                        disabled={minutoFinal !== ""}
                        value={horaFinal}
                        error={validando && horaFinal === ""}
                        onChange={(e) => setHoraFinal(e.target.value)}
                        options={opcionesHora.filter(
                          (h) =>
                            h >= parseInt(horaInicio === "" ? "0" : horaInicio)
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SingleSelect
                        label="Minuto"
                        disabled={minutoFinal !== ""}
                        value={minutoFinal}
                        error={validando && minutoFinal === ""}
                        onChange={(e) => setMinutoFinal(e.target.value)}
                        options={opcionesMinuto.filter(filterMinutes)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.subtitle}>
                Número de boletas sobrantes de la casilla
              </Typography>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <TextField
                  id="boletas-sobrantes"
                  size="small"
                  error={validando && boletasSobrantes === ""}
                  disabled
                  fullWidth
                  label="Boletas sobrantes"
                  placeholder="Ingrese la cantidad de boletas sobrantes"
                  helperText={
                    validando &&
                    boletasSobrantes === "" &&
                    "Favor de ingresar un número entero"
                  }
                  variant="outlined"
                  value={boletasSobrantes}
                  onChange={(e) => {
                    const { value } = e.target;
                    const intValue = parseInt(
                      value.toString().replace(NON_DIGIT, "")
                    );
                    let pEntry = isNaN(intValue) ? "" : intValue;
                    setBoletasSobrantes((v) => {
                      if (isNaN(intValue)) return "";
                      else if (value.length <= 3) return pEntry;
                      else return v;
                    });
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>
                NÚMERO ESCRITO DE PROTESTA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {acta &&
                acta.participantesPoliticos &&
                acta.participantesPoliticos.length > 0 ? (
                  [
                    ...participantesProtesta.sort((a, b) =>
                      a.orden && b.orden ? a.orden - b.orden : 1
                    ),
                  ]
                    .filter(filtroProtesta)
                    .map((p, index) => {
                      return (
                        <Grid item xs={2} key={index}>
                          <Grid
                            container
                            component={Paper}
                            style={{ alignItems: "center" }}
                          >
                            {p.logo !== "" ? (
                              <Grid item xs={12}>
                                <MiniCardEntry
                                  logo={photoBaseUrl + p.logo}
                                  disabled
                                  value={p.resultado}
                                />
                              </Grid>
                            ) : (
                              <div className={classes.panelEspecial}>
                                <Typography
                                  className={classes.panelEspecialText}
                                >
                                  {p.nombre}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })
                ) : (
                  <div />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container style={{ alignItems: "center" }}>
            <Checkbox
              value={encontroBoletas}
              checked={encontroBoletas}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography className={classes.text}>
              ENCONTRÓ BOLETAS DE OTRAS ELECCIONES
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {acta &&
              acta.tipoEleccion &&
              acta.tipoEleccion.idTipoEleccion !==
                TIPO_ELECCION.GUBERNATURA && (
                <Grid item xs={4}>
                  <Typography
                    className={classes.subtitle}
                    style={{ color: encontroBoletas ? "#000" : "#939393" }}
                  >
                    Boletas de GUBERNATURA encontradas
                  </Typography>
                  <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      id="filled-basic"
                      size="small"
                      disabled
                      error={
                        validando && encontroBoletas && otrasBoletasG === ""
                      }
                      fullWidth
                      label={`Boletas de GUBERNATURA`}
                      helperText={
                        validando &&
                        otrasBoletasG === "" &&
                        "Favor de ingresar un número entero"
                      }
                      variant="outlined"
                      value={otrasBoletasG}
                      onChange={(e) => {
                        const { value } = e.target;
                        const intValue = parseInt(
                          value.toString().replace(NON_DIGIT, "")
                        );
                        let pEntry = isNaN(intValue) ? "" : intValue;
                        setOtrasBoletasG((v) => {
                          if (isNaN(intValue)) return "";
                          else if (value.length <= 3) return pEntry;
                          else return v;
                        });
                      }}
                    />
                  </form>
                </Grid>
              )}

            {acta &&
              acta.tipoEleccion &&
              acta.tipoEleccion.idTipoEleccion !==
                TIPO_ELECCION.DIPUTACIONES_LOCALES &&
              acta.tipoEleccion.idTipoEleccion !==
                TIPO_ELECCION.DIPUTACIONES_LOCALES_RP && (
                <Grid item xs={4}>
                  <Typography
                    className={classes.subtitle}
                    style={{ color: encontroBoletas ? "#000" : "#939393" }}
                  >
                    Boletas DISTRITALES encontradas
                  </Typography>
                  <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      id="filled-basic"
                      size="small"
                      disabled
                      error={
                        validando && encontroBoletas && otrasBoletasD === ""
                      }
                      fullWidth
                      label={`Boletas DISTRITALES`}
                      helperText={
                        validando &&
                        otrasBoletasD === "" &&
                        "Favor de ingresar un número entero"
                      }
                      variant="outlined"
                      value={otrasBoletasD}
                      onChange={(e) => {
                        const { value } = e.target;
                        const intValue = parseInt(
                          value.toString().replace(NON_DIGIT, "")
                        );
                        let pEntry = isNaN(intValue) ? "" : intValue;
                        setOtrasBoletasD((v) => {
                          if (isNaN(intValue)) return "";
                          else if (value.length <= 3) return pEntry;
                          else return v;
                        });
                      }}
                    />
                  </form>
                </Grid>
              )}

            {acta &&
              acta.tipoEleccion &&
              acta.tipoEleccion.idTipoEleccion !==
                TIPO_ELECCION.AYUNTAMIENTO && (
                <Grid item xs={4}>
                  <Typography
                    className={classes.subtitle}
                    style={{ color: encontroBoletas ? "#000" : "#939393" }}
                  >
                    Boletas de AYUNTAMIENTO encontradas
                  </Typography>
                  <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      id="filled-basic"
                      size="small"
                      disabled
                      error={
                        validando && encontroBoletas && otrasBoletasM === ""
                      }
                      fullWidth
                      label={`Boletas de AYUNTAMIENTO`}
                      helperText={
                        validando &&
                        otrasBoletasM === "" &&
                        "Favor de ingresar un número entero"
                      }
                      variant="outlined"
                      value={otrasBoletasM}
                      onChange={(e) => {
                        const { value } = e.target;
                        const intValue = parseInt(
                          value.toString().replace(NON_DIGIT, "")
                        );
                        let pEntry = isNaN(intValue) ? "" : intValue;
                        setOtrasBoletasM((v) => {
                          if (isNaN(intValue)) return "";
                          else if (value.length <= 3) return pEntry;
                          else return v;
                        });
                      }}
                    />
                  </form>
                </Grid>
              )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center" style={{ fontSize: 22 }}>
            La casilla cuenta con <strong>{numeroVotosReservados}</strong> votos
            reservados que deberán resolverse.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontSize: 24 }}
                  >
                    RESULTADOS DE LA VOTACIÓN DE CASILLA:
                    {` ${acta ? acta.nombreCompletoActa : ""}`}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={6} style={{ textAlign: "center" }}>
                    Digite los votos para cada partido politico, coalición,
                    cantidatura común, candidato independiente, candidatos no
                    registrados y total de votos. <br /> En caso de no haber
                    votos en alguno de los campos, digite el número cero.
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={1}>No.</StyledTableCell>
                  <StyledTableCell colSpan={1} align="center">
                    Partido Político, Candidatura Común, Coalición o Candidato
                    Independiente
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">
                    RESULTADOS DE LA VOTACIÓN DE AYUNTAMIENTO CAPTURADA POR EL
                    GRUPO DE TRABAJO
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">
                    RESULTADOS DE LA VOTACIÓN DE AYUNTAMIENTO
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {acta &&
                acta.participantesPoliticos &&
                acta.participantesPoliticos.length > 0 ? (
                  [
                    ...acta.participantesPoliticos.sort((a, b) =>
                      a.orden && b.orden ? a.orden - b.orden : 1
                    ),
                    ...tipoVotos,
                  ].map((p, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell colSpan={1}>
                        {index < acta.participantesPoliticos.length
                          ? index + 1
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1} align="center">
                        {p.logo !== "" ? (
                          <img
                            alt={p.nombre}
                            src={p.logo}
                            style={{ width: 100 }}
                          />
                        ) : (
                          <div className={classes.panelEspecial}>
                            <Typography className={classes.panelEspecialText}>
                              {p.nombre}
                            </Typography>
                          </div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2} align="right">
                        <form
                          autoComplete="off"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <DarkerDisabledTextField
                            id={index.toString()}
                            label="Número del 0 al 999"
                            fullWidth
                            variant="standard"
                            required
                            disabled
                            value={
                              votosParticipantesPoliticosPrevios[index]
                                ? votosParticipantesPoliticosPrevios[index]
                                    .resultado
                                : ""
                            }
                            InputProps={{ className: classes.fieldText }}
                          />
                        </form>
                        {validando && p.resultado === "" && (
                          <FormHelperText className={classes.tableHelperText}>
                            Favor de ingresar un número entero
                          </FormHelperText>
                        )}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2} align="right">
                        <form
                          autoComplete="off"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <TextField
                            id={index.toString()}
                            label={
                              Boolean(
                                numeroVotosReservados - sumaVotosReservados
                              )
                                ? `Número del 0 al ${
                                    numeroVotosReservados - sumaVotosReservados
                                  }`
                                : "Votos reservados resueltos"
                            }
                            fullWidth
                            variant="standard"
                            required
                            value={
                              votosParticipantesPoliticos[index]
                                ? index ===
                                  votosParticipantesPoliticos.length - 1
                                  ? sumaVotosReservados
                                  : votosParticipantesPoliticos[index].resultado
                                : ""
                            }
                            disabled={
                              index ===
                                votosParticipantesPoliticos.length - 1 ||
                              (sumaVotosReservados === numeroVotosReservados &&
                                votosParticipantesPoliticos[index] &&
                                votosParticipantesPoliticos[index].resultado ===
                                  "")
                            }
                            InputProps={{ className: classes.fieldText }}
                            onChange={(e) => {
                              const { value } = e.target;
                              const intValue = parseInt(
                                value.toString().replace(NON_DIGIT, "")
                              );
                              let pEntry =
                                isNaN(intValue) || intValue === 0
                                  ? ""
                                  : intValue;
                              setVotosParticipantesPoliticos((v) => {
                                let arr = [...v];
                                let num = parseInt(arr[index].resultado);
                                if (isNaN(num)) num = 0;
                                if (
                                  arr.length > 0 &&
                                  (intValue <=
                                    numeroVotosReservados -
                                      sumaVotosReservados +
                                      num ||
                                    pEntry === "")
                                ) {
                                  arr[index].resultado = pEntry;
                                }
                                return arr;
                              });
                            }}
                          />
                        </form>
                        {validando &&
                          p.resultado === "" &&
                          !(
                            index === votosParticipantesPoliticos.length - 1 ||
                            (sumaVotosReservados === numeroVotosReservados &&
                              votosParticipantesPoliticos[index] &&
                              votosParticipantesPoliticos[index].resultado ===
                                "")
                          ) && (
                            <FormHelperText className={classes.tableHelperText}>
                              Favor de ingresar un número entero
                            </FormHelperText>
                          )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <div />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {sumaVotosReservados !== numeroVotosReservados && (
          <Grid item xs={12}>
            <Typography align="center" style={{ fontSize: 22 }}>
              Es necesario distribuir los{" "}
              <strong>{numeroVotosReservados - sumaVotosReservados}</strong>{" "}
              votos faltantes.
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            disabled={
              conVotos ||
              (!conVotos &&
                (postLoading ||
                  grupoTrabajoId === "" ||
                  puntoDeRecuento === "" ||
                  // boletasSobrantes === "" ||
                  sumaVotosReservados !== numeroVotosReservados ||
                  !boletasEncontradasValido ||
                  [...votosParticipantesPoliticosPrevios]
                    .filter(
                      (_, i) =>
                        i !== votosParticipantesPoliticosPrevios.length - 1
                    )
                    .some((p) => p.resultado === "")))
            }
            color="primary"
            variant="contained"
            style={{ float: "right", marginLeft: "1%" }}
            onClick={onSaveClicked}
          >
            {postLoading ? "CARGANDO" : "GUARDAR"}
          </Button>
        </Grid>
        <AlertDialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          title={`CONFIRMACIÓN DE RECUENTO CON GRUPO DE TRABAJO DE ACTA ${
            acta ? acta.nombreCompletoActa : ""
          }`}
          loading={postLoading}
          content="¿Está seguro que desea guardar la información capturada?"
          onAccept={(_) => onSubmitRecuentoGT(sumaVotos)}
        />
        <AlertDialog
          open={printOpen}
          // onClose={() => history.push("/recuento-gt-reservado/nuevo")}
          title={`${
            acta ? acta.nombreCompletoActa : ""
          } GUARDADO CORRECTAMENTE.`}
          titleColor="#60c45a"
          acceptText="Finalizar y continuar"
          content="Para continuar, haga click en Finalizar y continuar."
          onAccept={
            (_) => history.push("/recuento-gt-reservado/nuevo")
            // window.open(
            //   `${baseUrlPdf}constancia-individual?idActa=${idActa}&idUsuario=${id}`,
            //   "_blank"
            // )
          }
        />
      </Grid>
    </PageLayout>
  );
};

export default RecuentoGtReservadoDetail;
