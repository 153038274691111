import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Autorenew, Check, TouchApp } from "@material-ui/icons";
import React from "react";
import AlertDialog from "../../components/AlertDialog";
import AsyncSelect from "../../components/AsyncSelect";
import CustomDialog from "../../components/CustomDialog";
import SingleSelect from "../../components/SingleSelect";
import TablePageLayout from "../../components/TablePageLayout";
import { useModel } from "../../hooks/useModel";
import services from "../../services";
import RectificacionTable from "./RectificacionTable";

const headers = ["Acta", "Cantidad", "Revisadas"];

const tiposDeActa = [
  { value: 1, nombre: "ACTA ORIGINAL DE CASILLA" },
  { value: 2, nombre: "ACTA DE PREP" },
  { value: 3, nombre: "ACTA DE REPRESENTANTE POLÍTICO" },
];

const rcOpts = [
  { value: 1, nombre: "RECUENTO" },
  { value: 2, nombre: "COTEJO" },
];

const NuevaRectificacion = ({ history }) => {
  const [actaSeleccionada, setActaSeleccionada] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [estatusPaquete, setEstatusPaquete] = React.useState("");
  const [revision, setRevision] = React.useState("");
  const [estatusActa, setEstatusActa] = React.useState("");
  const [tipoActa, setTipoActa] = React.useState("");
  const [motivoRecuento, setMotivoRecuento] = React.useState("");
  const [motivoRectificacion, setMotivoRectificacion] = React.useState("");
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [rectificado, setRectificado] = React.useState(false);
  const [finalizarOpen, setFinalizarOpen] = React.useState(false);

  const { model, modelLoading, refreshModel } = useModel({
    name: "rectificacion/tablero",
    id: 1,
  });

  React.useEffect(() => {
    if (!modelLoading && model) {
      const recuento = model["recuento"];
      const cotejo = model["cotejo"];
      const sinRa = model["sin-ra"];
      // const sinRpe = model["sin-rpe"];
      const finRectificacion = model["finRectificacion"];
      if (finRectificacion) {
        setRectificado(true);
      }
      const tmp = [];
      tmp.push({
        acta: "RECUENTO",
        action: !rectificado,
        cantidad: recuento["total"],
        revisadas: recuento["revisadas"],
        extraParams: {
          estatusRecuento: "con-recuento",
          expand:
            "estatusPaquete,estatusActa,recuentoCotejo,fechaRectificacion,motivosRecuento,fechaComputo,recuentoGT,tipoDeActa",
        },
      });
      tmp.push({
        acta: "COTEJO",
        action: !rectificado,
        cantidad: cotejo["total"],
        revisadas: cotejo["revisadas"],
        extraParams: {
          estatusCotejo: "con-cotejo",
          expand:
            "estatusPaquete,estatusActa,recuentoCotejo,fechaRectificacion,fechaComputo,motivoRectificacion,tipoDeActa",
        },
      });
      tmp.push({
        acta: "PENDIENTE RESTANTE",
        cantidad: sinRa["total"],
        action: true,
        revisadas: sinRa["revisadas"],
        extraParams: {
          sinCr: 1,
          expand:
            "estatusPaquete,estatusActa,recuentoCotejo,fechaRectificacion",
        },
      });
      // tmp.push({
      //   acta: "SIN RECEPCIÓN PAQUETE",
      //   cantidad: sinRpe["total"],
      //   revisadas: sinRpe["revisadas"],
      //   extraParams: {
      //     estatusRecepcion: "sin-recepcion",
      //     estatusRpe: "sin-rpe",
      //     expand:
      //       "estatusPaquete,estatusActa,recuentoCotejo,fechaRectificacion",
      //   },
      // });
      setRows([...tmp]);
    }
  }, [model, modelLoading, rectificado]);

  React.useEffect(() => {
    if (actaSeleccionada) {
      if (actaSeleccionada.estatusPaquete) {
        setEstatusPaquete(actaSeleccionada.estatusPaquete);
      }
      if (actaSeleccionada.estatusActa) {
        setEstatusActa(actaSeleccionada.estatusActa);
      }
      setTipoActa({
        value: 1,
        nombre: "ACTA ORIGINAL DE CASILLA",
      });
      if (actaSeleccionada.recuentoCotejo === "R") {
        setRevision({ value: 1, nombre: "RECUENTO" });
      } else if (actaSeleccionada.recuentoCotejo === "C") {
        setRevision({ value: 2, nombre: "COTEJO" });
      }
      if (actaSeleccionada.motivoRectificacion) {
        setMotivoRectificacion(actaSeleccionada.motivoRectificacion);
      }
      if (actaSeleccionada.tipoDeActa) {
        const ele = tiposDeActa.find(
          (t) => t.nombre === actaSeleccionada.tipoDeActa
        );
        setTipoActa(ele);
      }
    }
  }, [actaSeleccionada]);

  // React.useEffect(() => {
  //   if (estatusPaquete !== "") {
  //     const estatusId = parseInt(estatusPaquete);
  //   }
  // }, [estatusPaquete])

  React.useEffect(() => {
    if (revision.value === 2) {
      setMotivoRecuento("");
    }
  }, [revision]);

  const onSubrowClicked = (acta) => {
    setActaSeleccionada(acta);
  };

  const onDialogClose = () => {
    setActaSeleccionada(null);
    setEstatusPaquete("");
    setRevision("");
    setEstatusActa("");
    setTipoActa("");
    setMotivoRecuento("");
    setMotivoRectificacion("");
  };

  const onDialogSave = () => {
    setSaveLoading(true);
    const body = {
      idActa: actaSeleccionada.idActa,
      idEstatusActa:
        revision && revision.value === 1 ? estatusActa.idEstatusActa : 4,
      idEstatusPaquete:
        revision && revision.value === 1 ? estatusPaquete.idEstatusPaquete : 2,
      cr: revision && revision.value === 1 ? "R" : "C",
      tipoActa: tipoActa.nombre,
    };
    if (motivoRecuento) {
      body.idMotivoRecuento = motivoRecuento.idMotivoRecuento;
    }
    if (motivoRectificacion !== "") {
      body.motivoRectificacion = motivoRectificacion;
    }
    services
      .post("rectificacion/guardar", body)
      .then((resp) => {
        if (resp) {
          onDialogClose();
          refreshModel();
        }
      })
      .finally((_) => setSaveLoading(false));
  };

  const finalizarClicked = () => {
    setSaveLoading(true);
    services
      .post("rectificacion/finalizar", {})
      .then((resp) => {
        if (resp) {
          refreshModel();
        }
      })
      .finally((_) => {
        setSaveLoading(false);
        setFinalizarOpen(false);
      });
  };

  return (
    <>
      <TablePageLayout
        title="RECTIFICACIÓN"
        history={history}
        links={[
          {
            to: `/rectificacion`,
            icon: <Autorenew fontSize="small" />,
            label: `Rectificación`,
          },
        ]}
        bottomActionButton={{
          label: "Finalizar rectificación",
          icon: <Check />,
          buttonProps: {
            onClick: () => setFinalizarOpen(true),
            disabled: rectificado || (model && model["sin-ra"]["total"] !== 0),
          },
        }}
        tableLoading={modelLoading}
        TableComponent={
          <RectificacionTable
            headers={headers}
            collapsibleRows={rows}
            onSubrowClicked={onSubrowClicked}
            columnsForTotal={["cantidad", "revisadas"]}
            showTotal={false}
          />
        }
        Dialog={
          <CustomDialog
            maxWidth="md"
            title={actaSeleccionada ? actaSeleccionada.nombreCompletoActa : ""}
            subtitle="Es importante revisar la información antes de envíar el acta a revisión por cotejo o recuento."
            isOpen={!!actaSeleccionada}
            handleClose={onDialogClose}
            handleSave={onDialogSave}
            saveDisabled={
              !revision ||
              (estatusPaquete &&
                estatusPaquete.idEstatusPaquete !== 1 &&
                ((revision && revision.value === 1 && motivoRecuento === "") ||
                  (revision &&
                    revision.value === 2 &&
                    motivoRectificacion === "")))
            }
            saveLoading={saveLoading}
            defaultActions
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {actaSeleccionada &&
                  actaSeleccionada.motivosRecuento &&
                  actaSeleccionada.motivosRecuento.length && (
                    <Typography style={{ fonSize: 22 }}>
                      <strong>Motivos de recuento previos:</strong>
                    </Typography>
                  )}

                {actaSeleccionada &&
                  actaSeleccionada.motivosRecuento &&
                  actaSeleccionada.motivosRecuento.length &&
                  actaSeleccionada.motivosRecuento.map((item) => (
                    <Typography>{item.nombre}</Typography>
                  ))}
              </Grid>

              <Grid item xs={6}>
                <SingleSelect
                  size="small"
                  label="Método de revisión"
                  options={rcOpts}
                  value={revision}
                  disabled={
                    estatusPaquete && estatusPaquete.idEstatusPaquete === 1
                  }
                  onChange={(e) => setRevision(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <SingleSelect
                  size="small"
                  label="Tipo de acta"
                  options={tiposDeActa}
                  value={tipoActa}
                  disabled={
                    estatusPaquete && estatusPaquete.idEstatusPaquete === 1
                  }
                  onChange={(e) => setTipoActa(e.target.value)}
                />
              </Grid>
              {actaSeleccionada?.abiertoEn === "PENDIENTE RESTANTE" && (
                <>
                  <Grid item xs={6}>
                    <AsyncSelect
                      size="small"
                      label="Estatus del paquete"
                      name="estatus-paquete"
                      labelProp="nombre"
                      valueProp="idEstatusPaquete"
                      value={estatusPaquete}
                      onChange={(e) => setEstatusPaquete(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AsyncSelect
                      size="small"
                      label="Estatus del acta"
                      name="estatus-acta"
                      labelProp="nombre"
                      value={estatusActa}
                      disabled={
                        estatusPaquete && estatusPaquete.idEstatusPaquete === 1
                      }
                      onChange={(e) => setEstatusActa(e.target.value)}
                    />
                  </Grid>
                </>
              )}
              {revision && revision.value === 1 && (
                <Grid item xs={12}>
                  <AsyncSelect
                    size="small"
                    label="Motivo de recuento"
                    name="motivo-recuento"
                    labelProp="nombre"
                    value={motivoRecuento}
                    disabled={
                      estatusPaquete && estatusPaquete.idEstatusPaquete === 1
                    }
                    onChange={(e) => setMotivoRecuento(e.target.value)}
                  />
                </Grid>
              )}
              {revision && revision.value === 2 && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Motivo de rectificación"
                    placeholder="Redacte el motivo de la rectificación"
                    value={motivoRectificacion}
                    disabled={
                      estatusPaquete && estatusPaquete.idEstatusPaquete === 1
                    }
                    onChange={(e) => setMotivoRectificacion(e.target.value)}
                  />
                </Grid>
              )}
            </Grid>
          </CustomDialog>
        }
      >
        {!modelLoading && model && model["sin-ra"]["total"] !== 0 && (
          <Grid
            container
            direction="column"
            alignItems="flex-end"
            justify="flex-end"
            style={{ color: "#919191", marginTop: 15 }}
          >
            <Typography>
              Existen Actas por definir en Cotejo o Recuento.
            </Typography>
          </Grid>
        )}
      </TablePageLayout>
      <AlertDialog
        open={finalizarOpen}
        title="¿ESTÁ SEGURO QUE DESEA FINALIZAR LA SESIÓN DE RECTIFICACIÓN Y RATIFICACIÓN?"
        onAccept={finalizarClicked}
        onClose={() => setFinalizarOpen(false)}
      />
    </>
  );
};

export default NuevaRectificacion;
