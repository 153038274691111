import React, { useState } from "react";
import TablePagination from "../../components/TablePagination";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Loading from "../../components/FullScreenLoader";
import { useModels } from "../../hooks/useModels";
import { useAlert } from "../../hooks/useAlert";
import { emptyRequest, postRequest } from "../../constants/request";
import { useHttp } from "../../hooks/useHttp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Actas = () => {
  const classes = useStyles();
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({
    name: "acta",
    expand: "casilla,tipoActa,tipoEleccion",
    ordenar: "idActa.desc",
  });
  const [saveRequest, setSaveRequest] = React.useState(emptyRequest);
  const [response, responseLoading, responseError] = useHttp(saveRequest);


  const onSaveClicked = (idActa) => {
    let request = null;
    if(idActa){
       request = postRequest("acta/reiniciar-acta",{ idActa });
    }else{
       request = postRequest("acta/reiniciar-actas",{ nombreScript: "reinciar" })
    }  
    request.alert = true;
    setSaveRequest(request);
    if (!responseError && !responseLoading) {
      setTimeout(() => {
      }, 1000);
    }
  };

  const changePage = (page) => {
    let params = {
      name: "acta",
      ordenar: "idActa.desc",
      pagina: page + 1,
      limite: 20,
      isCargando: false,
    };
    refreshModels(true, params);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              color="primary" 
              style={{margin:"10px 0px"}}
              onClick={() => onSaveClicked()}
            >
              Reiniciar todas las actas 
            </Button>
            {modelsLoading ? (
              <Loading />
            ) : (
              <TablePagination
                header={[]}
                data={models}
                paginatedApi
                changePageApi={changePage}
                count={modelsPage !== null ? modelsPage.total : 0}
                extraRows={[
                  {
                    prop: "casilla",
                    name: "Casilla",
                    cell: (row) => (
                      <> {row.casilla.seccionCasilla} </>
                    ),
                  },
                  {
                    prop: "tipoActa",
                    name: "Tipo Acta",
                    cell: (row) => (
                      <> {row.tipoActa.nombre} </>
                    ),
                  },
                  {
                    prop: "tipoEleccion",
                    name: "Tipo Elección",
                    cell: (row) => (
                      <> {row.tipoEleccion.nombre} </>
                    ),
                  },
                  {
                    prop: "acción",
                    name: "Acción",
                    cell: (row) => (
                      <>
                       <Button 
                         variant="contained" 
                         color="primary"
                         onClick={() => onSaveClicked(row.idActa)}
                       >
                         Reiniciar
                       </Button>
                      </>
                    ),
                  },
                ]}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Actas;
