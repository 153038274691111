import React from "react";
import Grid from "@material-ui/core/Grid";
import FindInPage from "@material-ui/icons/FindInPage";
import { History } from "@material-ui/icons";

import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";
import PageLayout from "../../components/PageLayout";
import SeleccionarActa from "../../components/SeleccionarActa";

const params = {
  estatusLectura: "sin-lectura",
  fields: "idActa,nombreCompletoActa,token",
};

const breadcrumbs = [
  {
    to: `/lectura-de-votos`,
    icon: <FindInPage fontSize="small" />,
    label: `Historial: Actas con lectura`,
  },
  {
    to: `/lectura-de-votos/nueva`,
    icon: <FindInPage fontSize="small" />,
    label: `Nueva lectura de votos`,
  },
];

const LecturaDeVotos = ({ history }) => {
  const onEntryChange = (_, value) => {
    if (value && value !== "") {
      history.push(
        `/lectura-de-votos/detalle?id=${value.token}&a=${value.idActa}`
      );
    }
  };

  return (
    <PageLayout
      title="LECTURA DE VOTOS"
      links={breadcrumbs}
      history={history}
      actionButton={{
        to: `/lectura-de-votos`,
        icon: <History />,
        label: `VER HISTORIAL DE LECTURA`,
      }}
      SearchComponents={
        <>
          <Grid item xs={6}>
            <AsyncAutocompleteSearch
              label="Seleccione el acta deseada"
              name="acta"
              extraParams={params}
              labelProp="nombreCompletoActa"
              searchParam="busqueda"
              onChange={onEntryChange}
              noOptionsText="No hay actas para lectura de votos."
            />
          </Grid>
        </>
      }
    >
      <SeleccionarActa />
    </PageLayout>
  );
};

export default LecturaDeVotos;
