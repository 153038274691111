import React from "react";
import { Ballot } from "@material-ui/icons";
import { Button, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { generateDefaultChartOptions, getPercentage } from "../../utils";
import { useModels } from "../../hooks/useModels";

const actasRequestDefault = {
  name: "consejo-electoral/estadistico-gt",
  extraParams: {},
};

const EstadisticoGruposDeTrabajo = ({ history }) => {
  const header = [];
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [actasRequest, setActasRequest] = React.useState(actasRequestDefault);
  const [actas, actasLoading] = useModels(actasRequest);
  let chartOptions = generateDefaultChartOptions();

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );

  const setIdConsejoElectoralCallback = React.useCallback(
    (e, v) => {
      if (!v) return;
      const { idConsejoElectoral } = v;
      if (idConsejoElectoral && idConsejoElectoral !== "") {
        const req = { ...actasRequestDefault };
        req.extraParams = { ...req.extraParams, idConsejoElectoral };
        setActasRequest(req);
        setIdConsejoElectoral(idConsejoElectoral);
      } else {
        setIdConsejoElectoral("");
      }
    },
    [setActasRequest]
  );

  const onSearchClicked = async () => {
    if (idConsejoElectoral && idConsejoElectoral !== "") {
      const req = { ...actasRequestDefault };
      req.extraParams = { ...req.extraParams, idConsejoElectoral };
      setActasRequest(req);
    }
  };

  const sinRecuentoInt =
    actas && actas[0]
      ? parseInt(actas[0]?.actasTotal.toString().replace(",", "")) -
        parseInt(actas[0]?.actasRecuento.toString().replace(",", ""))
      : 0;

  const conRecuentoInt =
    actas && actas[0]
      ? parseInt(actas[0]?.actasRecuento.toString().replace(",", ""))
      : 0;

  let extraOptions = {
    colors: ["#9E9E9E", "#ad2624"],
    title: {
      text: "RECUENTO POR GRUPO DE TRABAJO",
    },
    subtitle: {
      text: "TOTALIZADOR DE ACTAS EN RECUENTO Y RECONTADAS",
    },
    series: [
      {
        name: "Actas",
        data: [
          ["Sin recuento", sinRecuentoInt],
          ["Recontadas", conRecuentoInt],
        ],
      },
    ],
  };
  chartOptions = { ...chartOptions, ...extraOptions };

  return (
    <TablePageLayout
      title="ESTADÍSTICOS: REPORTE DE GRUPOS DE TRABAJO"
      model="consejos-electorales"
      history={history}
      links={[
        {
          to: "/lectura-votos",
          icon: <Ballot />,
          label: "Estadísticos: Reporte grupos de trabajo",
        },
      ]}
      SearchComponents={
        <>
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={actasLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={actasLoading}
      TableComponent={
        <Grid container justify="center">
          <Grid item xs={8} style={{ marginTop: 50 }}>
            <TablePagination
              header={header}
              data={actas}
              paginatedApi
              extraRows={[
                {
                  prop: "actasTotal",
                  name: "ACTAS A RECONTAR",
                  cell: (row) => <>{row.actasTotal}</>,
                },
                {
                  prop: "gruposTrabajo",
                  name: "GRUPOS DE TRABAJO",
                  cell: (row) => <>{row.gruposTrabajo}</>,
                },
                {
                  prop: "puntosRecuento",
                  name: "PUNTOS DE RECUENTO",
                  cell: (row) => <>{row?.puntosRecuento || 0}</>,
                },
                {
                  prop: "actasDatosGenerales",
                  name: "PORCENTAJE RECONTADAS",
                  cell: (row) => <>{row.actasRecuentoPorcentaje + " %"}</>,
                },
              ]}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 50 }}>
            {actas && actas[0] && (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default EstadisticoGruposDeTrabajo;
