import React, { useEffect, useState } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { Card, CircularProgress, TextField, Typography, Grid, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PageLayout from "../../../components/PageLayout";
import Services from "../../../services";
import FullScreenLoader from "../../../components/FullScreenLoader";
import TablePagination from "../../../components/TablePagination";
import GetAppIcon from "@material-ui/icons/GetApp";

const urlConsejos = "consejo-electoral?limite=-1";
const urlCasilla = "casilla?limite=-1&&expand=distrito,seccion,nombreCompleto,casillaClausura&idConsejo=";

const ReporteClausura = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [gettingCasillas, setGettingCasillas] = useState(false);
  const [consejos, setConsejos] = useState([]);
  const [casillas, setCasillas] = useState([]);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    let mounted = true;

    const obtenerConsejos = async () => {
      try {
        const response = await Services.get(urlConsejos);

        if (mounted) {
          setConsejos(response.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    obtenerConsejos();

    return () => (mounted = false);
  }, []);

  if (loading) return <FullScreenLoader />;

  const onChangeConsejos = async (consejo) => {
    if (!consejo) return;

    try {
      setGettingCasillas(true);

      const response = await Services.get(
        urlCasilla + consejo.idConsejoElectoral
      );

      setCasillas(response.resultado);
    } catch (error) {
      console.error(error);
    } finally {
      setGettingCasillas(false);
    }
  };

  const downloadExcel = async () => {
    try {
      if(downloading) return;

      setDownloading(true);

      await Services.downloadBlob(
        "excel/reporte-clausura",
        {},
        "ReporteClausura.xlsx"
      );
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <PageLayout
      title="Reporte Clausura"
      history={history}
      links={[
        {
          to: "/estadisticos/bitacora-escaneo",
          icon: <DescriptionIcon fontSize="small" />,
          label: "Reporte Clausura",
        },
      ]}
    >
      <Grid container spacing={2}> 
        <Grid item md={11}>
          <Autocomplete
            size="small"
            options={consejos}
            getOptionLabel={(option) => (option.nombre ? option.nombre : "")}
            style={{ width: "50%" }}
            renderInput={(params) => (
              <TextField
                style={{ backgroundColor: "white" }}
                {...params}
                label="Consejos Electorales"
                variant="outlined"
              />
            )}
            onChange={async (event, newValue) => await onChangeConsejos(newValue)}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item md={1}>
          <Button
            style={{ backgroundColor: "#4caf50", color: "white" }}
            fullWidth
            variant="contained"
            onClick={downloadExcel}
            startIcon={<GetAppIcon />}
          >
            {downloading ? <CircularProgress style={{color: "white"}} size={25} /> : "Excel"}
          </Button>
        </Grid>
      </Grid>
      {
        casillas.length 
        ?
          <TablePagination
            data={casillas}
            extraRows={[
              {
                prop: "nombreCompleto",
                name: "Casilla",
                cell: (row) => <>{row.nombreCompleto}</>,
              },
              {
                prop: "seccion",
                name: "Sección",
                cell: (row) => (
                  <>{row.seccion ? row.seccion.nombre : "Sin Sección"}</>
                ),
              },
              {
                prop: "casillaClausura",
                name: "Clasurado",
                cell: (row) => (
                  <>{row.casillaClausura ? "Clausurado" : "Pendiente"}</>
                ),
              },
            ]}
          />
        : 
          <Card style={{ padding: 10 }}>
          {
            gettingCasillas 
            ? 
              <center>
                <CircularProgress size={25} />
              </center>
            : 
              <Typography align="center">Sin resultados...</Typography>
          }
          </Card>
      }
    </PageLayout>
  );
};

export default ReporteClausura;
