import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootActive: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    width: "100%",
    cursor: "pointer",
  },
  rootInactive: {
    backgroundColor: theme.palette.grey[400],
    color: "white",
    width: "100%",
    cursor: "pointer",
  },
}));

const ModoCard = ({ nombre, onActivateClicked, active }) => {
  const classes = useStyles();

  return (
    <Card
      className={active ? classes.rootActive : classes.rootInactive}
      onClick={onActivateClicked}
    >
      <CardContent>
        <Typography variant="h5" component="h2">
          <strong>{nombre}</strong>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ModoCard;
